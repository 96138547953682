import React from "react";
import { Container } from "@material-ui/core";
import { Link } from "react-router-dom";

export function Home() {
  return (
    <Container>
      <h2>Ultimate</h2>
      <ul>
        <li>
          <Link to="/usau/rankings">USAU Rankings (WIP)</Link>
        </li>
        <li>
          <Link to="/audl">AUDL Throw Explorer (2021 Season)</Link>
        </li>
        <li>
          <Link to="/audl/stats">AUDL Stats (2021 Season)</Link>
        </li>
        <li>
          <Link to="/filmroom">Filmroom Tool</Link>
        </li>
      </ul>
      <h2>Basketball</h2>
      <ul>
        <li>
          <Link to="/wowy">With or Without You (2021-22 Season)</Link>
        </li>
        <li>
          <Link to="/similarity">Player Similarity (2020-21 Season)</Link>
        </li>
      </ul>
    </Container>
  );
}
