import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { FirebaseAppProvider } from "reactfire";

const firebaseConfig = {
  apiKey: "AIzaSyBoHeT41js8ex-Ix8P9MhFrv7aB6Ii4iX0",
  authDomain: "nba-viz-5572b.firebaseapp.com",
  projectId: "nba-viz-5572b",
  storageBucket: "nba-viz-5572b.appspot.com",
  messagingSenderId: "881739769162",
  appId: "1:881739769162:web:8642d41fbbc4861c458db3",
  measurementId: "G-YMK9YY6P9S",
};

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <App />
    </FirebaseAppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
