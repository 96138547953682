import React, { useState } from "react";
import "firebase/firestore";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import YouTube from "react-youtube";

import { useInterval } from "../common/useinterval";

interface ThrowEvent {
  timestamp: number;
  x: number;
  y: number;
}

interface Player {
  getCurrentTime(): number;
  pauseVideo(): void;
  playVideo(): void;
}

const useStyles = makeStyles(() =>
  createStyles({
    colContainer: {
      display: "flex",
    },
    rowContainer: {
      display: "flex",
      flexDirection: "column",
    },
    centerContent: {
      textAlign: "center",
      width: "70%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 10,
      marginBottom: 10,
    },
    fieldMap: {
      position: "relative",
    },
    throwMarker: {
      color: "blue",
      position: "absolute",
    },
    throwButton: {
      height: 56,
    },
  })
);

const IMG_FIELD_WIDTH = 150;
// const ACTUAL_FIELD_WIDTH = 40;
const IMG_FIELD_HEIGHT = 415;
// const ACTUAL_FIELD_HEIGHT = 110;

const VIDEO_OPTS = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    controls: 1 as 0 | 2 | 1,
    modestbranding: 1 as 1 | undefined,
  },
};

export function Ultimate() {
  const classes = useStyles();
  const theme = useTheme();

  const [videoId, setVideoId] = useState<string>("SQXx2fxQQx8");
  const [timeStamp, setTimeStamp] = useState<number>(0);
  const [player, setPlayer] = useState<Player | null>(null);
  const [throws, setThrows] = useState<ThrowEvent[]>([]);

  // Pick either primary or secondary to give most contrast against current mode.
  const contrastingThemeColor =
    theme.palette.type === "light" ? "primary" : "secondary";

  useInterval(() => {
    if (!player) return;
    setTimeStamp(player.getCurrentTime());
  }, 1000);

  const textFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVideoId(event.target.value);
  };

  const playerReady = (e: { target: Player }) => {
    setPlayer(e.target);
  };
  const throwButtonClick = () => {
    if (!player) return;
    player.pauseVideo();
    setThrows(
      throws.concat({ timestamp: player.getCurrentTime(), x: 0, y: 0 })
    );
  };

  const resumeButtonClick = () => {
    if (!player) return;
    player.playVideo();
  };

  const fieldClick = (e: MouseEvent) => {
    const rect = (e.target as HTMLImageElement).getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    if (!player) return;
    player.pauseVideo();
    setThrows(throws.concat({ timestamp: player.getCurrentTime(), x, y }));
  };

  const throwHasHappened = (t: ThrowEvent) => {
    return true; //t.timestamp >= timeStamp;
  };

  return (
    <div className={classes.rowContainer}>
      <div className={classes.colContainer}>
        <TextField
          id="outlined-basic"
          color={contrastingThemeColor}
          label="VideoId"
          variant="outlined"
          value={videoId}
          onChange={textFieldChange}
        />
        <Button
          className={classes.throwButton}
          variant="contained"
          color={contrastingThemeColor}
          onClick={() => throwButtonClick()}
        >
          Throw
        </Button>
        <Button
          className={classes.throwButton}
          variant="contained"
          color={contrastingThemeColor}
          onClick={() => resumeButtonClick()}
        >
          Resume
        </Button>
      </div>
      <div className={classes.colContainer}>
        <div>
          <YouTube
            videoId={videoId}
            onReady={(e) => playerReady(e)}
            opts={VIDEO_OPTS}
          />
          {formatTimeStamp(timeStamp)}
        </div>
        <div
          style={{
            width: IMG_FIELD_WIDTH,
            height: IMG_FIELD_HEIGHT,
          }}
          className={classes.fieldMap}
          onClick={(e: any) => fieldClick(e)}
        >
          <img
            alt="Map of an ultimate field."
            style={{ position: "absolute" }}
            className={classes.fieldMap}
            src="/IP Field Map.png"
            onClick={(e: any) => fieldClick(e)}
            width={IMG_FIELD_WIDTH}
            height={IMG_FIELD_HEIGHT}
          />
          {throws.filter(throwHasHappened).map((t) => (
            <div
              key={t.timestamp}
              className={classes.throwMarker}
              style={{ left: t.x, top: t.y }}
            >
              O
            </div>
          ))}
        </div>

        <div className={classes.rowContainer}>
          Throws:
          <div>
            {throws.filter(throwHasHappened).map((t) => (
              <div key={t.timestamp}>
                <b>{formatTimeStamp(t.timestamp)}</b> x: {t.x} y: {t.y}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function formatTimeStamp(timeStamp: number) {
  const hours = Math.floor(timeStamp / 3600);
  const minutes = Math.floor((timeStamp - hours * 3600) / 60);
  const seconds = Math.floor(timeStamp - hours * 3600 - minutes * 60);
  return `${hours}:${minutes}:${seconds}`;
}
