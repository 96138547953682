export function printableCatName(cat: string) {
  if (cat === "FG_PCT") return "FG%";
  if (cat === "FG3_PCT") return "FG3%";
  if (cat === "FT_PCT") return "FT%";
  if (cat === "PLAYER_HEIGHT_INCHES") return "HEIGHT";
  if (cat === "PLAYER_WEIGHT") return "WEIGHT";
  if (cat === "USG_PCT") return "USG%";
  if (cat === "AST_PCT") return "AST%";
  if (cat === "REB_PCT") return "REB%";
  if (cat === "OREB_PCT") return "OREB%";
  if (cat === "DREB_PCT") return "DREB%";
  if (cat === "TM_TOV_PCT") return "TM TOV%";
  if (cat === "FG_PCT_PAINT") return "Paint FG%";
  if (cat === "PCT_IN_PAINT") return "% Paint";
  if (cat === "FG_PCT_MR") return "Midrange FG%";
  if (cat === "PCT_IN_MR") return "% Midrange";
  if (cat === "FG_PCT_AB3") return "ATB3 FG%";
  if (cat === "PCT_IN_AB3") return "% ATB3";
  if (cat === "FG_PCT_C3") return "Corner3 FG%";
  if (cat === "PCT_IN_C3") return "% Corner3";
  return cat;
}

export function printableCatValue(cat: string, val: number) {
  if (cat === "PLAYER_HEIGHT_INCHES")
    return `${Math.floor(val / 12)}'${val % 12}"`;
  if (cat.indexOf("PCT") > -1) {
    return (val * 100).toFixed(1);
  }
  return val;
}

export function formatSalary(salary: number) {
  return "$" + salary.toLocaleString();
}
