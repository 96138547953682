import React from "react";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(() =>
  createStyles({
    picker: {
      width: 300,
    },
  })
);

// TODO(chrisbu): Can we provide typing for this props arg? It should be the default
// autocomplete props + a label string.
export function IPAutocomplete(props: any) {
  const theme = useTheme();
  // Pick either primary or secondary to give most contrast against current mode.
  const contrastingThemeColor =
    theme.palette.type === "light" ? "primary" : "secondary";

  const classes = useStyles();

  return (
    <Autocomplete
      options={props.options}
      getOptionLabel={props.getOptionLabel}
      value={props.value}
      className={classes.picker}
      onChange={(_, newValue) => {
        props.onChange(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          variant="outlined"
          color={contrastingThemeColor}
        />
      )}
    />
  );
}
