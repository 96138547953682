export const COUNTING_STATS = [
  "PTS",
  "REB",
  "AST",
  "STL",
  "BLK",
  "TOV",
  "FG_PCT",
  "FGA",
  "FG3_PCT",
  "FG3A",
  "FT_PCT",
  "FTA",
  "MIN",
];

export const ADVANCED_STATS = [
  "USG_PCT",
  "AST_PCT",
  "REB_PCT",
  "OREB_PCT",
  "DREB_PCT",
];

export const SHOOTING_STATS = [
  "FG_PCT_PAINT",
  "FG_PCT_MR",
  "FG_PCT_AB3",
  "FG_PCT_C3",
  "PCT_IN_PAINT",
  "PCT_IN_MR",
  "PCT_IN_AB3",
  "PCT_IN_C3",
];

export const VITAL_STATS = ["PLAYER_HEIGHT_INCHES", "PLAYER_WEIGHT", "AGE"];
