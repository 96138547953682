import {DATA} from "./data";

const DATE_WEIGHTS: Record<number, number> = {
    1: .5, 
    2: 0.527385,
    3: 0.5562698765,
    4: 0.5867367776,
    5: 0.6188723509,
    6: 0.6527679895,
    7: 0.6885200923,
    8: 0.7262303378,
    9: 0.7660059734,
    10: 0.8079601206,
    11: 0.8522120964,
    12: 0.8988877529,
    13: 0.9481198351,
    14: 1.000048358,
};

export interface Game {
    date: string;
    tournament: string;
    tournamentId: string;
    teamId: string;
    oppId: string;
    opp: string;
    team: string;
    score: number;
    oppScore: number;
    gameId: string;
}

type Ratings = Record<string, number[]>;

function ratingForGame(game: Game) {
    // Wrap the denonminator for R in Max(, 1) to make sure we arean't dividing by 0. This is not actually
    // mentioned in the algorithm description.
    const r = Math.min(game.score, game.oppScore) / Math.max((Math.max(game.score, game.oppScore) - 1), 1);
    return 125 + 475 * (Math.sin(Math.min(1, (1-r)/.5)*.4*Math.PI)/(Math.sin(.4*Math.PI)));
}

// Return blow outs sorted by which game is worth the most points to you, descending.
function sortBlowOuts(a: Game, b: Game, iteration: number, ratings: Ratings) {
    const aRating = (ratings[a.oppId][iteration] + ratingForGame(a));
    const bRating = (ratings[b.oppId][iteration] + ratingForGame(b));

    return aRating > bRating ?  -1 : 1;
}

// Sorts by ranking at iteration, descending.
function sortByRanking(a: string, b: string, iteration: number, ratings: Ratings) {
    return ratings[a][iteration] > ratings[b][iteration] ? -1 : 1;
}

// Debugging Function, prints a similar result to frisbee-rankings.com
function printSummary(teamId: string, iteration: number, ratings: Ratings, games: Game[], ignoredBlowouts: Set<string>[]) {
  console.log(" ");
  console.log(`Summary for ${games[0].team}(${ratings[teamId][iteration].toFixed(2)}) at iteration ${iteration}.`)
  const weightDenominator = games.filter(g => !ignoredBlowouts[iteration].has(g.gameId)).map(g => getDateWeight(g) * getScoreWeight(g)).reduce((cur, next) => cur + next, 0);
  for (const game of games) {
    const oppRating = ratings[game.oppId][iteration];
    const ratingChange = (oppRating + (game.score > game.oppScore? 1 : -1) * ratingForGame(game))
    const percent = (((getDateWeight(game) * getScoreWeight(game))/weightDenominator)*100).toFixed(4) + "%";
    const ignored = ignoredBlowouts[iteration].has(game.gameId)
    console.log(`${ignored ? '[IGNORED]': ''} ${game.score}-${game.oppScore} (${(game.score > game.oppScore? "+" : "-")}${Math.round(ratingForGame(game))}) vs ${game.opp}(${ratings[game.oppId][iteration].toFixed(2)}) on ${game.date} (${percent}) for ${ratingChange.toFixed(2)}`);
  }
  console.log(" ");
}

// The score weight of a game will be 1.0 if the winning score is at least 13, or if the total score is at least 19. 
// Games with a winning score below 13 and a total score below 19 will get lower score weights. Precisely, the score
// weight, where W and L denote the winning and losing scores, will be:
function getScoreWeight(game: Game) {
    const w = Math.max(game.score, game.oppScore);
    const l = Math.min(game.score, game.oppScore);
    if (w >= 13 || (w + l) >= 19) {
        return 1;
    }
    const weight = Math.sqrt((w + Math.max(l, Math.floor((w - 1)/ 2)))/ 19);
    return weight;
}

function getDateWeek(game: Game) {
    const referenceDate = new Date("June 4");
    const date = new Date(game.date);

    const msInWeek = 1000 * 60 * 60 * 24 * 7;
    const week =  Math.round(((date as any) - (referenceDate as any)) / msInWeek) + 1;
    return week;  
}

function getDateWeight(game: Game) {
    const weekNum = getDateWeek(game);
    return DATE_WEIGHTS[weekNum];
}

// Finally, if a team is rated more than 600 points higher than its opponent, and wins with a score that is more than
// twice the losing score plus one, the game is ignored for ratings purposes. However, this is only done if the winning
// team has at least N other results that are not being ignored, where N=5.
function isBlowOut(game: Game, iteration: number, ratings: Ratings) {
    // return false;
    const rating = ratings[game.teamId][iteration];
    const oppRating = ratings[game.oppId][iteration];
    // If team blew opp out.
    if (game.score > ((game.oppScore * 2) + 1) && (rating > (oppRating + 600))) {
        return true;
    }
    return false;
}

export function run(iterationCount: number, data: Record<string, any[]> = DATA){
    const startTime = Date.now();

    // TeamIds /w 0 game teams removed or only games pre season.
    const validTeamIds = Object.keys(data).filter(key => data[key].filter(g => getDateWeek(g) > 0).length > 0);

    // A copy of data but with invalid games (things like W-L) removed.
    // Also remove games where one team is not in the validTeamIds list.
    // This occurs when a club team plays against college teams for example.
    // Those college teams don't have ratings and don't attend other tournaments
    // so those games should just be dropped.
    const validGames: Record<string, Game[]> = {};
    for (const teamId of validTeamIds) {
        validGames[teamId] = data[teamId].filter(game => !(isNaN(game.score) || isNaN(game.oppScore)))
        .filter(game => game.score !== game.oppScore)
        .filter(game => getDateWeek(game) > 0)
        .filter(game => validTeamIds.includes(game.oppId))
        .map(game => {
            return {
                date: game.date,
                tournament: game.tournament,
                tournamentId: game.tournamentId,
                gameId: game.gameId,
                teamId: teamId,
                oppId: game.oppId,
                opp: game.opp,
                team: game.team,
                score: parseInt(game.score.toString()),
                oppScore: parseInt(game.oppScore.toString()),
            }});
    }

    // Mapping of team id to an array of their rating at each iteration.
    const ratings: Ratings = {};
    // Set of blowout games to be ignored at each iteration.
    const ignoredBlowouts: Set<string>[] = [];; 

    for (const teamId of validTeamIds) {
        // Everyone starts at 1000.
        ratings[teamId] = [1000];
    }

    for (let i = 0; i < iterationCount; i++) {
        ignoredBlowouts.push(new Set());
        const sortedByRanking = validTeamIds.sort((a, b) => sortByRanking(a, b, i, ratings))
        for (const teamId of sortedByRanking) {
            const nonIgnoredGames = validGames[teamId].filter(g => !ignoredBlowouts[i].has(g.gameId));
            // Find games that are eligible for being ignored.
            const blowoutEligible = nonIgnoredGames.filter(g => isBlowOut(g, i, ratings)).sort((a, b) => sortBlowOuts(a, b, i, ratings));
            const numBlowoutEligibleToCount = Math.max(5 - (nonIgnoredGames.length - blowoutEligible.length), 0);
            
            const newBlowouts = blowoutEligible.slice(numBlowoutEligibleToCount).map(g => g.gameId);
            for (const nb of newBlowouts) {
                ignoredBlowouts[i].add(nb);
            }

            // Now that blowouts for this team and iteration have been added to set, re-get the games to count.
            const gamesToCount = validGames[teamId].filter(g => !ignoredBlowouts[i].has(g.gameId));

            const denominator = gamesToCount.map(g => getDateWeight(g) * getScoreWeight(g)).reduce((cur, next) => cur + next, 0);
            let numerator = 0;
            for (const g of gamesToCount) {
                const win = g.score > g.oppScore;
                numerator =  numerator +  ((ratings[g.oppId][i] + (win ? 1 : -1) * ratingForGame(g)) * getDateWeight(g) * getScoreWeight(g));
            }
            ratings[teamId].push(numerator / denominator);
        }
    }

    const sortedTeamIds = validTeamIds.sort((a, b) => sortByRanking(a, b, iterationCount, ratings));

    let i = 0;
    let tableRankings = [];
    for (const teamId of sortedTeamIds) {
        i++;
        console.log(`${i}. ${validGames[teamId][0].team} ${ratings[teamId][iterationCount].toFixed(2)}`);
        tableRankings.push({
            ranking: i,
            team: validGames[teamId][0].team,
            teamID: teamId,
            wins: validGames[teamId].filter((g) => g.score > g.oppScore).length,
            losses: validGames[teamId].filter((g) => g.score < g.oppScore).length,
            rating: ratings[teamId][iterationCount].toFixed(2)
        });
        if (validGames[teamId][0].team === "Vault") {
          printSummary(teamId, iterationCount- 1, ratings, validGames[teamId], ignoredBlowouts)
        }
    }

    console.log(`Ran ${iterationCount} iterations in ${Date.now() - startTime}ms.`);
    // return ratings;
    return tableRankings;
}