import React from "react";
import { Container } from "@material-ui/core";
import { useParams, Link } from "react-router-dom";

import { DATA } from "./data";

export function Team() {
  const { id } = useParams<{ id: string }>();
  const games = DATA[id];
  const name = games[0].team;
  const wins = games.filter((g) => parseInt(g.score) > parseInt(g.oppScore));
  const losses = games.filter((g) => parseInt(g.score) < parseInt(g.oppScore));

  const tournaments = [...new Set(games.map((g) => g.tournament))];

  return (
    <Container>
      <h1>
        <a
          rel="noreferrer"
          target={"_blank"}
          href={`https://play.usaultimate.org/teams/events/Eventteam/?TeamId=${id}`}
        >
          {name} ({wins.length} - {losses.length})
        </a>
      </h1>
      {tournaments.map((t) => {
        const tournamentGames = games.filter((g) => g.tournament === t);

        return (
          <div>
            <h2>
              <Link to={`/usau/tournament/${tournamentGames[0].tournamentId}`}>
                {t}
              </Link>
            </h2>
            {tournamentGames.map((tg) => {
              return (
                <div>
                  <span>
                    {tg.score} - {tg.oppScore} vs{" "}
                  </span>
                  <Link to={`/usau/team/${tg.oppId}`}>{tg.opp}</Link>{" "}
                  <span>({tg.date})</span>
                </div>
              );
            })}
          </div>
        );
      })}
    </Container>
  );
}
