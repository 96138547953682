import {Game} from './types';

/** Some fake data to use before we actually have data in firestore. */
export const ringPony2018: Game = {
    id: 'coQl9xza3HQ',
    team1: 'Ring of Fire',
    team2: 'Pony',
    points: [
        {
            timestampStart: 296, // TODO(chrisbu): Fix this.
            team1Score: 0,
            team2Score: 0,
            possessions: [
                {
                    timestampStart: 296,
                    timestampEnd: 379,
                    team: 'Pony',
                    result: 'goal'
                }]
        },
        {
            timestampStart: 469, // TODO(chrisbu): Fix this.
            team1Score: 0,
            team2Score: 1,
            possessions: [
                {
                    timestampStart: 469,
                    timestampEnd: 503,
                    team: 'Ring of Fire',
                    result: 'goal'
                }]
        },
        {
            timestampStart: 588,
            team1Score: 1,
            team2Score: 1,
            possessions: [
                {
                    timestampStart: 595,
                    timestampEnd: 613,
                    team: 'Pony',
                    result: 'goal'
                }]
        },
        {
            timestampStart: 704,
            team1Score: 1,
            team2Score: 2,
            possessions: [
                {
                    timestampStart: 711,
                    timestampEnd: 743,
                    team: 'Ring of Fire',
                    result: 'goal'
                }]
        },
        {
            timestampStart: 824,
            team1Score: 2,
            team2Score: 2,
            possessions: [
                {
                    timestampStart: 832,
                    timestampEnd: 868,
                    team: 'Pony',
                    result: 'goal'
                }]
        },
        {
            timestampStart: 952,
            team1Score: 2,
            team2Score: 3,
            possessions: [
                {
                    timestampStart: 976,
                    timestampEnd: 1003,
                    team: 'Ring of Fire',
                    result: 'goal',
                }]
        },
        {
            timestampStart: 1128,
            team1Score: 3,
            team2Score: 3,
            possessions: [
                {
                    timestampStart: 1136,
                    timestampEnd: 1202,
                    team: 'Pony',
                    result: 'goal',
                }]
        },
        {
            timestampStart: 1289,
            team1Score: 3,
            team2Score: 4,
            possessions: [
                {
                    timestampStart: 1298,
                    timestampEnd: 1308,
                    team: 'Ring of Fire',
                    result: 'goal',
                }]
        },
        {
            timestampStart: 1387,
            team1Score: 4,
            team2Score: 4,
            possessions: [
                {
                    timestampStart: 1397,
                    timestampEnd: 1471,
                    team: 'Pony',
                    result: 'goal',
                }]
        },
        {
            timestampStart: 1557,
            team1Score: 4,
            team2Score: 5,
            possessions: [
                {
                    timestampStart: 1565,
                    timestampEnd: 1588,
                    team: 'Ring of Fire',
                    result: 'goal',
                }]
        },
        {
            timestampStart: 1667,
            team1Score: 5,
            team2Score: 5,
            possessions: [
                {
                    timestampStart: 1695,
                    timestampEnd: 1711,
                    team: 'Pony',
                    result: 'goal',
                }]
        },
        {
            timestampStart: 1858,
            team1Score: 5,
            team2Score: 6,
            possessions: [
                {
                    timestampStart: 1865,
                    timestampEnd: 1879,
                    team: 'Ring of Fire',
                    result: 'turnover',
                },
                {
                    timestampStart: 1911,
                    timestampEnd: 2123,
                    team: 'Pony',
                    result: 'turnover',
                },
                {
                    timestampStart: 2232,
                    timestampEnd: 2262,
                    team: 'Ring of Fire',
                    result: 'goal',
                },
            ]
        }
    ]
};

