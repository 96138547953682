import React from "react";

export function Wowy() {
  return <div>Chris needs to fix this page!</div>;
}
// import React, { useEffect, useState } from "react";
// import { useParams, useHistory } from "react-router-dom";
// import { collection } from "firebase/firestore";
// import { useFirestore, useFirestoreCollectionData } from "reactfire";
// import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";

// import CircularProgress from "@material-ui/core/CircularProgress";
// import Button from "@material-ui/core/Button";
// import Checkbox from "@material-ui/core/Checkbox";
// import Slider from "@material-ui/core/Slider";
// import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
// import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
// import Paper from "@material-ui/core/Paper";
// import { IPAutocomplete } from "../common/ipautocomplete";

// import {
//   Cell,
//   Dot,
//   ScatterChart,
//   Scatter,
//   CartesianGrid,
//   Tooltip,
//   Label,
//   ReferenceLine,
//   ResponsiveContainer,
//   XAxis,
//   YAxis,
//   ZAxis,
// } from "recharts";

// interface WowyData {
//   team_obj: TeamData;
//   leagueOrtg: number;
// }

// interface TeamData {
//   id: number;
//   name: string;
//   players: string[];
//   player_data: { [key: string]: { [key: string]: PlayerData } };
// }

// interface PlayerData {
//   with: { ortg: number; drtg: number; min: number };
//   without: { ortg: number; drtg: number; min: number };
// }

// interface WowyResult {
//   name: string;
//   wowy: Array<{ with: boolean; o: number; d: number; min: number }>;
// }

// const DEFAULT_TEAM_ID = "1610612737";

// const useStyles = makeStyles(() =>
//   createStyles({
//     container: {
//       margin: "10px",
//     },
//     pickerContainer: {
//       gap: "10px",
//       display: "flex",
//       flexWrap: "wrap",
//     },
//     centerContent: {
//       textAlign: "center",
//       width: "70%",
//       marginLeft: "auto",
//       marginRight: "auto",
//       marginTop: 10,
//       marginBottom: 10,
//     },
//     minSlider: {
//       marginLeft: 20,
//       marginRight: 20,
//       display: "inline-block",
//       width: 300,
//     },
//     teammatesButton: {
//       height: 56,
//     },
//     playerCheckboxContainer: {
//       display: "flex",
//       flexWrap: "wrap",
//     },
//     chartContainer: {
//       display: "flex",
//     },
//     tooltip: {
//       padding: 10,
//     },
//   })
// );

// export function Wowy() {
//   const theme = useTheme();
//   // Pick either primary or secondary to give most contrast against current mode.
//   const contrastingThemeColor =
//     theme.palette.type === "light" ? "primary" : "secondary";
//   const referenceLineColor = theme.palette.secondary.main;
//   const lineColor =
//     theme.palette.type === "light"
//       ? theme.palette.secondary.contrastText
//       : theme.palette.primary.contrastText;

//   const classes = useStyles();
//   const { id, playerId } = useParams<{ id: string; playerId: string }>();
//   const history = useHistory();
//   const [team, setTeam] = useState<TeamData | null>(null);
//   const [players, setPlayers] = useState<string[]>([]);
//   const [player, setPlayer] = useState<string | null>(null);
//   const [results, setResults] = useState<WowyResult[]>([]);
//   const [teammateCheckboxes, setTeammateCheckboxes] = useState<{
//     [key: string]: boolean;
//   }>({});
//   const [leagueOrtg, setLeagueOrtg] = useState<number>(0);
//   const [minSliderValue, setMinSliderValue] = useState<number>(0);
//   const [showTeammates, setShowTeammates] = useState<boolean>(false);

//   const collectionRef = collection(useFirestore(), "wowy");
//   const { status, data } = useFirestoreCollectionData(collectionRef);

//   useEffect(() => {
//     // If there is no data there is nothing to do.
//     if (!data) return;
//     // If we are missing team and/or player ids use defaults.
//     if (!id) {
//       history.push("/wowy/" + DEFAULT_TEAM_ID + "/0");
//       return;
//     } else if (playerId == null) {
//       history.push("/wowy/" + id + "/0");
//       return;
//     }

//     let leagueRatingFlag = false;
//     const teams = [];
//     for (const d of data as unknown as WowyData[]) {
//       if (d.team_obj) {
//         teams.push(d.team_obj);
//       }
//       if (!leagueRatingFlag && d.leagueOrtg) {
//         setLeagueOrtg(d.leagueOrtg);
//         leagueRatingFlag = true;
//       }
//     }

//     const team = teams.find((t) => t.id === parseInt(id)) || null;
//     setTeam(team);
//     if (team) {
//       const players = team.players.sort();
//       setPlayer(players[parseInt(playerId)]);
//       setPlayers(players);
//       setMinSliderValue(0);
//     }
//   }, [data, id, playerId, history]);

//   function updateTeam(team: TeamData | null) {
//     if (!team) return;
//     history.push("/wowy/" + team.id);
//   }

//   function updatePlayer(player: string | null) {
//     if (!team || !player || !players) return;
//     history.push("/wowy/" + team.id + "/" + players.indexOf(player));
//   }

//   useEffect(() => {
//     const arr = [];
//     if (!player || !team) return;
//     const teammateCheckboxes: { [key: string]: boolean } = {};

//     const teammates = players.filter((p) => p !== player);
//     for (const teammate of teammates) {
//       teammateCheckboxes[teammate] = true;
//       const wowy = [];
//       const oWithout = team.player_data[player][teammate].without.ortg;
//       const dWithout = team.player_data[player][teammate].without.drtg;
//       const minWithout = team.player_data[player][teammate].without.min;
//       if (!(oWithout === 0 && dWithout === 0)) {
//         wowy.push({ o: oWithout, d: dWithout, with: false, min: minWithout });
//       }

//       const oWith = team.player_data[player][teammate].with.ortg;
//       const dWith = team.player_data[player][teammate].with.drtg;
//       const minWith = team.player_data[player][teammate].with.min;
//       if (!(oWith === 0 && dWith === 0)) {
//         wowy.push({ o: oWith, d: dWith, with: true, min: minWith });
//       }

//       arr.push({
//         name: teammate,
//         wowy,
//       });
//     }
//     setTeammateCheckboxes(teammateCheckboxes);
//     setResults(arr);
//   }, [player, players, team]);

//   function meetsMinThreshhold(result: WowyResult) {
//     for (const w of result.wowy) {
//       if (w.with) {
//         return w.min >= minSliderValue;
//       }
//     }
//     return 0 >= minSliderValue;
//   }

//   const maxMinSliderValue = Math.max(
//     ...results
//       .map((r: WowyResult) => r.wowy)
//       .flat()
//       .filter((w) => w.with)
//       .map((w) => w.min)
//   );

//   const marks = [0, 0.2, 0.4, 0.6, 0.8, 1].map((v) => {
//     const val = Math.round(v * maxMinSliderValue);
//     return { value: val, label: val };
//   });

//   const displayedResults = results
//     .filter(meetsMinThreshhold)
//     .filter((r: WowyResult) => teammateCheckboxes[r.name]);

//   const os = displayedResults
//     .map((r: WowyResult) => r.wowy)
//     .flat()
//     .map((r) => r.o);
//   const ds = displayedResults
//     .map((r: WowyResult) => r.wowy)
//     .flat()
//     .map((r) => r.d);

//   const minX = Math.max(0, Math.round(Math.min(...ds) - 10));
//   const maxX = Math.round(Math.max(...ds) + 10);
//   const minY = Math.max(0, Math.round(Math.min(...os) - 10));
//   const maxY = Math.round(Math.max(...os) + 10);
//   const maxMin = Math.max(
//     ...displayedResults
//       .map((r: WowyResult) => r.wowy)
//       .flat()
//       .map((r) => r.min)
//   );

//   const DotWithText = (props: any) => {
//     const { cx, cy, fill, name, size } = props;
//     const r = 4 * (size / maxMin) + 2;
//     return (
//       <g>
//         <Dot cx={cx} cy={cy} r={r} fill={fill} />
//         <g transform={`translate(${cx},${cy})`}>
//           <text x={10} y={0} dy={4} textAnchor="left" fill={lineColor}>
//             {name}
//           </text>
//         </g>
//       </g>
//     );
//   };

//   function toggleTeammate(name: string) {
//     teammateCheckboxes[name] = !teammateCheckboxes[name];
//     setTeammateCheckboxes({ ...teammateCheckboxes });
//   }

//   function minSliderChange(event: {}, newValue: number | number[]) {
//     if (typeof newValue === "number") {
//       setMinSliderValue(newValue);
//     }
//   }

//   const CustomTooltip = ({ active, payload }: any) => {
//     if (active && payload) {
//       const data = payload[0].payload as {
//         d: number;
//         o: number;
//         min: number;
//         name: string;
//         with: boolean;
//       };
//       let oRatingColor,
//         dRatingColor,
//         netRatingColor = undefined;

//       const oppositeResult = results
//         .find((r) => r.name === data.name)
//         ?.wowy.find((w) => w.with !== data.with);
//       if (oppositeResult) {
//         if (data.o > oppositeResult.o) {
//           oRatingColor = "green";
//         } else if (data.o < oppositeResult.o) {
//           oRatingColor = "red";
//         }
//         if (data.d < oppositeResult.d) {
//           dRatingColor = "green";
//         } else if (data.d > oppositeResult.d) {
//           dRatingColor = "red";
//         }
//         if (data.o - data.d > oppositeResult.o - oppositeResult.d) {
//           netRatingColor = "green";
//         } else if (data.o - data.d < oppositeResult.o - oppositeResult.d) {
//           netRatingColor = "red";
//         }
//       }
//       return (
//         <Paper className={classes.tooltip}>
//           <div>
//             <b>
//               {data.name +
//                 " " +
//                 (data.with ? "with" : "without") +
//                 " " +
//                 player}
//             </b>
//           </div>
//           <div>
//             Net Rating: {(data.o - data.d).toFixed(1)}{" "}
//             {oppositeResult && (
//               <span style={{ color: netRatingColor }}>
//                 <b>
//                   (
//                   {(
//                     data.o -
//                     data.d -
//                     (oppositeResult.o - oppositeResult.d)
//                   ).toFixed(1)}
//                   )
//                 </b>
//               </span>
//             )}
//           </div>
//           <div>
//             Off Rating: {data.o.toFixed(1)}{" "}
//             {oppositeResult && (
//               <span style={{ color: oRatingColor }}>
//                 <b>({(data.o - oppositeResult.o).toFixed(1)})</b>
//               </span>
//             )}
//           </div>
//           <div>
//             Def Rating: {data.d.toFixed(1)}{" "}
//             {oppositeResult && (
//               <span style={{ color: dRatingColor }}>
//                 <b>({(oppositeResult.d - data.d).toFixed(1)})</b>
//               </span>
//             )}
//           </div>
//           <div>Minutes: {data.min.toFixed(1)}</div>
//         </Paper>
//       );
//     }
//     return null;
//   };

//   return status === "loading" ? (
//     <div className={classes.centerContent}>
//       <CircularProgress />
//     </div>
//   ) : (
//     <div className={classes.container}>
//       <div className={classes.pickerContainer}>
//         <IPAutocomplete
//           options={(data as unknown as WowyData[])
//             .filter((d) => d.team_obj)
//             .map((d) => d.team_obj)}
//           getOptionLabel={(option: TeamData) => option.name}
//           value={team}
//           onChange={updateTeam}
//           label="Team"
//         />
//         <IPAutocomplete
//           options={players}
//           getOptionLabel={(option: string) => option}
//           value={player}
//           onChange={updatePlayer}
//           label="Player"
//         />
//         <Button
//           className={classes.teammatesButton}
//           variant="contained"
//           color={contrastingThemeColor}
//           endIcon={showTeammates ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
//           onClick={() => setShowTeammates(!showTeammates)}
//         >
//           Toggle Teammates
//         </Button>
//         {maxMin > 0 && (
//           <div className={classes.minSlider}>
//             Min minutes together
//             <Slider
//               value={minSliderValue}
//               onChange={minSliderChange}
//               min={0}
//               max={maxMinSliderValue}
//               color={contrastingThemeColor}
//               marks={marks}
//             ></Slider>
//           </div>
//         )}
//       </div>
//       {showTeammates && (
//         <div className={classes.playerCheckboxContainer}>
//           {results.map((result: WowyResult, index: number) => (
//             <div key={`checkbox-${index}`}>
//               <Checkbox
//                 id={result.name}
//                 checked={teammateCheckboxes[result.name]}
//                 onChange={(e) => toggleTeammate(result.name)}
//                 disabled={!meetsMinThreshhold(result)}
//                 color={contrastingThemeColor}
//               />
//               {result.name + " (" + netRatingChangeStr(result) + ")"}
//             </div>
//           ))}
//         </div>
//       )}
//       <div
//         className={classes.chartContainer}
//         style={{ width: "100%", height: 700 }}
//       >
//         <ResponsiveContainer>
//           <ScatterChart
//             width={1400}
//             height={700}
//             margin={{ top: 20, right: 20, bottom: 10, left: 10 }}
//           >
//             <CartesianGrid strokeDasharray="3 3" />
//             <ReferenceLine
//               y={leagueOrtg}
//               stroke={referenceLineColor}
//               strokeDasharray="3 3"
//               strokeWidth="2"
//             />
//             <ReferenceLine
//               x={leagueOrtg}
//               stroke={referenceLineColor}
//               strokeDasharray="3 3"
//               strokeWidth="2"
//             />
//             <XAxis
//               dataKey="d"
//               name="D Rating"
//               type="number"
//               reversed={true}
//               domain={[minX, maxX]}
//               allowDecimals={false}
//             >
//               <Label
//                 value="D Rating"
//                 offset={0}
//                 position="insideBottom"
//                 fill={lineColor}
//               />
//             </XAxis>
//             <YAxis
//               dataKey="o"
//               name="O Rating"
//               type="number"
//               domain={[minY, maxY]}
//               allowDecimals={false}
//             >
//               <Label value="O Rating" offset={0} angle={-90} fill={lineColor} />
//             </YAxis>
//             <ZAxis
//               type="number"
//               dataKey="min"
//               name="Min"
//               range={[10, maxMin + 10]}
//             />
//             <Tooltip
//               cursor={{ strokeDasharray: "3 3" }}
//               content={<CustomTooltip />}
//             />
//             {displayedResults
//               .filter(meetsMinThreshhold)
//               .map((result: WowyResult, index: number) => (
//                 <Scatter
//                   key={`scatter-${index}`}
//                   line={true}
//                   name={result.name}
//                   data={result.wowy.map((w) =>
//                     Object.assign({ name: result.name }, w)
//                   )}
//                   fill={lineColor}
//                   shape={<DotWithText />}
//                 >
//                   {(
//                     result.wowy as Array<{
//                       with: boolean;
//                       o: number;
//                       d: number;
//                     }>
//                   ).map((entry, index: number) => {
//                     return (
//                       <Cell
//                         key={`cell-${index}`}
//                         fill={entry.with ? "green" : "red"}
//                       />
//                     );
//                   })}
//                 </Scatter>
//               ))}
//           </ScatterChart>
//         </ResponsiveContainer>
//       </div>
//     </div>
//   );
// }

// function netRatingChangeStr(result: WowyResult) {
//   const resultWith = result.wowy.find((w) => w.with);
//   const netRatingWith = resultWith ? resultWith.o - resultWith.d : 0;

//   const resultWithout = result.wowy.find((w) => w.with === false);
//   const netRatingWithout = resultWithout
//     ? resultWithout.o - resultWithout.d
//     : 0;

//   const netRatingDiff = netRatingWith - netRatingWithout;
//   const prefix = netRatingDiff > 0 ? "+" : "";
//   return prefix + netRatingDiff.toFixed(1);
// }
