import { useState } from "react";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";

import { IconButton } from "@material-ui/core";
import { PlayCircleFilledOutlined } from "@material-ui/icons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import Paper from "@material-ui/core/Paper";
import YouTube from "react-youtube";

import { IPAutocomplete } from "../common/ipautocomplete";
import { ringPony2018 } from "./fakeData";
import {
  Game,
  Point,
  Possession,
  getReceivingTeam,
  getPointEndTimeStamp,
  getPointResult,
} from "./types";

interface Player {
  getCurrentTime(): number;
  pauseVideo(): void;
  playVideo(): void;
  cueVideoById(cueVideoOpts: {
    videoId: String;
    startSeconds: Number;
    endSeconds?: Number;
  }): void;
  stopVideo(): void;
}

const ALL_CATEGORIES = ["Start time", "End time", "Offense Team", "Result"];

const VIDEO_OPTS = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    controls: 0 as 0 | 2 | 1,
    modestbranding: 1 as 1 | undefined,
    origin: "http://localhost:3000",
  },
};

const useStyles = makeStyles(() =>
  createStyles({
    filterContainer: {
      display: "flex",
    },
    playingRow: {
      outline: "1px solid yellow",
    },
    videoContainer: {
      backgroundColor: "black",
      height: "390px",
      textAlign: "center",
    },
  })
);

export function Explorer() {
  const theme = useTheme();
  const outlineColor =
    theme.palette.type === "light"
      ? theme.palette.primary.main
      : theme.palette.secondary.main;

  const classes = useStyles();
  const games: Game[] = [ringPony2018];
  const teams = [games[0].team1, games[0].team2];
  const allPoints = games.reduce(
    (accumulator, curr) => accumulator.concat(curr.points),
    [] as Point[]
  );
  const allPoss = allPoints.reduce(
    (accumulator, curr) => accumulator.concat(curr.possessions),
    [] as Possession[]
  );

  const [offenseTeam, setOffenseTeam] = useState<null | string>(null);

  const applyPointFilters = (point: Point) => {
    return offenseTeam === null || point.possessions[0].team === offenseTeam;
  };

  const applyPossFilters = (poss: Possession) => {
    return offenseTeam === null || poss.team === offenseTeam;
  };

  const points: Point[] = allPoints.filter(applyPointFilters);
  const possessions: Possession[] = allPoss.filter(applyPossFilters);
  const [grouping, setGrouping] = useState<"points" | "possessions">("points");
  const [player, setPlayer] = useState<null | Player>(null);
  const [clipIdx, setClipIdx] = useState<null | number>(null);
  const [prevPlayerState, setPrevPlayerState] = useState<number>(-1);

  const clips =
    grouping === "points"
      ? points.map((p) => {
          return {
            timestampStart: p.timestampStart,
            timestampEnd: getPointEndTimeStamp(p),
          };
        })
      : possessions;

  const playerReady = (e: { target: Player }) => {
    setPlayer(e.target);
    // Uncomment to control player in debugger.
    (window as any).player = e.target;
  };

  const playerStateChange = (e: { data: number }) => {
    if (!player || clipIdx === null) return;
    setPrevPlayerState(e.data);

    if (e.data === 0) {
      console.log("FINISH PLAYING");
      // If we get a FINISH update after a NOT PLAYING don't do anything else.
      if (prevPlayerState === -1) return;
      const newIdx = clipIdx + 1 >= clips.length ? 0 : clipIdx + 1;
      setClipIdx(newIdx);
      const nextClip = clips[newIdx];
      player.cueVideoById({
        videoId: games[0].id,
        startSeconds: nextClip.timestampStart,
        endSeconds: nextClip.timestampEnd,
      });
    } else if (e.data === -1) {
      console.log("NOT PLAYING");
    } else if (e.data === 3) {
      console.log("BUFFERING");
    } else if (e.data === 5) {
      console.log("CUED VIDEO");
      console.log("ATTEMPT TO PLAY!");
      player.playVideo();
    } else {
      console.log(e.data);
    }
  };

  // Make sure that when filters change we get the clipIdx and player into a good state.
  const cleanupOnFilterChange = () => {
    if (!player) return;
    player.stopVideo();
    setClipIdx(null);
  };

  const onGroupingChange = (val: "possessions" | "points") => {
    setGrouping(val);
    cleanupOnFilterChange();
  };

  const onChangeOffenseTeam = (val: string | null) => {
    setOffenseTeam(val);
    cleanupOnFilterChange();
  };

  const onRowClick = (index: number) => {
    if (!player) return;
    setClipIdx(index);
    const clip = clips[index];
    player.cueVideoById({
      videoId: games[0].id,
      startSeconds: clip.timestampStart,
      endSeconds: clip.timestampEnd,
    });
  };

  return (
    <div>
      <div className={classes.filterContainer}>
        <ToggleButtonGroup
          color="primary"
          value={grouping}
          exclusive
          onChange={(_, val) => onGroupingChange(val)}
        >
          <ToggleButton value="points">Points</ToggleButton>
          <ToggleButton value="possessions">Possessions</ToggleButton>
        </ToggleButtonGroup>
        <IPAutocomplete
          options={teams}
          value={offenseTeam}
          onChange={onChangeOffenseTeam}
          label="Offense Team"
        />
      </div>
      <div className={classes.videoContainer}>
        <YouTube
          videoId={games[0].id}
          onReady={(e) => playerReady(e)}
          opts={VIDEO_OPTS}
          onStateChange={(e) => playerStateChange(e)}
        />
      </div>
      <TableContainer component={Paper} style={{ maxHeight: 900 }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {ALL_CATEGORIES.map((cat) => (
                <TableCell key={cat}>{cat}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(grouping === "points" ? points : possessions).map((p, i) => (
              <TableRow
                key={i}
                style={{
                  outline: i === clipIdx ? `1px solid ${outlineColor}` : "",
                }}
              >
                <TableCell>
                  <IconButton onClick={() => onRowClick(i)}>
                    <PlayCircleFilledOutlined />
                  </IconButton>
                </TableCell>
                {ALL_CATEGORIES.map((cat) => (
                  <TableCell key={cat}>
                    {grouping === "points"
                      ? valueForPointCat(cat, p as Point)
                      : valueForPossCat(cat, p as Possession)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

function printTimeStamp(sec: number) {
  const hours = Math.floor(sec / 3600);
  const min = Math.floor((sec - hours * 3600) / 60);
  const seconds = Math.floor(sec - hours * 3600 - min * 60);
  return `${hours}:${min}:${seconds}`;
}

function valueForPointCat(category: string, point: Point) {
  switch (category) {
    case "Start time":
      return printTimeStamp(point.timestampStart);
    case "End time":
      return printTimeStamp(getPointEndTimeStamp(point));
    case "Score1":
      return point.team1Score;
    case "Score2":
      return point.team2Score;
    case "Offense Team":
      return getReceivingTeam(point);
    case "Result":
      return getPointResult(point);
    default:
      return category;
  }
}

function valueForPossCat(category: string, poss: Possession) {
  switch (category) {
    case "Start time":
      return printTimeStamp(poss.timestampStart);
    case "End time":
      return printTimeStamp(poss.timestampEnd);
    case "Offense Team":
      return poss.team;
    case "Result":
      return poss.result;
    default:
      return category;
  }
}
