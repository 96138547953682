export interface Throw {
    timestamp: number;
    srcX: number;
    srcY: number;
    destX: number;
    destY: number;
    thrower: string;
    receiver: string;
    turnover: boolean;
}

export interface Possession {
    timestampStart: number;
    timestampEnd: number;
    team: string;
    result: 'goal'|'turnover';
    // TODO(chrisbu): Uncomment this later when we get the whole thing working.
    // throws: Array<Throw>;
}

export interface Point {
    timestampStart: number;
    team1Score: number;
    team2Score: number;
    possessions: Array<Possession>;
}

export interface Game {
    id: string;
    team1: string;
    team2: string;
    points: Array<Point>;
}

export function getReceivingTeam(point: Point) {
    return point.possessions[0].team;
}

export function getPointEndTimeStamp(point: Point) {
    return point.possessions[point.possessions.length - 1].timestampEnd;
}

export function getPointResult(point: Point) {
    const hold = point.possessions[point.possessions.length - 1].team === getReceivingTeam(point);
    return hold ? 'Hold' : 'Break';
}

export function getGameName(game: Game) {
    return game.team1 + " vs " + game.team2;
}