import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { doc } from "firebase/firestore";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Shuffle from "@material-ui/icons/Shuffle";
import SwapVerticalCircleOutlined from "@material-ui/icons/SwapVerticalCircleOutlined";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import { IPAutocomplete } from "../common/ipautocomplete";

import {
  formatSalary,
  printableCatName,
  printableCatValue,
} from "./string_utils";

import {
  //ADVANCED_STATS,
  COUNTING_STATS,
  SHOOTING_STATS,
  VITAL_STATS,
} from "./tracked_stats";

interface NamedPlayer {
  PLAYER_NAME: string;
  PLAYER_ID: number;
  SALARY: string;
}

type Player = { [keyof: string]: number } & NamedPlayer;

interface Data {
  players: Player[];
}

// Returns a salary range we would expect the player to fall in.
function expectedSalary(
  playerSimScores: Array<{ player: Player; score: number }>
) {
  const salaries = playerSimScores
    .sort((a, b) => a.score - b.score)
    .slice(0, 10)
    .map((ps) =>
      parseInt(ps.player.SALARY.replaceAll(",", "").replace("$", ""))
    )
    .sort((a, b) => a - b);
  return formatSalary(salaries[4]) + " - " + formatSalary(salaries[5]);
}

const DEFAULT_PLAYER_ID = "203999";

const ALL_CATEGORIES = COUNTING_STATS.concat(VITAL_STATS)
  // .concat(ADVANCED_STATS)
  .concat(SHOOTING_STATS);

const useStyles = makeStyles(() =>
  createStyles({
    catLabel: {
      marginLeft: 10,
    },
    catChooserContainer: {
      margin: 10,
      display: "flex",
      flexWrap: "wrap",
      gap: 10,
    },
    controlsContainer: {
      margin: 10,
      display: "flex",
      alignItems: "center",
      gap: 10,
    },
    similarPlayerCardContainer: {
      display: "flex",
      flexWrap: "wrap",
      width: "70%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    similarPlayerCard: {
      width: 220,
      height: 220,
      margin: 10,
    },
    headshot: {
      width: 104,
      height: 76,
    },
    media: {
      height: 96,
    },
    centerContent: {
      textAlign: "center",
      width: "70%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 10,
      marginBottom: 10,
    },
    randomButton: {
      height: 56,
    },
  })
);

function normalizedValue(
  category: string,
  rawValue: number,
  means: { [keyof: string]: number },
  stds: { [keyof: string]: number }
) {
  const mean = means[category];
  const std = stds[category];
  return (rawValue - mean) / std;
}

interface StatSelectorProps {
  title: string;
  stats: string[];
  selectedStats: string[];
  toggleFunc: Function;
}

export function StatSelector(props: StatSelectorProps) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.catLabel}>
        <b>{props.title}</b>
      </div>
      {props.stats.map((cat) => (
        <span key={cat}>
          <Checkbox
            id={cat}
            checked={props.selectedStats.includes(cat)}
            onChange={(e) => props.toggleFunc(cat)}
          />
          <label htmlFor={cat}>{printableCatName(cat)}</label>
        </span>
      ))}
    </div>
  );
}

export function Similarity() {
  const theme = useTheme();
  // Pick either primary or secondary to give most contrast against current mode.
  const contrastingThemeColor =
    theme.palette.type === "light" ? "primary" : "secondary";

  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [player, setPlayer] = useState<Player | null>(null);
  const [selected, setSelected] = useState<Array<string>>(ALL_CATEGORIES);
  const [playerSimScores, setPlayerSimScores] = useState<
    Array<{ player: Player; score: number }>
  >([]);
  const [means, setMeans] = useState<{ [keyof: string]: number }>({});
  const [stds, setStds] = useState<{ [keyof: string]: number }>({});
  const docRef = doc(useFirestore(), "players", "2021");
  const { status, data } = useFirestoreDocData(docRef);

  useEffect(() => {
    // If there is no data there is nothing to do.
    if (!data) return;
    // If there is no ID navigate to the page with the default.
    if (!id) {
      history.push("/similarity/" + DEFAULT_PLAYER_ID);
      return;
    }

    // At this point we have data and an id so look up the player and set them.
    setPlayer(
      (data as Data).players.find((p) => p.PLAYER_ID === parseInt(id)) || null
    );
  }, [data, id, history]);

  // Create stat means and stds.
  useEffect(() => {
    if (!data) return;
    const newMeans: { [keyof: string]: number } = {};
    const newStds: { [keyof: string]: number } = {};

    const players = (data as Data).players;
    for (const cat of ALL_CATEGORIES) {
      const vals = players.map((p) => Number(p[cat]));
      const mean = vals.reduce((acc, cur) => acc + cur) / vals.length;
      const std = Math.sqrt(
        vals.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) /
          vals.length
      );
      newMeans[cat] = mean;
      newStds[cat] = std;
    }
    setMeans(newMeans);
    setStds(newStds);
  }, [data]);

  function toggleSelected(value: string) {
    const selectedSet = new Set(selected);
    if (selectedSet.has(value)) {
      selectedSet.delete(value);
    } else {
      selectedSet.add(value);
    }
    setSelected(Array.from(selectedSet));
  }

  function randomize() {
    const players = (data as Data).players;
    const idx = Math.floor(Math.random() * (players.length - 1));
    updatePlayer(players[idx]);
  }

  function updatePlayer(player: Player | null) {
    if (!player) return;
    // The URL is the ultimate state of truth and so we simply update it and the app
    // will respond accordingly.
    history.push("/similarity/" + player.PLAYER_ID);
  }

  // When the player or selected categories change, recalc similarity scores.
  useEffect(() => {
    if (!player || !selected.length) return;

    // Don't consider the selected player.
    const players = (data as Data).players.filter(
      (p) => p.PLAYER_NAME !== player.PLAYER_NAME
    );
    const scores = players.map((p) => {
      return {
        player: p,
        score:
          selected
            .map((cat) =>
              Math.abs(
                normalizedValue(cat, p[cat], means, stds) -
                  normalizedValue(cat, player[cat], means, stds)
              )
            )
            .reduce((acc, cur) => acc + cur) / selected.length,
      };
    });

    setPlayerSimScores(scores);
  }, [selected, player, data, means, stds]);

  return status === "loading" ? (
    <div className={classes.centerContent}>
      <CircularProgress />
    </div>
  ) : (
    <div>
      <div className={classes.catChooserContainer}>
        <StatSelector
          title="Basic Stats"
          stats={COUNTING_STATS}
          selectedStats={selected}
          toggleFunc={toggleSelected}
        ></StatSelector>
        <StatSelector
          title="Vital Stats"
          stats={VITAL_STATS}
          selectedStats={selected}
          toggleFunc={toggleSelected}
        ></StatSelector>
        {/* <StatSelector
          title="Advanced Stats"
          stats={ADVANCED_STATS}
          selectedStats={selected}
          toggleFunc={toggleSelected}
        ></StatSelector> */}
        <StatSelector
          title="Shot Location Stats"
          stats={SHOOTING_STATS}
          selectedStats={selected}
          toggleFunc={toggleSelected}
        ></StatSelector>
      </div>
      <div className={classes.controlsContainer}>
        <Button
          className={classes.randomButton}
          variant="contained"
          color={contrastingThemeColor}
          endIcon={<Shuffle />}
          onClick={() => randomize()}
        >
          Random
        </Button>
        <IPAutocomplete
          options={(data as Data).players}
          getOptionLabel={(option: Player) => option.PLAYER_NAME}
          value={player}
          onChange={updatePlayer}
          label="Player"
        />
        {player && (
          <img
            alt={player.PLAYER_NAME}
            className={classes.headshot}
            src={
              "https://cdn.nba.com/headshots/nba/latest/1040x760/" +
              player.PLAYER_ID +
              ".png"
            }
          ></img>
        )}
        {player && (
          <div>
            <div>
              <b>2020-21 Salary:</b>
            </div>
            <div>{player.SALARY}</div>
            <div>
              <b>Expected Salary:</b>
            </div>
            <div>
              {playerSimScores.length && expectedSalary(playerSimScores)}
            </div>
          </div>
        )}
      </div>
      {player && (
        <div>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {ALL_CATEGORIES.filter((cat) => selected.includes(cat)).map(
                    (cat) => (
                      <TableCell key={cat}>{printableCatName(cat)}</TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {ALL_CATEGORIES.filter((cat) => selected.includes(cat)).map(
                    (cat) => (
                      <TableCell key={cat}>
                        {printableCatValue(cat, player[cat])}
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div className={classes.centerContent}>
            <b>Top 10 Players Most Similiar to {player.PLAYER_NAME}</b>
          </div>
          <div className={classes.similarPlayerCardContainer}>
            {playerSimScores
              .sort((a, b) => a.score - b.score)
              .slice(0, 10)
              .map((ps, i) => (
                <Card
                  key={ps.player.PLAYER_NAME}
                  className={classes.similarPlayerCard}
                >
                  <CardMedia
                    className={classes.media}
                    image={
                      "https://cdn.nba.com/headshots/nba/latest/1040x760/" +
                      ps.player.PLAYER_ID +
                      ".png"
                    }
                    title={ps.player.PLAYER_NAME}
                  />
                  <CardContent>
                    <b>{"#" + (i + 1) + ". " + ps.player.PLAYER_NAME}</b>
                    <br />
                    {ps.player.SALARY}
                  </CardContent>
                  <CardActions>
                    <IconButton
                      color="inherit"
                      onClick={() => {
                        updatePlayer(ps.player);
                      }}
                    >
                      <SwapVerticalCircleOutlined />
                    </IconButton>
                  </CardActions>
                </Card>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
