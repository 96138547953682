import React, { useMemo } from "react";

// import { Container } from "@material-ui/core";
import { run } from "./rankingCalculator";

import Button from "@material-ui/core/Button";

import { Link } from "react-router-dom";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

// import ReactModal from 'react-modal';

import { DATA } from "./data";

export function Custom() {
    const rankings = useMemo(() => {
        return run(500);
      }, []);

    // const addedResults = []
    // const editedResults = []

    var showModal = false

    function handleModal() {
        console.log(showModal)
        console.log("in the modal function")
        showModal = !showModal
        console.log(showModal)
    }

    return (
    <div>
        {rankings.map((x) => (
            <div>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            {x['ranking']}. <Link to={"/usau/team/" + x["teamID"]}>{x["team"]}</Link>
                        </Typography>
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            {x["wins"]} - {x["losses"]}  Rating: {x["rating"]}
                        </Typography>
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            final third
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableContainer component={Paper} style={{ maxHeight: 900 }}>
                            <Table size="small" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Tournament</TableCell>
                                        <TableCell>Opponent</TableCell>
                                        <TableCell>Score</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {DATA[x["teamID"]].map((game) => (
                                        <TableRow>
                                            <TableCell>{game["date"]}</TableCell>
                                            <TableCell>{game["tournament"]}</TableCell>
                                            <TableCell>{game["opp"]}</TableCell>
                                            <TableCell>{game["score"]} - {game["oppScore"]}</TableCell>
                                            <TableCell>
                                                <Button variant="outlined"
                                                        onClick={() => {
                                                            console.log('editing ' + x["teamID"])
                                                            handleModal()
                                                        }}>Edit Result</Button>
                                            </TableCell>
                                        </TableRow>))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Button>Add Result</Button>
                    </AccordionDetails>
                </Accordion>
                {/* <ReactModal
                    isOpen={showModal}
                    contentLabel="Minimal Modal Example"
                >
                    <button onClick={handleModal}>Close Modal</button>
                </ReactModal> */}
            </div>
        ))}
    </div>
  )
}
