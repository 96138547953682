export interface ThrowEvent {
  game_id: string;
  season: string;
  ot_id: string;
  dt_id: string;
  t_id: string;
  r_id: string;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  res?: "throw_away" | "stall" | "goal" | "drop";
}

export interface AudlPlayerData {
  name: string;
  team_id: string;
  games: number;
  o_points: number;
  d_points: number;
}

export interface AudlThrowData {
  throws: { [gameId: string]: ThrowEvent[] };
  teams: { [key: string]: { full_name: string; abbrev: string } };
  players: {
    [key: string]: AudlPlayerData;
  };
};

export interface PlayerStatRow {
  name: string;
  team: string;
  teamId: string;
  throws: number;
  games: number;
  o_points: number;
  d_points: number;
  completions: number;
  completionPercent: number;
  assists: number;
  throwAways: number;
  stalls: number;
  hucks: number;
  huckPercent: number;
  huckCompletedPercent: number;
  yards: number;
  yardsPerThrow: number;
}

export function distanceOfThrow(throwEvent: ThrowEvent) {
  return Math.sqrt(
    Math.pow(throwEvent.x2 - throwEvent.x1, 2) +
      Math.pow(throwEvent.y2 - throwEvent.y1, 2)
  );
}

// If the throw destination is at a smaller y value we call it a dump.
export function throwIsDump(throwEvent: ThrowEvent) {
  return throwEvent.y2 <= throwEvent.y1 && !throwIsSwing(throwEvent);
}

// If the throw travels more horizontal distance than vertical, we call it a swing.
export function throwIsSwing(throwEvent: ThrowEvent) {
  // If a throw gains more than 5 yards stop calling it a swing.
  const throwGainsManyYards = throwEvent.y2 - throwEvent.y1 > 5;
  const verticalDistance = Math.abs(throwEvent.y2 - throwEvent.y1);
  const horizontalDistance = Math.abs(throwEvent.x2 - throwEvent.x1);
  return horizontalDistance > verticalDistance && !throwGainsManyYards;
}

// If a disc travels more than 40 vertical yards we call it a huck.
export function throwIsHuck(throwEvent: ThrowEvent) {
  return throwEvent.y2 - throwEvent.y1 > 40;
}

export function throwIsGainer(throwEvent: ThrowEvent) {
  return (
    !throwIsDump(throwEvent) &&
    !throwIsSwing(throwEvent) &&
    !throwIsHuck(throwEvent)
  );
}

export function throwIsComplete(throwEvent: ThrowEvent) {
  return throwEvent.res !== "stall" && throwEvent.res !== "throw_away";
}
