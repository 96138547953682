import { useEffect, useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  collection,
  doc,
  addDoc,
  where,
  query,
  setDoc,
} from "firebase/firestore";
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
} from "reactfire";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Popover from "@mui/material/Popover";
import IconButton from "@material-ui/core/IconButton";
import {
  Add,
  Edit,
  FastForward,
  FastRewind,
  Fullscreen,
  Pause,
  PlayArrow,
  PlayCircleFilledOutlined,
  SkipNext,
  SkipPrevious,
  Sync,
  SyncDisabled,
  InfoOutlined,
} from "@material-ui/icons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import YouTube from "react-youtube";

import { IPAutocomplete } from "../common/ipautocomplete";
import { UserContext } from "../UserContext";

interface PlayList {
  user: string;
  data: Clip[];
  lastEdit: number;
  title: string;
}

interface Clip {
  id: string;
  start: number;
  end: number;
}

interface Player {
  getCurrentTime(): number;
  getPlayerState(): number;
  pauseVideo(): void;
  playVideo(): void;
  cueVideoById(cueVideoOpts: {
    videoId: String;
    startSeconds: Number;
    endSeconds?: Number;
  }): void;
  seekTo(timestamp: number): void;
  stopVideo(): void;
}

const REQUIRED_COLS = ["id", "start", "end"];

const SPECIAL_COLS = REQUIRED_COLS.concat(["notes"]);

const VIDEO_OPTS = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    controls: 0 as 0 | 2 | 1,
    modestbranding: 1 as 1 | undefined,
    origin: "http://localhost:3000",
  },
};

const useStyles = makeStyles(() =>
  createStyles({
    filterContainer: {
      display: "flex",
      gap: "10px",
      padding: "10px",
    },
    videoContainer: {
      backgroundColor: "black",
      height: "390px",
      textAlign: "center",
    },
    videoControlsContainer: {
      display: "flex",
      gap: "10px",
      justifyContent: "center",
    },
    uploadButton: {
      height: "56px",
    },
  })
);

export function Filmroom() {
  const firestore = useFirestore();
  const classes = useStyles();

  const theme = useTheme();
  const highlightColor = theme.palette.divider;

  const user = useContext(UserContext);

  const { id } = useParams<{ id: string }>();
  const docRef = doc(firestore, "filmroom", id || "unknown");
  const { status, data } = useFirestoreDocData(docRef);

  const filmroomRef = collection(firestore, "filmroom");
  const filmroomRefQuery = query(
    filmroomRef,
    where("user", "==", user ? user.email : "")
  );

  const { data: userUploadsdata } =
    useFirestoreCollectionData(filmroomRefQuery);
  const userUploads = userUploadsdata
    ? (userUploadsdata as unknown as PlayList[])
    : [];
  const onSelectUploadedPlaylist = (val: any) => {
    if (val) {
      history.push("/filmroom/" + val["NO_ID_FIELD"]);
    } else {
      history.push("/filmroom");
    }
  };

  const history = useHistory();

  const [clips, setClips] = useState<Clip[]>([]);
  const [headers, setHeaders] = useState<string[]>([]);
  const [player, setPlayer] = useState<null | Player>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [clipIdx, setClipIdx] = useState<null | number>(null);
  const [prevPlayerState, setPrevPlayerState] = useState<number>(-1);
  const [filterVals, setFilterVals] = useState<(string | null)[]>([]);
  const [isAutoplayEnabled, setIsAutoplayEnabled] = useState<boolean>(true);

  const filterableCols = headers.filter((h) => SPECIAL_COLS.indexOf(h) === -1);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (status === "loading" || !data) return;

    if (!id) {
      setHeaders([]);
      setClips([]);
      return;
    }

    const rows = (data as any).data.split("\n") as string[];
    const headers = rows[0].split(",") as string[];
    setHeaders(headers);
    setClips(parseData(rows));

    const filterableCols = headers.filter(
      (h) => SPECIAL_COLS.indexOf(h) === -1
    );

    setFilterVals(new Array(filterableCols.length).fill(null));
  }, [id, status, data]);

  const applyFilters = (row: {}) => {
    for (let i = 0; i < filterVals.length; i++) {
      if (filterVals[i] === null) continue;
      const filterCol = filterableCols[i];
      if ((row as any)[filterCol] !== filterVals[i]) return false;
    }
    return true;
  };

  const filteredData = clips.filter(applyFilters);

  const onFileChange = (e: any, id?: string) => {
    const reader = new FileReader();
    // Get filename minus .csv extension.
    const fileName = e.target.files[0].name.split(".").slice(0, -1).join(".");

    reader.addEventListener("load", (event: any) => {
      // When the file is uploaded, read the text and write it to firebase. When it is written
      // and we have the document id returned to us navigate there.
      const result = event.target.result.replaceAll("\r", "").trim();
      if (!isDataValid(result)) {
        alert(
          "CSV was missing required columns. All files must contain 'id', 'start', 'end' columns."
        );
        return;
      }

      // Do a repalce.
      if (id) {
        const docRef = doc(firestore, "filmroom", id);
        setDoc(docRef, {
          lastEdit: Date.now(),
          user: user ? user.email : "guest",
          title: fileName,
          data: result,
        })
          .then(() => {
            history.push("/filmroom/" + id);
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
      } else {
        addDoc(collection(firestore, "filmroom"), {
          lastEdit: Date.now(),
          user: user ? user.email : "guest",
          title: fileName,
          data: result,
        })
          .then((docRef) => {
            history.push("/filmroom/" + docRef.id);
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
      }
    });
    reader.readAsText(e.target.files[0]);
  };

  const onRowClick = (index: number) => {
    if (!player) return;
    setClipIdx(index);
    const clip = filteredData[index];
    player.cueVideoById({
      videoId: clip.id,
      startSeconds: clip.start,
      endSeconds: clip.end,
    });
  };

  const playerReady = (e: { target: Player }) => {
    setPlayer(e.target);
    // Uncomment to control player in debugger.
    // (window as any).player = e.target;
  };

  const playerStateChange = (e: { data: number }) => {
    if (!player || clipIdx === null) return;
    setPrevPlayerState(e.data);

    if (e.data === 0) {
      setIsPlaying(false);
      // If we get a FINISH update after a NOT PLAYING don't do anything else.
      if (prevPlayerState === -1) return;

      // Only move to the next clip if autoplay is enabled.
      if (!isAutoplayEnabled) return;

      const newIdx = clipIdx + 1 >= filteredData.length ? 0 : clipIdx + 1;
      setClipIdx(newIdx);
      const nextClip = filteredData[newIdx];
      player.cueVideoById({
        videoId: nextClip.id,
        startSeconds: nextClip.start,
        endSeconds: nextClip.end,
      });
    } else if (e.data === 5) {
      // Video is cue-ed, attempt to play it.
      player.playVideo();
      setIsPlaying(true);
    }
  };

  const onChangeFilterVal = (col: string, val: string | null) => {
    const filterValsIdx = filterableCols.findIndex((fc) => fc === col);
    (filterVals as any)[filterValsIdx] = val;
    setFilterVals([...filterVals]);

    if (!player) return;
    player.stopVideo();
    setClipIdx(null);
  };

  const onPlayPauseClick = () => {
    if (!player) return;
    if (isPlaying) {
      player.pauseVideo();
    } else {
      player.playVideo();
    }
    setIsPlaying(!isPlaying);
  };

  const onSeekClick = (n: number) => {
    if (!player || clipIdx === null) return;
    const desiredSeek = player.getCurrentTime() + n;
    const minSeek = filteredData[clipIdx].start;
    const maxSeek = filteredData[clipIdx].end - 1;
    // Make sure we never seek out of the clip.
    player.seekTo(Math.min(Math.max(desiredSeek, minSeek), maxSeek));
  };

  const onPrevClipClick = () => {
    if (!player || clipIdx === null) return;

    // Instead of going negative, go to the last clip.
    const newIdx = clipIdx <= 0 ? filteredData.length - 1 : clipIdx - 1;
    onRowClick(newIdx);
  };

  const onNextClipClick = () => {
    if (!player || clipIdx === null) return;

    // Instead of going past the last clip, go to the first clip.
    const newIdx = clipIdx >= filteredData.length - 1 ? 0 : clipIdx + 1;
    onRowClick(newIdx);
  };

  const onFullscreenClick = () => {
    const iframe = document.querySelector("iframe");
    if (!iframe) return;
    iframe.requestFullscreen();
  };

  const onAutoplayClick = () => {
    setIsAutoplayEnabled(!isAutoplayEnabled);
  };

  return (
    <div>
      <div className={classes.filterContainer}>
        {(!data || user) && (
          <>
            <Button
              className={classes.uploadButton}
              variant="contained"
              component="label"
              color="primary"
            >
              {id ? (
                <>
                  <Edit /> Replace csv
                </>
              ) : (
                <>
                  <Add /> Upload a csv
                </>
              )}
              <input
                type="file"
                onChange={(e) => onFileChange(e, id)}
                accept=".csv"
                hidden
              />
            </Button>

            <Tooltip title="Schema Info">
              <IconButton onClick={handleClick}>
                <InfoOutlined />
              </IconButton>
            </Tooltip>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div style={{ padding: 16, maxWidth: 600 }}>
                <div>
                  <div>
                    <b>Schema Info</b>
                    <div>
                      The uploaded file must be a csv where each row corresponds
                      to a clip. Each column must have certain required columns
                      (see below) and any additional columns will create multi
                      select filters based on provided values.
                    </div>
                  </div>
                  <br />
                  <div>
                    <b>Special Columns:</b>
                    <div>
                      <b>id:</b> the youtube video id (e.g. 3GFPTr8Gwmg) [
                      <i>required</i>]
                    </div>
                    <div>
                      <b>start:</b> timestamp for clip start (e.g. 59:30) [
                      <i>required</i>]
                    </div>
                    <div>
                      <b>end:</b> timestamp for clip end (e.g. 1:01:20) [
                      <i>required</i>]
                    </div>
                    <div>
                      <b>notes:</b> column that will not be turned into a filter{" "}
                      [<i>optional</i>]
                    </div>
                  </div>
                </div>
              </div>
            </Popover>
            {user && (
              <IPAutocomplete
                getOptionLabel={(p: PlayList) => p.title}
                options={userUploads}
                value={
                  status === "loading" ? "Loading..." : data || { title: "" }
                }
                onChange={(val: any) => onSelectUploadedPlaylist(val)}
                label={"Uploads"}
              />
            )}
          </>
        )}
        {filterableCols.map((col) => (
          <IPAutocomplete
            key={col}
            options={getUniqueColVals(col, clips)}
            value={(filterVals as any)[col]}
            onChange={(val: string | null) => onChangeFilterVal(col, val)}
            label={col}
          />
        ))}
      </div>
      <div className={classes.videoContainer}>
        <YouTube
          onReady={(e) => playerReady(e)}
          opts={VIDEO_OPTS}
          onStateChange={(e) => playerStateChange(e)}
        />
      </div>
      <div className={classes.videoControlsContainer}>
        <Tooltip
          title={isAutoplayEnabled ? "Disable Autoplay" : "Enable Autoplay"}
        >
          <IconButton onClick={() => onAutoplayClick()}>
            {isAutoplayEnabled ? <SyncDisabled /> : <Sync />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Previous Clip">
          <IconButton onClick={() => onPrevClipClick()}>
            <SkipPrevious />
          </IconButton>
        </Tooltip>
        <Tooltip title="Rewind">
          <IconButton onClick={() => onSeekClick(-5)}>
            <FastRewind />
          </IconButton>
        </Tooltip>
        <Tooltip title={isPlaying ? "Pause" : "Play"}>
          <IconButton onClick={() => onPlayPauseClick()}>
            {isPlaying ? <Pause /> : <PlayArrow />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Fast Forward">
          <IconButton onClick={() => onSeekClick(5)}>
            <FastForward />
          </IconButton>
        </Tooltip>
        <Tooltip title="Next clip">
          <IconButton onClick={() => onNextClipClick()}>
            <SkipNext />
          </IconButton>
        </Tooltip>
        <Tooltip title="Fullscreen">
          <IconButton onClick={() => onFullscreenClick()}>
            <Fullscreen />
          </IconButton>
        </Tooltip>
      </div>
      {clips.length > 0 && (
        <TableContainer component={Paper} style={{ maxHeight: 900 }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {headers.filter(shouldShowInTable).map((cat) => (
                  <TableCell key={cat}>
                    <b>{cat}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((row, i) => (
                <TableRow
                  key={i}
                  style={{
                    backgroundColor: i === clipIdx ? `${highlightColor}` : "",
                  }}
                >
                  <TableCell>
                    <IconButton onClick={() => onRowClick(i)}>
                      <PlayCircleFilledOutlined />
                    </IconButton>
                  </TableCell>
                  {headers.filter(shouldShowInTable).map((header) => (
                    <TableCell key={header + i}>
                      {getValueAtRow(header, row)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

/**
 * For now we just don't put id in the table but in the future we might do something more complex.
 */
function shouldShowInTable(header: string) {
  return header !== "id";
}

function printTimeStamp(sec: number) {
  const hours = Math.floor(sec / 3600);
  const min = Math.floor((sec - hours * 3600) / 60);
  const seconds = Math.floor(sec - hours * 3600 - min * 60);
  return `${hours ? hours + ":" : ""}${min > 9 ? min : "0" + min}:${
    seconds > 9 ? seconds : "0" + seconds
  }`;
}

/**
 * Takes either seconds (e.g. 103) or a time string (e.g. 1:43)
 * and normalizes them to seconds.
 */
function convertToSec(time: number | string) {
  if (!Number.isNaN(Number(time))) return time;
  const parts = time
    .toString()
    .split(":")
    .map((p) => Number(p));
  if (parts.length === 2) {
    return parts[0] * 60 + parts[1];
  }
  return parts[0] * 3600 + parts[1] * 60 + parts[2];
}

function getValueAtRow(column: string, row: {}) {
  const rawValue = (row as any)[column];
  if (column === "start" || column === "end") {
    const id = (row as any)["id"];
    const url = `https://youtube.com/watch?v=${id}&t=${rawValue}`;
    return (
      <a href={url} target="_blank" rel="noreferrer">
        {printTimeStamp(rawValue)}
      </a>
    );
  }
  return rawValue;
}

function isDataValid(data: string) {
  const headers = data.split("\n")[0].split(",");
  return !REQUIRED_COLS.some((col) => !headers.includes(col));
}

function parseData(rows: string[]) {
  const headers = (rows.shift() as string).split(",");
  const data = rows.map((row) => {
    const rowObj = {};
    const vals = row.split(",");
    for (let i = 0; i < vals.length; i++) {
      const header = headers[i];
      // Normalize timestamps to seconds.
      const val =
        header === "start" || header === "end"
          ? convertToSec(vals[i])
          : vals[i];
      (rowObj as any)[header] = val;
    }
    return rowObj;
  });
  return data as unknown as Clip[];
}

function getUniqueColVals(column: string, rows: {}[]) {
  return [...new Set(rows.map((row) => (row as any)[column]))];
}
