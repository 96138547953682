import React, { useEffect, useState } from "react";

import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import SportsBasketballIcon from "@material-ui/icons/SportsBasketball";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from "@material-ui/core/styles";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { FirestoreProvider, useFirebaseApp } from "reactfire";
import { getFirestore } from "firebase/firestore";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  User,
} from "firebase/auth";

import { Home } from "./Home";
import { Audl } from "./audl";
import { AudlStats } from "./audl/stats";
import { AudlPlayer } from "./audl/player";
import { AudlTeam } from "./audl/team";
import { Filmroom } from "./filmroom";
import { Similarity } from "./similarity";
import { Ultimate } from "./ultimate";
import { Explorer } from "./ultimate/explorer";
import { Rankings } from "./usau/rankings";
import { Team } from "./usau/team";
import { Custom } from "./usau/custom";
import { Tournament } from "./usau/tournament";
import { Wowy } from "./wowy";
import { UserContext } from "./UserContext";

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      flexGrow: 1,
      paddingLeft: 5,
    },
    appBarLink: {
      textDecoration: "none",
    },
  })
);

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [mode, setMode] = useState<"light" | "dark" | null>(null);
  const classes = useStyles();
  const app = useFirebaseApp();
  const firestore = getFirestore(app);
  const auth = getAuth(app);

  const [user, setUser] = useState<User | null>(auth.currentUser);
  // Whether we have gotten the initial auth state from firebase yet.
  const [initialized, setInitialized] = useState(false);
  const provider = new GoogleAuthProvider();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
      setInitialized(true);
    });
  }, [auth]);

  const handleLoginClick = () => {
    if (user) {
      auth.signOut();
    } else {
      signInWithPopup(auth, provider)
        .then((result) => {
          setUser(result.user);
        })
        .catch((error) => {
          console.log(
            `Unable to login ${error.customData.email}. [${error.errorCode}]: ${error.errorMessage}`
          );
        });
    }
  };

  const theme = React.useMemo(() => {
    const preferredMode = prefersDarkMode ? "dark" : "light";
    const modeToUse = mode === null ? preferredMode : mode;
    return createTheme({
      palette: {
        primary: {
          main: "#1d428a", // Skyline Blue
        },
        secondary: {
          main: "#fec524", // Sunshine Yellow
        },
        type: modeToUse === "dark" ? "dark" : "light",
      },
    });
  }, [mode, prefersDarkMode]);

  const toggleTheme = () => {
    if (mode === null) {
      setMode(prefersDarkMode ? "light" : "dark");
    } else {
      setMode(mode === "dark" ? "light" : "dark");
    }
  };

  return (
    <FirestoreProvider sdk={firestore}>
      <ThemeProvider theme={theme}>
        <UserContext.Provider value={user}>
          <CssBaseline />
          <Router>
            <AppBar position="static">
              <Toolbar color="inherit">
                <SportsBasketballIcon />
                <Typography variant="h6" className={classes.title}>
                  <Link to="/" className={classes.appBarLink}>
                    Inverted Pivot
                  </Link>
                </Typography>
                {initialized && (
                  <Box
                    sx={{
                      flexGrow: 0,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      style={{ color: "white" }}
                      onClick={handleLoginClick}
                    >
                      {user ? "Logout" : "Login"}
                    </Button>
                    {user && (
                      <Avatar
                        alt={user.displayName || undefined}
                        src={user.photoURL || undefined}
                      />
                    )}
                  </Box>
                )}
                <IconButton
                  color="inherit"
                  aria-label="dark"
                  onClick={(event) => toggleTheme()}
                >
                  <Brightness4Icon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/audl/player/:id" component={AudlPlayer}></Route>
              <Route path="/audl/team/:id" component={AudlTeam}></Route>
              <Route path="/audl/stats" component={AudlStats}></Route>
              <Route path="/audl" component={Audl}></Route>
              <Route path="/filmroom/:id" component={Filmroom}></Route>
              <Route path="/filmroom" component={Filmroom}></Route>
              <Route path="/similarity/:id" component={Similarity}></Route>
              <Route path="/similarity" component={Similarity}></Route>
              <Route path="/ultimate/explorer" component={Explorer}></Route>
              <Route path="/ultimate" component={Ultimate}></Route>
              <Route path="/usau/rankings" component={Rankings}></Route>
              <Route path="/usau/team/:id" component={Team}></Route>
              <Route path="/usau/custom/" component={Custom}></Route>
              <Route path="/usau/tournament/:id" component={Tournament}></Route>
              <Route path="/wowy/:id/:playerId" component={Wowy}></Route>
              <Route path="/wowy/:id" component={Wowy}></Route>
              <Route path="/wowy" component={Wowy}></Route>
            </Switch>
          </Router>
        </UserContext.Provider>
      </ThemeProvider>
    </FirestoreProvider>
  );
}

export default App;
