import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { collection } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import {
  AudlPlayerData,
  AudlThrowData,
  ThrowEvent,
  throwIsComplete,
  throwIsHuck,
} from "../common/audl";
import { IPSparkChart } from "../common/ipsparkchart";

interface PlayerPerGameRow {
  games: number;
  name: string;
  pointsPerGame: number;
  throwsPerGame: number;
  completionPercent: number;
  assistsPerGame: number;
  throwAwaysPerGame: number;
  hucksPerGame: number;
  huckPercent: number;
  huckCompletionPercent: number;
  yardsPerGame: number;
  yardsPerThrow: number;
}

const ALL_CATEGORIES = [
  "pointsPerGame",
  "throwsPerGame",
  "completionPercent",
  "assistsPerGame",
  "throwAwaysPerGame",
  "hucksPerGame",
  "huckPercent",
  "huckCompletionPercent",
  "yardsPerGame",
  "yardsPerThrow",
];

const useStyles = makeStyles(() =>
  createStyles({
    centerContent: {
      textAlign: "center",
      width: "70%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 10,
      marginBottom: 10,
    },
    sparkChartsContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: 10,
    },
  })
);

export function AudlPlayer() {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const collectionRef = collection(useFirestore(), "audl");
  const { status, data } = useFirestoreCollectionData(collectionRef);

  const [teams, setTeams] = useState<{
    [key: string]: { full_name: string; abbrev: string };
  }>({});
  const [players, setPlayers] = useState<{
    [key: string]: AudlPlayerData;
  }>({});
  const [throws, setThrows] = useState<ThrowEvent[]>([]);
  const [statsObj, setStatsObj] = useState<{
    [playerId: string]: PlayerPerGameRow;
  }>({});
  const [team, setTeam] = useState<{
    full_name: string;
    abbrev: string;
    id: string;
  } | null>(null);

  useEffect(() => {
    if (!data) return;
    let throwEvents: ThrowEvent[] = [];
    for (const d of data as unknown as AudlThrowData[]) {
      if (d.teams) {
        setTeams(d.teams);
      }
      if (d.players) {
        setPlayers(d.players);
      }
      if (d.throws) {
        for (const gameId of Object.keys(d.throws)) {
          throwEvents = throwEvents.concat(d.throws[gameId]);
        }
      }
      setThrows(throwEvents);
    }
  }, [data]);

  useEffect(() => {
    if (Object.keys(teams).length === 0) return;
    const teamId = Object.keys(teams).find(
      (teamId) => teamId === players[id].team_id.toString()
    );
    if (!teamId) return;
    setTeam({
      id: teamId,
      full_name: teams[teamId].full_name,
      abbrev: teams[teamId].abbrev,
    });
  }, [teams, players, id]);

  useEffect(() => {
    // Ignore until all of these are defined.
    if (
      Object.keys(teams).length === 0 ||
      Object.keys(players).length === 0 ||
      Object.keys(throws).length === 0
    ) {
      return;
    }
    const throwsPerPlayer: { [playerId: string]: ThrowEvent[] } = {};
    for (const throwEvt of throws) {
      const throwerId = throwEvt.t_id;
      if (!throwsPerPlayer[throwerId]) {
        throwsPerPlayer[throwerId] = [];
      }
      throwsPerPlayer[throwerId].push(throwEvt);
    }

    const statsObj: { [playerId: string]: PlayerPerGameRow } = {};
    for (const playerId of Object.keys(players)) {
      const games = players[playerId].games;
      const points = players[playerId].d_points + players[playerId].o_points;
      const playerThrows = throwsPerPlayer[playerId] || [];
      const completions = playerThrows.filter(throwIsComplete);
      const assists = playerThrows.filter((t) => t.res === "goal");
      const throwAways = playerThrows.filter((t) => t.res === "throw_away");
      const hucks = playerThrows.filter(throwIsHuck);
      const completedHucks = hucks.filter(throwIsComplete);
      // Instead of counting dumps as negative yards, count them as 0.
      const yards = completions
        .map((t) => Math.max(t.y2 - t.y1, 0))
        .reduce((prev, cur) => prev + cur, 0);

      statsObj[playerId] = {
        games,
        name: players[playerId].name,
        pointsPerGame: points / (games || 1),
        throwsPerGame: playerThrows.length / (games || 1),
        completionPercent: completions.length / (playerThrows.length || 1),
        assistsPerGame: assists.length / (games || 1),
        throwAwaysPerGame: throwAways.length / (games || 1),
        hucksPerGame: hucks.length / (games || 1),
        huckPercent: hucks.length / (playerThrows.length || 1),
        huckCompletionPercent: completedHucks.length / (hucks.length || 1),
        yardsPerGame: yards / (games || 1),
        yardsPerThrow: yards / (completions.length + 1),
      };
    }
    setStatsObj(statsObj);
  }, [teams, players, throws]);

  function categoryIsPercent(category: string) {
    return (
      category === "completionPercent" ||
      category === "huckCompletionPercent" ||
      category === "huckPercent"
    );
  }

  function getBucketSize(category: string) {
    if (category === "throwsPerGame") {
      return 2;
    } else if (category === "pointsPerGame") {
      return 2;
    } else if (category === "completionPercent") {
      return 3;
    } else if (
      category === "throwAwaysPerGame" ||
      category === "hucksPerGame" ||
      category === "assistsPerGame"
    ) {
      return 1;
    } else if (category === "huckCompletionPercent") {
      return 7;
    } else if (category === "yardsPerGame") {
      return 15;
    } else if (category === "huckPercent") {
      return 2;
    }
    return 1;
  }

  function getCatForPlayer(category: string, playerId: string) {
    const multiplyer = categoryIsPercent(category) ? 100 : 1;
    const rawVal = (statsObj[playerId] as any)[category];
    return rawVal * multiplyer;
  }

  // Ignore players that played 0 games.
  function getLeagueValuesforCat(category: string) {
    const multiplyer = categoryIsPercent(category) ? 100 : 1;
    return Object.keys(statsObj)
      .filter((key) => (statsObj[key] as any)["games"] > 0)
      .map((key) => (statsObj[key] as any)[category] * multiplyer);
  }

  const games = ["1", "2", "3", "4", "5", "6"];

  return status === "loading" || Object.keys(statsObj).length === 0 ? (
    <div className={classes.centerContent}>
      <CircularProgress />
    </div>
  ) : (
    <div>
      <div>
        <Link to={"/audl/stats"}>League Stats</Link>
      </div>
      {players[id].name} -{" "}
      {team && <Link to={"/audl/team/" + team.id}>{team.abbrev}</Link>}
      <div className={classes.sparkChartsContainer}>
        {ALL_CATEGORIES.map((cat, idx) => (
          <IPSparkChart
            key={"spark-" + idx}
            value={getCatForPlayer(cat, id)}
            data={getLeagueValuesforCat(cat)}
            label={cat}
            bucketSize={getBucketSize(cat)}
          ></IPSparkChart>
        ))}
      </div>
      <TableContainer component={Paper} style={{ maxHeight: 900 }}>
        <Table>
          <TableHead>
            <TableRow>
              {["id", "game id"].concat(ALL_CATEGORIES).map((cat) => (
                <TableCell key={cat}>{cat}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {games.map((gameId: string, idx: number) => (
              <TableRow key={gameId}>
                <TableCell>{idx + 1}</TableCell>
                <TableCell>{gameId}</TableCell>
                {ALL_CATEGORIES.map((cat) => (
                  <TableCell key={cat}>{cat}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
