import React, { useMemo } from "react";

import { Container } from "@material-ui/core";
import { run } from "./rankingCalculator";

import { Link } from "react-router-dom";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

export function Rankings() {
  const rankings = useMemo(() => {
    return run(500);
  }, []);

  console.log(rankings);

  return (
    <Container>
      <TableContainer component={Paper} style={{ maxHeight: 900 }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Ranking</TableCell>
              <TableCell>Team</TableCell>
              <TableCell>Record</TableCell>
              <TableCell>Rating</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rankings.map((x) => (
              <TableRow>
                <TableCell>{x["ranking"]}</TableCell>
                <TableCell>
                  <Link to={"/usau/team/" + x["teamID"]}>{x["team"]}</Link>
                </TableCell>
                <TableCell>
                  {x["wins"]} - {x["losses"]}
                </TableCell>
                <TableCell>{x["rating"]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
