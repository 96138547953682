export interface Game {
  date: string;
  tournament: string;
  tournamentId: string;
  gameId: string;
  team: string;
  score: string;
  oppScore: string;
  opp: string;
  oppId: string;
}

export const DATA: Record<string, Game[]> = {
  "o7pFuDOxPP07DaaI1szMOj%2bdcR2%2bdFD1bt2gOuGwIg8%3d": [
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "rQ5VNJ8MDpT83W1zZxs5waN2sILlatBA7NpnpAhtlwQ%3d",
      "team": "Icey Boyz",
      "score": "1",
      "oppScore": "2",
      "opp": "Little John",
      "oppId": "AQqIfhow3GwkB0TLWgZhtnSQ1Rcxvr7ZH%2bMWqtruDbM%3d"
    },
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "6TuT2OdZlYEhINMbWeZ51ZaXNhErFXruZdJl6K4ndH4%3d",
      "team": "Icey Boyz",
      "score": "2",
      "oppScore": "0",
      "opp": "Fire Hawks",
      "oppId": "nWdDUCpVWRy85%2fC17qDdIcmCdIiRPYnq1BljTV4U1fA%3d"
    },
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "RIzukMkojGGOzZpKk1QYsbPdqXjNCbAMoUAGgDwrFSM%3d",
      "team": "Icey Boyz",
      "score": "2",
      "oppScore": "0",
      "opp": "ISO Cardio",
      "oppId": "B8Fv3voYTJVwF%2fp5tUAiPVnDYZIDh2i0vxjBE4ICYSQ%3d"
    },
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "mv4eoG2yTvt3%2bA3IDptVKZFE5dW7U3Tnk3iZa%2fv5Dnw%3d",
      "team": "Icey Boyz",
      "score": "2",
      "oppScore": "1",
      "opp": "Spicey [Freshman]",
      "oppId": "EId%2fcud3k6xolV7x2mncVuvy0sjcgMA3rN6SbCatc4o%3d"
    },
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "x%2fSOZuXtymkOhnyGiiORZujfvZd8RMWjIH4jbjBpscc%3d",
      "team": "Icey Boyz",
      "score": "2",
      "oppScore": "0",
      "opp": "Chasing Shade",
      "oppId": "IwzskuT6MrOKXV6riTdmX6OoQJHWmMDsR0sivF2Yaf8%3d"
    }
  ],
  "AQqIfhow3GwkB0TLWgZhtnSQ1Rcxvr7ZH%2bMWqtruDbM%3d": [
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "rQ5VNJ8MDpT83W1zZxs5waN2sILlatBA7NpnpAhtlwQ%3d",
      "team": "Little John",
      "score": "2",
      "oppScore": "1",
      "opp": "Icey Boyz",
      "oppId": "o7pFuDOxPP07DaaI1szMOj%2bdcR2%2bdFD1bt2gOuGwIg8%3d"
    },
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "5at3QUzPxYtur6jRxAOdRtD%2fEkJq96mnblGVKI0x%2fmI%3d",
      "team": "Little John",
      "score": "2",
      "oppScore": "0",
      "opp": "Chasing Shade",
      "oppId": "IwzskuT6MrOKXV6riTdmX6OoQJHWmMDsR0sivF2Yaf8%3d"
    },
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "0O%2bLCNsd6q19RobjHsyXBvdHVoafLC%2fE7wRXGzfxx5I%3d",
      "team": "Little John",
      "score": "2",
      "oppScore": "0",
      "opp": "Fire Hawks",
      "oppId": "nWdDUCpVWRy85%2fC17qDdIcmCdIiRPYnq1BljTV4U1fA%3d"
    },
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "0GOjGi1z4h4AWqKXk6rslQUTvSHIOhPUUfW70xmSf6Y%3d",
      "team": "Little John",
      "score": "2",
      "oppScore": "0",
      "opp": "ISO Cardio",
      "oppId": "B8Fv3voYTJVwF%2fp5tUAiPVnDYZIDh2i0vxjBE4ICYSQ%3d"
    },
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "URHPYAv4ODdxjrb2TPLRJkFLM8oRBIXBFxM8UvIvsfk%3d",
      "team": "Little John",
      "score": "2",
      "oppScore": "0",
      "opp": "Spicey [Freshman]",
      "oppId": "EId%2fcud3k6xolV7x2mncVuvy0sjcgMA3rN6SbCatc4o%3d"
    }
  ],
  "IwzskuT6MrOKXV6riTdmX6OoQJHWmMDsR0sivF2Yaf8%3d": [
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "xync%2faUM9JaZ9r33WtScsVYJArL2%2bdK7keEQ%2b5Wv9ro%3d",
      "team": "Chasing Shade",
      "score": "2",
      "oppScore": "1",
      "opp": "ISO Cardio",
      "oppId": "B8Fv3voYTJVwF%2fp5tUAiPVnDYZIDh2i0vxjBE4ICYSQ%3d"
    },
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "5at3QUzPxYtur6jRxAOdRtD%2fEkJq96mnblGVKI0x%2fmI%3d",
      "team": "Chasing Shade",
      "score": "0",
      "oppScore": "2",
      "opp": "Little John",
      "oppId": "AQqIfhow3GwkB0TLWgZhtnSQ1Rcxvr7ZH%2bMWqtruDbM%3d"
    },
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "nE8DYUC0lF%2bFe3nk6lJ7nh9oqJCDbAdLfMYqcAbCxUU%3d",
      "team": "Chasing Shade",
      "score": "2",
      "oppScore": "1",
      "opp": "Spicey [Freshman]",
      "oppId": "EId%2fcud3k6xolV7x2mncVuvy0sjcgMA3rN6SbCatc4o%3d"
    },
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "PiI99F7b%2fSCX50pdfO%2fY17yMATS%2bPCC83PHosEfnUKY%3d",
      "team": "Chasing Shade",
      "score": "2",
      "oppScore": "0",
      "opp": "Fire Hawks",
      "oppId": "nWdDUCpVWRy85%2fC17qDdIcmCdIiRPYnq1BljTV4U1fA%3d"
    },
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "x%2fSOZuXtymkOhnyGiiORZujfvZd8RMWjIH4jbjBpscc%3d",
      "team": "Chasing Shade",
      "score": "0",
      "oppScore": "2",
      "opp": "Icey Boyz",
      "oppId": "o7pFuDOxPP07DaaI1szMOj%2bdcR2%2bdFD1bt2gOuGwIg8%3d"
    }
  ],
  "EId%2fcud3k6xolV7x2mncVuvy0sjcgMA3rN6SbCatc4o%3d": [
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "JNCerlfTP27uq2raosX2wVhSEwVjvzVxZ3jwLl%2fd2P4%3d",
      "team": "Spicey-Freshman",
      "score": "2",
      "oppScore": "0",
      "opp": "Fire Hawks",
      "oppId": "nWdDUCpVWRy85%2fC17qDdIcmCdIiRPYnq1BljTV4U1fA%3d"
    },
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "VCxyhJE%2bOk3LhnkAQVb8grHJZz3ufpTres4p%2fDUo1GM%3d",
      "team": "Spicey-Freshman",
      "score": "2",
      "oppScore": "1",
      "opp": "ISO Cardio",
      "oppId": "B8Fv3voYTJVwF%2fp5tUAiPVnDYZIDh2i0vxjBE4ICYSQ%3d"
    },
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "nE8DYUC0lF%2bFe3nk6lJ7nh9oqJCDbAdLfMYqcAbCxUU%3d",
      "team": "Spicey-Freshman",
      "score": "1",
      "oppScore": "2",
      "opp": "Chasing Shade",
      "oppId": "IwzskuT6MrOKXV6riTdmX6OoQJHWmMDsR0sivF2Yaf8%3d"
    },
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "mv4eoG2yTvt3%2bA3IDptVKZFE5dW7U3Tnk3iZa%2fv5Dnw%3d",
      "team": "Spicey-Freshman",
      "score": "1",
      "oppScore": "2",
      "opp": "Icey Boyz",
      "oppId": "o7pFuDOxPP07DaaI1szMOj%2bdcR2%2bdFD1bt2gOuGwIg8%3d"
    },
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "URHPYAv4ODdxjrb2TPLRJkFLM8oRBIXBFxM8UvIvsfk%3d",
      "team": "Spicey-Freshman",
      "score": "0",
      "oppScore": "2",
      "opp": "Little John",
      "oppId": "AQqIfhow3GwkB0TLWgZhtnSQ1Rcxvr7ZH%2bMWqtruDbM%3d"
    }
  ],
  "nWdDUCpVWRy85%2fC17qDdIcmCdIiRPYnq1BljTV4U1fA%3d": [
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "JNCerlfTP27uq2raosX2wVhSEwVjvzVxZ3jwLl%2fd2P4%3d",
      "team": "Fire Hawks",
      "score": "0",
      "oppScore": "2",
      "opp": "Spicey [Freshman]",
      "oppId": "EId%2fcud3k6xolV7x2mncVuvy0sjcgMA3rN6SbCatc4o%3d"
    },
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "6TuT2OdZlYEhINMbWeZ51ZaXNhErFXruZdJl6K4ndH4%3d",
      "team": "Fire Hawks",
      "score": "0",
      "oppScore": "2",
      "opp": "Icey Boyz",
      "oppId": "o7pFuDOxPP07DaaI1szMOj%2bdcR2%2bdFD1bt2gOuGwIg8%3d"
    },
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "0O%2bLCNsd6q19RobjHsyXBvdHVoafLC%2fE7wRXGzfxx5I%3d",
      "team": "Fire Hawks",
      "score": "0",
      "oppScore": "2",
      "opp": "Little John",
      "oppId": "AQqIfhow3GwkB0TLWgZhtnSQ1Rcxvr7ZH%2bMWqtruDbM%3d"
    },
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "PiI99F7b%2fSCX50pdfO%2fY17yMATS%2bPCC83PHosEfnUKY%3d",
      "team": "Fire Hawks",
      "score": "0",
      "oppScore": "2",
      "opp": "Chasing Shade",
      "oppId": "IwzskuT6MrOKXV6riTdmX6OoQJHWmMDsR0sivF2Yaf8%3d"
    },
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "7FZgzrWQou3u1r29iVd3VG4RiO9UssMXLq3ocRb5KI0%3d",
      "team": "Fire Hawks",
      "score": "0",
      "oppScore": "2",
      "opp": "ISO Cardio",
      "oppId": "B8Fv3voYTJVwF%2fp5tUAiPVnDYZIDh2i0vxjBE4ICYSQ%3d"
    }
  ],
  "B8Fv3voYTJVwF%2fp5tUAiPVnDYZIDh2i0vxjBE4ICYSQ%3d": [
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "xync%2faUM9JaZ9r33WtScsVYJArL2%2bdK7keEQ%2b5Wv9ro%3d",
      "team": "ISO Cardio",
      "score": "1",
      "oppScore": "2",
      "opp": "Chasing Shade",
      "oppId": "IwzskuT6MrOKXV6riTdmX6OoQJHWmMDsR0sivF2Yaf8%3d"
    },
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "VCxyhJE%2bOk3LhnkAQVb8grHJZz3ufpTres4p%2fDUo1GM%3d",
      "team": "ISO Cardio",
      "score": "1",
      "oppScore": "2",
      "opp": "Spicey [Freshman]",
      "oppId": "EId%2fcud3k6xolV7x2mncVuvy0sjcgMA3rN6SbCatc4o%3d"
    },
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "RIzukMkojGGOzZpKk1QYsbPdqXjNCbAMoUAGgDwrFSM%3d",
      "team": "ISO Cardio",
      "score": "0",
      "oppScore": "2",
      "opp": "Icey Boyz",
      "oppId": "o7pFuDOxPP07DaaI1szMOj%2bdcR2%2bdFD1bt2gOuGwIg8%3d"
    },
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "0GOjGi1z4h4AWqKXk6rslQUTvSHIOhPUUfW70xmSf6Y%3d",
      "team": "ISO Cardio",
      "score": "0",
      "oppScore": "2",
      "opp": "Little John",
      "oppId": "AQqIfhow3GwkB0TLWgZhtnSQ1Rcxvr7ZH%2bMWqtruDbM%3d"
    },
    {
      "date": "March 13",
      "tournament": "AYU Spring 4v4",
      "tournamentId": "AYU-Spring-4v4",
      "gameId": "7FZgzrWQou3u1r29iVd3VG4RiO9UssMXLq3ocRb5KI0%3d",
      "team": "ISO Cardio",
      "score": "2",
      "oppScore": "0",
      "opp": "Fire Hawks",
      "oppId": "nWdDUCpVWRy85%2fC17qDdIcmCdIiRPYnq1BljTV4U1fA%3d"
    }
  ],
  "YLXPz%2fCNrXaNTSa14rMcZI2zV%2brkt0P1fA0UxcJlPaE%3d": [
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "Z9PvQJ9ICm1P7TaNkGNUj8hxeoAn5YPN0DoudPxViOg%3d",
      "team": "NOx",
      "score": "7",
      "oppScore": "15",
      "opp": "PowderHogs",
      "oppId": "4M9MJYyVOotcr31jr1X%2bW%2fnNzBLDCtMhEBgjHQi1W9s%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "0HUc6W1gIov0zRVJNJXRC%2fC581OYFWlCHK40GQauH%2fg%3d",
      "team": "NOx",
      "score": "6",
      "oppScore": "15",
      "opp": "Scythe",
      "oppId": "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "fXiKnOIcE9PTNkrlnOTWj47Ltmw1aapP%2fnqhjOkiVXI%3d",
      "team": "NOx",
      "score": "8",
      "oppScore": "12",
      "opp": "Sonoran Dog",
      "oppId": "WAPjwPL2R8YMCWgolwmIL1cqRoFxsqyk2gQeZ9iDOMA%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "HjtTPFHZHaIm8vV70v2TPYk9dqgBhijKdTbk4AqWags%3d",
      "team": "NOx",
      "score": "13",
      "oppScore": "12",
      "opp": "Colorado Cutthroat: Youth Club U-20 Boys",
      "oppId": "uCZC60On5XqvfotORPgB2aVS6YYypoZ%2bxRG1SToX3pc%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "FJizttpho0A2VJ7Ztwa6K9trsqi8YbqlYtLqEeaxyak%3d",
      "team": "NOx",
      "score": "15",
      "oppScore": "13",
      "opp": "Dawg Pound",
      "oppId": "cdHPsuclc2H0oeX5zO4ie6cA2mYipY1AN1i7fnLCrHU%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "%2fAjEcPRwBU0%2bB0fJoTsmpETBuL57rkkGZmPpk9KGYms%3d",
      "team": "NOx",
      "score": "4",
      "oppScore": "8",
      "opp": "Choice City Hops",
      "oppId": "8aw9r%2f7%2bx5K68IFW7B4Yeidcx%2fIpqRuvgzGi%2buM7%2b38%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "ObM3FYhD7F56UQcmhggtvqXk1HPNkqxVkeNOmL50bl0%3d",
      "team": "NOx",
      "score": "15",
      "oppScore": "5",
      "opp": "Supercell",
      "oppId": "%2bhSpC0vxzjqumEjEEoGhDiOz5%2bUgihYtLrNXn407Tbg%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "0KidyngaARF2JSThV1K9PWwJ3qJH3dtlXve6OtnyzG0%3d",
      "team": "NOx",
      "score": "6",
      "oppScore": "15",
      "opp": "Scythe",
      "oppId": "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "aG2DeXbE%2bUMIAUskFxtBP3akoKJbT5knNd69TyEfGGY%3d",
      "team": "NOx",
      "score": "15",
      "oppScore": "11",
      "opp": "STL Moonar",
      "oppId": "XslCVk8W7kUV6p1A54%2bck0Soa5yWKBsdq%2fdwbiJ%2f%2bkk%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "cBM94Ok0lcxtmlORo2KFWE%2fhOL33Wlhw%2fNJOlGVUu%2bc%3d",
      "team": "NOx",
      "score": "7",
      "oppScore": "15",
      "opp": "Kansas City Smokestack",
      "oppId": "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "JcOWmprUG0jq%2fJ%2bKJjPlT3bAgmZlCYCVr63pfc8kAr0%3d",
      "team": "NOx",
      "score": "15",
      "oppScore": "8",
      "opp": "Loaded Panda",
      "oppId": "8S%2f2XcUwQbLf0KM7yOmv3HEYXSrnLhAdwnHQvhMjxCY%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "4Nta6PhRKY2822cS6trXqlGqgl2i0aEQkl76hrk6REk%3d",
      "team": "NOx",
      "score": "7",
      "oppScore": "13",
      "opp": "DeMo",
      "oppId": "FdlFpUSGIahm89rZTXrHg2K85ugGqml2fjUWEPHtgPo%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "Qtxn5inCCi86tkbZFfpZtXycc79G2I4I5WQ01VZzvKY%3d",
      "team": "NOx",
      "score": "12",
      "oppScore": "6",
      "opp": "Overdrive",
      "oppId": "cfApDIqN4eoe%2fr26h%2b8fQj3s9XKGa9nJC8lT5uya7gM%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "dyQD0glN2qc6ZAskg4h%2bjlAGTjYwI0uW6cxruhGZM%2fw%3d",
      "team": "NOx",
      "score": "13",
      "oppScore": "5",
      "opp": "THE BODY",
      "oppId": "nyLl78Eg24EtJDKG0oHsU9e%2fg0hrOYqklm7h8m4R0Jo%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "J3S8W%2bDFpY8IhSeBGiskIZXUsdsYNxXdmjsZwwp6AB4%3d",
      "team": "NOx",
      "score": "6",
      "oppScore": "12",
      "opp": "Scythe",
      "oppId": "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "l7ZzeJLrADxtdAZMDxIYDomffHMpO%2fTIPaZttwTteKc%3d",
      "team": "NOx",
      "score": "6",
      "oppScore": "11",
      "opp": "Scythe",
      "oppId": "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "SP1VUYmerlZQfFNZ57i0dgeiOYtn%2bA2iHJtgiKcT1n0%3d",
      "team": "NOx",
      "score": "3",
      "oppScore": "13",
      "opp": "Cryptic",
      "oppId": "Qhc%2fzwjxDlH6UTqMQqHKE2ms56DaeAMWMHQJCBwAjIw%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "DOlopRQHskXsEmU7E6rbmwdXj%2ft%2fH0hCT%2bG1zEwYmpU%3d",
      "team": "NOx",
      "score": "13",
      "oppScore": "10",
      "opp": "Overdrive",
      "oppId": "cfApDIqN4eoe%2fr26h%2b8fQj3s9XKGa9nJC8lT5uya7gM%3d"
    }
  ],
  "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d": [
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "0HUc6W1gIov0zRVJNJXRC%2fC581OYFWlCHK40GQauH%2fg%3d",
      "team": "Scythe",
      "score": "15",
      "oppScore": "6",
      "opp": "NOx",
      "oppId": "YLXPz%2fCNrXaNTSa14rMcZI2zV%2brkt0P1fA0UxcJlPaE%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "EvJS9yFkLU1vePRwwOJGA9ns0EqSu%2fe3jRLyFPrrY%2bA%3d",
      "team": "Scythe",
      "score": "15",
      "oppScore": "8",
      "opp": "PowderHogs",
      "oppId": "4M9MJYyVOotcr31jr1X%2bW%2fnNzBLDCtMhEBgjHQi1W9s%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "%2bO7Eithj0KwWroVqvWOU7KKnXSkqVTwSL3W5bQZ2edA%3d",
      "team": "Scythe",
      "score": "14",
      "oppScore": "13",
      "opp": "Drought",
      "oppId": "ksz2i7%2bjkkoIQzWKSrtEphZr9icgiUTPL3Ixum49pfY%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "tNBanFnwOu3L1E9oLD%2bURU69%2f6nBZ7mbXQLJeA%2bkWRs%3d",
      "team": "Scythe",
      "score": "12",
      "oppScore": "14",
      "opp": "Sonoran Dog",
      "oppId": "WAPjwPL2R8YMCWgolwmIL1cqRoFxsqyk2gQeZ9iDOMA%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "W%2bSHiSBr39%2fD49bYnT1cavemJxjcgow6EbcJnSkjvUY%3d",
      "team": "Scythe",
      "score": "15",
      "oppScore": "12",
      "opp": "COSmic U-20 Boys",
      "oppId": "ibMdv4bC%2b0jt2%2fJSNUbBFKCLQxYMWOpm9yxWtbkm%2ff8%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "LO0wXL97tff%2fBZ3dzcJt%2b8Oen0lQiL8oyP%2ffIbb1hqM%3d",
      "team": "Scythe",
      "score": "2",
      "oppScore": "8",
      "opp": "Kansas City Smokestack",
      "oppId": "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "zrZU6eexIUaH4qYtTNR4f%2bBSOvlQ2BC5ztCSQC25q7o%3d",
      "team": "Scythe",
      "score": "15",
      "oppScore": "7",
      "opp": "STL Moonar",
      "oppId": "XslCVk8W7kUV6p1A54%2bck0Soa5yWKBsdq%2fdwbiJ%2f%2bkk%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "0KidyngaARF2JSThV1K9PWwJ3qJH3dtlXve6OtnyzG0%3d",
      "team": "Scythe",
      "score": "15",
      "oppScore": "6",
      "opp": "NOx",
      "oppId": "YLXPz%2fCNrXaNTSa14rMcZI2zV%2brkt0P1fA0UxcJlPaE%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "KIQVka97WjUM%2bc3ulBb2Q8tSDQUHVYKZkufHHNPQzLo%3d",
      "team": "Scythe",
      "score": "15",
      "oppScore": "1",
      "opp": "Supercell",
      "oppId": "%2bhSpC0vxzjqumEjEEoGhDiOz5%2bUgihYtLrNXn407Tbg%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "wxYtjsq%2fyUPwvSEPg6DrTJQvqOJxTc08zIc18Kq%2fq%2bM%3d",
      "team": "Scythe",
      "score": "15",
      "oppScore": "8",
      "opp": "Nomads",
      "oppId": "clyQEvW53ITGsU%2bGFSkoVd77O856fO%2f%2fPStXV2bXfy4%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "d8jt%2f7PMIWzYiCqExh1SUOD3jrvMM9u%2f4Cd0qU001Y0%3d",
      "team": "Scythe",
      "score": "15",
      "oppScore": "11",
      "opp": "Mallard II",
      "oppId": "waPLoije6zVsjVUcOXTlGSFZ10PPabaoS6kk%2bnW2NCo%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "10h09nLxMxfXndmrPkSu161Q885uGnFncpeJe1Clo%2bo%3d",
      "team": "Scythe",
      "score": "15",
      "oppScore": "12",
      "opp": "Kansas City Smokestack",
      "oppId": "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "pop00%2fh25JbIk0N%2fNSZj%2fApftShzHTt5BuE7VgBq6aE%3d",
      "team": "Scythe",
      "score": "11",
      "oppScore": "12",
      "opp": "Kansas City Smokestack",
      "oppId": "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "CeS6BhsdbfSh%2bcaTX1dZF9w%2fvg8%2b%2fYZCleF4ijSA6ak%3d",
      "team": "Scythe",
      "score": "13",
      "oppScore": "5",
      "opp": "Overdrive",
      "oppId": "cfApDIqN4eoe%2fr26h%2b8fQj3s9XKGa9nJC8lT5uya7gM%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "J3S8W%2bDFpY8IhSeBGiskIZXUsdsYNxXdmjsZwwp6AB4%3d",
      "team": "Scythe",
      "score": "12",
      "oppScore": "6",
      "opp": "NOx",
      "oppId": "YLXPz%2fCNrXaNTSa14rMcZI2zV%2brkt0P1fA0UxcJlPaE%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "l7ZzeJLrADxtdAZMDxIYDomffHMpO%2fTIPaZttwTteKc%3d",
      "team": "Scythe",
      "score": "11",
      "oppScore": "6",
      "opp": "NOx",
      "oppId": "YLXPz%2fCNrXaNTSa14rMcZI2zV%2brkt0P1fA0UxcJlPaE%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "XGM9tuALhZCN%2fVMRcHfA%2fptRnW637G4QesS7dMXq0js%3d",
      "team": "Scythe",
      "score": "10",
      "oppScore": "12",
      "opp": "DeMo",
      "oppId": "FdlFpUSGIahm89rZTXrHg2K85ugGqml2fjUWEPHtgPo%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "eUbOR9%2bkeQEgtzjtcVP7mFGmy%2fMB4Nd3gqM%2f1iAjRVU%3d",
      "team": "Scythe",
      "score": "W",
      "oppScore": "F",
      "opp": "Knights of Ni",
      "oppId": "dgH60oTrddgw%2bSl2xkIe%2fis%2fu1HY9ndk1MZTdN4OiAs%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "l11kjhCYZz60BhS4H7%2fK%2bjA2M3lpQHzJ6yBNab7UN9k%3d",
      "team": "Scythe",
      "score": "11",
      "oppScore": "13",
      "opp": "Kansas City Smokestack",
      "oppId": "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "mNhi9%2bAtl75dpT%2b7falNtyfI%2bPP1s2I8OycDok5fqfo%3d",
      "team": "Scythe",
      "score": "12",
      "oppScore": "13",
      "opp": "DeMo",
      "oppId": "FdlFpUSGIahm89rZTXrHg2K85ugGqml2fjUWEPHtgPo%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "%2bv0nv6el1jedrZEdHVEmmySSfWgahaqGmVuyNAdNfBw%3d",
      "team": "Scythe",
      "score": "9",
      "oppScore": "13",
      "opp": "Knights of Ni",
      "oppId": "dgH60oTrddgw%2bSl2xkIe%2fis%2fu1HY9ndk1MZTdN4OiAs%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "0mq8Z7tpJN9PPVJJL0%2bFNbKdx3A3Rwo%2bdGhvxcYXzNc%3d",
      "team": "Scythe",
      "score": "13",
      "oppScore": "6",
      "opp": "Cryptic",
      "oppId": "Qhc%2fzwjxDlH6UTqMQqHKE2ms56DaeAMWMHQJCBwAjIw%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "kcxFjNd%2f3MNc4fDxIkbOeu5uqVIc5VSlLsQPGOlxhM4%3d",
      "team": "Scythe",
      "score": "13",
      "oppScore": "6",
      "opp": "Mallard II",
      "oppId": "waPLoije6zVsjVUcOXTlGSFZ10PPabaoS6kk%2bnW2NCo%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "%2b97Za6MdgO60koTkFrCUG3Z51x9AyeS2gxGQWEtCes0%3d",
      "team": "Scythe",
      "score": "13",
      "oppScore": "8",
      "opp": "Knights of Ni",
      "oppId": "dgH60oTrddgw%2bSl2xkIe%2fis%2fu1HY9ndk1MZTdN4OiAs%3d"
    }
  ],
  "4M9MJYyVOotcr31jr1X%2bW%2fnNzBLDCtMhEBgjHQi1W9s%3d": [
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "Z9PvQJ9ICm1P7TaNkGNUj8hxeoAn5YPN0DoudPxViOg%3d",
      "team": "PowderHogs",
      "score": "15",
      "oppScore": "7",
      "opp": "NOx",
      "oppId": "YLXPz%2fCNrXaNTSa14rMcZI2zV%2brkt0P1fA0UxcJlPaE%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "EvJS9yFkLU1vePRwwOJGA9ns0EqSu%2fe3jRLyFPrrY%2bA%3d",
      "team": "PowderHogs",
      "score": "8",
      "oppScore": "15",
      "opp": "Scythe",
      "oppId": "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "Lf4FJJCDVSG9JOLWDuGofPO%2fsEJSsPJ4LWovvmWu08U%3d",
      "team": "PowderHogs",
      "score": "14",
      "oppScore": "15",
      "opp": "COSmic U-20 Boys",
      "oppId": "ibMdv4bC%2b0jt2%2fJSNUbBFKCLQxYMWOpm9yxWtbkm%2ff8%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "RAxeYT96lIyMALcobXItPfCh59h%2f1XGu5%2bHJuFnXbXQ%3d",
      "team": "PowderHogs",
      "score": "12",
      "oppScore": "15",
      "opp": "Nomads",
      "oppId": "clyQEvW53ITGsU%2bGFSkoVd77O856fO%2f%2fPStXV2bXfy4%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "law418UccJkAdzpT6sEon3E91bqCa5r2PvK33WBV0ME%3d",
      "team": "PowderHogs",
      "score": "11",
      "oppScore": "15",
      "opp": "Colorado Cutthroat: Youth Club U-20 Boys",
      "oppId": "uCZC60On5XqvfotORPgB2aVS6YYypoZ%2bxRG1SToX3pc%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "2WR4N04Kti0yVNQIwbiEcVfDwPzrHal%2b9F1fGykXI7g%3d",
      "team": "PowderHogs",
      "score": "7",
      "oppScore": "4",
      "opp": "Dawg Pound",
      "oppId": "cdHPsuclc2H0oeX5zO4ie6cA2mYipY1AN1i7fnLCrHU%3d"
    },
    {
      "date": "August 21",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "hYTy3cRX9DAJNNUvSWNy3dZqeo39JIftx4wRyDSHIWg%3d",
      "team": "PowderHogs",
      "score": "9",
      "oppScore": "13",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    },
    {
      "date": "August 21",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "%2f9MqJfqO5lu0Va2dj2jBnmtz2QtPOtdQ4Bib%2bKgTpqE%3d",
      "team": "PowderHogs",
      "score": "9",
      "oppScore": "13",
      "opp": "Offshore",
      "oppId": "z3CZ5zLpuh4%2b6lmsKpQSH%2fDpWRfMktj5DGqERnXkcXY%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "tGKdKu5JqWIiLIYy0i70%2bZZ%2bfdJf3jQNYYgWAREFjSc%3d",
      "team": "PowderHogs",
      "score": "8",
      "oppScore": "13",
      "opp": "Zyzzyva",
      "oppId": "Yfu4RKEHOC158Q5KQGT5xHxtk5hGHKp2RxFoXMEKk%2fA%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "Y3dt8N76pIFin8QdG9iLPIZVECMbC16TU39SAWcBRII%3d",
      "team": "PowderHogs",
      "score": "7",
      "oppScore": "13",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "qV%2bJx85wMKFhS32VM2d99lyES%2fyz7Zki4Vb%2fiB1PdvA%3d",
      "team": "PowderHogs",
      "score": "12",
      "oppScore": "13",
      "opp": "Seven Out",
      "oppId": "EKT%2b4wN3oW3%2bvTZF1BOVy%2fpLrku%2frikWkw8iDqstIOQ%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "%2bvwM2fHUUFqtKbe5RK7KzfjHa6cpewItFyaI4OSJxBI%3d",
      "team": "PowderHogs",
      "score": "4",
      "oppScore": "13",
      "opp": "Sawtooth",
      "oppId": "O%2fOvOBARFYfl3iP1PCdqMwd9SiYSMEiR%2b0E8MZeOOD4%3d"
    },
    {
      "date": "August 25",
      "tournament": "Utah Round Robin",
      "tournamentId": "Utah-Round-Robin",
      "gameId": "t3xP8GeS8QKiBn5sNkXrZbLVDeJGJp5LGnNpzvF1o7Q%3d",
      "team": "PowderHogs",
      "score": "3",
      "oppScore": "11",
      "opp": "Pando",
      "oppId": "CWW3TWk5yeYKuKEW8USBkbYZz5DyfbKjfswKBgSzYsA%3d"
    },
    {
      "date": "August 25",
      "tournament": "Utah Round Robin",
      "tournamentId": "Utah-Round-Robin",
      "gameId": "nWIax3zEysFNREfXnYShy2zNZy3B07tFXJs0NcJacTM%3d",
      "team": "PowderHogs",
      "score": "8",
      "oppScore": "11",
      "opp": "Utah Hatu",
      "oppId": "%2bmdoaqywGR6P4rhXTSYYxtvjQhBX094atWIs4EEL1Ys%3d"
    },
    {
      "date": "August 25",
      "tournament": "Utah Round Robin",
      "tournamentId": "Utah-Round-Robin",
      "gameId": "ZLlwjcU5KHKqRN%2byW5L0aRhnzej9SNfTuaFKMolwcUk%3d",
      "team": "PowderHogs",
      "score": "11",
      "oppScore": "4",
      "opp": "Old Ephraim",
      "oppId": "8pwKgFPj6U4BEx29pAWNsvAmMgzhAWQy%2fWTSNOSoj3c%3d"
    }
  ],
  "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d": [
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "ANLglh0K260uH7rQvadUobPZKpqyeUUr1fhzD44OzLA%3d",
      "team": "Inception",
      "score": "15",
      "oppScore": "9",
      "opp": "Choice City Hops",
      "oppId": "8aw9r%2f7%2bx5K68IFW7B4Yeidcx%2fIpqRuvgzGi%2buM7%2b38%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "MEwsqkQRujYzl2xRxhbsZCToYz29sVam%2fwOTDgn%2fcAE%3d",
      "team": "Inception",
      "score": "15",
      "oppScore": "11",
      "opp": "Kansas City Smokestack",
      "oppId": "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "p5DY08PS2fS61bZ3ZVU%2by2n1Td3rLiSh3ZumLSJQOL4%3d",
      "team": "Inception",
      "score": "13",
      "oppScore": "10",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "FoIlrSoP1mP4VFIHEIWHBVRUgLn4FMFfUUWP4D46wyw%3d",
      "team": "Inception",
      "score": "11",
      "oppScore": "10",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "fo6dhA%2f2gXbv4EEzRf3M3Z%2fb0Mjj2B6W8qID8c6cn4I%3d",
      "team": "Inception",
      "score": "12",
      "oppScore": "10",
      "opp": "Drought",
      "oppId": "ksz2i7%2bjkkoIQzWKSrtEphZr9icgiUTPL3Ixum49pfY%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "T89vr1eBLqrlQNlcYsyhaRmqs00TMEeBUSXA0GbEJuE%3d",
      "team": "Inception",
      "score": "F",
      "oppScore": "W",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "Snauo1xvLBrLXZefXtLuQ4OYrLEKoQZBNIPUcIJi3dE%3d",
      "team": "Inception",
      "score": "12",
      "oppScore": "7",
      "opp": "Battery",
      "oppId": "XgsOT%2bl5FkWgZoV7cQAy4vMUDJS3ygcjNozHGHsTYUE%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "oCxMXnJLJwO%2fRcEFabE5GECIh7%2bg4bra4yxUkIKTgoI%3d",
      "team": "Inception",
      "score": "10",
      "oppScore": "9",
      "opp": "General Strike",
      "oppId": "%2bAa3yq8xXOgWBEYZi%2fAT%2fiiGNJfFLcQeXn3x6sH%2fmhA%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "KhJ%2fNNOV5dlwpdKI4gQY55CKsSzx1pT97zQPghTGlFY%3d",
      "team": "Inception",
      "score": "15",
      "oppScore": "11",
      "opp": "Ghost Train",
      "oppId": "Tqxb9%2fb2Hxtbg%2f54ifErhjS%2bc%2fBnacDZk5RhQ%2fd%2b%2f5g%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "o4ZRywMiRsxsdm9XatOT0GUjz3xAgg9UHPAhsasbtMc%3d",
      "team": "Inception",
      "score": "10",
      "oppScore": "15",
      "opp": "SoCal Condors",
      "oppId": "Hs8IYCPj2pf9Nump3cK24FlAdOgCZVYhTQKGBSXFLXA%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "dSIWFglC6DnQBvJsJlaT3AisQef2hP9LDlCKIvIGpiE%3d",
      "team": "Inception",
      "score": "8",
      "oppScore": "10",
      "opp": "Clutch",
      "oppId": "JDc%2bBsCjGUX7gdebRqmdPvq1u2rFlZ1giM6BgTaL5Lk%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "HNafduAvm4VFJ%2bb8uDAt5F5pOKn0KYjOmvL445p1W04%3d",
      "team": "Inception",
      "score": "8",
      "oppScore": "13",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "LRAr6cfDwXnoyjI58fsLMjxvG%2bPZWmuQ72wVaeNVBlI%3d",
      "team": "Inception",
      "score": "2",
      "oppScore": "15",
      "opp": "Chicago Machine",
      "oppId": "a63rCMScaUnSuWtwLw1rcbPhZh4%2bUKiYzBab%2b52i9Pk%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "qSLJ0IlxEttAZM%2bl%2bMfomb%2f0JhBEoLBIS5uOiBAtUl8%3d",
      "team": "Inception",
      "score": "6",
      "oppScore": "15",
      "opp": "Sub Zero",
      "oppId": "8R4mmVt8rvJ%2bEDsQVxGD5KkmF8Rfnl3EHt4CpzxANac%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "Wq9%2bakluwH2OqD9o0bSidbdjPoIurUADNawTxD3sCJM%3d",
      "team": "Inception",
      "score": "4",
      "oppScore": "15",
      "opp": "Revolver",
      "oppId": "Kem%2foxDeD8r5XN1HNW8eHeRyzfBqnWTRaPCmLck6F1I%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "fKLSwc7rckLfV9EkkmgrmRRcYf8%2bULngq7UTYN%2bc62s%3d",
      "team": "Inception",
      "score": "5",
      "oppScore": "15",
      "opp": "Pittsburgh Temper",
      "oppId": "u%2bSlJ3XL7d589O5YW%2bbHhuzzJ855SfIO%2bGHlzwH3w3M%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "O0JclbF1V7%2b45PNN3%2fwKgsL1J%2f6T3KS8WAHAzEpgI%2fM%3d",
      "team": "Inception",
      "score": "7",
      "oppScore": "15",
      "opp": "Sub Zero",
      "oppId": "8R4mmVt8rvJ%2bEDsQVxGD5KkmF8Rfnl3EHt4CpzxANac%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "aDS%2bq%2f%2bnk8wqyw2gMvhV4AF40lJDd6icSuZbrGm1hx0%3d",
      "team": "Inception",
      "score": "6",
      "oppScore": "15",
      "opp": "Sprout",
      "oppId": "QyN2sOWwUrO10Eheq9tje8Trmro0kiO6dKpnQzSh9q4%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "2PcpbbKca62X%2f4QHbVnb4Lrj5Ut%2fIrM%2bH5SSjnJDg5o%3d",
      "team": "Inception",
      "score": "4",
      "oppScore": "15",
      "opp": "Tanasi",
      "oppId": "7MICo92AgV%2flT4d8dpq%2bhDNyJFhVtTVq8%2f94L7J8idw%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "xCVi5kXtIGBMXs6x%2bcBz2xmbBLxcCgwq3f5hxK0t518%3d",
      "team": "Inception",
      "score": "10",
      "oppScore": "9",
      "opp": "Phoenix",
      "oppId": "gSiJoSXGsIFqzcdqVTCYpgvQv7XMC%2b0qfhx6EDDLgrQ%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "vwdK8K2RjiXrp8xh6rY67kWLauqMT4r529imgIQS0wk%3d",
      "team": "Inception",
      "score": "10",
      "oppScore": "15",
      "opp": "Colonels",
      "oppId": "7Xv2AHNIg3Y%2fm1y7qWkHUz%2b9KT3NZFOCMpW7gBaxnfs%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "ikshrvI9oNShfyPY1mCPiAd86ld%2fp1nmploYobZVDyg%3d",
      "team": "Inception",
      "score": "9",
      "oppScore": "12",
      "opp": "Shade",
      "oppId": "Lh0BmORltuq%2bkEq6e8QTGFiUkZuG5GuP4DNNS5vaaAc%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "lRbKO%2fVD5cymDZws7IKjnMFse%2f%2ftfd6%2fF90uKpEctG4%3d",
      "team": "Inception",
      "score": "8",
      "oppScore": "11",
      "opp": "UpRoar",
      "oppId": "JYJdk5dDNlc6X6evPtQ6K9GWMwDy1xmzavYjS6uyHaE%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "tIjgjYQpxfKvxgsW21LVEnxGpK%2bzsPT3%2frX9fXvVOtk%3d",
      "team": "Inception",
      "score": "14",
      "oppScore": "15",
      "opp": "I-69",
      "oppId": "a64fDYPntTHPU4vghTeNstb32Z0JFlq528bbATJx%2bbs%3d"
    }
  ],
  "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d": [
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "rjktbLCdbyMmk8U5eUG2HNRwNBLC%2fEQ0IyRrwLv4FMo%3d",
      "team": "Fungi",
      "score": "15",
      "oppScore": "12",
      "opp": "Dawg Pound",
      "oppId": "cdHPsuclc2H0oeX5zO4ie6cA2mYipY1AN1i7fnLCrHU%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "RmA3uOXnWL403nOCulTuz203gq9oVAt759vScdbMBDE%3d",
      "team": "Fungi",
      "score": "11",
      "oppScore": "13",
      "opp": "Drought",
      "oppId": "ksz2i7%2bjkkoIQzWKSrtEphZr9icgiUTPL3Ixum49pfY%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "TvogWh6XjjQQWupD6EvRNqcAkbLhMo1wPGOkQW08AAo%3d",
      "team": "Fungi",
      "score": "15",
      "oppScore": "6",
      "opp": "Choice City Hops",
      "oppId": "8aw9r%2f7%2bx5K68IFW7B4Yeidcx%2fIpqRuvgzGi%2buM7%2b38%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "FoIlrSoP1mP4VFIHEIWHBVRUgLn4FMFfUUWP4D46wyw%3d",
      "team": "Fungi",
      "score": "10",
      "oppScore": "11",
      "opp": "Inception",
      "oppId": "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "BeeiCBekRuIp1wDz%2bW%2f950le9llog8m9LqTEkwVMPUM%3d",
      "team": "Fungi",
      "score": "13",
      "oppScore": "14",
      "opp": "Kansas City Smokestack",
      "oppId": "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "L%2bssTGsrYe0gVle%2fnxZ%2fNeIAFALbKDgtC8pcQ19Qn2E%3d",
      "team": "Fungi",
      "score": "8",
      "oppScore": "3",
      "opp": "COSmic U-20 Boys",
      "oppId": "ibMdv4bC%2b0jt2%2fJSNUbBFKCLQxYMWOpm9yxWtbkm%2ff8%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "1TU3MJt7ZhvX9EO%2fvFqo2bORaakKklEK8QvVsnMtqi0%3d",
      "team": "Fungi",
      "score": "14",
      "oppScore": "12",
      "opp": "Tanasi",
      "oppId": "7MICo92AgV%2flT4d8dpq%2bhDNyJFhVtTVq8%2f94L7J8idw%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "BuvFFktYQ1eN7XRCfyNwdMOst245%2f8D7tsswiQVgGu0%3d",
      "team": "Fungi",
      "score": "9",
      "oppScore": "11",
      "opp": "Clutch",
      "oppId": "JDc%2bBsCjGUX7gdebRqmdPvq1u2rFlZ1giM6BgTaL5Lk%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "m1jRa8yPVq2Lqd%2feK9RpgyffrdIFoXITp87Ni4Fdsy4%3d",
      "team": "Fungi",
      "score": "15",
      "oppScore": "8",
      "opp": "Battery",
      "oppId": "XgsOT%2bl5FkWgZoV7cQAy4vMUDJS3ygcjNozHGHsTYUE%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "308osjRVRqduX%2fuVVdf4BaT8ljh9C8sEk2KHrHrQ0CY%3d",
      "team": "Fungi",
      "score": "6",
      "oppScore": "15",
      "opp": "Johnny Bravo",
      "oppId": "AKF9iH0VKK2mK%2fzpE9qJYeqGR%2ff%2fBETu1dm16hUGaVo%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "0rskRpQQNHY9z6VOiZjKIM48N%2fng6XxyikxdYBfGMO0%3d",
      "team": "Fungi",
      "score": "8",
      "oppScore": "14",
      "opp": "GOAT",
      "oppId": "6IFGDipIDG9l%2br4E8TUyujjAslzlgFQUH9y5j9M0G4g%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "HNafduAvm4VFJ%2bb8uDAt5F5pOKn0KYjOmvL445p1W04%3d",
      "team": "Fungi",
      "score": "13",
      "oppScore": "8",
      "opp": "Inception",
      "oppId": "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "QqSbTOJgNyXUoPQZ2akpRVkILkS9GN%2fmmUkdrDMwVZQ%3d",
      "team": "Fungi",
      "score": "11",
      "oppScore": "13",
      "opp": "Ghost Train",
      "oppId": "Tqxb9%2fb2Hxtbg%2f54ifErhjS%2bc%2fBnacDZk5RhQ%2fd%2b%2f5g%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "zQRz0P2e9SEBd%2fYRt2BQoSn8sBDnOK3zQsz%2bft6FBQQ%3d",
      "team": "Fungi",
      "score": "9",
      "oppScore": "11",
      "opp": "Streetgang",
      "oppId": "%2fo6V9EACiot%2bzfjGzj7GQtyBnMoDlmHGs3Nl35pLoeU%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "fd3KX79kBq9fqs0xAJfG2v%2fsrWD2iaQera4QTIggN00%3d",
      "team": "Fungi",
      "score": "9",
      "oppScore": "12",
      "opp": "Dark Star",
      "oppId": "c9pW2q80DagMAQ3MIlN4qV7%2fnufSjZapIvSzbu5OU%2f4%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "rT3od05SgpW4rzgX1Gb3%2bWbOnvrUu%2fJINsSgltcm60k%3d",
      "team": "Fungi",
      "score": "11",
      "oppScore": "8",
      "opp": "Switchback",
      "oppId": "XNBtJjuPsH6Zq%2bmVmPwL1Zo0%2bl5xYeh3mWrrpzn7CTk%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "9Pp8h9K6LymxnMSc5%2b%2fFxwnXMcrldBOTuLoV7Jal0Q8%3d",
      "team": "Fungi",
      "score": "15",
      "oppScore": "7",
      "opp": "Battery",
      "oppId": "XgsOT%2bl5FkWgZoV7cQAy4vMUDJS3ygcjNozHGHsTYUE%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "D4hQTtTag0BeSSwIdMaBQY1R73BPueYL9ZjTeD6L5YA%3d",
      "team": "Fungi",
      "score": "14",
      "oppScore": "15",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "6ihUNQApTIaanbN0KykSjsQPGJqmB0vl5JejNv3mfgI%3d",
      "team": "Fungi",
      "score": "15",
      "oppScore": "12",
      "opp": "Ghost Train",
      "oppId": "Tqxb9%2fb2Hxtbg%2f54ifErhjS%2bc%2fBnacDZk5RhQ%2fd%2b%2f5g%3d"
    },
    {
      "date": "August 21",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "GrHUJrW9qkVWO%2fJ9vpu8GD2w5zeabW7%2fPo3Z1uR8UPo%3d",
      "team": "Fungi",
      "score": "15",
      "oppScore": "11",
      "opp": "Utah Hatu",
      "oppId": "%2bmdoaqywGR6P4rhXTSYYxtvjQhBX094atWIs4EEL1Ys%3d"
    },
    {
      "date": "August 21",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "PlagUKjRMja8zRSLceWGjWqWLmlSJqVN0XDLnX%2bNUSI%3d",
      "team": "Fungi",
      "score": "12",
      "oppScore": "8",
      "opp": "Switchback",
      "oppId": "XNBtJjuPsH6Zq%2bmVmPwL1Zo0%2bl5xYeh3mWrrpzn7CTk%3d"
    },
    {
      "date": "August 21",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "BDSWA3WKQlKhEhxuQCk3dszdiySXAcAqUrWdUBLU2xM%3d",
      "team": "Fungi",
      "score": "13",
      "oppScore": "11",
      "opp": "Zyzzyva",
      "oppId": "Yfu4RKEHOC158Q5KQGT5xHxtk5hGHKp2RxFoXMEKk%2fA%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "Y3dt8N76pIFin8QdG9iLPIZVECMbC16TU39SAWcBRII%3d",
      "team": "Fungi",
      "score": "13",
      "oppScore": "7",
      "opp": "PowderHogs",
      "oppId": "4M9MJYyVOotcr31jr1X%2bW%2fnNzBLDCtMhEBgjHQi1W9s%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "8oImPzUzI5rXAjWvGcqkptytFWJ5%2bWLR0rQrHvD9wCw%3d",
      "team": "Fungi",
      "score": "13",
      "oppScore": "11",
      "opp": "Zyzzyva",
      "oppId": "Yfu4RKEHOC158Q5KQGT5xHxtk5hGHKp2RxFoXMEKk%2fA%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "00xZSlY4FIOGqrajuZ%2blaTlSArJel%2bUAZc3%2bZU%2f53Hs%3d",
      "team": "Fungi",
      "score": "13",
      "oppScore": "1",
      "opp": "Seven Out",
      "oppId": "EKT%2b4wN3oW3%2bvTZF1BOVy%2fpLrku%2frikWkw8iDqstIOQ%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "qHpQicSKJbP88eO185p5V2yXOvF3A8NAfMBJY4PSFgE%3d",
      "team": "Fungi",
      "score": "9",
      "oppScore": "11",
      "opp": "Sawtooth",
      "oppId": "O%2fOvOBARFYfl3iP1PCdqMwd9SiYSMEiR%2b0E8MZeOOD4%3d"
    }
  ],
  "cdHPsuclc2H0oeX5zO4ie6cA2mYipY1AN1i7fnLCrHU%3d": [
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "rjktbLCdbyMmk8U5eUG2HNRwNBLC%2fEQ0IyRrwLv4FMo%3d",
      "team": "Dawg Pound",
      "score": "12",
      "oppScore": "15",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "BxAv5TdpGXtADfSYyd%2b30bpAwIFTAdrG6h8flVyW0WA%3d",
      "team": "Dawg Pound",
      "score": "8",
      "oppScore": "11",
      "opp": "Colorado Cutthroat: Youth Club U-20 Boys",
      "oppId": "uCZC60On5XqvfotORPgB2aVS6YYypoZ%2bxRG1SToX3pc%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "WJ4QSdU0C0SwRiEHS0gNUO5MoMaeq2oMknrl1hOAaSk%3d",
      "team": "Dawg Pound",
      "score": "10",
      "oppScore": "15",
      "opp": "Nomads",
      "oppId": "clyQEvW53ITGsU%2bGFSkoVd77O856fO%2f%2fPStXV2bXfy4%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "AqzdODvijfIoo8jQcezLSRi6ChlrTEsdql%2bgexb%2baig%3d",
      "team": "Dawg Pound",
      "score": "15",
      "oppScore": "11",
      "opp": "Choice City Hops",
      "oppId": "8aw9r%2f7%2bx5K68IFW7B4Yeidcx%2fIpqRuvgzGi%2buM7%2b38%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "FJizttpho0A2VJ7Ztwa6K9trsqi8YbqlYtLqEeaxyak%3d",
      "team": "Dawg Pound",
      "score": "13",
      "oppScore": "15",
      "opp": "NOx",
      "oppId": "YLXPz%2fCNrXaNTSa14rMcZI2zV%2brkt0P1fA0UxcJlPaE%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "2WR4N04Kti0yVNQIwbiEcVfDwPzrHal%2b9F1fGykXI7g%3d",
      "team": "Dawg Pound",
      "score": "4",
      "oppScore": "7",
      "opp": "PowderHogs",
      "oppId": "4M9MJYyVOotcr31jr1X%2bW%2fnNzBLDCtMhEBgjHQi1W9s%3d"
    }
  ],
  "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d": [
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "V5w%2fCr5%2bRgzBhdfN9LeSlonFuAUAv5tFXBbkX4ssdwQ%3d",
      "team": "ISO Atmo",
      "score": "15",
      "oppScore": "3",
      "opp": "COSmic U-20 Boys",
      "oppId": "ibMdv4bC%2b0jt2%2fJSNUbBFKCLQxYMWOpm9yxWtbkm%2ff8%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "zaQUgPS8sBbVyQ9idrMU7sViryMN7Ou5DuzIt3KAMEA%3d",
      "team": "ISO Atmo",
      "score": "15",
      "oppScore": "5",
      "opp": "Sonoran Dog",
      "oppId": "WAPjwPL2R8YMCWgolwmIL1cqRoFxsqyk2gQeZ9iDOMA%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "p5DY08PS2fS61bZ3ZVU%2by2n1Td3rLiSh3ZumLSJQOL4%3d",
      "team": "ISO Atmo",
      "score": "10",
      "oppScore": "13",
      "opp": "Inception",
      "oppId": "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "JmJiesy9SR8iTh8sxdsBqGoZ89Iv5JwZxy6tvOYU1x8%3d",
      "team": "ISO Atmo",
      "score": "15",
      "oppScore": "8",
      "opp": "COSmic U-20 Boys",
      "oppId": "ibMdv4bC%2b0jt2%2fJSNUbBFKCLQxYMWOpm9yxWtbkm%2ff8%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "h0cJPHy88Mdnt62Wvt5U5xSqrRoL4y41K0jzsIkobRw%3d",
      "team": "ISO Atmo",
      "score": "15",
      "oppScore": "7",
      "opp": "Sonoran Dog",
      "oppId": "WAPjwPL2R8YMCWgolwmIL1cqRoFxsqyk2gQeZ9iDOMA%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "T89vr1eBLqrlQNlcYsyhaRmqs00TMEeBUSXA0GbEJuE%3d",
      "team": "ISO Atmo",
      "score": "W",
      "oppScore": "F",
      "opp": "Inception",
      "oppId": "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "hQPTDdmV04yQ71fB5RVHvlPREXjpKdZz6PwgN%2fIVVgY%3d",
      "team": "ISO Atmo",
      "score": "1",
      "oppScore": "15",
      "opp": "Revolver",
      "oppId": "Kem%2foxDeD8r5XN1HNW8eHeRyzfBqnWTRaPCmLck6F1I%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "Ry2wUp1Arb9HJfq9GYRNu0H6qLhZIa%2b6HnJmKEJeMkw%3d",
      "team": "ISO Atmo",
      "score": "8",
      "oppScore": "12",
      "opp": "Ghost Train",
      "oppId": "Tqxb9%2fb2Hxtbg%2f54ifErhjS%2bc%2fBnacDZk5RhQ%2fd%2b%2f5g%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "DA1XcS4TQblKhJvS4IzGsCRh%2bKGYZU%2bmbh%2b8Dma8PXI%3d",
      "team": "ISO Atmo",
      "score": "10",
      "oppScore": "11",
      "opp": "GOAT",
      "oppId": "6IFGDipIDG9l%2br4E8TUyujjAslzlgFQUH9y5j9M0G4g%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "GpGglcFRFoMBGT%2fy3mIdidHiVZjpRfqAhQk0BWUgDnM%3d",
      "team": "ISO Atmo",
      "score": "10",
      "oppScore": "11",
      "opp": "Zyzzyva",
      "oppId": "Yfu4RKEHOC158Q5KQGT5xHxtk5hGHKp2RxFoXMEKk%2fA%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "HFvjpHBo81wVvfSc%2bOTpA3l3YW5Jax6VBLlJre4ft4U%3d",
      "team": "ISO Atmo",
      "score": "14",
      "oppScore": "6",
      "opp": "Battery",
      "oppId": "XgsOT%2bl5FkWgZoV7cQAy4vMUDJS3ygcjNozHGHsTYUE%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "moE8sVbW1jmFYEakBQ0INcaa1aEhcUPzwXHBZJlpmJc%3d",
      "team": "ISO Atmo",
      "score": "10",
      "oppScore": "14",
      "opp": "General Strike",
      "oppId": "%2bAa3yq8xXOgWBEYZi%2fAT%2fiiGNJfFLcQeXn3x6sH%2fmhA%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "LMwGpJ28z0CQToBByqHXYI1Z%2byELFZded9VhjjV8hFM%3d",
      "team": "ISO Atmo",
      "score": "12",
      "oppScore": "13",
      "opp": "OAT",
      "oppId": "3jrAxF6U290JZyiuGff9bkOyKPIspXXtN94jCffcvgs%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "4ooTQJwTEldvo9CppBbzFNZfPdqE751Kq4AOCVzOnk8%3d",
      "team": "ISO Atmo",
      "score": "13",
      "oppScore": "10",
      "opp": "Hazard",
      "oppId": "P0N8mJF5hP7HulHfG72Zdu2tlioAZAaLyhUwPE4s6T8%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "dvhRAtfXyBnAWVKDq8qmSJGgVA45b56ZFPvf%2ffd4V6M%3d",
      "team": "ISO Atmo",
      "score": "9",
      "oppScore": "13",
      "opp": "Sawtooth",
      "oppId": "O%2fOvOBARFYfl3iP1PCdqMwd9SiYSMEiR%2b0E8MZeOOD4%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "RItpzOyVgFW7L8in1fwAuJHyy%2b6ao1qhPug2M42hVxs%3d",
      "team": "ISO Atmo",
      "score": "10",
      "oppScore": "9",
      "opp": "Battery",
      "oppId": "XgsOT%2bl5FkWgZoV7cQAy4vMUDJS3ygcjNozHGHsTYUE%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "YDNM8a5lfnG2%2bKMJYy4vFj7buN4JzgApi6O4M21sY%2fU%3d",
      "team": "ISO Atmo",
      "score": "12",
      "oppScore": "14",
      "opp": "Streetgang",
      "oppId": "%2fo6V9EACiot%2bzfjGzj7GQtyBnMoDlmHGs3Nl35pLoeU%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "D4hQTtTag0BeSSwIdMaBQY1R73BPueYL9ZjTeD6L5YA%3d",
      "team": "ISO Atmo",
      "score": "15",
      "oppScore": "14",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "Y6CbHSOa9JvD7f3RoC1di4jZYzI8s1aB3Lo0XboIANU%3d",
      "team": "ISO Atmo",
      "score": "12",
      "oppScore": "15",
      "opp": "Hazard",
      "oppId": "P0N8mJF5hP7HulHfG72Zdu2tlioAZAaLyhUwPE4s6T8%3d"
    },
    {
      "date": "August 21",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "hYTy3cRX9DAJNNUvSWNy3dZqeo39JIftx4wRyDSHIWg%3d",
      "team": "ISO Atmo",
      "score": "13",
      "oppScore": "9",
      "opp": "PowderHogs",
      "oppId": "4M9MJYyVOotcr31jr1X%2bW%2fnNzBLDCtMhEBgjHQi1W9s%3d"
    },
    {
      "date": "August 21",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "I%2fTx8pUd%2fsDNiTW9wg6BCzuHmLUjeoLrSOK1db0Fnp8%3d",
      "team": "ISO Atmo",
      "score": "13",
      "oppScore": "7",
      "opp": "Seven Out",
      "oppId": "EKT%2b4wN3oW3%2bvTZF1BOVy%2fpLrku%2frikWkw8iDqstIOQ%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "G5scC3H65H11tR4haorZg0sINfWT1S68vFTgbfUuHnI%3d",
      "team": "ISO Atmo",
      "score": "11",
      "oppScore": "10",
      "opp": "Offshore",
      "oppId": "z3CZ5zLpuh4%2b6lmsKpQSH%2fDpWRfMktj5DGqERnXkcXY%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "Nf6bnTrcaT%2bWeYDl8f%2ftGPVLTJsmeLOwzDkH0VP7vyU%3d",
      "team": "ISO Atmo",
      "score": "9",
      "oppScore": "10",
      "opp": "Drought",
      "oppId": "ksz2i7%2bjkkoIQzWKSrtEphZr9icgiUTPL3Ixum49pfY%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "aXzfWKoZPtS5tTksD1xWOyQ1RKJ2mKYYRFpBj2R7UG8%3d",
      "team": "ISO Atmo",
      "score": "13",
      "oppScore": "8",
      "opp": "Switchback",
      "oppId": "XNBtJjuPsH6Zq%2bmVmPwL1Zo0%2bl5xYeh3mWrrpzn7CTk%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "owk%2fwVWvKBfaoW8Mf9I12J7RDRVgSXpzsKCtfMQTqAw%3d",
      "team": "ISO Atmo",
      "score": "11",
      "oppScore": "13",
      "opp": "Utah Hatu",
      "oppId": "%2bmdoaqywGR6P4rhXTSYYxtvjQhBX094atWIs4EEL1Ys%3d"
    }
  ],
  "ibMdv4bC%2b0jt2%2fJSNUbBFKCLQxYMWOpm9yxWtbkm%2ff8%3d": [
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "V5w%2fCr5%2bRgzBhdfN9LeSlonFuAUAv5tFXBbkX4ssdwQ%3d",
      "team": "COSmic U-20 Boys",
      "score": "3",
      "oppScore": "15",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "7eNlmjNVzS3KVYph%2fK%2f3n5bNyxuGQE0wTcfRMGzQkIY%3d",
      "team": "COSmic U-20 Boys",
      "score": "14",
      "oppScore": "12",
      "opp": "Nomads",
      "oppId": "clyQEvW53ITGsU%2bGFSkoVd77O856fO%2f%2fPStXV2bXfy4%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "Lf4FJJCDVSG9JOLWDuGofPO%2fsEJSsPJ4LWovvmWu08U%3d",
      "team": "COSmic U-20 Boys",
      "score": "15",
      "oppScore": "14",
      "opp": "PowderHogs",
      "oppId": "4M9MJYyVOotcr31jr1X%2bW%2fnNzBLDCtMhEBgjHQi1W9s%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "JmJiesy9SR8iTh8sxdsBqGoZ89Iv5JwZxy6tvOYU1x8%3d",
      "team": "COSmic U-20 Boys",
      "score": "8",
      "oppScore": "15",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "W%2bSHiSBr39%2fD49bYnT1cavemJxjcgow6EbcJnSkjvUY%3d",
      "team": "COSmic U-20 Boys",
      "score": "12",
      "oppScore": "15",
      "opp": "Scythe",
      "oppId": "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "L%2bssTGsrYe0gVle%2fnxZ%2fNeIAFALbKDgtC8pcQ19Qn2E%3d",
      "team": "COSmic U-20 Boys",
      "score": "3",
      "oppScore": "8",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "OA7JEsBUXxQI9d7nAQXAN3HvJP81j4ViXV1f7f4TSHM%3d",
      "team": "COSmic U-20 Boys",
      "score": "11",
      "oppScore": "13",
      "opp": "Charlotte Flight",
      "oppId": "P4imWyKxeDrrrqjQ0lCOZq28YNb5YA%2fJ4ekFVl4wwG4%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "hfwrQRvkY6wOEzZuqpOkIsZ0I20L6EowTLwCfo4s%2bgI%3d",
      "team": "COSmic U-20 Boys",
      "score": "14",
      "oppScore": "9",
      "opp": "Moon Pigs",
      "oppId": "u%2b%2fl72szLMaP0o%2f92qBd%2foiVIIHcxZYHekJ7R26OcY0%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "he7buGvJQkBSwrInW%2fPBzroQOX1vAy0jXAUMLFIq5Lc%3d",
      "team": "COSmic U-20 Boys",
      "score": "15",
      "oppScore": "3",
      "opp": "Metro",
      "oppId": "0beNd%2fOX%2bLMblyebB3k0%2fnyyo5a8SrRamMRZCpOj2Lw%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "Sqe7QQWGcTGFmgTmqymoQsDoDNAVfFqTP5Ncme9siDE%3d",
      "team": "COSmic U-20 Boys",
      "score": "15",
      "oppScore": "8",
      "opp": "Villains",
      "oppId": "Ym1ak6XomGej8LcWE974vySOBZfTF88e4wX%2bD3N6JX0%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "%2b2qffR7veTcIyb9%2bJgPMo2KU07No2e0fbBVHLJo7Ozc%3d",
      "team": "COSmic U-20 Boys",
      "score": "11",
      "oppScore": "15",
      "opp": "smOAK",
      "oppId": "wJwgr6ieDLPO2Y%2fODqgMXB7Ah9cCIzQW3Znr1W%2bwLMc%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "PLl%2fRqQ2CqWx3f2Z923YnQK0%2fpS2k%2fmRXTwj6BDTqZg%3d",
      "team": "COSmic U-20 Boys",
      "score": "14",
      "oppScore": "10",
      "opp": "DEVYL U-20 Boys",
      "oppId": "YXOyQcXz9dyRRYJVW1knp%2felzsehAC4MeGolkdDmmfc%3d"
    },
    {
      "date": "August 08",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "2hDf%2fH%2by64ylUFLuYPFqTt1DfVUBmAncFm2lqwf1tlw%3d",
      "team": "COSmic U-20 Boys",
      "score": "15",
      "oppScore": "10",
      "opp": "Texas United",
      "oppId": "g1sAWnCbOpRll1Sex%2b5gOMn6kWvnosk%2b%2buSEykPQk24%3d"
    },
    {
      "date": "August 08",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "Ynk8h%2fjBpPLgEt%2fbdTKY0cx6onBbn5LGa%2b9BmbR0GvA%3d",
      "team": "COSmic U-20 Boys",
      "score": "7",
      "oppScore": "15",
      "opp": "Charlotte Flight",
      "oppId": "P4imWyKxeDrrrqjQ0lCOZq28YNb5YA%2fJ4ekFVl4wwG4%3d"
    }
  ],
  "ksz2i7%2bjkkoIQzWKSrtEphZr9icgiUTPL3Ixum49pfY%3d": [
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "Toj8HJXGsEb91m%2bVgHP1HtZHG8XnpfRoVnCmfDUdQ%2fk%3d",
      "team": "Drought",
      "score": "12",
      "oppScore": "7",
      "opp": "Colorado Cutthroat: Youth Club U-20 Boys",
      "oppId": "uCZC60On5XqvfotORPgB2aVS6YYypoZ%2bxRG1SToX3pc%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "RmA3uOXnWL403nOCulTuz203gq9oVAt759vScdbMBDE%3d",
      "team": "Drought",
      "score": "13",
      "oppScore": "11",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "%2bO7Eithj0KwWroVqvWOU7KKnXSkqVTwSL3W5bQZ2edA%3d",
      "team": "Drought",
      "score": "13",
      "oppScore": "14",
      "opp": "Scythe",
      "oppId": "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "UOVfXSW3kkxaFTSDs5AUmJdEn%2bQnwpluTXZpZP8CDK0%3d",
      "team": "Drought",
      "score": "15",
      "oppScore": "10",
      "opp": "Kansas City Smokestack",
      "oppId": "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "fo6dhA%2f2gXbv4EEzRf3M3Z%2fb0Mjj2B6W8qID8c6cn4I%3d",
      "team": "Drought",
      "score": "10",
      "oppScore": "12",
      "opp": "Inception",
      "oppId": "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "OV2MUk4F3e%2fSTvU70beKVcRXWXdGQpuJUVc6hIj%2bIhk%3d",
      "team": "Drought",
      "score": "3",
      "oppScore": "3",
      "opp": "Sonoran Dog",
      "oppId": "WAPjwPL2R8YMCWgolwmIL1cqRoFxsqyk2gQeZ9iDOMA%3d"
    },
    {
      "date": "July 23",
      "tournament": "Angel City Shootout 2022",
      "tournamentId": "Angel-City-Shootout-2022",
      "gameId": "MNsH97dGXlW0ElLmxycQsubjDNftEdpX04ftWDJXQXQ%3d",
      "team": "Drought",
      "score": "15",
      "oppScore": "9",
      "opp": "OC Crows",
      "oppId": "NvxMsKI3fOV1hpY0a9oUKl0EGgRtzFW8o3PpEg3r%2b%2bI%3d"
    },
    {
      "date": "July 23",
      "tournament": "Angel City Shootout 2022",
      "tournamentId": "Angel-City-Shootout-2022",
      "gameId": "bOYpObOA9McrS8SnZ5zFKg%2bxLgXNet%2bG%2bch2c1YvLoU%3d",
      "team": "Drought",
      "score": "15",
      "oppScore": "9",
      "opp": "Offshore",
      "oppId": "z3CZ5zLpuh4%2b6lmsKpQSH%2fDpWRfMktj5DGqERnXkcXY%3d"
    },
    {
      "date": "July 23",
      "tournament": "Angel City Shootout 2022",
      "tournamentId": "Angel-City-Shootout-2022",
      "gameId": "JFVvO%2buq2v1qy7NpUNR%2ffbwyIU0O8RZEqPBblszWVDg%3d",
      "team": "Drought",
      "score": "15",
      "oppScore": "8",
      "opp": "Hazard",
      "oppId": "P0N8mJF5hP7HulHfG72Zdu2tlioAZAaLyhUwPE4s6T8%3d"
    },
    {
      "date": "August 06",
      "tournament": "Coconino Classic",
      "tournamentId": "Coconino-Classic",
      "gameId": "wveDNumjG0IL8Z34P31RD3qq8GizR8bydcWwAF%2buhXk%3d",
      "team": "Drought",
      "score": "15",
      "oppScore": "5",
      "opp": "Monsoon",
      "oppId": "iMWyb%2fFNBKjTkIqXjFBY%2bhoKQ%2b3niXP6UqbJkmR4Fiw%3d"
    },
    {
      "date": "August 06",
      "tournament": "Coconino Classic",
      "tournamentId": "Coconino-Classic",
      "gameId": "EMVCeWuZp0LhaUkbKYpqi%2f0Q3zMzTjLAXt88totUdME%3d",
      "team": "Drought",
      "score": "15",
      "oppScore": "7",
      "opp": "Seven Out",
      "oppId": "EKT%2b4wN3oW3%2bvTZF1BOVy%2fpLrku%2frikWkw8iDqstIOQ%3d"
    },
    {
      "date": "August 06",
      "tournament": "Coconino Classic",
      "tournamentId": "Coconino-Classic",
      "gameId": "foGlvZwKp4hZE8KKJYIWhzePJfCsegrwv4llrjdlT%2bw%3d",
      "team": "Drought",
      "score": "15",
      "oppScore": "9",
      "opp": "Sonoran Dog",
      "oppId": "WAPjwPL2R8YMCWgolwmIL1cqRoFxsqyk2gQeZ9iDOMA%3d"
    },
    {
      "date": "August 21",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "mQQbovS2EqR6UFrajHQQwquqlNMhKcXFk8W81hNhH0c%3d",
      "team": "Drought",
      "score": "10",
      "oppScore": "13",
      "opp": "Zyzzyva",
      "oppId": "Yfu4RKEHOC158Q5KQGT5xHxtk5hGHKp2RxFoXMEKk%2fA%3d"
    },
    {
      "date": "August 21",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "hmwRPNY2n50N1O5CozEjBcEdtwCscJYs45GZgEcUuEs%3d",
      "team": "Drought",
      "score": "8",
      "oppScore": "13",
      "opp": "Utah Hatu",
      "oppId": "%2bmdoaqywGR6P4rhXTSYYxtvjQhBX094atWIs4EEL1Ys%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "yUSMM02NIcou4pD7pgoCQQ4qem5mIk%2bnoLvnJ0MYAvQ%3d",
      "team": "Drought",
      "score": "13",
      "oppScore": "12",
      "opp": "Utah Hatu",
      "oppId": "%2bmdoaqywGR6P4rhXTSYYxtvjQhBX094atWIs4EEL1Ys%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "Nf6bnTrcaT%2bWeYDl8f%2ftGPVLTJsmeLOwzDkH0VP7vyU%3d",
      "team": "Drought",
      "score": "10",
      "oppScore": "9",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "eVF5MXXRlxMkxOq2O8x3%2bFOEk2%2b2iymgf8beeYUAUbA%3d",
      "team": "Drought",
      "score": "13",
      "oppScore": "5",
      "opp": "Offshore",
      "oppId": "z3CZ5zLpuh4%2b6lmsKpQSH%2fDpWRfMktj5DGqERnXkcXY%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "QrI%2fx8lgcVTnhnVksGCMM8IXWaueMf%2fOwlcso9AYVs8%3d",
      "team": "Drought",
      "score": "8",
      "oppScore": "11",
      "opp": "Switchback",
      "oppId": "XNBtJjuPsH6Zq%2bmVmPwL1Zo0%2bl5xYeh3mWrrpzn7CTk%3d"
    }
  ],
  "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d": [
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "gxEo1Cnk%2bKd8h%2bNghwonrnBAKIX0TVjwqvfaswIV%2bpU%3d",
      "team": "Kansas City Smokestack",
      "score": "15",
      "oppScore": "8",
      "opp": "Choice City Hops",
      "oppId": "8aw9r%2f7%2bx5K68IFW7B4Yeidcx%2fIpqRuvgzGi%2buM7%2b38%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "MEwsqkQRujYzl2xRxhbsZCToYz29sVam%2fwOTDgn%2fcAE%3d",
      "team": "Kansas City Smokestack",
      "score": "11",
      "oppScore": "15",
      "opp": "Inception",
      "oppId": "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "f0UucSOR15gcwP3DWB9UDM4F8gSsKbAARew32tJ9jJ0%3d",
      "team": "Kansas City Smokestack",
      "score": "15",
      "oppScore": "6",
      "opp": "Colorado Cutthroat: Youth Club U-20 Boys",
      "oppId": "uCZC60On5XqvfotORPgB2aVS6YYypoZ%2bxRG1SToX3pc%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "UOVfXSW3kkxaFTSDs5AUmJdEn%2bQnwpluTXZpZP8CDK0%3d",
      "team": "Kansas City Smokestack",
      "score": "10",
      "oppScore": "15",
      "opp": "Drought",
      "oppId": "ksz2i7%2bjkkoIQzWKSrtEphZr9icgiUTPL3Ixum49pfY%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "BeeiCBekRuIp1wDz%2bW%2f950le9llog8m9LqTEkwVMPUM%3d",
      "team": "Kansas City Smokestack",
      "score": "14",
      "oppScore": "13",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "LO0wXL97tff%2fBZ3dzcJt%2b8Oen0lQiL8oyP%2ffIbb1hqM%3d",
      "team": "Kansas City Smokestack",
      "score": "8",
      "oppScore": "2",
      "opp": "Scythe",
      "oppId": "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "Qb2RtUSIdZ%2bJOQefp1X6gYWKqpf%2bACAJhLEU8H9SPPo%3d",
      "team": "Kansas City Smokestack",
      "score": "15",
      "oppScore": "6",
      "opp": "Overdrive",
      "oppId": "cfApDIqN4eoe%2fr26h%2b8fQj3s9XKGa9nJC8lT5uya7gM%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "MGDq%2b0ULvHaiBVFHy1co%2f0vXSten15fjiSaHGyQgiYc%3d",
      "team": "Kansas City Smokestack",
      "score": "15",
      "oppScore": "2",
      "opp": "Meadowlark",
      "oppId": "5mFdu3Xc%2bl7OoxB5yBc9Q8%2bCImG%2b%2fOFvdvmpUhgLwdo%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "plgzxs1LYxqbmVxgQ2gs39mrycosE6sEWsV5dE5JYvA%3d",
      "team": "Kansas City Smokestack",
      "score": "15",
      "oppScore": "6",
      "opp": "Nomads",
      "oppId": "clyQEvW53ITGsU%2bGFSkoVd77O856fO%2f%2fPStXV2bXfy4%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "cBM94Ok0lcxtmlORo2KFWE%2fhOL33Wlhw%2fNJOlGVUu%2bc%3d",
      "team": "Kansas City Smokestack",
      "score": "15",
      "oppScore": "7",
      "opp": "NOx",
      "oppId": "YLXPz%2fCNrXaNTSa14rMcZI2zV%2brkt0P1fA0UxcJlPaE%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "9BEyWpk1fHuGHno8k718RTydTEHU9aFHtBXvsx9hWc4%3d",
      "team": "Kansas City Smokestack",
      "score": "15",
      "oppScore": "14",
      "opp": "Cryptic",
      "oppId": "Qhc%2fzwjxDlH6UTqMQqHKE2ms56DaeAMWMHQJCBwAjIw%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "10h09nLxMxfXndmrPkSu161Q885uGnFncpeJe1Clo%2bo%3d",
      "team": "Kansas City Smokestack",
      "score": "12",
      "oppScore": "15",
      "opp": "Scythe",
      "oppId": "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "pop00%2fh25JbIk0N%2fNSZj%2fApftShzHTt5BuE7VgBq6aE%3d",
      "team": "Kansas City Smokestack",
      "score": "12",
      "oppScore": "11",
      "opp": "Scythe",
      "oppId": "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "MzDs3T5UBt%2fNUOfI2yq8v3MzZa2j%2f8XmJNirWCiqaxI%3d",
      "team": "Kansas City Smokestack",
      "score": "13",
      "oppScore": "1",
      "opp": "THE BODY",
      "oppId": "nyLl78Eg24EtJDKG0oHsU9e%2fg0hrOYqklm7h8m4R0Jo%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "tk9qvEAb%2f8%2b5DZo4kFNCrm%2fdc0%2fYWkkLoLIoL%2bxkPV0%3d",
      "team": "Kansas City Smokestack",
      "score": "13",
      "oppScore": "2",
      "opp": "Trident II",
      "oppId": "b69A0xfMxQKwR01A7jfOqdv1wbChbTxRp5jko3aOCXk%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "4c5T%2b4vlGIqrka4Mug1wCkGxWZmnDL4TwWG3A2GUffw%3d",
      "team": "Kansas City Smokestack",
      "score": "13",
      "oppScore": "2",
      "opp": "Overdrive",
      "oppId": "cfApDIqN4eoe%2fr26h%2b8fQj3s9XKGa9nJC8lT5uya7gM%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "BPBXrvES9pB5Cqw7BuqGKzljsBh6SJBrKJe5frRNJSI%3d",
      "team": "Kansas City Smokestack",
      "score": "12",
      "oppScore": "9",
      "opp": "Knights of Ni",
      "oppId": "dgH60oTrddgw%2bSl2xkIe%2fis%2fu1HY9ndk1MZTdN4OiAs%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "i2Y3cTuXchwe4Ao65XR%2fOcekI1E9P2d6B3%2f6S8h8ucw%3d",
      "team": "Kansas City Smokestack",
      "score": "12",
      "oppScore": "10",
      "opp": "DeMo",
      "oppId": "FdlFpUSGIahm89rZTXrHg2K85ugGqml2fjUWEPHtgPo%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "l11kjhCYZz60BhS4H7%2fK%2bjA2M3lpQHzJ6yBNab7UN9k%3d",
      "team": "Kansas City Smokestack",
      "score": "13",
      "oppScore": "11",
      "opp": "Scythe",
      "oppId": "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "L7YBBpvt5%2bTAdIbznCEkkgjKsUG%2b%2b5n5CD6pGCpvDEM%3d",
      "team": "Kansas City Smokestack",
      "score": "11",
      "oppScore": "9",
      "opp": "Knights of Ni",
      "oppId": "dgH60oTrddgw%2bSl2xkIe%2fis%2fu1HY9ndk1MZTdN4OiAs%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "h5ogb%2bgP43WK%2bcnBcY6Z7VHcBP5UeJ8zJpe1xlJT%2fE0%3d",
      "team": "Kansas City Smokestack",
      "score": "13",
      "oppScore": "12",
      "opp": "DeMo",
      "oppId": "FdlFpUSGIahm89rZTXrHg2K85ugGqml2fjUWEPHtgPo%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "3gHWBSyqNWHnPyNXHwD1NraPlIM3QOP106S93qS7w4Q%3d",
      "team": "Kansas City Smokestack",
      "score": "12",
      "oppScore": "13",
      "opp": "Mallard II",
      "oppId": "waPLoije6zVsjVUcOXTlGSFZ10PPabaoS6kk%2bnW2NCo%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "aFvOAezFczvqwhXAWeediU0DfGK3UmsI2%2fGS7%2fvXwhI%3d",
      "team": "Kansas City Smokestack",
      "score": "7",
      "oppScore": "8",
      "opp": "Cryptic",
      "oppId": "Qhc%2fzwjxDlH6UTqMQqHKE2ms56DaeAMWMHQJCBwAjIw%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "5t3FHidII4tVb2Y4JHdy50YnW2wwpHort1B3SgL%2b8J8%3d",
      "team": "Kansas City Smokestack",
      "score": "13",
      "oppScore": "7",
      "opp": "HouSE",
      "oppId": "W3JpjYTNBVirvq%2bLhhgD1xswtN%2bDHXo2Y5uhwfm%2f450%3d"
    }
  ],
  "8aw9r%2f7%2bx5K68IFW7B4Yeidcx%2fIpqRuvgzGi%2buM7%2b38%3d": [
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "ANLglh0K260uH7rQvadUobPZKpqyeUUr1fhzD44OzLA%3d",
      "team": "Choice City Hops",
      "score": "9",
      "oppScore": "15",
      "opp": "Inception",
      "oppId": "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "gxEo1Cnk%2bKd8h%2bNghwonrnBAKIX0TVjwqvfaswIV%2bpU%3d",
      "team": "Choice City Hops",
      "score": "8",
      "oppScore": "15",
      "opp": "Kansas City Smokestack",
      "oppId": "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "TvogWh6XjjQQWupD6EvRNqcAkbLhMo1wPGOkQW08AAo%3d",
      "team": "Choice City Hops",
      "score": "6",
      "oppScore": "15",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "AqzdODvijfIoo8jQcezLSRi6ChlrTEsdql%2bgexb%2baig%3d",
      "team": "Choice City Hops",
      "score": "11",
      "oppScore": "15",
      "opp": "Dawg Pound",
      "oppId": "cdHPsuclc2H0oeX5zO4ie6cA2mYipY1AN1i7fnLCrHU%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "cudx%2fDew%2fJf4bTQQxKYeNabd%2f%2boeiXmzUM6jbvC%2bYT8%3d",
      "team": "Choice City Hops",
      "score": "15",
      "oppScore": "13",
      "opp": "Nomads",
      "oppId": "clyQEvW53ITGsU%2bGFSkoVd77O856fO%2f%2fPStXV2bXfy4%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "%2fAjEcPRwBU0%2bB0fJoTsmpETBuL57rkkGZmPpk9KGYms%3d",
      "team": "Choice City Hops",
      "score": "8",
      "oppScore": "4",
      "opp": "NOx",
      "oppId": "YLXPz%2fCNrXaNTSa14rMcZI2zV%2brkt0P1fA0UxcJlPaE%3d"
    }
  ],
  "uCZC60On5XqvfotORPgB2aVS6YYypoZ%2bxRG1SToX3pc%3d": [
    {
      "date": "February 26",
      "tournament": "Snow Melt at Colorado College",
      "tournamentId": "Snow-Melt-at-Colorado-College",
      "gameId": "wED8HLXmsL%2b2EjjT3r%2f7HGch9RAh96KCnmqqdov3lbE%3d",
      "team": "Colorado Cutthroat: Youth Club U-20 Boys",
      "score": "4",
      "oppScore": "8",
      "opp": "Colorado School of Mines",
      "oppId": "Fvs3I8VYnxkzOXsC0V6FpF4AwmIGpQxSNI8O7ynivCM%3d"
    },
    {
      "date": "February 26",
      "tournament": "Snow Melt at Colorado College",
      "tournamentId": "Snow-Melt-at-Colorado-College",
      "gameId": "mxeC4sJeudhaQFOYQOg0xxMM0ToawUkWgDAkNlIBpR4%3d",
      "team": "Colorado Cutthroat: Youth Club U-20 Boys",
      "score": "4",
      "oppScore": "13",
      "opp": "Colorado College",
      "oppId": "A6mYzI2N8zsmGWONnFY2A1w4nty3v6HeN2BcU3UfR%2f4%3d"
    },
    {
      "date": "February 26",
      "tournament": "Snow Melt at Colorado College",
      "tournamentId": "Snow-Melt-at-Colorado-College",
      "gameId": "WXNn%2fvUpV2MJAX%2bfWLrk2EW7XuUpNNkC1RqVKk4tsCU%3d",
      "team": "Colorado Cutthroat: Youth Club U-20 Boys",
      "score": "4",
      "oppScore": "8",
      "opp": "Colorado [B]",
      "oppId": "YPUunHU%2bbusVN638wCjwrS0yQwkIMyMUHkTiLsNsFWo%3d"
    },
    {
      "date": "February 27",
      "tournament": "Snow Melt at Colorado College",
      "tournamentId": "Snow-Melt-at-Colorado-College",
      "gameId": "siUEH4Zl3FkJFNsSWsJMlXK%2bL3JE%2fvZnt%2biFUYVnp9E%3d",
      "team": "Colorado Cutthroat: Youth Club U-20 Boys",
      "score": "7",
      "oppScore": "12",
      "opp": "Air Force",
      "oppId": "tjahVw52cL5JTyu7VkjBde7FogO7xAvwgzZOi6JtsVw%3d"
    },
    {
      "date": "February 27",
      "tournament": "Snow Melt at Colorado College",
      "tournamentId": "Snow-Melt-at-Colorado-College",
      "gameId": "P0reS5%2bjg7Nm8Mz%2bjp6JJ80x1fMFXiPUbakIT9lGdQo%3d",
      "team": "Colorado Cutthroat: Youth Club U-20 Boys",
      "score": "13",
      "oppScore": "3",
      "opp": "Colorado School of Mines [B]",
      "oppId": "U31mNgy6Is7%2fVmiuNHfXBELKVrL9NRhFHj310aKWFhU%3d"
    },
    {
      "date": "February 27",
      "tournament": "Snow Melt at Colorado College",
      "tournamentId": "Snow-Melt-at-Colorado-College",
      "gameId": "b8vgw1s2VLSwhIhax8mkYJomB64JFn1CadtWlcWpFCY%3d",
      "team": "Colorado Cutthroat: Youth Club U-20 Boys",
      "score": "1",
      "oppScore": "10",
      "opp": "Denver",
      "oppId": "yL%2ffKCQaMPr105nZjPZXgHFAmrHB19TAKATe1c5s9N8%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "Toj8HJXGsEb91m%2bVgHP1HtZHG8XnpfRoVnCmfDUdQ%2fk%3d",
      "team": "Colorado Cutthroat: Youth Club U-20 Boys",
      "score": "7",
      "oppScore": "12",
      "opp": "Drought",
      "oppId": "ksz2i7%2bjkkoIQzWKSrtEphZr9icgiUTPL3Ixum49pfY%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "BxAv5TdpGXtADfSYyd%2b30bpAwIFTAdrG6h8flVyW0WA%3d",
      "team": "Colorado Cutthroat: Youth Club U-20 Boys",
      "score": "11",
      "oppScore": "8",
      "opp": "Dawg Pound",
      "oppId": "cdHPsuclc2H0oeX5zO4ie6cA2mYipY1AN1i7fnLCrHU%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "f0UucSOR15gcwP3DWB9UDM4F8gSsKbAARew32tJ9jJ0%3d",
      "team": "Colorado Cutthroat: Youth Club U-20 Boys",
      "score": "6",
      "oppScore": "15",
      "opp": "Kansas City Smokestack",
      "oppId": "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "HjtTPFHZHaIm8vV70v2TPYk9dqgBhijKdTbk4AqWags%3d",
      "team": "Colorado Cutthroat: Youth Club U-20 Boys",
      "score": "12",
      "oppScore": "13",
      "opp": "NOx",
      "oppId": "YLXPz%2fCNrXaNTSa14rMcZI2zV%2brkt0P1fA0UxcJlPaE%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "law418UccJkAdzpT6sEon3E91bqCa5r2PvK33WBV0ME%3d",
      "team": "Colorado Cutthroat: Youth Club U-20 Boys",
      "score": "15",
      "oppScore": "11",
      "opp": "PowderHogs",
      "oppId": "4M9MJYyVOotcr31jr1X%2bW%2fnNzBLDCtMhEBgjHQi1W9s%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "YknFUXNJblwwVoV1X7adie8TV7zzynYP%2fJYv7g8TxGE%3d",
      "team": "Colorado Cutthroat: Youth Club U-20 Boys",
      "score": "6",
      "oppScore": "2",
      "opp": "Nomads",
      "oppId": "clyQEvW53ITGsU%2bGFSkoVd77O856fO%2f%2fPStXV2bXfy4%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "ncvhCKIA8n8XfHpF1%2fEEULwcHSjSAGqBx%2f6uNAkNLEg%3d",
      "team": "Colorado Cutthroat: Youth Club U-20 Boys",
      "score": "13",
      "oppScore": "4",
      "opp": "Connecticut Harpoon",
      "oppId": "YIzHfo1sGWgfgEtOVFTJQgQkK1t%2be88CqVk%2beFoGL7A%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "Icq5%2bYzOwmxc180CymB0IlCbRs4meRnjNXCIVM7zYSM%3d",
      "team": "Colorado Cutthroat: Youth Club U-20 Boys",
      "score": "15",
      "oppScore": "3",
      "opp": "Madison MUFAbots U20B",
      "oppId": "yS%2b5dCtBaSh02YjjqqEKw1w%2fIMo1u39kfp4HCvNjBog%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "Ubf1OleQD6uwxI025vjiiw8gXYiTknpaC7%2b4qHRMlBM%3d",
      "team": "Colorado Cutthroat: Youth Club U-20 Boys",
      "score": "9",
      "oppScore": "10",
      "opp": "Texas United",
      "oppId": "g1sAWnCbOpRll1Sex%2b5gOMn6kWvnosk%2b%2buSEykPQk24%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "YVCSSvJZvwHjtreHmgp07gpF3T458G%2fqoYP9QfNHARc%3d",
      "team": "Colorado Cutthroat: Youth Club U-20 Boys",
      "score": "15",
      "oppScore": "1",
      "opp": "Metro",
      "oppId": "0beNd%2fOX%2bLMblyebB3k0%2fnyyo5a8SrRamMRZCpOj2Lw%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "0OKKz1wiSkqhlzbCt88X7Vs2yLJW%2f2FdUsTyP7QzlCQ%3d",
      "team": "Colorado Cutthroat: Youth Club U-20 Boys",
      "score": "15",
      "oppScore": "5",
      "opp": "Birds Of Play",
      "oppId": "C5gjZFjOICEF1K9fBVXojD5NxWjD32cRDWmzxGkasm0%3d"
    },
    {
      "date": "August 08",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "P2c0sgPinrEvZuMVm5eZYBuxKrqnv0OvlP%2f2mdUnR3w%3d",
      "team": "Colorado Cutthroat: Youth Club U-20 Boys",
      "score": "10",
      "oppScore": "12",
      "opp": "Villains",
      "oppId": "Ym1ak6XomGej8LcWE974vySOBZfTF88e4wX%2bD3N6JX0%3d"
    },
    {
      "date": "August 08",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "LGPnYJZfuy5wJmL7R2Fq%2fHJrRjCXh0tVPuMyxP4Fl9Y%3d",
      "team": "Colorado Cutthroat: Youth Club U-20 Boys",
      "score": "10",
      "oppScore": "6",
      "opp": "Connecticut Harpoon",
      "oppId": "YIzHfo1sGWgfgEtOVFTJQgQkK1t%2be88CqVk%2beFoGL7A%3d"
    }
  ],
  "clyQEvW53ITGsU%2bGFSkoVd77O856fO%2f%2fPStXV2bXfy4%3d": [
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "A7gHe%2faGt%2fNu9tvcjSPWWmbHyaQ2orqDGwcFKSC1LKU%3d",
      "team": "Nomads",
      "score": "10",
      "oppScore": "12",
      "opp": "Sonoran Dog",
      "oppId": "WAPjwPL2R8YMCWgolwmIL1cqRoFxsqyk2gQeZ9iDOMA%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "7eNlmjNVzS3KVYph%2fK%2f3n5bNyxuGQE0wTcfRMGzQkIY%3d",
      "team": "Nomads",
      "score": "12",
      "oppScore": "14",
      "opp": "COSmic U-20 Boys",
      "oppId": "ibMdv4bC%2b0jt2%2fJSNUbBFKCLQxYMWOpm9yxWtbkm%2ff8%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "WJ4QSdU0C0SwRiEHS0gNUO5MoMaeq2oMknrl1hOAaSk%3d",
      "team": "Nomads",
      "score": "15",
      "oppScore": "10",
      "opp": "Dawg Pound",
      "oppId": "cdHPsuclc2H0oeX5zO4ie6cA2mYipY1AN1i7fnLCrHU%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "RAxeYT96lIyMALcobXItPfCh59h%2f1XGu5%2bHJuFnXbXQ%3d",
      "team": "Nomads",
      "score": "15",
      "oppScore": "12",
      "opp": "PowderHogs",
      "oppId": "4M9MJYyVOotcr31jr1X%2bW%2fnNzBLDCtMhEBgjHQi1W9s%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "cudx%2fDew%2fJf4bTQQxKYeNabd%2f%2boeiXmzUM6jbvC%2bYT8%3d",
      "team": "Nomads",
      "score": "13",
      "oppScore": "15",
      "opp": "Choice City Hops",
      "oppId": "8aw9r%2f7%2bx5K68IFW7B4Yeidcx%2fIpqRuvgzGi%2buM7%2b38%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "YknFUXNJblwwVoV1X7adie8TV7zzynYP%2fJYv7g8TxGE%3d",
      "team": "Nomads",
      "score": "2",
      "oppScore": "6",
      "opp": "Colorado Cutthroat: Youth Club U-20 Boys",
      "oppId": "uCZC60On5XqvfotORPgB2aVS6YYypoZ%2bxRG1SToX3pc%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "zbavBndnNen5Ezkw5mbg3F3%2b1Lg5y1YQx0pyqEesWLk%3d",
      "team": "Nomads",
      "score": "15",
      "oppScore": "10",
      "opp": "Meadowlark",
      "oppId": "5mFdu3Xc%2bl7OoxB5yBc9Q8%2bCImG%2b%2fOFvdvmpUhgLwdo%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "YsIW8XOjBBEtjr4DKv6hD%2b%2bxHbrLjE%2fBvYzQ1B7YNfs%3d",
      "team": "Nomads",
      "score": "13",
      "oppScore": "5",
      "opp": "Overdrive",
      "oppId": "cfApDIqN4eoe%2fr26h%2b8fQj3s9XKGa9nJC8lT5uya7gM%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "plgzxs1LYxqbmVxgQ2gs39mrycosE6sEWsV5dE5JYvA%3d",
      "team": "Nomads",
      "score": "6",
      "oppScore": "15",
      "opp": "Kansas City Smokestack",
      "oppId": "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "wxYtjsq%2fyUPwvSEPg6DrTJQvqOJxTc08zIc18Kq%2fq%2bM%3d",
      "team": "Nomads",
      "score": "8",
      "oppScore": "15",
      "opp": "Scythe",
      "oppId": "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "EAwTDwpMOKzk1UQQrR%2fuyjDH9YjvgaeOROArudQB4Ww%3d",
      "team": "Nomads",
      "score": "8",
      "oppScore": "15",
      "opp": "DeMo",
      "oppId": "FdlFpUSGIahm89rZTXrHg2K85ugGqml2fjUWEPHtgPo%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "sRK9%2f4bKPS94Wnfc7pu5xTVQ%2bmKfntG8ypTYuN0n9q8%3d",
      "team": "Nomads",
      "score": "11",
      "oppScore": "8",
      "opp": "Loaded Panda",
      "oppId": "8S%2f2XcUwQbLf0KM7yOmv3HEYXSrnLhAdwnHQvhMjxCY%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "5Grc5t%2fdYaMzcBWOw5iEB8LpR5X8f4zhwtil8oWn14c%3d",
      "team": "Nomads",
      "score": "13",
      "oppScore": "7",
      "opp": "THE BODY",
      "oppId": "nyLl78Eg24EtJDKG0oHsU9e%2fg0hrOYqklm7h8m4R0Jo%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "COAtsafvfs0RzxFtLAi%2fmVHlxFq%2bVNtgiBZRjJ8%2fEaA%3d",
      "team": "Nomads",
      "score": "13",
      "oppScore": "7",
      "opp": "Loaded Panda",
      "oppId": "8S%2f2XcUwQbLf0KM7yOmv3HEYXSrnLhAdwnHQvhMjxCY%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "eP1NEakXIS5ZkHGSMZafE1fToe5Xlh2PljWHT6YjtfU%3d",
      "team": "Nomads",
      "score": "10",
      "oppScore": "13",
      "opp": "Haymaker",
      "oppId": "F7zZjPQTPbFe%2fxIVicHJD2zRIOORpO5hSxfBuY5fxcQ%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "GAcAnGLV78SycHz8svtFGGOX%2bCk2zcS%2fQXQmMbGr2Sw%3d",
      "team": "Nomads",
      "score": "9",
      "oppScore": "13",
      "opp": "HouSE",
      "oppId": "W3JpjYTNBVirvq%2bLhhgD1xswtN%2bDHXo2Y5uhwfm%2f450%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "SQiGsHDd9PqqCSC3k6%2fwmZlTUdent8p1baRe6EvvIZg%3d",
      "team": "Nomads",
      "score": "13",
      "oppScore": "8",
      "opp": "Rubicon Rapids",
      "oppId": "%2bIdRZdPfxa07Yaa3PzfmJw5so76rO6A7JVJAQXOY%2b40%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "UZkRz9UVnvJsTT9tPlZ87HCpIpL3bx%2bMfvnku24sEYw%3d",
      "team": "Nomads",
      "score": "13",
      "oppScore": "12",
      "opp": "Nain Rouge",
      "oppId": "VlCPZLsfBQ0DobBH%2fc22bLMbT2Ge3MbFrq%2fRZ8Jw0gM%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "8PKzuJ9tLlDPQ8ImY4h5moqWpvi%2bBjfsvAZ%2fr4o77S8%3d",
      "team": "Nomads",
      "score": "7",
      "oppScore": "13",
      "opp": "MKE",
      "oppId": "qHyFAYrlLVWh07Z9rgHqv1nh7SsCt5k4p8Ri0Bna7Zc%3d"
    }
  ],
  "WAPjwPL2R8YMCWgolwmIL1cqRoFxsqyk2gQeZ9iDOMA%3d": [
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "A7gHe%2faGt%2fNu9tvcjSPWWmbHyaQ2orqDGwcFKSC1LKU%3d",
      "team": "Sonoran Dog",
      "score": "12",
      "oppScore": "10",
      "opp": "Nomads",
      "oppId": "clyQEvW53ITGsU%2bGFSkoVd77O856fO%2f%2fPStXV2bXfy4%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "zaQUgPS8sBbVyQ9idrMU7sViryMN7Ou5DuzIt3KAMEA%3d",
      "team": "Sonoran Dog",
      "score": "5",
      "oppScore": "15",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    },
    {
      "date": "June 18",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "fXiKnOIcE9PTNkrlnOTWj47Ltmw1aapP%2fnqhjOkiVXI%3d",
      "team": "Sonoran Dog",
      "score": "12",
      "oppScore": "8",
      "opp": "NOx",
      "oppId": "YLXPz%2fCNrXaNTSa14rMcZI2zV%2brkt0P1fA0UxcJlPaE%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "tNBanFnwOu3L1E9oLD%2bURU69%2f6nBZ7mbXQLJeA%2bkWRs%3d",
      "team": "Sonoran Dog",
      "score": "14",
      "oppScore": "12",
      "opp": "Scythe",
      "oppId": "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "h0cJPHy88Mdnt62Wvt5U5xSqrRoL4y41K0jzsIkobRw%3d",
      "team": "Sonoran Dog",
      "score": "7",
      "oppScore": "15",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    },
    {
      "date": "June 19",
      "tournament": "Colorado Summer Solstice",
      "tournamentId": "Colorado-Summer-Solstice",
      "gameId": "OV2MUk4F3e%2fSTvU70beKVcRXWXdGQpuJUVc6hIj%2bIhk%3d",
      "team": "Sonoran Dog",
      "score": "3",
      "oppScore": "3",
      "opp": "Drought",
      "oppId": "ksz2i7%2bjkkoIQzWKSrtEphZr9icgiUTPL3Ixum49pfY%3d"
    },
    {
      "date": "August 06",
      "tournament": "Coconino Classic",
      "tournamentId": "Coconino-Classic",
      "gameId": "0aFNp0vZ2qZCNy0Ee8Jv0Trehr%2f%2byhrO6ZigbDHaJTA%3d",
      "team": "Sonoran Dog",
      "score": "15",
      "oppScore": "9",
      "opp": "Seven Out",
      "oppId": "EKT%2b4wN3oW3%2bvTZF1BOVy%2fpLrku%2frikWkw8iDqstIOQ%3d"
    },
    {
      "date": "August 06",
      "tournament": "Coconino Classic",
      "tournamentId": "Coconino-Classic",
      "gameId": "yfbvlFtz%2bAWkdyShcg1ahJU3MND5lRYSKpV94pKFa4g%3d",
      "team": "Sonoran Dog",
      "score": "14",
      "oppScore": "8",
      "opp": "Monsoon",
      "oppId": "iMWyb%2fFNBKjTkIqXjFBY%2bhoKQ%2b3niXP6UqbJkmR4Fiw%3d"
    },
    {
      "date": "August 06",
      "tournament": "Coconino Classic",
      "tournamentId": "Coconino-Classic",
      "gameId": "foGlvZwKp4hZE8KKJYIWhzePJfCsegrwv4llrjdlT%2bw%3d",
      "team": "Sonoran Dog",
      "score": "9",
      "oppScore": "15",
      "opp": "Drought",
      "oppId": "ksz2i7%2bjkkoIQzWKSrtEphZr9icgiUTPL3Ixum49pfY%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "sCwkMHQOKzXVeYU4jksLsYjDqq2Zi0yTPoL8TA9%2bF4s%3d",
      "team": "Sonoran Dog",
      "score": "8",
      "oppScore": "12",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "5cR4RI4tzLqZjux0HKnmnb3AtPqv%2bA8ZuAhUXbrKLeY%3d",
      "team": "Sonoran Dog",
      "score": "6",
      "oppScore": "13",
      "opp": "Foxtrot",
      "oppId": "0iROWvYUWTMjSSoepkFSHy9JjWFh%2bD04t8pOIQ3p9I4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "A46k%2b0LeJromPEv9WiVb1ehbJzuybBYadkrXmoN%2fSjk%3d",
      "team": "Sonoran Dog",
      "score": "7",
      "oppScore": "9",
      "opp": "Firefly TX",
      "oppId": "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "ANAX619FoRn5sJUxM%2fDm3Tk%2bgBsODUqqJE4vz9EWw3g%3d",
      "team": "Sonoran Dog",
      "score": "5",
      "oppScore": "12",
      "opp": "Flash Flood",
      "oppId": "JQrYQgb4YJNC2aMynOWjHiZmxJvsuqeLyz2Red9EabU%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "qyofxuLNpmd6t8eFLKnteiDcueRFqEI8jUtK17oGYGY%3d",
      "team": "Sonoran Dog",
      "score": "13",
      "oppScore": "6",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "2J%2bBx7NaEmVg90GX46NmbL6es5frk2ZZroYDakuJRnc%3d",
      "team": "Sonoran Dog",
      "score": "13",
      "oppScore": "1",
      "opp": "Riverside Messengers [B]",
      "oppId": "%2fk2Al%2fURWo%2fM8QndFYA1FAuFkpV3PwlxFADSQxiJBu4%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "SkT0HERs%2fOzl0qAo5FRHdpTeH8jGOOIvXwjNQ13suTA%3d",
      "team": "Sonoran Dog",
      "score": "8",
      "oppScore": "12",
      "opp": "Alamode",
      "oppId": "KQpJIKwJNo0Ue1B4HG72J1RHG35Xquk0pCeyRP4y06g%3d"
    }
  ],
  "btdybQHkeLP7UjqWKhbXqcEMojopFgSilO0%2b3mjGSzM%3d": [
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "iGFL39I8p1CgBt9VAfsM0O0i9iiHnfCe4MSa8xUveTw%3d",
      "team": "Riverside",
      "score": "6",
      "oppScore": "15",
      "opp": "Flash Flood",
      "oppId": "JQrYQgb4YJNC2aMynOWjHiZmxJvsuqeLyz2Red9EabU%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "6JrtY%2biIfmtgMKnbg2wGWTq6qTt1YvOHtUZ0V7bRUso%3d",
      "team": "Riverside",
      "score": "13",
      "oppScore": "4",
      "opp": "Shrimp Discs",
      "oppId": "Bo%2bC%2fuzaALnn%2bTSSrEp4YXd6tJCmxm1yj1wwlvK%2fvJ4%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "%2fVxnhp6I9tAiRgY3YAm5NOGN7Cmeh5QSw5o2Rjhk%2f%2fo%3d",
      "team": "Riverside",
      "score": "8",
      "oppScore": "12",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "L2eK3%2fYM7Myej5SH%2bNWsIlHi9ePzfXVYitz2TFIWBGs%3d",
      "team": "Riverside",
      "score": "13",
      "oppScore": "3",
      "opp": "Central Arkansas Surge",
      "oppId": "a2C88dafFqaR9lnMU22LAsN40joRWyCBnRQyyoRh3jY%3d"
    },
    {
      "date": "June 19",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "%2bpZk9A0qLXO1qKVyPpMxnXz1bc36qL8%2bt%2bbH0o%2bJjGM%3d",
      "team": "Riverside",
      "score": "W",
      "oppScore": "L",
      "opp": "Shrimp Discs",
      "oppId": "Bo%2bC%2fuzaALnn%2bTSSrEp4YXd6tJCmxm1yj1wwlvK%2fvJ4%3d"
    },
    {
      "date": "June 19",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "lJl62lVd%2bEMBYXNEKHHFnYq4LKyYZxjUnAEZMbZNNCI%3d",
      "team": "Riverside",
      "score": "13",
      "oppScore": "11",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "42x3%2bJKHcEXcKzh1w39Wt%2f4GXfTztQ%2bk5q7oCfTaQhs%3d",
      "team": "Riverside",
      "score": "13",
      "oppScore": "3",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "Wap3uX%2f%2brblVRU5tgSf%2bV9h2yxOhfZiJiSXoffR58Qg%3d",
      "team": "Riverside",
      "score": "12",
      "oppScore": "10",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "pOoSyll%2fr4P3aJSCVkIJFuIYZS8V6YzMUmPfbqpcAO4%3d",
      "team": "Riverside",
      "score": "13",
      "oppScore": "2",
      "opp": "Adventure Time",
      "oppId": "qvXwPo5QehiheOQO5VGwHcsVyYUPf2WOcw0w%2fKf0KR0%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "0FJsLdhMNUptZwOk1ZnqRvPbrZUYwO4x3Q6zuVZMZ14%3d",
      "team": "Riverside",
      "score": "13",
      "oppScore": "11",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "GJDUTRXi%2fHmhewxbwVGAvXJzlCPKk1oP2pDJQn9Gqqw%3d",
      "team": "Riverside",
      "score": "10",
      "oppScore": "9",
      "opp": "Firefly TX",
      "oppId": "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "mVyXKC7q%2fLI5SFzdS6eSBz2PaRWGhQZHs4ZUOURs1z4%3d",
      "team": "Riverside",
      "score": "13",
      "oppScore": "7",
      "opp": "Dallas United Outlaws",
      "oppId": "JvlTMemVWiCex%2fC0N5CCgk2DrV419cGvk%2bO1z84fDZY%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "YYHIPVOqKYaQVuagxZ6WW8WAH2faK1RZYudPbHzRByI%3d",
      "team": "Riverside",
      "score": "13",
      "oppScore": "7",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "JbljbpQePbXZirdaoWzJyqFX5%2bEgqPd4mIEjG7OGJ7I%3d",
      "team": "Riverside",
      "score": "10",
      "oppScore": "13",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "uxFyNcNlSI9MjIX%2fd2yt4NpbMBe8Un9EYdRWwoIQU%2b4%3d",
      "team": "Riverside",
      "score": "13",
      "oppScore": "11",
      "opp": "Harvey Cats",
      "oppId": "cbjkcOMrhSyGKLqnK1uuJLy8ENbcEcYemex94WHxvxU%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "rHXcrtuz89yTZdAauWPMmNSAHlwJJteW%2fVDIkU1dEHA%3d",
      "team": "Riverside",
      "score": "12",
      "oppScore": "8",
      "opp": "Foxtrot",
      "oppId": "0iROWvYUWTMjSSoepkFSHy9JjWFh%2bD04t8pOIQ3p9I4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "eht9oCi8NyV4e7dpVjEoM3boToTxee8%2bXSCjL3gSzy8%3d",
      "team": "Riverside",
      "score": "9",
      "oppScore": "13",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "TDaxPEfLH8DsltmteI5sIAb26qxxVC7Ca35VzZaS9K8%3d",
      "team": "Riverside",
      "score": "9",
      "oppScore": "11",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "dcgEPZsmGx%2fmgHQjnpBjklKUoBM7YEr5CSvaVgx802A%3d",
      "team": "Riverside",
      "score": "13",
      "oppScore": "4",
      "opp": "Riverside Messengers [B]",
      "oppId": "%2fk2Al%2fURWo%2fM8QndFYA1FAuFkpV3PwlxFADSQxiJBu4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "SlR%2fDNJrn%2fcGB6jZOmZ2mKK7tY7WI9NNVjdQ62IGL3M%3d",
      "team": "Riverside",
      "score": "11",
      "oppScore": "12",
      "opp": "Alamode",
      "oppId": "KQpJIKwJNo0Ue1B4HG72J1RHG35Xquk0pCeyRP4y06g%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "AoRHRXQmDIc4f2Rc%2bvoZw7dHL9dEYvmMIQ%2bM9VrgOCI%3d",
      "team": "Riverside",
      "score": "13",
      "oppScore": "5",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "uchMlwkM%2bqblG9N3AQHOfcnOMzHO8inrM6nws0lXT4c%3d",
      "team": "Riverside",
      "score": "12",
      "oppScore": "10",
      "opp": "Flash Flood",
      "oppId": "JQrYQgb4YJNC2aMynOWjHiZmxJvsuqeLyz2Red9EabU%3d"
    }
  ],
  "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d": [
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "Wnx%2bgk19RGJTtka9nhi8Gs%2flyJw4w08fHdgpXkMC15U%3d",
      "team": "Gamble",
      "score": "15",
      "oppScore": "8",
      "opp": "Shrimp Discs",
      "oppId": "Bo%2bC%2fuzaALnn%2bTSSrEp4YXd6tJCmxm1yj1wwlvK%2fvJ4%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "yNdgEKl0ffuyhiZ32wbvRKFmLUHCx1kIG9YWfelVojQ%3d",
      "team": "Gamble",
      "score": "13",
      "oppScore": "3",
      "opp": "Meadowlark",
      "oppId": "5mFdu3Xc%2bl7OoxB5yBc9Q8%2bCImG%2b%2fOFvdvmpUhgLwdo%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "bPTbWEBjXrrmhC44jFl8PqNNGidwhzA3aOJIFTwiGwQ%3d",
      "team": "Gamble",
      "score": "14",
      "oppScore": "13",
      "opp": "Alamode",
      "oppId": "KQpJIKwJNo0Ue1B4HG72J1RHG35Xquk0pCeyRP4y06g%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "qZkgm4yGVQkHE24Jmq8VcVQCwrjBF6iGkUlFMHylojw%3d",
      "team": "Gamble",
      "score": "13",
      "oppScore": "5",
      "opp": "Alamode",
      "oppId": "KQpJIKwJNo0Ue1B4HG72J1RHG35Xquk0pCeyRP4y06g%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "sXLWZHDPl316WCIDCDyraiM7qsn5iBbY3nJKNAvnbLQ%3d",
      "team": "Gamble",
      "score": "8",
      "oppScore": "13",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "e7DYbGCwXv1obRuGyu3WqhLN5W0PmIiYO5SiT3t4UqY%3d",
      "team": "Gamble",
      "score": "10",
      "oppScore": "7",
      "opp": "Flash Flood",
      "oppId": "JQrYQgb4YJNC2aMynOWjHiZmxJvsuqeLyz2Red9EabU%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "tn6FnE73cVlYzVFlkn4LT%2bDDjQAXkYriXsF9TvlEDos%3d",
      "team": "Gamble",
      "score": "9",
      "oppScore": "6",
      "opp": "Firefly TX",
      "oppId": "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "QeBy27txfuz3q6I%2fMnMrhtJVkhulJGEwrBRCXo1HtbM%3d",
      "team": "Gamble",
      "score": "11",
      "oppScore": "6",
      "opp": "Harvey Cats",
      "oppId": "cbjkcOMrhSyGKLqnK1uuJLy8ENbcEcYemex94WHxvxU%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "g2ewdAmtpUPKKHJXaLu6%2bo23gg7KDRa%2b9AHyBGAt45w%3d",
      "team": "Gamble",
      "score": "8",
      "oppScore": "9",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "RrJ0r%2fwtzZvrNEHgxNBrwF%2f2ISe4M%2b%2bNlme6CO18EoY%3d",
      "team": "Gamble",
      "score": "9",
      "oppScore": "10",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "v8GG%2betXee9YaPt8HnM7F7elx054%2ffC5IX%2fmYxg10%2b8%3d",
      "team": "Gamble",
      "score": "11",
      "oppScore": "7",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "NH6dm%2fxmDsi3y5nJEDCT7kQdMZ6%2bueTOSZqoyIgKXtI%3d",
      "team": "Gamble",
      "score": "11",
      "oppScore": "2",
      "opp": "Adventure Time",
      "oppId": "qvXwPo5QehiheOQO5VGwHcsVyYUPf2WOcw0w%2fKf0KR0%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "nngBtWwudMOqSQOaCNLtOd%2f2BDgBG530U61%2b1WVoVKE%3d",
      "team": "Gamble",
      "score": "10",
      "oppScore": "11",
      "opp": "Clutch",
      "oppId": "JDc%2bBsCjGUX7gdebRqmdPvq1u2rFlZ1giM6BgTaL5Lk%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "DB4p47CPfDUASVNzL5PdfImkzWpsAh27VG0CE8ls4%2fw%3d",
      "team": "Gamble",
      "score": "8",
      "oppScore": "7",
      "opp": "Foxtrot",
      "oppId": "0iROWvYUWTMjSSoepkFSHy9JjWFh%2bD04t8pOIQ3p9I4%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "lVMr6Av5RgaRNBGssFqRK6n6v4RACb1baWLLwD7O4PQ%3d",
      "team": "Gamble",
      "score": "13",
      "oppScore": "4",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "Wap3uX%2f%2brblVRU5tgSf%2bV9h2yxOhfZiJiSXoffR58Qg%3d",
      "team": "Gamble",
      "score": "10",
      "oppScore": "12",
      "opp": "Riverside",
      "oppId": "btdybQHkeLP7UjqWKhbXqcEMojopFgSilO0%2b3mjGSzM%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "rd%2bE4MmckEh6BBlO6VYhov2ZZjWO3kfLg7Y2zndqn2I%3d",
      "team": "Gamble",
      "score": "13",
      "oppScore": "6",
      "opp": "Firefly TX",
      "oppId": "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "2fNtlE2JtvXwRH34bKtNfQrfaw0OEGaS%2fZcLz%2ftIQrg%3d",
      "team": "Gamble",
      "score": "13",
      "oppScore": "2",
      "opp": "Adventure Time",
      "oppId": "qvXwPo5QehiheOQO5VGwHcsVyYUPf2WOcw0w%2fKf0KR0%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "ohGduYztYE83ac4%2bS0s%2f6G6bCYIrdg%2fQkt%2fYrDwzJQs%3d",
      "team": "Gamble",
      "score": "13",
      "oppScore": "2",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "4T6mGkkHtwJ6u5k%2bXmTLkfitK1uE1w3Z0QA2h5sWKgs%3d",
      "team": "Gamble",
      "score": "13",
      "oppScore": "7",
      "opp": "Foxtrot",
      "oppId": "0iROWvYUWTMjSSoepkFSHy9JjWFh%2bD04t8pOIQ3p9I4%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "kOZJSgm1LqXAAnyMd9gXhstaCygRTQ0TDBvjpOhVPzk%3d",
      "team": "Gamble",
      "score": "11",
      "oppScore": "13",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "cqzl0I%2bbQ6tKDFZdZRfr9DB4IMxsUa6QVDECUvfzDyc%3d",
      "team": "Gamble",
      "score": "13",
      "oppScore": "8",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "sCwkMHQOKzXVeYU4jksLsYjDqq2Zi0yTPoL8TA9%2bF4s%3d",
      "team": "Gamble",
      "score": "12",
      "oppScore": "8",
      "opp": "Sonoran Dog",
      "oppId": "WAPjwPL2R8YMCWgolwmIL1cqRoFxsqyk2gQeZ9iDOMA%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "lsydUVc%2faUilLhA%2bxMviaMClm7wmFMCRVmHlZUmTIl0%3d",
      "team": "Gamble",
      "score": "13",
      "oppScore": "5",
      "opp": "Flash Flood",
      "oppId": "JQrYQgb4YJNC2aMynOWjHiZmxJvsuqeLyz2Red9EabU%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "y0%2fMgR2HyIygDnDwXkNx6kPpGs2aps9WKZxGjOUrNxI%3d",
      "team": "Gamble",
      "score": "13",
      "oppScore": "11",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "g2f326SjrvEhPcg9TK%2boKlZV4nT%2bAmSu5gDbEM08cPg%3d",
      "team": "Gamble",
      "score": "11",
      "oppScore": "10",
      "opp": "Foxtrot",
      "oppId": "0iROWvYUWTMjSSoepkFSHy9JjWFh%2bD04t8pOIQ3p9I4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "TDaxPEfLH8DsltmteI5sIAb26qxxVC7Ca35VzZaS9K8%3d",
      "team": "Gamble",
      "score": "11",
      "oppScore": "9",
      "opp": "Riverside",
      "oppId": "btdybQHkeLP7UjqWKhbXqcEMojopFgSilO0%2b3mjGSzM%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "Tm%2frVdAgUXxLLGEbfUtX5qivc7zGNL49yYhxxMpIDhk%3d",
      "team": "Gamble",
      "score": "13",
      "oppScore": "3",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "XPTV6Bi5QdFZSFWD8TLrqLLYqrcKnm7HKvJF8DmSCPw%3d",
      "team": "Gamble",
      "score": "13",
      "oppScore": "6",
      "opp": "Firefly TX",
      "oppId": "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "45TKENTQiZBg4ehrU6ixVEh0DssKe0yK6RAEbkVuans%3d",
      "team": "Gamble",
      "score": "7",
      "oppScore": "13",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    }
  ],
  "JQrYQgb4YJNC2aMynOWjHiZmxJvsuqeLyz2Red9EabU%3d": [
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "iGFL39I8p1CgBt9VAfsM0O0i9iiHnfCe4MSa8xUveTw%3d",
      "team": "Flash Flood",
      "score": "15",
      "oppScore": "6",
      "opp": "Riverside",
      "oppId": "btdybQHkeLP7UjqWKhbXqcEMojopFgSilO0%2b3mjGSzM%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "nIiv1CpNy1gK9p9ujYccH4j9%2fa43qnlup4pVK3pSCJ0%3d",
      "team": "Flash Flood",
      "score": "13",
      "oppScore": "3",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "UVEh%2bOREphVNqIHINM33e%2fC2p7aXZOGMWmNa5z73lzE%3d",
      "team": "Flash Flood",
      "score": "12",
      "oppScore": "14",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "wZ1HNzCFLq0WCTepzpFNLEg6%2bytWv%2fi3NC02cAUEPpc%3d",
      "team": "Flash Flood",
      "score": "11",
      "oppScore": "10",
      "opp": "Dallas United Outlaws",
      "oppId": "JvlTMemVWiCex%2fC0N5CCgk2DrV419cGvk%2bO1z84fDZY%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "e7DYbGCwXv1obRuGyu3WqhLN5W0PmIiYO5SiT3t4UqY%3d",
      "team": "Flash Flood",
      "score": "7",
      "oppScore": "10",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "June 19",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "C77PdMcxUuN%2fqaDFIxDxNf4TiUy3mKMO%2fEAzjL0KDbs%3d",
      "team": "Flash Flood",
      "score": "11",
      "oppScore": "9",
      "opp": "Alamode",
      "oppId": "KQpJIKwJNo0Ue1B4HG72J1RHG35Xquk0pCeyRP4y06g%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "o2tW9XudK%2fvHHB%2bDRcekUXKw2Ny5wH5D8Tdt16uv4hg%3d",
      "team": "Flash Flood",
      "score": "8",
      "oppScore": "15",
      "opp": "GOAT",
      "oppId": "6IFGDipIDG9l%2br4E8TUyujjAslzlgFQUH9y5j9M0G4g%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "cfqPuPcJUvVX8BF6HvKTdyuX6ow0pU%2fGE4tjAcxMoaQ%3d",
      "team": "Flash Flood",
      "score": "12",
      "oppScore": "11",
      "opp": "Zyzzyva",
      "oppId": "Yfu4RKEHOC158Q5KQGT5xHxtk5hGHKp2RxFoXMEKk%2fA%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "nGcgHgOcn7FflcI5lxwdxtHG7cvN%2fn4WG%2f6qEJIMd34%3d",
      "team": "Flash Flood",
      "score": "6",
      "oppScore": "14",
      "opp": "Tanasi",
      "oppId": "7MICo92AgV%2flT4d8dpq%2bhDNyJFhVtTVq8%2f94L7J8idw%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "7nzSYel8yFTUzZmMK1feU26Vv6PUiM1%2blVRRMqmpiMg%3d",
      "team": "Flash Flood",
      "score": "11",
      "oppScore": "12",
      "opp": "Ghost Train",
      "oppId": "Tqxb9%2fb2Hxtbg%2f54ifErhjS%2bc%2fBnacDZk5RhQ%2fd%2b%2f5g%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "usKc1Rhj29llAUiKC36U%2f%2bbceZ2IU2FOkhoLW6gAjlg%3d",
      "team": "Flash Flood",
      "score": "9",
      "oppScore": "10",
      "opp": "General Strike",
      "oppId": "%2bAa3yq8xXOgWBEYZi%2fAT%2fiiGNJfFLcQeXn3x6sH%2fmhA%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "DNBB0poCnAulitUeBAr7Cs0%2bBk6wiY1wCuTbvMNZz6s%3d",
      "team": "Flash Flood",
      "score": "12",
      "oppScore": "9",
      "opp": "Battery",
      "oppId": "XgsOT%2bl5FkWgZoV7cQAy4vMUDJS3ygcjNozHGHsTYUE%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "rCUkxOANCJ8IwaSK5shDHKVhCG3ZBXBfEk7Czwc%2fx5c%3d",
      "team": "Flash Flood",
      "score": "13",
      "oppScore": "11",
      "opp": "Firefly TX",
      "oppId": "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "EgsPFgBShcEdg79rveAuBmc9zfwHE%2bDkkbSsb6y7Uj0%3d",
      "team": "Flash Flood",
      "score": "12",
      "oppScore": "11",
      "opp": "Harvey Cats",
      "oppId": "cbjkcOMrhSyGKLqnK1uuJLy8ENbcEcYemex94WHxvxU%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "lsydUVc%2faUilLhA%2bxMviaMClm7wmFMCRVmHlZUmTIl0%3d",
      "team": "Flash Flood",
      "score": "5",
      "oppScore": "13",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "kDyw%2bBmGLpeN8ry7u2eJCNzI3nu13%2bWgYfG35A0RJLA%3d",
      "team": "Flash Flood",
      "score": "4",
      "oppScore": "13",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "tjGI0EUsmGqdfJMfvXN3SWuUme8JIeAM1vIqz3Ci2ek%3d",
      "team": "Flash Flood",
      "score": "13",
      "oppScore": "3",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "ANAX619FoRn5sJUxM%2fDm3Tk%2bgBsODUqqJE4vz9EWw3g%3d",
      "team": "Flash Flood",
      "score": "12",
      "oppScore": "5",
      "opp": "Sonoran Dog",
      "oppId": "WAPjwPL2R8YMCWgolwmIL1cqRoFxsqyk2gQeZ9iDOMA%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "m9Ip8nb4QOLTW7uWCjJ0vNwy8zJpP3bxDYRPETObO0k%3d",
      "team": "Flash Flood",
      "score": "13",
      "oppScore": "7",
      "opp": "Foxtrot",
      "oppId": "0iROWvYUWTMjSSoepkFSHy9JjWFh%2bD04t8pOIQ3p9I4%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "uchMlwkM%2bqblG9N3AQHOfcnOMzHO8inrM6nws0lXT4c%3d",
      "team": "Flash Flood",
      "score": "10",
      "oppScore": "12",
      "opp": "Riverside",
      "oppId": "btdybQHkeLP7UjqWKhbXqcEMojopFgSilO0%2b3mjGSzM%3d"
    }
  ],
  "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d": [
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "8di4c2i9ffUd%2fuAzdziMOCM39BA9i6y1sD9OdQzVAMY%3d",
      "team": "Cowtown Cannons",
      "score": "12",
      "oppScore": "11",
      "opp": "Shrimp Discs",
      "oppId": "Bo%2bC%2fuzaALnn%2bTSSrEp4YXd6tJCmxm1yj1wwlvK%2fvJ4%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "4AiZEgVa5hEKfR6HdFbKyLD%2fJykdW4aVYgHuP%2fJNes0%3d",
      "team": "Cowtown Cannons",
      "score": "10",
      "oppScore": "12",
      "opp": "Alamode",
      "oppId": "KQpJIKwJNo0Ue1B4HG72J1RHG35Xquk0pCeyRP4y06g%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "%2fVxnhp6I9tAiRgY3YAm5NOGN7Cmeh5QSw5o2Rjhk%2f%2fo%3d",
      "team": "Cowtown Cannons",
      "score": "12",
      "oppScore": "8",
      "opp": "Riverside",
      "oppId": "btdybQHkeLP7UjqWKhbXqcEMojopFgSilO0%2b3mjGSzM%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "3GAzhI2sgE1gKKStnq3ig5E7W9ZbFMYp5MmpgmHZ9%2fE%3d",
      "team": "Cowtown Cannons",
      "score": "9",
      "oppScore": "13",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "June 19",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "PvfQyhePlr9gu0EekVPHepb3aMu1rIPvViEGd3jyvvg%3d",
      "team": "Cowtown Cannons",
      "score": "12",
      "oppScore": "5",
      "opp": "Dallas United Outlaws",
      "oppId": "JvlTMemVWiCex%2fC0N5CCgk2DrV419cGvk%2bO1z84fDZY%3d"
    },
    {
      "date": "June 19",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "lJl62lVd%2bEMBYXNEKHHFnYq4LKyYZxjUnAEZMbZNNCI%3d",
      "team": "Cowtown Cannons",
      "score": "11",
      "oppScore": "13",
      "opp": "Riverside",
      "oppId": "btdybQHkeLP7UjqWKhbXqcEMojopFgSilO0%2b3mjGSzM%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "91ZdNXxRhpELNcZ7TNV%2fz0%2fsUTOyhjImvUtx68iVtJw%3d",
      "team": "Cowtown Cannons",
      "score": "9",
      "oppScore": "5",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "PO3Fnv%2fb170iJ4rU0nUHimB%2fa%2bscKLlNWDqI23WdNE0%3d",
      "team": "Cowtown Cannons",
      "score": "5",
      "oppScore": "9",
      "opp": "Clutch",
      "oppId": "JDc%2bBsCjGUX7gdebRqmdPvq1u2rFlZ1giM6BgTaL5Lk%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "IxOp%2bCRQI%2fY1NGQAyH6lEPXhcQWdVAoYK0ZrZi6NxLk%3d",
      "team": "Cowtown Cannons",
      "score": "10",
      "oppScore": "7",
      "opp": "Foxtrot",
      "oppId": "0iROWvYUWTMjSSoepkFSHy9JjWFh%2bD04t8pOIQ3p9I4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "n22oqV1ZG1MBgw8PSnL9KAatOXFycp8OYmTMpKSwphs%3d",
      "team": "Cowtown Cannons",
      "score": "11",
      "oppScore": "4",
      "opp": "Adventure Time",
      "oppId": "qvXwPo5QehiheOQO5VGwHcsVyYUPf2WOcw0w%2fKf0KR0%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "v8GG%2betXee9YaPt8HnM7F7elx054%2ffC5IX%2fmYxg10%2b8%3d",
      "team": "Cowtown Cannons",
      "score": "7",
      "oppScore": "11",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "eA3YMRhDgm3ipHZTpjyDlPSrv9d71PJuYg8pJxV%2fNPI%3d",
      "team": "Cowtown Cannons",
      "score": "7",
      "oppScore": "8",
      "opp": "Firefly TX",
      "oppId": "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "2UlWF%2fn9akjzCoXhdEl4QiRDnaC8JjMZXa79KOQZtJ4%3d",
      "team": "Cowtown Cannons",
      "score": "7",
      "oppScore": "10",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "Fj8sFAYj4VZdMJkVE2OyG6hp4%2bI3oEJStYIyOw%2f7PhE%3d",
      "team": "Cowtown Cannons",
      "score": "7",
      "oppScore": "8",
      "opp": "Harvey Cats",
      "oppId": "cbjkcOMrhSyGKLqnK1uuJLy8ENbcEcYemex94WHxvxU%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "lVMr6Av5RgaRNBGssFqRK6n6v4RACb1baWLLwD7O4PQ%3d",
      "team": "Cowtown Cannons",
      "score": "4",
      "oppScore": "13",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "3YCGjIT2i0Wk3l9yNY%2bBY2G50L8lDJBiV1I7jc7ErL4%3d",
      "team": "Cowtown Cannons",
      "score": "11",
      "oppScore": "10",
      "opp": "Firefly TX",
      "oppId": "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "ZZX%2fFOPIqsmrslLSNi0fapz13Fdz%2bf9hCxQDTqHRGkA%3d",
      "team": "Cowtown Cannons",
      "score": "13",
      "oppScore": "6",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "0FJsLdhMNUptZwOk1ZnqRvPbrZUYwO4x3Q6zuVZMZ14%3d",
      "team": "Cowtown Cannons",
      "score": "11",
      "oppScore": "13",
      "opp": "Riverside",
      "oppId": "btdybQHkeLP7UjqWKhbXqcEMojopFgSilO0%2b3mjGSzM%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "T9yDNn7Q0GB%2b5St%2bwa%2bxW9WkWmbd6V3nKgAohsqHfSY%3d",
      "team": "Cowtown Cannons",
      "score": "13",
      "oppScore": "2",
      "opp": "Adventure Time",
      "oppId": "qvXwPo5QehiheOQO5VGwHcsVyYUPf2WOcw0w%2fKf0KR0%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "IXfOfIq8aPCzDf13472Xhv9eXhrCMphpywt7tvLp97g%3d",
      "team": "Cowtown Cannons",
      "score": "11",
      "oppScore": "10",
      "opp": "Alamode",
      "oppId": "KQpJIKwJNo0Ue1B4HG72J1RHG35Xquk0pCeyRP4y06g%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "YYHIPVOqKYaQVuagxZ6WW8WAH2faK1RZYudPbHzRByI%3d",
      "team": "Cowtown Cannons",
      "score": "7",
      "oppScore": "13",
      "opp": "Riverside",
      "oppId": "btdybQHkeLP7UjqWKhbXqcEMojopFgSilO0%2b3mjGSzM%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "cqzl0I%2bbQ6tKDFZdZRfr9DB4IMxsUa6QVDECUvfzDyc%3d",
      "team": "Cowtown Cannons",
      "score": "8",
      "oppScore": "13",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "OK2Sk3gILadaPP0UYBCuFkch3PHJXCi9fR89xF3Tuew%3d",
      "team": "Cowtown Cannons",
      "score": "13",
      "oppScore": "1",
      "opp": "Riverside Messengers [B]",
      "oppId": "%2fk2Al%2fURWo%2fM8QndFYA1FAuFkpV3PwlxFADSQxiJBu4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "r0lwhNAHIf%2bozaf%2bwpsh%2f9qleikdh%2f5QQ64MRcLaI%2bc%3d",
      "team": "Cowtown Cannons",
      "score": "11",
      "oppScore": "10",
      "opp": "Alamode",
      "oppId": "KQpJIKwJNo0Ue1B4HG72J1RHG35Xquk0pCeyRP4y06g%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "y0%2fMgR2HyIygDnDwXkNx6kPpGs2aps9WKZxGjOUrNxI%3d",
      "team": "Cowtown Cannons",
      "score": "11",
      "oppScore": "13",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "pQ5NwvAXKiVflj%2bjYghuTYsQ0aTr%2bbx18p%2fAOwxJGKE%3d",
      "team": "Cowtown Cannons",
      "score": "4",
      "oppScore": "13",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "t0C05XgcbZclUuT6u2Cp7rmVeLH1iApcRIPWKxZstYI%3d",
      "team": "Cowtown Cannons",
      "score": "7",
      "oppScore": "13",
      "opp": "Harvey Cats",
      "oppId": "cbjkcOMrhSyGKLqnK1uuJLy8ENbcEcYemex94WHxvxU%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "AoRHRXQmDIc4f2Rc%2bvoZw7dHL9dEYvmMIQ%2bM9VrgOCI%3d",
      "team": "Cowtown Cannons",
      "score": "5",
      "oppScore": "13",
      "opp": "Riverside",
      "oppId": "btdybQHkeLP7UjqWKhbXqcEMojopFgSilO0%2b3mjGSzM%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "dABWwrI7Tkj7PqeEKgyGeSRyEnJ7KjOg4C%2bfMmn1pZ8%3d",
      "team": "Cowtown Cannons",
      "score": "10",
      "oppScore": "13",
      "opp": "Foxtrot",
      "oppId": "0iROWvYUWTMjSSoepkFSHy9JjWFh%2bD04t8pOIQ3p9I4%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "OhFbKxpj24KfcRP6GrCyYByt%2fOIuWKtdiDdtBxo8MQg%3d",
      "team": "Cowtown Cannons",
      "score": "9",
      "oppScore": "3",
      "opp": "Firefly TX",
      "oppId": "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d"
    }
  ],
  "a2C88dafFqaR9lnMU22LAsN40joRWyCBnRQyyoRh3jY%3d": [
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "U4jejffR17giBfccm8Mx6NQKRAVcrqIQC1dTAHVz3Gg%3d",
      "team": "Central Arkansas Surge",
      "score": "8",
      "oppScore": "13",
      "opp": "Rawhide",
      "oppId": "loSeq%2bvNEN8CCdx6pU5P0mfNTczm7%2fAa01t6KMfxol0%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "c9%2fp1efO4cPbcrztvQsr%2fsWFVtX%2f83kMp1SyRh2J8Og%3d",
      "team": "Central Arkansas Surge",
      "score": "5",
      "oppScore": "13",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "L2eK3%2fYM7Myej5SH%2bNWsIlHi9ePzfXVYitz2TFIWBGs%3d",
      "team": "Central Arkansas Surge",
      "score": "3",
      "oppScore": "13",
      "opp": "Riverside",
      "oppId": "btdybQHkeLP7UjqWKhbXqcEMojopFgSilO0%2b3mjGSzM%3d"
    },
    {
      "date": "June 19",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "MJZpzxk6iz%2fnDs9tkWWCt7%2fJSRAMvBd1ce2lQB%2bM01U%3d",
      "team": "Central Arkansas Surge",
      "score": "14",
      "oppScore": "9",
      "opp": "Meadowlark",
      "oppId": "5mFdu3Xc%2bl7OoxB5yBc9Q8%2bCImG%2b%2fOFvdvmpUhgLwdo%3d"
    },
    {
      "date": "June 19",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "IqMDmipg6GRUIEbOyfeT%2bNxjtDyajbKsqwWF%2fFjK%2bNg%3d",
      "team": "Central Arkansas Surge",
      "score": "11",
      "oppScore": "14",
      "opp": "Rawhide",
      "oppId": "loSeq%2bvNEN8CCdx6pU5P0mfNTczm7%2fAa01t6KMfxol0%3d"
    },
    {
      "date": "June 19",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "rfxv2SBYC0JELj9pvTYTvFgqwgsB8WZDB9yUchfOw9k%3d",
      "team": "Central Arkansas Surge",
      "score": "14",
      "oppScore": "9",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "August 20",
      "tournament": "OK Corral 2022",
      "tournamentId": "OK-Corral-2022",
      "gameId": "YYRElDfu1T87zAD1kiKN2tohblrPuFoMnem13LFIhxg%3d",
      "team": "Central Arkansas Surge",
      "score": "3",
      "oppScore": "13",
      "opp": "Dreadnought",
      "oppId": "uzGm2kYsw4N4NvAkRAEeLbHAJE108B9cHHBgy%2bR99xE%3d"
    },
    {
      "date": "August 20",
      "tournament": "OK Corral 2022",
      "tournamentId": "OK-Corral-2022",
      "gameId": "Ofy1IEhxtlI1OiEZ%2f9gMugnTpc9i4FtK6bgIUq8bFHw%3d",
      "team": "Central Arkansas Surge",
      "score": "6",
      "oppScore": "13",
      "opp": "Rawhide",
      "oppId": "loSeq%2bvNEN8CCdx6pU5P0mfNTczm7%2fAa01t6KMfxol0%3d"
    },
    {
      "date": "August 28",
      "tournament": "OK Corral 2022",
      "tournamentId": "OK-Corral-2022",
      "gameId": "Q6%2b3Du7h2bgWCk%2b%2bqCBl5pCqcgWBwdp48UitF7BIitI%3d",
      "team": "Central Arkansas Surge",
      "score": "8",
      "oppScore": "15",
      "opp": "Rawhide",
      "oppId": "loSeq%2bvNEN8CCdx6pU5P0mfNTczm7%2fAa01t6KMfxol0%3d"
    }
  ],
  "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d": [
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "r49DEBRcV%2bh1MMJQUc28TvYs5poODg6Xdng9sNOZu6A%3d",
      "team": "H.I.P",
      "score": "15",
      "oppScore": "6",
      "opp": "Dallas United Outlaws",
      "oppId": "JvlTMemVWiCex%2fC0N5CCgk2DrV419cGvk%2bO1z84fDZY%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "c9%2fp1efO4cPbcrztvQsr%2fsWFVtX%2f83kMp1SyRh2J8Og%3d",
      "team": "H.I.P",
      "score": "13",
      "oppScore": "5",
      "opp": "Central Arkansas Surge",
      "oppId": "a2C88dafFqaR9lnMU22LAsN40joRWyCBnRQyyoRh3jY%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "UVEh%2bOREphVNqIHINM33e%2fC2p7aXZOGMWmNa5z73lzE%3d",
      "team": "H.I.P",
      "score": "14",
      "oppScore": "12",
      "opp": "Flash Flood",
      "oppId": "JQrYQgb4YJNC2aMynOWjHiZmxJvsuqeLyz2Red9EabU%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "UbgJ%2fLUKE5GP21QBCbsZv%2fuXBjCr5qeNq6Bag9p0Hy4%3d",
      "team": "H.I.P",
      "score": "13",
      "oppScore": "6",
      "opp": "Rawhide",
      "oppId": "loSeq%2bvNEN8CCdx6pU5P0mfNTczm7%2fAa01t6KMfxol0%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "sXLWZHDPl316WCIDCDyraiM7qsn5iBbY3nJKNAvnbLQ%3d",
      "team": "H.I.P",
      "score": "13",
      "oppScore": "8",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "3GAzhI2sgE1gKKStnq3ig5E7W9ZbFMYp5MmpgmHZ9%2fE%3d",
      "team": "H.I.P",
      "score": "13",
      "oppScore": "9",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "qa0NUdjXtTcjnOAHYGcT4TEf2iHeepNbPJyDnvhwjZc%3d",
      "team": "H.I.P",
      "score": "9",
      "oppScore": "10",
      "opp": "Clutch",
      "oppId": "JDc%2bBsCjGUX7gdebRqmdPvq1u2rFlZ1giM6BgTaL5Lk%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "yTm%2f8mD2rek8Sm%2bY7fnm5dAtA93WFZYcVcJIRnV1Cvo%3d",
      "team": "H.I.P",
      "score": "9",
      "oppScore": "7",
      "opp": "Foxtrot",
      "oppId": "0iROWvYUWTMjSSoepkFSHy9JjWFh%2bD04t8pOIQ3p9I4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "E2t0XzNBSheDu2MaBgg9mhUfGD2Z2pOEprNoHxOM9wA%3d",
      "team": "H.I.P",
      "score": "11",
      "oppScore": "4",
      "opp": "Firefly TX",
      "oppId": "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "RrJ0r%2fwtzZvrNEHgxNBrwF%2f2ISe4M%2b%2bNlme6CO18EoY%3d",
      "team": "H.I.P",
      "score": "10",
      "oppScore": "9",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "ANXvM%2bs2GCo12cEA%2fywcIai3%2b5fX46KKCcMJ0d1twH0%3d",
      "team": "H.I.P",
      "score": "11",
      "oppScore": "4",
      "opp": "Harvey Cats",
      "oppId": "cbjkcOMrhSyGKLqnK1uuJLy8ENbcEcYemex94WHxvxU%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "1O3RCcjOKkbT%2b7AQt0Qnwgo%2bh4Ky87Z6TMwtbzWvTbU%3d",
      "team": "H.I.P",
      "score": "11",
      "oppScore": "3",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "2UlWF%2fn9akjzCoXhdEl4QiRDnaC8JjMZXa79KOQZtJ4%3d",
      "team": "H.I.P",
      "score": "10",
      "oppScore": "7",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "ENgB5i5Tre%2bq7b%2bFyy80FvChipo1KIek50mUMqxr%2f5w%3d",
      "team": "H.I.P",
      "score": "11",
      "oppScore": "1",
      "opp": "Adventure Time",
      "oppId": "qvXwPo5QehiheOQO5VGwHcsVyYUPf2WOcw0w%2fKf0KR0%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "z3nGiwhzqeF%2fVM%2bPxLw7qpxX0D7oM2FT5CorQppS7%2b8%3d",
      "team": "H.I.P",
      "score": "13",
      "oppScore": "5",
      "opp": "Foxtrot",
      "oppId": "0iROWvYUWTMjSSoepkFSHy9JjWFh%2bD04t8pOIQ3p9I4%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "IVLVfXgOAAKNG1Te51zXoiV2cWThYc0O5IWLqhAM0LA%3d",
      "team": "H.I.P",
      "score": "11",
      "oppScore": "13",
      "opp": "Alamode",
      "oppId": "KQpJIKwJNo0Ue1B4HG72J1RHG35Xquk0pCeyRP4y06g%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "BCsmOMFiG8kU%2bcVSDsV7%2feDxVW9DQga49TSedvcADss%3d",
      "team": "H.I.P",
      "score": "13",
      "oppScore": "0",
      "opp": "Deaf Fruit",
      "oppId": "B8w1PwMJi87%2fAp%2b6M8SYsYlCa%2fBJcbAiBoKLkadwT1Y%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "51w84vYjVqSxBcGMSW%2b2GsSQhaNDYGrIVLwffyqF0yw%3d",
      "team": "H.I.P",
      "score": "13",
      "oppScore": "6",
      "opp": "Dallas United Outlaws",
      "oppId": "JvlTMemVWiCex%2fC0N5CCgk2DrV419cGvk%2bO1z84fDZY%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "J3AWrQfAxihcujRuDgum3xG56nx4uYdmKI8M%2ftpt6sg%3d",
      "team": "H.I.P",
      "score": "13",
      "oppScore": "4",
      "opp": "Firefly TX",
      "oppId": "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "kOZJSgm1LqXAAnyMd9gXhstaCygRTQ0TDBvjpOhVPzk%3d",
      "team": "H.I.P",
      "score": "13",
      "oppScore": "11",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "JbljbpQePbXZirdaoWzJyqFX5%2bEgqPd4mIEjG7OGJ7I%3d",
      "team": "H.I.P",
      "score": "13",
      "oppScore": "10",
      "opp": "Riverside",
      "oppId": "btdybQHkeLP7UjqWKhbXqcEMojopFgSilO0%2b3mjGSzM%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "34EfAEucipIfQymDv2hI9A9AV5l%2fjxbPQoeL84bIO44%3d",
      "team": "H.I.P",
      "score": "13",
      "oppScore": "11",
      "opp": "Alamode",
      "oppId": "KQpJIKwJNo0Ue1B4HG72J1RHG35Xquk0pCeyRP4y06g%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "LQYNsPq4R1WFuiG6xj%2fnXek%2bTfjXq7wQiKEMd8RkB2w%3d",
      "team": "H.I.P",
      "score": "13",
      "oppScore": "4",
      "opp": "Firefly TX",
      "oppId": "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "eht9oCi8NyV4e7dpVjEoM3boToTxee8%2bXSCjL3gSzy8%3d",
      "team": "H.I.P",
      "score": "13",
      "oppScore": "9",
      "opp": "Riverside",
      "oppId": "btdybQHkeLP7UjqWKhbXqcEMojopFgSilO0%2b3mjGSzM%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "pQ5NwvAXKiVflj%2bjYghuTYsQ0aTr%2bbx18p%2fAOwxJGKE%3d",
      "team": "H.I.P",
      "score": "13",
      "oppScore": "4",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "kDyw%2bBmGLpeN8ry7u2eJCNzI3nu13%2bWgYfG35A0RJLA%3d",
      "team": "H.I.P",
      "score": "13",
      "oppScore": "4",
      "opp": "Flash Flood",
      "oppId": "JQrYQgb4YJNC2aMynOWjHiZmxJvsuqeLyz2Red9EabU%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "bYk7f%2fqFpiKj3FSH9MkLzEkuZjJxI7Oz8j8DoF0wY%2fU%3d",
      "team": "H.I.P",
      "score": "13",
      "oppScore": "5",
      "opp": "Riverside Messengers [B]",
      "oppId": "%2fk2Al%2fURWo%2fM8QndFYA1FAuFkpV3PwlxFADSQxiJBu4%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "k982rxzqtegKh6957BQrGUPmS8mmuaEkU4Gwq%2fyujuc%3d",
      "team": "H.I.P",
      "score": "13",
      "oppScore": "6",
      "opp": "Harvey Cats",
      "oppId": "cbjkcOMrhSyGKLqnK1uuJLy8ENbcEcYemex94WHxvxU%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "45TKENTQiZBg4ehrU6ixVEh0DssKe0yK6RAEbkVuans%3d",
      "team": "H.I.P",
      "score": "13",
      "oppScore": "7",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    }
  ],
  "5mFdu3Xc%2bl7OoxB5yBc9Q8%2bCImG%2b%2fOFvdvmpUhgLwdo%3d": [
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "DjdCTgUlnEgCL0DzNzu16dDW0bNFEsQHv6JiwbNSPcU%3d",
      "team": "Meadowlark",
      "score": "4",
      "oppScore": "13",
      "opp": "Alamode",
      "oppId": "KQpJIKwJNo0Ue1B4HG72J1RHG35Xquk0pCeyRP4y06g%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "yNdgEKl0ffuyhiZ32wbvRKFmLUHCx1kIG9YWfelVojQ%3d",
      "team": "Meadowlark",
      "score": "3",
      "oppScore": "13",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "ffU6j0J6GXIDq%2bLLM1gKu5Ml98CwJLuPtbltd%2br9k6c%3d",
      "team": "Meadowlark",
      "score": "4",
      "oppScore": "13",
      "opp": "Dallas United Outlaws",
      "oppId": "JvlTMemVWiCex%2fC0N5CCgk2DrV419cGvk%2bO1z84fDZY%3d"
    },
    {
      "date": "June 19",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "MJZpzxk6iz%2fnDs9tkWWCt7%2fJSRAMvBd1ce2lQB%2bM01U%3d",
      "team": "Meadowlark",
      "score": "9",
      "oppScore": "14",
      "opp": "Central Arkansas Surge",
      "oppId": "a2C88dafFqaR9lnMU22LAsN40joRWyCBnRQyyoRh3jY%3d"
    },
    {
      "date": "June 19",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "NNmws8rts71EwSCgPCnMoNzuCFVBic53%2fxqRFju5NVg%3d",
      "team": "Meadowlark",
      "score": "10",
      "oppScore": "13",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "June 19",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "mXdhvIASuZSJeB0GspIFUHcqjXBD%2f8cFvozEjEi8Dgc%3d",
      "team": "Meadowlark",
      "score": "3",
      "oppScore": "11",
      "opp": "Rawhide",
      "oppId": "loSeq%2bvNEN8CCdx6pU5P0mfNTczm7%2fAa01t6KMfxol0%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "zbavBndnNen5Ezkw5mbg3F3%2b1Lg5y1YQx0pyqEesWLk%3d",
      "team": "Meadowlark",
      "score": "10",
      "oppScore": "15",
      "opp": "Nomads",
      "oppId": "clyQEvW53ITGsU%2bGFSkoVd77O856fO%2f%2fPStXV2bXfy4%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "MGDq%2b0ULvHaiBVFHy1co%2f0vXSten15fjiSaHGyQgiYc%3d",
      "team": "Meadowlark",
      "score": "2",
      "oppScore": "15",
      "opp": "Kansas City Smokestack",
      "oppId": "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "00o5rz8Yvr8X1u0FBqz8sbR8X5WlY0P1lFz0hSzu%2b8A%3d",
      "team": "Meadowlark",
      "score": "15",
      "oppScore": "13",
      "opp": "Overdrive",
      "oppId": "cfApDIqN4eoe%2fr26h%2b8fQj3s9XKGa9nJC8lT5uya7gM%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "AUI7euQAzXzst%2fMSe1bRaIFkxff%2fmPGXIzyZftCkSnU%3d",
      "team": "Meadowlark",
      "score": "8",
      "oppScore": "15",
      "opp": "Dreadnought",
      "oppId": "uzGm2kYsw4N4NvAkRAEeLbHAJE108B9cHHBgy%2bR99xE%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "dOEJgoREa4261YnIVnTBewSXipaDtHZbicJHCgb84J0%3d",
      "team": "Meadowlark",
      "score": "10",
      "oppScore": "11",
      "opp": "THE BODY",
      "oppId": "nyLl78Eg24EtJDKG0oHsU9e%2fg0hrOYqklm7h8m4R0Jo%3d"
    }
  ],
  "Bo%2bC%2fuzaALnn%2bTSSrEp4YXd6tJCmxm1yj1wwlvK%2fvJ4%3d": [
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "Wnx%2bgk19RGJTtka9nhi8Gs%2flyJw4w08fHdgpXkMC15U%3d",
      "team": "Shrimp Discs",
      "score": "8",
      "oppScore": "15",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "8di4c2i9ffUd%2fuAzdziMOCM39BA9i6y1sD9OdQzVAMY%3d",
      "team": "Shrimp Discs",
      "score": "11",
      "oppScore": "12",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "6JrtY%2biIfmtgMKnbg2wGWTq6qTt1YvOHtUZ0V7bRUso%3d",
      "team": "Shrimp Discs",
      "score": "4",
      "oppScore": "13",
      "opp": "Riverside",
      "oppId": "btdybQHkeLP7UjqWKhbXqcEMojopFgSilO0%2b3mjGSzM%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "%2bkhcqRrIzsvlsB0YGdPN5N4JvedRwSGNSE%2fe%2f2U04ho%3d",
      "team": "Shrimp Discs",
      "score": "9",
      "oppScore": "7",
      "opp": "Rawhide",
      "oppId": "loSeq%2bvNEN8CCdx6pU5P0mfNTczm7%2fAa01t6KMfxol0%3d"
    },
    {
      "date": "June 19",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "%2bpZk9A0qLXO1qKVyPpMxnXz1bc36qL8%2bt%2bbH0o%2bJjGM%3d",
      "team": "Shrimp Discs",
      "score": "L",
      "oppScore": "W",
      "opp": "Riverside",
      "oppId": "btdybQHkeLP7UjqWKhbXqcEMojopFgSilO0%2b3mjGSzM%3d"
    },
    {
      "date": "June 19",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "%2fM66kX%2bRH4CR5fp8HF7fcBasbTE4O0VWqZbZ2VbuQH0%3d",
      "team": "Shrimp Discs",
      "score": "L",
      "oppScore": "W",
      "opp": "Dallas United Outlaws",
      "oppId": "JvlTMemVWiCex%2fC0N5CCgk2DrV419cGvk%2bO1z84fDZY%3d"
    }
  ],
  "KQpJIKwJNo0Ue1B4HG72J1RHG35Xquk0pCeyRP4y06g%3d": [
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "DjdCTgUlnEgCL0DzNzu16dDW0bNFEsQHv6JiwbNSPcU%3d",
      "team": "Alamode",
      "score": "13",
      "oppScore": "4",
      "opp": "Meadowlark",
      "oppId": "5mFdu3Xc%2bl7OoxB5yBc9Q8%2bCImG%2b%2fOFvdvmpUhgLwdo%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "4AiZEgVa5hEKfR6HdFbKyLD%2fJykdW4aVYgHuP%2fJNes0%3d",
      "team": "Alamode",
      "score": "12",
      "oppScore": "10",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "bPTbWEBjXrrmhC44jFl8PqNNGidwhzA3aOJIFTwiGwQ%3d",
      "team": "Alamode",
      "score": "13",
      "oppScore": "14",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "qZkgm4yGVQkHE24Jmq8VcVQCwrjBF6iGkUlFMHylojw%3d",
      "team": "Alamode",
      "score": "5",
      "oppScore": "13",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "TldUQZKznWT2ojOGNR1Y5wVKYxJ33zsldVuFdW7oo9M%3d",
      "team": "Alamode",
      "score": "13",
      "oppScore": "5",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "June 19",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "C77PdMcxUuN%2fqaDFIxDxNf4TiUy3mKMO%2fEAzjL0KDbs%3d",
      "team": "Alamode",
      "score": "9",
      "oppScore": "11",
      "opp": "Flash Flood",
      "oppId": "JQrYQgb4YJNC2aMynOWjHiZmxJvsuqeLyz2Red9EabU%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "CXvModJUOOjKrR9exf1VO0XOietPmwURis4enfyhXyE%3d",
      "team": "Alamode",
      "score": "12",
      "oppScore": "13",
      "opp": "Foxtrot",
      "oppId": "0iROWvYUWTMjSSoepkFSHy9JjWFh%2bD04t8pOIQ3p9I4%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "jMR541FaXpS2iCPABoNX9nkipXTP3KYoVTpcr48X5Y8%3d",
      "team": "Alamode",
      "score": "13",
      "oppScore": "10",
      "opp": "Dallas United Outlaws",
      "oppId": "JvlTMemVWiCex%2fC0N5CCgk2DrV419cGvk%2bO1z84fDZY%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "IVLVfXgOAAKNG1Te51zXoiV2cWThYc0O5IWLqhAM0LA%3d",
      "team": "Alamode",
      "score": "13",
      "oppScore": "11",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "KJGZXJZp9xh8%2bCpspYb6EJ4pxQjU1VLwSiWI2w6PLBg%3d",
      "team": "Alamode",
      "score": "13",
      "oppScore": "1",
      "opp": "Deaf Fruit",
      "oppId": "B8w1PwMJi87%2fAp%2b6M8SYsYlCa%2fBJcbAiBoKLkadwT1Y%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "IXfOfIq8aPCzDf13472Xhv9eXhrCMphpywt7tvLp97g%3d",
      "team": "Alamode",
      "score": "10",
      "oppScore": "11",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "ip2yr%2fybvKNfG4QRG0lRmGGcdTAynb7QgDwBZmo42%2f0%3d",
      "team": "Alamode",
      "score": "10",
      "oppScore": "9",
      "opp": "Dallas United Outlaws",
      "oppId": "JvlTMemVWiCex%2fC0N5CCgk2DrV419cGvk%2bO1z84fDZY%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "Bnn3T9Asda18Nvy7Xliy19f0FJKwR6OhlHbPpRFBTJs%3d",
      "team": "Alamode",
      "score": "13",
      "oppScore": "11",
      "opp": "Foxtrot",
      "oppId": "0iROWvYUWTMjSSoepkFSHy9JjWFh%2bD04t8pOIQ3p9I4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "34EfAEucipIfQymDv2hI9A9AV5l%2fjxbPQoeL84bIO44%3d",
      "team": "Alamode",
      "score": "11",
      "oppScore": "13",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "r0lwhNAHIf%2bozaf%2bwpsh%2f9qleikdh%2f5QQ64MRcLaI%2bc%3d",
      "team": "Alamode",
      "score": "10",
      "oppScore": "11",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "WlOpYCarRuwaLolP0XZEe56yAJNS4wyOgA18Qi9niZc%3d",
      "team": "Alamode",
      "score": "8",
      "oppScore": "13",
      "opp": "Harvey Cats",
      "oppId": "cbjkcOMrhSyGKLqnK1uuJLy8ENbcEcYemex94WHxvxU%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "SlR%2fDNJrn%2fcGB6jZOmZ2mKK7tY7WI9NNVjdQ62IGL3M%3d",
      "team": "Alamode",
      "score": "12",
      "oppScore": "11",
      "opp": "Riverside",
      "oppId": "btdybQHkeLP7UjqWKhbXqcEMojopFgSilO0%2b3mjGSzM%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "G6Bk2%2fC9dpJzMEcl9t1lRo25n0fxhEYajFk%2fIphsDbw%3d",
      "team": "Alamode",
      "score": "13",
      "oppScore": "3",
      "opp": "Riverside Messengers [B]",
      "oppId": "%2fk2Al%2fURWo%2fM8QndFYA1FAuFkpV3PwlxFADSQxiJBu4%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "umOCGL8EvdW5RcrfxpAXGFWyCrD1KEjMx%2f2F2XTDvi4%3d",
      "team": "Alamode",
      "score": "13",
      "oppScore": "4",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "SkT0HERs%2fOzl0qAo5FRHdpTeH8jGOOIvXwjNQ13suTA%3d",
      "team": "Alamode",
      "score": "12",
      "oppScore": "8",
      "opp": "Sonoran Dog",
      "oppId": "WAPjwPL2R8YMCWgolwmIL1cqRoFxsqyk2gQeZ9iDOMA%3d"
    }
  ],
  "JvlTMemVWiCex%2fC0N5CCgk2DrV419cGvk%2bO1z84fDZY%3d": [
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "WG6QkwvENTKCEWsaJ5CzXJ%2bWpw%2f4IA98tvV%2f3zcpBX8%3d",
      "team": "Dallas United Outlaws",
      "score": "13",
      "oppScore": "6",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "r49DEBRcV%2bh1MMJQUc28TvYs5poODg6Xdng9sNOZu6A%3d",
      "team": "Dallas United Outlaws",
      "score": "6",
      "oppScore": "15",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "wZ1HNzCFLq0WCTepzpFNLEg6%2bytWv%2fi3NC02cAUEPpc%3d",
      "team": "Dallas United Outlaws",
      "score": "10",
      "oppScore": "11",
      "opp": "Flash Flood",
      "oppId": "JQrYQgb4YJNC2aMynOWjHiZmxJvsuqeLyz2Red9EabU%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "ffU6j0J6GXIDq%2bLLM1gKu5Ml98CwJLuPtbltd%2br9k6c%3d",
      "team": "Dallas United Outlaws",
      "score": "13",
      "oppScore": "4",
      "opp": "Meadowlark",
      "oppId": "5mFdu3Xc%2bl7OoxB5yBc9Q8%2bCImG%2b%2fOFvdvmpUhgLwdo%3d"
    },
    {
      "date": "June 19",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "PvfQyhePlr9gu0EekVPHepb3aMu1rIPvViEGd3jyvvg%3d",
      "team": "Dallas United Outlaws",
      "score": "5",
      "oppScore": "12",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "June 19",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "%2fM66kX%2bRH4CR5fp8HF7fcBasbTE4O0VWqZbZ2VbuQH0%3d",
      "team": "Dallas United Outlaws",
      "score": "W",
      "oppScore": "L",
      "opp": "Shrimp Discs",
      "oppId": "Bo%2bC%2fuzaALnn%2bTSSrEp4YXd6tJCmxm1yj1wwlvK%2fvJ4%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "EZz77DbJ6RkIZrIOBFt%2b7tV0ak9SW78qkpqNqGnKS58%3d",
      "team": "Dallas United Outlaws",
      "score": "13",
      "oppScore": "3",
      "opp": "Deaf Fruit",
      "oppId": "B8w1PwMJi87%2fAp%2b6M8SYsYlCa%2fBJcbAiBoKLkadwT1Y%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "jMR541FaXpS2iCPABoNX9nkipXTP3KYoVTpcr48X5Y8%3d",
      "team": "Dallas United Outlaws",
      "score": "10",
      "oppScore": "13",
      "opp": "Alamode",
      "oppId": "KQpJIKwJNo0Ue1B4HG72J1RHG35Xquk0pCeyRP4y06g%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "LUR9EGugv%2bX1M8YUNMGgYcFlOKVnU4CA4GlPZh%2f1LJ8%3d",
      "team": "Dallas United Outlaws",
      "score": "8",
      "oppScore": "13",
      "opp": "Foxtrot",
      "oppId": "0iROWvYUWTMjSSoepkFSHy9JjWFh%2bD04t8pOIQ3p9I4%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "51w84vYjVqSxBcGMSW%2b2GsSQhaNDYGrIVLwffyqF0yw%3d",
      "team": "Dallas United Outlaws",
      "score": "6",
      "oppScore": "13",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "mVyXKC7q%2fLI5SFzdS6eSBz2PaRWGhQZHs4ZUOURs1z4%3d",
      "team": "Dallas United Outlaws",
      "score": "7",
      "oppScore": "13",
      "opp": "Riverside",
      "oppId": "btdybQHkeLP7UjqWKhbXqcEMojopFgSilO0%2b3mjGSzM%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "ip2yr%2fybvKNfG4QRG0lRmGGcdTAynb7QgDwBZmo42%2f0%3d",
      "team": "Dallas United Outlaws",
      "score": "9",
      "oppScore": "10",
      "opp": "Alamode",
      "oppId": "KQpJIKwJNo0Ue1B4HG72J1RHG35Xquk0pCeyRP4y06g%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "bM1a4ch%2bzDShePCebjOr6zqbW%2fFneFrggwFjEuy%2b9Rw%3d",
      "team": "Dallas United Outlaws",
      "score": "8",
      "oppScore": "11",
      "opp": "Firefly TX",
      "oppId": "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d"
    }
  ],
  "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d": [
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "WG6QkwvENTKCEWsaJ5CzXJ%2bWpw%2f4IA98tvV%2f3zcpBX8%3d",
      "team": "San Antonio Warhawks",
      "score": "6",
      "oppScore": "13",
      "opp": "Dallas United Outlaws",
      "oppId": "JvlTMemVWiCex%2fC0N5CCgk2DrV419cGvk%2bO1z84fDZY%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "uwTvrXAIf4aVe%2bSip6R7sIYSqJwt5pTLkTBuBpZwY%2bA%3d",
      "team": "San Antonio Warhawks",
      "score": "5",
      "oppScore": "15",
      "opp": "Rawhide",
      "oppId": "loSeq%2bvNEN8CCdx6pU5P0mfNTczm7%2fAa01t6KMfxol0%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "nIiv1CpNy1gK9p9ujYccH4j9%2fa43qnlup4pVK3pSCJ0%3d",
      "team": "San Antonio Warhawks",
      "score": "3",
      "oppScore": "13",
      "opp": "Flash Flood",
      "oppId": "JQrYQgb4YJNC2aMynOWjHiZmxJvsuqeLyz2Red9EabU%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "TldUQZKznWT2ojOGNR1Y5wVKYxJ33zsldVuFdW7oo9M%3d",
      "team": "San Antonio Warhawks",
      "score": "5",
      "oppScore": "13",
      "opp": "Alamode",
      "oppId": "KQpJIKwJNo0Ue1B4HG72J1RHG35Xquk0pCeyRP4y06g%3d"
    },
    {
      "date": "June 19",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "NNmws8rts71EwSCgPCnMoNzuCFVBic53%2fxqRFju5NVg%3d",
      "team": "San Antonio Warhawks",
      "score": "13",
      "oppScore": "10",
      "opp": "Meadowlark",
      "oppId": "5mFdu3Xc%2bl7OoxB5yBc9Q8%2bCImG%2b%2fOFvdvmpUhgLwdo%3d"
    },
    {
      "date": "June 19",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "rfxv2SBYC0JELj9pvTYTvFgqwgsB8WZDB9yUchfOw9k%3d",
      "team": "San Antonio Warhawks",
      "score": "9",
      "oppScore": "14",
      "opp": "Central Arkansas Surge",
      "oppId": "a2C88dafFqaR9lnMU22LAsN40joRWyCBnRQyyoRh3jY%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "91ZdNXxRhpELNcZ7TNV%2fz0%2fsUTOyhjImvUtx68iVtJw%3d",
      "team": "San Antonio Warhawks",
      "score": "5",
      "oppScore": "9",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "JsFye8KTOXstteg74Bve72NkkcqFBEv2jJ0iqQITTfs%3d",
      "team": "San Antonio Warhawks",
      "score": "9",
      "oppScore": "3",
      "opp": "Adventure Time",
      "oppId": "qvXwPo5QehiheOQO5VGwHcsVyYUPf2WOcw0w%2fKf0KR0%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "asZAInBKcPjed%2fzyRIBA9Blv3%2bnYPwEZMNct7GVvOZ8%3d",
      "team": "San Antonio Warhawks",
      "score": "6",
      "oppScore": "8",
      "opp": "Foxtrot",
      "oppId": "0iROWvYUWTMjSSoepkFSHy9JjWFh%2bD04t8pOIQ3p9I4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "g2ewdAmtpUPKKHJXaLu6%2bo23gg7KDRa%2b9AHyBGAt45w%3d",
      "team": "San Antonio Warhawks",
      "score": "9",
      "oppScore": "8",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "g21bA7AYyMojWHYLo%2bm3jZv%2fs4786NBFQxJSaxb1QH8%3d",
      "team": "San Antonio Warhawks",
      "score": "5",
      "oppScore": "11",
      "opp": "Clutch",
      "oppId": "JDc%2bBsCjGUX7gdebRqmdPvq1u2rFlZ1giM6BgTaL5Lk%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "v0ovGJvLQ1V%2fMS%2bISra%2bOfvLBV%2bYr3e0muHC6axR%2bsg%3d",
      "team": "San Antonio Warhawks",
      "score": "9",
      "oppScore": "7",
      "opp": "Firefly TX",
      "oppId": "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "1O3RCcjOKkbT%2b7AQt0Qnwgo%2bh4Ky87Z6TMwtbzWvTbU%3d",
      "team": "San Antonio Warhawks",
      "score": "3",
      "oppScore": "11",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "JB%2bftFaJFaD6wtwHXGpDkWumY%2bOeDGLdlfu5X9eWzrQ%3d",
      "team": "San Antonio Warhawks",
      "score": "5",
      "oppScore": "11",
      "opp": "Harvey Cats",
      "oppId": "cbjkcOMrhSyGKLqnK1uuJLy8ENbcEcYemex94WHxvxU%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "42x3%2bJKHcEXcKzh1w39Wt%2f4GXfTztQ%2bk5q7oCfTaQhs%3d",
      "team": "San Antonio Warhawks",
      "score": "3",
      "oppScore": "13",
      "opp": "Riverside",
      "oppId": "btdybQHkeLP7UjqWKhbXqcEMojopFgSilO0%2b3mjGSzM%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "aqaczjWFp8rIPmvOZ087oigHarE93pBi44agZV3CEtE%3d",
      "team": "San Antonio Warhawks",
      "score": "10",
      "oppScore": "7",
      "opp": "Adventure Time",
      "oppId": "qvXwPo5QehiheOQO5VGwHcsVyYUPf2WOcw0w%2fKf0KR0%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "ZZX%2fFOPIqsmrslLSNi0fapz13Fdz%2bf9hCxQDTqHRGkA%3d",
      "team": "San Antonio Warhawks",
      "score": "6",
      "oppScore": "13",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "BH72toGwFznIGFbydevCth3PIdpnJEJdXv%2bsPhbdf6o%3d",
      "team": "San Antonio Warhawks",
      "score": "4",
      "oppScore": "13",
      "opp": "Firefly TX",
      "oppId": "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "ohGduYztYE83ac4%2bS0s%2f6G6bCYIrdg%2fQkt%2fYrDwzJQs%3d",
      "team": "San Antonio Warhawks",
      "score": "2",
      "oppScore": "13",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "K8TC4ysKTGhbSAKH9jDJfBgr2QPb8AJrAhw0hLc6VZs%3d",
      "team": "San Antonio Warhawks",
      "score": "13",
      "oppScore": "6",
      "opp": "Deaf Fruit",
      "oppId": "B8w1PwMJi87%2fAp%2b6M8SYsYlCa%2fBJcbAiBoKLkadwT1Y%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "me%2bhXU%2fpPY7ZGsGmQ%2bNTpX%2fwR2lpVFiL%2f%2bvS7G3Ed6s%3d",
      "team": "San Antonio Warhawks",
      "score": "12",
      "oppScore": "8",
      "opp": "Adventure Time",
      "oppId": "qvXwPo5QehiheOQO5VGwHcsVyYUPf2WOcw0w%2fKf0KR0%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "aymRUuOeWWi%2bTGz94SE5CF5fP0ZKdRO9fo1Z6BxulpI%3d",
      "team": "San Antonio Warhawks",
      "score": "2",
      "oppScore": "13",
      "opp": "Foxtrot",
      "oppId": "0iROWvYUWTMjSSoepkFSHy9JjWFh%2bD04t8pOIQ3p9I4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "NK2R%2fDLAjlh9KoynukH35q%2f5OOOEKKNY4buyJfo8%2fD4%3d",
      "team": "San Antonio Warhawks",
      "score": "6",
      "oppScore": "12",
      "opp": "Firefly TX",
      "oppId": "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "tjGI0EUsmGqdfJMfvXN3SWuUme8JIeAM1vIqz3Ci2ek%3d",
      "team": "San Antonio Warhawks",
      "score": "3",
      "oppScore": "13",
      "opp": "Flash Flood",
      "oppId": "JQrYQgb4YJNC2aMynOWjHiZmxJvsuqeLyz2Red9EabU%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "Tm%2frVdAgUXxLLGEbfUtX5qivc7zGNL49yYhxxMpIDhk%3d",
      "team": "San Antonio Warhawks",
      "score": "3",
      "oppScore": "13",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "qyofxuLNpmd6t8eFLKnteiDcueRFqEI8jUtK17oGYGY%3d",
      "team": "San Antonio Warhawks",
      "score": "6",
      "oppScore": "13",
      "opp": "Sonoran Dog",
      "oppId": "WAPjwPL2R8YMCWgolwmIL1cqRoFxsqyk2gQeZ9iDOMA%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "umOCGL8EvdW5RcrfxpAXGFWyCrD1KEjMx%2f2F2XTDvi4%3d",
      "team": "San Antonio Warhawks",
      "score": "4",
      "oppScore": "13",
      "opp": "Alamode",
      "oppId": "KQpJIKwJNo0Ue1B4HG72J1RHG35Xquk0pCeyRP4y06g%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "6L3MojiBodjbNNxJ%2bsfa08Uybav32kWIZcsShd8JAv0%3d",
      "team": "San Antonio Warhawks",
      "score": "10",
      "oppScore": "5",
      "opp": "Riverside Messengers [B]",
      "oppId": "%2fk2Al%2fURWo%2fM8QndFYA1FAuFkpV3PwlxFADSQxiJBu4%3d"
    }
  ],
  "loSeq%2bvNEN8CCdx6pU5P0mfNTczm7%2fAa01t6KMfxol0%3d": [
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "uwTvrXAIf4aVe%2bSip6R7sIYSqJwt5pTLkTBuBpZwY%2bA%3d",
      "team": "Rawhide",
      "score": "15",
      "oppScore": "5",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "U4jejffR17giBfccm8Mx6NQKRAVcrqIQC1dTAHVz3Gg%3d",
      "team": "Rawhide",
      "score": "13",
      "oppScore": "8",
      "opp": "Central Arkansas Surge",
      "oppId": "a2C88dafFqaR9lnMU22LAsN40joRWyCBnRQyyoRh3jY%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "UbgJ%2fLUKE5GP21QBCbsZv%2fuXBjCr5qeNq6Bag9p0Hy4%3d",
      "team": "Rawhide",
      "score": "6",
      "oppScore": "13",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "June 18",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "%2bkhcqRrIzsvlsB0YGdPN5N4JvedRwSGNSE%2fe%2f2U04ho%3d",
      "team": "Rawhide",
      "score": "7",
      "oppScore": "9",
      "opp": "Shrimp Discs",
      "oppId": "Bo%2bC%2fuzaALnn%2bTSSrEp4YXd6tJCmxm1yj1wwlvK%2fvJ4%3d"
    },
    {
      "date": "June 19",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "IqMDmipg6GRUIEbOyfeT%2bNxjtDyajbKsqwWF%2fFjK%2bNg%3d",
      "team": "Rawhide",
      "score": "14",
      "oppScore": "11",
      "opp": "Central Arkansas Surge",
      "oppId": "a2C88dafFqaR9lnMU22LAsN40joRWyCBnRQyyoRh3jY%3d"
    },
    {
      "date": "June 19",
      "tournament": "Texas 2 Finger Tournament 2022",
      "tournamentId": "Texas-2-Finger-Tournament-2022",
      "gameId": "mXdhvIASuZSJeB0GspIFUHcqjXBD%2f8cFvozEjEi8Dgc%3d",
      "team": "Rawhide",
      "score": "11",
      "oppScore": "3",
      "opp": "Meadowlark",
      "oppId": "5mFdu3Xc%2bl7OoxB5yBc9Q8%2bCImG%2b%2fOFvdvmpUhgLwdo%3d"
    },
    {
      "date": "August 20",
      "tournament": "OK Corral 2022",
      "tournamentId": "OK-Corral-2022",
      "gameId": "Ofy1IEhxtlI1OiEZ%2f9gMugnTpc9i4FtK6bgIUq8bFHw%3d",
      "team": "Rawhide",
      "score": "13",
      "oppScore": "6",
      "opp": "Central Arkansas Surge",
      "oppId": "a2C88dafFqaR9lnMU22LAsN40joRWyCBnRQyyoRh3jY%3d"
    },
    {
      "date": "August 20",
      "tournament": "OK Corral 2022",
      "tournamentId": "OK-Corral-2022",
      "gameId": "P%2b8nYlvvSSft%2bjmEJz3GsC6Rrtpkv7kQE83DFNaUBPw%3d",
      "team": "Rawhide",
      "score": "13",
      "oppScore": "9",
      "opp": "Dreadnought",
      "oppId": "uzGm2kYsw4N4NvAkRAEeLbHAJE108B9cHHBgy%2bR99xE%3d"
    },
    {
      "date": "August 28",
      "tournament": "OK Corral 2022",
      "tournamentId": "OK-Corral-2022",
      "gameId": "Q6%2b3Du7h2bgWCk%2b%2bqCBl5pCqcgWBwdp48UitF7BIitI%3d",
      "team": "Rawhide",
      "score": "15",
      "oppScore": "8",
      "opp": "Central Arkansas Surge",
      "oppId": "a2C88dafFqaR9lnMU22LAsN40joRWyCBnRQyyoRh3jY%3d"
    }
  ],
  "jzVhyLybRfcABTsshmdvgsxYDSASMTHMGNde%2bkQTb4Y%3d": [
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "Ht93R8BnLeWaOaodieBWAI0GIaNn7wstnzPjDYA6%2bsM%3d",
      "team": "Chimney",
      "score": "14",
      "oppScore": "15",
      "opp": "Flying Dutchmen",
      "oppId": "7amY9LIoyY8hOWkzj5Nqp1H8c7bkR6ySQBzeCHsiQFg%3d"
    },
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "60G15%2b8PylZS255wz%2ba0X5XRHoqOa%2f4xdzm73ha7iak%3d",
      "team": "Chimney",
      "score": "9",
      "oppScore": "10",
      "opp": "Hazard",
      "oppId": "RBbGte9ro%2fCZWrmOmcHNTdHB41zGQFb64ubBkZjcTEI%3d"
    },
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "A5m%2bVGQGMXydZhdTb61Ivtm3dxIg%2bRqdSpXYOUYEpfQ%3d",
      "team": "Chimney",
      "score": "13",
      "oppScore": "14",
      "opp": "Enigma",
      "oppId": "vGMVLiic34oSyMk%2bETpVqMkuexU7MBkROOjGtFdKoVM%3d"
    },
    {
      "date": "June 26",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "aB0rCLZlCYIthmkOVzW1zaz9llcDXlreNVC0B5xgoyQ%3d",
      "team": "Chimney",
      "score": "11",
      "oppScore": "12",
      "opp": "Beacon",
      "oppId": "4n5jY6eFSRuptn3xrctxCFjAy7g9dqmJM2uPNhY3qbk%3d"
    },
    {
      "date": "June 26",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "8gPFdyORWjavM%2fFeco7Y43gJptnwEa1N%2bQM0DPYAlb8%3d",
      "team": "Chimney",
      "score": "13",
      "oppScore": "8",
      "opp": "AGE Against the Machine GGM",
      "oppId": "PGFvuARzsEPpLAu8aRRGaJ6CiGl%2fo97vxgqDeaUh3yk%3d"
    },
    {
      "date": "June 26",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "hxBUm2MpMQeQS44SmA30YIL12sZtb1%2fYhx2nazpPKcQ%3d",
      "team": "Chimney",
      "score": "W",
      "oppScore": "F",
      "opp": "Flying Pig",
      "oppId": "rUyyXUmvJe1zUxoCIq%2bJ8srRSS59bSwzNSdxsoWUbdY%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "%2fYLEXKLdM8dWpj2H4S5bjmRNBwVfTheNtDvYFREiuMc%3d",
      "team": "Chimney",
      "score": "11",
      "oppScore": "12",
      "opp": "Magma Bears",
      "oppId": "tOcZDMKk80VDM0VC5NJXyzOSixma%2fCVGplidpqww6q8%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "X%2fnDV4TWs9JM4NMbp4YucpAJllyPMmJsfljo%2fbjTIaA%3d",
      "team": "Chimney",
      "score": "6",
      "oppScore": "13",
      "opp": "Redwolves",
      "oppId": "DoL%2frShNUJHM7OLFhcsMzWlsS0aohpfm30HvDbpVZi4%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "5SmBNLSKG4y6ntuHR0CABjB%2barSfnN3UYuN4kVolnUQ%3d",
      "team": "Chimney",
      "score": "8",
      "oppScore": "13",
      "opp": "Winc City Fog of War",
      "oppId": "6gsiUt3INMB4Td2RTxJkksYpRIJsACnjptCfHUZeg2o%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "n8t0neSU6LNfxMG%2bDRZowEzPuTwJzI7VrYFRDg%2bDC8w%3d",
      "team": "Chimney",
      "score": "13",
      "oppScore": "8",
      "opp": "Spring Break '93",
      "oppId": "hRUlbCXzaOf83wDSPKpV36LeX3FmlXgFaE5wdndlB3Q%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "3vsG1DaNnOlXPPf%2brJ9C0UH72L45YmyhnODVXAqNOvg%3d",
      "team": "Chimney",
      "score": "11",
      "oppScore": "7",
      "opp": "Adelphos",
      "oppId": "IINBHzSyAD1qveaivUllPpQBPkMwjzVWcFo4s84DFtc%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "yiE4Yn5%2bu1pTl6Iu1k3Z194SkmZeexfYP2Bnu9dSZqI%3d",
      "team": "Chimney",
      "score": "7",
      "oppScore": "5",
      "opp": "Hazard",
      "oppId": "RBbGte9ro%2fCZWrmOmcHNTdHB41zGQFb64ubBkZjcTEI%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "I0H2S4c1PBFV1rBo%2bht5QPDMTRYKrGUSTmwQ3ZWi5tI%3d",
      "team": "Chimney",
      "score": "12",
      "oppScore": "13",
      "opp": "Timber",
      "oppId": "KEdthZtu%2ffyLTQQr6Y655G91Wz0j0VrQj7Ap2G5YBOM%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "DAZL5TmBsYmWKg1r3Kn6tsh2B7ppaR8yblu7473lB50%3d",
      "team": "Chimney",
      "score": "9",
      "oppScore": "13",
      "opp": "HouSE",
      "oppId": "W3JpjYTNBVirvq%2bLhhgD1xswtN%2bDHXo2Y5uhwfm%2f450%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "YUuHwUPYPb230GsBoKA4f9cHnUnd4lerKdGn%2be5uCYM%3d",
      "team": "Chimney",
      "score": "12",
      "oppScore": "13",
      "opp": "Nain Rouge",
      "oppId": "VlCPZLsfBQ0DobBH%2fc22bLMbT2Ge3MbFrq%2fRZ8Jw0gM%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "j53rCFzdTfC5xKNk3p02jxML%2bz31fRNvcQ8yktzfW%2b0%3d",
      "team": "Chimney",
      "score": "13",
      "oppScore": "10",
      "opp": "Loaded Panda",
      "oppId": "8S%2f2XcUwQbLf0KM7yOmv3HEYXSrnLhAdwnHQvhMjxCY%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "VnE9YBPOVmEGxplII8sWWDeyNAujC2oXl6mVsQLCXCE%3d",
      "team": "Chimney",
      "score": "9",
      "oppScore": "13",
      "opp": "Beacon",
      "oppId": "4n5jY6eFSRuptn3xrctxCFjAy7g9dqmJM2uPNhY3qbk%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "ilTWWn3hftQrwAy%2bG9XTRdG1uHa6L3EcDVucwRkZmY0%3d",
      "team": "Chimney",
      "score": "13",
      "oppScore": "9",
      "opp": "Timber",
      "oppId": "KEdthZtu%2ffyLTQQr6Y655G91Wz0j0VrQj7Ap2G5YBOM%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "jR6iyfljxmKlbjn7McgXXrmfqqcL6uXzkXGMgToTk9M%3d",
      "team": "Chimney",
      "score": "5",
      "oppScore": "13",
      "opp": "Enigma",
      "oppId": "vGMVLiic34oSyMk%2bETpVqMkuexU7MBkROOjGtFdKoVM%3d"
    }
  ],
  "RBbGte9ro%2fCZWrmOmcHNTdHB41zGQFb64ubBkZjcTEI%3d": [
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "W7BIixfcRI4o3W%2fdL7N07gQC6ylG6ospuXj77fqJo4g%3d",
      "team": "Hazard",
      "score": "13",
      "oppScore": "7",
      "opp": "Enigma",
      "oppId": "vGMVLiic34oSyMk%2bETpVqMkuexU7MBkROOjGtFdKoVM%3d"
    },
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "60G15%2b8PylZS255wz%2ba0X5XRHoqOa%2f4xdzm73ha7iak%3d",
      "team": "Hazard",
      "score": "10",
      "oppScore": "9",
      "opp": "Chimney",
      "oppId": "jzVhyLybRfcABTsshmdvgsxYDSASMTHMGNde%2bkQTb4Y%3d"
    },
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "dZ7ZMIYGqjHuj5B2v6hMSezCQgNieww13WzhbeOHXXs%3d",
      "team": "Hazard",
      "score": "12",
      "oppScore": "9",
      "opp": "Flying Dutchmen",
      "oppId": "7amY9LIoyY8hOWkzj5Nqp1H8c7bkR6ySQBzeCHsiQFg%3d"
    },
    {
      "date": "June 26",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "Fed3lBmV%2f3fW7%2b1A%2f2%2f0KhuYFxCa6jXktSMVAuFT%2bGk%3d",
      "team": "Hazard",
      "score": "9",
      "oppScore": "15",
      "opp": "Pittsburgh Stealers",
      "oppId": "icSGwphEBV6tcYJLRwPxEC22s0bt6rct5HG5ubjyetw%3d"
    },
    {
      "date": "June 26",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "Na%2fSz1NIHOvuuPHIax%2bmWIHlpRb7Qm3ikEBJPMeoK2I%3d",
      "team": "Hazard",
      "score": "10",
      "oppScore": "13",
      "opp": "Flying Dutchmen",
      "oppId": "7amY9LIoyY8hOWkzj5Nqp1H8c7bkR6ySQBzeCHsiQFg%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "zRNqb8jT8BQo3cSQt9V5Qk%2fIRocJD7iwG1Zcx3nDtVs%3d",
      "team": "Hazard",
      "score": "7",
      "oppScore": "13",
      "opp": "Brickhouse",
      "oppId": "b5ARnVg7OngG1a0%2fJuMZTHluwgNFe%2bN5esQIIKsEOUY%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "xq%2bY02AWO6w3%2bn9AeKSPtvSX5l4Id9Hlf1uxPktXdns%3d",
      "team": "Hazard",
      "score": "6",
      "oppScore": "13",
      "opp": "Red Tide",
      "oppId": "s6HGV42d1dEo0YRx%2fIycGA2qxvKRYzzfEITLPlDYO1I%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "t6CVLI9ollRIQqmhSU2pUwHHMMXrknKM7rXwVnbc4n0%3d",
      "team": "Hazard",
      "score": "12",
      "oppScore": "7",
      "opp": "Richmond Floodwall",
      "oppId": "fa1yMF%2fsPXZM%2b%2f4My9LlUixC%2bMFxnwM7gPH410zO6EA%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "2TIzZ%2fj1o3mRwszeF0QtCFQUainxc%2fzba6bUAyJWVR0%3d",
      "team": "Hazard",
      "score": "11",
      "oppScore": "9",
      "opp": "MOB Ultimate",
      "oppId": "otuSqjNVhCJZE0ojAe0x6mw6GKk1Ux8ciM5ADgrPUEI%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "7cgLo%2bpwscy9THMqqyfPTPKyBoVmsCdUdvdxy3I7o3Y%3d",
      "team": "Hazard",
      "score": "8",
      "oppScore": "7",
      "opp": "JAWN",
      "oppId": "ZmiNobvozutOTlvBTZEmqu8bW1a0Lvp6PqGmIiNlkGU%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "yiE4Yn5%2bu1pTl6Iu1k3Z194SkmZeexfYP2Bnu9dSZqI%3d",
      "team": "Hazard",
      "score": "5",
      "oppScore": "7",
      "opp": "Chimney",
      "oppId": "jzVhyLybRfcABTsshmdvgsxYDSASMTHMGNde%2bkQTb4Y%3d"
    }
  ],
  "rUyyXUmvJe1zUxoCIq%2bJ8srRSS59bSwzNSdxsoWUbdY%3d": [
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "bowOUfHH6xRldp7DIaT8ecgFC%2fQ2%2bXnO9b9muYQIU7k%3d",
      "team": "Flying Pig",
      "score": "5",
      "oppScore": "13",
      "opp": "Beacon",
      "oppId": "4n5jY6eFSRuptn3xrctxCFjAy7g9dqmJM2uPNhY3qbk%3d"
    },
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "lRrP1aFaVvI8cx1HkIL%2bTit90cdH3%2f7YGSvlHtFchhQ%3d",
      "team": "Flying Pig",
      "score": "12",
      "oppScore": "11",
      "opp": "AGE Against the Machine GGM",
      "oppId": "PGFvuARzsEPpLAu8aRRGaJ6CiGl%2fo97vxgqDeaUh3yk%3d"
    },
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "xccg%2bejriAzprcr6FNZnYV2mLuE23BLWJ3JKDEVCMFE%3d",
      "team": "Flying Pig",
      "score": "5",
      "oppScore": "13",
      "opp": "Black Lung",
      "oppId": "olaZlUwWblgF%2b%2b2nRJgbWJ2tj6QzPm5aIO7kfbpnA58%3d"
    },
    {
      "date": "June 26",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "ZGmGUKgfcdVLH1qQomODIgnjaL8sCDZxdT3TxOCLP0w%3d",
      "team": "Flying Pig",
      "score": "7",
      "oppScore": "13",
      "opp": "Pittsburgh Stealers",
      "oppId": "icSGwphEBV6tcYJLRwPxEC22s0bt6rct5HG5ubjyetw%3d"
    },
    {
      "date": "June 26",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "AIloD8Gy4fgpc7usUidlGAg2ivUXwB14LY%2bCoGdDyfI%3d",
      "team": "Flying Pig",
      "score": "5",
      "oppScore": "13",
      "opp": "Enigma",
      "oppId": "vGMVLiic34oSyMk%2bETpVqMkuexU7MBkROOjGtFdKoVM%3d"
    },
    {
      "date": "June 26",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "hxBUm2MpMQeQS44SmA30YIL12sZtb1%2fYhx2nazpPKcQ%3d",
      "team": "Flying Pig",
      "score": "F",
      "oppScore": "W",
      "opp": "Chimney",
      "oppId": "jzVhyLybRfcABTsshmdvgsxYDSASMTHMGNde%2bkQTb4Y%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "FoJ7acWZfPdlxNmg0C%2bKoe58dao4J1nYC3wmq8%2bjsdM%3d",
      "team": "Flying Pig",
      "score": "15",
      "oppScore": "4",
      "opp": "Birds Of Play",
      "oppId": "C5gjZFjOICEF1K9fBVXojD5NxWjD32cRDWmzxGkasm0%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "ru222Wpbjfr50xqvytnJhKrIEixli0zRYBTwcce2ulo%3d",
      "team": "Flying Pig",
      "score": "15",
      "oppScore": "2",
      "opp": "Rochester Chill U20B",
      "oppId": "pPK5PXnfX0q%2fGE3tHrGqH5OncspwOgKXCTMcISCjbXE%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "hgWj7W0N4I31%2fLxZj7KH%2fJE12KVEadH%2bkD1JNqMJuhQ%3d",
      "team": "Flying Pig",
      "score": "11",
      "oppScore": "13",
      "opp": "Villains",
      "oppId": "Ym1ak6XomGej8LcWE974vySOBZfTF88e4wX%2bD3N6JX0%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "yMAuDLnEKMnJTELsDBnslqxGWRipuhnjn80n%2f5JXc50%3d",
      "team": "Flying Pig",
      "score": "6",
      "oppScore": "13",
      "opp": "Charlotte Flight",
      "oppId": "P4imWyKxeDrrrqjQ0lCOZq28YNb5YA%2fJ4ekFVl4wwG4%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "eOOtjYf7OH%2fhg04MYv07iSzqWDSzoGEkRUkcIN6MNjc%3d",
      "team": "Flying Pig",
      "score": "6",
      "oppScore": "15",
      "opp": "Red Hots - u20",
      "oppId": "Ggh5FAV%2b2R4byKaxZbHQGXO72Bh%2fypyZmUX%2fWkOdMsg%3d"
    },
    {
      "date": "August 08",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "WztpAlHAPaDoIcFUyg7D4BM%2bGQtPxDzqxD2251lt9R0%3d",
      "team": "Flying Pig",
      "score": "11",
      "oppScore": "12",
      "opp": "Moon Pigs",
      "oppId": "u%2b%2fl72szLMaP0o%2f92qBd%2foiVIIHcxZYHekJ7R26OcY0%3d"
    },
    {
      "date": "August 08",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "sBpt3%2f4laMRKsMFrMZHmyq28%2fq3bfsUKVwppOakBgj4%3d",
      "team": "Flying Pig",
      "score": "13",
      "oppScore": "10",
      "opp": "Birds Of Play",
      "oppId": "C5gjZFjOICEF1K9fBVXojD5NxWjD32cRDWmzxGkasm0%3d"
    }
  ],
  "PGFvuARzsEPpLAu8aRRGaJ6CiGl%2fo97vxgqDeaUh3yk%3d": [
    {
      "date": "June 04",
      "tournament": "2022 USA Ultimate Great Lakes Great Grand Masters Men's Regionals",
      "tournamentId": "2022-USA-Ultimate-Great-Lakes-Great-Grand-Masters-Mens-Regionals-",
      "gameId": "cOSKJeEyWYoaIgSnuhbcAlJuEkaDKH2WlNbtAMM%2fYmw%3d",
      "team": "AGE Against the Machine GGM",
      "score": "6",
      "oppScore": "15",
      "opp": "CHRONIC",
      "oppId": "7AUxtSyPxPvJIURsJapxxUFAmFD7VUSz4%2faUo78ySZA%3d"
    },
    {
      "date": "June 04",
      "tournament": "2022 USA Ultimate Great Lakes Great Grand Masters Men's Regionals",
      "tournamentId": "2022-USA-Ultimate-Great-Lakes-Great-Grand-Masters-Mens-Regionals-",
      "gameId": "6n5nJD8qkTIUKKfGBvgZrk4JnZ3MCfX9XQZHHR3iMJs%3d",
      "team": "AGE Against the Machine GGM",
      "score": "10",
      "oppScore": "15",
      "opp": "Zombiemaker",
      "oppId": "nTMrIvXFMjiKD9U4PzHDadThdFExYHyyrwFZW6BZp7o%3d"
    },
    {
      "date": "June 04",
      "tournament": "2022 USA Ultimate Great Lakes Great Grand Masters Men's Regionals",
      "tournamentId": "2022-USA-Ultimate-Great-Lakes-Great-Grand-Masters-Mens-Regionals-",
      "gameId": "Sl2y2hjAW%2fzpjvBXQlCXCfTkHm%2ft8Hp2jNjs3Zoe41Q%3d",
      "team": "AGE Against the Machine GGM",
      "score": "15",
      "oppScore": "14",
      "opp": "Aging Bulls",
      "oppId": "7llifpOCI7AeAj%2fgJlUB6rqWKWTVszzihpbdm73MSUQ%3d"
    },
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "EHGFIxDczLCgyUSEzg7Fc17zwEXO3DUUdYxHFg%2bmW5M%3d",
      "team": "AGE Against the Machine GGM",
      "score": "3",
      "oppScore": "13",
      "opp": "Pittsburgh Stealers",
      "oppId": "icSGwphEBV6tcYJLRwPxEC22s0bt6rct5HG5ubjyetw%3d"
    },
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "udhayMLULDYY3nywVZQLTF6wAqafEo0kpcxaTXgvBIA%3d",
      "team": "AGE Against the Machine GGM",
      "score": "3",
      "oppScore": "13",
      "opp": "Black Lung",
      "oppId": "olaZlUwWblgF%2b%2b2nRJgbWJ2tj6QzPm5aIO7kfbpnA58%3d"
    },
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "lRrP1aFaVvI8cx1HkIL%2bTit90cdH3%2f7YGSvlHtFchhQ%3d",
      "team": "AGE Against the Machine GGM",
      "score": "11",
      "oppScore": "12",
      "opp": "Flying Pig",
      "oppId": "rUyyXUmvJe1zUxoCIq%2bJ8srRSS59bSwzNSdxsoWUbdY%3d"
    },
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "7eQbVgZmFVw5zBe8Ysc81yn%2bPJV3GqtjhF6jmGP146A%3d",
      "team": "AGE Against the Machine GGM",
      "score": "6",
      "oppScore": "13",
      "opp": "Beacon",
      "oppId": "4n5jY6eFSRuptn3xrctxCFjAy7g9dqmJM2uPNhY3qbk%3d"
    },
    {
      "date": "June 26",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "8gPFdyORWjavM%2fFeco7Y43gJptnwEa1N%2bQM0DPYAlb8%3d",
      "team": "AGE Against the Machine GGM",
      "score": "8",
      "oppScore": "13",
      "opp": "Chimney",
      "oppId": "jzVhyLybRfcABTsshmdvgsxYDSASMTHMGNde%2bkQTb4Y%3d"
    },
    {
      "date": "June 26",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "9WYF8GvAFwvxG1YUl0w%2bc6lfFJrAeyH%2fyjp7hmWOHYk%3d",
      "team": "AGE Against the Machine GGM",
      "score": "5",
      "oppScore": "13",
      "opp": "Enigma",
      "oppId": "vGMVLiic34oSyMk%2bETpVqMkuexU7MBkROOjGtFdKoVM%3d"
    },
    {
      "date": "July 15",
      "tournament": "2022 USA Ultimate Masters Championships",
      "tournamentId": "2022-USA-Ultimate-Masters-Championships",
      "gameId": "EtE%2bbnxLjKmx7MFa7OJqJtkf5MmOVbjEfc2AhwhvnMg%3d",
      "team": "AGE Against the Machine GGM",
      "score": "9",
      "oppScore": "14",
      "opp": "HURT",
      "oppId": "5UWkPHOJwlRbzkRQ6zPjMAQwQNu32eGDORjMXgl0rz0%3d"
    },
    {
      "date": "July 15",
      "tournament": "2022 USA Ultimate Masters Championships",
      "tournamentId": "2022-USA-Ultimate-Masters-Championships",
      "gameId": "X0wtZR7fBiVS9FicqfnLsT4ZZ99omDAfjqJVRbm1vAk%3d",
      "team": "AGE Against the Machine GGM",
      "score": "4",
      "oppScore": "15",
      "opp": "No Country 50",
      "oppId": "CKVe4HqZMJnJOQaPtskc9PjwTLq0aWnUnUsJQ01xDA8%3d"
    },
    {
      "date": "July 15",
      "tournament": "2022 USA Ultimate Masters Championships",
      "tournamentId": "2022-USA-Ultimate-Masters-Championships",
      "gameId": "hE%2f8HPSryLm7lA6r1wTlFOeIMvyZ87JLimuFzvQDmZk%3d",
      "team": "AGE Against the Machine GGM",
      "score": "9",
      "oppScore": "13",
      "opp": "Thirst'N'Howl",
      "oppId": "dQka68y5FdrJJOIzKSvhuE04Y38SrAiqtlHvOFZiVcQ%3d"
    },
    {
      "date": "July 16",
      "tournament": "2022 USA Ultimate Masters Championships",
      "tournamentId": "2022-USA-Ultimate-Masters-Championships",
      "gameId": "6gz7Y5vRKf3Wr5HIXtrez%2fuKgqgFW7NOCkQg1XREzro%3d",
      "team": "AGE Against the Machine GGM",
      "score": "11",
      "oppScore": "13",
      "opp": "Shelf Life",
      "oppId": "fjZfxlr7ibkfIOqt8O%2fIkumTSFHy9L5Elo8ZDpiw2Sc%3d"
    },
    {
      "date": "July 16",
      "tournament": "2022 USA Ultimate Masters Championships",
      "tournamentId": "2022-USA-Ultimate-Masters-Championships",
      "gameId": "CjxEpGtNRbZu8l7MJh7kXti7jJGpS2lJgvKfllagXq0%3d",
      "team": "AGE Against the Machine GGM",
      "score": "14",
      "oppScore": "9",
      "opp": "Burnt Ends GGM",
      "oppId": "DZT%2f4BOCcmy3AZpeypVwfoj%2fV5G%2f36gJABu2Vk1D5%2fQ%3d"
    },
    {
      "date": "July 17",
      "tournament": "2022 USA Ultimate Masters Championships",
      "tournamentId": "2022-USA-Ultimate-Masters-Championships",
      "gameId": "4jKwBnE9VMtRaqW27BumsZG5ON9SP%2bg4812zE2Y0s%2f8%3d",
      "team": "AGE Against the Machine GGM",
      "score": "13",
      "oppScore": "10",
      "opp": "Thirst'N'Howl",
      "oppId": "dQka68y5FdrJJOIzKSvhuE04Y38SrAiqtlHvOFZiVcQ%3d"
    }
  ],
  "icSGwphEBV6tcYJLRwPxEC22s0bt6rct5HG5ubjyetw%3d": [
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "EHGFIxDczLCgyUSEzg7Fc17zwEXO3DUUdYxHFg%2bmW5M%3d",
      "team": "Pittsburgh Stealers",
      "score": "13",
      "oppScore": "3",
      "opp": "AGE Against the Machine GGM",
      "oppId": "PGFvuARzsEPpLAu8aRRGaJ6CiGl%2fo97vxgqDeaUh3yk%3d"
    },
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "Bq4ysX3eMaiHVWszy69LdUHwjdpdC%2b48rJQUQTNL1UY%3d",
      "team": "Pittsburgh Stealers",
      "score": "13",
      "oppScore": "10",
      "opp": "Beacon",
      "oppId": "4n5jY6eFSRuptn3xrctxCFjAy7g9dqmJM2uPNhY3qbk%3d"
    },
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "Lijc173LfgvgkkR9jehng3u9G3%2fsUq5DT%2bY4QMOq8Tk%3d",
      "team": "Pittsburgh Stealers",
      "score": "8",
      "oppScore": "13",
      "opp": "Black Lung",
      "oppId": "olaZlUwWblgF%2b%2b2nRJgbWJ2tj6QzPm5aIO7kfbpnA58%3d"
    },
    {
      "date": "June 26",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "ZGmGUKgfcdVLH1qQomODIgnjaL8sCDZxdT3TxOCLP0w%3d",
      "team": "Pittsburgh Stealers",
      "score": "13",
      "oppScore": "7",
      "opp": "Flying Pig",
      "oppId": "rUyyXUmvJe1zUxoCIq%2bJ8srRSS59bSwzNSdxsoWUbdY%3d"
    },
    {
      "date": "June 26",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "Fed3lBmV%2f3fW7%2b1A%2f2%2f0KhuYFxCa6jXktSMVAuFT%2bGk%3d",
      "team": "Pittsburgh Stealers",
      "score": "15",
      "oppScore": "9",
      "opp": "Hazard",
      "oppId": "RBbGte9ro%2fCZWrmOmcHNTdHB41zGQFb64ubBkZjcTEI%3d"
    },
    {
      "date": "June 26",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "ZuW8KfH2iIVcSKS%2bEp4YGP114%2bA9n7mt36Mp7XYBA8g%3d",
      "team": "Pittsburgh Stealers",
      "score": "11",
      "oppScore": "9",
      "opp": "Black Lung",
      "oppId": "olaZlUwWblgF%2b%2b2nRJgbWJ2tj6QzPm5aIO7kfbpnA58%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "70hWmK1BBkPiRHuTZCWIkOg5e9Rkfm3buG%2fSZgg%2bYRk%3d",
      "team": "Pittsburgh Stealers",
      "score": "13",
      "oppScore": "2",
      "opp": "Deepfake",
      "oppId": "MjCJCQyu%2bVgQCxsNuCDsIlsaBlb84XeNodQMva1Vl6s%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "%2f%2bDYTEomUu%2bLHdxYdvRtj0ALfJTCL%2bd7y%2fpc8JZpPGU%3d",
      "team": "Pittsburgh Stealers",
      "score": "13",
      "oppScore": "10",
      "opp": "Ballston Booya",
      "oppId": "nyrFBt9ebf%2bCb5bJv%2fsbfoGgjlyW%2bH3CLoFeaZGYMEY%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "DI2pCjOEupQ4m%2bk86MmbWmsujxVD2c1k4CjcSOlEuXI%3d",
      "team": "Pittsburgh Stealers",
      "score": "13",
      "oppScore": "6",
      "opp": "John Doe",
      "oppId": "U8i%2ffBl%2bGdNRXUaehdkV4W9lOM4QHUciytmWcy3oxXI%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "txhyiXovL6EgowbPqD3BLzgOA4yAi9iOWeA73uerMv8%3d",
      "team": "Pittsburgh Stealers",
      "score": "9",
      "oppScore": "12",
      "opp": "Red Tide",
      "oppId": "s6HGV42d1dEo0YRx%2fIycGA2qxvKRYzzfEITLPlDYO1I%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "nE4D0Q8EhLO2j4ShCoiqxYbxVR6FYhUDD5rAk2aZAtw%3d",
      "team": "Pittsburgh Stealers",
      "score": "10",
      "oppScore": "13",
      "opp": "Oakgrove Boys",
      "oppId": "1zUV4kEpPc%2flA39WIyrp9j1w%2fhuIkgE6KlD9%2f%2ffQzg8%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "FScsYrlwr4OqfhgGBIgkrg7B%2b7FTIGQ3LSmd7ZvyoIw%3d",
      "team": "Pittsburgh Stealers",
      "score": "5",
      "oppScore": "4",
      "opp": "Brickhouse",
      "oppId": "b5ARnVg7OngG1a0%2fJuMZTHluwgNFe%2bN5esQIIKsEOUY%3d"
    }
  ],
  "4n5jY6eFSRuptn3xrctxCFjAy7g9dqmJM2uPNhY3qbk%3d": [
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "bowOUfHH6xRldp7DIaT8ecgFC%2fQ2%2bXnO9b9muYQIU7k%3d",
      "team": "Beacon",
      "score": "13",
      "oppScore": "5",
      "opp": "Flying Pig",
      "oppId": "rUyyXUmvJe1zUxoCIq%2bJ8srRSS59bSwzNSdxsoWUbdY%3d"
    },
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "Bq4ysX3eMaiHVWszy69LdUHwjdpdC%2b48rJQUQTNL1UY%3d",
      "team": "Beacon",
      "score": "10",
      "oppScore": "13",
      "opp": "Pittsburgh Stealers",
      "oppId": "icSGwphEBV6tcYJLRwPxEC22s0bt6rct5HG5ubjyetw%3d"
    },
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "7eQbVgZmFVw5zBe8Ysc81yn%2bPJV3GqtjhF6jmGP146A%3d",
      "team": "Beacon",
      "score": "13",
      "oppScore": "6",
      "opp": "AGE Against the Machine GGM",
      "oppId": "PGFvuARzsEPpLAu8aRRGaJ6CiGl%2fo97vxgqDeaUh3yk%3d"
    },
    {
      "date": "June 26",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "A8%2ftOUwl%2fuKpe5RhEFBHa6tBVf8RTqlIOMaGHAzVn10%3d",
      "team": "Beacon",
      "score": "11",
      "oppScore": "10",
      "opp": "Black Lung",
      "oppId": "olaZlUwWblgF%2b%2b2nRJgbWJ2tj6QzPm5aIO7kfbpnA58%3d"
    },
    {
      "date": "June 26",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "aB0rCLZlCYIthmkOVzW1zaz9llcDXlreNVC0B5xgoyQ%3d",
      "team": "Beacon",
      "score": "12",
      "oppScore": "11",
      "opp": "Chimney",
      "oppId": "jzVhyLybRfcABTsshmdvgsxYDSASMTHMGNde%2bkQTb4Y%3d"
    },
    {
      "date": "June 26",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "0972C6Pdecif4wJ%2fS%2bNrpAtKHt6f%2fB5I%2bstDAYIDOCg%3d",
      "team": "Beacon",
      "score": "13",
      "oppScore": "10",
      "opp": "Enigma",
      "oppId": "vGMVLiic34oSyMk%2bETpVqMkuexU7MBkROOjGtFdKoVM%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "F%2fL3VLA2%2bykOYzzyJW%2bLCaqtkD4dtBzpciu6glPn6bo%3d",
      "team": "Beacon",
      "score": "9",
      "oppScore": "15",
      "opp": "STL Lounar",
      "oppId": "oDdMOQb83b3Np2dDk%2b2JcTekyRlMRY%2b2lYbnQeDn3Pk%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "RVjpg8LuwIpCAlcSlejSpW1qFLvgrQDidPwTz4ma6UQ%3d",
      "team": "Beacon",
      "score": "10",
      "oppScore": "15",
      "opp": "Trident I",
      "oppId": "HJQp9EgoBkW9LmipJ4BOA7JQ%2bJ%2fsSTavaeYtiM6KQus%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "rWZV2N2Ah7coWBe5ZNBCCTDknBc9eCbADkMP8Kx2KJg%3d",
      "team": "Beacon",
      "score": "14",
      "oppScore": "8",
      "opp": "HouSE",
      "oppId": "W3JpjYTNBVirvq%2bLhhgD1xswtN%2bDHXo2Y5uhwfm%2f450%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "VMrhn23DZ38pNSKJ0ZTqCi7jSSts8oqlma1s5Ggu2rw%3d",
      "team": "Beacon",
      "score": "15",
      "oppScore": "3",
      "opp": "Mad Men B [B]",
      "oppId": "hMj3CHJLwG8WqQXZcH4AKvR5bELtlJK6A4UDsh8UYEk%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "Fq7IoBNHreW%2bVqe%2bataTj7XWHPvL9EtBbPxTyR3j0Q8%3d",
      "team": "Beacon",
      "score": "15",
      "oppScore": "7",
      "opp": "Trident II",
      "oppId": "b69A0xfMxQKwR01A7jfOqdv1wbChbTxRp5jko3aOCXk%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "zCMiN6Cc6mUZjB%2fQywg%2f9KpjtR7K9AanpQtRQBuEt2U%3d",
      "team": "Beacon",
      "score": "15",
      "oppScore": "9",
      "opp": "I-69",
      "oppId": "a64fDYPntTHPU4vghTeNstb32Z0JFlq528bbATJx%2bbs%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "Z5q5Px6t5GHnJ3xEIqNWAKs79O%2f6mB6y%2bVFA6PtrZGs%3d",
      "team": "Beacon",
      "score": "12",
      "oppScore": "9",
      "opp": "Overdrive",
      "oppId": "cfApDIqN4eoe%2fr26h%2b8fQj3s9XKGa9nJC8lT5uya7gM%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "2qaETBa9658iZJc9v%2bFuWhs%2bVlL1RxJp07jRlal650A%3d",
      "team": "Beacon",
      "score": "13",
      "oppScore": "1",
      "opp": "Trident II",
      "oppId": "b69A0xfMxQKwR01A7jfOqdv1wbChbTxRp5jko3aOCXk%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "BvNgmvO28D62Gbjx29Rsi0rEl2Pr3dpDPOBC9nNTddg%3d",
      "team": "Beacon",
      "score": "12",
      "oppScore": "13",
      "opp": "Mallard II",
      "oppId": "waPLoije6zVsjVUcOXTlGSFZ10PPabaoS6kk%2bnW2NCo%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "dpxEyuGppCjB%2bXB81P%2bNvPWCmnaFmK2XM3GUm5KCaKI%3d",
      "team": "Beacon",
      "score": "7",
      "oppScore": "13",
      "opp": "Cryptic",
      "oppId": "Qhc%2fzwjxDlH6UTqMQqHKE2ms56DaeAMWMHQJCBwAjIw%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "VnE9YBPOVmEGxplII8sWWDeyNAujC2oXl6mVsQLCXCE%3d",
      "team": "Beacon",
      "score": "13",
      "oppScore": "9",
      "opp": "Chimney",
      "oppId": "jzVhyLybRfcABTsshmdvgsxYDSASMTHMGNde%2bkQTb4Y%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "p%2b6pN%2byVZ0IF2CrKlqPYTMnP3JjzFNNBiPYylfVqYjA%3d",
      "team": "Beacon",
      "score": "9",
      "oppScore": "13",
      "opp": "MKE",
      "oppId": "qHyFAYrlLVWh07Z9rgHqv1nh7SsCt5k4p8Ri0Bna7Zc%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "vXZsDrridsPi60K%2bH9VOixV%2bttImQbnjZxznvYHjPXA%3d",
      "team": "Beacon",
      "score": "13",
      "oppScore": "11",
      "opp": "Nain Rouge",
      "oppId": "VlCPZLsfBQ0DobBH%2fc22bLMbT2Ge3MbFrq%2fRZ8Jw0gM%3d"
    }
  ],
  "vGMVLiic34oSyMk%2bETpVqMkuexU7MBkROOjGtFdKoVM%3d": [
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "W7BIixfcRI4o3W%2fdL7N07gQC6ylG6ospuXj77fqJo4g%3d",
      "team": "Enigma",
      "score": "7",
      "oppScore": "13",
      "opp": "Hazard",
      "oppId": "RBbGte9ro%2fCZWrmOmcHNTdHB41zGQFb64ubBkZjcTEI%3d"
    },
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "ABG6pv58eVopLllTXzBW%2forcdFSZa871%2bUMAv5%2fByOY%3d",
      "team": "Enigma",
      "score": "9",
      "oppScore": "14",
      "opp": "Flying Dutchmen",
      "oppId": "7amY9LIoyY8hOWkzj5Nqp1H8c7bkR6ySQBzeCHsiQFg%3d"
    },
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "A5m%2bVGQGMXydZhdTb61Ivtm3dxIg%2bRqdSpXYOUYEpfQ%3d",
      "team": "Enigma",
      "score": "14",
      "oppScore": "13",
      "opp": "Chimney",
      "oppId": "jzVhyLybRfcABTsshmdvgsxYDSASMTHMGNde%2bkQTb4Y%3d"
    },
    {
      "date": "June 26",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "AIloD8Gy4fgpc7usUidlGAg2ivUXwB14LY%2bCoGdDyfI%3d",
      "team": "Enigma",
      "score": "13",
      "oppScore": "5",
      "opp": "Flying Pig",
      "oppId": "rUyyXUmvJe1zUxoCIq%2bJ8srRSS59bSwzNSdxsoWUbdY%3d"
    },
    {
      "date": "June 26",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "0972C6Pdecif4wJ%2fS%2bNrpAtKHt6f%2fB5I%2bstDAYIDOCg%3d",
      "team": "Enigma",
      "score": "10",
      "oppScore": "13",
      "opp": "Beacon",
      "oppId": "4n5jY6eFSRuptn3xrctxCFjAy7g9dqmJM2uPNhY3qbk%3d"
    },
    {
      "date": "June 26",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "9WYF8GvAFwvxG1YUl0w%2bc6lfFJrAeyH%2fyjp7hmWOHYk%3d",
      "team": "Enigma",
      "score": "13",
      "oppScore": "5",
      "opp": "AGE Against the Machine GGM",
      "oppId": "PGFvuARzsEPpLAu8aRRGaJ6CiGl%2fo97vxgqDeaUh3yk%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "pxLSDRI8NzK%2b%2fDnDKNllHyi5WlJxqLSVxwuE6cJE04U%3d",
      "team": "Enigma",
      "score": "12",
      "oppScore": "13",
      "opp": "Cryptic",
      "oppId": "Qhc%2fzwjxDlH6UTqMQqHKE2ms56DaeAMWMHQJCBwAjIw%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "gqYoy%2bKwrpkSH7kGngM%2f5q4zM2GgRLhrGxpBgV128PM%3d",
      "team": "Enigma",
      "score": "10",
      "oppScore": "13",
      "opp": "MKE",
      "oppId": "qHyFAYrlLVWh07Z9rgHqv1nh7SsCt5k4p8Ri0Bna7Zc%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "O2BEWwC1ciAtJ3CCh5niKLCZ8W9nZ5ZNKoR8DlJNW%2fg%3d",
      "team": "Enigma",
      "score": "13",
      "oppScore": "5",
      "opp": "Rubicon Rapids",
      "oppId": "%2bIdRZdPfxa07Yaa3PzfmJw5so76rO6A7JVJAQXOY%2b40%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "4uTSy3DjsWKu7Us3X8GvY%2fGOW90udRfeEzdnekrTXpo%3d",
      "team": "Enigma",
      "score": "13",
      "oppScore": "5",
      "opp": "Overdrive",
      "oppId": "cfApDIqN4eoe%2fr26h%2b8fQj3s9XKGa9nJC8lT5uya7gM%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "Y1QWzL9YCsqkD0U%2bhJ6%2bgvwZJODU3ApZZlPix6p88Qg%3d",
      "team": "Enigma",
      "score": "12",
      "oppScore": "13",
      "opp": "Nain Rouge",
      "oppId": "VlCPZLsfBQ0DobBH%2fc22bLMbT2Ge3MbFrq%2fRZ8Jw0gM%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "0xnpzdCNjIcU%2bugPkHimmJqir2xR36jwDwkb%2beTQ7yA%3d",
      "team": "Enigma",
      "score": "13",
      "oppScore": "11",
      "opp": "Rubicon Rapids",
      "oppId": "%2bIdRZdPfxa07Yaa3PzfmJw5so76rO6A7JVJAQXOY%2b40%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "jR6iyfljxmKlbjn7McgXXrmfqqcL6uXzkXGMgToTk9M%3d",
      "team": "Enigma",
      "score": "13",
      "oppScore": "5",
      "opp": "Chimney",
      "oppId": "jzVhyLybRfcABTsshmdvgsxYDSASMTHMGNde%2bkQTb4Y%3d"
    }
  ],
  "olaZlUwWblgF%2b%2b2nRJgbWJ2tj6QzPm5aIO7kfbpnA58%3d": [
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "udhayMLULDYY3nywVZQLTF6wAqafEo0kpcxaTXgvBIA%3d",
      "team": "Black Lung",
      "score": "13",
      "oppScore": "3",
      "opp": "AGE Against the Machine GGM",
      "oppId": "PGFvuARzsEPpLAu8aRRGaJ6CiGl%2fo97vxgqDeaUh3yk%3d"
    },
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "Lijc173LfgvgkkR9jehng3u9G3%2fsUq5DT%2bY4QMOq8Tk%3d",
      "team": "Black Lung",
      "score": "13",
      "oppScore": "8",
      "opp": "Pittsburgh Stealers",
      "oppId": "icSGwphEBV6tcYJLRwPxEC22s0bt6rct5HG5ubjyetw%3d"
    },
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "xccg%2bejriAzprcr6FNZnYV2mLuE23BLWJ3JKDEVCMFE%3d",
      "team": "Black Lung",
      "score": "13",
      "oppScore": "5",
      "opp": "Flying Pig",
      "oppId": "rUyyXUmvJe1zUxoCIq%2bJ8srRSS59bSwzNSdxsoWUbdY%3d"
    },
    {
      "date": "June 26",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "A8%2ftOUwl%2fuKpe5RhEFBHa6tBVf8RTqlIOMaGHAzVn10%3d",
      "team": "Black Lung",
      "score": "10",
      "oppScore": "11",
      "opp": "Beacon",
      "oppId": "4n5jY6eFSRuptn3xrctxCFjAy7g9dqmJM2uPNhY3qbk%3d"
    },
    {
      "date": "June 26",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "9qRaRopaxjgjX%2fjnozP%2fXksmWXY0HgTXRRO%2fF43tLHg%3d",
      "team": "Black Lung",
      "score": "12",
      "oppScore": "9",
      "opp": "Flying Dutchmen",
      "oppId": "7amY9LIoyY8hOWkzj5Nqp1H8c7bkR6ySQBzeCHsiQFg%3d"
    },
    {
      "date": "June 26",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "ZuW8KfH2iIVcSKS%2bEp4YGP114%2bA9n7mt36Mp7XYBA8g%3d",
      "team": "Black Lung",
      "score": "9",
      "oppScore": "11",
      "opp": "Pittsburgh Stealers",
      "oppId": "icSGwphEBV6tcYJLRwPxEC22s0bt6rct5HG5ubjyetw%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "Ei9ZFVGqdtvTPB9TRoxNUD9u1S2lFrvMEEU6SNIJPAY%3d",
      "team": "Black Lung",
      "score": "5",
      "oppScore": "7",
      "opp": "Second Nature",
      "oppId": "9QN%2fOffYfC%2f0mj9CpLqFRthKvgHjrHj%2bM2%2fY44PMiCE%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "0j3Wrw3YnIeTGVW5lw%2fFUUGzoPRTasDFFtw%2bcPdGQ8A%3d",
      "team": "Black Lung",
      "score": "6",
      "oppScore": "13",
      "opp": "Lost Boys",
      "oppId": "aNMyR0uAJNwnidjeClZAMqixGjtlQqA6k7CiZP1R0%2bM%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "3%2fRDVuzh0DAOwXpRIJiYzw6SoNiXf1EWxHBcbI0Q9FM%3d",
      "team": "Black Lung",
      "score": "11",
      "oppScore": "9",
      "opp": "Space Cowboys",
      "oppId": "3%2bZ1uFiVej3zLASHWkgqXkJ7hNQ98n84b2FvR4T3pQA%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "N%2ffGDffSP6CE4ci8zRZWu67UERRTCex0PILeDE%2fhW64%3d",
      "team": "Black Lung",
      "score": "8",
      "oppScore": "11",
      "opp": "Dyno",
      "oppId": "fIw72i6bkbJnjTbK02aFLpJ9Qy2lCUVWlRBvI%2b8UmIc%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "bIBMGijZa8p1SZxxbc8IaixJ6r6uxr5z0EfasQ3hLyM%3d",
      "team": "Black Lung",
      "score": "11",
      "oppScore": "4",
      "opp": "Tennessee Folklore",
      "oppId": "z7Hwsz316C%2bFf9Cg1gKP3NGBSqxMqnFjQRbeyMUhUD4%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "fYrmKoPxLGHcXXsEXqfZceLpJe7mM%2bHOVvPEnfekbHg%3d",
      "team": "Black Lung",
      "score": "10",
      "oppScore": "9",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "7N%2b0n44oGyWLRv6obEpX4nEhN94Aq2LooJl%2bYv6NYoQ%3d",
      "team": "Black Lung",
      "score": "9",
      "oppScore": "13",
      "opp": "Oakgrove Boys",
      "oppId": "1zUV4kEpPc%2flA39WIyrp9j1w%2fhuIkgE6KlD9%2f%2ffQzg8%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "nOye6PEVEODzen4s0w8qvYxZQYlNAR517NpxLiwM15A%3d",
      "team": "Black Lung",
      "score": "13",
      "oppScore": "10",
      "opp": "Space Cowboys",
      "oppId": "3%2bZ1uFiVej3zLASHWkgqXkJ7hNQ98n84b2FvR4T3pQA%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "YmPXUOykrMMadDK2I3vme2TW8Z3ykaMnDx4YZShU0A4%3d",
      "team": "Black Lung",
      "score": "5",
      "oppScore": "13",
      "opp": "Raleigh-Durham United",
      "oppId": "ESHhN%2bMK1oy0j%2bQJSL8mb9cq8h30Erb63T07U8pubEc%3d"
    }
  ],
  "7amY9LIoyY8hOWkzj5Nqp1H8c7bkR6ySQBzeCHsiQFg%3d": [
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "Ht93R8BnLeWaOaodieBWAI0GIaNn7wstnzPjDYA6%2bsM%3d",
      "team": "Flying Dutchmen",
      "score": "15",
      "oppScore": "14",
      "opp": "Chimney",
      "oppId": "jzVhyLybRfcABTsshmdvgsxYDSASMTHMGNde%2bkQTb4Y%3d"
    },
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "ABG6pv58eVopLllTXzBW%2forcdFSZa871%2bUMAv5%2fByOY%3d",
      "team": "Flying Dutchmen",
      "score": "14",
      "oppScore": "9",
      "opp": "Enigma",
      "oppId": "vGMVLiic34oSyMk%2bETpVqMkuexU7MBkROOjGtFdKoVM%3d"
    },
    {
      "date": "June 25",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "dZ7ZMIYGqjHuj5B2v6hMSezCQgNieww13WzhbeOHXXs%3d",
      "team": "Flying Dutchmen",
      "score": "9",
      "oppScore": "12",
      "opp": "Hazard",
      "oppId": "RBbGte9ro%2fCZWrmOmcHNTdHB41zGQFb64ubBkZjcTEI%3d"
    },
    {
      "date": "June 26",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "9qRaRopaxjgjX%2fjnozP%2fXksmWXY0HgTXRRO%2fF43tLHg%3d",
      "team": "Flying Dutchmen",
      "score": "9",
      "oppScore": "12",
      "opp": "Black Lung",
      "oppId": "olaZlUwWblgF%2b%2b2nRJgbWJ2tj6QzPm5aIO7kfbpnA58%3d"
    },
    {
      "date": "June 26",
      "tournament": "SCINNY",
      "tournamentId": "SCINNY",
      "gameId": "Na%2fSz1NIHOvuuPHIax%2bmWIHlpRb7Qm3ikEBJPMeoK2I%3d",
      "team": "Flying Dutchmen",
      "score": "13",
      "oppScore": "10",
      "opp": "Hazard",
      "oppId": "RBbGte9ro%2fCZWrmOmcHNTdHB41zGQFb64ubBkZjcTEI%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "zkI5avyqu09rNhh8DlFu2B2UoNJD%2fZb5bjBtE3NCZV8%3d",
      "team": "Flying Dutchmen",
      "score": "11",
      "oppScore": "15",
      "opp": "Knights of Ni",
      "oppId": "dgH60oTrddgw%2bSl2xkIe%2fis%2fu1HY9ndk1MZTdN4OiAs%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "y7bwzH0c6DNSW%2b%2byEYtkq3swZUlKufvt9QtTLs3YQAM%3d",
      "team": "Flying Dutchmen",
      "score": "11",
      "oppScore": "12",
      "opp": "MKE",
      "oppId": "qHyFAYrlLVWh07Z9rgHqv1nh7SsCt5k4p8Ri0Bna7Zc%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "o7tzd3AbCVc9qF3W0EV8qEL4h07jwNNyMmtIzYJPxlQ%3d",
      "team": "Flying Dutchmen",
      "score": "15",
      "oppScore": "3",
      "opp": "Mad Men B [B]",
      "oppId": "hMj3CHJLwG8WqQXZcH4AKvR5bELtlJK6A4UDsh8UYEk%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "nhUP5g1d%2f%2bHvwnG73UzrfesNVX0IaEsbHlLlSILkFUU%3d",
      "team": "Flying Dutchmen",
      "score": "15",
      "oppScore": "10",
      "opp": "HouSE",
      "oppId": "W3JpjYTNBVirvq%2bLhhgD1xswtN%2bDHXo2Y5uhwfm%2f450%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "3%2bzZszi1KbfsNQ6cR4P9vGeVvTVUbMFlnznZsTj1Vao%3d",
      "team": "Flying Dutchmen",
      "score": "12",
      "oppScore": "15",
      "opp": "I-69",
      "oppId": "a64fDYPntTHPU4vghTeNstb32Z0JFlq528bbATJx%2bbs%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "mG0IpYNZuW1vdg%2fk4oY9IC6J4FPf4UP%2fZpRwC9Ua5IU%3d",
      "team": "Flying Dutchmen",
      "score": "15",
      "oppScore": "6",
      "opp": "Trident II",
      "oppId": "b69A0xfMxQKwR01A7jfOqdv1wbChbTxRp5jko3aOCXk%3d"
    },
    {
      "date": "August 06",
      "tournament": "Louisville Slugfest",
      "tournamentId": "Louisville-Slugfest",
      "gameId": "gdUr8tqF4MWx0%2bkfRt%2f2vMSpynHRcDV40KG7XHNmYxA%3d",
      "team": "Flying Dutchmen",
      "score": "5",
      "oppScore": "13",
      "opp": "Colonels",
      "oppId": "7Xv2AHNIg3Y%2fm1y7qWkHUz%2b9KT3NZFOCMpW7gBaxnfs%3d"
    },
    {
      "date": "August 06",
      "tournament": "Louisville Slugfest",
      "tournamentId": "Louisville-Slugfest",
      "gameId": "4iNRKKUZn9R9xlQDpG4YC8%2bTJBWz%2fJO5BksH%2fptZBI8%3d",
      "team": "Flying Dutchmen",
      "score": "11",
      "oppScore": "10",
      "opp": "Nashville Mudcats",
      "oppId": "m1V5goSU%2bO1BR4XRhH%2fyGq44j%2f4hNKcb%2bBApPx5jVzQ%3d"
    },
    {
      "date": "August 06",
      "tournament": "Louisville Slugfest",
      "tournamentId": "Louisville-Slugfest",
      "gameId": "stBiilu5iSTcxOm9VSuC3GBqNJMb7n2yGmey5FxIGGU%3d",
      "team": "Flying Dutchmen",
      "score": "6",
      "oppScore": "11",
      "opp": "Colonels",
      "oppId": "7Xv2AHNIg3Y%2fm1y7qWkHUz%2b9KT3NZFOCMpW7gBaxnfs%3d"
    }
  ],
  "LdH2zJ494O%2bxdz6p92IsLTMaAmjgf6aP%2fBvM9Lr1RaQ%3d": [
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "2wOJh4vLP6m37wcBd7biB9dwagWVRiK72sDw9yRLyUs%3d",
      "team": "Rush Hour ATL",
      "score": "11",
      "oppScore": "6",
      "opp": "Second Nature",
      "oppId": "9QN%2fOffYfC%2f0mj9CpLqFRthKvgHjrHj%2bM2%2fY44PMiCE%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "3xIMUFVBD2Zo8mihcVKyioZ8Z5jK5GrDtDjI%2bDHlci4%3d",
      "team": "Rush Hour ATL",
      "score": "11",
      "oppScore": "6",
      "opp": "Nashville Mudcats",
      "oppId": "m1V5goSU%2bO1BR4XRhH%2fyGq44j%2f4hNKcb%2bBApPx5jVzQ%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "hIT6spNxnGeaxWhnAdQU%2frUu6h6UpHwXIaE%2b1xcuWGM%3d",
      "team": "Rush Hour ATL",
      "score": "11",
      "oppScore": "10",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "rZYfy%2fEY1O3cuJk1OThp%2b1EMVoLsqYAZsxXZhEEkD68%3d",
      "team": "Rush Hour ATL",
      "score": "8",
      "oppScore": "9",
      "opp": "Alliance",
      "oppId": "%2f7JeYXylBxa9Xw5f5LSwua5DyAomcfJ7a7MZDP7nSUs%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "3%2b6CPoTZHNrjkoJy8ak56qnVqTg6pxXco5UxrqQA0eQ%3d",
      "team": "Rush Hour ATL",
      "score": "10",
      "oppScore": "6",
      "opp": "Dyno",
      "oppId": "fIw72i6bkbJnjTbK02aFLpJ9Qy2lCUVWlRBvI%2b8UmIc%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "Q4kWkyY57fwllEaZV%2fac2eHfPU1VPcgdNFnYCMhUlxM%3d",
      "team": "Rush Hour ATL",
      "score": "12",
      "oppScore": "9",
      "opp": "Atlanta Arson",
      "oppId": "oAgat63CZNVga8weCR1ui3IB9YvjaA54miCvE%2f%2bRb5g%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "76Hsrvrx1kfLIeDUd0g71EFk6gVN7b3P6XR7g8gdjjk%3d",
      "team": "Rush Hour ATL",
      "score": "5",
      "oppScore": "8",
      "opp": "Alliance",
      "oppId": "%2f7JeYXylBxa9Xw5f5LSwua5DyAomcfJ7a7MZDP7nSUs%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "j2anvfJYRoigyTteQdT6cZ2qXMqfUjcWRiGac3IWR24%3d",
      "team": "Rush Hour ATL",
      "score": "9",
      "oppScore": "7",
      "opp": "Second Nature",
      "oppId": "9QN%2fOffYfC%2f0mj9CpLqFRthKvgHjrHj%2bM2%2fY44PMiCE%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "uNoXjnjna0IUk5iyrrMocAyb%2bHXxZaEPMV4d%2fYNYlQI%3d",
      "team": "Rush Hour ATL",
      "score": "10",
      "oppScore": "6",
      "opp": "Atlanta Arson",
      "oppId": "oAgat63CZNVga8weCR1ui3IB9YvjaA54miCvE%2f%2bRb5g%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "dWCEr7N1wungpwuTeNJrgDyIFx7RcnOF%2bCuman85FiY%3d",
      "team": "Rush Hour ATL",
      "score": "13",
      "oppScore": "1",
      "opp": "Tampa Tempest",
      "oppId": "hQXi92LAmE32qiZ%2fpZRYvkIBTehvd13BZlvBS7aR3%2fQ%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "JEcBG9ybUAWnW4y0WFllIkoaDU3ve7VjyS17QA8C9B8%3d",
      "team": "Rush Hour ATL",
      "score": "9",
      "oppScore": "10",
      "opp": "Delirium",
      "oppId": "0yudJHn8N3NbInYx1wqMxoticHnPBt4YkAfZZAUc7U8%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "0ww6MIp8mQEKb5N71xclj2lOMDDFoVfd0aeFJJwFh1w%3d",
      "team": "Rush Hour ATL",
      "score": "13",
      "oppScore": "8",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "DgUiTZVvmiaXk%2fq9vLZ5v2cLQl46pSe%2bdwg35B6BhI8%3d",
      "team": "Rush Hour ATL",
      "score": "13",
      "oppScore": "4",
      "opp": "Space Cowboys",
      "oppId": "3%2bZ1uFiVej3zLASHWkgqXkJ7hNQ98n84b2FvR4T3pQA%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "zF5pnvXU0d6%2bPJJ9uHbSI9XrUU%2fx70xZieshsDvsy84%3d",
      "team": "Rush Hour ATL",
      "score": "9",
      "oppScore": "13",
      "opp": "Dyno",
      "oppId": "fIw72i6bkbJnjTbK02aFLpJ9Qy2lCUVWlRBvI%2b8UmIc%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "flzn%2bzWTaRKUTblT936JYQYwyX7f1LtQr60%2bIAP17Ms%3d",
      "team": "Rush Hour ATL",
      "score": "11",
      "oppScore": "5",
      "opp": "Nashville Mudcats",
      "oppId": "m1V5goSU%2bO1BR4XRhH%2fyGq44j%2f4hNKcb%2bBApPx5jVzQ%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "8s9PhiYe0oGSpWP7e8Kx2S6GfCL2G3KCvHNqoFEeehs%3d",
      "team": "Rush Hour ATL",
      "score": "5",
      "oppScore": "11",
      "opp": "baNC",
      "oppId": "uce10clG%2fWf3GrKUxwKToSDTqXSp6FS58Fb50TN%2fPwU%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "EyXYXp3AER3fzAj%2f9KMB0MxSF8SwcoHgzDlzVP5%2fw1M%3d",
      "team": "Rush Hour ATL",
      "score": "11",
      "oppScore": "7",
      "opp": "Space Cowboys",
      "oppId": "3%2bZ1uFiVej3zLASHWkgqXkJ7hNQ98n84b2FvR4T3pQA%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "xRvXBN4JFcglRRiaCTLpJsF3ovdv7VNp8DJX4JX67e4%3d",
      "team": "Rush Hour ATL",
      "score": "11",
      "oppScore": "4",
      "opp": "Triumph",
      "oppId": "wAZDt10Q5MoiqMIAGOgPxedaQ2KA3QCSPn7hC8W%2bn5s%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "kWNGa0XCoZRRZDJupi81Vd8qXOLoF4TfCpge0KupuR0%3d",
      "team": "Rush Hour ATL",
      "score": "11",
      "oppScore": "7",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "6OHPwc703s0LkeV4E37LKaWOiRt7XAYq75muPy56u3c%3d",
      "team": "Rush Hour ATL",
      "score": "9",
      "oppScore": "13",
      "opp": "Brickhouse",
      "oppId": "b5ARnVg7OngG1a0%2fJuMZTHluwgNFe%2bN5esQIIKsEOUY%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "vVbzrt3s13DS0y6OhAtP0b%2bjs97bujLpxNYc43TNbMU%3d",
      "team": "Rush Hour ATL",
      "score": "7",
      "oppScore": "9",
      "opp": "Charleston Heat Stroke",
      "oppId": "quSAXJHtjRXSWAHbdztMDD8ftXgRqlTrsEfTBm793Nk%3d"
    }
  ],
  "%2f7JeYXylBxa9Xw5f5LSwua5DyAomcfJ7a7MZDP7nSUs%3d": [
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "UZCY80gidCvmZDhbOHgwRt1qMSp9rn8oWsgbgJVNuIs%3d",
      "team": "Alliance",
      "score": "9",
      "oppScore": "8",
      "opp": "Dyno",
      "oppId": "fIw72i6bkbJnjTbK02aFLpJ9Qy2lCUVWlRBvI%2b8UmIc%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "nc2S6obUa2lEnr6H%2fQ6U34PVvK%2fR4UX5SMplK1QTH9g%3d",
      "team": "Alliance",
      "score": "10",
      "oppScore": "8",
      "opp": "Battleship",
      "oppId": "qDX5A%2fquM05Kah9vXHSYFrstrvRQbJhmA8cR4rz1ar8%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "uKofBIRyS0iQA9meg8hs%2bqS841FCug4NRFp0%2ffvc0KU%3d",
      "team": "Alliance",
      "score": "9",
      "oppScore": "5",
      "opp": "Villains",
      "oppId": "Ym1ak6XomGej8LcWE974vySOBZfTF88e4wX%2bD3N6JX0%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "rZYfy%2fEY1O3cuJk1OThp%2b1EMVoLsqYAZsxXZhEEkD68%3d",
      "team": "Alliance",
      "score": "9",
      "oppScore": "8",
      "opp": "Rush Hour ATL",
      "oppId": "LdH2zJ494O%2bxdz6p92IsLTMaAmjgf6aP%2fBvM9Lr1RaQ%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "H1Ph82omXXyHzoaIsISaiNyXVmSUWl6Fp2QxAcc85Ek%3d",
      "team": "Alliance",
      "score": "9",
      "oppScore": "8",
      "opp": "Battleship",
      "oppId": "qDX5A%2fquM05Kah9vXHSYFrstrvRQbJhmA8cR4rz1ar8%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "76Hsrvrx1kfLIeDUd0g71EFk6gVN7b3P6XR7g8gdjjk%3d",
      "team": "Alliance",
      "score": "8",
      "oppScore": "5",
      "opp": "Rush Hour ATL",
      "oppId": "LdH2zJ494O%2bxdz6p92IsLTMaAmjgf6aP%2fBvM9Lr1RaQ%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "Zb6R1DZM51lqWZaFdqbU5FAGBzxjN6j3Pbv1PYiKnqI%3d",
      "team": "Alliance",
      "score": "10",
      "oppScore": "8",
      "opp": "Lost Boys",
      "oppId": "aNMyR0uAJNwnidjeClZAMqixGjtlQqA6k7CiZP1R0%2bM%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "2EMB9LIoVapXCGA3HFH%2bo4f8Xk0Yz6xovJszTHrdtGM%3d",
      "team": "Alliance",
      "score": "9",
      "oppScore": "8",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "RGlUnDpd1VWfVOoXVM4Xf4Rxrx3UPjkef4u%2bphziC9c%3d",
      "team": "Alliance",
      "score": "9",
      "oppScore": "8",
      "opp": "Delirium",
      "oppId": "0yudJHn8N3NbInYx1wqMxoticHnPBt4YkAfZZAUc7U8%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "2D5%2fRYoprSTqBjariU8maQa6GzZ0S0BJmVNpAQHEiTE%3d",
      "team": "Alliance",
      "score": "13",
      "oppScore": "5",
      "opp": "Tennessee Folklore",
      "oppId": "z7Hwsz316C%2bFf9Cg1gKP3NGBSqxMqnFjQRbeyMUhUD4%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "Jgml5TPOp3kYAAZabPoFe4llpVNsUwCy5%2b52Y6ThIL4%3d",
      "team": "Alliance",
      "score": "13",
      "oppScore": "3",
      "opp": "Tampa Tempest",
      "oppId": "hQXi92LAmE32qiZ%2fpZRYvkIBTehvd13BZlvBS7aR3%2fQ%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "2lskUGgG6ngBRnXcod6tiFruBwq%2f9neQtqSaCqoMHLU%3d",
      "team": "Alliance",
      "score": "10",
      "oppScore": "11",
      "opp": "UpRoar",
      "oppId": "JYJdk5dDNlc6X6evPtQ6K9GWMwDy1xmzavYjS6uyHaE%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "bxyvnjNGIa%2bHbBwaKQ1SWkT3qkwRtLDjLUYb0XBv%2fVQ%3d",
      "team": "Alliance",
      "score": "9",
      "oppScore": "13",
      "opp": "Lost Boys",
      "oppId": "aNMyR0uAJNwnidjeClZAMqixGjtlQqA6k7CiZP1R0%2bM%3d"
    },
    {
      "date": "August 20",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "1RLyKfdAmsOoso3gpvBRhvrSlqCuuo%2b%2f3Z%2fmnGUVol8%3d",
      "team": "Alliance",
      "score": "7",
      "oppScore": "13",
      "opp": "Tennessee Folklore",
      "oppId": "z7Hwsz316C%2bFf9Cg1gKP3NGBSqxMqnFjQRbeyMUhUD4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "5DPjcDlI%2f8gUAsNOJKlmjHkl66q1s%2fo4NceScA%2fzvMw%3d",
      "team": "Alliance",
      "score": "13",
      "oppScore": "8",
      "opp": "Atlanta Arson",
      "oppId": "oAgat63CZNVga8weCR1ui3IB9YvjaA54miCvE%2f%2bRb5g%3d"
    },
    {
      "date": "August 20",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "5axhaC4kT%2bvRg%2fGGuxokC3apWfDcfbkqfCVLWdGEECg%3d",
      "team": "Alliance",
      "score": "13",
      "oppScore": "11",
      "opp": "Hooch",
      "oppId": "OmOeVI6iSB0tHCNLK01vsOOpZmXF97z%2b2x81ifAm8Vs%3d"
    },
    {
      "date": "August 20",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "BLVLO%2fU%2fvF9d%2bzRTZT6WGV61Wb1sLdg6El2Xulivo1A%3d",
      "team": "Alliance",
      "score": "9",
      "oppScore": "13",
      "opp": "Delirium",
      "oppId": "0yudJHn8N3NbInYx1wqMxoticHnPBt4YkAfZZAUc7U8%3d"
    },
    {
      "date": "August 21",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "Gp1X2g2o%2fnOHn0SZQ3xSHRLKzMnflp8JFnJWm3NC5Ow%3d",
      "team": "Alliance",
      "score": "12",
      "oppScore": "10",
      "opp": "Atlanta Arson",
      "oppId": "oAgat63CZNVga8weCR1ui3IB9YvjaA54miCvE%2f%2bRb5g%3d"
    },
    {
      "date": "August 21",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "sTPcBsSiP2tjN%2fyarCXn%2bXgREGBMgLWF7rx%2fhAw2ZWc%3d",
      "team": "Alliance",
      "score": "9",
      "oppScore": "13",
      "opp": "Delirium",
      "oppId": "0yudJHn8N3NbInYx1wqMxoticHnPBt4YkAfZZAUc7U8%3d"
    },
    {
      "date": "August 21",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "X5vR7YN%2bh7qv0yiy5YUPZRMuafrB9KRI%2fKSwE9PB4ro%3d",
      "team": "Alliance",
      "score": "6",
      "oppScore": "9",
      "opp": "Hooch",
      "oppId": "OmOeVI6iSB0tHCNLK01vsOOpZmXF97z%2b2x81ifAm8Vs%3d"
    }
  ],
  "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d": [
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "2zrfjSMVgPNDsD%2b2rLyy0lBCdRzON58pAaDdUR6qlUI%3d",
      "team": "Raptor",
      "score": "11",
      "oppScore": "9",
      "opp": "Nashville Mudcats",
      "oppId": "m1V5goSU%2bO1BR4XRhH%2fyGq44j%2f4hNKcb%2bBApPx5jVzQ%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "nCxzF04KFVSHXlj0%2beGd%2bBjzcU0E%2fuYsBRxf1wEweC0%3d",
      "team": "Raptor",
      "score": "11",
      "oppScore": "7",
      "opp": "Second Nature",
      "oppId": "9QN%2fOffYfC%2f0mj9CpLqFRthKvgHjrHj%2bM2%2fY44PMiCE%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "hIT6spNxnGeaxWhnAdQU%2frUu6h6UpHwXIaE%2b1xcuWGM%3d",
      "team": "Raptor",
      "score": "10",
      "oppScore": "11",
      "opp": "Rush Hour ATL",
      "oppId": "LdH2zJ494O%2bxdz6p92IsLTMaAmjgf6aP%2fBvM9Lr1RaQ%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "GZRc0bTtovDERlBb62JOrrF3uvGKfp1y6tEs9o%2bWvpM%3d",
      "team": "Raptor",
      "score": "5",
      "oppScore": "11",
      "opp": "Dyno",
      "oppId": "fIw72i6bkbJnjTbK02aFLpJ9Qy2lCUVWlRBvI%2b8UmIc%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "rCj5u%2bS51bamQlWi3XF5m1GxMh74pW5gpiK7FEOn6EE%3d",
      "team": "Raptor",
      "score": "5",
      "oppScore": "12",
      "opp": "Dyno",
      "oppId": "fIw72i6bkbJnjTbK02aFLpJ9Qy2lCUVWlRBvI%2b8UmIc%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "vSL5VQHzwTtVQWcSrMGWDpe26tte7yfOesvI3x77F58%3d",
      "team": "Raptor",
      "score": "10",
      "oppScore": "11",
      "opp": "Second Nature",
      "oppId": "9QN%2fOffYfC%2f0mj9CpLqFRthKvgHjrHj%2bM2%2fY44PMiCE%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "xU%2fGEXUaEqAGXLaNwFmqdHGddF8KgEhTMn9uerhR55A%3d",
      "team": "Raptor",
      "score": "8",
      "oppScore": "9",
      "opp": "Battleship",
      "oppId": "qDX5A%2fquM05Kah9vXHSYFrstrvRQbJhmA8cR4rz1ar8%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "2EMB9LIoVapXCGA3HFH%2bo4f8Xk0Yz6xovJszTHrdtGM%3d",
      "team": "Raptor",
      "score": "8",
      "oppScore": "9",
      "opp": "Alliance",
      "oppId": "%2f7JeYXylBxa9Xw5f5LSwua5DyAomcfJ7a7MZDP7nSUs%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "KxvDwrQoA7uUJMig9QXiy9PeTdxpehjF8v7Pci9qHlk%3d",
      "team": "Raptor",
      "score": "6",
      "oppScore": "7",
      "opp": "Atlanta Arson",
      "oppId": "oAgat63CZNVga8weCR1ui3IB9YvjaA54miCvE%2f%2bRb5g%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "bP9s0vsNXwM%2b0BuB%2bMGyfIgwyr7LfRnDcRPlO6bocOc%3d",
      "team": "Raptor",
      "score": "8",
      "oppScore": "13",
      "opp": "Tennessee Folklore",
      "oppId": "z7Hwsz316C%2bFf9Cg1gKP3NGBSqxMqnFjQRbeyMUhUD4%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "0ww6MIp8mQEKb5N71xclj2lOMDDFoVfd0aeFJJwFh1w%3d",
      "team": "Raptor",
      "score": "8",
      "oppScore": "13",
      "opp": "Rush Hour ATL",
      "oppId": "LdH2zJ494O%2bxdz6p92IsLTMaAmjgf6aP%2fBvM9Lr1RaQ%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "B2RlKFEGj3z%2b7L22Au9TVzmbs1HkYYdM6anNqgi%2bWGA%3d",
      "team": "Raptor",
      "score": "W",
      "oppScore": "F",
      "opp": "Second Nature",
      "oppId": "9QN%2fOffYfC%2f0mj9CpLqFRthKvgHjrHj%2bM2%2fY44PMiCE%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "fYrmKoPxLGHcXXsEXqfZceLpJe7mM%2bHOVvPEnfekbHg%3d",
      "team": "Raptor",
      "score": "9",
      "oppScore": "10",
      "opp": "Black Lung",
      "oppId": "olaZlUwWblgF%2b%2b2nRJgbWJ2tj6QzPm5aIO7kfbpnA58%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "cWJTzf2zm9Ss6uBGSefUg5Pvuii9HQb%2bcRfECNQ1r%2fY%3d",
      "team": "Raptor",
      "score": "6",
      "oppScore": "10",
      "opp": "Space Cowboys",
      "oppId": "3%2bZ1uFiVej3zLASHWkgqXkJ7hNQ98n84b2FvR4T3pQA%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "8Qs27sFcPYIXIlxTOvx0QydEYFLDznoMlw8SXe5Z94M%3d",
      "team": "Raptor",
      "score": "11",
      "oppScore": "8",
      "opp": "Triumph",
      "oppId": "wAZDt10Q5MoiqMIAGOgPxedaQ2KA3QCSPn7hC8W%2bn5s%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "SDAzlaSf4sKq8utR7jJ9ESrjuyZ%2f%2bxZe23hASoELEUY%3d",
      "team": "Raptor",
      "score": "10",
      "oppScore": "11",
      "opp": "baNC",
      "oppId": "uce10clG%2fWf3GrKUxwKToSDTqXSp6FS58Fb50TN%2fPwU%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "exJRjoYLGNNEzKGNfrzFKKmiiVv5llWQxOU9y44iGas%3d",
      "team": "Raptor",
      "score": "10",
      "oppScore": "11",
      "opp": "Nashville Mudcats",
      "oppId": "m1V5goSU%2bO1BR4XRhH%2fyGq44j%2f4hNKcb%2bBApPx5jVzQ%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "kWNGa0XCoZRRZDJupi81Vd8qXOLoF4TfCpge0KupuR0%3d",
      "team": "Raptor",
      "score": "7",
      "oppScore": "11",
      "opp": "Rush Hour ATL",
      "oppId": "LdH2zJ494O%2bxdz6p92IsLTMaAmjgf6aP%2fBvM9Lr1RaQ%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "h7WbS%2bFuGGR1dJknwhRvTHeQ4qRTWLMBNFE8b5eV8S8%3d",
      "team": "Raptor",
      "score": "13",
      "oppScore": "6",
      "opp": "Queen City Kings",
      "oppId": "95yP4SyRqRX7HgLtZ9jNth95JPB163KSMqpgMxymy8g%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "y6JgVYLgWNpkO7sZw%2bIqPA%2fQuVq0QGn65vacbb78fbY%3d",
      "team": "Raptor",
      "score": "13",
      "oppScore": "6",
      "opp": "Nashville Mudcats",
      "oppId": "m1V5goSU%2bO1BR4XRhH%2fyGq44j%2f4hNKcb%2bBApPx5jVzQ%3d"
    },
    {
      "date": "August 06",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "m5WPewYf0XWhXMrGGeSVNZLExUOK9%2fQ6nNp%2f%2fIOucXI%3d",
      "team": "Raptor",
      "score": "13",
      "oppScore": "12",
      "opp": "Vicious Cycle",
      "oppId": "iThg94F9Gjn72NSYiusu2Lt7M1KILkpxIeQ2vLs8yes%3d"
    },
    {
      "date": "August 06",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "mMSpuVFpbbMWf7zAkt0pejQgg1RzsO8gOWnoeKgq%2fMc%3d",
      "team": "Raptor",
      "score": "9",
      "oppScore": "10",
      "opp": "El Ni\u00f1o",
      "oppId": "LE1oc%2fdB%2f%2f8We2RewjqcXWGVvpctpO4IKZbpyJ8TCpw%3d"
    },
    {
      "date": "August 06",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "A1ez05FOUgpgzRySknNJmk3b8B0f9mmleaMRVGIImMw%3d",
      "team": "Raptor",
      "score": "13",
      "oppScore": "6",
      "opp": "Reptar",
      "oppId": "zO5MdCdfs1wmlIOMRsdDti3ewmeXeC48aUnnQqdSG9o%3d"
    },
    {
      "date": "August 07",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "ID0Y00RJkPLQIigOBUPvdC3wM7szHlCrCgQ9yJ8uZDo%3d",
      "team": "Raptor",
      "score": "13",
      "oppScore": "9",
      "opp": "ScooberDivers",
      "oppId": "EQ5VJcEYXXeAviHK3zWePBbmNmZFDh9f%2fMkKpC9pY4Y%3d"
    },
    {
      "date": "August 07",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "ZtICzdL5TcZbtXslGQ9ScKABpEstkZgeBbXw%2bTmvhzc%3d",
      "team": "Raptor",
      "score": "15",
      "oppScore": "5",
      "opp": "Tampa Tempest",
      "oppId": "hQXi92LAmE32qiZ%2fpZRYvkIBTehvd13BZlvBS7aR3%2fQ%3d"
    },
    {
      "date": "August 07",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "%2byRunQFSqvhniEQHXQ9A%2bpBiKojQJq4gGOr23uZTWR8%3d",
      "team": "Raptor",
      "score": "9",
      "oppScore": "10",
      "opp": "Southside",
      "oppId": "jnzIw1u16R54lBpk1gV3qvXlKsIvelmRZNPjjDpRKXo%3d"
    },
    {
      "date": "August 07",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "9z0m2OMZAPPFLdKN52gZ3f3s0KEllaayYBtEqJ6mUOI%3d",
      "team": "Raptor",
      "score": "0",
      "oppScore": "0",
      "opp": "Southside",
      "oppId": "jnzIw1u16R54lBpk1gV3qvXlKsIvelmRZNPjjDpRKXo%3d"
    }
  ],
  "fIw72i6bkbJnjTbK02aFLpJ9Qy2lCUVWlRBvI%2b8UmIc%3d": [
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "UZCY80gidCvmZDhbOHgwRt1qMSp9rn8oWsgbgJVNuIs%3d",
      "team": "Dyno",
      "score": "8",
      "oppScore": "9",
      "opp": "Alliance",
      "oppId": "%2f7JeYXylBxa9Xw5f5LSwua5DyAomcfJ7a7MZDP7nSUs%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "tOhnGadvUhO9HXi9RE7gQALuGqoylkxMY4u7TDEIeG8%3d",
      "team": "Dyno",
      "score": "11",
      "oppScore": "3",
      "opp": "Villains",
      "oppId": "Ym1ak6XomGej8LcWE974vySOBZfTF88e4wX%2bD3N6JX0%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "ZNx1pO1zNEPd9k1zdzz62%2fRXaHVirzkMpNebHuUVUvM%3d",
      "team": "Dyno",
      "score": "9",
      "oppScore": "8",
      "opp": "Battleship",
      "oppId": "qDX5A%2fquM05Kah9vXHSYFrstrvRQbJhmA8cR4rz1ar8%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "GZRc0bTtovDERlBb62JOrrF3uvGKfp1y6tEs9o%2bWvpM%3d",
      "team": "Dyno",
      "score": "11",
      "oppScore": "5",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "3%2b6CPoTZHNrjkoJy8ak56qnVqTg6pxXco5UxrqQA0eQ%3d",
      "team": "Dyno",
      "score": "6",
      "oppScore": "10",
      "opp": "Rush Hour ATL",
      "oppId": "LdH2zJ494O%2bxdz6p92IsLTMaAmjgf6aP%2fBvM9Lr1RaQ%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "rCj5u%2bS51bamQlWi3XF5m1GxMh74pW5gpiK7FEOn6EE%3d",
      "team": "Dyno",
      "score": "12",
      "oppScore": "5",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "mNYfZkAsVu7G6Rxwa4IKZZthYLI%2b4bKJlf3%2bwLHczQc%3d",
      "team": "Dyno",
      "score": "6",
      "oppScore": "13",
      "opp": "Lost Boys",
      "oppId": "aNMyR0uAJNwnidjeClZAMqixGjtlQqA6k7CiZP1R0%2bM%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "dQwDu%2ffythsHGcckkEy1K2FyYOn0Ig2BSg2OJklC7XA%3d",
      "team": "Dyno",
      "score": "7",
      "oppScore": "4",
      "opp": "Atlanta Arson",
      "oppId": "oAgat63CZNVga8weCR1ui3IB9YvjaA54miCvE%2f%2bRb5g%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "dDd89SwVUtUyjOUjaXp8Wx34xuAqJ1zmGpdBp%2bstKhI%3d",
      "team": "Dyno",
      "score": "10",
      "oppScore": "13",
      "opp": "UpRoar",
      "oppId": "JYJdk5dDNlc6X6evPtQ6K9GWMwDy1xmzavYjS6uyHaE%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "Ju7ZzMHFKQrGaFalZLb2pspsCME%2bF8CIUw7QEj8Rb34%3d",
      "team": "Dyno",
      "score": "8",
      "oppScore": "3",
      "opp": "Yee-Haw",
      "oppId": "NpDxoDV0mpf0Hc%2fBzLLx0bhgfIsOKi%2b2AIQMCptTcck%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "HtsIQvSA2ZsCtZ877PKHBWNt0AdOk%2b9PaFY7SYBEujg%3d",
      "team": "Dyno",
      "score": "8",
      "oppScore": "6",
      "opp": "Space Cowboys",
      "oppId": "3%2bZ1uFiVej3zLASHWkgqXkJ7hNQ98n84b2FvR4T3pQA%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "N%2ffGDffSP6CE4ci8zRZWu67UERRTCex0PILeDE%2fhW64%3d",
      "team": "Dyno",
      "score": "11",
      "oppScore": "8",
      "opp": "Black Lung",
      "oppId": "olaZlUwWblgF%2b%2b2nRJgbWJ2tj6QzPm5aIO7kfbpnA58%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "m9lFDzVghxUDN2SV4jR21t4NHGcNWHZ%2b1d6wgp0uH7o%3d",
      "team": "Dyno",
      "score": "13",
      "oppScore": "7",
      "opp": "Atlanta Arson",
      "oppId": "oAgat63CZNVga8weCR1ui3IB9YvjaA54miCvE%2f%2bRb5g%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "zF5pnvXU0d6%2bPJJ9uHbSI9XrUU%2fx70xZieshsDvsy84%3d",
      "team": "Dyno",
      "score": "13",
      "oppScore": "9",
      "opp": "Rush Hour ATL",
      "oppId": "LdH2zJ494O%2bxdz6p92IsLTMaAmjgf6aP%2fBvM9Lr1RaQ%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "eCqzNzeNSUfR55lDOqrhElt464Xlq02X2KtT0B8Ezmk%3d",
      "team": "Dyno",
      "score": "11",
      "oppScore": "4",
      "opp": "Scoop",
      "oppId": "Rj8mJfKJry2xyWaxTIh5HdXGLaTMxr7%2fXjyL4uJET2o%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "TKghAeos5oVs%2byO2vz%2fYhVs3thZVNLpq%2bLMnAGvjFJg%3d",
      "team": "Dyno",
      "score": "3",
      "oppScore": "11",
      "opp": "Brickhouse",
      "oppId": "b5ARnVg7OngG1a0%2fJuMZTHluwgNFe%2bN5esQIIKsEOUY%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "AoOoGB%2fHm0KK7YM8ZtJOYe%2bQpmqEMsjZ%2fjzO5iv9%2bF0%3d",
      "team": "Dyno",
      "score": "11",
      "oppScore": "0",
      "opp": "Queen City Kings",
      "oppId": "95yP4SyRqRX7HgLtZ9jNth95JPB163KSMqpgMxymy8g%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "TDXiVxibZjXeWruuq8KYtXa41WEP%2bZQ17gq0adxMQVw%3d",
      "team": "Dyno",
      "score": "11",
      "oppScore": "6",
      "opp": "Tennessee Folklore",
      "oppId": "z7Hwsz316C%2bFf9Cg1gKP3NGBSqxMqnFjQRbeyMUhUD4%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "MxPFfSUMqyDNEgnUF5uqdMcBZGHmi%2f6gwCeQQasTBFA%3d",
      "team": "Dyno",
      "score": "8",
      "oppScore": "9",
      "opp": "Charleston Heat Stroke",
      "oppId": "quSAXJHtjRXSWAHbdztMDD8ftXgRqlTrsEfTBm793Nk%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "EnRvC16WqsVeLX7NVxUM0pdOQ6OXMaxBAEsyKCKOGqY%3d",
      "team": "Dyno",
      "score": "12",
      "oppScore": "8",
      "opp": "Triumph",
      "oppId": "wAZDt10Q5MoiqMIAGOgPxedaQ2KA3QCSPn7hC8W%2bn5s%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "PhpsOTTt1QVQWJTfU1hUzXciUq5lZeTxtJbAkfBsDM8%3d",
      "team": "Dyno",
      "score": "F",
      "oppScore": "W",
      "opp": "Space Cowboys",
      "oppId": "3%2bZ1uFiVej3zLASHWkgqXkJ7hNQ98n84b2FvR4T3pQA%3d"
    }
  ],
  "qDX5A%2fquM05Kah9vXHSYFrstrvRQbJhmA8cR4rz1ar8%3d": [
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "g9GdzNhSxUfDeAgldmJaX%2bdcrRpQNXYjQ%2bffEYWpA8E%3d",
      "team": "Battleship",
      "score": "11",
      "oppScore": "8",
      "opp": "Villains",
      "oppId": "Ym1ak6XomGej8LcWE974vySOBZfTF88e4wX%2bD3N6JX0%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "nc2S6obUa2lEnr6H%2fQ6U34PVvK%2fR4UX5SMplK1QTH9g%3d",
      "team": "Battleship",
      "score": "8",
      "oppScore": "10",
      "opp": "Alliance",
      "oppId": "%2f7JeYXylBxa9Xw5f5LSwua5DyAomcfJ7a7MZDP7nSUs%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "ZNx1pO1zNEPd9k1zdzz62%2fRXaHVirzkMpNebHuUVUvM%3d",
      "team": "Battleship",
      "score": "8",
      "oppScore": "9",
      "opp": "Dyno",
      "oppId": "fIw72i6bkbJnjTbK02aFLpJ9Qy2lCUVWlRBvI%2b8UmIc%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "RP9PpJwYWeHqrwa5x355qNxawCfdSZnxtniAYnU4QsI%3d",
      "team": "Battleship",
      "score": "11",
      "oppScore": "6",
      "opp": "Second Nature",
      "oppId": "9QN%2fOffYfC%2f0mj9CpLqFRthKvgHjrHj%2bM2%2fY44PMiCE%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "H1Ph82omXXyHzoaIsISaiNyXVmSUWl6Fp2QxAcc85Ek%3d",
      "team": "Battleship",
      "score": "8",
      "oppScore": "9",
      "opp": "Alliance",
      "oppId": "%2f7JeYXylBxa9Xw5f5LSwua5DyAomcfJ7a7MZDP7nSUs%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "CrDSUb4vLQNvZwS%2b1tjOi1uPfWb7uTlZeBu7n5WbUIo%3d",
      "team": "Battleship",
      "score": "7",
      "oppScore": "8",
      "opp": "Atlanta Arson",
      "oppId": "oAgat63CZNVga8weCR1ui3IB9YvjaA54miCvE%2f%2bRb5g%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "xU%2fGEXUaEqAGXLaNwFmqdHGddF8KgEhTMn9uerhR55A%3d",
      "team": "Battleship",
      "score": "9",
      "oppScore": "8",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    }
  ],
  "oAgat63CZNVga8weCR1ui3IB9YvjaA54miCvE%2f%2bRb5g%3d": [
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "dRLkzE5tmwv4J%2bFe1TVpHhJw9oMUCEQRK%2bZiUDKVuec%3d",
      "team": "Atlanta Arson",
      "score": "11",
      "oppScore": "7",
      "opp": "Music City Mafia",
      "oppId": "XPgFkg08zZAYqCVWIFQ5JStHxHWnl5AdE3mX%2bAo4nc4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "HI4wm%2bj93zOglL%2bWNnn4co3vUvtEinhpZJNRf3kLS44%3d",
      "team": "Atlanta Arson",
      "score": "7",
      "oppScore": "11",
      "opp": "Delirium",
      "oppId": "0yudJHn8N3NbInYx1wqMxoticHnPBt4YkAfZZAUc7U8%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "aVP53tdk%2fHAeuLHOdbVRJdFQjpShsjNHxv9TwD56d14%3d",
      "team": "Atlanta Arson",
      "score": "11",
      "oppScore": "6",
      "opp": "Clumsy by Nature",
      "oppId": "OM78oWF5JvWWpnZl%2fWdqyT9JlsF5FmPVL89v9mQzr%2bU%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "jYnFVBFaQ98M7QzHeH%2fn%2f83UzWiMkWBAhw2M7Wd%2fguw%3d",
      "team": "Atlanta Arson",
      "score": "5",
      "oppScore": "11",
      "opp": "Lost Boys",
      "oppId": "aNMyR0uAJNwnidjeClZAMqixGjtlQqA6k7CiZP1R0%2bM%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "F0n7jsFDD8Ld9Bdb1OAVujeMYdBbGjqMS%2bdv2QmT9b4%3d",
      "team": "Atlanta Arson",
      "score": "13",
      "oppScore": "10",
      "opp": "Second Nature",
      "oppId": "9QN%2fOffYfC%2f0mj9CpLqFRthKvgHjrHj%2bM2%2fY44PMiCE%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "Q4kWkyY57fwllEaZV%2fac2eHfPU1VPcgdNFnYCMhUlxM%3d",
      "team": "Atlanta Arson",
      "score": "9",
      "oppScore": "12",
      "opp": "Rush Hour ATL",
      "oppId": "LdH2zJ494O%2bxdz6p92IsLTMaAmjgf6aP%2fBvM9Lr1RaQ%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "CrDSUb4vLQNvZwS%2b1tjOi1uPfWb7uTlZeBu7n5WbUIo%3d",
      "team": "Atlanta Arson",
      "score": "8",
      "oppScore": "7",
      "opp": "Battleship",
      "oppId": "qDX5A%2fquM05Kah9vXHSYFrstrvRQbJhmA8cR4rz1ar8%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "dQwDu%2ffythsHGcckkEy1K2FyYOn0Ig2BSg2OJklC7XA%3d",
      "team": "Atlanta Arson",
      "score": "4",
      "oppScore": "7",
      "opp": "Dyno",
      "oppId": "fIw72i6bkbJnjTbK02aFLpJ9Qy2lCUVWlRBvI%2b8UmIc%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "Dp4f9XZcBEjDZim3mOGSRMopgaRGfut2QvPX%2fBkbuzg%3d",
      "team": "Atlanta Arson",
      "score": "12",
      "oppScore": "11",
      "opp": "Tennessee Folklore",
      "oppId": "z7Hwsz316C%2bFf9Cg1gKP3NGBSqxMqnFjQRbeyMUhUD4%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "KxvDwrQoA7uUJMig9QXiy9PeTdxpehjF8v7Pci9qHlk%3d",
      "team": "Atlanta Arson",
      "score": "7",
      "oppScore": "6",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "uNoXjnjna0IUk5iyrrMocAyb%2bHXxZaEPMV4d%2fYNYlQI%3d",
      "team": "Atlanta Arson",
      "score": "6",
      "oppScore": "10",
      "opp": "Rush Hour ATL",
      "oppId": "LdH2zJ494O%2bxdz6p92IsLTMaAmjgf6aP%2fBvM9Lr1RaQ%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "AvL9idkQ0e5Swl9rlQQ6Gmb7O9z1Pznr%2fIqm6rTnY1c%3d",
      "team": "Atlanta Arson",
      "score": "5",
      "oppScore": "13",
      "opp": "Delirium",
      "oppId": "0yudJHn8N3NbInYx1wqMxoticHnPBt4YkAfZZAUc7U8%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "m9lFDzVghxUDN2SV4jR21t4NHGcNWHZ%2b1d6wgp0uH7o%3d",
      "team": "Atlanta Arson",
      "score": "7",
      "oppScore": "13",
      "opp": "Dyno",
      "oppId": "fIw72i6bkbJnjTbK02aFLpJ9Qy2lCUVWlRBvI%2b8UmIc%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "wX6%2feCL9GRE%2bF0qpIP1cRC%2bwS4pf4n%2bzcF4GdVCBKME%3d",
      "team": "Atlanta Arson",
      "score": "10",
      "oppScore": "9",
      "opp": "Space Cowboys",
      "oppId": "3%2bZ1uFiVej3zLASHWkgqXkJ7hNQ98n84b2FvR4T3pQA%3d"
    },
    {
      "date": "August 20",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "n1YcR2ZzHV0nrv5EEZf5hfh60nBHgN4n9%2blrc8D6dp8%3d",
      "team": "Atlanta Arson",
      "score": "12",
      "oppScore": "13",
      "opp": "Tennessee Folklore",
      "oppId": "z7Hwsz316C%2bFf9Cg1gKP3NGBSqxMqnFjQRbeyMUhUD4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "lwsvoGHF%2fCBDmPwD3LRZjX9hpufp143iXe0OjpNhb%2fg%3d",
      "team": "Atlanta Arson",
      "score": "5",
      "oppScore": "13",
      "opp": "Delirium",
      "oppId": "0yudJHn8N3NbInYx1wqMxoticHnPBt4YkAfZZAUc7U8%3d"
    },
    {
      "date": "August 20",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "5DPjcDlI%2f8gUAsNOJKlmjHkl66q1s%2fo4NceScA%2fzvMw%3d",
      "team": "Atlanta Arson",
      "score": "8",
      "oppScore": "13",
      "opp": "Alliance",
      "oppId": "%2f7JeYXylBxa9Xw5f5LSwua5DyAomcfJ7a7MZDP7nSUs%3d"
    },
    {
      "date": "August 20",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "kz6yxmht6Cp49EYvUFvKIAacUopNWkifUcdW0m%2birbg%3d",
      "team": "Atlanta Arson",
      "score": "9",
      "oppScore": "11",
      "opp": "Hooch",
      "oppId": "OmOeVI6iSB0tHCNLK01vsOOpZmXF97z%2b2x81ifAm8Vs%3d"
    },
    {
      "date": "August 21",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "Gp1X2g2o%2fnOHn0SZQ3xSHRLKzMnflp8JFnJWm3NC5Ow%3d",
      "team": "Atlanta Arson",
      "score": "10",
      "oppScore": "12",
      "opp": "Alliance",
      "oppId": "%2f7JeYXylBxa9Xw5f5LSwua5DyAomcfJ7a7MZDP7nSUs%3d"
    }
  ],
  "XPgFkg08zZAYqCVWIFQ5JStHxHWnl5AdE3mX%2bAo4nc4%3d": [
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "FS7A9V4HAkZMj0L%2fZZVNS94BdDStE9fwuWsS1eq4SRg%3d",
      "team": "Music City Mafia",
      "score": "7",
      "oppScore": "11",
      "opp": "Delirium",
      "oppId": "0yudJHn8N3NbInYx1wqMxoticHnPBt4YkAfZZAUc7U8%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "dRLkzE5tmwv4J%2bFe1TVpHhJw9oMUCEQRK%2bZiUDKVuec%3d",
      "team": "Music City Mafia",
      "score": "7",
      "oppScore": "11",
      "opp": "Atlanta Arson",
      "oppId": "oAgat63CZNVga8weCR1ui3IB9YvjaA54miCvE%2f%2bRb5g%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "3UChSn5YFphCmz7aPB%2fdeoIhyE1wfosn4gXhyYQyetA%3d",
      "team": "Music City Mafia",
      "score": "11",
      "oppScore": "0",
      "opp": "Clumsy by Nature",
      "oppId": "OM78oWF5JvWWpnZl%2fWdqyT9JlsF5FmPVL89v9mQzr%2bU%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "5pGz8hyS5IjW7daRccdK%2btLyAiW6Qw%2bnAAEzG5N8aN0%3d",
      "team": "Music City Mafia",
      "score": "2",
      "oppScore": "11",
      "opp": "Lost Boys",
      "oppId": "aNMyR0uAJNwnidjeClZAMqixGjtlQqA6k7CiZP1R0%2bM%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "G1XS8ANwElJq%2fZ%2f58oTLl%2bqqNnRuhS9eTjhzcPt%2fpt8%3d",
      "team": "Music City Mafia",
      "score": "7",
      "oppScore": "11",
      "opp": "Villains",
      "oppId": "Ym1ak6XomGej8LcWE974vySOBZfTF88e4wX%2bD3N6JX0%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "KlVfzJ1Cls3iBRmN1DeJRFemAMZ%2bUQisp%2bEesdZ9IU0%3d",
      "team": "Music City Mafia",
      "score": "13",
      "oppScore": "3",
      "opp": "Clumsy by Nature",
      "oppId": "OM78oWF5JvWWpnZl%2fWdqyT9JlsF5FmPVL89v9mQzr%2bU%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "Y%2fSIOhdGLg09P1s0knnGtBySCM7QsRrO3uqDxo3F1Ac%3d",
      "team": "Music City Mafia",
      "score": "5",
      "oppScore": "7",
      "opp": "Nashville Mudcats",
      "oppId": "m1V5goSU%2bO1BR4XRhH%2fyGq44j%2f4hNKcb%2bBApPx5jVzQ%3d"
    }
  ],
  "Ym1ak6XomGej8LcWE974vySOBZfTF88e4wX%2bD3N6JX0%3d": [
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "g9GdzNhSxUfDeAgldmJaX%2bdcrRpQNXYjQ%2bffEYWpA8E%3d",
      "team": "Villains",
      "score": "8",
      "oppScore": "11",
      "opp": "Battleship",
      "oppId": "qDX5A%2fquM05Kah9vXHSYFrstrvRQbJhmA8cR4rz1ar8%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "tOhnGadvUhO9HXi9RE7gQALuGqoylkxMY4u7TDEIeG8%3d",
      "team": "Villains",
      "score": "3",
      "oppScore": "11",
      "opp": "Dyno",
      "oppId": "fIw72i6bkbJnjTbK02aFLpJ9Qy2lCUVWlRBvI%2b8UmIc%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "uKofBIRyS0iQA9meg8hs%2bqS841FCug4NRFp0%2ffvc0KU%3d",
      "team": "Villains",
      "score": "5",
      "oppScore": "9",
      "opp": "Alliance",
      "oppId": "%2f7JeYXylBxa9Xw5f5LSwua5DyAomcfJ7a7MZDP7nSUs%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "w%2bGDuYaEG4Mua5xchJULl2QSnMRrxmF5IK9kjs6nYo0%3d",
      "team": "Villains",
      "score": "10",
      "oppScore": "11",
      "opp": "Nashville Mudcats",
      "oppId": "m1V5goSU%2bO1BR4XRhH%2fyGq44j%2f4hNKcb%2bBApPx5jVzQ%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "G1XS8ANwElJq%2fZ%2f58oTLl%2bqqNnRuhS9eTjhzcPt%2fpt8%3d",
      "team": "Villains",
      "score": "11",
      "oppScore": "7",
      "opp": "Music City Mafia",
      "oppId": "XPgFkg08zZAYqCVWIFQ5JStHxHWnl5AdE3mX%2bAo4nc4%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "ekoI36li4GWLJACI7uEFXg91oZCR1xu24lOQb6JXUd8%3d",
      "team": "Villains",
      "score": "8",
      "oppScore": "7",
      "opp": "Nashville Mudcats",
      "oppId": "m1V5goSU%2bO1BR4XRhH%2fyGq44j%2f4hNKcb%2bBApPx5jVzQ%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "BiaTOo4gFQUgmO%2fyOUo3Y1y%2bUtcvw9SDC44Ye05xL0M%3d",
      "team": "Villains",
      "score": "13",
      "oppScore": "0",
      "opp": "Clumsy by Nature",
      "oppId": "OM78oWF5JvWWpnZl%2fWdqyT9JlsF5FmPVL89v9mQzr%2bU%3d"
    },
    {
      "date": "July 23",
      "tournament": "2022 Southeast Showdown Youth Tournament",
      "tournamentId": "2022-Southeast-Showdown-Youth-Tournament",
      "gameId": "14WhmVcpkyw30PV4%2bHEkKDKISmh2X8aAXP3fqbFIvrg%3d",
      "team": "Villains",
      "score": "7",
      "oppScore": "9",
      "opp": "ATLiens",
      "oppId": "qZY2T5ErBr5PXUFOH0dm5Uwx7NN1R30sTDUYc%2bRvHIM%3d"
    },
    {
      "date": "July 23",
      "tournament": "2022 Southeast Showdown Youth Tournament",
      "tournamentId": "2022-Southeast-Showdown-Youth-Tournament",
      "gameId": "%2bZliedF3wU6Ff20ZY4dz8AUGDiTXGLpsvXOTDLqwws8%3d",
      "team": "Villains",
      "score": "7",
      "oppScore": "9",
      "opp": "Foggy Bottom Boys",
      "oppId": "pZBAWiTtMe65S%2b5DyaXh%2fK8oMrDmXk6ny66TYS85X7U%3d"
    },
    {
      "date": "July 24",
      "tournament": "2022 Southeast Showdown Youth Tournament",
      "tournamentId": "2022-Southeast-Showdown-Youth-Tournament",
      "gameId": "lzfylBdRtDJpbWQ1S1sH9IFo9s3hn6OOHSNfsKmTjCg%3d",
      "team": "Villains",
      "score": "6",
      "oppScore": "8",
      "opp": "Triforce",
      "oppId": "bE9A3Qj1MqW7pCIHHtQnnzKx11rAl2O9wYRSSZQ4Z40%3d"
    },
    {
      "date": "July 24",
      "tournament": "2022 Southeast Showdown Youth Tournament",
      "tournamentId": "2022-Southeast-Showdown-Youth-Tournament",
      "gameId": "gKlKfRWnK6PI7KwbsDfRi97ZJjXZzRbCS%2bSDZHsV6wk%3d",
      "team": "Villains",
      "score": "11",
      "oppScore": "1",
      "opp": "Metro",
      "oppId": "0beNd%2fOX%2bLMblyebB3k0%2fnyyo5a8SrRamMRZCpOj2Lw%3d"
    },
    {
      "date": "July 24",
      "tournament": "2022 Southeast Showdown Youth Tournament",
      "tournamentId": "2022-Southeast-Showdown-Youth-Tournament",
      "gameId": "HYby56uZQxW7XUj5xChDjhQH7SDVkkn7YU4Ndc%2f4ats%3d",
      "team": "Villains",
      "score": "9",
      "oppScore": "3",
      "opp": "Carolina Sky",
      "oppId": "hB68K7FHsR2eKlSa4C1RbbSGll5%2fMOSptFMsBcCQrbw%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "rY5qKeSnDT%2b%2bFFjcKrp89VL0KmfmugPEUPNfp2zwQT0%3d",
      "team": "Villains",
      "score": "15",
      "oppScore": "0",
      "opp": "Rochester Chill U20B",
      "oppId": "pPK5PXnfX0q%2fGE3tHrGqH5OncspwOgKXCTMcISCjbXE%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "hX1FCa7%2fO7FxeOmuTJicXFmfavQmg0N1qq9kR4LvY2c%3d",
      "team": "Villains",
      "score": "15",
      "oppScore": "6",
      "opp": "Birds Of Play",
      "oppId": "C5gjZFjOICEF1K9fBVXojD5NxWjD32cRDWmzxGkasm0%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "hgWj7W0N4I31%2fLxZj7KH%2fJE12KVEadH%2bkD1JNqMJuhQ%3d",
      "team": "Villains",
      "score": "13",
      "oppScore": "11",
      "opp": "Flying Pig",
      "oppId": "rUyyXUmvJe1zUxoCIq%2bJ8srRSS59bSwzNSdxsoWUbdY%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "Sqe7QQWGcTGFmgTmqymoQsDoDNAVfFqTP5Ncme9siDE%3d",
      "team": "Villains",
      "score": "8",
      "oppScore": "15",
      "opp": "COSmic U-20 Boys",
      "oppId": "ibMdv4bC%2b0jt2%2fJSNUbBFKCLQxYMWOpm9yxWtbkm%2ff8%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "W7m%2fsNDMgqn7d65pTOtDarjm6kb%2b9%2bIuh4s4p5fU%2fLw%3d",
      "team": "Villains",
      "score": "15",
      "oppScore": "1",
      "opp": "Rochester Chill U20B",
      "oppId": "pPK5PXnfX0q%2fGE3tHrGqH5OncspwOgKXCTMcISCjbXE%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "%2fULsv0ja6tdEK00JmzQHIwYIcW4WWm6QjUQcAQz3SPY%3d",
      "team": "Villains",
      "score": "15",
      "oppScore": "2",
      "opp": "Madison MUFAbots U20B",
      "oppId": "yS%2b5dCtBaSh02YjjqqEKw1w%2fIMo1u39kfp4HCvNjBog%3d"
    },
    {
      "date": "August 08",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "P2c0sgPinrEvZuMVm5eZYBuxKrqnv0OvlP%2f2mdUnR3w%3d",
      "team": "Villains",
      "score": "12",
      "oppScore": "10",
      "opp": "Colorado Cutthroat: Youth Club U-20 Boys",
      "oppId": "uCZC60On5XqvfotORPgB2aVS6YYypoZ%2bxRG1SToX3pc%3d"
    },
    {
      "date": "August 08",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "2l3EOFKCkzYFhtlxIwbVZ82zbxkrafXJBmB%2bc8NyCIU%3d",
      "team": "Villains",
      "score": "12",
      "oppScore": "11",
      "opp": "Red Hots - u20",
      "oppId": "Ggh5FAV%2b2R4byKaxZbHQGXO72Bh%2fypyZmUX%2fWkOdMsg%3d"
    }
  ],
  "aNMyR0uAJNwnidjeClZAMqixGjtlQqA6k7CiZP1R0%2bM%3d": [
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "D5LVlAfKqDx5WIC0KfcmFBGru6HB1pJJZBiAiF%2fwNAE%3d",
      "team": "Lost Boys",
      "score": "11",
      "oppScore": "0",
      "opp": "Clumsy by Nature",
      "oppId": "OM78oWF5JvWWpnZl%2fWdqyT9JlsF5FmPVL89v9mQzr%2bU%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "UqHG0jQNmSHuhTSFfTjs%2fDyOSQcHqGlNLOBTBzs3L00%3d",
      "team": "Lost Boys",
      "score": "11",
      "oppScore": "3",
      "opp": "Delirium",
      "oppId": "0yudJHn8N3NbInYx1wqMxoticHnPBt4YkAfZZAUc7U8%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "5pGz8hyS5IjW7daRccdK%2btLyAiW6Qw%2bnAAEzG5N8aN0%3d",
      "team": "Lost Boys",
      "score": "11",
      "oppScore": "2",
      "opp": "Music City Mafia",
      "oppId": "XPgFkg08zZAYqCVWIFQ5JStHxHWnl5AdE3mX%2bAo4nc4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "jYnFVBFaQ98M7QzHeH%2fn%2f83UzWiMkWBAhw2M7Wd%2fguw%3d",
      "team": "Lost Boys",
      "score": "11",
      "oppScore": "5",
      "opp": "Atlanta Arson",
      "oppId": "oAgat63CZNVga8weCR1ui3IB9YvjaA54miCvE%2f%2bRb5g%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "%2fpNTYXi3%2ftaKQBW%2bU4gZQQllSKOH1DrjNLnJi5PKsl0%3d",
      "team": "Lost Boys",
      "score": "13",
      "oppScore": "7",
      "opp": "Second Nature",
      "oppId": "9QN%2fOffYfC%2f0mj9CpLqFRthKvgHjrHj%2bM2%2fY44PMiCE%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "mNYfZkAsVu7G6Rxwa4IKZZthYLI%2b4bKJlf3%2bwLHczQc%3d",
      "team": "Lost Boys",
      "score": "13",
      "oppScore": "6",
      "opp": "Dyno",
      "oppId": "fIw72i6bkbJnjTbK02aFLpJ9Qy2lCUVWlRBvI%2b8UmIc%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "Zb6R1DZM51lqWZaFdqbU5FAGBzxjN6j3Pbv1PYiKnqI%3d",
      "team": "Lost Boys",
      "score": "8",
      "oppScore": "10",
      "opp": "Alliance",
      "oppId": "%2f7JeYXylBxa9Xw5f5LSwua5DyAomcfJ7a7MZDP7nSUs%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "CCm2ltkjoV7Phs3tQsKMJUMCNQ9KcYV2HNOr0qHOBgA%3d",
      "team": "Lost Boys",
      "score": "13",
      "oppScore": "4",
      "opp": "Yee-Haw",
      "oppId": "NpDxoDV0mpf0Hc%2fBzLLx0bhgfIsOKi%2b2AIQMCptTcck%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "0j3Wrw3YnIeTGVW5lw%2fFUUGzoPRTasDFFtw%2bcPdGQ8A%3d",
      "team": "Lost Boys",
      "score": "13",
      "oppScore": "6",
      "opp": "Black Lung",
      "oppId": "olaZlUwWblgF%2b%2b2nRJgbWJ2tj6QzPm5aIO7kfbpnA58%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "1l8DghI%2f87xE3rNGgMSXJl%2fvg1eZ%2b132C1xR2dbZK1A%3d",
      "team": "Lost Boys",
      "score": "11",
      "oppScore": "10",
      "opp": "UpRoar",
      "oppId": "JYJdk5dDNlc6X6evPtQ6K9GWMwDy1xmzavYjS6uyHaE%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "3P8hfgWlFlKgOBmZeU9bIOhVhpP7HO1ixQmoUxrwjxM%3d",
      "team": "Lost Boys",
      "score": "13",
      "oppScore": "5",
      "opp": "Second Nature",
      "oppId": "9QN%2fOffYfC%2f0mj9CpLqFRthKvgHjrHj%2bM2%2fY44PMiCE%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "XQt%2fnUwciKlxClwrkyyl%2bDTr1rfb9AZOuJaJ3dEHNnE%3d",
      "team": "Lost Boys",
      "score": "8",
      "oppScore": "12",
      "opp": "Delirium",
      "oppId": "0yudJHn8N3NbInYx1wqMxoticHnPBt4YkAfZZAUc7U8%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "bxyvnjNGIa%2bHbBwaKQ1SWkT3qkwRtLDjLUYb0XBv%2fVQ%3d",
      "team": "Lost Boys",
      "score": "13",
      "oppScore": "9",
      "opp": "Alliance",
      "oppId": "%2f7JeYXylBxa9Xw5f5LSwua5DyAomcfJ7a7MZDP7nSUs%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "my%2btjM0oipVb04pQxLCyKSD%2biYQwoFxVnl1x7sJgDYA%3d",
      "team": "Lost Boys",
      "score": "9",
      "oppScore": "8",
      "opp": "Haymaker",
      "oppId": "F7zZjPQTPbFe%2fxIVicHJD2zRIOORpO5hSxfBuY5fxcQ%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "OgYoQGGQmOxLQm3nlDrbGsCKWckSrrAZmDjirNZk%2fw0%3d",
      "team": "Lost Boys",
      "score": "12",
      "oppScore": "11",
      "opp": "CITYWIDE Special",
      "oppId": "gfGiZOzR7u7D3oIG%2bAqrTtu3j0xEuTDZKIumDUKFRto%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "fBmuJi1M1oKa8RD65iJue3fcPjPMM5pu5J2UcXO5QYQ%3d",
      "team": "Lost Boys",
      "score": "6",
      "oppScore": "14",
      "opp": "Garden State Ultimate",
      "oppId": "rv5RwYIoNIY0EqGzDkizdJwIJ%2bLTiDa65wF3GyKDUjo%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "jL1chbS7TnDzX1%2fVagnzX5TzWeqaaI9dxX0OTKeIW2g%3d",
      "team": "Lost Boys",
      "score": "7",
      "oppScore": "14",
      "opp": "Omen",
      "oppId": "LvpOSyQd02uHlDLxvVMuw0fzNjwTUO8G7CpXAwQn40I%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "qN9rYO0ey%2fwbppxRg%2bgTmnrFl%2fC9X7D1E9nV0MPP3Ys%3d",
      "team": "Lost Boys",
      "score": "8",
      "oppScore": "10",
      "opp": "Redwolves",
      "oppId": "DoL%2frShNUJHM7OLFhcsMzWlsS0aohpfm30HvDbpVZi4%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "vLDegsk7NLfNaUta34SdbP9MQbvGN%2fKrE9csmoO6PhM%3d",
      "team": "Lost Boys",
      "score": "9",
      "oppScore": "6",
      "opp": "CITYWIDE Special",
      "oppId": "gfGiZOzR7u7D3oIG%2bAqrTtu3j0xEuTDZKIumDUKFRto%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "jfLFNv4mKmiTR0eKPzp9f%2b6kyfOBaozJbjROjJ%2bOUkc%3d",
      "team": "Lost Boys",
      "score": "11",
      "oppScore": "7",
      "opp": "Triumph",
      "oppId": "wAZDt10Q5MoiqMIAGOgPxedaQ2KA3QCSPn7hC8W%2bn5s%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "uLgp%2b4PklPjNWoci5llGdzLuI%2fjLYSPn1duWHHz6xpw%3d",
      "team": "Lost Boys",
      "score": "10",
      "oppScore": "11",
      "opp": "Redwolves",
      "oppId": "DoL%2frShNUJHM7OLFhcsMzWlsS0aohpfm30HvDbpVZi4%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "fcuSrVPEBVCEMRjJ8bn%2bmJc%2fede5RCo04tmuHOv2vBw%3d",
      "team": "Lost Boys",
      "score": "11",
      "oppScore": "1",
      "opp": "Richmond Floodwall",
      "oppId": "fa1yMF%2fsPXZM%2b%2f4My9LlUixC%2bMFxnwM7gPH410zO6EA%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "A8IhiyBZZc0fdva7%2f6WOM5ULuoaXr4sUk61VzTABHpo%3d",
      "team": "Lost Boys",
      "score": "11",
      "oppScore": "6",
      "opp": "Brickhouse",
      "oppId": "b5ARnVg7OngG1a0%2fJuMZTHluwgNFe%2bN5esQIIKsEOUY%3d"
    }
  ],
  "0yudJHn8N3NbInYx1wqMxoticHnPBt4YkAfZZAUc7U8%3d": [
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "FS7A9V4HAkZMj0L%2fZZVNS94BdDStE9fwuWsS1eq4SRg%3d",
      "team": "Delirium",
      "score": "11",
      "oppScore": "7",
      "opp": "Music City Mafia",
      "oppId": "XPgFkg08zZAYqCVWIFQ5JStHxHWnl5AdE3mX%2bAo4nc4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "UqHG0jQNmSHuhTSFfTjs%2fDyOSQcHqGlNLOBTBzs3L00%3d",
      "team": "Delirium",
      "score": "3",
      "oppScore": "11",
      "opp": "Lost Boys",
      "oppId": "aNMyR0uAJNwnidjeClZAMqixGjtlQqA6k7CiZP1R0%2bM%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "HI4wm%2bj93zOglL%2bWNnn4co3vUvtEinhpZJNRf3kLS44%3d",
      "team": "Delirium",
      "score": "11",
      "oppScore": "7",
      "opp": "Atlanta Arson",
      "oppId": "oAgat63CZNVga8weCR1ui3IB9YvjaA54miCvE%2f%2bRb5g%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "yBi057D6z9lpyzBougj4pB4gguRuDYGkT%2funStpurxk%3d",
      "team": "Delirium",
      "score": "11",
      "oppScore": "2",
      "opp": "Clumsy by Nature",
      "oppId": "OM78oWF5JvWWpnZl%2fWdqyT9JlsF5FmPVL89v9mQzr%2bU%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "bXwAAiw73adrndYnoD9hIocduYwy4wMMQRt%2bReXdxwY%3d",
      "team": "Delirium",
      "score": "13",
      "oppScore": "1",
      "opp": "Tampa Tempest",
      "oppId": "hQXi92LAmE32qiZ%2fpZRYvkIBTehvd13BZlvBS7aR3%2fQ%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "RGlUnDpd1VWfVOoXVM4Xf4Rxrx3UPjkef4u%2bphziC9c%3d",
      "team": "Delirium",
      "score": "8",
      "oppScore": "9",
      "opp": "Alliance",
      "oppId": "%2f7JeYXylBxa9Xw5f5LSwua5DyAomcfJ7a7MZDP7nSUs%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "JEcBG9ybUAWnW4y0WFllIkoaDU3ve7VjyS17QA8C9B8%3d",
      "team": "Delirium",
      "score": "10",
      "oppScore": "9",
      "opp": "Rush Hour ATL",
      "oppId": "LdH2zJ494O%2bxdz6p92IsLTMaAmjgf6aP%2fBvM9Lr1RaQ%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "AvL9idkQ0e5Swl9rlQQ6Gmb7O9z1Pznr%2fIqm6rTnY1c%3d",
      "team": "Delirium",
      "score": "13",
      "oppScore": "5",
      "opp": "Atlanta Arson",
      "oppId": "oAgat63CZNVga8weCR1ui3IB9YvjaA54miCvE%2f%2bRb5g%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "XQt%2fnUwciKlxClwrkyyl%2bDTr1rfb9AZOuJaJ3dEHNnE%3d",
      "team": "Delirium",
      "score": "12",
      "oppScore": "8",
      "opp": "Lost Boys",
      "oppId": "aNMyR0uAJNwnidjeClZAMqixGjtlQqA6k7CiZP1R0%2bM%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "n%2bcVc2KQSDQG%2bXENZ0HPcUS3Uw1TF8RY3k3RHTg4j0g%3d",
      "team": "Delirium",
      "score": "15",
      "oppScore": "14",
      "opp": "UpRoar",
      "oppId": "JYJdk5dDNlc6X6evPtQ6K9GWMwDy1xmzavYjS6uyHaE%3d"
    },
    {
      "date": "August 20",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "WtENl9ycDMWwXNKzeV%2bgpFqKtg%2bwPovCBev%2fI1jE%2baY%3d",
      "team": "Delirium",
      "score": "13",
      "oppScore": "9",
      "opp": "Hooch",
      "oppId": "OmOeVI6iSB0tHCNLK01vsOOpZmXF97z%2b2x81ifAm8Vs%3d"
    },
    {
      "date": "August 20",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "lwsvoGHF%2fCBDmPwD3LRZjX9hpufp143iXe0OjpNhb%2fg%3d",
      "team": "Delirium",
      "score": "13",
      "oppScore": "5",
      "opp": "Atlanta Arson",
      "oppId": "oAgat63CZNVga8weCR1ui3IB9YvjaA54miCvE%2f%2bRb5g%3d"
    },
    {
      "date": "August 20",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "R9ooUw1VdydGq6Et3cxg94Bnp2fOqQ6zQ3k5FxrxS08%3d",
      "team": "Delirium",
      "score": "13",
      "oppScore": "5",
      "opp": "Tennessee Folklore",
      "oppId": "z7Hwsz316C%2bFf9Cg1gKP3NGBSqxMqnFjQRbeyMUhUD4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "BLVLO%2fU%2fvF9d%2bzRTZT6WGV61Wb1sLdg6El2Xulivo1A%3d",
      "team": "Delirium",
      "score": "13",
      "oppScore": "9",
      "opp": "Alliance",
      "oppId": "%2f7JeYXylBxa9Xw5f5LSwua5DyAomcfJ7a7MZDP7nSUs%3d"
    },
    {
      "date": "August 21",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "sTPcBsSiP2tjN%2fyarCXn%2bXgREGBMgLWF7rx%2fhAw2ZWc%3d",
      "team": "Delirium",
      "score": "13",
      "oppScore": "9",
      "opp": "Alliance",
      "oppId": "%2f7JeYXylBxa9Xw5f5LSwua5DyAomcfJ7a7MZDP7nSUs%3d"
    },
    {
      "date": "August 21",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "bT1Z9eGmjW%2fwk60UUDfsU6DKsQRm9osWM1wZ6FeqkTI%3d",
      "team": "Delirium",
      "score": "13",
      "oppScore": "12",
      "opp": "Tennessee Folklore",
      "oppId": "z7Hwsz316C%2bFf9Cg1gKP3NGBSqxMqnFjQRbeyMUhUD4%3d"
    }
  ],
  "9QN%2fOffYfC%2f0mj9CpLqFRthKvgHjrHj%2bM2%2fY44PMiCE%3d": [
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "2wOJh4vLP6m37wcBd7biB9dwagWVRiK72sDw9yRLyUs%3d",
      "team": "Second Nature",
      "score": "6",
      "oppScore": "11",
      "opp": "Rush Hour ATL",
      "oppId": "LdH2zJ494O%2bxdz6p92IsLTMaAmjgf6aP%2fBvM9Lr1RaQ%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "nCxzF04KFVSHXlj0%2beGd%2bBjzcU0E%2fuYsBRxf1wEweC0%3d",
      "team": "Second Nature",
      "score": "7",
      "oppScore": "11",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "RZZFU%2f64bMEGU5WTkRJjVU3cT6dOxiqJM1nVQJL%2bcMk%3d",
      "team": "Second Nature",
      "score": "11",
      "oppScore": "8",
      "opp": "Nashville Mudcats",
      "oppId": "m1V5goSU%2bO1BR4XRhH%2fyGq44j%2f4hNKcb%2bBApPx5jVzQ%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "RP9PpJwYWeHqrwa5x355qNxawCfdSZnxtniAYnU4QsI%3d",
      "team": "Second Nature",
      "score": "6",
      "oppScore": "11",
      "opp": "Battleship",
      "oppId": "qDX5A%2fquM05Kah9vXHSYFrstrvRQbJhmA8cR4rz1ar8%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "F0n7jsFDD8Ld9Bdb1OAVujeMYdBbGjqMS%2bdv2QmT9b4%3d",
      "team": "Second Nature",
      "score": "10",
      "oppScore": "13",
      "opp": "Atlanta Arson",
      "oppId": "oAgat63CZNVga8weCR1ui3IB9YvjaA54miCvE%2f%2bRb5g%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "%2fpNTYXi3%2ftaKQBW%2bU4gZQQllSKOH1DrjNLnJi5PKsl0%3d",
      "team": "Second Nature",
      "score": "7",
      "oppScore": "13",
      "opp": "Lost Boys",
      "oppId": "aNMyR0uAJNwnidjeClZAMqixGjtlQqA6k7CiZP1R0%2bM%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "vSL5VQHzwTtVQWcSrMGWDpe26tte7yfOesvI3x77F58%3d",
      "team": "Second Nature",
      "score": "11",
      "oppScore": "10",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "j2anvfJYRoigyTteQdT6cZ2qXMqfUjcWRiGac3IWR24%3d",
      "team": "Second Nature",
      "score": "7",
      "oppScore": "9",
      "opp": "Rush Hour ATL",
      "oppId": "LdH2zJ494O%2bxdz6p92IsLTMaAmjgf6aP%2fBvM9Lr1RaQ%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "uOAXvE3YxRpDFG5kPs2zSnPbNjkk3armqsWiA7GJyno%3d",
      "team": "Second Nature",
      "score": "13",
      "oppScore": "4",
      "opp": "Tampa Tempest",
      "oppId": "hQXi92LAmE32qiZ%2fpZRYvkIBTehvd13BZlvBS7aR3%2fQ%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "Ei9ZFVGqdtvTPB9TRoxNUD9u1S2lFrvMEEU6SNIJPAY%3d",
      "team": "Second Nature",
      "score": "7",
      "oppScore": "5",
      "opp": "Black Lung",
      "oppId": "olaZlUwWblgF%2b%2b2nRJgbWJ2tj6QzPm5aIO7kfbpnA58%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "1vsTnnjQxREqAz1KkWDmYmJE8Hmv0DNWFrhwmLY6UOw%3d",
      "team": "Second Nature",
      "score": "10",
      "oppScore": "12",
      "opp": "Space Cowboys",
      "oppId": "3%2bZ1uFiVej3zLASHWkgqXkJ7hNQ98n84b2FvR4T3pQA%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "3P8hfgWlFlKgOBmZeU9bIOhVhpP7HO1ixQmoUxrwjxM%3d",
      "team": "Second Nature",
      "score": "5",
      "oppScore": "13",
      "opp": "Lost Boys",
      "oppId": "aNMyR0uAJNwnidjeClZAMqixGjtlQqA6k7CiZP1R0%2bM%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "B2RlKFEGj3z%2b7L22Au9TVzmbs1HkYYdM6anNqgi%2bWGA%3d",
      "team": "Second Nature",
      "score": "F",
      "oppScore": "W",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "Qfh%2fMCFPVlKOV3z7HFc5XwouKhmuRnXOSX7JFlC0j4I%3d",
      "team": "Second Nature",
      "score": "F",
      "oppScore": "W",
      "opp": "Tennessee Folklore",
      "oppId": "z7Hwsz316C%2bFf9Cg1gKP3NGBSqxMqnFjQRbeyMUhUD4%3d"
    }
  ],
  "m1V5goSU%2bO1BR4XRhH%2fyGq44j%2f4hNKcb%2bBApPx5jVzQ%3d": [
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "2zrfjSMVgPNDsD%2b2rLyy0lBCdRzON58pAaDdUR6qlUI%3d",
      "team": "Nashville Mudcats",
      "score": "9",
      "oppScore": "11",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "3xIMUFVBD2Zo8mihcVKyioZ8Z5jK5GrDtDjI%2bDHlci4%3d",
      "team": "Nashville Mudcats",
      "score": "6",
      "oppScore": "11",
      "opp": "Rush Hour ATL",
      "oppId": "LdH2zJ494O%2bxdz6p92IsLTMaAmjgf6aP%2fBvM9Lr1RaQ%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "RZZFU%2f64bMEGU5WTkRJjVU3cT6dOxiqJM1nVQJL%2bcMk%3d",
      "team": "Nashville Mudcats",
      "score": "8",
      "oppScore": "11",
      "opp": "Second Nature",
      "oppId": "9QN%2fOffYfC%2f0mj9CpLqFRthKvgHjrHj%2bM2%2fY44PMiCE%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "w%2bGDuYaEG4Mua5xchJULl2QSnMRrxmF5IK9kjs6nYo0%3d",
      "team": "Nashville Mudcats",
      "score": "11",
      "oppScore": "10",
      "opp": "Villains",
      "oppId": "Ym1ak6XomGej8LcWE974vySOBZfTF88e4wX%2bD3N6JX0%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "seQzsx5Iu13eovjLlbJboEev0UmOmJpTHMVivfJHMys%3d",
      "team": "Nashville Mudcats",
      "score": "8",
      "oppScore": "4",
      "opp": "Clumsy by Nature",
      "oppId": "OM78oWF5JvWWpnZl%2fWdqyT9JlsF5FmPVL89v9mQzr%2bU%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "ekoI36li4GWLJACI7uEFXg91oZCR1xu24lOQb6JXUd8%3d",
      "team": "Nashville Mudcats",
      "score": "7",
      "oppScore": "8",
      "opp": "Villains",
      "oppId": "Ym1ak6XomGej8LcWE974vySOBZfTF88e4wX%2bD3N6JX0%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "Y%2fSIOhdGLg09P1s0knnGtBySCM7QsRrO3uqDxo3F1Ac%3d",
      "team": "Nashville Mudcats",
      "score": "7",
      "oppScore": "5",
      "opp": "Music City Mafia",
      "oppId": "XPgFkg08zZAYqCVWIFQ5JStHxHWnl5AdE3mX%2bAo4nc4%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "flzn%2bzWTaRKUTblT936JYQYwyX7f1LtQr60%2bIAP17Ms%3d",
      "team": "Nashville Mudcats",
      "score": "5",
      "oppScore": "11",
      "opp": "Rush Hour ATL",
      "oppId": "LdH2zJ494O%2bxdz6p92IsLTMaAmjgf6aP%2fBvM9Lr1RaQ%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "5PHu5G7XVbWnQiCRPB6vY1o8YDzYrfwjrWJyN7LxGD8%3d",
      "team": "Nashville Mudcats",
      "score": "2",
      "oppScore": "11",
      "opp": "Space Cowboys",
      "oppId": "3%2bZ1uFiVej3zLASHWkgqXkJ7hNQ98n84b2FvR4T3pQA%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "A3g3fh%2bwNFvaQYzZXRdDt8vckFaH57Vw8bENHVdN4hI%3d",
      "team": "Nashville Mudcats",
      "score": "4",
      "oppScore": "11",
      "opp": "Triumph",
      "oppId": "wAZDt10Q5MoiqMIAGOgPxedaQ2KA3QCSPn7hC8W%2bn5s%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "exJRjoYLGNNEzKGNfrzFKKmiiVv5llWQxOU9y44iGas%3d",
      "team": "Nashville Mudcats",
      "score": "11",
      "oppScore": "10",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "DwMyo3o0keU6%2fTqs%2fHhUR87mzhWQUrMcQzk5%2b%2fg77vc%3d",
      "team": "Nashville Mudcats",
      "score": "4",
      "oppScore": "11",
      "opp": "baNC",
      "oppId": "uce10clG%2fWf3GrKUxwKToSDTqXSp6FS58Fb50TN%2fPwU%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "qHUxx%2f1mZlbcQNtxQ1W9%2bouzfJcHiuOWLf%2bd1bX9dhQ%3d",
      "team": "Nashville Mudcats",
      "score": "13",
      "oppScore": "11",
      "opp": "Scoop",
      "oppId": "Rj8mJfKJry2xyWaxTIh5HdXGLaTMxr7%2fXjyL4uJET2o%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "y6JgVYLgWNpkO7sZw%2bIqPA%2fQuVq0QGn65vacbb78fbY%3d",
      "team": "Nashville Mudcats",
      "score": "6",
      "oppScore": "13",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "August 06",
      "tournament": "Louisville Slugfest",
      "tournamentId": "Louisville-Slugfest",
      "gameId": "4iNRKKUZn9R9xlQDpG4YC8%2bTJBWz%2fJO5BksH%2fptZBI8%3d",
      "team": "Nashville Mudcats",
      "score": "10",
      "oppScore": "11",
      "opp": "Flying Dutchmen",
      "oppId": "7amY9LIoyY8hOWkzj5Nqp1H8c7bkR6ySQBzeCHsiQFg%3d"
    },
    {
      "date": "August 06",
      "tournament": "Louisville Slugfest",
      "tournamentId": "Louisville-Slugfest",
      "gameId": "32%2b37GnjRQ7ws1AJ3S6I0tkSsysM68mz0lSgF2Wvi8I%3d",
      "team": "Nashville Mudcats",
      "score": "4",
      "oppScore": "13",
      "opp": "Colonels",
      "oppId": "7Xv2AHNIg3Y%2fm1y7qWkHUz%2b9KT3NZFOCMpW7gBaxnfs%3d"
    }
  ],
  "OM78oWF5JvWWpnZl%2fWdqyT9JlsF5FmPVL89v9mQzr%2bU%3d": [
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "D5LVlAfKqDx5WIC0KfcmFBGru6HB1pJJZBiAiF%2fwNAE%3d",
      "team": "Clumsy by Nature",
      "score": "0",
      "oppScore": "11",
      "opp": "Lost Boys",
      "oppId": "aNMyR0uAJNwnidjeClZAMqixGjtlQqA6k7CiZP1R0%2bM%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "3UChSn5YFphCmz7aPB%2fdeoIhyE1wfosn4gXhyYQyetA%3d",
      "team": "Clumsy by Nature",
      "score": "0",
      "oppScore": "11",
      "opp": "Music City Mafia",
      "oppId": "XPgFkg08zZAYqCVWIFQ5JStHxHWnl5AdE3mX%2bAo4nc4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "aVP53tdk%2fHAeuLHOdbVRJdFQjpShsjNHxv9TwD56d14%3d",
      "team": "Clumsy by Nature",
      "score": "6",
      "oppScore": "11",
      "opp": "Atlanta Arson",
      "oppId": "oAgat63CZNVga8weCR1ui3IB9YvjaA54miCvE%2f%2bRb5g%3d"
    },
    {
      "date": "June 25",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "yBi057D6z9lpyzBougj4pB4gguRuDYGkT%2funStpurxk%3d",
      "team": "Clumsy by Nature",
      "score": "2",
      "oppScore": "11",
      "opp": "Delirium",
      "oppId": "0yudJHn8N3NbInYx1wqMxoticHnPBt4YkAfZZAUc7U8%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "seQzsx5Iu13eovjLlbJboEev0UmOmJpTHMVivfJHMys%3d",
      "team": "Clumsy by Nature",
      "score": "4",
      "oppScore": "8",
      "opp": "Nashville Mudcats",
      "oppId": "m1V5goSU%2bO1BR4XRhH%2fyGq44j%2f4hNKcb%2bBApPx5jVzQ%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "KlVfzJ1Cls3iBRmN1DeJRFemAMZ%2bUQisp%2bEesdZ9IU0%3d",
      "team": "Clumsy by Nature",
      "score": "3",
      "oppScore": "13",
      "opp": "Music City Mafia",
      "oppId": "XPgFkg08zZAYqCVWIFQ5JStHxHWnl5AdE3mX%2bAo4nc4%3d"
    },
    {
      "date": "June 26",
      "tournament": "Huckfest 2022",
      "tournamentId": "Huckfest-2022",
      "gameId": "BiaTOo4gFQUgmO%2fyOUo3Y1y%2bUtcvw9SDC44Ye05xL0M%3d",
      "team": "Clumsy by Nature",
      "score": "0",
      "oppScore": "13",
      "opp": "Villains",
      "oppId": "Ym1ak6XomGej8LcWE974vySOBZfTF88e4wX%2bD3N6JX0%3d"
    }
  ],
  "JDc%2bBsCjGUX7gdebRqmdPvq1u2rFlZ1giM6BgTaL5Lk%3d": [
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "qa0NUdjXtTcjnOAHYGcT4TEf2iHeepNbPJyDnvhwjZc%3d",
      "team": "Clutch",
      "score": "10",
      "oppScore": "9",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "MaAaOeHWi40HNQuGm3pGcxCO2LFIvOWQ6myWpo%2bxYs0%3d",
      "team": "Clutch",
      "score": "9",
      "oppScore": "6",
      "opp": "Harvey Cats",
      "oppId": "cbjkcOMrhSyGKLqnK1uuJLy8ENbcEcYemex94WHxvxU%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "PO3Fnv%2fb170iJ4rU0nUHimB%2fa%2bscKLlNWDqI23WdNE0%3d",
      "team": "Clutch",
      "score": "9",
      "oppScore": "5",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "7ulKcElgXsFYt%2bcxPjk0PvsKaKUke2a2A8piXEeFoqU%3d",
      "team": "Clutch",
      "score": "11",
      "oppScore": "1",
      "opp": "Adventure Time",
      "oppId": "qvXwPo5QehiheOQO5VGwHcsVyYUPf2WOcw0w%2fKf0KR0%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "g21bA7AYyMojWHYLo%2bm3jZv%2fs4786NBFQxJSaxb1QH8%3d",
      "team": "Clutch",
      "score": "11",
      "oppScore": "5",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "7KAX2LyA9S%2fsUMFA2FACE07zG3NWWXC1KFIi7P3w7V0%3d",
      "team": "Clutch",
      "score": "11",
      "oppScore": "6",
      "opp": "Foxtrot",
      "oppId": "0iROWvYUWTMjSSoepkFSHy9JjWFh%2bD04t8pOIQ3p9I4%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "nngBtWwudMOqSQOaCNLtOd%2f2BDgBG530U61%2b1WVoVKE%3d",
      "team": "Clutch",
      "score": "11",
      "oppScore": "10",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "9oaesr5B%2fN%2bYXi0MLAOWM8TDzj5sZqjibrtB16FHlLI%3d",
      "team": "Clutch",
      "score": "11",
      "oppScore": "5",
      "opp": "Firefly TX",
      "oppId": "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "BuvFFktYQ1eN7XRCfyNwdMOst245%2f8D7tsswiQVgGu0%3d",
      "team": "Clutch",
      "score": "11",
      "oppScore": "9",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "2TtwLRT9A43LsKBYpfgoWt0lADrnr3lmhffFLmuDyNk%3d",
      "team": "Clutch",
      "score": "5",
      "oppScore": "13",
      "opp": "Tanasi",
      "oppId": "7MICo92AgV%2flT4d8dpq%2bhDNyJFhVtTVq8%2f94L7J8idw%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "CH8fu%2f0QviFWicxh%2bIOo6xNS2G%2fahxpZLZlufRnqjbY%3d",
      "team": "Clutch",
      "score": "15",
      "oppScore": "12",
      "opp": "General Strike",
      "oppId": "%2bAa3yq8xXOgWBEYZi%2fAT%2fiiGNJfFLcQeXn3x6sH%2fmhA%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "ZRaM5zuAyhpU8%2bBhb3p0eo0uF9xGuiOqtFgellXKJ%2bg%3d",
      "team": "Clutch",
      "score": "6",
      "oppScore": "14",
      "opp": "Revolver",
      "oppId": "Kem%2foxDeD8r5XN1HNW8eHeRyzfBqnWTRaPCmLck6F1I%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "dSIWFglC6DnQBvJsJlaT3AisQef2hP9LDlCKIvIGpiE%3d",
      "team": "Clutch",
      "score": "10",
      "oppScore": "8",
      "opp": "Inception",
      "oppId": "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "zA2vDn3bSuqkXTnXCHMVoKlSifwSKOvFGOvkTDM4WZg%3d",
      "team": "Clutch",
      "score": "9",
      "oppScore": "15",
      "opp": "GOAT",
      "oppId": "6IFGDipIDG9l%2br4E8TUyujjAslzlgFQUH9y5j9M0G4g%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "G1gGAQfMeEhVZgGUp86quyr4ocX4aVqYtvVjak64Vog%3d",
      "team": "Clutch",
      "score": "6",
      "oppScore": "15",
      "opp": "Mad Men",
      "oppId": "Al%2fA4sj9NEIvG2pfoVeKR8OCQL%2fREoXEJcD3sZnI49A%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "k2rHd1AO2lpBUOsvGM44d4JtsRgCN0CewWJIaiAJmic%3d",
      "team": "Clutch",
      "score": "9",
      "oppScore": "15",
      "opp": "GOAT",
      "oppId": "6IFGDipIDG9l%2br4E8TUyujjAslzlgFQUH9y5j9M0G4g%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "ED3iT7UtHcHthdc3m5DPsn35R9%2fo%2bR%2bPKYT2xVB6Y5s%3d",
      "team": "Clutch",
      "score": "12",
      "oppScore": "10",
      "opp": "UpRoar",
      "oppId": "JYJdk5dDNlc6X6evPtQ6K9GWMwDy1xmzavYjS6uyHaE%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "IwYByf9lENVXFVLWAVvqJcd6mklM71pVdIMeXTiEktM%3d",
      "team": "Clutch",
      "score": "12",
      "oppScore": "10",
      "opp": "I-69",
      "oppId": "a64fDYPntTHPU4vghTeNstb32Z0JFlq528bbATJx%2bbs%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "OFRyJruMolY6Dc3tPsQDUwmz4%2fI0g8u9gJ5CHJNy6XY%3d",
      "team": "Clutch",
      "score": "11",
      "oppScore": "9",
      "opp": "Phoenix",
      "oppId": "gSiJoSXGsIFqzcdqVTCYpgvQv7XMC%2b0qfhx6EDDLgrQ%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "B8hH80IFLfz6%2f9bfBx2r0mdWrEyymNM9gKy33m4MBKA%3d",
      "team": "Clutch",
      "score": "9",
      "oppScore": "11",
      "opp": "Trident I",
      "oppId": "HJQp9EgoBkW9LmipJ4BOA7JQ%2bJ%2fsSTavaeYtiM6KQus%3d"
    }
  ],
  "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d": [
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "h7HFioIlqb9hP0ZWZcITDe%2bVbxdgPxNw5%2btxjYb8c3E%3d",
      "team": "Firefly TX",
      "score": "4",
      "oppScore": "8",
      "opp": "Foxtrot",
      "oppId": "0iROWvYUWTMjSSoepkFSHy9JjWFh%2bD04t8pOIQ3p9I4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "tn6FnE73cVlYzVFlkn4LT%2bDDjQAXkYriXsF9TvlEDos%3d",
      "team": "Firefly TX",
      "score": "6",
      "oppScore": "9",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "E2t0XzNBSheDu2MaBgg9mhUfGD2Z2pOEprNoHxOM9wA%3d",
      "team": "Firefly TX",
      "score": "4",
      "oppScore": "11",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "%2bZTcKs6GqRuQA2AZ08xqtXqvv02cXbnEtkkCoVs5WTY%3d",
      "team": "Firefly TX",
      "score": "6",
      "oppScore": "5",
      "opp": "Harvey Cats",
      "oppId": "cbjkcOMrhSyGKLqnK1uuJLy8ENbcEcYemex94WHxvxU%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "v0ovGJvLQ1V%2fMS%2bISra%2bOfvLBV%2bYr3e0muHC6axR%2bsg%3d",
      "team": "Firefly TX",
      "score": "7",
      "oppScore": "9",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "eA3YMRhDgm3ipHZTpjyDlPSrv9d71PJuYg8pJxV%2fNPI%3d",
      "team": "Firefly TX",
      "score": "8",
      "oppScore": "7",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "u8NewrAu5FzGiSs%2fD5ZeUUXufCdwoIueWpjsN%2b60OtA%3d",
      "team": "Firefly TX",
      "score": "11",
      "oppScore": "3",
      "opp": "Adventure Time",
      "oppId": "qvXwPo5QehiheOQO5VGwHcsVyYUPf2WOcw0w%2fKf0KR0%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "9oaesr5B%2fN%2bYXi0MLAOWM8TDzj5sZqjibrtB16FHlLI%3d",
      "team": "Firefly TX",
      "score": "5",
      "oppScore": "11",
      "opp": "Clutch",
      "oppId": "JDc%2bBsCjGUX7gdebRqmdPvq1u2rFlZ1giM6BgTaL5Lk%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "wSHTC%2ftxoVQVZRp%2fFVU8XsvExYjtBQDxqyrSIhMUmz4%3d",
      "team": "Firefly TX",
      "score": "13",
      "oppScore": "5",
      "opp": "Adventure Time",
      "oppId": "qvXwPo5QehiheOQO5VGwHcsVyYUPf2WOcw0w%2fKf0KR0%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "3YCGjIT2i0Wk3l9yNY%2bBY2G50L8lDJBiV1I7jc7ErL4%3d",
      "team": "Firefly TX",
      "score": "10",
      "oppScore": "11",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "rd%2bE4MmckEh6BBlO6VYhov2ZZjWO3kfLg7Y2zndqn2I%3d",
      "team": "Firefly TX",
      "score": "6",
      "oppScore": "13",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "BH72toGwFznIGFbydevCth3PIdpnJEJdXv%2bsPhbdf6o%3d",
      "team": "Firefly TX",
      "score": "13",
      "oppScore": "4",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "GJDUTRXi%2fHmhewxbwVGAvXJzlCPKk1oP2pDJQn9Gqqw%3d",
      "team": "Firefly TX",
      "score": "9",
      "oppScore": "10",
      "opp": "Riverside",
      "oppId": "btdybQHkeLP7UjqWKhbXqcEMojopFgSilO0%2b3mjGSzM%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "J3AWrQfAxihcujRuDgum3xG56nx4uYdmKI8M%2ftpt6sg%3d",
      "team": "Firefly TX",
      "score": "4",
      "oppScore": "13",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "jnXed%2fuVo3BpLNLqbha1gos%2bu2eSNK9bzQDBbR%2bPg%2bo%3d",
      "team": "Firefly TX",
      "score": "9",
      "oppScore": "13",
      "opp": "Foxtrot",
      "oppId": "0iROWvYUWTMjSSoepkFSHy9JjWFh%2bD04t8pOIQ3p9I4%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "bM1a4ch%2bzDShePCebjOr6zqbW%2fFneFrggwFjEuy%2b9Rw%3d",
      "team": "Firefly TX",
      "score": "11",
      "oppScore": "8",
      "opp": "Dallas United Outlaws",
      "oppId": "JvlTMemVWiCex%2fC0N5CCgk2DrV419cGvk%2bO1z84fDZY%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "rCUkxOANCJ8IwaSK5shDHKVhCG3ZBXBfEk7Czwc%2fx5c%3d",
      "team": "Firefly TX",
      "score": "11",
      "oppScore": "13",
      "opp": "Flash Flood",
      "oppId": "JQrYQgb4YJNC2aMynOWjHiZmxJvsuqeLyz2Red9EabU%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "NK2R%2fDLAjlh9KoynukH35q%2f5OOOEKKNY4buyJfo8%2fD4%3d",
      "team": "Firefly TX",
      "score": "12",
      "oppScore": "6",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "LQYNsPq4R1WFuiG6xj%2fnXek%2bTfjXq7wQiKEMd8RkB2w%3d",
      "team": "Firefly TX",
      "score": "4",
      "oppScore": "13",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "A46k%2b0LeJromPEv9WiVb1ehbJzuybBYadkrXmoN%2fSjk%3d",
      "team": "Firefly TX",
      "score": "9",
      "oppScore": "7",
      "opp": "Sonoran Dog",
      "oppId": "WAPjwPL2R8YMCWgolwmIL1cqRoFxsqyk2gQeZ9iDOMA%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "UoMTe3saAK3tykwhiD1yIqUcLvI4pYj%2btn04rD9W0E8%3d",
      "team": "Firefly TX",
      "score": "8",
      "oppScore": "13",
      "opp": "Foxtrot",
      "oppId": "0iROWvYUWTMjSSoepkFSHy9JjWFh%2bD04t8pOIQ3p9I4%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "XPTV6Bi5QdFZSFWD8TLrqLLYqrcKnm7HKvJF8DmSCPw%3d",
      "team": "Firefly TX",
      "score": "6",
      "oppScore": "13",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "jUXqze0oXkGbsHHCktmv8XtuPjtD%2f0bCGZQQPQ%2fzTv4%3d",
      "team": "Firefly TX",
      "score": "9",
      "oppScore": "13",
      "opp": "Harvey Cats",
      "oppId": "cbjkcOMrhSyGKLqnK1uuJLy8ENbcEcYemex94WHxvxU%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "OhFbKxpj24KfcRP6GrCyYByt%2fOIuWKtdiDdtBxo8MQg%3d",
      "team": "Firefly TX",
      "score": "3",
      "oppScore": "9",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    }
  ],
  "0iROWvYUWTMjSSoepkFSHy9JjWFh%2bD04t8pOIQ3p9I4%3d": [
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "h7HFioIlqb9hP0ZWZcITDe%2bVbxdgPxNw5%2btxjYb8c3E%3d",
      "team": "Foxtrot",
      "score": "8",
      "oppScore": "4",
      "opp": "Firefly TX",
      "oppId": "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "yTm%2f8mD2rek8Sm%2bY7fnm5dAtA93WFZYcVcJIRnV1Cvo%3d",
      "team": "Foxtrot",
      "score": "7",
      "oppScore": "9",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "asZAInBKcPjed%2fzyRIBA9Blv3%2bnYPwEZMNct7GVvOZ8%3d",
      "team": "Foxtrot",
      "score": "8",
      "oppScore": "6",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "IxOp%2bCRQI%2fY1NGQAyH6lEPXhcQWdVAoYK0ZrZi6NxLk%3d",
      "team": "Foxtrot",
      "score": "7",
      "oppScore": "10",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "TQXGTxxIDuJ7Gz7KzA%2fANBfAc49GFrgDOtxymx25beg%3d",
      "team": "Foxtrot",
      "score": "8",
      "oppScore": "7",
      "opp": "Harvey Cats",
      "oppId": "cbjkcOMrhSyGKLqnK1uuJLy8ENbcEcYemex94WHxvxU%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "8UV2TbJUgqKlrZHT8t7Frt81ulFXgTKG5RKPRNqjhmc%3d",
      "team": "Foxtrot",
      "score": "11",
      "oppScore": "0",
      "opp": "Adventure Time",
      "oppId": "qvXwPo5QehiheOQO5VGwHcsVyYUPf2WOcw0w%2fKf0KR0%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "7KAX2LyA9S%2fsUMFA2FACE07zG3NWWXC1KFIi7P3w7V0%3d",
      "team": "Foxtrot",
      "score": "6",
      "oppScore": "11",
      "opp": "Clutch",
      "oppId": "JDc%2bBsCjGUX7gdebRqmdPvq1u2rFlZ1giM6BgTaL5Lk%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "DB4p47CPfDUASVNzL5PdfImkzWpsAh27VG0CE8ls4%2fw%3d",
      "team": "Foxtrot",
      "score": "7",
      "oppScore": "8",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "CXvModJUOOjKrR9exf1VO0XOietPmwURis4enfyhXyE%3d",
      "team": "Foxtrot",
      "score": "13",
      "oppScore": "12",
      "opp": "Alamode",
      "oppId": "KQpJIKwJNo0Ue1B4HG72J1RHG35Xquk0pCeyRP4y06g%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "z3nGiwhzqeF%2fVM%2bPxLw7qpxX0D7oM2FT5CorQppS7%2b8%3d",
      "team": "Foxtrot",
      "score": "5",
      "oppScore": "13",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "MvQ7ngXfvDNwqurHFDM9590tUzIxp80hju26BGeeHBI%3d",
      "team": "Foxtrot",
      "score": "13",
      "oppScore": "0",
      "opp": "Deaf Fruit",
      "oppId": "B8w1PwMJi87%2fAp%2b6M8SYsYlCa%2fBJcbAiBoKLkadwT1Y%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "LUR9EGugv%2bX1M8YUNMGgYcFlOKVnU4CA4GlPZh%2f1LJ8%3d",
      "team": "Foxtrot",
      "score": "13",
      "oppScore": "8",
      "opp": "Dallas United Outlaws",
      "oppId": "JvlTMemVWiCex%2fC0N5CCgk2DrV419cGvk%2bO1z84fDZY%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "4T6mGkkHtwJ6u5k%2bXmTLkfitK1uE1w3Z0QA2h5sWKgs%3d",
      "team": "Foxtrot",
      "score": "7",
      "oppScore": "13",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "jnXed%2fuVo3BpLNLqbha1gos%2bu2eSNK9bzQDBbR%2bPg%2bo%3d",
      "team": "Foxtrot",
      "score": "13",
      "oppScore": "9",
      "opp": "Firefly TX",
      "oppId": "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "Bnn3T9Asda18Nvy7Xliy19f0FJKwR6OhlHbPpRFBTJs%3d",
      "team": "Foxtrot",
      "score": "11",
      "oppScore": "13",
      "opp": "Alamode",
      "oppId": "KQpJIKwJNo0Ue1B4HG72J1RHG35Xquk0pCeyRP4y06g%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "aymRUuOeWWi%2bTGz94SE5CF5fP0ZKdRO9fo1Z6BxulpI%3d",
      "team": "Foxtrot",
      "score": "13",
      "oppScore": "2",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "5cR4RI4tzLqZjux0HKnmnb3AtPqv%2bA8ZuAhUXbrKLeY%3d",
      "team": "Foxtrot",
      "score": "13",
      "oppScore": "6",
      "opp": "Sonoran Dog",
      "oppId": "WAPjwPL2R8YMCWgolwmIL1cqRoFxsqyk2gQeZ9iDOMA%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "rHXcrtuz89yTZdAauWPMmNSAHlwJJteW%2fVDIkU1dEHA%3d",
      "team": "Foxtrot",
      "score": "8",
      "oppScore": "12",
      "opp": "Riverside",
      "oppId": "btdybQHkeLP7UjqWKhbXqcEMojopFgSilO0%2b3mjGSzM%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "g2f326SjrvEhPcg9TK%2boKlZV4nT%2bAmSu5gDbEM08cPg%3d",
      "team": "Foxtrot",
      "score": "10",
      "oppScore": "11",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "UoMTe3saAK3tykwhiD1yIqUcLvI4pYj%2btn04rD9W0E8%3d",
      "team": "Foxtrot",
      "score": "13",
      "oppScore": "8",
      "opp": "Firefly TX",
      "oppId": "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "m9Ip8nb4QOLTW7uWCjJ0vNwy8zJpP3bxDYRPETObO0k%3d",
      "team": "Foxtrot",
      "score": "7",
      "oppScore": "13",
      "opp": "Flash Flood",
      "oppId": "JQrYQgb4YJNC2aMynOWjHiZmxJvsuqeLyz2Red9EabU%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "dABWwrI7Tkj7PqeEKgyGeSRyEnJ7KjOg4C%2bfMmn1pZ8%3d",
      "team": "Foxtrot",
      "score": "13",
      "oppScore": "10",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "jH%2fDKvGQmLOW9ruLl1O6Uu53dl%2f%2fPzRbsgfdJf3fWjs%3d",
      "team": "Foxtrot",
      "score": "13",
      "oppScore": "10",
      "opp": "Harvey Cats",
      "oppId": "cbjkcOMrhSyGKLqnK1uuJLy8ENbcEcYemex94WHxvxU%3d"
    }
  ],
  "qvXwPo5QehiheOQO5VGwHcsVyYUPf2WOcw0w%2fKf0KR0%3d": [
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "RgDVGBIGpL9%2ftO2tItRSuISkqIlNZnApgunFryDLDO4%3d",
      "team": "Adventure Time",
      "score": "2",
      "oppScore": "11",
      "opp": "Harvey Cats",
      "oppId": "cbjkcOMrhSyGKLqnK1uuJLy8ENbcEcYemex94WHxvxU%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "JsFye8KTOXstteg74Bve72NkkcqFBEv2jJ0iqQITTfs%3d",
      "team": "Adventure Time",
      "score": "3",
      "oppScore": "9",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "7ulKcElgXsFYt%2bcxPjk0PvsKaKUke2a2A8piXEeFoqU%3d",
      "team": "Adventure Time",
      "score": "1",
      "oppScore": "11",
      "opp": "Clutch",
      "oppId": "JDc%2bBsCjGUX7gdebRqmdPvq1u2rFlZ1giM6BgTaL5Lk%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "n22oqV1ZG1MBgw8PSnL9KAatOXFycp8OYmTMpKSwphs%3d",
      "team": "Adventure Time",
      "score": "4",
      "oppScore": "11",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "8UV2TbJUgqKlrZHT8t7Frt81ulFXgTKG5RKPRNqjhmc%3d",
      "team": "Adventure Time",
      "score": "0",
      "oppScore": "11",
      "opp": "Foxtrot",
      "oppId": "0iROWvYUWTMjSSoepkFSHy9JjWFh%2bD04t8pOIQ3p9I4%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "NH6dm%2fxmDsi3y5nJEDCT7kQdMZ6%2bueTOSZqoyIgKXtI%3d",
      "team": "Adventure Time",
      "score": "2",
      "oppScore": "11",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "u8NewrAu5FzGiSs%2fD5ZeUUXufCdwoIueWpjsN%2b60OtA%3d",
      "team": "Adventure Time",
      "score": "3",
      "oppScore": "11",
      "opp": "Firefly TX",
      "oppId": "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "ENgB5i5Tre%2bq7b%2bFyy80FvChipo1KIek50mUMqxr%2f5w%3d",
      "team": "Adventure Time",
      "score": "1",
      "oppScore": "11",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "wSHTC%2ftxoVQVZRp%2fFVU8XsvExYjtBQDxqyrSIhMUmz4%3d",
      "team": "Adventure Time",
      "score": "5",
      "oppScore": "13",
      "opp": "Firefly TX",
      "oppId": "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "aqaczjWFp8rIPmvOZ087oigHarE93pBi44agZV3CEtE%3d",
      "team": "Adventure Time",
      "score": "7",
      "oppScore": "10",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "pOoSyll%2fr4P3aJSCVkIJFuIYZS8V6YzMUmPfbqpcAO4%3d",
      "team": "Adventure Time",
      "score": "2",
      "oppScore": "13",
      "opp": "Riverside",
      "oppId": "btdybQHkeLP7UjqWKhbXqcEMojopFgSilO0%2b3mjGSzM%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "2fNtlE2JtvXwRH34bKtNfQrfaw0OEGaS%2fZcLz%2ftIQrg%3d",
      "team": "Adventure Time",
      "score": "2",
      "oppScore": "13",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "T9yDNn7Q0GB%2b5St%2bwa%2bxW9WkWmbd6V3nKgAohsqHfSY%3d",
      "team": "Adventure Time",
      "score": "2",
      "oppScore": "13",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "%2fDyOj%2bhDfpf68cU4ldurKx82NBHfejPCABl6NU3kvZY%3d",
      "team": "Adventure Time",
      "score": "10",
      "oppScore": "7",
      "opp": "Deaf Fruit",
      "oppId": "B8w1PwMJi87%2fAp%2b6M8SYsYlCa%2fBJcbAiBoKLkadwT1Y%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "me%2bhXU%2fpPY7ZGsGmQ%2bNTpX%2fwR2lpVFiL%2f%2bvS7G3Ed6s%3d",
      "team": "Adventure Time",
      "score": "8",
      "oppScore": "12",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    }
  ],
  "cbjkcOMrhSyGKLqnK1uuJLy8ENbcEcYemex94WHxvxU%3d": [
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "RgDVGBIGpL9%2ftO2tItRSuISkqIlNZnApgunFryDLDO4%3d",
      "team": "Harvey Cats",
      "score": "11",
      "oppScore": "2",
      "opp": "Adventure Time",
      "oppId": "qvXwPo5QehiheOQO5VGwHcsVyYUPf2WOcw0w%2fKf0KR0%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "MaAaOeHWi40HNQuGm3pGcxCO2LFIvOWQ6myWpo%2bxYs0%3d",
      "team": "Harvey Cats",
      "score": "6",
      "oppScore": "9",
      "opp": "Clutch",
      "oppId": "JDc%2bBsCjGUX7gdebRqmdPvq1u2rFlZ1giM6BgTaL5Lk%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "QeBy27txfuz3q6I%2fMnMrhtJVkhulJGEwrBRCXo1HtbM%3d",
      "team": "Harvey Cats",
      "score": "6",
      "oppScore": "11",
      "opp": "Gamble",
      "oppId": "CzaUUKiVGF7%2f0a2m%2fWnUwzNWeBijkuQS38aT%2fz9vOY4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "%2bZTcKs6GqRuQA2AZ08xqtXqvv02cXbnEtkkCoVs5WTY%3d",
      "team": "Harvey Cats",
      "score": "5",
      "oppScore": "6",
      "opp": "Firefly TX",
      "oppId": "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "TQXGTxxIDuJ7Gz7KzA%2fANBfAc49GFrgDOtxymx25beg%3d",
      "team": "Harvey Cats",
      "score": "7",
      "oppScore": "8",
      "opp": "Foxtrot",
      "oppId": "0iROWvYUWTMjSSoepkFSHy9JjWFh%2bD04t8pOIQ3p9I4%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "ANXvM%2bs2GCo12cEA%2fywcIai3%2b5fX46KKCcMJ0d1twH0%3d",
      "team": "Harvey Cats",
      "score": "4",
      "oppScore": "11",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "JB%2bftFaJFaD6wtwHXGpDkWumY%2bOeDGLdlfu5X9eWzrQ%3d",
      "team": "Harvey Cats",
      "score": "11",
      "oppScore": "5",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    },
    {
      "date": "June 26",
      "tournament": "Dog Days of Summer",
      "tournamentId": "Dog-Days-of-Summer",
      "gameId": "Fj8sFAYj4VZdMJkVE2OyG6hp4%2bI3oEJStYIyOw%2f7PhE%3d",
      "team": "Harvey Cats",
      "score": "8",
      "oppScore": "7",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "Ld1qR53qKAgC1MuHh%2bTNJoqA2T11uCD%2fCrvuL9ulDW4%3d",
      "team": "Harvey Cats",
      "score": "15",
      "oppScore": "6",
      "opp": "Red Hots - u20",
      "oppId": "Ggh5FAV%2b2R4byKaxZbHQGXO72Bh%2fypyZmUX%2fWkOdMsg%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "OGdVI9AqOVMSvGB%2bNS6zXxui8c3WBoADoZGuR%2fKW9fQ%3d",
      "team": "Harvey Cats",
      "score": "15",
      "oppScore": "6",
      "opp": "I-69",
      "oppId": "a64fDYPntTHPU4vghTeNstb32Z0JFlq528bbATJx%2bbs%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "ImWumKBxQqQafyaU5p9I2hk96lWERpxTH08gk%2b3a6Lo%3d",
      "team": "Harvey Cats",
      "score": "5",
      "oppScore": "15",
      "opp": "Colonels",
      "oppId": "7Xv2AHNIg3Y%2fm1y7qWkHUz%2b9KT3NZFOCMpW7gBaxnfs%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "SKE4YsYHG7XNC7vl%2b%2bi%2fyuYw6ok4otg%2bgTqZg7iYwKU%3d",
      "team": "Harvey Cats",
      "score": "8",
      "oppScore": "15",
      "opp": "Mallard",
      "oppId": "iIrscp1ZOpIpCSkyCbUNt0Vq3FobvpA18ZWbPM63hJo%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "D%2fuxglNNZYq6RqJlVMhj67mflTCMRqqRgN8er6GV%2fco%3d",
      "team": "Harvey Cats",
      "score": "14",
      "oppScore": "11",
      "opp": "MKE",
      "oppId": "qHyFAYrlLVWh07Z9rgHqv1nh7SsCt5k4p8Ri0Bna7Zc%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "HPTXv5ZFrI%2b1fsuAMoDAETGq67rF4MkQcclsOPkCB2I%3d",
      "team": "Harvey Cats",
      "score": "14",
      "oppScore": "13",
      "opp": "Knights of Ni",
      "oppId": "dgH60oTrddgw%2bSl2xkIe%2fis%2fu1HY9ndk1MZTdN4OiAs%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "uxFyNcNlSI9MjIX%2fd2yt4NpbMBe8Un9EYdRWwoIQU%2b4%3d",
      "team": "Harvey Cats",
      "score": "11",
      "oppScore": "13",
      "opp": "Riverside",
      "oppId": "btdybQHkeLP7UjqWKhbXqcEMojopFgSilO0%2b3mjGSzM%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "u8assaIprrh50mbIX8dyWxQ2DGGU%2f8%2blqy1P5jO1n5g%3d",
      "team": "Harvey Cats",
      "score": "13",
      "oppScore": "3",
      "opp": "Riverside Messengers [B]",
      "oppId": "%2fk2Al%2fURWo%2fM8QndFYA1FAuFkpV3PwlxFADSQxiJBu4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "EgsPFgBShcEdg79rveAuBmc9zfwHE%2bDkkbSsb6y7Uj0%3d",
      "team": "Harvey Cats",
      "score": "11",
      "oppScore": "12",
      "opp": "Flash Flood",
      "oppId": "JQrYQgb4YJNC2aMynOWjHiZmxJvsuqeLyz2Red9EabU%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "WlOpYCarRuwaLolP0XZEe56yAJNS4wyOgA18Qi9niZc%3d",
      "team": "Harvey Cats",
      "score": "13",
      "oppScore": "8",
      "opp": "Alamode",
      "oppId": "KQpJIKwJNo0Ue1B4HG72J1RHG35Xquk0pCeyRP4y06g%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "t0C05XgcbZclUuT6u2Cp7rmVeLH1iApcRIPWKxZstYI%3d",
      "team": "Harvey Cats",
      "score": "13",
      "oppScore": "7",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "k982rxzqtegKh6957BQrGUPmS8mmuaEkU4Gwq%2fyujuc%3d",
      "team": "Harvey Cats",
      "score": "6",
      "oppScore": "13",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "jUXqze0oXkGbsHHCktmv8XtuPjtD%2f0bCGZQQPQ%2fzTv4%3d",
      "team": "Harvey Cats",
      "score": "13",
      "oppScore": "9",
      "opp": "Firefly TX",
      "oppId": "gxqy7ZUUI6pegoTOJij9BOLYqLxq8MEoF8jLhykUs%2f4%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "jH%2fDKvGQmLOW9ruLl1O6Uu53dl%2f%2fPzRbsgfdJf3fWjs%3d",
      "team": "Harvey Cats",
      "score": "10",
      "oppScore": "13",
      "opp": "Foxtrot",
      "oppId": "0iROWvYUWTMjSSoepkFSHy9JjWFh%2bD04t8pOIQ3p9I4%3d"
    }
  ],
  "3jrAxF6U290JZyiuGff9bkOyKPIspXXtN94jCffcvgs%3d": [
    {
      "date": "June 25",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "GRe9QlNUnI4FurF6kv3h1XUj4BX0ncHAm3hWI8Dyr2U%3d",
      "team": "OAT",
      "score": "10",
      "oppScore": "9",
      "opp": "Casino Ultimate",
      "oppId": "ucDUBMz85JWSgEIJ67ida5y0%2b0ldaJ7HdrWybh8Ev6E%3d"
    },
    {
      "date": "June 25",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "3v1sbfOLIoLSVarqBg7O9qwXs5CLDCqH%2fMyj9WXeO64%3d",
      "team": "OAT",
      "score": "10",
      "oppScore": "7",
      "opp": "Streetgang",
      "oppId": "%2fo6V9EACiot%2bzfjGzj7GQtyBnMoDlmHGs3Nl35pLoeU%3d"
    },
    {
      "date": "June 25",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "pCgZ%2f2AKsI9%2fdKnqL3rHRvZxH3TGGPFDXAqCt0gaTEY%3d",
      "team": "OAT",
      "score": "13",
      "oppScore": "3",
      "opp": "Monsoon",
      "oppId": "iMWyb%2fFNBKjTkIqXjFBY%2bhoKQ%2b3niXP6UqbJkmR4Fiw%3d"
    },
    {
      "date": "June 26",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "N9PuEEMvI%2fezINVtythAB4FUMOrcnRbektCginMv6gc%3d",
      "team": "OAT",
      "score": "12",
      "oppScore": "13",
      "opp": "Hazard",
      "oppId": "P0N8mJF5hP7HulHfG72Zdu2tlioAZAaLyhUwPE4s6T8%3d"
    },
    {
      "date": "June 26",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "iL1aIVic6PVMA54EFoNz6VWufXqx71Y6mK5076N7hgY%3d",
      "team": "OAT",
      "score": "11",
      "oppScore": "3",
      "opp": "OC Crows",
      "oppId": "NvxMsKI3fOV1hpY0a9oUKl0EGgRtzFW8o3PpEg3r%2b%2bI%3d"
    },
    {
      "date": "June 26",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "1PaScVDg7UYM5%2fYbo6cAfzKmq6oDakC04lOKL2v6OAU%3d",
      "team": "OAT",
      "score": "10",
      "oppScore": "11",
      "opp": "Streetgang",
      "oppId": "%2fo6V9EACiot%2bzfjGzj7GQtyBnMoDlmHGs3Nl35pLoeU%3d"
    },
    {
      "date": "July 09",
      "tournament": "Revolution",
      "tournamentId": "Revolution",
      "gameId": "bzi8y1cyEN%2frX%2bQOjwjhHaghB%2f1sn8CiRzImDIhpjaE%3d",
      "team": "OAT",
      "score": "13",
      "oppScore": "2",
      "opp": "Red Dawn",
      "oppId": "wgus%2bDUjcIEQFNwcW6C2SbArugk%2b1zLeWnZguxqHXlE%3d"
    },
    {
      "date": "July 09",
      "tournament": "Revolution",
      "tournamentId": "Revolution",
      "gameId": "6e2l1zuxb0jXOJVlGk%2fcTUYOUCBj2q97M%2fl9bTr0mK0%3d",
      "team": "OAT",
      "score": "13",
      "oppScore": "2",
      "opp": "Mavericks",
      "oppId": "aOMaClZUq3ONOWgUgWYMbnRTrQ1lXICtIdPXubWywt4%3d"
    },
    {
      "date": "July 09",
      "tournament": "Revolution",
      "tournamentId": "Revolution",
      "gameId": "EH5wxoemZTVH3mEIrpqkwxdCu35KBNbw87V7kzEW8jE%3d",
      "team": "OAT",
      "score": "13",
      "oppScore": "9",
      "opp": "OAKLAND Ultimate",
      "oppId": "NU8saUpp6kvWaPJScKgEI2XRcIv7eeRwJI5ZPArOifo%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "LMwGpJ28z0CQToBByqHXYI1Z%2byELFZded9VhjjV8hFM%3d",
      "team": "OAT",
      "score": "13",
      "oppScore": "12",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "cciJiWqRkpQDUCFUU1iXDygvV02OqaucSrjP2wBIe4M%3d",
      "team": "OAT",
      "score": "13",
      "oppScore": "5",
      "opp": "Battery",
      "oppId": "XgsOT%2bl5FkWgZoV7cQAy4vMUDJS3ygcjNozHGHsTYUE%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "nmZ8kkBmkFFCrkfpZqTC7SOhba6jdlaudZO4vtLjgxM%3d",
      "team": "OAT",
      "score": "13",
      "oppScore": "6",
      "opp": "Hazard",
      "oppId": "P0N8mJF5hP7HulHfG72Zdu2tlioAZAaLyhUwPE4s6T8%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "S4cvWIev8WN%2bBYLbK%2bJ4vqXAUZa%2bGO3N6oxvvGBCQdU%3d",
      "team": "OAT",
      "score": "9",
      "oppScore": "11",
      "opp": "Sawtooth",
      "oppId": "O%2fOvOBARFYfl3iP1PCdqMwd9SiYSMEiR%2b0E8MZeOOD4%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "3dhQULMBlkhnO2o4whnP5vu7ejbOlze6wIss%2bRUP708%3d",
      "team": "OAT",
      "score": "13",
      "oppScore": "12",
      "opp": "Ghost Train",
      "oppId": "Tqxb9%2fb2Hxtbg%2f54ifErhjS%2bc%2fBnacDZk5RhQ%2fd%2b%2f5g%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "J74qYroFtuQ%2fQvbH3RyNDkHRL%2fsSL0JnM77Dxsl2%2bAw%3d",
      "team": "OAT",
      "score": "10",
      "oppScore": "15",
      "opp": "Dark Star",
      "oppId": "c9pW2q80DagMAQ3MIlN4qV7%2fnufSjZapIvSzbu5OU%2f4%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "%2bmg%2b8D%2bg3WwTWJRSlzqi5Z%2f3ilS73vHYYIFz%2b3%2fahVQ%3d",
      "team": "OAT",
      "score": "7",
      "oppScore": "11",
      "opp": "Sawtooth",
      "oppId": "O%2fOvOBARFYfl3iP1PCdqMwd9SiYSMEiR%2b0E8MZeOOD4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "ozNQ4FHZ4NtMMLTljJe3yPAhzDclyz2gH5sgfupVeUM%3d",
      "team": "OAT",
      "score": "15",
      "oppScore": "13",
      "opp": "Battery",
      "oppId": "XgsOT%2bl5FkWgZoV7cQAy4vMUDJS3ygcjNozHGHsTYUE%3d"
    },
    {
      "date": "August 20",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "M1oQwnL43vr9E8bngFn6HWyNVLcaF8DRqyddBgaW%2bwg%3d",
      "team": "OAT",
      "score": "9",
      "oppScore": "15",
      "opp": "Furious George",
      "oppId": "AyYWHH3tsm9Kguy8GVmoEwGKAXpOtKzKP1KhenuZxfY%3d"
    },
    {
      "date": "August 20",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "xN7t%2fybt%2bM6ghBsMPH0SPK2bf4VQcU28tPvdcHo8qoY%3d",
      "team": "OAT",
      "score": "14",
      "oppScore": "15",
      "opp": "Emerald City",
      "oppId": "m4Q4f0lvofIPyQTpoi5YZ5nNQ2WH52F0MziY%2fWjcttE%3d"
    },
    {
      "date": "August 21",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "dL1FfRVxnrJ5qxhLYvzGS1jGXGG%2bMAZZPdEqcqDTNyI%3d",
      "team": "OAT",
      "score": "10",
      "oppScore": "15",
      "opp": "Revolver",
      "oppId": "Kem%2foxDeD8r5XN1HNW8eHeRyzfBqnWTRaPCmLck6F1I%3d"
    },
    {
      "date": "August 21",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "f9zgwzZOXe5JrDY0%2foZgfvKwi4x%2bUqmCVm3tcfKaVxA%3d",
      "team": "OAT",
      "score": "15",
      "oppScore": "4",
      "opp": "Battery",
      "oppId": "XgsOT%2bl5FkWgZoV7cQAy4vMUDJS3ygcjNozHGHsTYUE%3d"
    },
    {
      "date": "August 21",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "bpQom34xcm3veCNM0UWHiud5PeNoB0Y2JFyEQ13R7dM%3d",
      "team": "OAT",
      "score": "11",
      "oppScore": "15",
      "opp": "Furious George",
      "oppId": "AyYWHH3tsm9Kguy8GVmoEwGKAXpOtKzKP1KhenuZxfY%3d"
    }
  ],
  "NvxMsKI3fOV1hpY0a9oUKl0EGgRtzFW8o3PpEg3r%2b%2bI%3d": [
    {
      "date": "June 25",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "bbpM3EJ%2b6uOaiCOLAgZk4b%2fh61MK12nZcCV78OjsApk%3d",
      "team": "OC Crows",
      "score": "11",
      "oppScore": "8",
      "opp": "Monsoon",
      "oppId": "iMWyb%2fFNBKjTkIqXjFBY%2bhoKQ%2b3niXP6UqbJkmR4Fiw%3d"
    },
    {
      "date": "June 25",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "3z1QOw9sW7qm34%2foLIsIKLLvCM22S%2be6wqW2QEvPUGg%3d",
      "team": "OC Crows",
      "score": "13",
      "oppScore": "7",
      "opp": "Hazard",
      "oppId": "P0N8mJF5hP7HulHfG72Zdu2tlioAZAaLyhUwPE4s6T8%3d"
    },
    {
      "date": "June 25",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "oUrHtKeXtbdBRG0WkFmMld9EuDlex3HMp32EJVyZ9a0%3d",
      "team": "OC Crows",
      "score": "8",
      "oppScore": "12",
      "opp": "Streetgang",
      "oppId": "%2fo6V9EACiot%2bzfjGzj7GQtyBnMoDlmHGs3Nl35pLoeU%3d"
    },
    {
      "date": "June 26",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "Gbmn%2bO1o7Y8QIzmjILNHxYrpLBKFKIprNyYdmE3FFP4%3d",
      "team": "OC Crows",
      "score": "11",
      "oppScore": "12",
      "opp": "Casino Ultimate",
      "oppId": "ucDUBMz85JWSgEIJ67ida5y0%2b0ldaJ7HdrWybh8Ev6E%3d"
    },
    {
      "date": "June 26",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "iL1aIVic6PVMA54EFoNz6VWufXqx71Y6mK5076N7hgY%3d",
      "team": "OC Crows",
      "score": "3",
      "oppScore": "11",
      "opp": "OAT",
      "oppId": "3jrAxF6U290JZyiuGff9bkOyKPIspXXtN94jCffcvgs%3d"
    },
    {
      "date": "June 26",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "lOEGxgB%2bA3Z2Wf80I6KJp6mEqJ39iyh%2bOz3Ytr51r8E%3d",
      "team": "OC Crows",
      "score": "8",
      "oppScore": "9",
      "opp": "Casino Ultimate",
      "oppId": "ucDUBMz85JWSgEIJ67ida5y0%2b0ldaJ7HdrWybh8Ev6E%3d"
    },
    {
      "date": "July 23",
      "tournament": "Angel City Shootout 2022",
      "tournamentId": "Angel-City-Shootout-2022",
      "gameId": "MNsH97dGXlW0ElLmxycQsubjDNftEdpX04ftWDJXQXQ%3d",
      "team": "OC Crows",
      "score": "9",
      "oppScore": "15",
      "opp": "Drought",
      "oppId": "ksz2i7%2bjkkoIQzWKSrtEphZr9icgiUTPL3Ixum49pfY%3d"
    },
    {
      "date": "July 23",
      "tournament": "Angel City Shootout 2022",
      "tournamentId": "Angel-City-Shootout-2022",
      "gameId": "wt9Pd3dXa0Sy8VtdnaVHXLBLDAYzNz3VwpT3Ldg78Mc%3d",
      "team": "OC Crows",
      "score": "13",
      "oppScore": "14",
      "opp": "Hazard",
      "oppId": "P0N8mJF5hP7HulHfG72Zdu2tlioAZAaLyhUwPE4s6T8%3d"
    },
    {
      "date": "July 23",
      "tournament": "Angel City Shootout 2022",
      "tournamentId": "Angel-City-Shootout-2022",
      "gameId": "iIorzRRRsHBFLhRO5PtIG2FtpFBMQM%2fRJHe%2fQHpHFYQ%3d",
      "team": "OC Crows",
      "score": "11",
      "oppScore": "14",
      "opp": "Offshore",
      "oppId": "z3CZ5zLpuh4%2b6lmsKpQSH%2fDpWRfMktj5DGqERnXkcXY%3d"
    }
  ],
  "%2fo6V9EACiot%2bzfjGzj7GQtyBnMoDlmHGs3Nl35pLoeU%3d": [
    {
      "date": "June 25",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "MgB5b0q0t3FBtSBynrUcSs6BQuiVpEETW9IfQgXybE4%3d",
      "team": "Streetgang",
      "score": "13",
      "oppScore": "11",
      "opp": "Hazard",
      "oppId": "P0N8mJF5hP7HulHfG72Zdu2tlioAZAaLyhUwPE4s6T8%3d"
    },
    {
      "date": "June 25",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "3v1sbfOLIoLSVarqBg7O9qwXs5CLDCqH%2fMyj9WXeO64%3d",
      "team": "Streetgang",
      "score": "7",
      "oppScore": "10",
      "opp": "OAT",
      "oppId": "3jrAxF6U290JZyiuGff9bkOyKPIspXXtN94jCffcvgs%3d"
    },
    {
      "date": "June 25",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "oUrHtKeXtbdBRG0WkFmMld9EuDlex3HMp32EJVyZ9a0%3d",
      "team": "Streetgang",
      "score": "12",
      "oppScore": "8",
      "opp": "OC Crows",
      "oppId": "NvxMsKI3fOV1hpY0a9oUKl0EGgRtzFW8o3PpEg3r%2b%2bI%3d"
    },
    {
      "date": "June 26",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "2dudg%2bjUeO%2fdOk7co6OoM%2fEpwLcbXOD%2f1KX9xxbqoyo%3d",
      "team": "Streetgang",
      "score": "13",
      "oppScore": "2",
      "opp": "Monsoon",
      "oppId": "iMWyb%2fFNBKjTkIqXjFBY%2bhoKQ%2b3niXP6UqbJkmR4Fiw%3d"
    },
    {
      "date": "June 26",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "9IPBtj%2bL0fmxOndJaUBlsSnvVoxzIBPwXu%2fEDLxMzU8%3d",
      "team": "Streetgang",
      "score": "12",
      "oppScore": "7",
      "opp": "Casino Ultimate",
      "oppId": "ucDUBMz85JWSgEIJ67ida5y0%2b0ldaJ7HdrWybh8Ev6E%3d"
    },
    {
      "date": "June 26",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "1PaScVDg7UYM5%2fYbo6cAfzKmq6oDakC04lOKL2v6OAU%3d",
      "team": "Streetgang",
      "score": "11",
      "oppScore": "10",
      "opp": "OAT",
      "oppId": "3jrAxF6U290JZyiuGff9bkOyKPIspXXtN94jCffcvgs%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "NARpnkQHY371MH1%2bM84khFBbB3ICP%2bsZW8FBVmn3Hnc%3d",
      "team": "Streetgang",
      "score": "10",
      "oppScore": "12",
      "opp": "Switchback",
      "oppId": "XNBtJjuPsH6Zq%2bmVmPwL1Zo0%2bl5xYeh3mWrrpzn7CTk%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "zQRz0P2e9SEBd%2fYRt2BQoSn8sBDnOK3zQsz%2bft6FBQQ%3d",
      "team": "Streetgang",
      "score": "11",
      "oppScore": "9",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "ymR6sp3OsFhSPRHykpcw0%2fnqrZ6S3FG4p3Fg%2b2fr%2bLM%3d",
      "team": "Streetgang",
      "score": "13",
      "oppScore": "7",
      "opp": "Ghost Train",
      "oppId": "Tqxb9%2fb2Hxtbg%2f54ifErhjS%2bc%2fBnacDZk5RhQ%2fd%2b%2f5g%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "ps%2bJKk5xU3gdCw9vZ0sgbI0O4rBzXCTpzXZ9Br%2bngHI%3d",
      "team": "Streetgang",
      "score": "10",
      "oppScore": "11",
      "opp": "Dark Star",
      "oppId": "c9pW2q80DagMAQ3MIlN4qV7%2fnufSjZapIvSzbu5OU%2f4%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "YDNM8a5lfnG2%2bKMJYy4vFj7buN4JzgApi6O4M21sY%2fU%3d",
      "team": "Streetgang",
      "score": "14",
      "oppScore": "12",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "eqQc1OpL4Tc11BxZMY%2f2IgAISZ9bncsEs0oIrybEpic%3d",
      "team": "Streetgang",
      "score": "13",
      "oppScore": "12",
      "opp": "Sawtooth",
      "oppId": "O%2fOvOBARFYfl3iP1PCdqMwd9SiYSMEiR%2b0E8MZeOOD4%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "skpVyELfxZ5j1N%2bAFfozH4gEHOQNuldmj7SaKrl34s0%3d",
      "team": "Streetgang",
      "score": "9",
      "oppScore": "15",
      "opp": "Dark Star",
      "oppId": "c9pW2q80DagMAQ3MIlN4qV7%2fnufSjZapIvSzbu5OU%2f4%3d"
    },
    {
      "date": "August 27",
      "tournament": "CBR 2022",
      "tournamentId": "CBR-2022",
      "gameId": "tHw6Dc8L%2b9BRyvh6nva5flPDo9atbXB9ybyvdFqI9p8%3d",
      "team": "Streetgang",
      "score": "15",
      "oppScore": "10",
      "opp": "Name Brand",
      "oppId": "nD%2bVkshg%2ftrTn6m%2bBloXZ0U8vTke1bsPdPY%2b4AI%2fStA%3d"
    },
    {
      "date": "August 27",
      "tournament": "CBR 2022",
      "tournamentId": "CBR-2022",
      "gameId": "uh5gKT%2bW5FV14O96b4xKvF4heZliZrnyOEJJYE2%2bixU%3d",
      "team": "Streetgang",
      "score": "10",
      "oppScore": "15",
      "opp": "Dark Star",
      "oppId": "c9pW2q80DagMAQ3MIlN4qV7%2fnufSjZapIvSzbu5OU%2f4%3d"
    },
    {
      "date": "August 27",
      "tournament": "CBR 2022",
      "tournamentId": "CBR-2022",
      "gameId": "qLYTYIHqsJ8YUNedhXm49BJD1qTVQpUchKjkqQdnhxU%3d",
      "team": "Streetgang",
      "score": "15",
      "oppScore": "8",
      "opp": "Make it Rain",
      "oppId": "GNSXf0PP6g6tw8m0RFzPDaKIh%2fXdPOhBGKFYOiBXFeQ%3d"
    }
  ],
  "ucDUBMz85JWSgEIJ67ida5y0%2b0ldaJ7HdrWybh8Ev6E%3d": [
    {
      "date": "June 25",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "GRe9QlNUnI4FurF6kv3h1XUj4BX0ncHAm3hWI8Dyr2U%3d",
      "team": "Casino Ultimate",
      "score": "9",
      "oppScore": "10",
      "opp": "OAT",
      "oppId": "3jrAxF6U290JZyiuGff9bkOyKPIspXXtN94jCffcvgs%3d"
    },
    {
      "date": "June 25",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "Un4p4gXp62uGih6Tmj6edVnqOjpn5LIZYrcRUywtjgw%3d",
      "team": "Casino Ultimate",
      "score": "13",
      "oppScore": "4",
      "opp": "Monsoon",
      "oppId": "iMWyb%2fFNBKjTkIqXjFBY%2bhoKQ%2b3niXP6UqbJkmR4Fiw%3d"
    },
    {
      "date": "June 25",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "516JWlOZgRu4wpRAfU5pwYpjU2Gq0Ry%2f%2fF7d%2bGWKXVM%3d",
      "team": "Casino Ultimate",
      "score": "13",
      "oppScore": "7",
      "opp": "Hazard",
      "oppId": "P0N8mJF5hP7HulHfG72Zdu2tlioAZAaLyhUwPE4s6T8%3d"
    },
    {
      "date": "June 26",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "Gbmn%2bO1o7Y8QIzmjILNHxYrpLBKFKIprNyYdmE3FFP4%3d",
      "team": "Casino Ultimate",
      "score": "12",
      "oppScore": "11",
      "opp": "OC Crows",
      "oppId": "NvxMsKI3fOV1hpY0a9oUKl0EGgRtzFW8o3PpEg3r%2b%2bI%3d"
    },
    {
      "date": "June 26",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "9IPBtj%2bL0fmxOndJaUBlsSnvVoxzIBPwXu%2fEDLxMzU8%3d",
      "team": "Casino Ultimate",
      "score": "7",
      "oppScore": "12",
      "opp": "Streetgang",
      "oppId": "%2fo6V9EACiot%2bzfjGzj7GQtyBnMoDlmHGs3Nl35pLoeU%3d"
    },
    {
      "date": "June 26",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "lOEGxgB%2bA3Z2Wf80I6KJp6mEqJ39iyh%2bOz3Ytr51r8E%3d",
      "team": "Casino Ultimate",
      "score": "9",
      "oppScore": "8",
      "opp": "OC Crows",
      "oppId": "NvxMsKI3fOV1hpY0a9oUKl0EGgRtzFW8o3PpEg3r%2b%2bI%3d"
    }
  ],
  "iMWyb%2fFNBKjTkIqXjFBY%2bhoKQ%2b3niXP6UqbJkmR4Fiw%3d": [
    {
      "date": "June 25",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "bbpM3EJ%2b6uOaiCOLAgZk4b%2fh61MK12nZcCV78OjsApk%3d",
      "team": "Monsoon",
      "score": "8",
      "oppScore": "11",
      "opp": "OC Crows",
      "oppId": "NvxMsKI3fOV1hpY0a9oUKl0EGgRtzFW8o3PpEg3r%2b%2bI%3d"
    },
    {
      "date": "June 25",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "Un4p4gXp62uGih6Tmj6edVnqOjpn5LIZYrcRUywtjgw%3d",
      "team": "Monsoon",
      "score": "4",
      "oppScore": "13",
      "opp": "Casino Ultimate",
      "oppId": "ucDUBMz85JWSgEIJ67ida5y0%2b0ldaJ7HdrWybh8Ev6E%3d"
    },
    {
      "date": "June 25",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "pCgZ%2f2AKsI9%2fdKnqL3rHRvZxH3TGGPFDXAqCt0gaTEY%3d",
      "team": "Monsoon",
      "score": "3",
      "oppScore": "13",
      "opp": "OAT",
      "oppId": "3jrAxF6U290JZyiuGff9bkOyKPIspXXtN94jCffcvgs%3d"
    },
    {
      "date": "June 26",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "2dudg%2bjUeO%2fdOk7co6OoM%2fEpwLcbXOD%2f1KX9xxbqoyo%3d",
      "team": "Monsoon",
      "score": "2",
      "oppScore": "13",
      "opp": "Streetgang",
      "oppId": "%2fo6V9EACiot%2bzfjGzj7GQtyBnMoDlmHGs3Nl35pLoeU%3d"
    },
    {
      "date": "June 26",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "J%2bVw4brlBx%2fWWAQPTsxirzeO%2fRe6352JuMqoFH3pfLw%3d",
      "team": "Monsoon",
      "score": "1",
      "oppScore": "13",
      "opp": "Hazard",
      "oppId": "P0N8mJF5hP7HulHfG72Zdu2tlioAZAaLyhUwPE4s6T8%3d"
    },
    {
      "date": "June 26",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "xSD665KDZeL3lmg%2ftz85ekqEwDUK9XDXqRHNDznc9F8%3d",
      "team": "Monsoon",
      "score": "0",
      "oppScore": "0",
      "opp": "Hazard",
      "oppId": "P0N8mJF5hP7HulHfG72Zdu2tlioAZAaLyhUwPE4s6T8%3d"
    },
    {
      "date": "August 06",
      "tournament": "Coconino Classic",
      "tournamentId": "Coconino-Classic",
      "gameId": "wveDNumjG0IL8Z34P31RD3qq8GizR8bydcWwAF%2buhXk%3d",
      "team": "Monsoon",
      "score": "5",
      "oppScore": "15",
      "opp": "Drought",
      "oppId": "ksz2i7%2bjkkoIQzWKSrtEphZr9icgiUTPL3Ixum49pfY%3d"
    },
    {
      "date": "August 06",
      "tournament": "Coconino Classic",
      "tournamentId": "Coconino-Classic",
      "gameId": "yfbvlFtz%2bAWkdyShcg1ahJU3MND5lRYSKpV94pKFa4g%3d",
      "team": "Monsoon",
      "score": "8",
      "oppScore": "14",
      "opp": "Sonoran Dog",
      "oppId": "WAPjwPL2R8YMCWgolwmIL1cqRoFxsqyk2gQeZ9iDOMA%3d"
    },
    {
      "date": "August 06",
      "tournament": "Coconino Classic",
      "tournamentId": "Coconino-Classic",
      "gameId": "3GHbC1oFt89EuDcQ4hHtTdnbcsrUmcdGptGVW6o3K2g%3d",
      "team": "Monsoon",
      "score": "13",
      "oppScore": "14",
      "opp": "Seven Out",
      "oppId": "EKT%2b4wN3oW3%2bvTZF1BOVy%2fpLrku%2frikWkw8iDqstIOQ%3d"
    }
  ],
  "P0N8mJF5hP7HulHfG72Zdu2tlioAZAaLyhUwPE4s6T8%3d": [
    {
      "date": "June 25",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "MgB5b0q0t3FBtSBynrUcSs6BQuiVpEETW9IfQgXybE4%3d",
      "team": "Hazard",
      "score": "11",
      "oppScore": "13",
      "opp": "Streetgang",
      "oppId": "%2fo6V9EACiot%2bzfjGzj7GQtyBnMoDlmHGs3Nl35pLoeU%3d"
    },
    {
      "date": "June 25",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "3z1QOw9sW7qm34%2foLIsIKLLvCM22S%2be6wqW2QEvPUGg%3d",
      "team": "Hazard",
      "score": "7",
      "oppScore": "13",
      "opp": "OC Crows",
      "oppId": "NvxMsKI3fOV1hpY0a9oUKl0EGgRtzFW8o3PpEg3r%2b%2bI%3d"
    },
    {
      "date": "June 25",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "516JWlOZgRu4wpRAfU5pwYpjU2Gq0Ry%2f%2fF7d%2bGWKXVM%3d",
      "team": "Hazard",
      "score": "7",
      "oppScore": "13",
      "opp": "Casino Ultimate",
      "oppId": "ucDUBMz85JWSgEIJ67ida5y0%2b0ldaJ7HdrWybh8Ev6E%3d"
    },
    {
      "date": "June 26",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "N9PuEEMvI%2fezINVtythAB4FUMOrcnRbektCginMv6gc%3d",
      "team": "Hazard",
      "score": "13",
      "oppScore": "12",
      "opp": "OAT",
      "oppId": "3jrAxF6U290JZyiuGff9bkOyKPIspXXtN94jCffcvgs%3d"
    },
    {
      "date": "June 26",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "J%2bVw4brlBx%2fWWAQPTsxirzeO%2fRe6352JuMqoFH3pfLw%3d",
      "team": "Hazard",
      "score": "13",
      "oppScore": "1",
      "opp": "Monsoon",
      "oppId": "iMWyb%2fFNBKjTkIqXjFBY%2bhoKQ%2b3niXP6UqbJkmR4Fiw%3d"
    },
    {
      "date": "June 26",
      "tournament": "San Diego Slammer 2022",
      "tournamentId": "San-Diego-Slammer-2022",
      "gameId": "xSD665KDZeL3lmg%2ftz85ekqEwDUK9XDXqRHNDznc9F8%3d",
      "team": "Hazard",
      "score": "0",
      "oppScore": "0",
      "opp": "Monsoon",
      "oppId": "iMWyb%2fFNBKjTkIqXjFBY%2bhoKQ%2b3niXP6UqbJkmR4Fiw%3d"
    },
    {
      "date": "July 23",
      "tournament": "Angel City Shootout 2022",
      "tournamentId": "Angel-City-Shootout-2022",
      "gameId": "SRA2P6w3%2faRUtqFYYT88sdVayoylIX5oVqY5NxyxduM%3d",
      "team": "Hazard",
      "score": "15",
      "oppScore": "13",
      "opp": "Offshore",
      "oppId": "z3CZ5zLpuh4%2b6lmsKpQSH%2fDpWRfMktj5DGqERnXkcXY%3d"
    },
    {
      "date": "July 23",
      "tournament": "Angel City Shootout 2022",
      "tournamentId": "Angel-City-Shootout-2022",
      "gameId": "wt9Pd3dXa0Sy8VtdnaVHXLBLDAYzNz3VwpT3Ldg78Mc%3d",
      "team": "Hazard",
      "score": "14",
      "oppScore": "13",
      "opp": "OC Crows",
      "oppId": "NvxMsKI3fOV1hpY0a9oUKl0EGgRtzFW8o3PpEg3r%2b%2bI%3d"
    },
    {
      "date": "July 23",
      "tournament": "Angel City Shootout 2022",
      "tournamentId": "Angel-City-Shootout-2022",
      "gameId": "JFVvO%2buq2v1qy7NpUNR%2ffbwyIU0O8RZEqPBblszWVDg%3d",
      "team": "Hazard",
      "score": "8",
      "oppScore": "15",
      "opp": "Drought",
      "oppId": "ksz2i7%2bjkkoIQzWKSrtEphZr9icgiUTPL3Ixum49pfY%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "0kpoz5I6jL%2fHH5V3Y7C41vxu2CBn261NV6PeMLHrtUg%3d",
      "team": "Hazard",
      "score": "11",
      "oppScore": "10",
      "opp": "Battery",
      "oppId": "XgsOT%2bl5FkWgZoV7cQAy4vMUDJS3ygcjNozHGHsTYUE%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "5yLOYRxaNrhBt2wYeFwzHNmuILQ5YbmJKOzcqvfnU%2fI%3d",
      "team": "Hazard",
      "score": "9",
      "oppScore": "13",
      "opp": "Sawtooth",
      "oppId": "O%2fOvOBARFYfl3iP1PCdqMwd9SiYSMEiR%2b0E8MZeOOD4%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "4ooTQJwTEldvo9CppBbzFNZfPdqE751Kq4AOCVzOnk8%3d",
      "team": "Hazard",
      "score": "10",
      "oppScore": "13",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "nmZ8kkBmkFFCrkfpZqTC7SOhba6jdlaudZO4vtLjgxM%3d",
      "team": "Hazard",
      "score": "6",
      "oppScore": "13",
      "opp": "OAT",
      "oppId": "3jrAxF6U290JZyiuGff9bkOyKPIspXXtN94jCffcvgs%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "2MctzqMsWyZTpw895hElHgC7LwQMvxsJ7vd7xV16p1M%3d",
      "team": "Hazard",
      "score": "12",
      "oppScore": "11",
      "opp": "Switchback",
      "oppId": "XNBtJjuPsH6Zq%2bmVmPwL1Zo0%2bl5xYeh3mWrrpzn7CTk%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "lyKwlzT2B67jiEslpie36e24Bs8oGX9N2ddkkbcNVnI%3d",
      "team": "Hazard",
      "score": "9",
      "oppScore": "8",
      "opp": "Ghost Train",
      "oppId": "Tqxb9%2fb2Hxtbg%2f54ifErhjS%2bc%2fBnacDZk5RhQ%2fd%2b%2f5g%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "Y6CbHSOa9JvD7f3RoC1di4jZYzI8s1aB3Lo0XboIANU%3d",
      "team": "Hazard",
      "score": "15",
      "oppScore": "12",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    }
  ],
  "3xV6rIwyIem%2bql5tz%2bZmFG9gr%2bp6WKu5k4q5bwFcSp0%3d": [
    {
      "date": "June 25",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "rZ1Tg6SoFZ%2fsc9%2fnDp2CReTfXOQrdRU%2fyuVbxapdXvQ%3d",
      "team": "Vault",
      "score": "13",
      "oppScore": "11",
      "opp": "Truck Stop",
      "oppId": "pUj3i6rzWdnpW5TeEEoriE%2fRmJjK%2brKBLe8y4LGWtZY%3d"
    },
    {
      "date": "June 25",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "gobNd%2fY4nI%2bTfcpdio64WoKPM0N8%2bWElmlCCF1HbhYQ%3d",
      "team": "Vault",
      "score": "13",
      "oppScore": "10",
      "opp": "Phantom",
      "oppId": "Hc0As%2bNg1YaD%2fyL3LwaIEtSMTc23KxHOibI0ZwQLDa4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "zReYz0YKKmM2kAXztsTPAicbxTrwrYFI2GHnG6ltR84%3d",
      "team": "Vault",
      "score": "12",
      "oppScore": "8",
      "opp": "Sprout",
      "oppId": "QyN2sOWwUrO10Eheq9tje8Trmro0kiO6dKpnQzSh9q4%3d"
    },
    {
      "date": "June 26",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "kJaGZmlaPkLRUK22glvTx8LzocIPJJrc3TCXUdjhaxQ%3d",
      "team": "Vault",
      "score": "12",
      "oppScore": "11",
      "opp": "Pittsburgh Temper",
      "oppId": "u%2bSlJ3XL7d589O5YW%2bbHhuzzJ855SfIO%2bGHlzwH3w3M%3d"
    },
    {
      "date": "June 26",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "S9JFSfgNTpeFCVj8ixHLP0qdCVLJxH2lT%2bZPJ%2bDO3kA%3d",
      "team": "Vault",
      "score": "9",
      "oppScore": "15",
      "opp": "PoNY",
      "oppId": "s8Hq5QrzeTvvFPbmIo%2bj5qGjiFRUrzVIivVUtEM%2bvd4%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "AMUDrUzCG2a586G7d2SID0ycxkWOiCk2qkKS0BofHU4%3d",
      "team": "Vault",
      "score": "15",
      "oppScore": "2",
      "opp": "Haymaker",
      "oppId": "F7zZjPQTPbFe%2fxIVicHJD2zRIOORpO5hSxfBuY5fxcQ%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "mspb6YEL1aJ4k6asz3%2fhKYtThGgBBs%2fqzFUfFSLrBZw%3d",
      "team": "Vault",
      "score": "13",
      "oppScore": "9",
      "opp": "Blueprint",
      "oppId": "BDhLSFxnViHodVQ9noIy0%2by0HWSmiJ3qgV5WI1ozsAk%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "y2NBMzCnYfUaRcVVSfnVCsEUml0ESo6YqH5NTaBYmbs%3d",
      "team": "Vault",
      "score": "11",
      "oppScore": "8",
      "opp": "Raleigh-Durham United",
      "oppId": "ESHhN%2bMK1oy0j%2bQJSL8mb9cq8h30Erb63T07U8pubEc%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "Wskfwve3zwgL7IE1VpM3sdfFIbwzNYlE%2bvyBMKqO6To%3d",
      "team": "Vault",
      "score": "13",
      "oppScore": "7",
      "opp": "Phoenix",
      "oppId": "gSiJoSXGsIFqzcdqVTCYpgvQv7XMC%2b0qfhx6EDDLgrQ%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "MfVjRuHc6JPU9azU5Pq5kTa4bhOHZIt%2b%2fGmYzXBTAjQ%3d",
      "team": "Vault",
      "score": "13",
      "oppScore": "8",
      "opp": "Trident I",
      "oppId": "HJQp9EgoBkW9LmipJ4BOA7JQ%2bJ%2fsSTavaeYtiM6KQus%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "mPKV1dqSILKrHpeIQdr8lZ%2bRG7Oa0Nm9YipGVSRUaYI%3d",
      "team": "Vault",
      "score": "10",
      "oppScore": "8",
      "opp": "Mad Men",
      "oppId": "Al%2fA4sj9NEIvG2pfoVeKR8OCQL%2fREoXEJcD3sZnI49A%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "I%2bcw%2bEySbIec3ELlq6DRLl4BPr7L5V1ZaEeAJDr5hYE%3d",
      "team": "Vault",
      "score": "15",
      "oppScore": "6",
      "opp": "Shade",
      "oppId": "Lh0BmORltuq%2bkEq6e8QTGFiUkZuG5GuP4DNNS5vaaAc%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "6mYA3uO43e0y6PLeM093KllRAQYEGUYP9Nu9a%2fgCYQw%3d",
      "team": "Vault",
      "score": "14",
      "oppScore": "11",
      "opp": "Mallard",
      "oppId": "iIrscp1ZOpIpCSkyCbUNt0Vq3FobvpA18ZWbPM63hJo%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "EhmidJtop%2b0OpniCpftgTgZ%2fjsOQcXcnpUMlZxD3DlU%3d",
      "team": "Vault",
      "score": "14",
      "oppScore": "13",
      "opp": "Omen",
      "oppId": "LvpOSyQd02uHlDLxvVMuw0fzNjwTUO8G7CpXAwQn40I%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "fMro7mjvtApIRlmb5clk6zzQuCuSBwDfsDau3pbF0fI%3d",
      "team": "Vault",
      "score": "10",
      "oppScore": "9",
      "opp": "Tanasi",
      "oppId": "7MICo92AgV%2flT4d8dpq%2bhDNyJFhVtTVq8%2f94L7J8idw%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "c1sBcFWUiYbc72HBh2jbpDKTtM%2fWVHXY979EYcoSgmk%3d",
      "team": "Vault",
      "score": "11",
      "oppScore": "13",
      "opp": "GOAT",
      "oppId": "6IFGDipIDG9l%2br4E8TUyujjAslzlgFQUH9y5j9M0G4g%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "j0fUQcE6bnQYpzYbuI8ewWhVv7saiRCsOl1QdyNLw28%3d",
      "team": "Vault",
      "score": "10",
      "oppScore": "12",
      "opp": "Doublewide",
      "oppId": "Nm8EGFzg2L2gF7kgWvfP7%2bbHNWDz7Dq77ckjxxzanlg%3d"
    }
  ],
  "Hc0As%2bNg1YaD%2fyL3LwaIEtSMTc23KxHOibI0ZwQLDa4%3d": [
    {
      "date": "June 25",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "Snjb20xghnnO95ZeNwPpn5JaIY9KpyS8c9nXA3K4YYc%3d",
      "team": "Phantom",
      "score": "9",
      "oppScore": "13",
      "opp": "Pittsburgh Temper",
      "oppId": "u%2bSlJ3XL7d589O5YW%2bbHhuzzJ855SfIO%2bGHlzwH3w3M%3d"
    },
    {
      "date": "June 25",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "gobNd%2fY4nI%2bTfcpdio64WoKPM0N8%2bWElmlCCF1HbhYQ%3d",
      "team": "Phantom",
      "score": "10",
      "oppScore": "13",
      "opp": "Vault",
      "oppId": "3xV6rIwyIem%2bql5tz%2bZmFG9gr%2bp6WKu5k4q5bwFcSp0%3d"
    },
    {
      "date": "June 25",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "g6%2bayDntlWRjoR31%2bjK3EetIscynWuH4kp7STlLZfJY%3d",
      "team": "Phantom",
      "score": "6",
      "oppScore": "13",
      "opp": "Truck Stop",
      "oppId": "pUj3i6rzWdnpW5TeEEoriE%2fRmJjK%2brKBLe8y4LGWtZY%3d"
    },
    {
      "date": "June 26",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "3I6zPLhXNdqol7RTfguHAVEEYMyq69A8s%2b7esCv7%2fdU%3d",
      "team": "Phantom",
      "score": "9",
      "oppScore": "10",
      "opp": "PoNY",
      "oppId": "s8Hq5QrzeTvvFPbmIo%2bj5qGjiFRUrzVIivVUtEM%2bvd4%3d"
    },
    {
      "date": "June 26",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "gsWg%2fqKrmleAIoCJE798%2fVescRFgZSqrRtiOeqfzdUo%3d",
      "team": "Phantom",
      "score": "8",
      "oppScore": "11",
      "opp": "Sprout",
      "oppId": "QyN2sOWwUrO10Eheq9tje8Trmro0kiO6dKpnQzSh9q4%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "mrrtO9pPnrq02hp8jCcUa8r7u9J5xAHmlzg8e8iYWjg%3d",
      "team": "Phantom",
      "score": "10",
      "oppScore": "7",
      "opp": "Shade",
      "oppId": "Lh0BmORltuq%2bkEq6e8QTGFiUkZuG5GuP4DNNS5vaaAc%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "mJ2AzbCyxq89P3gI5St6mbvwTk%2boGuSH6tTdegW6rKY%3d",
      "team": "Phantom",
      "score": "12",
      "oppScore": "13",
      "opp": "Tanasi",
      "oppId": "7MICo92AgV%2flT4d8dpq%2bhDNyJFhVtTVq8%2f94L7J8idw%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "pbZS8k6Iz8wofbXQj9KPNC4Q45881cIIVpnl10eItu8%3d",
      "team": "Phantom",
      "score": "9",
      "oppScore": "13",
      "opp": "Trident I",
      "oppId": "HJQp9EgoBkW9LmipJ4BOA7JQ%2bJ%2fsSTavaeYtiM6KQus%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "2NFu%2f5%2fRS8uDTkaz%2bbS1lZtBXbH4jG5PeuVkz9ULxJw%3d",
      "team": "Phantom",
      "score": "12",
      "oppScore": "6",
      "opp": "Mallard",
      "oppId": "iIrscp1ZOpIpCSkyCbUNt0Vq3FobvpA18ZWbPM63hJo%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "5MlYN7D%2f7AWFA%2fojbtyYZ6uOlng1g%2f1892yKVowB6LM%3d",
      "team": "Phantom",
      "score": "10",
      "oppScore": "7",
      "opp": "CITYWIDE Special",
      "oppId": "gfGiZOzR7u7D3oIG%2bAqrTtu3j0xEuTDZKIumDUKFRto%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "6h95vW6z4GqLmJryukFeb9ArfGA3vCz6E3CmGB1g%2fBI%3d",
      "team": "Phantom",
      "score": "10",
      "oppScore": "8",
      "opp": "Redwolves",
      "oppId": "DoL%2frShNUJHM7OLFhcsMzWlsS0aohpfm30HvDbpVZi4%3d"
    }
  ],
  "QyN2sOWwUrO10Eheq9tje8Trmro0kiO6dKpnQzSh9q4%3d": [
    {
      "date": "June 25",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "wlZhDy35ewQP6xLYKs4pKC7RJm9rcRzS00f2UIlxDHQ%3d",
      "team": "Sprout",
      "score": "6",
      "oppScore": "11",
      "opp": "PoNY",
      "oppId": "s8Hq5QrzeTvvFPbmIo%2bj5qGjiFRUrzVIivVUtEM%2bvd4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "W6XSi8x7Jqb5ZtXLong0UkmU2Ih7jy9iMkFY8mmMRck%3d",
      "team": "Sprout",
      "score": "9",
      "oppScore": "11",
      "opp": "Pittsburgh Temper",
      "oppId": "u%2bSlJ3XL7d589O5YW%2bbHhuzzJ855SfIO%2bGHlzwH3w3M%3d"
    },
    {
      "date": "June 25",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "zReYz0YKKmM2kAXztsTPAicbxTrwrYFI2GHnG6ltR84%3d",
      "team": "Sprout",
      "score": "8",
      "oppScore": "12",
      "opp": "Vault",
      "oppId": "3xV6rIwyIem%2bql5tz%2bZmFG9gr%2bp6WKu5k4q5bwFcSp0%3d"
    },
    {
      "date": "June 26",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "n8gKFvEhPJFAvBUqsPYI4ZDLaaeiSkc3rJ8XIDBFk08%3d",
      "team": "Sprout",
      "score": "9",
      "oppScore": "12",
      "opp": "Truck Stop",
      "oppId": "pUj3i6rzWdnpW5TeEEoriE%2fRmJjK%2brKBLe8y4LGWtZY%3d"
    },
    {
      "date": "June 26",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "gsWg%2fqKrmleAIoCJE798%2fVescRFgZSqrRtiOeqfzdUo%3d",
      "team": "Sprout",
      "score": "11",
      "oppScore": "8",
      "opp": "Phantom",
      "oppId": "Hc0As%2bNg1YaD%2fyL3LwaIEtSMTc23KxHOibI0ZwQLDa4%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "lh5j5Bd4WL24Z3CRy2kqRsvjlisRfU1oTBCpMuVWa7g%3d",
      "team": "Sprout",
      "score": "15",
      "oppScore": "9",
      "opp": "Pittsburgh Temper",
      "oppId": "u%2bSlJ3XL7d589O5YW%2bbHhuzzJ855SfIO%2bGHlzwH3w3M%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "O3U5HjOllL6uXxWOfRN5WgXikXdWyCzxFqcm%2bnRJIco%3d",
      "team": "Sprout",
      "score": "10",
      "oppScore": "12",
      "opp": "Johnny Bravo",
      "oppId": "AKF9iH0VKK2mK%2fzpE9qJYeqGR%2ff%2fBETu1dm16hUGaVo%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "wLuRdUzMhGYGUzHpjZsdryBlmplv9NYEyxkLkmRBIBo%3d",
      "team": "Sprout",
      "score": "12",
      "oppScore": "13",
      "opp": "Emerald City",
      "oppId": "m4Q4f0lvofIPyQTpoi5YZ5nNQ2WH52F0MziY%2fWjcttE%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "0lq%2ba4wD1FvVOX2DpzfHulCzWiQ5yiI9J%2bKF2XvQ1zY%3d",
      "team": "Sprout",
      "score": "12",
      "oppScore": "15",
      "opp": "Sub Zero",
      "oppId": "8R4mmVt8rvJ%2bEDsQVxGD5KkmF8Rfnl3EHt4CpzxANac%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "G1o38FfzPG8VP%2bMpLhhBLT3JyrsByL6JPHfjtF68%2bVA%3d",
      "team": "Sprout",
      "score": "10",
      "oppScore": "15",
      "opp": "Pittsburgh Temper",
      "oppId": "u%2bSlJ3XL7d589O5YW%2bbHhuzzJ855SfIO%2bGHlzwH3w3M%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "aDS%2bq%2f%2bnk8wqyw2gMvhV4AF40lJDd6icSuZbrGm1hx0%3d",
      "team": "Sprout",
      "score": "15",
      "oppScore": "6",
      "opp": "Inception",
      "oppId": "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d"
    }
  ],
  "u%2bSlJ3XL7d589O5YW%2bbHhuzzJ855SfIO%2bGHlzwH3w3M%3d": [
    {
      "date": "June 25",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "Snjb20xghnnO95ZeNwPpn5JaIY9KpyS8c9nXA3K4YYc%3d",
      "team": "Pittsburgh Temper",
      "score": "13",
      "oppScore": "9",
      "opp": "Phantom",
      "oppId": "Hc0As%2bNg1YaD%2fyL3LwaIEtSMTc23KxHOibI0ZwQLDa4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "W6XSi8x7Jqb5ZtXLong0UkmU2Ih7jy9iMkFY8mmMRck%3d",
      "team": "Pittsburgh Temper",
      "score": "11",
      "oppScore": "9",
      "opp": "Sprout",
      "oppId": "QyN2sOWwUrO10Eheq9tje8Trmro0kiO6dKpnQzSh9q4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "xCQQgmbjmlVpxvzTGY18wd7XoMkHXusfV%2f9g%2bOogo5A%3d",
      "team": "Pittsburgh Temper",
      "score": "12",
      "oppScore": "13",
      "opp": "PoNY",
      "oppId": "s8Hq5QrzeTvvFPbmIo%2bj5qGjiFRUrzVIivVUtEM%2bvd4%3d"
    },
    {
      "date": "June 26",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "kJaGZmlaPkLRUK22glvTx8LzocIPJJrc3TCXUdjhaxQ%3d",
      "team": "Pittsburgh Temper",
      "score": "11",
      "oppScore": "12",
      "opp": "Vault",
      "oppId": "3xV6rIwyIem%2bql5tz%2bZmFG9gr%2bp6WKu5k4q5bwFcSp0%3d"
    },
    {
      "date": "June 26",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "8pHPXXxurGTr3Cgo4fa3GS9xNSJ1G4jA1GYk%2bUerx9E%3d",
      "team": "Pittsburgh Temper",
      "score": "9",
      "oppScore": "12",
      "opp": "Truck Stop",
      "oppId": "pUj3i6rzWdnpW5TeEEoriE%2fRmJjK%2brKBLe8y4LGWtZY%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "lh5j5Bd4WL24Z3CRy2kqRsvjlisRfU1oTBCpMuVWa7g%3d",
      "team": "Pittsburgh Temper",
      "score": "9",
      "oppScore": "15",
      "opp": "Sprout",
      "oppId": "QyN2sOWwUrO10Eheq9tje8Trmro0kiO6dKpnQzSh9q4%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "JJ0LlgqfxrsYGzQYQyP2oMTGGYEK4Qt%2bytAEHSmTV3I%3d",
      "team": "Pittsburgh Temper",
      "score": "9",
      "oppScore": "15",
      "opp": "Johnny Bravo",
      "oppId": "AKF9iH0VKK2mK%2fzpE9qJYeqGR%2ff%2fBETu1dm16hUGaVo%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "iR21rHqChHlPTlLJXVPd4cv5lljjtojCefw68R5akMc%3d",
      "team": "Pittsburgh Temper",
      "score": "11",
      "oppScore": "12",
      "opp": "Chain Lightning",
      "oppId": "xMA73o8VVEaOTmaBmpN0fQZ4779P9LJ9jH3j44aVabA%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "fKLSwc7rckLfV9EkkmgrmRRcYf8%2bULngq7UTYN%2bc62s%3d",
      "team": "Pittsburgh Temper",
      "score": "15",
      "oppScore": "5",
      "opp": "Inception",
      "oppId": "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "G1o38FfzPG8VP%2bMpLhhBLT3JyrsByL6JPHfjtF68%2bVA%3d",
      "team": "Pittsburgh Temper",
      "score": "15",
      "oppScore": "10",
      "opp": "Sprout",
      "oppId": "QyN2sOWwUrO10Eheq9tje8Trmro0kiO6dKpnQzSh9q4%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "1cnkyvJAE0gkMrTLLiAAFFMenDlCufu2%2fk7tXMgOPGU%3d",
      "team": "Pittsburgh Temper",
      "score": "15",
      "oppScore": "7",
      "opp": "Sub Zero",
      "oppId": "8R4mmVt8rvJ%2bEDsQVxGD5KkmF8Rfnl3EHt4CpzxANac%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "d1",
      "team": "Pittsburgh Temper",
      "score": "14",
      "oppScore": "15",
      "opp": "Truck Stop",
      "oppId": "pUj3i6rzWdnpW5TeEEoriE%2fRmJjK%2brKBLe8y4LGWtZY%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "d3",
      "team": "Pittsburgh Temper",
      "score": "10",
      "oppScore": "15",
      "opp": "Ring of Fire",
      "oppId": "smh7D9CkiNxg%2bf%2b9mPfQh2h%2fUCMicaHAcvHdBji3s6k%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "pq1",
      "team": "Pittsburgh Temper",
      "score": "13",
      "oppScore": "15",
      "opp": "DiG",
      "oppId": "kn%2bHmtzxdjY7vxb%2fpJ9qsfEsWaDB6JrUBL7Ga4bU18M%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "e1",
      "team": "Pittsburgh Temper",
      "score": "12",
      "oppScore": "15",
      "opp": "Sub Zero",
      "oppId": "8R4mmVt8rvJ%2bEDsQVxGD5KkmF8Rfnl3EHt4CpzxANac%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "e3",
      "team": "Pittsburgh Temper",
      "score": "15",
      "oppScore": "10",
      "opp": "SoCal Condors",
      "oppId": "Hs8IYCPj2pf9Nump3cK24FlAdOgCZVYhTQKGBSXFLXA%3d"
    },
    {
      "date": "September 05",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "e5",
      "team": "Pittsburgh Temper",
      "score": "11",
      "oppScore": "15",
      "opp": "Doublewide",
      "oppId": "Nm8EGFzg2L2gF7kgWvfP7%2bbHNWDz7Dq77ckjxxzanlg%3d"
    },
  ],
  "s8Hq5QrzeTvvFPbmIo%2bj5qGjiFRUrzVIivVUtEM%2bvd4%3d": [
    {
      "date": "June 25",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "wlZhDy35ewQP6xLYKs4pKC7RJm9rcRzS00f2UIlxDHQ%3d",
      "team": "PoNY",
      "score": "11",
      "oppScore": "6",
      "opp": "Sprout",
      "oppId": "QyN2sOWwUrO10Eheq9tje8Trmro0kiO6dKpnQzSh9q4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "Typ%2b9SecABhBXi%2fNWaIx4hzE4Tg23cH09ozZgxrrvD4%3d",
      "team": "PoNY",
      "score": "13",
      "oppScore": "10",
      "opp": "Truck Stop",
      "oppId": "pUj3i6rzWdnpW5TeEEoriE%2fRmJjK%2brKBLe8y4LGWtZY%3d"
    },
    {
      "date": "June 25",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "xCQQgmbjmlVpxvzTGY18wd7XoMkHXusfV%2f9g%2bOogo5A%3d",
      "team": "PoNY",
      "score": "13",
      "oppScore": "12",
      "opp": "Pittsburgh Temper",
      "oppId": "u%2bSlJ3XL7d589O5YW%2bbHhuzzJ855SfIO%2bGHlzwH3w3M%3d"
    },
    {
      "date": "June 26",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "3I6zPLhXNdqol7RTfguHAVEEYMyq69A8s%2b7esCv7%2fdU%3d",
      "team": "PoNY",
      "score": "10",
      "oppScore": "9",
      "opp": "Phantom",
      "oppId": "Hc0As%2bNg1YaD%2fyL3LwaIEtSMTc23KxHOibI0ZwQLDa4%3d"
    },
    {
      "date": "June 26",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "S9JFSfgNTpeFCVj8ixHLP0qdCVLJxH2lT%2bZPJ%2bDO3kA%3d",
      "team": "PoNY",
      "score": "15",
      "oppScore": "9",
      "opp": "Vault",
      "oppId": "3xV6rIwyIem%2bql5tz%2bZmFG9gr%2bp6WKu5k4q5bwFcSp0%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "c1",
      "team": "PoNY",
      "score": "15",
      "oppScore": "13",
      "opp": "Rhino Slam!",
      "oppId": "oAJ4T9WLKOA4ICTPMYYK1645dFdRoI5WhU60sNRZIMk%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "c2",
      "team": "PoNY",
      "score": "12",
      "oppScore": "14",
      "opp": "Chicago Machine",
      "oppId": "a63rCMScaUnSuWtwLw1rcbPhZh4%2bUKiYzBab%2b52i9Pk%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "pq2",
      "team": "PoNY",
      "score": "15",
      "oppScore": "11",
      "opp": "Doublewide",
      "oppId": "Nm8EGFzg2L2gF7kgWvfP7%2bbHNWDz7Dq77ckjxxzanlg%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "q2",
      "team": "PoNY",
      "score": "13",
      "oppScore": "12",
      "opp": "Johnny Bravo",
      "oppId": "AKF9iH0VKK2mK%2fzpE9qJYeqGR%2ff%2fBETu1dm16hUGaVo%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "s1",
      "team": "PoNY",
      "score": "15",
      "oppScore": "12",
      "opp": "DiG",
      "oppId": "kn%2bHmtzxdjY7vxb%2fpJ9qsfEsWaDB6JrUBL7Ga4bU18M%3d"
    },
    {
      "date": "September 05",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "finals",
      "team": "PoNY",
      "score": "11",
      "oppScore": "15",
      "opp": "Truck Stop",
      "oppId": "pUj3i6rzWdnpW5TeEEoriE%2fRmJjK%2brKBLe8y4LGWtZY%3d"
    },
  ],
  "pUj3i6rzWdnpW5TeEEoriE%2fRmJjK%2brKBLe8y4LGWtZY%3d": [
    {
      "date": "June 25",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "rZ1Tg6SoFZ%2fsc9%2fnDp2CReTfXOQrdRU%2fyuVbxapdXvQ%3d",
      "team": "Truck Stop",
      "score": "11",
      "oppScore": "13",
      "opp": "Vault",
      "oppId": "3xV6rIwyIem%2bql5tz%2bZmFG9gr%2bp6WKu5k4q5bwFcSp0%3d"
    },
    {
      "date": "June 25",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "Typ%2b9SecABhBXi%2fNWaIx4hzE4Tg23cH09ozZgxrrvD4%3d",
      "team": "Truck Stop",
      "score": "10",
      "oppScore": "13",
      "opp": "PoNY",
      "oppId": "s8Hq5QrzeTvvFPbmIo%2bj5qGjiFRUrzVIivVUtEM%2bvd4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "g6%2bayDntlWRjoR31%2bjK3EetIscynWuH4kp7STlLZfJY%3d",
      "team": "Truck Stop",
      "score": "13",
      "oppScore": "6",
      "opp": "Phantom",
      "oppId": "Hc0As%2bNg1YaD%2fyL3LwaIEtSMTc23KxHOibI0ZwQLDa4%3d"
    },
    {
      "date": "June 26",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "n8gKFvEhPJFAvBUqsPYI4ZDLaaeiSkc3rJ8XIDBFk08%3d",
      "team": "Truck Stop",
      "score": "12",
      "oppScore": "9",
      "opp": "Sprout",
      "oppId": "QyN2sOWwUrO10Eheq9tje8Trmro0kiO6dKpnQzSh9q4%3d"
    },
    {
      "date": "June 26",
      "tournament": "Phantom Invite",
      "tournamentId": "Phantom-Invite",
      "gameId": "8pHPXXxurGTr3Cgo4fa3GS9xNSJ1G4jA1GYk%2bUerx9E%3d",
      "team": "Truck Stop",
      "score": "12",
      "oppScore": "9",
      "opp": "Pittsburgh Temper",
      "oppId": "u%2bSlJ3XL7d589O5YW%2bbHhuzzJ855SfIO%2bGHlzwH3w3M%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "7sFhxOEU52jR5napva92WcSeXSj58444laAcdZw2L1Q%3d",
      "team": "Truck Stop",
      "score": "15",
      "oppScore": "9",
      "opp": "Emerald City",
      "oppId": "m4Q4f0lvofIPyQTpoi5YZ5nNQ2WH52F0MziY%2fWjcttE%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "7uKs%2f27J0HIBNeVxLMnut2Ya0lnMOB2BNzYpIpsBWVI%3d",
      "team": "Truck Stop",
      "score": "15",
      "oppScore": "13",
      "opp": "Chain Lightning",
      "oppId": "xMA73o8VVEaOTmaBmpN0fQZ4779P9LJ9jH3j44aVabA%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "pZdRpcre2nRTPw4C8tBTb5STqXRlgKl3p5sAZnPj5a4%3d",
      "team": "Truck Stop",
      "score": "12",
      "oppScore": "15",
      "opp": "Johnny Bravo",
      "oppId": "AKF9iH0VKK2mK%2fzpE9qJYeqGR%2ff%2fBETu1dm16hUGaVo%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "ctt%2fkjCiC01VOkZAFIuVeKqSAWRNf5TSKRMKSi2KlbE%3d",
      "team": "Truck Stop",
      "score": "15",
      "oppScore": "11",
      "opp": "Revolver",
      "oppId": "Kem%2foxDeD8r5XN1HNW8eHeRyzfBqnWTRaPCmLck6F1I%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "mbpe84OvibQdB3v6aUvMCwuv4uVKzjI9sbAyyNKHsJ0%3d",
      "team": "Truck Stop",
      "score": "15",
      "oppScore": "13",
      "opp": "Chain Lightning",
      "oppId": "xMA73o8VVEaOTmaBmpN0fQZ4779P9LJ9jH3j44aVabA%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "Vri%2bPOrClzrKXZ%2b7mmV1fbSFkJ%2fEanpRnzIlHsqHnBQ%3d",
      "team": "Truck Stop",
      "score": "14",
      "oppScore": "13",
      "opp": "Johnny Bravo",
      "oppId": "AKF9iH0VKK2mK%2fzpE9qJYeqGR%2ff%2fBETu1dm16hUGaVo%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "d1",
      "team": "Truck Stop",
      "score": "15",
      "oppScore": "14",
      "opp": "Pittsburgh Temper",
      "oppId": "u%2bSlJ3XL7d589O5YW%2bbHhuzzJ855SfIO%2bGHlzwH3w3M%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "d2",
      "team": "Truck Stop",
      "score": "15",
      "oppScore": "7",
      "opp": "Ring of Fire",
      "oppId": "smh7D9CkiNxg%2bf%2b9mPfQh2h%2fUCMicaHAcvHdBji3s6k%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "cross2",
      "team": "Truck Stop",
      "score": "15",
      "oppScore": "11",
      "opp": "Johnny Bravo",
      "oppId": "AKF9iH0VKK2mK%2fzpE9qJYeqGR%2ff%2fBETu1dm16hUGaVo%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "q4",
      "team": "Truck Stop",
      "score": "15",
      "oppScore": "10",
      "opp": "Rhino Slam!",
      "oppId": "oAJ4T9WLKOA4ICTPMYYK1645dFdRoI5WhU60sNRZIMk%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "s2",
      "team": "Truck Stop",
      "score": "15",
      "oppScore": "14",
      "opp": "Ring of Fire",
      "oppId": "smh7D9CkiNxg%2bf%2b9mPfQh2h%2fUCMicaHAcvHdBji3s6k%3d"
    },
    {
      "date": "September 05",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "finals",
      "team": "Truck Stop",
      "score": "15",
      "oppScore": "11",
      "opp": "PoNY",
      "oppId": "s8Hq5QrzeTvvFPbmIo%2bj5qGjiFRUrzVIivVUtEM%2bvd4%3d"
    },
  ],
  "XNBtJjuPsH6Zq%2bmVmPwL1Zo0%2bl5xYeh3mWrrpzn7CTk%3d": [
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "AcQljDT1al9qRjEnlHA75EsPmRfX5amOIFUDoenBrzk%3d",
      "team": "Switchback",
      "score": "9",
      "oppScore": "7",
      "opp": "SOUF",
      "oppId": "apgZHT%2fi0I9dSEMbVZWQYonmRxVork%2bwAmVlY01CfPQ%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "qUSTb0iB0zCcG47SRvQRyeKpS9s92q%2bAWCSGt4vgaIk%3d",
      "team": "Switchback",
      "score": "8",
      "oppScore": "13",
      "opp": "Zyzzyva",
      "oppId": "Yfu4RKEHOC158Q5KQGT5xHxtk5hGHKp2RxFoXMEKk%2fA%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "UhDmTxT2nxkOX09twliF3M0NSX4lxfaLkBkawc%2bv4D4%3d",
      "team": "Switchback",
      "score": "9",
      "oppScore": "12",
      "opp": "Oregon Trainwreck",
      "oppId": "4kZQQUYXvneeQkXiO%2bU1YCVyOwrHOLC6tdR6dVhA%2b%2bA%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "lAX6lMzC1jqPDUE4MZrBoo9uHShas0zfVlDdhhYGRHw%3d",
      "team": "Switchback",
      "score": "11",
      "oppScore": "10",
      "opp": "Sawtooth",
      "oppId": "O%2fOvOBARFYfl3iP1PCdqMwd9SiYSMEiR%2b0E8MZeOOD4%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "zOaqYM%2fPi1ZtwK6DZL7kLTnDFIKF%2frj0wbL9nl%2bGXJk%3d",
      "team": "Switchback",
      "score": "6",
      "oppScore": "11",
      "opp": "Sawtooth",
      "oppId": "O%2fOvOBARFYfl3iP1PCdqMwd9SiYSMEiR%2b0E8MZeOOD4%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "NARpnkQHY371MH1%2bM84khFBbB3ICP%2bsZW8FBVmn3Hnc%3d",
      "team": "Switchback",
      "score": "12",
      "oppScore": "10",
      "opp": "Streetgang",
      "oppId": "%2fo6V9EACiot%2bzfjGzj7GQtyBnMoDlmHGs3Nl35pLoeU%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "7%2faClXPJyCdwN32CwSsnZFv25XB4pTIqqlyRNas24bI%3d",
      "team": "Switchback",
      "score": "9",
      "oppScore": "10",
      "opp": "Ghost Train",
      "oppId": "Tqxb9%2fb2Hxtbg%2f54ifErhjS%2bc%2fBnacDZk5RhQ%2fd%2b%2f5g%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "SyTj8nQNcPn74c7tNBihO%2fUURSP3UPpNKTiVaizZyZg%3d",
      "team": "Switchback",
      "score": "9",
      "oppScore": "10",
      "opp": "Dark Star",
      "oppId": "c9pW2q80DagMAQ3MIlN4qV7%2fnufSjZapIvSzbu5OU%2f4%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "rT3od05SgpW4rzgX1Gb3%2bWbOnvrUu%2fJINsSgltcm60k%3d",
      "team": "Switchback",
      "score": "8",
      "oppScore": "11",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "2MctzqMsWyZTpw895hElHgC7LwQMvxsJ7vd7xV16p1M%3d",
      "team": "Switchback",
      "score": "11",
      "oppScore": "12",
      "opp": "Hazard",
      "oppId": "P0N8mJF5hP7HulHfG72Zdu2tlioAZAaLyhUwPE4s6T8%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "R7PcAUFAMtE0bA8%2fP8%2bXbctuQxerVeo%2fxtndBIYYN6E%3d",
      "team": "Switchback",
      "score": "13",
      "oppScore": "7",
      "opp": "Battery",
      "oppId": "XgsOT%2bl5FkWgZoV7cQAy4vMUDJS3ygcjNozHGHsTYUE%3d"
    },
    {
      "date": "August 21",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "PlagUKjRMja8zRSLceWGjWqWLmlSJqVN0XDLnX%2bNUSI%3d",
      "team": "Switchback",
      "score": "8",
      "oppScore": "12",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "August 21",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "spzMGnUR4WC4YTwj7jkKzyj5JVM020dAxODzSAnuu3c%3d",
      "team": "Switchback",
      "score": "9",
      "oppScore": "12",
      "opp": "Sawtooth",
      "oppId": "O%2fOvOBARFYfl3iP1PCdqMwd9SiYSMEiR%2b0E8MZeOOD4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "NuwJuxdHlZQ%2fdhhbdC9hOTjtCqxKAi4shm7KszxUOfs%3d",
      "team": "Switchback",
      "score": "13",
      "oppScore": "3",
      "opp": "Offshore",
      "oppId": "z3CZ5zLpuh4%2b6lmsKpQSH%2fDpWRfMktj5DGqERnXkcXY%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "aXzfWKoZPtS5tTksD1xWOyQ1RKJ2mKYYRFpBj2R7UG8%3d",
      "team": "Switchback",
      "score": "8",
      "oppScore": "13",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "AOV621y71nqimqjmZPiOOpHRrO1potTiv69Su2n89yA%3d",
      "team": "Switchback",
      "score": "13",
      "oppScore": "10",
      "opp": "Utah Hatu",
      "oppId": "%2bmdoaqywGR6P4rhXTSYYxtvjQhBX094atWIs4EEL1Ys%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "QrI%2fx8lgcVTnhnVksGCMM8IXWaueMf%2fOwlcso9AYVs8%3d",
      "team": "Switchback",
      "score": "11",
      "oppScore": "8",
      "opp": "Drought",
      "oppId": "ksz2i7%2bjkkoIQzWKSrtEphZr9icgiUTPL3Ixum49pfY%3d"
    },
    {
      "date": "August 27",
      "tournament": "CBR 2022",
      "tournamentId": "CBR-2022",
      "gameId": "0Jkg3t1CzoJdHmtz9hcnBCKFPedYnYnpDZa30xpPayA%3d",
      "team": "Switchback",
      "score": "15",
      "oppScore": "7",
      "opp": "Ghost Train",
      "oppId": "Tqxb9%2fb2Hxtbg%2f54ifErhjS%2bc%2fBnacDZk5RhQ%2fd%2b%2f5g%3d"
    },
    {
      "date": "August 27",
      "tournament": "CBR 2022",
      "tournamentId": "CBR-2022",
      "gameId": "BWiB483B0IjiNYNzJRS%2bspJJp5y2KicEYDoLt13qds0%3d",
      "team": "Switchback",
      "score": "12",
      "oppScore": "15",
      "opp": "Dark Star",
      "oppId": "c9pW2q80DagMAQ3MIlN4qV7%2fnufSjZapIvSzbu5OU%2f4%3d"
    }
  ],
  "oAJ4T9WLKOA4ICTPMYYK1645dFdRoI5WhU60sNRZIMk%3d": [
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "J7bnVQpwlhlxXslg1wjAzk973pAFaSzSNczPgquaUoA%3d",
      "team": "Rhino Slam!",
      "score": "9",
      "oppScore": "11",
      "opp": "Furious George",
      "oppId": "AyYWHH3tsm9Kguy8GVmoEwGKAXpOtKzKP1KhenuZxfY%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "3Wg3dllqDM2tq85PUjxFWJ%2fFv%2bWNQ2x%2fvECykZjhpVQ%3d",
      "team": "Rhino Slam!",
      "score": "12",
      "oppScore": "8",
      "opp": "Revolver",
      "oppId": "Kem%2foxDeD8r5XN1HNW8eHeRyzfBqnWTRaPCmLck6F1I%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "8ecrcyVA6gnPj%2bFajALrAfjZxV96zmWd%2buI4qUqvcPw%3d",
      "team": "Rhino Slam!",
      "score": "10",
      "oppScore": "8",
      "opp": "Emerald City",
      "oppId": "m4Q4f0lvofIPyQTpoi5YZ5nNQ2WH52F0MziY%2fWjcttE%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "JX0mEBpwjcC%2bvQWt82Mfu8VtOQN8wDReDlpyL0IfKrk%3d",
      "team": "Rhino Slam!",
      "score": "8",
      "oppScore": "13",
      "opp": "Sockeye",
      "oppId": "Ur5qIXbyxiUHKLhvlB%2bSYJDI9nx7KM4Txz1fUtmqC2w%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "NVLuNt14yTcoIDGjn%2fvns7qWcxTFYR6JTe8ac%2b8E79U%3d",
      "team": "Rhino Slam!",
      "score": "13",
      "oppScore": "6",
      "opp": "Emerald City",
      "oppId": "m4Q4f0lvofIPyQTpoi5YZ5nNQ2WH52F0MziY%2fWjcttE%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "Qr4ebEG6u28FEr93sZrkKap%2bD%2ffxNmLwnOYnMZuvqKc%3d",
      "team": "Rhino Slam!",
      "score": "12",
      "oppScore": "9",
      "opp": "Furious George",
      "oppId": "AyYWHH3tsm9Kguy8GVmoEwGKAXpOtKzKP1KhenuZxfY%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "HsohIzCznir8H%2bAPBh72jJFO2XjnkgcVCYnycpSsZcw%3d",
      "team": "Rhino Slam!",
      "score": "9",
      "oppScore": "13",
      "opp": "Sockeye",
      "oppId": "Ur5qIXbyxiUHKLhvlB%2bSYJDI9nx7KM4Txz1fUtmqC2w%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "c1",
      "team": "Rhino Slam!",
      "score": "13",
      "oppScore": "15",
      "opp": "PoNY",
      "oppId": "s8Hq5QrzeTvvFPbmIo%2bj5qGjiFRUrzVIivVUtEM%2bvd4%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "c3",
      "team": "Rhino Slam!",
      "score": "11",
      "oppScore": "15",
      "opp": "Chicago Machine",
      "oppId": "a63rCMScaUnSuWtwLw1rcbPhZh4%2bUKiYzBab%2b52i9Pk%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "pq4",
      "team": "Rhino Slam!",
      "score": "14",
      "oppScore": "12",
      "opp": "SoCal Condors",
      "oppId": "Hs8IYCPj2pf9Nump3cK24FlAdOgCZVYhTQKGBSXFLXA%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "q4",
      "team": "Rhino Slam!",
      "score": "10",
      "oppScore": "15",
      "opp": "Truck Stop",
      "oppId": "pUj3i6rzWdnpW5TeEEoriE%2fRmJjK%2brKBLe8y4LGWtZY%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "f2",
      "team": "Rhino Slam!",
      "score": "14",
      "oppScore": "9",
      "opp": "Sockeye",
      "oppId": "Ur5qIXbyxiUHKLhvlB%2bSYJDI9nx7KM4Txz1fUtmqC2w%3d"
    },
    {
      "date": "September 05",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "fifth",
      "team": "Rhino Slam!",
      "score": "12",
      "oppScore": "11",
      "opp": "Johnny Bravo",
      "oppId": "AKF9iH0VKK2mK%2fzpE9qJYeqGR%2ff%2fBETu1dm16hUGaVo%3d"
    },
  ],
  "apgZHT%2fi0I9dSEMbVZWQYonmRxVork%2bwAmVlY01CfPQ%3d": [
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "AcQljDT1al9qRjEnlHA75EsPmRfX5amOIFUDoenBrzk%3d",
      "team": "SOUF",
      "score": "7",
      "oppScore": "9",
      "opp": "Switchback",
      "oppId": "XNBtJjuPsH6Zq%2bmVmPwL1Zo0%2bl5xYeh3mWrrpzn7CTk%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "xltpq%2ft1Wobx3D7KncY6VSe%2fXzcSbnnMNPznk5t35KE%3d",
      "team": "SOUF",
      "score": "9",
      "oppScore": "13",
      "opp": "Oregon Trainwreck",
      "oppId": "4kZQQUYXvneeQkXiO%2bU1YCVyOwrHOLC6tdR6dVhA%2b%2bA%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "jNR%2bWTlVWMZofktc%2bd881wbuZqGO8VUfyCLUsdtAZao%3d",
      "team": "SOUF",
      "score": "11",
      "oppScore": "8",
      "opp": "Zyzzyva",
      "oppId": "Yfu4RKEHOC158Q5KQGT5xHxtk5hGHKp2RxFoXMEKk%2fA%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "75OB31B0TgY3%2fMgkJBulVfOhGp4%2bKSAxTGRmHNToONU%3d",
      "team": "SOUF",
      "score": "11",
      "oppScore": "9",
      "opp": "Name Brand",
      "oppId": "nD%2bVkshg%2ftrTn6m%2bBloXZ0U8vTke1bsPdPY%2b4AI%2fStA%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "gPDrvnQas1Qqi2WtN1dv7r3TSDtHhRCHkDtds2PaaBs%3d",
      "team": "SOUF",
      "score": "7",
      "oppScore": "9",
      "opp": "Sawtooth",
      "oppId": "O%2fOvOBARFYfl3iP1PCdqMwd9SiYSMEiR%2b0E8MZeOOD4%3d"
    },
    {
      "date": "August 27",
      "tournament": "CBR 2022",
      "tournamentId": "CBR-2022",
      "gameId": "WsvKsebOARW%2bZ1gVIQ7v85TWtSGTIZNaqN3DWO7%2b03c%3d",
      "team": "SOUF",
      "score": "14",
      "oppScore": "6",
      "opp": "Name Brand",
      "oppId": "nD%2bVkshg%2ftrTn6m%2bBloXZ0U8vTke1bsPdPY%2b4AI%2fStA%3d"
    },
    {
      "date": "August 27",
      "tournament": "CBR 2022",
      "tournamentId": "CBR-2022",
      "gameId": "6lRLFZWBSzwlNWRuhSQJBcXxivX7T%2f8Y9yv6mlvb3kU%3d",
      "team": "SOUF",
      "score": "12",
      "oppScore": "10",
      "opp": "Make it Rain",
      "oppId": "GNSXf0PP6g6tw8m0RFzPDaKIh%2fXdPOhBGKFYOiBXFeQ%3d"
    }
  ],
  "Kem%2foxDeD8r5XN1HNW8eHeRyzfBqnWTRaPCmLck6F1I%3d": [
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "s1GXZZk9x9Xsno1GehNWmib4mpEZYbtGmf7%2bqxD5vDg%3d",
      "team": "Revolver",
      "score": "8",
      "oppScore": "12",
      "opp": "Furious George",
      "oppId": "AyYWHH3tsm9Kguy8GVmoEwGKAXpOtKzKP1KhenuZxfY%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "TrE1%2bhFQDZvfaovXN6hJWVLPCRzPHQA1ORLEM6USn%2b0%3d",
      "team": "Revolver",
      "score": "7",
      "oppScore": "13",
      "opp": "Sockeye",
      "oppId": "Ur5qIXbyxiUHKLhvlB%2bSYJDI9nx7KM4Txz1fUtmqC2w%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "3Wg3dllqDM2tq85PUjxFWJ%2fFv%2bWNQ2x%2fvECykZjhpVQ%3d",
      "team": "Revolver",
      "score": "8",
      "oppScore": "12",
      "opp": "Rhino Slam!",
      "oppId": "oAJ4T9WLKOA4ICTPMYYK1645dFdRoI5WhU60sNRZIMk%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "jfJFimwNHXFcCD0TGRw4%2fmVxfmf3J%2fBKokzPhDDXMRQ%3d",
      "team": "Revolver",
      "score": "13",
      "oppScore": "8",
      "opp": "Emerald City",
      "oppId": "m4Q4f0lvofIPyQTpoi5YZ5nNQ2WH52F0MziY%2fWjcttE%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "23V%2bWcBECTUDVvgHA8Vai%2byXNWwdLfsss3tuMaGHhaI%3d",
      "team": "Revolver",
      "score": "13",
      "oppScore": "5",
      "opp": "Dark Star",
      "oppId": "c9pW2q80DagMAQ3MIlN4qV7%2fnufSjZapIvSzbu5OU%2f4%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "WkQC51Hos2g2z3D5i826y5vlrOY20rv%2bmjl1rwp1dKE%3d",
      "team": "Revolver",
      "score": "9",
      "oppScore": "13",
      "opp": "Sockeye",
      "oppId": "Ur5qIXbyxiUHKLhvlB%2bSYJDI9nx7KM4Txz1fUtmqC2w%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "hQPTDdmV04yQ71fB5RVHvlPREXjpKdZz6PwgN%2fIVVgY%3d",
      "team": "Revolver",
      "score": "15",
      "oppScore": "1",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "WlFx51PnXJtnVf3lc0KzutOp8hP4tQIbZLwa%2f9iuGI8%3d",
      "team": "Revolver",
      "score": "14",
      "oppScore": "12",
      "opp": "SoCal Condors",
      "oppId": "Hs8IYCPj2pf9Nump3cK24FlAdOgCZVYhTQKGBSXFLXA%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "Fhb0CLjmowO3BG3NkwQrh48x0kWjQhMz4%2fJNMc9hVD4%3d",
      "team": "Revolver",
      "score": "13",
      "oppScore": "14",
      "opp": "Johnny Bravo",
      "oppId": "AKF9iH0VKK2mK%2fzpE9qJYeqGR%2ff%2fBETu1dm16hUGaVo%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "ZRaM5zuAyhpU8%2bBhb3p0eo0uF9xGuiOqtFgellXKJ%2bg%3d",
      "team": "Revolver",
      "score": "14",
      "oppScore": "6",
      "opp": "Clutch",
      "oppId": "JDc%2bBsCjGUX7gdebRqmdPvq1u2rFlZ1giM6BgTaL5Lk%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "t8dznbnaMCM6ZpXJeZk%2bkCLNyjN1L%2b2lkFkdDVEKMEI%3d",
      "team": "Revolver",
      "score": "12",
      "oppScore": "9",
      "opp": "SoCal Condors",
      "oppId": "Hs8IYCPj2pf9Nump3cK24FlAdOgCZVYhTQKGBSXFLXA%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "6vR6Iy%2fFALg6kV7KFR3TifOwx4E%2fKogJWShg1%2befUdo%3d",
      "team": "Revolver",
      "score": "13",
      "oppScore": "15",
      "opp": "Johnny Bravo",
      "oppId": "AKF9iH0VKK2mK%2fzpE9qJYeqGR%2ff%2fBETu1dm16hUGaVo%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "VL1bCYx3i8gODc117vldPSVjV2aQSjT%2bOvOKYJLENuA%3d",
      "team": "Revolver",
      "score": "11",
      "oppScore": "15",
      "opp": "DiG",
      "oppId": "kn%2bHmtzxdjY7vxb%2fpJ9qsfEsWaDB6JrUBL7Ga4bU18M%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "Qy93cRlsWroBTf9uFmr%2bquMI5qzIneGak7780e7Psa4%3d",
      "team": "Revolver",
      "score": "15",
      "oppScore": "13",
      "opp": "Pando",
      "oppId": "CWW3TWk5yeYKuKEW8USBkbYZz5DyfbKjfswKBgSzYsA%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "Wq9%2bakluwH2OqD9o0bSidbdjPoIurUADNawTxD3sCJM%3d",
      "team": "Revolver",
      "score": "15",
      "oppScore": "4",
      "opp": "Inception",
      "oppId": "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "ctt%2fkjCiC01VOkZAFIuVeKqSAWRNf5TSKRMKSi2KlbE%3d",
      "team": "Revolver",
      "score": "11",
      "oppScore": "15",
      "opp": "Truck Stop",
      "oppId": "pUj3i6rzWdnpW5TeEEoriE%2fRmJjK%2brKBLe8y4LGWtZY%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "Ojt4yqkytJhCAFrz2pHBrcc1t5M02mKxcG7WHrgdEUg%3d",
      "team": "Revolver",
      "score": "15",
      "oppScore": "12",
      "opp": "Chicago Machine",
      "oppId": "a63rCMScaUnSuWtwLw1rcbPhZh4%2bUKiYzBab%2b52i9Pk%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "Me9ov0cjH8ir29J29WfArdD8OPfoQM40L5zGQ2eyrPM%3d",
      "team": "Revolver",
      "score": "15",
      "oppScore": "4",
      "opp": "Pando",
      "oppId": "CWW3TWk5yeYKuKEW8USBkbYZz5DyfbKjfswKBgSzYsA%3d"
    },
    {
      "date": "August 20",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "XnqwtUnRvLFA2srTdfs2lLZRl%2ftmuJ%2bYVCLOO32Ood0%3d",
      "team": "Revolver",
      "score": "15",
      "oppScore": "10",
      "opp": "Emerald City",
      "oppId": "m4Q4f0lvofIPyQTpoi5YZ5nNQ2WH52F0MziY%2fWjcttE%3d"
    },
    {
      "date": "August 20",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "mS6ZrD5V3O6Wmb9x1LbGLZyYzInFsETMr%2bQqBQjIoa0%3d",
      "team": "Revolver",
      "score": "15",
      "oppScore": "11",
      "opp": "Furious George",
      "oppId": "AyYWHH3tsm9Kguy8GVmoEwGKAXpOtKzKP1KhenuZxfY%3d"
    },
    {
      "date": "August 20",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "kqHXihiaZZ3mLVt8YeBdukjyvfwMHUuvs%2bqZteMD0NE%3d",
      "team": "Revolver",
      "score": "15",
      "oppScore": "7",
      "opp": "Battery",
      "oppId": "XgsOT%2bl5FkWgZoV7cQAy4vMUDJS3ygcjNozHGHsTYUE%3d"
    },
    {
      "date": "August 21",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "dL1FfRVxnrJ5qxhLYvzGS1jGXGG%2bMAZZPdEqcqDTNyI%3d",
      "team": "Revolver",
      "score": "15",
      "oppScore": "10",
      "opp": "OAT",
      "oppId": "3jrAxF6U290JZyiuGff9bkOyKPIspXXtN94jCffcvgs%3d"
    },
    {
      "date": "August 21",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "OKoZj%2bf6h8J1EzB8rEZOPLundH0aYWi6Ytann4qxTok%3d",
      "team": "Revolver",
      "score": "15",
      "oppScore": "12",
      "opp": "Emerald City",
      "oppId": "m4Q4f0lvofIPyQTpoi5YZ5nNQ2WH52F0MziY%2fWjcttE%3d"
    }
  ],
  "Ur5qIXbyxiUHKLhvlB%2bSYJDI9nx7KM4Txz1fUtmqC2w%3d": [
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "XLKFMgt012awpgFvwUn0funLtL90ZZrYVpB7ovjHubc%3d",
      "team": "Sockeye",
      "score": "13",
      "oppScore": "6",
      "opp": "Emerald City",
      "oppId": "m4Q4f0lvofIPyQTpoi5YZ5nNQ2WH52F0MziY%2fWjcttE%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "TrE1%2bhFQDZvfaovXN6hJWVLPCRzPHQA1ORLEM6USn%2b0%3d",
      "team": "Sockeye",
      "score": "13",
      "oppScore": "7",
      "opp": "Revolver",
      "oppId": "Kem%2foxDeD8r5XN1HNW8eHeRyzfBqnWTRaPCmLck6F1I%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "IAEhk8qMjQy1y0pb6Fb3QjquQGp2L97MK5y9M7cze64%3d",
      "team": "Sockeye",
      "score": "13",
      "oppScore": "11",
      "opp": "Furious George",
      "oppId": "AyYWHH3tsm9Kguy8GVmoEwGKAXpOtKzKP1KhenuZxfY%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "JX0mEBpwjcC%2bvQWt82Mfu8VtOQN8wDReDlpyL0IfKrk%3d",
      "team": "Sockeye",
      "score": "13",
      "oppScore": "8",
      "opp": "Rhino Slam!",
      "oppId": "oAJ4T9WLKOA4ICTPMYYK1645dFdRoI5WhU60sNRZIMk%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "cg2PSouLFpAQoLhiKCZINZtFbUewfHJmDAeIcvv21Ig%3d",
      "team": "Sockeye",
      "score": "13",
      "oppScore": "2",
      "opp": "Oregon Trainwreck",
      "oppId": "4kZQQUYXvneeQkXiO%2bU1YCVyOwrHOLC6tdR6dVhA%2b%2bA%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "WkQC51Hos2g2z3D5i826y5vlrOY20rv%2bmjl1rwp1dKE%3d",
      "team": "Sockeye",
      "score": "13",
      "oppScore": "9",
      "opp": "Revolver",
      "oppId": "Kem%2foxDeD8r5XN1HNW8eHeRyzfBqnWTRaPCmLck6F1I%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "HsohIzCznir8H%2bAPBh72jJFO2XjnkgcVCYnycpSsZcw%3d",
      "team": "Sockeye",
      "score": "13",
      "oppScore": "9",
      "opp": "Rhino Slam!",
      "oppId": "oAJ4T9WLKOA4ICTPMYYK1645dFdRoI5WhU60sNRZIMk%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "a1",
      "team": "Sockeye",
      "score": "13",
      "oppScore": "12",
      "opp": "SoCal Condors",
      "oppId": "Hs8IYCPj2pf9Nump3cK24FlAdOgCZVYhTQKGBSXFLXA%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "a2",
      "team": "Sockeye",
      "score": "15",
      "oppScore": "11",
      "opp": "Doublewide",
      "oppId": "Nm8EGFzg2L2gF7kgWvfP7%2bbHNWDz7Dq77ckjxxzanlg%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "cross1",
      "team": "Sockeye",
      "score": "14",
      "oppScore": "15",
      "opp": "Chicago Machine",
      "oppId": "a63rCMScaUnSuWtwLw1rcbPhZh4%2bUKiYzBab%2b52i9Pk%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "q3",
      "team": "Sockeye",
      "score": "12",
      "oppScore": "14",
      "opp": "Ring of Fire",
      "oppId": "smh7D9CkiNxg%2bf%2b9mPfQh2h%2fUCMicaHAcvHdBji3s6k%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "f2",
      "team": "Sockeye",
      "score": "9",
      "oppScore": "14",
      "opp": "Rhino Slam!",
      "oppId": "oAJ4T9WLKOA4ICTPMYYK1645dFdRoI5WhU60sNRZIMk%3d"
    },
    {
      "date": "September 05",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "seventh",
      "team": "Sockeye",
      "score": "10",
      "oppScore": "13",
      "opp": "Chicago Machine",
      "oppId": "a63rCMScaUnSuWtwLw1rcbPhZh4%2bUKiYzBab%2b52i9Pk%3d"
    },
  ],
  "nD%2bVkshg%2ftrTn6m%2bBloXZ0U8vTke1bsPdPY%2b4AI%2fStA%3d": [
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "QSrz52zxkt5pX1Td1i5ETgU9yvXqzMfHbBCBV3sB2fQ%3d",
      "team": "Name Brand",
      "score": "7",
      "oppScore": "13",
      "opp": "Ghost Train",
      "oppId": "Tqxb9%2fb2Hxtbg%2f54ifErhjS%2bc%2fBnacDZk5RhQ%2fd%2b%2f5g%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "V9sCABA9igCkhOrfYGJzY%2ffgVLY9R4tbJQRO4ISZA1w%3d",
      "team": "Name Brand",
      "score": "4",
      "oppScore": "13",
      "opp": "Dark Star",
      "oppId": "c9pW2q80DagMAQ3MIlN4qV7%2fnufSjZapIvSzbu5OU%2f4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "6HQR%2bu89l22yEArJA9SWf4BTk1wUb9oRUnYbOEmdWsA%3d",
      "team": "Name Brand",
      "score": "3",
      "oppScore": "13",
      "opp": "Sawtooth",
      "oppId": "O%2fOvOBARFYfl3iP1PCdqMwd9SiYSMEiR%2b0E8MZeOOD4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "75OB31B0TgY3%2fMgkJBulVfOhGp4%2bKSAxTGRmHNToONU%3d",
      "team": "Name Brand",
      "score": "9",
      "oppScore": "11",
      "opp": "SOUF",
      "oppId": "apgZHT%2fi0I9dSEMbVZWQYonmRxVork%2bwAmVlY01CfPQ%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "5AD%2f3aYWjWmi5hMHpcPRetd0YsKtEFXPofvibWT5DzQ%3d",
      "team": "Name Brand",
      "score": "8",
      "oppScore": "13",
      "opp": "Ghost Train",
      "oppId": "Tqxb9%2fb2Hxtbg%2f54ifErhjS%2bc%2fBnacDZk5RhQ%2fd%2b%2f5g%3d"
    },
    {
      "date": "August 27",
      "tournament": "CBR 2022",
      "tournamentId": "CBR-2022",
      "gameId": "WsvKsebOARW%2bZ1gVIQ7v85TWtSGTIZNaqN3DWO7%2b03c%3d",
      "team": "Name Brand",
      "score": "6",
      "oppScore": "14",
      "opp": "SOUF",
      "oppId": "apgZHT%2fi0I9dSEMbVZWQYonmRxVork%2bwAmVlY01CfPQ%3d"
    },
    {
      "date": "August 27",
      "tournament": "CBR 2022",
      "tournamentId": "CBR-2022",
      "gameId": "tHw6Dc8L%2b9BRyvh6nva5flPDo9atbXB9ybyvdFqI9p8%3d",
      "team": "Name Brand",
      "score": "10",
      "oppScore": "15",
      "opp": "Streetgang",
      "oppId": "%2fo6V9EACiot%2bzfjGzj7GQtyBnMoDlmHGs3Nl35pLoeU%3d"
    },
    {
      "date": "August 27",
      "tournament": "CBR 2022",
      "tournamentId": "CBR-2022",
      "gameId": "IYqMjekZYFr6Wm00upday3g0EUXfDxLO0sMqSTZmJMs%3d",
      "team": "Name Brand",
      "score": "6",
      "oppScore": "15",
      "opp": "Ghost Train",
      "oppId": "Tqxb9%2fb2Hxtbg%2f54ifErhjS%2bc%2fBnacDZk5RhQ%2fd%2b%2f5g%3d"
    }
  ],
  "Yfu4RKEHOC158Q5KQGT5xHxtk5hGHKp2RxFoXMEKk%2fA%3d": [
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "N8IgsjKWHJi6cRQcuOPOH9%2bPiK5RtNa5ph20MhOz04E%3d",
      "team": "Zyzzyva",
      "score": "13",
      "oppScore": "9",
      "opp": "Oregon Trainwreck",
      "oppId": "4kZQQUYXvneeQkXiO%2bU1YCVyOwrHOLC6tdR6dVhA%2b%2bA%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "qUSTb0iB0zCcG47SRvQRyeKpS9s92q%2bAWCSGt4vgaIk%3d",
      "team": "Zyzzyva",
      "score": "13",
      "oppScore": "8",
      "opp": "Switchback",
      "oppId": "XNBtJjuPsH6Zq%2bmVmPwL1Zo0%2bl5xYeh3mWrrpzn7CTk%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "jNR%2bWTlVWMZofktc%2bd881wbuZqGO8VUfyCLUsdtAZao%3d",
      "team": "Zyzzyva",
      "score": "8",
      "oppScore": "11",
      "opp": "SOUF",
      "oppId": "apgZHT%2fi0I9dSEMbVZWQYonmRxVork%2bwAmVlY01CfPQ%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "RbM5QBxU8C6L7VAb3plGuPIZssbAqM896hZw2PM18JM%3d",
      "team": "Zyzzyva",
      "score": "9",
      "oppScore": "10",
      "opp": "Dark Star",
      "oppId": "c9pW2q80DagMAQ3MIlN4qV7%2fnufSjZapIvSzbu5OU%2f4%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "p4LBdy2wFK%2bq7RO1LvZSG13jbmT1HcyULXuoQ3OP2wA%3d",
      "team": "Zyzzyva",
      "score": "5",
      "oppScore": "13",
      "opp": "Furious George",
      "oppId": "AyYWHH3tsm9Kguy8GVmoEwGKAXpOtKzKP1KhenuZxfY%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "4Vwg%2bDQAK6Q2TLJsPRhkDc%2bfV%2fjFvohBEck1QScbLns%3d",
      "team": "Zyzzyva",
      "score": "4",
      "oppScore": "13",
      "opp": "Emerald City",
      "oppId": "m4Q4f0lvofIPyQTpoi5YZ5nNQ2WH52F0MziY%2fWjcttE%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "KjC1TfeNJagXdQfeDlKUPs%2bLBAlAmRjrIHSZaVXM13E%3d",
      "team": "Zyzzyva",
      "score": "F",
      "oppScore": "W",
      "opp": "Oregon Trainwreck",
      "oppId": "4kZQQUYXvneeQkXiO%2bU1YCVyOwrHOLC6tdR6dVhA%2b%2bA%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "HaMRuplGKJbh2vNw4bsYIqtyX%2bNQFQ7c0BNBebjxk3E%3d",
      "team": "Zyzzyva",
      "score": "6",
      "oppScore": "15",
      "opp": "Johnny Bravo",
      "oppId": "AKF9iH0VKK2mK%2fzpE9qJYeqGR%2ff%2fBETu1dm16hUGaVo%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "cfqPuPcJUvVX8BF6HvKTdyuX6ow0pU%2fGE4tjAcxMoaQ%3d",
      "team": "Zyzzyva",
      "score": "11",
      "oppScore": "12",
      "opp": "Flash Flood",
      "oppId": "JQrYQgb4YJNC2aMynOWjHiZmxJvsuqeLyz2Red9EabU%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "Fy0K7aKRaYKjZ9kIoxxKTiYAhlznHAqG%2f3NT85nXI8g%3d",
      "team": "Zyzzyva",
      "score": "9",
      "oppScore": "15",
      "opp": "SoCal Condors",
      "oppId": "Hs8IYCPj2pf9Nump3cK24FlAdOgCZVYhTQKGBSXFLXA%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "GpGglcFRFoMBGT%2fy3mIdidHiVZjpRfqAhQk0BWUgDnM%3d",
      "team": "Zyzzyva",
      "score": "11",
      "oppScore": "10",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "2PzsO5YveB%2fFjM8tFCMGdbqcBfULyMz%2favNr6EU%2bZMI%3d",
      "team": "Zyzzyva",
      "score": "10",
      "oppScore": "8",
      "opp": "Battery",
      "oppId": "XgsOT%2bl5FkWgZoV7cQAy4vMUDJS3ygcjNozHGHsTYUE%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "TZGGRXGHQG9qmTPL0JaOOI2LJtEeGnjpqe5UA1XY0jo%3d",
      "team": "Zyzzyva",
      "score": "13",
      "oppScore": "11",
      "opp": "Ghost Train",
      "oppId": "Tqxb9%2fb2Hxtbg%2f54ifErhjS%2bc%2fBnacDZk5RhQ%2fd%2b%2f5g%3d"
    },
    {
      "date": "August 21",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "mQQbovS2EqR6UFrajHQQwquqlNMhKcXFk8W81hNhH0c%3d",
      "team": "Zyzzyva",
      "score": "13",
      "oppScore": "10",
      "opp": "Drought",
      "oppId": "ksz2i7%2bjkkoIQzWKSrtEphZr9icgiUTPL3Ixum49pfY%3d"
    },
    {
      "date": "August 21",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "X9ZNdvU653P6R7nPCcUP7Cs2zrJCPfLM6xtCO6hzaM8%3d",
      "team": "Zyzzyva",
      "score": "13",
      "oppScore": "8",
      "opp": "Sawtooth",
      "oppId": "O%2fOvOBARFYfl3iP1PCdqMwd9SiYSMEiR%2b0E8MZeOOD4%3d"
    },
    {
      "date": "August 21",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "BDSWA3WKQlKhEhxuQCk3dszdiySXAcAqUrWdUBLU2xM%3d",
      "team": "Zyzzyva",
      "score": "11",
      "oppScore": "13",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "tGKdKu5JqWIiLIYy0i70%2bZZ%2bfdJf3jQNYYgWAREFjSc%3d",
      "team": "Zyzzyva",
      "score": "13",
      "oppScore": "8",
      "opp": "PowderHogs",
      "oppId": "4M9MJYyVOotcr31jr1X%2bW%2fnNzBLDCtMhEBgjHQi1W9s%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "6FHXAkrO7dRuaYw6Bb%2bDLqqyHoNtmobD%2b%2b5QVOWziFw%3d",
      "team": "Zyzzyva",
      "score": "7",
      "oppScore": "9",
      "opp": "Sawtooth",
      "oppId": "O%2fOvOBARFYfl3iP1PCdqMwd9SiYSMEiR%2b0E8MZeOOD4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "8oImPzUzI5rXAjWvGcqkptytFWJ5%2bWLR0rQrHvD9wCw%3d",
      "team": "Zyzzyva",
      "score": "11",
      "oppScore": "13",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "23DgN7oxqVFV9IpXQrdhR22hlMIkHSPeXoyzl%2fPJqEA%3d",
      "team": "Zyzzyva",
      "score": "13",
      "oppScore": "3",
      "opp": "Seven Out",
      "oppId": "EKT%2b4wN3oW3%2bvTZF1BOVy%2fpLrku%2frikWkw8iDqstIOQ%3d"
    }
  ],
  "4kZQQUYXvneeQkXiO%2bU1YCVyOwrHOLC6tdR6dVhA%2b%2bA%3d": [
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "N8IgsjKWHJi6cRQcuOPOH9%2bPiK5RtNa5ph20MhOz04E%3d",
      "team": "Oregon Trainwreck",
      "score": "9",
      "oppScore": "13",
      "opp": "Zyzzyva",
      "oppId": "Yfu4RKEHOC158Q5KQGT5xHxtk5hGHKp2RxFoXMEKk%2fA%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "xltpq%2ft1Wobx3D7KncY6VSe%2fXzcSbnnMNPznk5t35KE%3d",
      "team": "Oregon Trainwreck",
      "score": "13",
      "oppScore": "9",
      "opp": "SOUF",
      "oppId": "apgZHT%2fi0I9dSEMbVZWQYonmRxVork%2bwAmVlY01CfPQ%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "UhDmTxT2nxkOX09twliF3M0NSX4lxfaLkBkawc%2bv4D4%3d",
      "team": "Oregon Trainwreck",
      "score": "12",
      "oppScore": "9",
      "opp": "Switchback",
      "oppId": "XNBtJjuPsH6Zq%2bmVmPwL1Zo0%2bl5xYeh3mWrrpzn7CTk%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "vMLd4aycFBrtgTGSzr0IWuY%2bk1K5yuufs%2f6YSfU6ayE%3d",
      "team": "Oregon Trainwreck",
      "score": "13",
      "oppScore": "5",
      "opp": "Ghost Train",
      "oppId": "Tqxb9%2fb2Hxtbg%2f54ifErhjS%2bc%2fBnacDZk5RhQ%2fd%2b%2f5g%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "cg2PSouLFpAQoLhiKCZINZtFbUewfHJmDAeIcvv21Ig%3d",
      "team": "Oregon Trainwreck",
      "score": "2",
      "oppScore": "13",
      "opp": "Sockeye",
      "oppId": "Ur5qIXbyxiUHKLhvlB%2bSYJDI9nx7KM4Txz1fUtmqC2w%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "sqgstMtKa2BDaLtUQk%2b21sqDdDrJEJimX3wRwU5AJSs%3d",
      "team": "Oregon Trainwreck",
      "score": "8",
      "oppScore": "13",
      "opp": "Dark Star",
      "oppId": "c9pW2q80DagMAQ3MIlN4qV7%2fnufSjZapIvSzbu5OU%2f4%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "KjC1TfeNJagXdQfeDlKUPs%2bLBAlAmRjrIHSZaVXM13E%3d",
      "team": "Oregon Trainwreck",
      "score": "W",
      "oppScore": "F",
      "opp": "Zyzzyva",
      "oppId": "Yfu4RKEHOC158Q5KQGT5xHxtk5hGHKp2RxFoXMEKk%2fA%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "VDasABGNNZ3Vr6uC%2bUDfzzqLGt8o3lXlsRUyVUExwic%3d",
      "team": "Oregon Trainwreck",
      "score": "10",
      "oppScore": "11",
      "opp": "smOAK",
      "oppId": "wJwgr6ieDLPO2Y%2fODqgMXB7Ah9cCIzQW3Znr1W%2bwLMc%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "eUWzuIzu0T%2fBRBJ9R77Tqzo%2fjWirqZwbpaSaXy16yKg%3d",
      "team": "Oregon Trainwreck",
      "score": "14",
      "oppScore": "7",
      "opp": "Foggy Bottom Boys",
      "oppId": "pZBAWiTtMe65S%2b5DyaXh%2fK8oMrDmXk6ny66TYS85X7U%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "dVv7vQaTr70gT%2bAVanNvxxvLCXzj%2bmrbNNa32VmlFrs%3d",
      "team": "Oregon Trainwreck",
      "score": "15",
      "oppScore": "12",
      "opp": "Red Dawn",
      "oppId": "wgus%2bDUjcIEQFNwcW6C2SbArugk%2b1zLeWnZguxqHXlE%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "6ERZaIxLhvrtjx%2bukj4Z09Pmo7bfZZQVc%2fjHY4wKYcA%3d",
      "team": "Oregon Trainwreck",
      "score": "9",
      "oppScore": "15",
      "opp": "Utah Swarm U20 Boys",
      "oppId": "7zOE8CWHFub%2bZUOhOxBboFAMcpZTTbC8qJbOkBlESOk%3d"
    },
    {
      "date": "August 08",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "8gxWB8CMJ2gxNPwCHr%2bPxCgmpe%2bvH42kKP9g%2fpWK8SE%3d",
      "team": "Oregon Trainwreck",
      "score": "7",
      "oppScore": "12",
      "opp": "Triforce",
      "oppId": "bE9A3Qj1MqW7pCIHHtQnnzKx11rAl2O9wYRSSZQ4Z40%3d"
    },
    {
      "date": "August 08",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "9CA3FEcua0QcTIh9QJLlX4vxJRsf3iI2%2bU%2fNhf38fnc%3d",
      "team": "Oregon Trainwreck",
      "score": "10",
      "oppScore": "9",
      "opp": "Seven Hills U20 Open",
      "oppId": "2D%2bFPn8oCjm3tG6NxWZp2Dp0JX6jLR1rE1uzYoJ%2f6lI%3d"
    }
  ],
  "AyYWHH3tsm9Kguy8GVmoEwGKAXpOtKzKP1KhenuZxfY%3d": [
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "s1GXZZk9x9Xsno1GehNWmib4mpEZYbtGmf7%2bqxD5vDg%3d",
      "team": "Furious George",
      "score": "12",
      "oppScore": "8",
      "opp": "Revolver",
      "oppId": "Kem%2foxDeD8r5XN1HNW8eHeRyzfBqnWTRaPCmLck6F1I%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "J7bnVQpwlhlxXslg1wjAzk973pAFaSzSNczPgquaUoA%3d",
      "team": "Furious George",
      "score": "11",
      "oppScore": "9",
      "opp": "Rhino Slam!",
      "oppId": "oAJ4T9WLKOA4ICTPMYYK1645dFdRoI5WhU60sNRZIMk%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "GuCtThE1G%2bPrk84a1EcZK8ks1AZ47liUQ7%2fsI%2fttYYI%3d",
      "team": "Furious George",
      "score": "11",
      "oppScore": "6",
      "opp": "Emerald City",
      "oppId": "m4Q4f0lvofIPyQTpoi5YZ5nNQ2WH52F0MziY%2fWjcttE%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "IAEhk8qMjQy1y0pb6Fb3QjquQGp2L97MK5y9M7cze64%3d",
      "team": "Furious George",
      "score": "11",
      "oppScore": "13",
      "opp": "Sockeye",
      "oppId": "Ur5qIXbyxiUHKLhvlB%2bSYJDI9nx7KM4Txz1fUtmqC2w%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "p4LBdy2wFK%2bq7RO1LvZSG13jbmT1HcyULXuoQ3OP2wA%3d",
      "team": "Furious George",
      "score": "13",
      "oppScore": "5",
      "opp": "Zyzzyva",
      "oppId": "Yfu4RKEHOC158Q5KQGT5xHxtk5hGHKp2RxFoXMEKk%2fA%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "Qr4ebEG6u28FEr93sZrkKap%2bD%2ffxNmLwnOYnMZuvqKc%3d",
      "team": "Furious George",
      "score": "9",
      "oppScore": "12",
      "opp": "Rhino Slam!",
      "oppId": "oAJ4T9WLKOA4ICTPMYYK1645dFdRoI5WhU60sNRZIMk%3d"
    },
    {
      "date": "August 20",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "Uam7rsrnYUcnDyDu0zuYWOGwnSplrDVh2xMmI5UbVEE%3d",
      "team": "Furious George",
      "score": "15",
      "oppScore": "11",
      "opp": "Emerald City",
      "oppId": "m4Q4f0lvofIPyQTpoi5YZ5nNQ2WH52F0MziY%2fWjcttE%3d"
    },
    {
      "date": "August 20",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "M1oQwnL43vr9E8bngFn6HWyNVLcaF8DRqyddBgaW%2bwg%3d",
      "team": "Furious George",
      "score": "15",
      "oppScore": "9",
      "opp": "OAT",
      "oppId": "3jrAxF6U290JZyiuGff9bkOyKPIspXXtN94jCffcvgs%3d"
    },
    {
      "date": "August 20",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "mS6ZrD5V3O6Wmb9x1LbGLZyYzInFsETMr%2bQqBQjIoa0%3d",
      "team": "Furious George",
      "score": "11",
      "oppScore": "15",
      "opp": "Revolver",
      "oppId": "Kem%2foxDeD8r5XN1HNW8eHeRyzfBqnWTRaPCmLck6F1I%3d"
    },
    {
      "date": "August 21",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "plAsYmNhx5vfq6UcqtjI5VY92bIZDt2%2bnfVNCWlDuGY%3d",
      "team": "Furious George",
      "score": "15",
      "oppScore": "4",
      "opp": "Battery",
      "oppId": "XgsOT%2bl5FkWgZoV7cQAy4vMUDJS3ygcjNozHGHsTYUE%3d"
    },
    {
      "date": "August 21",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "FaYpEI1U61o1k90FSVDTCgUJ0vOTYDbQeHDC8D5DOjk%3d",
      "team": "Furious George",
      "score": "13",
      "oppScore": "15",
      "opp": "Emerald City",
      "oppId": "m4Q4f0lvofIPyQTpoi5YZ5nNQ2WH52F0MziY%2fWjcttE%3d"
    },
    {
      "date": "August 21",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "bpQom34xcm3veCNM0UWHiud5PeNoB0Y2JFyEQ13R7dM%3d",
      "team": "Furious George",
      "score": "15",
      "oppScore": "11",
      "opp": "OAT",
      "oppId": "3jrAxF6U290JZyiuGff9bkOyKPIspXXtN94jCffcvgs%3d"
    }
  ],
  "O%2fOvOBARFYfl3iP1PCdqMwd9SiYSMEiR%2b0E8MZeOOD4%3d": [
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "RHW9h8mZRePF8NN%2f4sBF8ktxpJeZTd%2bEBXDdBTLM6i8%3d",
      "team": "Sawtooth",
      "score": "10",
      "oppScore": "11",
      "opp": "Dark Star",
      "oppId": "c9pW2q80DagMAQ3MIlN4qV7%2fnufSjZapIvSzbu5OU%2f4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "CitwmRVv2qeWbsmZC8O6af8WsHIK8Rz%2fJwSZdvSxYh8%3d",
      "team": "Sawtooth",
      "score": "10",
      "oppScore": "13",
      "opp": "Ghost Train",
      "oppId": "Tqxb9%2fb2Hxtbg%2f54ifErhjS%2bc%2fBnacDZk5RhQ%2fd%2b%2f5g%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "6HQR%2bu89l22yEArJA9SWf4BTk1wUb9oRUnYbOEmdWsA%3d",
      "team": "Sawtooth",
      "score": "13",
      "oppScore": "3",
      "opp": "Name Brand",
      "oppId": "nD%2bVkshg%2ftrTn6m%2bBloXZ0U8vTke1bsPdPY%2b4AI%2fStA%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "lAX6lMzC1jqPDUE4MZrBoo9uHShas0zfVlDdhhYGRHw%3d",
      "team": "Sawtooth",
      "score": "10",
      "oppScore": "11",
      "opp": "Switchback",
      "oppId": "XNBtJjuPsH6Zq%2bmVmPwL1Zo0%2bl5xYeh3mWrrpzn7CTk%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "gPDrvnQas1Qqi2WtN1dv7r3TSDtHhRCHkDtds2PaaBs%3d",
      "team": "Sawtooth",
      "score": "9",
      "oppScore": "7",
      "opp": "SOUF",
      "oppId": "apgZHT%2fi0I9dSEMbVZWQYonmRxVork%2bwAmVlY01CfPQ%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "zOaqYM%2fPi1ZtwK6DZL7kLTnDFIKF%2frj0wbL9nl%2bGXJk%3d",
      "team": "Sawtooth",
      "score": "11",
      "oppScore": "6",
      "opp": "Switchback",
      "oppId": "XNBtJjuPsH6Zq%2bmVmPwL1Zo0%2bl5xYeh3mWrrpzn7CTk%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "5yLOYRxaNrhBt2wYeFwzHNmuILQ5YbmJKOzcqvfnU%2fI%3d",
      "team": "Sawtooth",
      "score": "13",
      "oppScore": "9",
      "opp": "Hazard",
      "oppId": "P0N8mJF5hP7HulHfG72Zdu2tlioAZAaLyhUwPE4s6T8%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "O8JQz9zRKHhWfNNjVnVyu2iBc0XL0biguAEy%2fyoaV%2fw%3d",
      "team": "Sawtooth",
      "score": "11",
      "oppScore": "6",
      "opp": "Battery",
      "oppId": "XgsOT%2bl5FkWgZoV7cQAy4vMUDJS3ygcjNozHGHsTYUE%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "dvhRAtfXyBnAWVKDq8qmSJGgVA45b56ZFPvf%2ffd4V6M%3d",
      "team": "Sawtooth",
      "score": "13",
      "oppScore": "9",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "S4cvWIev8WN%2bBYLbK%2bJ4vqXAUZa%2bGO3N6oxvvGBCQdU%3d",
      "team": "Sawtooth",
      "score": "11",
      "oppScore": "9",
      "opp": "OAT",
      "oppId": "3jrAxF6U290JZyiuGff9bkOyKPIspXXtN94jCffcvgs%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "eqQc1OpL4Tc11BxZMY%2f2IgAISZ9bncsEs0oIrybEpic%3d",
      "team": "Sawtooth",
      "score": "12",
      "oppScore": "13",
      "opp": "Streetgang",
      "oppId": "%2fo6V9EACiot%2bzfjGzj7GQtyBnMoDlmHGs3Nl35pLoeU%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "%2bmg%2b8D%2bg3WwTWJRSlzqi5Z%2f3ilS73vHYYIFz%2b3%2fahVQ%3d",
      "team": "Sawtooth",
      "score": "11",
      "oppScore": "7",
      "opp": "OAT",
      "oppId": "3jrAxF6U290JZyiuGff9bkOyKPIspXXtN94jCffcvgs%3d"
    },
    {
      "date": "August 21",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "X9ZNdvU653P6R7nPCcUP7Cs2zrJCPfLM6xtCO6hzaM8%3d",
      "team": "Sawtooth",
      "score": "8",
      "oppScore": "13",
      "opp": "Zyzzyva",
      "oppId": "Yfu4RKEHOC158Q5KQGT5xHxtk5hGHKp2RxFoXMEKk%2fA%3d"
    },
    {
      "date": "August 21",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "spzMGnUR4WC4YTwj7jkKzyj5JVM020dAxODzSAnuu3c%3d",
      "team": "Sawtooth",
      "score": "12",
      "oppScore": "9",
      "opp": "Switchback",
      "oppId": "XNBtJjuPsH6Zq%2bmVmPwL1Zo0%2bl5xYeh3mWrrpzn7CTk%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "GaD%2fvVQqjFZ6uWSW7QZJUQ8tyUTUWSKwv7JoWlysCjM%3d",
      "team": "Sawtooth",
      "score": "13",
      "oppScore": "7",
      "opp": "Seven Out",
      "oppId": "EKT%2b4wN3oW3%2bvTZF1BOVy%2fpLrku%2frikWkw8iDqstIOQ%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "6FHXAkrO7dRuaYw6Bb%2bDLqqyHoNtmobD%2b%2b5QVOWziFw%3d",
      "team": "Sawtooth",
      "score": "9",
      "oppScore": "7",
      "opp": "Zyzzyva",
      "oppId": "Yfu4RKEHOC158Q5KQGT5xHxtk5hGHKp2RxFoXMEKk%2fA%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "%2bvwM2fHUUFqtKbe5RK7KzfjHa6cpewItFyaI4OSJxBI%3d",
      "team": "Sawtooth",
      "score": "13",
      "oppScore": "4",
      "opp": "PowderHogs",
      "oppId": "4M9MJYyVOotcr31jr1X%2bW%2fnNzBLDCtMhEBgjHQi1W9s%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "qHpQicSKJbP88eO185p5V2yXOvF3A8NAfMBJY4PSFgE%3d",
      "team": "Sawtooth",
      "score": "11",
      "oppScore": "9",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    }
  ],
  "c9pW2q80DagMAQ3MIlN4qV7%2fnufSjZapIvSzbu5OU%2f4%3d": [
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "RHW9h8mZRePF8NN%2f4sBF8ktxpJeZTd%2bEBXDdBTLM6i8%3d",
      "team": "Dark Star",
      "score": "11",
      "oppScore": "10",
      "opp": "Sawtooth",
      "oppId": "O%2fOvOBARFYfl3iP1PCdqMwd9SiYSMEiR%2b0E8MZeOOD4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "V9sCABA9igCkhOrfYGJzY%2ffgVLY9R4tbJQRO4ISZA1w%3d",
      "team": "Dark Star",
      "score": "13",
      "oppScore": "4",
      "opp": "Name Brand",
      "oppId": "nD%2bVkshg%2ftrTn6m%2bBloXZ0U8vTke1bsPdPY%2b4AI%2fStA%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "sGgwUmGrXkPe%2fLgOy1JG7wBLM3gMzU5G7IJAhjqPYwk%3d",
      "team": "Dark Star",
      "score": "13",
      "oppScore": "7",
      "opp": "Ghost Train",
      "oppId": "Tqxb9%2fb2Hxtbg%2f54ifErhjS%2bc%2fBnacDZk5RhQ%2fd%2b%2f5g%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "RbM5QBxU8C6L7VAb3plGuPIZssbAqM896hZw2PM18JM%3d",
      "team": "Dark Star",
      "score": "10",
      "oppScore": "9",
      "opp": "Zyzzyva",
      "oppId": "Yfu4RKEHOC158Q5KQGT5xHxtk5hGHKp2RxFoXMEKk%2fA%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "23V%2bWcBECTUDVvgHA8Vai%2byXNWwdLfsss3tuMaGHhaI%3d",
      "team": "Dark Star",
      "score": "5",
      "oppScore": "13",
      "opp": "Revolver",
      "oppId": "Kem%2foxDeD8r5XN1HNW8eHeRyzfBqnWTRaPCmLck6F1I%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "sqgstMtKa2BDaLtUQk%2b21sqDdDrJEJimX3wRwU5AJSs%3d",
      "team": "Dark Star",
      "score": "13",
      "oppScore": "8",
      "opp": "Oregon Trainwreck",
      "oppId": "4kZQQUYXvneeQkXiO%2bU1YCVyOwrHOLC6tdR6dVhA%2b%2bA%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "pRnbwmcU7QSBdJd7Cau6fy5QEajYD8S4U9xZWbSSiZU%3d",
      "team": "Dark Star",
      "score": "13",
      "oppScore": "10",
      "opp": "Emerald City",
      "oppId": "m4Q4f0lvofIPyQTpoi5YZ5nNQ2WH52F0MziY%2fWjcttE%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "dvvdHjCnxKHG4Fr%2b3f%2fbH8fexMggKkAA3aRoWBRvEjM%3d",
      "team": "Dark Star",
      "score": "11",
      "oppScore": "10",
      "opp": "Ghost Train",
      "oppId": "Tqxb9%2fb2Hxtbg%2f54ifErhjS%2bc%2fBnacDZk5RhQ%2fd%2b%2f5g%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "fd3KX79kBq9fqs0xAJfG2v%2fsrWD2iaQera4QTIggN00%3d",
      "team": "Dark Star",
      "score": "12",
      "oppScore": "9",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "SyTj8nQNcPn74c7tNBihO%2fUURSP3UPpNKTiVaizZyZg%3d",
      "team": "Dark Star",
      "score": "10",
      "oppScore": "9",
      "opp": "Switchback",
      "oppId": "XNBtJjuPsH6Zq%2bmVmPwL1Zo0%2bl5xYeh3mWrrpzn7CTk%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "ps%2bJKk5xU3gdCw9vZ0sgbI0O4rBzXCTpzXZ9Br%2bngHI%3d",
      "team": "Dark Star",
      "score": "11",
      "oppScore": "10",
      "opp": "Streetgang",
      "oppId": "%2fo6V9EACiot%2bzfjGzj7GQtyBnMoDlmHGs3Nl35pLoeU%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "J74qYroFtuQ%2fQvbH3RyNDkHRL%2fsSL0JnM77Dxsl2%2bAw%3d",
      "team": "Dark Star",
      "score": "15",
      "oppScore": "10",
      "opp": "OAT",
      "oppId": "3jrAxF6U290JZyiuGff9bkOyKPIspXXtN94jCffcvgs%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "skpVyELfxZ5j1N%2bAFfozH4gEHOQNuldmj7SaKrl34s0%3d",
      "team": "Dark Star",
      "score": "15",
      "oppScore": "9",
      "opp": "Streetgang",
      "oppId": "%2fo6V9EACiot%2bzfjGzj7GQtyBnMoDlmHGs3Nl35pLoeU%3d"
    },
    {
      "date": "August 27",
      "tournament": "CBR 2022",
      "tournamentId": "CBR-2022",
      "gameId": "pV%2bmiITemglcSLU%2bLC%2bsE22Iy0fvGVDydVA0S2v6Su4%3d",
      "team": "Dark Star",
      "score": "14",
      "oppScore": "10",
      "opp": "Make it Rain",
      "oppId": "GNSXf0PP6g6tw8m0RFzPDaKIh%2fXdPOhBGKFYOiBXFeQ%3d"
    },
    {
      "date": "August 27",
      "tournament": "CBR 2022",
      "tournamentId": "CBR-2022",
      "gameId": "BWiB483B0IjiNYNzJRS%2bspJJp5y2KicEYDoLt13qds0%3d",
      "team": "Dark Star",
      "score": "15",
      "oppScore": "12",
      "opp": "Switchback",
      "oppId": "XNBtJjuPsH6Zq%2bmVmPwL1Zo0%2bl5xYeh3mWrrpzn7CTk%3d"
    },
    {
      "date": "August 27",
      "tournament": "CBR 2022",
      "tournamentId": "CBR-2022",
      "gameId": "uh5gKT%2bW5FV14O96b4xKvF4heZliZrnyOEJJYE2%2bixU%3d",
      "team": "Dark Star",
      "score": "15",
      "oppScore": "10",
      "opp": "Streetgang",
      "oppId": "%2fo6V9EACiot%2bzfjGzj7GQtyBnMoDlmHGs3Nl35pLoeU%3d"
    }
  ],
  "m4Q4f0lvofIPyQTpoi5YZ5nNQ2WH52F0MziY%2fWjcttE%3d": [
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "XLKFMgt012awpgFvwUn0funLtL90ZZrYVpB7ovjHubc%3d",
      "team": "Emerald City",
      "score": "6",
      "oppScore": "13",
      "opp": "Sockeye",
      "oppId": "Ur5qIXbyxiUHKLhvlB%2bSYJDI9nx7KM4Txz1fUtmqC2w%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "GuCtThE1G%2bPrk84a1EcZK8ks1AZ47liUQ7%2fsI%2fttYYI%3d",
      "team": "Emerald City",
      "score": "6",
      "oppScore": "11",
      "opp": "Furious George",
      "oppId": "AyYWHH3tsm9Kguy8GVmoEwGKAXpOtKzKP1KhenuZxfY%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "8ecrcyVA6gnPj%2bFajALrAfjZxV96zmWd%2buI4qUqvcPw%3d",
      "team": "Emerald City",
      "score": "8",
      "oppScore": "10",
      "opp": "Rhino Slam!",
      "oppId": "oAJ4T9WLKOA4ICTPMYYK1645dFdRoI5WhU60sNRZIMk%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "jfJFimwNHXFcCD0TGRw4%2fmVxfmf3J%2fBKokzPhDDXMRQ%3d",
      "team": "Emerald City",
      "score": "8",
      "oppScore": "13",
      "opp": "Revolver",
      "oppId": "Kem%2foxDeD8r5XN1HNW8eHeRyzfBqnWTRaPCmLck6F1I%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "NVLuNt14yTcoIDGjn%2fvns7qWcxTFYR6JTe8ac%2b8E79U%3d",
      "team": "Emerald City",
      "score": "6",
      "oppScore": "13",
      "opp": "Rhino Slam!",
      "oppId": "oAJ4T9WLKOA4ICTPMYYK1645dFdRoI5WhU60sNRZIMk%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "4Vwg%2bDQAK6Q2TLJsPRhkDc%2bfV%2fjFvohBEck1QScbLns%3d",
      "team": "Emerald City",
      "score": "13",
      "oppScore": "4",
      "opp": "Zyzzyva",
      "oppId": "Yfu4RKEHOC158Q5KQGT5xHxtk5hGHKp2RxFoXMEKk%2fA%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "pRnbwmcU7QSBdJd7Cau6fy5QEajYD8S4U9xZWbSSiZU%3d",
      "team": "Emerald City",
      "score": "10",
      "oppScore": "13",
      "opp": "Dark Star",
      "oppId": "c9pW2q80DagMAQ3MIlN4qV7%2fnufSjZapIvSzbu5OU%2f4%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "7sFhxOEU52jR5napva92WcSeXSj58444laAcdZw2L1Q%3d",
      "team": "Emerald City",
      "score": "9",
      "oppScore": "15",
      "opp": "Truck Stop",
      "oppId": "pUj3i6rzWdnpW5TeEEoriE%2fRmJjK%2brKBLe8y4LGWtZY%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "pLJmnX%2fIgkBxnvDh%2bQce%2bNK53CyXxeOu5bfVd0bUtX8%3d",
      "team": "Emerald City",
      "score": "8",
      "oppScore": "15",
      "opp": "Chain Lightning",
      "oppId": "xMA73o8VVEaOTmaBmpN0fQZ4779P9LJ9jH3j44aVabA%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "wLuRdUzMhGYGUzHpjZsdryBlmplv9NYEyxkLkmRBIBo%3d",
      "team": "Emerald City",
      "score": "13",
      "oppScore": "12",
      "opp": "Sprout",
      "oppId": "QyN2sOWwUrO10Eheq9tje8Trmro0kiO6dKpnQzSh9q4%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "Jp2NajSpSjLhHek3ML3IupTs6Xx%2frF1giCoAf%2bOPD68%3d",
      "team": "Emerald City",
      "score": "9",
      "oppScore": "15",
      "opp": "DiG",
      "oppId": "kn%2bHmtzxdjY7vxb%2fpJ9qsfEsWaDB6JrUBL7Ga4bU18M%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "SgrFEq9VLADgByG1BBLXEb83UKPzJ7%2fRW9AUbJ7dpgQ%3d",
      "team": "Emerald City",
      "score": "12",
      "oppScore": "15",
      "opp": "Pando",
      "oppId": "CWW3TWk5yeYKuKEW8USBkbYZz5DyfbKjfswKBgSzYsA%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "jg0SbkKkVuqCt2Ui2KUM9GS5%2fd3l0XypyIsKiGfk0%2fs%3d",
      "team": "Emerald City",
      "score": "7",
      "oppScore": "15",
      "opp": "Chicago Machine",
      "oppId": "a63rCMScaUnSuWtwLw1rcbPhZh4%2bUKiYzBab%2b52i9Pk%3d"
    },
    {
      "date": "August 20",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "Uam7rsrnYUcnDyDu0zuYWOGwnSplrDVh2xMmI5UbVEE%3d",
      "team": "Emerald City",
      "score": "11",
      "oppScore": "15",
      "opp": "Furious George",
      "oppId": "AyYWHH3tsm9Kguy8GVmoEwGKAXpOtKzKP1KhenuZxfY%3d"
    },
    {
      "date": "August 20",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "XnqwtUnRvLFA2srTdfs2lLZRl%2ftmuJ%2bYVCLOO32Ood0%3d",
      "team": "Emerald City",
      "score": "10",
      "oppScore": "15",
      "opp": "Revolver",
      "oppId": "Kem%2foxDeD8r5XN1HNW8eHeRyzfBqnWTRaPCmLck6F1I%3d"
    },
    {
      "date": "August 20",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "IJXZdRdzvlWZdm00VJktDzgSZMuQ7q9H9a6Cxv1sc%2bU%3d",
      "team": "Emerald City",
      "score": "15",
      "oppScore": "6",
      "opp": "Battery",
      "oppId": "XgsOT%2bl5FkWgZoV7cQAy4vMUDJS3ygcjNozHGHsTYUE%3d"
    },
    {
      "date": "August 20",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "xN7t%2fybt%2bM6ghBsMPH0SPK2bf4VQcU28tPvdcHo8qoY%3d",
      "team": "Emerald City",
      "score": "15",
      "oppScore": "14",
      "opp": "OAT",
      "oppId": "3jrAxF6U290JZyiuGff9bkOyKPIspXXtN94jCffcvgs%3d"
    },
    {
      "date": "August 21",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "FaYpEI1U61o1k90FSVDTCgUJ0vOTYDbQeHDC8D5DOjk%3d",
      "team": "Emerald City",
      "score": "15",
      "oppScore": "13",
      "opp": "Furious George",
      "oppId": "AyYWHH3tsm9Kguy8GVmoEwGKAXpOtKzKP1KhenuZxfY%3d"
    },
    {
      "date": "August 21",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "OKoZj%2bf6h8J1EzB8rEZOPLundH0aYWi6Ytann4qxTok%3d",
      "team": "Emerald City",
      "score": "12",
      "oppScore": "15",
      "opp": "Revolver",
      "oppId": "Kem%2foxDeD8r5XN1HNW8eHeRyzfBqnWTRaPCmLck6F1I%3d"
    }
  ],
  "Tqxb9%2fb2Hxtbg%2f54ifErhjS%2bc%2fBnacDZk5RhQ%2fd%2b%2f5g%3d": [
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "QSrz52zxkt5pX1Td1i5ETgU9yvXqzMfHbBCBV3sB2fQ%3d",
      "team": "Ghost Train",
      "score": "13",
      "oppScore": "7",
      "opp": "Name Brand",
      "oppId": "nD%2bVkshg%2ftrTn6m%2bBloXZ0U8vTke1bsPdPY%2b4AI%2fStA%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "CitwmRVv2qeWbsmZC8O6af8WsHIK8Rz%2fJwSZdvSxYh8%3d",
      "team": "Ghost Train",
      "score": "13",
      "oppScore": "10",
      "opp": "Sawtooth",
      "oppId": "O%2fOvOBARFYfl3iP1PCdqMwd9SiYSMEiR%2b0E8MZeOOD4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "sGgwUmGrXkPe%2fLgOy1JG7wBLM3gMzU5G7IJAhjqPYwk%3d",
      "team": "Ghost Train",
      "score": "7",
      "oppScore": "13",
      "opp": "Dark Star",
      "oppId": "c9pW2q80DagMAQ3MIlN4qV7%2fnufSjZapIvSzbu5OU%2f4%3d"
    },
    {
      "date": "June 25",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "vMLd4aycFBrtgTGSzr0IWuY%2bk1K5yuufs%2f6YSfU6ayE%3d",
      "team": "Ghost Train",
      "score": "5",
      "oppScore": "13",
      "opp": "Oregon Trainwreck",
      "oppId": "4kZQQUYXvneeQkXiO%2bU1YCVyOwrHOLC6tdR6dVhA%2b%2bA%3d"
    },
    {
      "date": "June 26",
      "tournament": "Eugene Summer Solstice 2022",
      "tournamentId": "Eugene-Summer-Solstice-2022",
      "gameId": "5AD%2f3aYWjWmi5hMHpcPRetd0YsKtEFXPofvibWT5DzQ%3d",
      "team": "Ghost Train",
      "score": "13",
      "oppScore": "8",
      "opp": "Name Brand",
      "oppId": "nD%2bVkshg%2ftrTn6m%2bBloXZ0U8vTke1bsPdPY%2b4AI%2fStA%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "EqyYDlRv039IO%2fpWWtc1HoaHeojLz2AokhgM0qDockY%3d",
      "team": "Ghost Train",
      "score": "2",
      "oppScore": "15",
      "opp": "SoCal Condors",
      "oppId": "Hs8IYCPj2pf9Nump3cK24FlAdOgCZVYhTQKGBSXFLXA%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "Ry2wUp1Arb9HJfq9GYRNu0H6qLhZIa%2b6HnJmKEJeMkw%3d",
      "team": "Ghost Train",
      "score": "12",
      "oppScore": "8",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "KhJ%2fNNOV5dlwpdKI4gQY55CKsSzx1pT97zQPghTGlFY%3d",
      "team": "Ghost Train",
      "score": "11",
      "oppScore": "15",
      "opp": "Inception",
      "oppId": "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "7nzSYel8yFTUzZmMK1feU26Vv6PUiM1%2blVRRMqmpiMg%3d",
      "team": "Ghost Train",
      "score": "12",
      "oppScore": "11",
      "opp": "Flash Flood",
      "oppId": "JQrYQgb4YJNC2aMynOWjHiZmxJvsuqeLyz2Red9EabU%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "YUCrIdrqy0t8cFXWY%2bmelMJfujQrKsF%2fcXqjroNbCgc%3d",
      "team": "Ghost Train",
      "score": "11",
      "oppScore": "10",
      "opp": "General Strike",
      "oppId": "%2bAa3yq8xXOgWBEYZi%2fAT%2fiiGNJfFLcQeXn3x6sH%2fmhA%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "TZGGRXGHQG9qmTPL0JaOOI2LJtEeGnjpqe5UA1XY0jo%3d",
      "team": "Ghost Train",
      "score": "11",
      "oppScore": "13",
      "opp": "Zyzzyva",
      "oppId": "Yfu4RKEHOC158Q5KQGT5xHxtk5hGHKp2RxFoXMEKk%2fA%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "QqSbTOJgNyXUoPQZ2akpRVkILkS9GN%2fmmUkdrDMwVZQ%3d",
      "team": "Ghost Train",
      "score": "13",
      "oppScore": "11",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "dvvdHjCnxKHG4Fr%2b3f%2fbH8fexMggKkAA3aRoWBRvEjM%3d",
      "team": "Ghost Train",
      "score": "10",
      "oppScore": "11",
      "opp": "Dark Star",
      "oppId": "c9pW2q80DagMAQ3MIlN4qV7%2fnufSjZapIvSzbu5OU%2f4%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "7%2faClXPJyCdwN32CwSsnZFv25XB4pTIqqlyRNas24bI%3d",
      "team": "Ghost Train",
      "score": "10",
      "oppScore": "9",
      "opp": "Switchback",
      "oppId": "XNBtJjuPsH6Zq%2bmVmPwL1Zo0%2bl5xYeh3mWrrpzn7CTk%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "ymR6sp3OsFhSPRHykpcw0%2fnqrZ6S3FG4p3Fg%2b2fr%2bLM%3d",
      "team": "Ghost Train",
      "score": "7",
      "oppScore": "13",
      "opp": "Streetgang",
      "oppId": "%2fo6V9EACiot%2bzfjGzj7GQtyBnMoDlmHGs3Nl35pLoeU%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "3dhQULMBlkhnO2o4whnP5vu7ejbOlze6wIss%2bRUP708%3d",
      "team": "Ghost Train",
      "score": "12",
      "oppScore": "13",
      "opp": "OAT",
      "oppId": "3jrAxF6U290JZyiuGff9bkOyKPIspXXtN94jCffcvgs%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "lyKwlzT2B67jiEslpie36e24Bs8oGX9N2ddkkbcNVnI%3d",
      "team": "Ghost Train",
      "score": "8",
      "oppScore": "9",
      "opp": "Hazard",
      "oppId": "P0N8mJF5hP7HulHfG72Zdu2tlioAZAaLyhUwPE4s6T8%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "6ihUNQApTIaanbN0KykSjsQPGJqmB0vl5JejNv3mfgI%3d",
      "team": "Ghost Train",
      "score": "12",
      "oppScore": "15",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "August 27",
      "tournament": "CBR 2022",
      "tournamentId": "CBR-2022",
      "gameId": "0Jkg3t1CzoJdHmtz9hcnBCKFPedYnYnpDZa30xpPayA%3d",
      "team": "Ghost Train",
      "score": "7",
      "oppScore": "15",
      "opp": "Switchback",
      "oppId": "XNBtJjuPsH6Zq%2bmVmPwL1Zo0%2bl5xYeh3mWrrpzn7CTk%3d"
    },
    {
      "date": "August 27",
      "tournament": "CBR 2022",
      "tournamentId": "CBR-2022",
      "gameId": "wv0e1wxNuQksbWXmE%2b82SpimnoqBlxkrtP3hV%2b5gqXo%3d",
      "team": "Ghost Train",
      "score": "13",
      "oppScore": "7",
      "opp": "Make it Rain",
      "oppId": "GNSXf0PP6g6tw8m0RFzPDaKIh%2fXdPOhBGKFYOiBXFeQ%3d"
    },
    {
      "date": "August 27",
      "tournament": "CBR 2022",
      "tournamentId": "CBR-2022",
      "gameId": "IYqMjekZYFr6Wm00upday3g0EUXfDxLO0sMqSTZmJMs%3d",
      "team": "Ghost Train",
      "score": "15",
      "oppScore": "6",
      "opp": "Name Brand",
      "oppId": "nD%2bVkshg%2ftrTn6m%2bBloXZ0U8vTke1bsPdPY%2b4AI%2fStA%3d"
    }
  ],
  "U8i%2ffBl%2bGdNRXUaehdkV4W9lOM4QHUciytmWcy3oxXI%3d": [
    {
      "date": "June 26",
      "tournament": "MOB Invite",
      "tournamentId": "MOB-Invite",
      "gameId": "ec8gZxxNVUim5ASALXIOPZBSOmcvB5Y6Rddmuqfr%2bKQ%3d",
      "team": "John Doe",
      "score": "11",
      "oppScore": "9",
      "opp": "SUPA FC",
      "oppId": "KtH9xWy9jBGhUoOWQhn%2fSIE8Vsl4jI9MQcOiMTnZdyQ%3d"
    },
    {
      "date": "June 26",
      "tournament": "MOB Invite",
      "tournamentId": "MOB-Invite",
      "gameId": "DkMiXJ7eqbfK7FGa2%2frYDYQmLXD8ehiqqnXI%2b%2fQrM7k%3d",
      "team": "John Doe",
      "score": "11",
      "oppScore": "9",
      "opp": "Winc City Fog of War",
      "oppId": "6gsiUt3INMB4Td2RTxJkksYpRIJsACnjptCfHUZeg2o%3d"
    },
    {
      "date": "June 26",
      "tournament": "MOB Invite",
      "tournamentId": "MOB-Invite",
      "gameId": "%2fy1kDhE3ttuqiPnPkLGXDcrvDIVkvVwYvaKNq4P%2bfog%3d",
      "team": "John Doe",
      "score": "9",
      "oppScore": "5",
      "opp": "MOB Ultimate",
      "oppId": "otuSqjNVhCJZE0ojAe0x6mw6GKk1Ux8ciM5ADgrPUEI%3d"
    },
    {
      "date": "June 26",
      "tournament": "MOB Invite",
      "tournamentId": "MOB-Invite",
      "gameId": "S%2fk94zmTpl2OYlHf5G52LCAAeXTZyA9rGo5bhRbxmqk%3d",
      "team": "John Doe",
      "score": "6",
      "oppScore": "10",
      "opp": "Bomb Squad",
      "oppId": "GMU0OYY8eXqf1AR6M93JxRSXSpRgEq9sJxKUtkOjXgg%3d"
    },
    {
      "date": "July 16",
      "tournament": "Philly Special 2022",
      "tournamentId": "Philly-Special-2022",
      "gameId": "s9Kdw6m9pEwdFEK%2b4zPXvAWOsN3fsLnlie8IYsOSauo%3d",
      "team": "John Doe",
      "score": "10",
      "oppScore": "11",
      "opp": "Bomb Squad",
      "oppId": "GMU0OYY8eXqf1AR6M93JxRSXSpRgEq9sJxKUtkOjXgg%3d"
    },
    {
      "date": "July 16",
      "tournament": "Philly Special 2022",
      "tournamentId": "Philly-Special-2022",
      "gameId": "g%2bDuGP3%2fwaQgb%2bLSIl2E%2fOE8QHv8QJ1ZmX94y1lq438%3d",
      "team": "John Doe",
      "score": "15",
      "oppScore": "9",
      "opp": "Adelphos",
      "oppId": "IINBHzSyAD1qveaivUllPpQBPkMwjzVWcFo4s84DFtc%3d"
    },
    {
      "date": "July 16",
      "tournament": "Philly Special 2022",
      "tournamentId": "Philly-Special-2022",
      "gameId": "Fli5QPuxuxDGRgt7mGyD7sjI5gwj%2bnge8hJZGIl57QI%3d",
      "team": "John Doe",
      "score": "11",
      "oppScore": "15",
      "opp": "Town Hall Stars",
      "oppId": "2Kd8tzcR0KA7rHRMTMb17b6HIPSIH%2bVBl3400ISNCtw%3d"
    },
    {
      "date": "July 17",
      "tournament": "Philly Special 2022",
      "tournamentId": "Philly-Special-2022",
      "gameId": "i0BXFIoHPq1b6vBFmHgFTByY%2b00CmXe2fe2kRGEAW1s%3d",
      "team": "John Doe",
      "score": "15",
      "oppScore": "11",
      "opp": "Adelphos",
      "oppId": "IINBHzSyAD1qveaivUllPpQBPkMwjzVWcFo4s84DFtc%3d"
    },
    {
      "date": "July 17",
      "tournament": "Philly Special 2022",
      "tournamentId": "Philly-Special-2022",
      "gameId": "4nShaAE9rBSkLqOkt59Dls3VUGDwAlFjpGs5rURuo%2fY%3d",
      "team": "John Doe",
      "score": "14",
      "oppScore": "11",
      "opp": "Bomb Squad",
      "oppId": "GMU0OYY8eXqf1AR6M93JxRSXSpRgEq9sJxKUtkOjXgg%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "DgiQfcLs6S%2bl7yTjUw%2bm48R70k8RD3G2WhTOP0ZibYM%3d",
      "team": "John Doe",
      "score": "13",
      "oppScore": "9",
      "opp": "Ballston Booya",
      "oppId": "nyrFBt9ebf%2bCb5bJv%2fsbfoGgjlyW%2bH3CLoFeaZGYMEY%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "eRez9aTB7SoNH3lkCImHMN9%2bV9NJQJJT6l4tMpaTd8M%3d",
      "team": "John Doe",
      "score": "13",
      "oppScore": "5",
      "opp": "Deepfake",
      "oppId": "MjCJCQyu%2bVgQCxsNuCDsIlsaBlb84XeNodQMva1Vl6s%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "DI2pCjOEupQ4m%2bk86MmbWmsujxVD2c1k4CjcSOlEuXI%3d",
      "team": "John Doe",
      "score": "6",
      "oppScore": "13",
      "opp": "Pittsburgh Stealers",
      "oppId": "icSGwphEBV6tcYJLRwPxEC22s0bt6rct5HG5ubjyetw%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "pUdJtkK4Sir9vuJz%2f0b98PgedVaVVlHKBhYsHLKM17Y%3d",
      "team": "John Doe",
      "score": "8",
      "oppScore": "9",
      "opp": "Bomb Squad",
      "oppId": "GMU0OYY8eXqf1AR6M93JxRSXSpRgEq9sJxKUtkOjXgg%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "xVI%2fZHau6Kt0Ki50mO3OuHWzZHGlMTP1HqSXVNWu0bg%3d",
      "team": "John Doe",
      "score": "13",
      "oppScore": "7",
      "opp": "SUPA FC",
      "oppId": "KtH9xWy9jBGhUoOWQhn%2fSIE8Vsl4jI9MQcOiMTnZdyQ%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "Zk4rJ%2bwo8IYLmtRCjh3Jrj49bO1xVjqCI7YOet6jUDw%3d",
      "team": "John Doe",
      "score": "9",
      "oppScore": "8",
      "opp": "Colt",
      "oppId": "csuSEMdqMABUec374gfmA4qxClU85JTTEhhJhBcJ2Do%3d"
    }
  ],
  "otuSqjNVhCJZE0ojAe0x6mw6GKk1Ux8ciM5ADgrPUEI%3d": [
    {
      "date": "June 26",
      "tournament": "MOB Invite",
      "tournamentId": "MOB-Invite",
      "gameId": "yqn0qwMkAX0py12xbQaVX00lQJnceY23M6GPgTw6cZ8%3d",
      "team": "MOB Ultimate",
      "score": "8",
      "oppScore": "13",
      "opp": "Bomb Squad",
      "oppId": "GMU0OYY8eXqf1AR6M93JxRSXSpRgEq9sJxKUtkOjXgg%3d"
    },
    {
      "date": "June 26",
      "tournament": "MOB Invite",
      "tournamentId": "MOB-Invite",
      "gameId": "%2fy1kDhE3ttuqiPnPkLGXDcrvDIVkvVwYvaKNq4P%2bfog%3d",
      "team": "MOB Ultimate",
      "score": "5",
      "oppScore": "9",
      "opp": "John Doe",
      "oppId": "U8i%2ffBl%2bGdNRXUaehdkV4W9lOM4QHUciytmWcy3oxXI%3d"
    },
    {
      "date": "June 26",
      "tournament": "MOB Invite",
      "tournamentId": "MOB-Invite",
      "gameId": "XbKGKRIM1MgpElbHXd1rN%2blDC6fERAzRD09EOSSrlKs%3d",
      "team": "MOB Ultimate",
      "score": "11",
      "oppScore": "10",
      "opp": "Winc City Fog of War",
      "oppId": "6gsiUt3INMB4Td2RTxJkksYpRIJsACnjptCfHUZeg2o%3d"
    },
    {
      "date": "June 26",
      "tournament": "MOB Invite",
      "tournamentId": "MOB-Invite",
      "gameId": "js8au3nW99dNCn7PmLBkzXlrrgRO0gnx0n5YqrIeXDY%3d",
      "team": "MOB Ultimate",
      "score": "8",
      "oppScore": "10",
      "opp": "SUPA FC",
      "oppId": "KtH9xWy9jBGhUoOWQhn%2fSIE8Vsl4jI9MQcOiMTnZdyQ%3d"
    },
    {
      "date": "July 10",
      "tournament": "MOB Open",
      "tournamentId": "MOB-Open",
      "gameId": "AntCXWM8FAe3eXz3Wk3R8Sxbdgt3g%2bydMGEHEf9ryqc%3d",
      "team": "MOB Ultimate",
      "score": "9",
      "oppScore": "13",
      "opp": "Redwolves",
      "oppId": "DoL%2frShNUJHM7OLFhcsMzWlsS0aohpfm30HvDbpVZi4%3d"
    },
    {
      "date": "July 10",
      "tournament": "MOB Open",
      "tournamentId": "MOB-Open",
      "gameId": "u%2fbk1b5INfcfeH72AJV5%2fMAWia%2bJOljltMeq%2fuudPd4%3d",
      "team": "MOB Ultimate",
      "score": "9",
      "oppScore": "13",
      "opp": "Winc City Fog of War",
      "oppId": "6gsiUt3INMB4Td2RTxJkksYpRIJsACnjptCfHUZeg2o%3d"
    },
    {
      "date": "July 10",
      "tournament": "MOB Open",
      "tournamentId": "MOB-Open",
      "gameId": "RsZ32eif1WBcDW0TfIX4Hax6tdk9YVkUogUe%2f%2bbN7iI%3d",
      "team": "MOB Ultimate",
      "score": "8",
      "oppScore": "13",
      "opp": "Bomb Squad",
      "oppId": "GMU0OYY8eXqf1AR6M93JxRSXSpRgEq9sJxKUtkOjXgg%3d"
    },
    {
      "date": "July 10",
      "tournament": "MOB Open",
      "tournamentId": "MOB-Open",
      "gameId": "FHIpvtc8XfuwoEuYTc7A9i2GIysvFus55Q3YHnWh6XY%3d",
      "team": "MOB Ultimate",
      "score": "8",
      "oppScore": "12",
      "opp": "Oakgrove Boys",
      "oppId": "1zUV4kEpPc%2flA39WIyrp9j1w%2fhuIkgE6KlD9%2f%2ffQzg8%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "66BdzvQil2UbcSzeH%2bA6mHaDkSBkX6mvxOvk0P2kffk%3d",
      "team": "MOB Ultimate",
      "score": "6",
      "oppScore": "13",
      "opp": "Oakgrove Boys",
      "oppId": "1zUV4kEpPc%2flA39WIyrp9j1w%2fhuIkgE6KlD9%2f%2ffQzg8%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "ZnT0iAN9kjRMyD94pVNcmgG4Pf3EmKgVfmHFI2EspPE%3d",
      "team": "MOB Ultimate",
      "score": "6",
      "oppScore": "10",
      "opp": "Helots",
      "oppId": "m6Orv8C1KcDAyDCEIuhdmpNBKN41R0jWgAvkxa%2bLmOk%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "hcJvxtCNEzcjEtUf32fWornxXJ191a82nj%2fxAl0iGFc%3d",
      "team": "MOB Ultimate",
      "score": "9",
      "oppScore": "13",
      "opp": "JAWN",
      "oppId": "ZmiNobvozutOTlvBTZEmqu8bW1a0Lvp6PqGmIiNlkGU%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "2TIzZ%2fj1o3mRwszeF0QtCFQUainxc%2fzba6bUAyJWVR0%3d",
      "team": "MOB Ultimate",
      "score": "9",
      "oppScore": "11",
      "opp": "Hazard",
      "oppId": "RBbGte9ro%2fCZWrmOmcHNTdHB41zGQFb64ubBkZjcTEI%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "1AAUommDt1Vm2mmtbUZTz8JGXpoXEGNqGf3fUJmOZrw%3d",
      "team": "MOB Ultimate",
      "score": "3",
      "oppScore": "11",
      "opp": "Town Hall Stars",
      "oppId": "2Kd8tzcR0KA7rHRMTMb17b6HIPSIH%2bVBl3400ISNCtw%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "GLdhMq2EAMdJ6l%2bQnB11HM%2bd1ZNqSVn6pxOq70sRYQY%3d",
      "team": "MOB Ultimate",
      "score": "12",
      "oppScore": "7",
      "opp": "Spring Break '93",
      "oppId": "hRUlbCXzaOf83wDSPKpV36LeX3FmlXgFaE5wdndlB3Q%3d"
    }
  ],
  "6gsiUt3INMB4Td2RTxJkksYpRIJsACnjptCfHUZeg2o%3d": [
    {
      "date": "June 26",
      "tournament": "MOB Invite",
      "tournamentId": "MOB-Invite",
      "gameId": "DkMiXJ7eqbfK7FGa2%2frYDYQmLXD8ehiqqnXI%2b%2fQrM7k%3d",
      "team": "Winc City Fog of War",
      "score": "9",
      "oppScore": "11",
      "opp": "John Doe",
      "oppId": "U8i%2ffBl%2bGdNRXUaehdkV4W9lOM4QHUciytmWcy3oxXI%3d"
    },
    {
      "date": "June 26",
      "tournament": "MOB Invite",
      "tournamentId": "MOB-Invite",
      "gameId": "4svrd7xTamtBt9RnVoqohc2JmHJ9pZMFTRCA497y4G0%3d",
      "team": "Winc City Fog of War",
      "score": "7",
      "oppScore": "13",
      "opp": "SUPA FC",
      "oppId": "KtH9xWy9jBGhUoOWQhn%2fSIE8Vsl4jI9MQcOiMTnZdyQ%3d"
    },
    {
      "date": "June 26",
      "tournament": "MOB Invite",
      "tournamentId": "MOB-Invite",
      "gameId": "XbKGKRIM1MgpElbHXd1rN%2blDC6fERAzRD09EOSSrlKs%3d",
      "team": "Winc City Fog of War",
      "score": "10",
      "oppScore": "11",
      "opp": "MOB Ultimate",
      "oppId": "otuSqjNVhCJZE0ojAe0x6mw6GKk1Ux8ciM5ADgrPUEI%3d"
    },
    {
      "date": "June 26",
      "tournament": "MOB Invite",
      "tournamentId": "MOB-Invite",
      "gameId": "XgIRTzqawiSAv%2bzCI%2b9avi9vUuZORvIAuPBwFhZLwRg%3d",
      "team": "Winc City Fog of War",
      "score": "9",
      "oppScore": "12",
      "opp": "Bomb Squad",
      "oppId": "GMU0OYY8eXqf1AR6M93JxRSXSpRgEq9sJxKUtkOjXgg%3d"
    },
    {
      "date": "July 10",
      "tournament": "MOB Open",
      "tournamentId": "MOB-Open",
      "gameId": "yJWDGyDco8TuYARQ%2fPUJSiGZ4bIA7OH68AkHYSnuOQI%3d",
      "team": "Winc City Fog of War",
      "score": "13",
      "oppScore": "12",
      "opp": "Bomb Squad",
      "oppId": "GMU0OYY8eXqf1AR6M93JxRSXSpRgEq9sJxKUtkOjXgg%3d"
    },
    {
      "date": "July 10",
      "tournament": "MOB Open",
      "tournamentId": "MOB-Open",
      "gameId": "u%2fbk1b5INfcfeH72AJV5%2fMAWia%2bJOljltMeq%2fuudPd4%3d",
      "team": "Winc City Fog of War",
      "score": "13",
      "oppScore": "9",
      "opp": "MOB Ultimate",
      "oppId": "otuSqjNVhCJZE0ojAe0x6mw6GKk1Ux8ciM5ADgrPUEI%3d"
    },
    {
      "date": "July 10",
      "tournament": "MOB Open",
      "tournamentId": "MOB-Open",
      "gameId": "SVfsIjMqr%2fT2hRXPGyJCFf6k4SCheM8aishfLvGy7bA%3d",
      "team": "Winc City Fog of War",
      "score": "5",
      "oppScore": "13",
      "opp": "Redwolves",
      "oppId": "DoL%2frShNUJHM7OLFhcsMzWlsS0aohpfm30HvDbpVZi4%3d"
    },
    {
      "date": "July 10",
      "tournament": "MOB Open",
      "tournamentId": "MOB-Open",
      "gameId": "Xi0YowVm577Z2phq%2bi1Z34SuktBvVp4IiNUgpzX0cfU%3d",
      "team": "Winc City Fog of War",
      "score": "8",
      "oppScore": "11",
      "opp": "Oakgrove Boys",
      "oppId": "1zUV4kEpPc%2flA39WIyrp9j1w%2fhuIkgE6KlD9%2f%2ffQzg8%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "D9w%2fWqkfgv6Z4A%2fp5rSnGC2c0CAhteOjsvk4LaW1dsM%3d",
      "team": "Winc City Fog of War",
      "score": "7",
      "oppScore": "13",
      "opp": "Redwolves",
      "oppId": "DoL%2frShNUJHM7OLFhcsMzWlsS0aohpfm30HvDbpVZi4%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "q2KFQ4b5duFHn3sdGvTwqxUiCRdhSZefPG0ypY3bnP4%3d",
      "team": "Winc City Fog of War",
      "score": "8",
      "oppScore": "10",
      "opp": "Magma Bears",
      "oppId": "tOcZDMKk80VDM0VC5NJXyzOSixma%2fCVGplidpqww6q8%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "5SmBNLSKG4y6ntuHR0CABjB%2barSfnN3UYuN4kVolnUQ%3d",
      "team": "Winc City Fog of War",
      "score": "13",
      "oppScore": "8",
      "opp": "Chimney",
      "oppId": "jzVhyLybRfcABTsshmdvgsxYDSASMTHMGNde%2bkQTb4Y%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "4obQ2wP7JmZW1ylh20lozUUKeG7xa02sz%2fZZ6EEkXyg%3d",
      "team": "Winc City Fog of War",
      "score": "7",
      "oppScore": "9",
      "opp": "Adelphos",
      "oppId": "IINBHzSyAD1qveaivUllPpQBPkMwjzVWcFo4s84DFtc%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "PmFk1nM7rpHRxDh3qowhdP0mTbzUmsCNHu2rY8fll0o%3d",
      "team": "Winc City Fog of War",
      "score": "11",
      "oppScore": "8",
      "opp": "Spring Break '93",
      "oppId": "hRUlbCXzaOf83wDSPKpV36LeX3FmlXgFaE5wdndlB3Q%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "VJWKtUz9yE2bCprx99dXg2cFa3rQBa6k2c0HOuNP78c%3d",
      "team": "Winc City Fog of War",
      "score": "7",
      "oppScore": "11",
      "opp": "Town Hall Stars",
      "oppId": "2Kd8tzcR0KA7rHRMTMb17b6HIPSIH%2bVBl3400ISNCtw%3d"
    }
  ],
  "GMU0OYY8eXqf1AR6M93JxRSXSpRgEq9sJxKUtkOjXgg%3d": [
    {
      "date": "June 26",
      "tournament": "MOB Invite",
      "tournamentId": "MOB-Invite",
      "gameId": "yqn0qwMkAX0py12xbQaVX00lQJnceY23M6GPgTw6cZ8%3d",
      "team": "Bomb Squad",
      "score": "13",
      "oppScore": "8",
      "opp": "MOB Ultimate",
      "oppId": "otuSqjNVhCJZE0ojAe0x6mw6GKk1Ux8ciM5ADgrPUEI%3d"
    },
    {
      "date": "June 26",
      "tournament": "MOB Invite",
      "tournamentId": "MOB-Invite",
      "gameId": "xkAbqrvbsJfzrM7vW9RCTZc3hDM2cPkQdQ%2fkaq1XK%2fI%3d",
      "team": "Bomb Squad",
      "score": "11",
      "oppScore": "8",
      "opp": "SUPA FC",
      "oppId": "KtH9xWy9jBGhUoOWQhn%2fSIE8Vsl4jI9MQcOiMTnZdyQ%3d"
    },
    {
      "date": "June 26",
      "tournament": "MOB Invite",
      "tournamentId": "MOB-Invite",
      "gameId": "S%2fk94zmTpl2OYlHf5G52LCAAeXTZyA9rGo5bhRbxmqk%3d",
      "team": "Bomb Squad",
      "score": "10",
      "oppScore": "6",
      "opp": "John Doe",
      "oppId": "U8i%2ffBl%2bGdNRXUaehdkV4W9lOM4QHUciytmWcy3oxXI%3d"
    },
    {
      "date": "June 26",
      "tournament": "MOB Invite",
      "tournamentId": "MOB-Invite",
      "gameId": "XgIRTzqawiSAv%2bzCI%2b9avi9vUuZORvIAuPBwFhZLwRg%3d",
      "team": "Bomb Squad",
      "score": "12",
      "oppScore": "9",
      "opp": "Winc City Fog of War",
      "oppId": "6gsiUt3INMB4Td2RTxJkksYpRIJsACnjptCfHUZeg2o%3d"
    },
    {
      "date": "July 10",
      "tournament": "MOB Open",
      "tournamentId": "MOB-Open",
      "gameId": "yJWDGyDco8TuYARQ%2fPUJSiGZ4bIA7OH68AkHYSnuOQI%3d",
      "team": "Bomb Squad",
      "score": "12",
      "oppScore": "13",
      "opp": "Winc City Fog of War",
      "oppId": "6gsiUt3INMB4Td2RTxJkksYpRIJsACnjptCfHUZeg2o%3d"
    },
    {
      "date": "July 10",
      "tournament": "MOB Open",
      "tournamentId": "MOB-Open",
      "gameId": "HJw9i0cXcbbSkmBDTn5rEeKXic0Jt%2bX9qOPP%2bEH2UK4%3d",
      "team": "Bomb Squad",
      "score": "12",
      "oppScore": "13",
      "opp": "Oakgrove Boys",
      "oppId": "1zUV4kEpPc%2flA39WIyrp9j1w%2fhuIkgE6KlD9%2f%2ffQzg8%3d"
    },
    {
      "date": "July 10",
      "tournament": "MOB Open",
      "tournamentId": "MOB-Open",
      "gameId": "RsZ32eif1WBcDW0TfIX4Hax6tdk9YVkUogUe%2f%2bbN7iI%3d",
      "team": "Bomb Squad",
      "score": "13",
      "oppScore": "8",
      "opp": "MOB Ultimate",
      "oppId": "otuSqjNVhCJZE0ojAe0x6mw6GKk1Ux8ciM5ADgrPUEI%3d"
    },
    {
      "date": "July 10",
      "tournament": "MOB Open",
      "tournamentId": "MOB-Open",
      "gameId": "ZecpxoNftVB2QO8UpdRRM2fu4WznQZpV1xrMCOQynRU%3d",
      "team": "Bomb Squad",
      "score": "9",
      "oppScore": "13",
      "opp": "Redwolves",
      "oppId": "DoL%2frShNUJHM7OLFhcsMzWlsS0aohpfm30HvDbpVZi4%3d"
    },
    {
      "date": "July 16",
      "tournament": "Philly Special 2022",
      "tournamentId": "Philly-Special-2022",
      "gameId": "s9Kdw6m9pEwdFEK%2b4zPXvAWOsN3fsLnlie8IYsOSauo%3d",
      "team": "Bomb Squad",
      "score": "11",
      "oppScore": "10",
      "opp": "John Doe",
      "oppId": "U8i%2ffBl%2bGdNRXUaehdkV4W9lOM4QHUciytmWcy3oxXI%3d"
    },
    {
      "date": "July 16",
      "tournament": "Philly Special 2022",
      "tournamentId": "Philly-Special-2022",
      "gameId": "4fVM4T93ZmHKgQWHJYMVwBvZmu3q%2bPv%2f4AZbbWiFTO0%3d",
      "team": "Bomb Squad",
      "score": "8",
      "oppScore": "15",
      "opp": "Town Hall Stars",
      "oppId": "2Kd8tzcR0KA7rHRMTMb17b6HIPSIH%2bVBl3400ISNCtw%3d"
    },
    {
      "date": "July 16",
      "tournament": "Philly Special 2022",
      "tournamentId": "Philly-Special-2022",
      "gameId": "KW3pP0BPh8urO53nl%2frLvfXNQ9%2fAf4FIaAlRPRjMtIs%3d",
      "team": "Bomb Squad",
      "score": "15",
      "oppScore": "7",
      "opp": "Adelphos",
      "oppId": "IINBHzSyAD1qveaivUllPpQBPkMwjzVWcFo4s84DFtc%3d"
    },
    {
      "date": "July 17",
      "tournament": "Philly Special 2022",
      "tournamentId": "Philly-Special-2022",
      "gameId": "4nShaAE9rBSkLqOkt59Dls3VUGDwAlFjpGs5rURuo%2fY%3d",
      "team": "Bomb Squad",
      "score": "11",
      "oppScore": "14",
      "opp": "John Doe",
      "oppId": "U8i%2ffBl%2bGdNRXUaehdkV4W9lOM4QHUciytmWcy3oxXI%3d"
    },
    {
      "date": "July 17",
      "tournament": "Philly Special 2022",
      "tournamentId": "Philly-Special-2022",
      "gameId": "OYEa8MJ%2bPw43x7qRZx7HtjhGOX1aCHEs%2bx6mVhtk2%2fg%3d",
      "team": "Bomb Squad",
      "score": "12",
      "oppScore": "10",
      "opp": "Adelphos",
      "oppId": "IINBHzSyAD1qveaivUllPpQBPkMwjzVWcFo4s84DFtc%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "rTVs8qe86Vt6w87ktRBfESm2Gerj4zcXSIg62YNp2kQ%3d",
      "team": "Bomb Squad",
      "score": "12",
      "oppScore": "5",
      "opp": "Adelphos",
      "oppId": "IINBHzSyAD1qveaivUllPpQBPkMwjzVWcFo4s84DFtc%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "GLjcjC1hedTmmwBIJ4G2iYUSXu0FlNz5lDMiOATO2cI%3d",
      "team": "Bomb Squad",
      "score": "8",
      "oppScore": "9",
      "opp": "BAG",
      "oppId": "0tQmFBgNfrHGaAXxTYC%2f8oP1TSA1q7cMU6hhrYmt1Y0%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "FrJSLgeQaAHOi5SQm9cMyGqNpnYfJ7m3ftqElFFR3tQ%3d",
      "team": "Bomb Squad",
      "score": "7",
      "oppScore": "13",
      "opp": "Colt",
      "oppId": "csuSEMdqMABUec374gfmA4qxClU85JTTEhhJhBcJ2Do%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "pUdJtkK4Sir9vuJz%2f0b98PgedVaVVlHKBhYsHLKM17Y%3d",
      "team": "Bomb Squad",
      "score": "9",
      "oppScore": "8",
      "opp": "John Doe",
      "oppId": "U8i%2ffBl%2bGdNRXUaehdkV4W9lOM4QHUciytmWcy3oxXI%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "Cfi7t0bg%2bDv4pUT6%2btZta4qvghzitPRYQO%2fJgd68DeY%3d",
      "team": "Bomb Squad",
      "score": "8",
      "oppScore": "9",
      "opp": "Helots",
      "oppId": "m6Orv8C1KcDAyDCEIuhdmpNBKN41R0jWgAvkxa%2bLmOk%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "aPOY2b6QeIHdEACVxeQLl6VYyXv9yEXGnB0x%2fNV9%2bZY%3d",
      "team": "Bomb Squad",
      "score": "7",
      "oppScore": "11",
      "opp": "Magma Bears",
      "oppId": "tOcZDMKk80VDM0VC5NJXyzOSixma%2fCVGplidpqww6q8%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "5p1T16saogxbWLd79CIJm%2ftfGuCXy5qU05rdSDBhwVc%3d",
      "team": "Bomb Squad",
      "score": "13",
      "oppScore": "6",
      "opp": "Odyssey",
      "oppId": "b3BwYIqzVB8cFGN1zic0%2bcWc5J5ATsFKGuQD%2ferKcTM%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "PU9BbyJZRlT516PFLJuwjj%2fVouB35e%2fJlA7B2A7kio0%3d",
      "team": "Bomb Squad",
      "score": "13",
      "oppScore": "11",
      "opp": "Magma Bears",
      "oppId": "tOcZDMKk80VDM0VC5NJXyzOSixma%2fCVGplidpqww6q8%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "rTTYTOZU8xqA7lo%2b5Hy3DCV8wjOQXDYSztcQJDvFREc%3d",
      "team": "Bomb Squad",
      "score": "11",
      "oppScore": "12",
      "opp": "7AM",
      "oppId": "mQPEfmcAYYIzzM4%2fYEbIkukCamR5oeyXQBECP7Pwhh8%3d"
    }
  ],
  "KtH9xWy9jBGhUoOWQhn%2fSIE8Vsl4jI9MQcOiMTnZdyQ%3d": [
    {
      "date": "June 26",
      "tournament": "MOB Invite",
      "tournamentId": "MOB-Invite",
      "gameId": "ec8gZxxNVUim5ASALXIOPZBSOmcvB5Y6Rddmuqfr%2bKQ%3d",
      "team": "SUPA FC",
      "score": "9",
      "oppScore": "11",
      "opp": "John Doe",
      "oppId": "U8i%2ffBl%2bGdNRXUaehdkV4W9lOM4QHUciytmWcy3oxXI%3d"
    },
    {
      "date": "June 26",
      "tournament": "MOB Invite",
      "tournamentId": "MOB-Invite",
      "gameId": "xkAbqrvbsJfzrM7vW9RCTZc3hDM2cPkQdQ%2fkaq1XK%2fI%3d",
      "team": "SUPA FC",
      "score": "8",
      "oppScore": "11",
      "opp": "Bomb Squad",
      "oppId": "GMU0OYY8eXqf1AR6M93JxRSXSpRgEq9sJxKUtkOjXgg%3d"
    },
    {
      "date": "June 26",
      "tournament": "MOB Invite",
      "tournamentId": "MOB-Invite",
      "gameId": "4svrd7xTamtBt9RnVoqohc2JmHJ9pZMFTRCA497y4G0%3d",
      "team": "SUPA FC",
      "score": "13",
      "oppScore": "7",
      "opp": "Winc City Fog of War",
      "oppId": "6gsiUt3INMB4Td2RTxJkksYpRIJsACnjptCfHUZeg2o%3d"
    },
    {
      "date": "June 26",
      "tournament": "MOB Invite",
      "tournamentId": "MOB-Invite",
      "gameId": "js8au3nW99dNCn7PmLBkzXlrrgRO0gnx0n5YqrIeXDY%3d",
      "team": "SUPA FC",
      "score": "10",
      "oppScore": "8",
      "opp": "MOB Ultimate",
      "oppId": "otuSqjNVhCJZE0ojAe0x6mw6GKk1Ux8ciM5ADgrPUEI%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "JnvNtMnD5XFwgs4qO76tKfA4MGHNaPmIv4%2bI1VOKCr0%3d",
      "team": "SUPA FC",
      "score": "13",
      "oppScore": "6",
      "opp": "Queen City Kings",
      "oppId": "95yP4SyRqRX7HgLtZ9jNth95JPB163KSMqpgMxymy8g%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "eVR8VXD8vK4%2bqmwINSGciWVGtzFzgHklDYz%2bJOAvqus%3d",
      "team": "SUPA FC",
      "score": "10",
      "oppScore": "6",
      "opp": "Town Hall Stars",
      "oppId": "2Kd8tzcR0KA7rHRMTMb17b6HIPSIH%2bVBl3400ISNCtw%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "EculGFWFr53OGSNYlrkrHfR00WrTvY32%2bFh6iJhYJuE%3d",
      "team": "SUPA FC",
      "score": "6",
      "oppScore": "13",
      "opp": "Blueprint",
      "oppId": "BDhLSFxnViHodVQ9noIy0%2by0HWSmiJ3qgV5WI1ozsAk%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "VafB1wWbuCK0YfNtPs%2fnjt%2b5kfDoiOlX629tt0e34Pc%3d",
      "team": "SUPA FC",
      "score": "4",
      "oppScore": "13",
      "opp": "Helots",
      "oppId": "m6Orv8C1KcDAyDCEIuhdmpNBKN41R0jWgAvkxa%2bLmOk%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "xVI%2fZHau6Kt0Ki50mO3OuHWzZHGlMTP1HqSXVNWu0bg%3d",
      "team": "SUPA FC",
      "score": "7",
      "oppScore": "13",
      "opp": "John Doe",
      "oppId": "U8i%2ffBl%2bGdNRXUaehdkV4W9lOM4QHUciytmWcy3oxXI%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "72X9YB84RUWkOMpYsghGRnpiPU1KStqlaJxClM0eioE%3d",
      "team": "SUPA FC",
      "score": "7",
      "oppScore": "13",
      "opp": "Ballston Booya",
      "oppId": "nyrFBt9ebf%2bCb5bJv%2fsbfoGgjlyW%2bH3CLoFeaZGYMEY%3d"
    }
  ],
  "b3BwYIqzVB8cFGN1zic0%2bcWc5J5ATsFKGuQD%2ferKcTM%3d": [
    {
      "date": "July 09",
      "tournament": "Antlerlock 2022",
      "tournamentId": "Antlerlock-2022",
      "gameId": "zn8%2biapCnCKFv4enayA%2b6JFnG73G0uUAehD%2fmh7nKsI%3d",
      "team": "Odyssey",
      "score": "4",
      "oppScore": "15",
      "opp": "Alibi",
      "oppId": "YEITgS48Qi42eAbGR80m%2fHHsltj1RQq%2be2tZnpaH3HY%3d"
    },
    {
      "date": "July 09",
      "tournament": "Antlerlock 2022",
      "tournamentId": "Antlerlock-2022",
      "gameId": "ubBHGHagWonGqFmUIu%2fS%2bkLkctJ9%2fG5%2bN3WiCJuN00E%3d",
      "team": "Odyssey",
      "score": "4",
      "oppScore": "15",
      "opp": "TireBizFriz",
      "oppId": "8V7KiKjowPKXYVA3WBFCPRK7xrpBH0Y57Z6GJCfBx3s%3d"
    },
    {
      "date": "July 09",
      "tournament": "Antlerlock 2022",
      "tournamentId": "Antlerlock-2022",
      "gameId": "%2bat8XnBPHquRTNgL7ABJpKjVs23DmTsPz%2bBcGtuFXIQ%3d",
      "team": "Odyssey",
      "score": "1",
      "oppScore": "14",
      "opp": "Lantern",
      "oppId": "5z%2bIWsl3V2NLqJRxKMauFZK8Pmkffh9obp1ocWQtXnY%3d"
    },
    {
      "date": "July 09",
      "tournament": "Antlerlock 2022",
      "tournamentId": "Antlerlock-2022",
      "gameId": "kzx4ZZfTZG1NmI%2bLi0NXzPBzndFWxCpaueAvzCTdi5o%3d",
      "team": "Odyssey",
      "score": "6",
      "oppScore": "15",
      "opp": "Too-few Monkeys",
      "oppId": "NTHtKdZmV%2f4aDq0a0cTEErTk3C4EkBo6tDXr5R1S2gE%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "%2fSNxw%2bucUUjM%2f5Y1FT0GrjFRUrRWIaoIFtOTN%2bzK160%3d",
      "team": "Odyssey",
      "score": "6",
      "oppScore": "11",
      "opp": "NIIIght",
      "oppId": "Mrfn10Tc53xvcYoOI6fGWhBaehYqA70hV8%2bGcc6QWF4%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "T2APvj%2bzDPfzba3dkFsQTRSybX3%2fwRKqrEPRSyRmA8M%3d",
      "team": "Odyssey",
      "score": "2",
      "oppScore": "15",
      "opp": "Club M - Manic",
      "oppId": "EPJEJz5rBmfBf0wlHEMDiTnhdrXlFtK%2bu2m7WHlH71E%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "SqScEuYr58dKrNh%2biNavFNTgo0dICtimS5MnMoubChQ%3d",
      "team": "Odyssey",
      "score": "2",
      "oppScore": "15",
      "opp": "Helots",
      "oppId": "m6Orv8C1KcDAyDCEIuhdmpNBKN41R0jWgAvkxa%2bLmOk%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "De8nRGBhbhF69QWs64v%2btMKLP9kpOgV0p355Q6JXisU%3d",
      "team": "Odyssey",
      "score": "6",
      "oppScore": "13",
      "opp": "BUDA U20B",
      "oppId": "jNsNN0Cf8lhuONh44thxFg7QsW6SRNpdPPPxrQyss8I%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "dqM0fdo0a9MJtaFY9IKQ6TGgvwKgXnx4ivq4vBc9%2bxU%3d",
      "team": "Odyssey",
      "score": "11",
      "oppScore": "12",
      "opp": "Red Squirrels",
      "oppId": "jLIBBhnc%2bi3%2bYrlyriV4mCL5Ti7S8%2b9yO0XC5HXh%2fcQ%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "UK0m%2f70PP5tGpmLtzE1XR5zV%2bTlC%2f3UEEMVvB29KY88%3d",
      "team": "Odyssey",
      "score": "10",
      "oppScore": "8",
      "opp": "Alibi",
      "oppId": "YEITgS48Qi42eAbGR80m%2fHHsltj1RQq%2be2tZnpaH3HY%3d"
    },
    {
      "date": "August 20",
      "tournament": "Northeast Classic 2022",
      "tournamentId": "Northeast-Classic-2022",
      "gameId": "iPRktCFRKhwMOUQ8LX79KuX394kK7lYIgMH2IgRnuYE%3d",
      "team": "Odyssey",
      "score": "13",
      "oppScore": "15",
      "opp": "Alibi",
      "oppId": "YEITgS48Qi42eAbGR80m%2fHHsltj1RQq%2be2tZnpaH3HY%3d"
    },
    {
      "date": "August 20",
      "tournament": "Northeast Classic 2022",
      "tournamentId": "Northeast-Classic-2022",
      "gameId": "tdlP2FndG0RXnFYGVzNAd5%2bi2SR%2bRHcRvbQ9nMaWaCI%3d",
      "team": "Odyssey",
      "score": "7",
      "oppScore": "15",
      "opp": "ADK Mountain Crew",
      "oppId": "kq%2bjX0z0mI2demJGurW9c4YwXa1qYvydu%2fcl7Dmf4PM%3d"
    },
    {
      "date": "August 20",
      "tournament": "Northeast Classic 2022",
      "tournamentId": "Northeast-Classic-2022",
      "gameId": "hZIA2qcnG%2bG%2bbt00uUTlnww0L%2b9M531k1bgqpbvJogU%3d",
      "team": "Odyssey",
      "score": "3",
      "oppScore": "15",
      "opp": "MBTA",
      "oppId": "hLR3gjv9xQ1g1QebpZRZmoy9aTcPypW2VOulzmPNeXs%3d"
    },
    {
      "date": "August 21",
      "tournament": "Northeast Classic 2022",
      "tournamentId": "Northeast-Classic-2022",
      "gameId": "d7X0rahXc4OPmRNggt%2fQtqTe9XYaD1NQCrVz3H50ERU%3d",
      "team": "Odyssey",
      "score": "8",
      "oppScore": "15",
      "opp": "MBTA",
      "oppId": "hLR3gjv9xQ1g1QebpZRZmoy9aTcPypW2VOulzmPNeXs%3d"
    },
    {
      "date": "August 22",
      "tournament": "Northeast Classic 2022",
      "tournamentId": "Northeast-Classic-2022",
      "gameId": "sU%2fligE45YmpsMpMCt%2b9jNiFOo9ouogZRv9mTEfDFmE%3d",
      "team": "Odyssey",
      "score": "12",
      "oppScore": "10",
      "opp": "ADK Mountain Crew",
      "oppId": "kq%2bjX0z0mI2demJGurW9c4YwXa1qYvydu%2fcl7Dmf4PM%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "wykpPlgCW1mheq%2bafPcqbRETlpbMhvpyqpkfQkeRpKs%3d",
      "team": "Odyssey",
      "score": "4",
      "oppScore": "13",
      "opp": "Magma Bears",
      "oppId": "tOcZDMKk80VDM0VC5NJXyzOSixma%2fCVGplidpqww6q8%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "5p1T16saogxbWLd79CIJm%2ftfGuCXy5qU05rdSDBhwVc%3d",
      "team": "Odyssey",
      "score": "6",
      "oppScore": "13",
      "opp": "Bomb Squad",
      "oppId": "GMU0OYY8eXqf1AR6M93JxRSXSpRgEq9sJxKUtkOjXgg%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "IL%2b7VJ8cTP05TuvvzZt44N7zCkBgwVhOk8kJIi0NtNY%3d",
      "team": "Odyssey",
      "score": "8",
      "oppScore": "13",
      "opp": "7AM",
      "oppId": "mQPEfmcAYYIzzM4%2fYEbIkukCamR5oeyXQBECP7Pwhh8%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "tymOdGEKmGC%2bp1MkMXQvjgX9W95P6%2fmeWcW4zz8Ij6o%3d",
      "team": "Odyssey",
      "score": "4",
      "oppScore": "13",
      "opp": "Needham Squad",
      "oppId": "nB%2bSQRgdJB9OGhcTAQ0lU8uZ8f%2bTFwQebHh0A8topa0%3d"
    }
  ],
  "NTHtKdZmV%2f4aDq0a0cTEErTk3C4EkBo6tDXr5R1S2gE%3d": [
    {
      "date": "July 09",
      "tournament": "Antlerlock 2022",
      "tournamentId": "Antlerlock-2022",
      "gameId": "9C1xy5WrLtryye4v4AaXG531aEbuRXrTR1K%2b9SqIeDQ%3d",
      "team": "Too-few Monkeys",
      "score": "5",
      "oppScore": "15",
      "opp": "TireBizFriz",
      "oppId": "8V7KiKjowPKXYVA3WBFCPRK7xrpBH0Y57Z6GJCfBx3s%3d"
    },
    {
      "date": "July 09",
      "tournament": "Antlerlock 2022",
      "tournamentId": "Antlerlock-2022",
      "gameId": "kzx4ZZfTZG1NmI%2bLi0NXzPBzndFWxCpaueAvzCTdi5o%3d",
      "team": "Too-few Monkeys",
      "score": "15",
      "oppScore": "6",
      "opp": "Odyssey",
      "oppId": "b3BwYIqzVB8cFGN1zic0%2bcWc5J5ATsFKGuQD%2ferKcTM%3d"
    }
  ],
  "YEITgS48Qi42eAbGR80m%2fHHsltj1RQq%2be2tZnpaH3HY%3d": [
    {
      "date": "July 09",
      "tournament": "Antlerlock 2022",
      "tournamentId": "Antlerlock-2022",
      "gameId": "zn8%2biapCnCKFv4enayA%2b6JFnG73G0uUAehD%2fmh7nKsI%3d",
      "team": "Alibi",
      "score": "15",
      "oppScore": "4",
      "opp": "Odyssey",
      "oppId": "b3BwYIqzVB8cFGN1zic0%2bcWc5J5ATsFKGuQD%2ferKcTM%3d"
    },
    {
      "date": "July 09",
      "tournament": "Antlerlock 2022",
      "tournamentId": "Antlerlock-2022",
      "gameId": "mcG7hEVXa0r1a6Gm%2fpUD8%2bibJXGtLy5BXh6XprSXvlc%3d",
      "team": "Alibi",
      "score": "4",
      "oppScore": "15",
      "opp": "Lantern",
      "oppId": "5z%2bIWsl3V2NLqJRxKMauFZK8Pmkffh9obp1ocWQtXnY%3d"
    },
    {
      "date": "July 09",
      "tournament": "Antlerlock 2022",
      "tournamentId": "Antlerlock-2022",
      "gameId": "G5CJuaBAmkhmXJYDGMbdK6CnjuA%2blg8e4FQr73kqzRQ%3d",
      "team": "Alibi",
      "score": "6",
      "oppScore": "15",
      "opp": "TireBizFriz",
      "oppId": "8V7KiKjowPKXYVA3WBFCPRK7xrpBH0Y57Z6GJCfBx3s%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "3TIRjTKi2rizEFJGXfOStS69a4GLBIo86jKRllJ%2fB2g%3d",
      "team": "Alibi",
      "score": "12",
      "oppScore": "11",
      "opp": "Red Squirrels",
      "oppId": "jLIBBhnc%2bi3%2bYrlyriV4mCL5Ti7S8%2b9yO0XC5HXh%2fcQ%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "SA07IVmEdIMPjed0As44mQ3aA6XumlFLG9IN7ETDy2w%3d",
      "team": "Alibi",
      "score": "5",
      "oppScore": "10",
      "opp": "Colt",
      "oppId": "csuSEMdqMABUec374gfmA4qxClU85JTTEhhJhBcJ2Do%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "XuT%2bPXa0ZUspYEkYETK0KY9LfSTQfYVapGAlBdxKELQ%3d",
      "team": "Alibi",
      "score": "4",
      "oppScore": "12",
      "opp": "TROUT",
      "oppId": "bv%2fXE7f5uCQpkvM1FFa1CUcXftCxs7NptVBS7qJ1FVE%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "cM105Boy%2b1%2bq9qgzf1KZ7rkAQWUujYRvbDUKRfORyXg%3d",
      "team": "Alibi",
      "score": "3",
      "oppScore": "15",
      "opp": "MBTA",
      "oppId": "hLR3gjv9xQ1g1QebpZRZmoy9aTcPypW2VOulzmPNeXs%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "yPJRd3GzU%2ffEKhcwsx3%2fRQt489m8oq6mJmmXnsPHTZ8%3d",
      "team": "Alibi",
      "score": "7",
      "oppScore": "15",
      "opp": "NIIIght",
      "oppId": "Mrfn10Tc53xvcYoOI6fGWhBaehYqA70hV8%2bGcc6QWF4%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "UK0m%2f70PP5tGpmLtzE1XR5zV%2bTlC%2f3UEEMVvB29KY88%3d",
      "team": "Alibi",
      "score": "8",
      "oppScore": "10",
      "opp": "Odyssey",
      "oppId": "b3BwYIqzVB8cFGN1zic0%2bcWc5J5ATsFKGuQD%2ferKcTM%3d"
    },
    {
      "date": "August 20",
      "tournament": "Northeast Classic 2022",
      "tournamentId": "Northeast-Classic-2022",
      "gameId": "iPRktCFRKhwMOUQ8LX79KuX394kK7lYIgMH2IgRnuYE%3d",
      "team": "Alibi",
      "score": "15",
      "oppScore": "13",
      "opp": "Odyssey",
      "oppId": "b3BwYIqzVB8cFGN1zic0%2bcWc5J5ATsFKGuQD%2ferKcTM%3d"
    },
    {
      "date": "August 20",
      "tournament": "Northeast Classic 2022",
      "tournamentId": "Northeast-Classic-2022",
      "gameId": "cOsXHaZ7eL8aej2sZAQLIAleaAOtmA4oRfA7UUrErTU%3d",
      "team": "Alibi",
      "score": "8",
      "oppScore": "15",
      "opp": "MBTA",
      "oppId": "hLR3gjv9xQ1g1QebpZRZmoy9aTcPypW2VOulzmPNeXs%3d"
    },
    {
      "date": "August 20",
      "tournament": "Northeast Classic 2022",
      "tournamentId": "Northeast-Classic-2022",
      "gameId": "%2f9OOVaR99sZoCm71z%2bxLeEKrYEw94CWrhPOwVzLUkr0%3d",
      "team": "Alibi",
      "score": "14",
      "oppScore": "15",
      "opp": "ADK Mountain Crew",
      "oppId": "kq%2bjX0z0mI2demJGurW9c4YwXa1qYvydu%2fcl7Dmf4PM%3d"
    },
    {
      "date": "August 21",
      "tournament": "Northeast Classic 2022",
      "tournamentId": "Northeast-Classic-2022",
      "gameId": "f4REulb%2bL882EwXfbVu4Xgpf4uaL7Bia8kLhgnn8%2bCs%3d",
      "team": "Alibi",
      "score": "15",
      "oppScore": "11",
      "opp": "ADK Mountain Crew",
      "oppId": "kq%2bjX0z0mI2demJGurW9c4YwXa1qYvydu%2fcl7Dmf4PM%3d"
    },
    {
      "date": "August 21",
      "tournament": "Northeast Classic 2022",
      "tournamentId": "Northeast-Classic-2022",
      "gameId": "VwZVIf3IZCVJC6wH%2fa0ZPYK1x3KNbUNm%2f5H4l%2f4e7as%3d",
      "team": "Alibi",
      "score": "14",
      "oppScore": "15",
      "opp": "MBTA",
      "oppId": "hLR3gjv9xQ1g1QebpZRZmoy9aTcPypW2VOulzmPNeXs%3d"
    }
  ],
  "5z%2bIWsl3V2NLqJRxKMauFZK8Pmkffh9obp1ocWQtXnY%3d": [
    {
      "date": "July 09",
      "tournament": "Antlerlock 2022",
      "tournamentId": "Antlerlock-2022",
      "gameId": "2Or3ohfmMAP210OKqRLtUF%2fxdGVujVIe%2fDt%2bqnH3kZ8%3d",
      "team": "Lantern",
      "score": "10",
      "oppScore": "13",
      "opp": "TireBizFriz",
      "oppId": "8V7KiKjowPKXYVA3WBFCPRK7xrpBH0Y57Z6GJCfBx3s%3d"
    },
    {
      "date": "July 09",
      "tournament": "Antlerlock 2022",
      "tournamentId": "Antlerlock-2022",
      "gameId": "mcG7hEVXa0r1a6Gm%2fpUD8%2bibJXGtLy5BXh6XprSXvlc%3d",
      "team": "Lantern",
      "score": "15",
      "oppScore": "4",
      "opp": "Alibi",
      "oppId": "YEITgS48Qi42eAbGR80m%2fHHsltj1RQq%2be2tZnpaH3HY%3d"
    },
    {
      "date": "July 09",
      "tournament": "Antlerlock 2022",
      "tournamentId": "Antlerlock-2022",
      "gameId": "%2bat8XnBPHquRTNgL7ABJpKjVs23DmTsPz%2bBcGtuFXIQ%3d",
      "team": "Lantern",
      "score": "14",
      "oppScore": "1",
      "opp": "Odyssey",
      "oppId": "b3BwYIqzVB8cFGN1zic0%2bcWc5J5ATsFKGuQD%2ferKcTM%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "iYwbal%2f6baP52oVn6XzhSsZBHoN%2fOGdHP9%2b2McEle84%3d",
      "team": "Lantern",
      "score": "13",
      "oppScore": "4",
      "opp": "BAG",
      "oppId": "0tQmFBgNfrHGaAXxTYC%2f8oP1TSA1q7cMU6hhrYmt1Y0%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "vq4pdMiy2LV3WsBsfGjrPlyYhnV4jgsEVwT%2bKO76ixc%3d",
      "team": "Lantern",
      "score": "8",
      "oppScore": "14",
      "opp": "Big Wrench",
      "oppId": "cD5%2b6qwajeEeZXFAZqH%2f%2fSNl6zunDFFnMpdAAl7eKv0%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "l371w59muWXJmscGVHPVA7aUb4NidBUSb5vZoimHn3A%3d",
      "team": "Lantern",
      "score": "12",
      "oppScore": "8",
      "opp": "MKE",
      "oppId": "qHyFAYrlLVWh07Z9rgHqv1nh7SsCt5k4p8Ri0Bna7Zc%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "zG3Migju48p%2fZ7InxGYhwK8rOo5w3y%2fqPmtrX6MfvXY%3d",
      "team": "Lantern",
      "score": "15",
      "oppScore": "6",
      "opp": "Magma Bears",
      "oppId": "tOcZDMKk80VDM0VC5NJXyzOSixma%2fCVGplidpqww6q8%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "CCYVZUaCEzC6tMXxjoEsM059paMfZz4bIA5WaPQ3HYk%3d",
      "team": "Lantern",
      "score": "6",
      "oppScore": "15",
      "opp": "DiG",
      "oppId": "kn%2bHmtzxdjY7vxb%2fpJ9qsfEsWaDB6JrUBL7Ga4bU18M%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "cuTokFP4c0QiZ23N9mlPwunCqnrlzYXYO176bbCO9O0%3d",
      "team": "Lantern",
      "score": "11",
      "oppScore": "10",
      "opp": "Big Wrench",
      "oppId": "cD5%2b6qwajeEeZXFAZqH%2f%2fSNl6zunDFFnMpdAAl7eKv0%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "szLWq%2bUFNVfBstPGcQFILAI0wzLu1tHHzdq9MfgRLcE%3d",
      "team": "Lantern",
      "score": "13",
      "oppScore": "5",
      "opp": "Spring Break '93",
      "oppId": "hRUlbCXzaOf83wDSPKpV36LeX3FmlXgFaE5wdndlB3Q%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "2agntXeeoq8qr%2bIhjD6Mno1EuVxZSffrV2OwIo0%2fCfk%3d",
      "team": "Lantern",
      "score": "13",
      "oppScore": "6",
      "opp": "CITYWIDE Special",
      "oppId": "gfGiZOzR7u7D3oIG%2bAqrTtu3j0xEuTDZKIumDUKFRto%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "OxmWj3jbZax5TiC6mAkbX%2bnrjL5mvWYmFoUAUXoW98A%3d",
      "team": "Lantern",
      "score": "13",
      "oppScore": "3",
      "opp": "Scoop",
      "oppId": "Rj8mJfKJry2xyWaxTIh5HdXGLaTMxr7%2fXjyL4uJET2o%3d"
    }
  ],
  "8V7KiKjowPKXYVA3WBFCPRK7xrpBH0Y57Z6GJCfBx3s%3d": [
    {
      "date": "July 09",
      "tournament": "Antlerlock 2022",
      "tournamentId": "Antlerlock-2022",
      "gameId": "2Or3ohfmMAP210OKqRLtUF%2fxdGVujVIe%2fDt%2bqnH3kZ8%3d",
      "team": "TireBizFriz",
      "score": "13",
      "oppScore": "10",
      "opp": "Lantern",
      "oppId": "5z%2bIWsl3V2NLqJRxKMauFZK8Pmkffh9obp1ocWQtXnY%3d"
    },
    {
      "date": "July 09",
      "tournament": "Antlerlock 2022",
      "tournamentId": "Antlerlock-2022",
      "gameId": "ubBHGHagWonGqFmUIu%2fS%2bkLkctJ9%2fG5%2bN3WiCJuN00E%3d",
      "team": "TireBizFriz",
      "score": "15",
      "oppScore": "4",
      "opp": "Odyssey",
      "oppId": "b3BwYIqzVB8cFGN1zic0%2bcWc5J5ATsFKGuQD%2ferKcTM%3d"
    },
    {
      "date": "July 09",
      "tournament": "Antlerlock 2022",
      "tournamentId": "Antlerlock-2022",
      "gameId": "9C1xy5WrLtryye4v4AaXG531aEbuRXrTR1K%2b9SqIeDQ%3d",
      "team": "TireBizFriz",
      "score": "15",
      "oppScore": "5",
      "opp": "Too-few Monkeys",
      "oppId": "NTHtKdZmV%2f4aDq0a0cTEErTk3C4EkBo6tDXr5R1S2gE%3d"
    },
    {
      "date": "July 09",
      "tournament": "Antlerlock 2022",
      "tournamentId": "Antlerlock-2022",
      "gameId": "G5CJuaBAmkhmXJYDGMbdK6CnjuA%2blg8e4FQr73kqzRQ%3d",
      "team": "TireBizFriz",
      "score": "15",
      "oppScore": "6",
      "opp": "Alibi",
      "oppId": "YEITgS48Qi42eAbGR80m%2fHHsltj1RQq%2be2tZnpaH3HY%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "%2bjLm%2b04f8JD9Eu7TCxYGcEQGWq33I5i2uep614KB3QU%3d",
      "team": "TireBizFriz",
      "score": "14",
      "oppScore": "10",
      "opp": "Needham Squad",
      "oppId": "nB%2bSQRgdJB9OGhcTAQ0lU8uZ8f%2bTFwQebHh0A8topa0%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "TBuL%2frAv2fdeY6iWUbzCM5%2bnm4Fu4agcpwPmw%2bxzsIQ%3d",
      "team": "TireBizFriz",
      "score": "12",
      "oppScore": "10",
      "opp": "Iditarod",
      "oppId": "7tBre8lNjeAgLNCS5dx%2fnhybe521PEeqaFyoOhXPzSk%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "eT0jWU5RDyUBoE18gIMBTM5VptoxUSOrtp11n8TyOLY%3d",
      "team": "TireBizFriz",
      "score": "12",
      "oppScore": "7",
      "opp": "Red Tide",
      "oppId": "s6HGV42d1dEo0YRx%2fIycGA2qxvKRYzzfEITLPlDYO1I%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "uOdeJ0pzen2chFFcndWsuiF8Y38btlPcca%2bqld4SsQA%3d",
      "team": "TireBizFriz",
      "score": "5",
      "oppScore": "15",
      "opp": "DiG",
      "oppId": "kn%2bHmtzxdjY7vxb%2fpJ9qsfEsWaDB6JrUBL7Ga4bU18M%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "EGCE3R%2b12wVK3FuEl4K2SIm4A0yb94W%2bIvmzpQwn5UQ%3d",
      "team": "TireBizFriz",
      "score": "14",
      "oppScore": "9",
      "opp": "Big Wrench",
      "oppId": "cD5%2b6qwajeEeZXFAZqH%2f%2fSNl6zunDFFnMpdAAl7eKv0%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "A9jBmz88HrPl7865cX%2ft1soj9edG9JYy%2f0d8Cxure3o%3d",
      "team": "TireBizFriz",
      "score": "4",
      "oppScore": "15",
      "opp": "DiG",
      "oppId": "kn%2bHmtzxdjY7vxb%2fpJ9qsfEsWaDB6JrUBL7Ga4bU18M%3d"
    }
  ],
  "F7zZjPQTPbFe%2fxIVicHJD2zRIOORpO5hSxfBuY5fxcQ%3d": [
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "46vVZDVat4bQ7pM%2feFVSJfcChcX%2fK7y6YU8e%2bMYrkK4%3d",
      "team": "Haymaker",
      "score": "15",
      "oppScore": "6",
      "opp": "Trident II",
      "oppId": "b69A0xfMxQKwR01A7jfOqdv1wbChbTxRp5jko3aOCXk%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "bKRPyIAr9Hy0m%2fL0jfq60xsC7IIZESwJ3Pp6FsRSbuI%3d",
      "team": "Haymaker",
      "score": "15",
      "oppScore": "8",
      "opp": "Timber",
      "oppId": "KEdthZtu%2ffyLTQQr6Y655G91Wz0j0VrQj7Ap2G5YBOM%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "eCcmK8GEDEpcYroxroAwTfvLJG9MsITnR1W%2bdru2nc4%3d",
      "team": "Haymaker",
      "score": "10",
      "oppScore": "15",
      "opp": "Mallard",
      "oppId": "iIrscp1ZOpIpCSkyCbUNt0Vq3FobvpA18ZWbPM63hJo%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "NvrSsV9NfcENpJTngCImokiLSwO%2f8hNhC3E1mjUAAvc%3d",
      "team": "Haymaker",
      "score": "12",
      "oppScore": "13",
      "opp": "Colonels",
      "oppId": "7Xv2AHNIg3Y%2fm1y7qWkHUz%2b9KT3NZFOCMpW7gBaxnfs%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "oiyq4Y0%2foBXO7FEjz3CGIOB6%2f8cejqeV0%2b1ksPMFf3o%3d",
      "team": "Haymaker",
      "score": "9",
      "oppScore": "14",
      "opp": "Knights of Ni",
      "oppId": "dgH60oTrddgw%2bSl2xkIe%2fis%2fu1HY9ndk1MZTdN4OiAs%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "2IZc469JFIy5utOpN8QWjEXH%2fbqKnSz%2fvuH0sLz8gnw%3d",
      "team": "Haymaker",
      "score": "13",
      "oppScore": "7",
      "opp": "MKE",
      "oppId": "qHyFAYrlLVWh07Z9rgHqv1nh7SsCt5k4p8Ri0Bna7Zc%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "my%2btjM0oipVb04pQxLCyKSD%2biYQwoFxVnl1x7sJgDYA%3d",
      "team": "Haymaker",
      "score": "8",
      "oppScore": "9",
      "opp": "Lost Boys",
      "oppId": "aNMyR0uAJNwnidjeClZAMqixGjtlQqA6k7CiZP1R0%2bM%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "AMUDrUzCG2a586G7d2SID0ycxkWOiCk2qkKS0BofHU4%3d",
      "team": "Haymaker",
      "score": "2",
      "oppScore": "15",
      "opp": "Vault",
      "oppId": "3xV6rIwyIem%2bql5tz%2bZmFG9gr%2bp6WKu5k4q5bwFcSp0%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "DUZhw8GIL4818hB9Jz1DXvTvhFvmc2JyrTwcBZqmids%3d",
      "team": "Haymaker",
      "score": "7",
      "oppScore": "13",
      "opp": "Raleigh-Durham United",
      "oppId": "ESHhN%2bMK1oy0j%2bQJSL8mb9cq8h30Erb63T07U8pubEc%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "Tz73rjSzazC7aGtkyoL%2f7MWZxUHpjjpHFBuh2NBGVfk%3d",
      "team": "Haymaker",
      "score": "6",
      "oppScore": "12",
      "opp": "Blueprint",
      "oppId": "BDhLSFxnViHodVQ9noIy0%2by0HWSmiJ3qgV5WI1ozsAk%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "Gjf5lH7N8EPsrVVXEoLJ3F7LEWEvvnRhwQfUnjcevkY%3d",
      "team": "Haymaker",
      "score": "9",
      "oppScore": "7",
      "opp": "Mallard",
      "oppId": "iIrscp1ZOpIpCSkyCbUNt0Vq3FobvpA18ZWbPM63hJo%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "iMTAyz7CXCRel38XUAqxHYC6k6IdH8GsW9Qg1ePPPWU%3d",
      "team": "Haymaker",
      "score": "3",
      "oppScore": "10",
      "opp": "Shade",
      "oppId": "Lh0BmORltuq%2bkEq6e8QTGFiUkZuG5GuP4DNNS5vaaAc%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "MKRFGUD464ZLLF2V8Ln1EQxaftfcVqdcfwRmF%2fR%2fqTg%3d",
      "team": "Haymaker",
      "score": "13",
      "oppScore": "2",
      "opp": "Loaded Panda",
      "oppId": "8S%2f2XcUwQbLf0KM7yOmv3HEYXSrnLhAdwnHQvhMjxCY%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "o0vhI%2bVrCs0sqQlHCehhXWMnm20Ku7as76MYNCCLnsA%3d",
      "team": "Haymaker",
      "score": "13",
      "oppScore": "5",
      "opp": "THE BODY",
      "oppId": "nyLl78Eg24EtJDKG0oHsU9e%2fg0hrOYqklm7h8m4R0Jo%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "eP1NEakXIS5ZkHGSMZafE1fToe5Xlh2PljWHT6YjtfU%3d",
      "team": "Haymaker",
      "score": "13",
      "oppScore": "10",
      "opp": "Nomads",
      "oppId": "clyQEvW53ITGsU%2bGFSkoVd77O856fO%2f%2fPStXV2bXfy4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "outfIxF2vanpk970FXST2b3r8XfAtoqotRgwUR7qmiE%3d",
      "team": "Haymaker",
      "score": "13",
      "oppScore": "8",
      "opp": "Nain Rouge",
      "oppId": "VlCPZLsfBQ0DobBH%2fc22bLMbT2Ge3MbFrq%2fRZ8Jw0gM%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "husq5IyfbEZs2mnbq2nyRZQwinBBSKsGuhoP4g%2bIvgs%3d",
      "team": "Haymaker",
      "score": "11",
      "oppScore": "7",
      "opp": "DeMo",
      "oppId": "FdlFpUSGIahm89rZTXrHg2K85ugGqml2fjUWEPHtgPo%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "IDSQK2%2bWvZGRe5PpUhImUYBIVxQ7E1S%2fBQrQCy2uC8s%3d",
      "team": "Haymaker",
      "score": "11",
      "oppScore": "12",
      "opp": "Knights of Ni",
      "oppId": "dgH60oTrddgw%2bSl2xkIe%2fis%2fu1HY9ndk1MZTdN4OiAs%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "DEDFF%2bk3tlwnHpflxkHt2AO9o3OBVprY83yulXLOUgw%3d",
      "team": "Haymaker",
      "score": "13",
      "oppScore": "10",
      "opp": "Mallard II",
      "oppId": "waPLoije6zVsjVUcOXTlGSFZ10PPabaoS6kk%2bnW2NCo%3d"
    }
  ],
  "7Xv2AHNIg3Y%2fm1y7qWkHUz%2b9KT3NZFOCMpW7gBaxnfs%3d": [
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "pnWWA4joe5kqC%2fLaxICfOKsr8XaP4hqOzzFH4jqzZaA%3d",
      "team": "Colonels",
      "score": "15",
      "oppScore": "4",
      "opp": "I-69",
      "oppId": "a64fDYPntTHPU4vghTeNstb32Z0JFlq528bbATJx%2bbs%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "oMMfWXIh4%2bTAzBmqV4fErvg1%2bMHvy2dd%2f1ZpdFxWDP4%3d",
      "team": "Colonels",
      "score": "15",
      "oppScore": "4",
      "opp": "Red Hots - u20",
      "oppId": "Ggh5FAV%2b2R4byKaxZbHQGXO72Bh%2fypyZmUX%2fWkOdMsg%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "ImWumKBxQqQafyaU5p9I2hk96lWERpxTH08gk%2b3a6Lo%3d",
      "team": "Colonels",
      "score": "15",
      "oppScore": "5",
      "opp": "Harvey Cats",
      "oppId": "cbjkcOMrhSyGKLqnK1uuJLy8ENbcEcYemex94WHxvxU%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "NvrSsV9NfcENpJTngCImokiLSwO%2f8hNhC3E1mjUAAvc%3d",
      "team": "Colonels",
      "score": "13",
      "oppScore": "12",
      "opp": "Haymaker",
      "oppId": "F7zZjPQTPbFe%2fxIVicHJD2zRIOORpO5hSxfBuY5fxcQ%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "8uC0CxwgeUyuvvpi0BznEXSgT%2fDQl4SIrbUlDhFJ5pc%3d",
      "team": "Colonels",
      "score": "11",
      "oppScore": "12",
      "opp": "STL Lounar",
      "oppId": "oDdMOQb83b3Np2dDk%2b2JcTekyRlMRY%2b2lYbnQeDn3Pk%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "tYXL9%2fPzEmjswbp5Y%2bgcnU9LLAL1yaMSG33wy9DrsTs%3d",
      "team": "Colonels",
      "score": "15",
      "oppScore": "11",
      "opp": "Mallard",
      "oppId": "iIrscp1ZOpIpCSkyCbUNt0Vq3FobvpA18ZWbPM63hJo%3d"
    },
    {
      "date": "August 06",
      "tournament": "Louisville Slugfest",
      "tournamentId": "Louisville-Slugfest",
      "gameId": "gdUr8tqF4MWx0%2bkfRt%2f2vMSpynHRcDV40KG7XHNmYxA%3d",
      "team": "Colonels",
      "score": "13",
      "oppScore": "5",
      "opp": "Flying Dutchmen",
      "oppId": "7amY9LIoyY8hOWkzj5Nqp1H8c7bkR6ySQBzeCHsiQFg%3d"
    },
    {
      "date": "August 06",
      "tournament": "Louisville Slugfest",
      "tournamentId": "Louisville-Slugfest",
      "gameId": "32%2b37GnjRQ7ws1AJ3S6I0tkSsysM68mz0lSgF2Wvi8I%3d",
      "team": "Colonels",
      "score": "13",
      "oppScore": "4",
      "opp": "Nashville Mudcats",
      "oppId": "m1V5goSU%2bO1BR4XRhH%2fyGq44j%2f4hNKcb%2bBApPx5jVzQ%3d"
    },
    {
      "date": "August 06",
      "tournament": "Louisville Slugfest",
      "tournamentId": "Louisville-Slugfest",
      "gameId": "stBiilu5iSTcxOm9VSuC3GBqNJMb7n2yGmey5FxIGGU%3d",
      "team": "Colonels",
      "score": "11",
      "oppScore": "6",
      "opp": "Flying Dutchmen",
      "oppId": "7amY9LIoyY8hOWkzj5Nqp1H8c7bkR6ySQBzeCHsiQFg%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "tu3Vhnpvodhqvl0MhCdeP2TU8%2fg%2f4fv8P6rD1wHZIE8%3d",
      "team": "Colonels",
      "score": "15",
      "oppScore": "13",
      "opp": "Phoenix",
      "oppId": "gSiJoSXGsIFqzcdqVTCYpgvQv7XMC%2b0qfhx6EDDLgrQ%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "DhsrlUBo6%2bAPkV9g1JGFCjrh0WrdMz73J6%2bETlg2lE8%3d",
      "team": "Colonels",
      "score": "13",
      "oppScore": "12",
      "opp": "Tanasi",
      "oppId": "7MICo92AgV%2flT4d8dpq%2bhDNyJFhVtTVq8%2f94L7J8idw%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "vwdK8K2RjiXrp8xh6rY67kWLauqMT4r529imgIQS0wk%3d",
      "team": "Colonels",
      "score": "15",
      "oppScore": "10",
      "opp": "Inception",
      "oppId": "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "KpLT0bPIYwb75u2gpG1Dq9wWKTpGjEf8Fj54aCvwSVM%3d",
      "team": "Colonels",
      "score": "11",
      "oppScore": "13",
      "opp": "Omen",
      "oppId": "LvpOSyQd02uHlDLxvVMuw0fzNjwTUO8G7CpXAwQn40I%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "2sRFG%2bBrw0DO6oYYpaItPG5SJHieWfPmhZP5P%2bhRQjM%3d",
      "team": "Colonels",
      "score": "7",
      "oppScore": "15",
      "opp": "Mad Men",
      "oppId": "Al%2fA4sj9NEIvG2pfoVeKR8OCQL%2fREoXEJcD3sZnI49A%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "f2%2fqEDuzwTXbgUzL8vYLtKi5e8o2QhvfQsgwVolftiE%3d",
      "team": "Colonels",
      "score": "7",
      "oppScore": "14",
      "opp": "Garden State Ultimate",
      "oppId": "rv5RwYIoNIY0EqGzDkizdJwIJ%2bLTiDa65wF3GyKDUjo%3d"
    }
  ],
  "HJQp9EgoBkW9LmipJ4BOA7JQ%2bJ%2fsSTavaeYtiM6KQus%3d": [
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "n%2bM%2f6V1MP21TLfkyplV96yfhRnjiU4A2v%2boWRZqQ80c%3d",
      "team": "Trident I",
      "score": "15",
      "oppScore": "3",
      "opp": "HouSE",
      "oppId": "W3JpjYTNBVirvq%2bLhhgD1xswtN%2bDHXo2Y5uhwfm%2f450%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "RVjpg8LuwIpCAlcSlejSpW1qFLvgrQDidPwTz4ma6UQ%3d",
      "team": "Trident I",
      "score": "15",
      "oppScore": "10",
      "opp": "Beacon",
      "oppId": "4n5jY6eFSRuptn3xrctxCFjAy7g9dqmJM2uPNhY3qbk%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "6uhIHOh%2bYEBIXK3iodojhB2xlbf3MltXUKAl4Wnd2pI%3d",
      "team": "Trident I",
      "score": "15",
      "oppScore": "8",
      "opp": "STL Lounar",
      "oppId": "oDdMOQb83b3Np2dDk%2b2JcTekyRlMRY%2b2lYbnQeDn3Pk%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "w7SM1IGbWHQJYME%2fOp8H2vCdqUpm6u5ThWSpgwCSLlE%3d",
      "team": "Trident I",
      "score": "15",
      "oppScore": "4",
      "opp": "MKE",
      "oppId": "qHyFAYrlLVWh07Z9rgHqv1nh7SsCt5k4p8Ri0Bna7Zc%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "4N3ICNE7yzuo4PK6zFVsIRWw1ro7pV9qJg3YrflWvdM%3d",
      "team": "Trident I",
      "score": "13",
      "oppScore": "8",
      "opp": "Mallard",
      "oppId": "iIrscp1ZOpIpCSkyCbUNt0Vq3FobvpA18ZWbPM63hJo%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "Pl3wlYRMNzYNKyJNRljECYP9r5sUnBb7oluc5QNQlAE%3d",
      "team": "Trident I",
      "score": "15",
      "oppScore": "8",
      "opp": "STL Lounar",
      "oppId": "oDdMOQb83b3Np2dDk%2b2JcTekyRlMRY%2b2lYbnQeDn3Pk%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "3bd7aI4Wry8QjyHcnT4hywrdY9OKFreZY%2bVfIdR2QbE%3d",
      "team": "Trident I",
      "score": "14",
      "oppScore": "7",
      "opp": "Mallard",
      "oppId": "iIrscp1ZOpIpCSkyCbUNt0Vq3FobvpA18ZWbPM63hJo%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "pbZS8k6Iz8wofbXQj9KPNC4Q45881cIIVpnl10eItu8%3d",
      "team": "Trident I",
      "score": "13",
      "oppScore": "9",
      "opp": "Phantom",
      "oppId": "Hc0As%2bNg1YaD%2fyL3LwaIEtSMTc23KxHOibI0ZwQLDa4%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "1m5ZQyC4YFk1%2fRbxgvGO73kvbEoCxyBEKkxmhWVzQbg%3d",
      "team": "Trident I",
      "score": "8",
      "oppScore": "11",
      "opp": "Tanasi",
      "oppId": "7MICo92AgV%2flT4d8dpq%2bhDNyJFhVtTVq8%2f94L7J8idw%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "FPkrRtMnY%2b2FjYr4j3DsMLEv5WO2zMo2dhBdhjzw8uA%3d",
      "team": "Trident I",
      "score": "10",
      "oppScore": "9",
      "opp": "Omen",
      "oppId": "LvpOSyQd02uHlDLxvVMuw0fzNjwTUO8G7CpXAwQn40I%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "MfVjRuHc6JPU9azU5Pq5kTa4bhOHZIt%2b%2fGmYzXBTAjQ%3d",
      "team": "Trident I",
      "score": "8",
      "oppScore": "13",
      "opp": "Vault",
      "oppId": "3xV6rIwyIem%2bql5tz%2bZmFG9gr%2bp6WKu5k4q5bwFcSp0%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "reHUm8VYOrWd3CNw9BwMQ1qt0FVTCavNYmkamGu8Av0%3d",
      "team": "Trident I",
      "score": "9",
      "oppScore": "10",
      "opp": "Tanasi",
      "oppId": "7MICo92AgV%2flT4d8dpq%2bhDNyJFhVtTVq8%2f94L7J8idw%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "7064rlIcBLNnwP6GsEMCdmfQ4sL25bSH1jgy3WmJcyA%3d",
      "team": "Trident I",
      "score": "15",
      "oppScore": "9",
      "opp": "I-69",
      "oppId": "a64fDYPntTHPU4vghTeNstb32Z0JFlq528bbATJx%2bbs%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "h0gLRHa2qPaVfq5ZaMEesvk1YnoPb%2beWDSMoauarQfo%3d",
      "team": "Trident I",
      "score": "12",
      "oppScore": "14",
      "opp": "Garden State Ultimate",
      "oppId": "rv5RwYIoNIY0EqGzDkizdJwIJ%2bLTiDa65wF3GyKDUjo%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "SuPEEd9mpo7MqhPzzyb0ZMMT01junB%2fFgOixECLyR00%3d",
      "team": "Trident I",
      "score": "10",
      "oppScore": "14",
      "opp": "Doublewide",
      "oppId": "Nm8EGFzg2L2gF7kgWvfP7%2bbHNWDz7Dq77ckjxxzanlg%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "y4UULVy96y%2fl8QgDKzVV5Nn4NFPEnXizhc1joa2SxtI%3d",
      "team": "Trident I",
      "score": "15",
      "oppScore": "7",
      "opp": "UpRoar",
      "oppId": "JYJdk5dDNlc6X6evPtQ6K9GWMwDy1xmzavYjS6uyHaE%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "MavHVqa0xTQxdMzkjPdC2eyvkz%2bw3znZZUeLsrr%2bcp0%3d",
      "team": "Trident I",
      "score": "11",
      "oppScore": "8",
      "opp": "Shade",
      "oppId": "Lh0BmORltuq%2bkEq6e8QTGFiUkZuG5GuP4DNNS5vaaAc%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "B8hH80IFLfz6%2f9bfBx2r0mdWrEyymNM9gKy33m4MBKA%3d",
      "team": "Trident I",
      "score": "11",
      "oppScore": "9",
      "opp": "Clutch",
      "oppId": "JDc%2bBsCjGUX7gdebRqmdPvq1u2rFlZ1giM6BgTaL5Lk%3d"
    }
  ],
  "oDdMOQb83b3Np2dDk%2b2JcTekyRlMRY%2b2lYbnQeDn3Pk%3d": [
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "F%2fL3VLA2%2bykOYzzyJW%2bLCaqtkD4dtBzpciu6glPn6bo%3d",
      "team": "STL Lounar",
      "score": "15",
      "oppScore": "9",
      "opp": "Beacon",
      "oppId": "4n5jY6eFSRuptn3xrctxCFjAy7g9dqmJM2uPNhY3qbk%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "NBpo5oBa3SLUleQOeXJZeUl9G3hoGIh1%2b8zGInjr0aI%3d",
      "team": "STL Lounar",
      "score": "15",
      "oppScore": "9",
      "opp": "HouSE",
      "oppId": "W3JpjYTNBVirvq%2bLhhgD1xswtN%2bDHXo2Y5uhwfm%2f450%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "6uhIHOh%2bYEBIXK3iodojhB2xlbf3MltXUKAl4Wnd2pI%3d",
      "team": "STL Lounar",
      "score": "8",
      "oppScore": "15",
      "opp": "Trident I",
      "oppId": "HJQp9EgoBkW9LmipJ4BOA7JQ%2bJ%2fsSTavaeYtiM6KQus%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "mDw%2fpl%2fSm%2bxTwxchfNu1%2bQA92H3IuPNCP2hW%2fmeUBaY%3d",
      "team": "STL Lounar",
      "score": "15",
      "oppScore": "12",
      "opp": "Knights of Ni",
      "oppId": "dgH60oTrddgw%2bSl2xkIe%2fis%2fu1HY9ndk1MZTdN4OiAs%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "8uC0CxwgeUyuvvpi0BznEXSgT%2fDQl4SIrbUlDhFJ5pc%3d",
      "team": "STL Lounar",
      "score": "12",
      "oppScore": "11",
      "opp": "Colonels",
      "oppId": "7Xv2AHNIg3Y%2fm1y7qWkHUz%2b9KT3NZFOCMpW7gBaxnfs%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "Pl3wlYRMNzYNKyJNRljECYP9r5sUnBb7oluc5QNQlAE%3d",
      "team": "STL Lounar",
      "score": "8",
      "oppScore": "15",
      "opp": "Trident I",
      "oppId": "HJQp9EgoBkW9LmipJ4BOA7JQ%2bJ%2fsSTavaeYtiM6KQus%3d"
    }
  ],
  "KEdthZtu%2ffyLTQQr6Y655G91Wz0j0VrQj7Ap2G5YBOM%3d": [
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "yM2BwM6tfVcygNIcaHkzxyRSXZ%2bNN0f3Y%2fsNoxsLdaI%3d",
      "team": "Timber",
      "score": "6",
      "oppScore": "15",
      "opp": "Mallard",
      "oppId": "iIrscp1ZOpIpCSkyCbUNt0Vq3FobvpA18ZWbPM63hJo%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "bKRPyIAr9Hy0m%2fL0jfq60xsC7IIZESwJ3Pp6FsRSbuI%3d",
      "team": "Timber",
      "score": "8",
      "oppScore": "15",
      "opp": "Haymaker",
      "oppId": "F7zZjPQTPbFe%2fxIVicHJD2zRIOORpO5hSxfBuY5fxcQ%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "0MqKXCKH2i%2fHYqdCs7E%2bTOe4yyCFv2BKHSmoBdmE1WM%3d",
      "team": "Timber",
      "score": "9",
      "oppScore": "15",
      "opp": "Trident II",
      "oppId": "b69A0xfMxQKwR01A7jfOqdv1wbChbTxRp5jko3aOCXk%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "%2bVCxmNXwXZJyQe3sLDG79tRZoAc26tnREQh2Z77UHHI%3d",
      "team": "Timber",
      "score": "7",
      "oppScore": "15",
      "opp": "I-69",
      "oppId": "a64fDYPntTHPU4vghTeNstb32Z0JFlq528bbATJx%2bbs%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "B8dvmTBOdGCL%2bl9a1zuJun0RQvLyXKmXegZOfAb%2beWI%3d",
      "team": "Timber",
      "score": "12",
      "oppScore": "15",
      "opp": "HouSE",
      "oppId": "W3JpjYTNBVirvq%2bLhhgD1xswtN%2bDHXo2Y5uhwfm%2f450%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "NImzspJw5i1mSW2LaJ%2fDHVk82aRGjPaLy6UihvgKGOE%3d",
      "team": "Timber",
      "score": "W",
      "oppScore": "F",
      "opp": "Mad Men B [B]",
      "oppId": "hMj3CHJLwG8WqQXZcH4AKvR5bELtlJK6A4UDsh8UYEk%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "I0H2S4c1PBFV1rBo%2bht5QPDMTRYKrGUSTmwQ3ZWi5tI%3d",
      "team": "Timber",
      "score": "13",
      "oppScore": "12",
      "opp": "Chimney",
      "oppId": "jzVhyLybRfcABTsshmdvgsxYDSASMTHMGNde%2bkQTb4Y%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "wPA%2fq7GnBUsUd7930rdB4kqsgUhY0509nTRmW1X6Jhs%3d",
      "team": "Timber",
      "score": "8",
      "oppScore": "13",
      "opp": "Nain Rouge",
      "oppId": "VlCPZLsfBQ0DobBH%2fc22bLMbT2Ge3MbFrq%2fRZ8Jw0gM%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "o005qo5QFiHp5mgz%2fstxm5ezNQ5oNEL%2bBLjT5BxcLRM%3d",
      "team": "Timber",
      "score": "13",
      "oppScore": "10",
      "opp": "HouSE",
      "oppId": "W3JpjYTNBVirvq%2bLhhgD1xswtN%2bDHXo2Y5uhwfm%2f450%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "%2bmXR4n27t2Wi8URNcSFEFCiD9g30DrcxZc7XhzK%2bSaY%3d",
      "team": "Timber",
      "score": "13",
      "oppScore": "6",
      "opp": "THE BODY",
      "oppId": "nyLl78Eg24EtJDKG0oHsU9e%2fg0hrOYqklm7h8m4R0Jo%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "L4OkRtj1wpTX4V6kesfXtbjDjzJEzvo102%2b2iFzALGU%3d",
      "team": "Timber",
      "score": "7",
      "oppScore": "13",
      "opp": "MKE",
      "oppId": "qHyFAYrlLVWh07Z9rgHqv1nh7SsCt5k4p8Ri0Bna7Zc%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "ilTWWn3hftQrwAy%2bG9XTRdG1uHa6L3EcDVucwRkZmY0%3d",
      "team": "Timber",
      "score": "9",
      "oppScore": "13",
      "opp": "Chimney",
      "oppId": "jzVhyLybRfcABTsshmdvgsxYDSASMTHMGNde%2bkQTb4Y%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "40%2fG0O5CqsYwd9bfzdX31OX98Ymt%2bGp%2bCGfaFGZfys8%3d",
      "team": "Timber",
      "score": "13",
      "oppScore": "10",
      "opp": "Rubicon Rapids",
      "oppId": "%2bIdRZdPfxa07Yaa3PzfmJw5so76rO6A7JVJAQXOY%2b40%3d"
    }
  ],
  "hMj3CHJLwG8WqQXZcH4AKvR5bELtlJK6A4UDsh8UYEk%3d": [
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "isteCXffI27%2ftTpwkWQ9Ix36cw%2b%2fREd25eua%2fFkPaFk%3d",
      "team": "Mad Men B-B",
      "score": "12",
      "oppScore": "14",
      "opp": "MKE",
      "oppId": "qHyFAYrlLVWh07Z9rgHqv1nh7SsCt5k4p8Ri0Bna7Zc%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "spxsf%2fC5BVe4tRtvjn%2fxHoM%2foNIcaVvK9YEwZiQUiV0%3d",
      "team": "Mad Men B-B",
      "score": "4",
      "oppScore": "15",
      "opp": "Knights of Ni",
      "oppId": "dgH60oTrddgw%2bSl2xkIe%2fis%2fu1HY9ndk1MZTdN4OiAs%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "o7tzd3AbCVc9qF3W0EV8qEL4h07jwNNyMmtIzYJPxlQ%3d",
      "team": "Mad Men B-B",
      "score": "3",
      "oppScore": "15",
      "opp": "Flying Dutchmen",
      "oppId": "7amY9LIoyY8hOWkzj5Nqp1H8c7bkR6ySQBzeCHsiQFg%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "VMrhn23DZ38pNSKJ0ZTqCi7jSSts8oqlma1s5Ggu2rw%3d",
      "team": "Mad Men B-B",
      "score": "3",
      "oppScore": "15",
      "opp": "Beacon",
      "oppId": "4n5jY6eFSRuptn3xrctxCFjAy7g9dqmJM2uPNhY3qbk%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "1G%2bPm1xKLru9Olp93YyoB96fKJAnS9gJ9SkpSWacs5Y%3d",
      "team": "Mad Men B-B",
      "score": "6",
      "oppScore": "15",
      "opp": "Red Hots - u20",
      "oppId": "Ggh5FAV%2b2R4byKaxZbHQGXO72Bh%2fypyZmUX%2fWkOdMsg%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "NImzspJw5i1mSW2LaJ%2fDHVk82aRGjPaLy6UihvgKGOE%3d",
      "team": "Mad Men B-B",
      "score": "F",
      "oppScore": "W",
      "opp": "Timber",
      "oppId": "KEdthZtu%2ffyLTQQr6Y655G91Wz0j0VrQj7Ap2G5YBOM%3d"
    }
  ],
  "qHyFAYrlLVWh07Z9rgHqv1nh7SsCt5k4p8Ri0Bna7Zc%3d": [
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "isteCXffI27%2ftTpwkWQ9Ix36cw%2b%2fREd25eua%2fFkPaFk%3d",
      "team": "MKE",
      "score": "14",
      "oppScore": "12",
      "opp": "Mad Men B [B]",
      "oppId": "hMj3CHJLwG8WqQXZcH4AKvR5bELtlJK6A4UDsh8UYEk%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "y7bwzH0c6DNSW%2b%2byEYtkq3swZUlKufvt9QtTLs3YQAM%3d",
      "team": "MKE",
      "score": "12",
      "oppScore": "11",
      "opp": "Flying Dutchmen",
      "oppId": "7amY9LIoyY8hOWkzj5Nqp1H8c7bkR6ySQBzeCHsiQFg%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "b2dGsZB%2bDesfYeOvmuExj4iVLYDX3CeEj%2f6C9zo3dRU%3d",
      "team": "MKE",
      "score": "5",
      "oppScore": "15",
      "opp": "Knights of Ni",
      "oppId": "dgH60oTrddgw%2bSl2xkIe%2fis%2fu1HY9ndk1MZTdN4OiAs%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "w7SM1IGbWHQJYME%2fOp8H2vCdqUpm6u5ThWSpgwCSLlE%3d",
      "team": "MKE",
      "score": "4",
      "oppScore": "15",
      "opp": "Trident I",
      "oppId": "HJQp9EgoBkW9LmipJ4BOA7JQ%2bJ%2fsSTavaeYtiM6KQus%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "D%2fuxglNNZYq6RqJlVMhj67mflTCMRqqRgN8er6GV%2fco%3d",
      "team": "MKE",
      "score": "11",
      "oppScore": "14",
      "opp": "Harvey Cats",
      "oppId": "cbjkcOMrhSyGKLqnK1uuJLy8ENbcEcYemex94WHxvxU%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "2IZc469JFIy5utOpN8QWjEXH%2fbqKnSz%2fvuH0sLz8gnw%3d",
      "team": "MKE",
      "score": "7",
      "oppScore": "13",
      "opp": "Haymaker",
      "oppId": "F7zZjPQTPbFe%2fxIVicHJD2zRIOORpO5hSxfBuY5fxcQ%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "yoTDw9XpQl6bIdFU%2bgve5YZxnpL%2bIIKWizQHUjL%2f9M4%3d",
      "team": "MKE",
      "score": "10",
      "oppScore": "15",
      "opp": "BAG",
      "oppId": "0tQmFBgNfrHGaAXxTYC%2f8oP1TSA1q7cMU6hhrYmt1Y0%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "l371w59muWXJmscGVHPVA7aUb4NidBUSb5vZoimHn3A%3d",
      "team": "MKE",
      "score": "8",
      "oppScore": "12",
      "opp": "Lantern",
      "oppId": "5z%2bIWsl3V2NLqJRxKMauFZK8Pmkffh9obp1ocWQtXnY%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "7JJRUopxQiRfF0%2fC%2fQdjyGoUPI7n%2bRYsCowSmcHd8kY%3d",
      "team": "MKE",
      "score": "10",
      "oppScore": "8",
      "opp": "Magma Bears",
      "oppId": "tOcZDMKk80VDM0VC5NJXyzOSixma%2fCVGplidpqww6q8%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "aEDTGyxAeRNFYJv39gW%2f5JkgOe3xropEvSVCGbAqk%2fA%3d",
      "team": "MKE",
      "score": "4",
      "oppScore": "15",
      "opp": "Big Wrench",
      "oppId": "cD5%2b6qwajeEeZXFAZqH%2f%2fSNl6zunDFFnMpdAAl7eKv0%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "Z21vFRfGIyzdb1mZ68X5OowKb1G419e4DWKzUx4Jtus%3d",
      "team": "MKE",
      "score": "8",
      "oppScore": "15",
      "opp": "Iditarod",
      "oppId": "7tBre8lNjeAgLNCS5dx%2fnhybe521PEeqaFyoOhXPzSk%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "%2fv5GC9ShzeOQ5sAOZlcVccA%2f2zq2FeoQ9aJP50nDDCA%3d",
      "team": "MKE",
      "score": "12",
      "oppScore": "11",
      "opp": "Magma Bears",
      "oppId": "tOcZDMKk80VDM0VC5NJXyzOSixma%2fCVGplidpqww6q8%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "ED85YQkp6QcR4zUlsQiWc%2bbuJwrgR9HZIoVWX%2bhz45M%3d",
      "team": "MKE",
      "score": "13",
      "oppScore": "5",
      "opp": "Rubicon Rapids",
      "oppId": "%2bIdRZdPfxa07Yaa3PzfmJw5so76rO6A7JVJAQXOY%2b40%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "gqYoy%2bKwrpkSH7kGngM%2f5q4zM2GgRLhrGxpBgV128PM%3d",
      "team": "MKE",
      "score": "13",
      "oppScore": "10",
      "opp": "Enigma",
      "oppId": "vGMVLiic34oSyMk%2bETpVqMkuexU7MBkROOjGtFdKoVM%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "QirYaEcv9o0zyl7MFqWXazfuo8q%2bg72%2fMjROjWwSNEs%3d",
      "team": "MKE",
      "score": "10",
      "oppScore": "13",
      "opp": "Cryptic",
      "oppId": "Qhc%2fzwjxDlH6UTqMQqHKE2ms56DaeAMWMHQJCBwAjIw%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "TBdUYuCG64ggsPeBUBhDAOomQOjiwBXEtvkSPjEdEzw%3d",
      "team": "MKE",
      "score": "11",
      "oppScore": "13",
      "opp": "Mallard II",
      "oppId": "waPLoije6zVsjVUcOXTlGSFZ10PPabaoS6kk%2bnW2NCo%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "L4OkRtj1wpTX4V6kesfXtbjDjzJEzvo102%2b2iFzALGU%3d",
      "team": "MKE",
      "score": "13",
      "oppScore": "7",
      "opp": "Timber",
      "oppId": "KEdthZtu%2ffyLTQQr6Y655G91Wz0j0VrQj7Ap2G5YBOM%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "p%2b6pN%2byVZ0IF2CrKlqPYTMnP3JjzFNNBiPYylfVqYjA%3d",
      "team": "MKE",
      "score": "13",
      "oppScore": "9",
      "opp": "Beacon",
      "oppId": "4n5jY6eFSRuptn3xrctxCFjAy7g9dqmJM2uPNhY3qbk%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "8PKzuJ9tLlDPQ8ImY4h5moqWpvi%2bBjfsvAZ%2fr4o77S8%3d",
      "team": "MKE",
      "score": "13",
      "oppScore": "7",
      "opp": "Nomads",
      "oppId": "clyQEvW53ITGsU%2bGFSkoVd77O856fO%2f%2fPStXV2bXfy4%3d"
    }
  ],
  "Ggh5FAV%2b2R4byKaxZbHQGXO72Bh%2fypyZmUX%2fWkOdMsg%3d": [
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "Ld1qR53qKAgC1MuHh%2bTNJoqA2T11uCD%2fCrvuL9ulDW4%3d",
      "team": "Red Hots - u20",
      "score": "6",
      "oppScore": "15",
      "opp": "Harvey Cats",
      "oppId": "cbjkcOMrhSyGKLqnK1uuJLy8ENbcEcYemex94WHxvxU%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "oMMfWXIh4%2bTAzBmqV4fErvg1%2bMHvy2dd%2f1ZpdFxWDP4%3d",
      "team": "Red Hots - u20",
      "score": "4",
      "oppScore": "15",
      "opp": "Colonels",
      "oppId": "7Xv2AHNIg3Y%2fm1y7qWkHUz%2b9KT3NZFOCMpW7gBaxnfs%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "VK9lehOnWDWUEr%2bCUunc0C9laLVQuAodPDKX2IAXoGI%3d",
      "team": "Red Hots - u20",
      "score": "6",
      "oppScore": "15",
      "opp": "I-69",
      "oppId": "a64fDYPntTHPU4vghTeNstb32Z0JFlq528bbATJx%2bbs%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "duTRIv9mB%2bR7VxN9hp1dZu5A47aG5EGElZGWO7qbcfU%3d",
      "team": "Red Hots - u20",
      "score": "10",
      "oppScore": "12",
      "opp": "Trident II",
      "oppId": "b69A0xfMxQKwR01A7jfOqdv1wbChbTxRp5jko3aOCXk%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "1G%2bPm1xKLru9Olp93YyoB96fKJAnS9gJ9SkpSWacs5Y%3d",
      "team": "Red Hots - u20",
      "score": "15",
      "oppScore": "6",
      "opp": "Mad Men B [B]",
      "oppId": "hMj3CHJLwG8WqQXZcH4AKvR5bELtlJK6A4UDsh8UYEk%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "P0R221n7CwEDTeFUbCb55Ugb9rxdBTSZWBwwEFIM2QY%3d",
      "team": "Red Hots - u20",
      "score": "10",
      "oppScore": "15",
      "opp": "HouSE",
      "oppId": "W3JpjYTNBVirvq%2bLhhgD1xswtN%2bDHXo2Y5uhwfm%2f450%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "uiZ1NzYed89pPkGwQh3j37FSUAawli4l70XE9FPyVZ8%3d",
      "team": "Red Hots - u20",
      "score": "11",
      "oppScore": "15",
      "opp": "Philadelphia SEPDA (Boys)",
      "oppId": "5O9ir9HXplxStTnTGuxWkmxt8TitCqJnl0x7u%2fVD5VU%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "6qmMiH3TuzPYjynAK%2fLSO%2bxwQ8QseG2jxvl4k3Dkb6I%3d",
      "team": "Red Hots - u20",
      "score": "12",
      "oppScore": "5",
      "opp": "Carolina Sky",
      "oppId": "hB68K7FHsR2eKlSa4C1RbbSGll5%2fMOSptFMsBcCQrbw%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "agmxHwNIF3R1P%2bnvdei0MmRDKqQQS8yMlvJ0ntbluD8%3d",
      "team": "Red Hots - u20",
      "score": "14",
      "oppScore": "15",
      "opp": "Texas United",
      "oppId": "g1sAWnCbOpRll1Sex%2b5gOMn6kWvnosk%2b%2buSEykPQk24%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "V7on7TqyHfcF9zW3IC6NyHuIwD7OQqujj9Em%2fUOGcF4%3d",
      "team": "Red Hots - u20",
      "score": "11",
      "oppScore": "14",
      "opp": "Rising Tide U20B",
      "oppId": "RC9DlxiW4CrMZejVF2qr%2bt2v5sLxXc8%2bf6fM4NCFUXU%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "eOOtjYf7OH%2fhg04MYv07iSzqWDSzoGEkRUkcIN6MNjc%3d",
      "team": "Red Hots - u20",
      "score": "15",
      "oppScore": "6",
      "opp": "Flying Pig",
      "oppId": "rUyyXUmvJe1zUxoCIq%2bJ8srRSS59bSwzNSdxsoWUbdY%3d"
    },
    {
      "date": "August 08",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "iHDMoNGYn0%2bdSdv28k1%2fiePNW8QGJgxR50rDiXWcRaE%3d",
      "team": "Red Hots - u20",
      "score": "15",
      "oppScore": "8",
      "opp": "Connecticut Harpoon",
      "oppId": "YIzHfo1sGWgfgEtOVFTJQgQkK1t%2be88CqVk%2beFoGL7A%3d"
    },
    {
      "date": "August 08",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "2l3EOFKCkzYFhtlxIwbVZ82zbxkrafXJBmB%2bc8NyCIU%3d",
      "team": "Red Hots - u20",
      "score": "11",
      "oppScore": "12",
      "opp": "Villains",
      "oppId": "Ym1ak6XomGej8LcWE974vySOBZfTF88e4wX%2bD3N6JX0%3d"
    }
  ],
  "b69A0xfMxQKwR01A7jfOqdv1wbChbTxRp5jko3aOCXk%3d": [
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "46vVZDVat4bQ7pM%2feFVSJfcChcX%2fK7y6YU8e%2bMYrkK4%3d",
      "team": "Trident II",
      "score": "6",
      "oppScore": "15",
      "opp": "Haymaker",
      "oppId": "F7zZjPQTPbFe%2fxIVicHJD2zRIOORpO5hSxfBuY5fxcQ%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "wOk1O5k0lFcRaTYoR%2b3UcZxB5XCRosPFaH6%2btjE6CKE%3d",
      "team": "Trident II",
      "score": "1",
      "oppScore": "15",
      "opp": "Mallard",
      "oppId": "iIrscp1ZOpIpCSkyCbUNt0Vq3FobvpA18ZWbPM63hJo%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "0MqKXCKH2i%2fHYqdCs7E%2bTOe4yyCFv2BKHSmoBdmE1WM%3d",
      "team": "Trident II",
      "score": "15",
      "oppScore": "9",
      "opp": "Timber",
      "oppId": "KEdthZtu%2ffyLTQQr6Y655G91Wz0j0VrQj7Ap2G5YBOM%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "duTRIv9mB%2bR7VxN9hp1dZu5A47aG5EGElZGWO7qbcfU%3d",
      "team": "Trident II",
      "score": "12",
      "oppScore": "10",
      "opp": "Red Hots - u20",
      "oppId": "Ggh5FAV%2b2R4byKaxZbHQGXO72Bh%2fypyZmUX%2fWkOdMsg%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "Fq7IoBNHreW%2bVqe%2bataTj7XWHPvL9EtBbPxTyR3j0Q8%3d",
      "team": "Trident II",
      "score": "7",
      "oppScore": "15",
      "opp": "Beacon",
      "oppId": "4n5jY6eFSRuptn3xrctxCFjAy7g9dqmJM2uPNhY3qbk%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "mG0IpYNZuW1vdg%2fk4oY9IC6J4FPf4UP%2fZpRwC9Ua5IU%3d",
      "team": "Trident II",
      "score": "6",
      "oppScore": "15",
      "opp": "Flying Dutchmen",
      "oppId": "7amY9LIoyY8hOWkzj5Nqp1H8c7bkR6ySQBzeCHsiQFg%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "XVHfJiGFtu0UAFoZ0l6rRTw3TGZV4A6x%2b3m6dKmKRnM%3d",
      "team": "Trident II",
      "score": "13",
      "oppScore": "8",
      "opp": "THE BODY",
      "oppId": "nyLl78Eg24EtJDKG0oHsU9e%2fg0hrOYqklm7h8m4R0Jo%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "3LKlY5OEyZoY%2fRLj7Ypvtnfbrm8QNQh0XNJcG6N9C%2bI%3d",
      "team": "Trident II",
      "score": "11",
      "oppScore": "13",
      "opp": "Overdrive",
      "oppId": "cfApDIqN4eoe%2fr26h%2b8fQj3s9XKGa9nJC8lT5uya7gM%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "tk9qvEAb%2f8%2b5DZo4kFNCrm%2fdc0%2fYWkkLoLIoL%2bxkPV0%3d",
      "team": "Trident II",
      "score": "2",
      "oppScore": "13",
      "opp": "Kansas City Smokestack",
      "oppId": "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "utOtkbjMLP2GlAaOdMjTKEcQ%2bI5uIk5kMk3ddDECcFI%3d",
      "team": "Trident II",
      "score": "6",
      "oppScore": "13",
      "opp": "NOMAD",
      "oppId": "RFpmXY6FT7eRry4gGUAnkSywT92Q24c%2fMw5CrinbhpI%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "Has4L%2bvJTvsitizH6QRyFlgYdgQ8VODiYWSz4ch4I%2fk%3d",
      "team": "Trident II",
      "score": "12",
      "oppScore": "10",
      "opp": "DINGWOP",
      "oppId": "xZAGkPMHJ9VCCvgCVVn5WRxQtwy%2bmZ7Q%2bGsb4rLqQqE%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "ZLWQtUEKQWhTnQALtIMy5cdq%2bE9yowD%2fLYKK7KM0nRI%3d",
      "team": "Trident II",
      "score": "7",
      "oppScore": "9",
      "opp": "THE BODY",
      "oppId": "nyLl78Eg24EtJDKG0oHsU9e%2fg0hrOYqklm7h8m4R0Jo%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "rdBPWsHns90Hj91I3biUyT5TYOuPlR8Q97Pb9HMfsto%3d",
      "team": "Trident II",
      "score": "5",
      "oppScore": "13",
      "opp": "Mallard II",
      "oppId": "waPLoije6zVsjVUcOXTlGSFZ10PPabaoS6kk%2bnW2NCo%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "2qaETBa9658iZJc9v%2bFuWhs%2bVlL1RxJp07jRlal650A%3d",
      "team": "Trident II",
      "score": "1",
      "oppScore": "13",
      "opp": "Beacon",
      "oppId": "4n5jY6eFSRuptn3xrctxCFjAy7g9dqmJM2uPNhY3qbk%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "yaLNTT1XV9Zf9c6k6GlubEYGjldVXgzpYKdaovHRe0w%3d",
      "team": "Trident II",
      "score": "13",
      "oppScore": "10",
      "opp": "Overdrive",
      "oppId": "cfApDIqN4eoe%2fr26h%2b8fQj3s9XKGa9nJC8lT5uya7gM%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "g7wJ4ftaMWEyrywSpeROzoB9lpDCvbaqTpXaiQbrhHQ%3d",
      "team": "Trident II",
      "score": "10",
      "oppScore": "11",
      "opp": "Rubicon Rapids",
      "oppId": "%2bIdRZdPfxa07Yaa3PzfmJw5so76rO6A7JVJAQXOY%2b40%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "OjNlhr1Qgd8RQuZ00GnTABNKybVF8olMjfj2QyCORb4%3d",
      "team": "Trident II",
      "score": "13",
      "oppScore": "10",
      "opp": "Loaded Panda",
      "oppId": "8S%2f2XcUwQbLf0KM7yOmv3HEYXSrnLhAdwnHQvhMjxCY%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "%2bjKwxLW5xtWExVSDs0kCbhhFY0GlF3Mjt%2bZoUO0rvAI%3d",
      "team": "Trident II",
      "score": "13",
      "oppScore": "2",
      "opp": "THE BODY",
      "oppId": "nyLl78Eg24EtJDKG0oHsU9e%2fg0hrOYqklm7h8m4R0Jo%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "2ze3YaApDsSqNTz6AbHpL4%2bHOx1iIbnpKwirB0WbosI%3d",
      "team": "Trident II",
      "score": "7",
      "oppScore": "11",
      "opp": "Overdrive",
      "oppId": "cfApDIqN4eoe%2fr26h%2b8fQj3s9XKGa9nJC8lT5uya7gM%3d"
    }
  ],
  "W3JpjYTNBVirvq%2bLhhgD1xswtN%2bDHXo2Y5uhwfm%2f450%3d": [
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "n%2bM%2f6V1MP21TLfkyplV96yfhRnjiU4A2v%2boWRZqQ80c%3d",
      "team": "HouSE",
      "score": "3",
      "oppScore": "15",
      "opp": "Trident I",
      "oppId": "HJQp9EgoBkW9LmipJ4BOA7JQ%2bJ%2fsSTavaeYtiM6KQus%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "NBpo5oBa3SLUleQOeXJZeUl9G3hoGIh1%2b8zGInjr0aI%3d",
      "team": "HouSE",
      "score": "9",
      "oppScore": "15",
      "opp": "STL Lounar",
      "oppId": "oDdMOQb83b3Np2dDk%2b2JcTekyRlMRY%2b2lYbnQeDn3Pk%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "rWZV2N2Ah7coWBe5ZNBCCTDknBc9eCbADkMP8Kx2KJg%3d",
      "team": "HouSE",
      "score": "8",
      "oppScore": "14",
      "opp": "Beacon",
      "oppId": "4n5jY6eFSRuptn3xrctxCFjAy7g9dqmJM2uPNhY3qbk%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "nhUP5g1d%2f%2bHvwnG73UzrfesNVX0IaEsbHlLlSILkFUU%3d",
      "team": "HouSE",
      "score": "10",
      "oppScore": "15",
      "opp": "Flying Dutchmen",
      "oppId": "7amY9LIoyY8hOWkzj5Nqp1H8c7bkR6ySQBzeCHsiQFg%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "B8dvmTBOdGCL%2bl9a1zuJun0RQvLyXKmXegZOfAb%2beWI%3d",
      "team": "HouSE",
      "score": "15",
      "oppScore": "12",
      "opp": "Timber",
      "oppId": "KEdthZtu%2ffyLTQQr6Y655G91Wz0j0VrQj7Ap2G5YBOM%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "P0R221n7CwEDTeFUbCb55Ugb9rxdBTSZWBwwEFIM2QY%3d",
      "team": "HouSE",
      "score": "15",
      "oppScore": "10",
      "opp": "Red Hots - u20",
      "oppId": "Ggh5FAV%2b2R4byKaxZbHQGXO72Bh%2fypyZmUX%2fWkOdMsg%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "fNwZsXXmS5DAX5QyRfhYpdhUXr%2fjDC4Q6HY3O3sk%2b9o%3d",
      "team": "HouSE",
      "score": "13",
      "oppScore": "9",
      "opp": "Nain Rouge",
      "oppId": "VlCPZLsfBQ0DobBH%2fc22bLMbT2Ge3MbFrq%2fRZ8Jw0gM%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "DAZL5TmBsYmWKg1r3Kn6tsh2B7ppaR8yblu7473lB50%3d",
      "team": "HouSE",
      "score": "13",
      "oppScore": "9",
      "opp": "Chimney",
      "oppId": "jzVhyLybRfcABTsshmdvgsxYDSASMTHMGNde%2bkQTb4Y%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "o005qo5QFiHp5mgz%2fstxm5ezNQ5oNEL%2bBLjT5BxcLRM%3d",
      "team": "HouSE",
      "score": "10",
      "oppScore": "13",
      "opp": "Timber",
      "oppId": "KEdthZtu%2ffyLTQQr6Y655G91Wz0j0VrQj7Ap2G5YBOM%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "GAcAnGLV78SycHz8svtFGGOX%2bCk2zcS%2fQXQmMbGr2Sw%3d",
      "team": "HouSE",
      "score": "13",
      "oppScore": "9",
      "opp": "Nomads",
      "oppId": "clyQEvW53ITGsU%2bGFSkoVd77O856fO%2f%2fPStXV2bXfy4%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "WMJeIIR%2bNqC0yj92%2fmKXUzCaZYR6wX%2fKUmCYnZ9ExMQ%3d",
      "team": "HouSE",
      "score": "10",
      "oppScore": "12",
      "opp": "Knights of Ni",
      "oppId": "dgH60oTrddgw%2bSl2xkIe%2fis%2fu1HY9ndk1MZTdN4OiAs%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "3JxIjfLXe5Iey95cl8M9R4bohzgq%2b3BlQpxM1e30a%2bw%3d",
      "team": "HouSE",
      "score": "6",
      "oppScore": "13",
      "opp": "DeMo",
      "oppId": "FdlFpUSGIahm89rZTXrHg2K85ugGqml2fjUWEPHtgPo%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "5t3FHidII4tVb2Y4JHdy50YnW2wwpHort1B3SgL%2b8J8%3d",
      "team": "HouSE",
      "score": "7",
      "oppScore": "13",
      "opp": "Kansas City Smokestack",
      "oppId": "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d"
    }
  ],
  "a64fDYPntTHPU4vghTeNstb32Z0JFlq528bbATJx%2bbs%3d": [
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "pnWWA4joe5kqC%2fLaxICfOKsr8XaP4hqOzzFH4jqzZaA%3d",
      "team": "I-69",
      "score": "4",
      "oppScore": "15",
      "opp": "Colonels",
      "oppId": "7Xv2AHNIg3Y%2fm1y7qWkHUz%2b9KT3NZFOCMpW7gBaxnfs%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "OGdVI9AqOVMSvGB%2bNS6zXxui8c3WBoADoZGuR%2fKW9fQ%3d",
      "team": "I-69",
      "score": "6",
      "oppScore": "15",
      "opp": "Harvey Cats",
      "oppId": "cbjkcOMrhSyGKLqnK1uuJLy8ENbcEcYemex94WHxvxU%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "VK9lehOnWDWUEr%2bCUunc0C9laLVQuAodPDKX2IAXoGI%3d",
      "team": "I-69",
      "score": "15",
      "oppScore": "6",
      "opp": "Red Hots - u20",
      "oppId": "Ggh5FAV%2b2R4byKaxZbHQGXO72Bh%2fypyZmUX%2fWkOdMsg%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "%2bVCxmNXwXZJyQe3sLDG79tRZoAc26tnREQh2Z77UHHI%3d",
      "team": "I-69",
      "score": "15",
      "oppScore": "7",
      "opp": "Timber",
      "oppId": "KEdthZtu%2ffyLTQQr6Y655G91Wz0j0VrQj7Ap2G5YBOM%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "3%2bzZszi1KbfsNQ6cR4P9vGeVvTVUbMFlnznZsTj1Vao%3d",
      "team": "I-69",
      "score": "15",
      "oppScore": "12",
      "opp": "Flying Dutchmen",
      "oppId": "7amY9LIoyY8hOWkzj5Nqp1H8c7bkR6ySQBzeCHsiQFg%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "zCMiN6Cc6mUZjB%2fQywg%2f9KpjtR7K9AanpQtRQBuEt2U%3d",
      "team": "I-69",
      "score": "9",
      "oppScore": "15",
      "opp": "Beacon",
      "oppId": "4n5jY6eFSRuptn3xrctxCFjAy7g9dqmJM2uPNhY3qbk%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "7064rlIcBLNnwP6GsEMCdmfQ4sL25bSH1jgy3WmJcyA%3d",
      "team": "I-69",
      "score": "9",
      "oppScore": "15",
      "opp": "Trident I",
      "oppId": "HJQp9EgoBkW9LmipJ4BOA7JQ%2bJ%2fsSTavaeYtiM6KQus%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "%2fkSoNekY4NU%2fHgtlg6eSL%2fBTRkeGVc7bt8YuSjfb0Fg%3d",
      "team": "I-69",
      "score": "7",
      "oppScore": "15",
      "opp": "Doublewide",
      "oppId": "Nm8EGFzg2L2gF7kgWvfP7%2bbHNWDz7Dq77ckjxxzanlg%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "%2fYdjIYdrXbj3tT%2bMcHTo7EAVBXfWCHP%2fCFu2HTzVgNE%3d",
      "team": "I-69",
      "score": "8",
      "oppScore": "14",
      "opp": "Garden State Ultimate",
      "oppId": "rv5RwYIoNIY0EqGzDkizdJwIJ%2bLTiDa65wF3GyKDUjo%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "IwYByf9lENVXFVLWAVvqJcd6mklM71pVdIMeXTiEktM%3d",
      "team": "I-69",
      "score": "10",
      "oppScore": "12",
      "opp": "Clutch",
      "oppId": "JDc%2bBsCjGUX7gdebRqmdPvq1u2rFlZ1giM6BgTaL5Lk%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "aW1wiAXD%2btXu7sG1CpzkzxpgnRy1dDoJXmZt6JEA3hk%3d",
      "team": "I-69",
      "score": "6",
      "oppScore": "14",
      "opp": "Mallard",
      "oppId": "iIrscp1ZOpIpCSkyCbUNt0Vq3FobvpA18ZWbPM63hJo%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "tIjgjYQpxfKvxgsW21LVEnxGpK%2bzsPT3%2frX9fXvVOtk%3d",
      "team": "I-69",
      "score": "15",
      "oppScore": "14",
      "opp": "Inception",
      "oppId": "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d"
    }
  ],
  "dgH60oTrddgw%2bSl2xkIe%2fis%2fu1HY9ndk1MZTdN4OiAs%3d": [
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "zkI5avyqu09rNhh8DlFu2B2UoNJD%2fZb5bjBtE3NCZV8%3d",
      "team": "Knights of Ni",
      "score": "15",
      "oppScore": "11",
      "opp": "Flying Dutchmen",
      "oppId": "7amY9LIoyY8hOWkzj5Nqp1H8c7bkR6ySQBzeCHsiQFg%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "spxsf%2fC5BVe4tRtvjn%2fxHoM%2foNIcaVvK9YEwZiQUiV0%3d",
      "team": "Knights of Ni",
      "score": "15",
      "oppScore": "4",
      "opp": "Mad Men B [B]",
      "oppId": "hMj3CHJLwG8WqQXZcH4AKvR5bELtlJK6A4UDsh8UYEk%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "b2dGsZB%2bDesfYeOvmuExj4iVLYDX3CeEj%2f6C9zo3dRU%3d",
      "team": "Knights of Ni",
      "score": "15",
      "oppScore": "5",
      "opp": "MKE",
      "oppId": "qHyFAYrlLVWh07Z9rgHqv1nh7SsCt5k4p8Ri0Bna7Zc%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "mDw%2fpl%2fSm%2bxTwxchfNu1%2bQA92H3IuPNCP2hW%2fmeUBaY%3d",
      "team": "Knights of Ni",
      "score": "12",
      "oppScore": "15",
      "opp": "STL Lounar",
      "oppId": "oDdMOQb83b3Np2dDk%2b2JcTekyRlMRY%2b2lYbnQeDn3Pk%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "oiyq4Y0%2foBXO7FEjz3CGIOB6%2f8cejqeV0%2b1ksPMFf3o%3d",
      "team": "Knights of Ni",
      "score": "14",
      "oppScore": "9",
      "opp": "Haymaker",
      "oppId": "F7zZjPQTPbFe%2fxIVicHJD2zRIOORpO5hSxfBuY5fxcQ%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "HPTXv5ZFrI%2b1fsuAMoDAETGq67rF4MkQcclsOPkCB2I%3d",
      "team": "Knights of Ni",
      "score": "13",
      "oppScore": "14",
      "opp": "Harvey Cats",
      "oppId": "cbjkcOMrhSyGKLqnK1uuJLy8ENbcEcYemex94WHxvxU%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "ykl9NSec02yU3aPxGKwi1jtdlQFVY0p8Nm0VMLZdJic%3d",
      "team": "Knights of Ni",
      "score": "13",
      "oppScore": "6",
      "opp": "NOMAD",
      "oppId": "RFpmXY6FT7eRry4gGUAnkSywT92Q24c%2fMw5CrinbhpI%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "ghOmqgZF8BzBUhJ1lt0CCiljJzpZJ19hZim7bKibl6g%3d",
      "team": "Knights of Ni",
      "score": "8",
      "oppScore": "13",
      "opp": "DeMo",
      "oppId": "FdlFpUSGIahm89rZTXrHg2K85ugGqml2fjUWEPHtgPo%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "zYiSYDriyabZTJhVTCDFgcNDCTcWYxYl0RLKecQETGg%3d",
      "team": "Knights of Ni",
      "score": "11",
      "oppScore": "10",
      "opp": "Cryptic",
      "oppId": "Qhc%2fzwjxDlH6UTqMQqHKE2ms56DaeAMWMHQJCBwAjIw%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "4cj%2fJB5LiDMDIhKliY30A5F55%2fIAPA%2fhtjJOgKR0m4I%3d",
      "team": "Knights of Ni",
      "score": "13",
      "oppScore": "8",
      "opp": "Mallard II",
      "oppId": "waPLoije6zVsjVUcOXTlGSFZ10PPabaoS6kk%2bnW2NCo%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "BPBXrvES9pB5Cqw7BuqGKzljsBh6SJBrKJe5frRNJSI%3d",
      "team": "Knights of Ni",
      "score": "9",
      "oppScore": "12",
      "opp": "Kansas City Smokestack",
      "oppId": "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "eUbOR9%2bkeQEgtzjtcVP7mFGmy%2fMB4Nd3gqM%2f1iAjRVU%3d",
      "team": "Knights of Ni",
      "score": "F",
      "oppScore": "W",
      "opp": "Scythe",
      "oppId": "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "Sz%2batzYFRMV4cFFtPXGvJU8yahShdhws7cEQMernWF8%3d",
      "team": "Knights of Ni",
      "score": "12",
      "oppScore": "13",
      "opp": "DeMo",
      "oppId": "FdlFpUSGIahm89rZTXrHg2K85ugGqml2fjUWEPHtgPo%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "L7YBBpvt5%2bTAdIbznCEkkgjKsUG%2b%2b5n5CD6pGCpvDEM%3d",
      "team": "Knights of Ni",
      "score": "9",
      "oppScore": "11",
      "opp": "Kansas City Smokestack",
      "oppId": "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "%2bv0nv6el1jedrZEdHVEmmySSfWgahaqGmVuyNAdNfBw%3d",
      "team": "Knights of Ni",
      "score": "13",
      "oppScore": "9",
      "opp": "Scythe",
      "oppId": "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "WMJeIIR%2bNqC0yj92%2fmKXUzCaZYR6wX%2fKUmCYnZ9ExMQ%3d",
      "team": "Knights of Ni",
      "score": "12",
      "oppScore": "10",
      "opp": "HouSE",
      "oppId": "W3JpjYTNBVirvq%2bLhhgD1xswtN%2bDHXo2Y5uhwfm%2f450%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "IDSQK2%2bWvZGRe5PpUhImUYBIVxQ7E1S%2fBQrQCy2uC8s%3d",
      "team": "Knights of Ni",
      "score": "12",
      "oppScore": "11",
      "opp": "Haymaker",
      "oppId": "F7zZjPQTPbFe%2fxIVicHJD2zRIOORpO5hSxfBuY5fxcQ%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "%2b97Za6MdgO60koTkFrCUG3Z51x9AyeS2gxGQWEtCes0%3d",
      "team": "Knights of Ni",
      "score": "8",
      "oppScore": "13",
      "opp": "Scythe",
      "oppId": "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d"
    }
  ],
  "iIrscp1ZOpIpCSkyCbUNt0Vq3FobvpA18ZWbPM63hJo%3d": [
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "yM2BwM6tfVcygNIcaHkzxyRSXZ%2bNN0f3Y%2fsNoxsLdaI%3d",
      "team": "Mallard",
      "score": "15",
      "oppScore": "6",
      "opp": "Timber",
      "oppId": "KEdthZtu%2ffyLTQQr6Y655G91Wz0j0VrQj7Ap2G5YBOM%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "wOk1O5k0lFcRaTYoR%2b3UcZxB5XCRosPFaH6%2btjE6CKE%3d",
      "team": "Mallard",
      "score": "15",
      "oppScore": "1",
      "opp": "Trident II",
      "oppId": "b69A0xfMxQKwR01A7jfOqdv1wbChbTxRp5jko3aOCXk%3d"
    },
    {
      "date": "July 09",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "eCcmK8GEDEpcYroxroAwTfvLJG9MsITnR1W%2bdru2nc4%3d",
      "team": "Mallard",
      "score": "15",
      "oppScore": "10",
      "opp": "Haymaker",
      "oppId": "F7zZjPQTPbFe%2fxIVicHJD2zRIOORpO5hSxfBuY5fxcQ%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "SKE4YsYHG7XNC7vl%2b%2bi%2fyuYw6ok4otg%2bgTqZg7iYwKU%3d",
      "team": "Mallard",
      "score": "15",
      "oppScore": "8",
      "opp": "Harvey Cats",
      "oppId": "cbjkcOMrhSyGKLqnK1uuJLy8ENbcEcYemex94WHxvxU%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "4N3ICNE7yzuo4PK6zFVsIRWw1ro7pV9qJg3YrflWvdM%3d",
      "team": "Mallard",
      "score": "8",
      "oppScore": "13",
      "opp": "Trident I",
      "oppId": "HJQp9EgoBkW9LmipJ4BOA7JQ%2bJ%2fsSTavaeYtiM6KQus%3d"
    },
    {
      "date": "July 10",
      "tournament": "Heavyweights 2022",
      "tournamentId": "Heavyweights-2022",
      "gameId": "tYXL9%2fPzEmjswbp5Y%2bgcnU9LLAL1yaMSG33wy9DrsTs%3d",
      "team": "Mallard",
      "score": "11",
      "oppScore": "15",
      "opp": "Colonels",
      "oppId": "7Xv2AHNIg3Y%2fm1y7qWkHUz%2b9KT3NZFOCMpW7gBaxnfs%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "ZQn%2bNqJMv3%2fSw%2b8oA0wxLSTYIACZrFxvbVw5rLhTXOc%3d",
      "team": "Mallard",
      "score": "7",
      "oppScore": "11",
      "opp": "Redwolves",
      "oppId": "DoL%2frShNUJHM7OLFhcsMzWlsS0aohpfm30HvDbpVZi4%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "3bd7aI4Wry8QjyHcnT4hywrdY9OKFreZY%2bVfIdR2QbE%3d",
      "team": "Mallard",
      "score": "7",
      "oppScore": "14",
      "opp": "Trident I",
      "oppId": "HJQp9EgoBkW9LmipJ4BOA7JQ%2bJ%2fsSTavaeYtiM6KQus%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "rByi0pjJp1QXi%2fsv4ElU64ocPtKSffnsVFlBGNNuuDE%3d",
      "team": "Mallard",
      "score": "4",
      "oppScore": "14",
      "opp": "Tanasi",
      "oppId": "7MICo92AgV%2flT4d8dpq%2bhDNyJFhVtTVq8%2f94L7J8idw%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "2NFu%2f5%2fRS8uDTkaz%2bbS1lZtBXbH4jG5PeuVkz9ULxJw%3d",
      "team": "Mallard",
      "score": "6",
      "oppScore": "12",
      "opp": "Phantom",
      "oppId": "Hc0As%2bNg1YaD%2fyL3LwaIEtSMTc23KxHOibI0ZwQLDa4%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "Gjf5lH7N8EPsrVVXEoLJ3F7LEWEvvnRhwQfUnjcevkY%3d",
      "team": "Mallard",
      "score": "7",
      "oppScore": "9",
      "opp": "Haymaker",
      "oppId": "F7zZjPQTPbFe%2fxIVicHJD2zRIOORpO5hSxfBuY5fxcQ%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "rGPKgniB8HbX4IXRFqkiFeDEllQ3NqEAeITgDmGwBqQ%3d",
      "team": "Mallard",
      "score": "8",
      "oppScore": "11",
      "opp": "Blueprint",
      "oppId": "BDhLSFxnViHodVQ9noIy0%2by0HWSmiJ3qgV5WI1ozsAk%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "PeelBdPpjn9Re%2bG2RWfM6tj%2b63%2fIXYeogmhWdNagfcE%3d",
      "team": "Mallard",
      "score": "9",
      "oppScore": "13",
      "opp": "Omen",
      "oppId": "LvpOSyQd02uHlDLxvVMuw0fzNjwTUO8G7CpXAwQn40I%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "6mYA3uO43e0y6PLeM093KllRAQYEGUYP9Nu9a%2fgCYQw%3d",
      "team": "Mallard",
      "score": "11",
      "oppScore": "14",
      "opp": "Vault",
      "oppId": "3xV6rIwyIem%2bql5tz%2bZmFG9gr%2bp6WKu5k4q5bwFcSp0%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "gCvPrqHLjhi6xZMORJkbNhDGxY15oQDrQFgf0p%2fpJI8%3d",
      "team": "Mallard",
      "score": "11",
      "oppScore": "9",
      "opp": "Shade",
      "oppId": "Lh0BmORltuq%2bkEq6e8QTGFiUkZuG5GuP4DNNS5vaaAc%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "FGQYzLDNSPp6GqEBAhhsPdDGpIzg%2fYZWyt3vbT0qzRU%3d",
      "team": "Mallard",
      "score": "11",
      "oppScore": "12",
      "opp": "Phoenix",
      "oppId": "gSiJoSXGsIFqzcdqVTCYpgvQv7XMC%2b0qfhx6EDDLgrQ%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "aW1wiAXD%2btXu7sG1CpzkzxpgnRy1dDoJXmZt6JEA3hk%3d",
      "team": "Mallard",
      "score": "14",
      "oppScore": "6",
      "opp": "I-69",
      "oppId": "a64fDYPntTHPU4vghTeNstb32Z0JFlq528bbATJx%2bbs%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "bIBdIvzz33ElqRZ3wqD0tJw%2bQY58aYvwNxNq715unOE%3d",
      "team": "Mallard",
      "score": "14",
      "oppScore": "9",
      "opp": "UpRoar",
      "oppId": "JYJdk5dDNlc6X6evPtQ6K9GWMwDy1xmzavYjS6uyHaE%3d"
    }
  ],
  "NpDxoDV0mpf0Hc%2fBzLLx0bhgfIsOKi%2b2AIQMCptTcck%3d": [
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "CCm2ltkjoV7Phs3tQsKMJUMCNQ9KcYV2HNOr0qHOBgA%3d",
      "team": "Yee-Haw",
      "score": "4",
      "oppScore": "13",
      "opp": "Lost Boys",
      "oppId": "aNMyR0uAJNwnidjeClZAMqixGjtlQqA6k7CiZP1R0%2bM%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "Ju7ZzMHFKQrGaFalZLb2pspsCME%2bF8CIUw7QEj8Rb34%3d",
      "team": "Yee-Haw",
      "score": "3",
      "oppScore": "8",
      "opp": "Dyno",
      "oppId": "fIw72i6bkbJnjTbK02aFLpJ9Qy2lCUVWlRBvI%2b8UmIc%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "1RXwBeLv%2f03YGMhf0TqFaw6de58XmmCLTf%2b7ChAPAh8%3d",
      "team": "Yee-Haw",
      "score": "4",
      "oppScore": "13",
      "opp": "UpRoar",
      "oppId": "JYJdk5dDNlc6X6evPtQ6K9GWMwDy1xmzavYjS6uyHaE%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "xPHxwzLNP53qibT0X1%2faF3vaxZYklkqqAJRMpAFLoo0%3d",
      "team": "Yee-Haw",
      "score": "8",
      "oppScore": "13",
      "opp": "Space Cowboys",
      "oppId": "3%2bZ1uFiVej3zLASHWkgqXkJ7hNQ98n84b2FvR4T3pQA%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "JZEI8St9FQftVmoSiLo3ftwWCNN%2bB2bQmbSfJ3Qqphg%3d",
      "team": "Yee-Haw",
      "score": "W",
      "oppScore": "F",
      "opp": "Tampa Tempest",
      "oppId": "hQXi92LAmE32qiZ%2fpZRYvkIBTehvd13BZlvBS7aR3%2fQ%3d"
    }
  ],
  "hQXi92LAmE32qiZ%2fpZRYvkIBTehvd13BZlvBS7aR3%2fQ%3d": [
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "uOAXvE3YxRpDFG5kPs2zSnPbNjkk3armqsWiA7GJyno%3d",
      "team": "Tampa Tempest",
      "score": "4",
      "oppScore": "13",
      "opp": "Second Nature",
      "oppId": "9QN%2fOffYfC%2f0mj9CpLqFRthKvgHjrHj%2bM2%2fY44PMiCE%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "bXwAAiw73adrndYnoD9hIocduYwy4wMMQRt%2bReXdxwY%3d",
      "team": "Tampa Tempest",
      "score": "1",
      "oppScore": "13",
      "opp": "Delirium",
      "oppId": "0yudJHn8N3NbInYx1wqMxoticHnPBt4YkAfZZAUc7U8%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "dWCEr7N1wungpwuTeNJrgDyIFx7RcnOF%2bCuman85FiY%3d",
      "team": "Tampa Tempest",
      "score": "1",
      "oppScore": "13",
      "opp": "Rush Hour ATL",
      "oppId": "LdH2zJ494O%2bxdz6p92IsLTMaAmjgf6aP%2fBvM9Lr1RaQ%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "Jgml5TPOp3kYAAZabPoFe4llpVNsUwCy5%2b52Y6ThIL4%3d",
      "team": "Tampa Tempest",
      "score": "3",
      "oppScore": "13",
      "opp": "Alliance",
      "oppId": "%2f7JeYXylBxa9Xw5f5LSwua5DyAomcfJ7a7MZDP7nSUs%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "JZEI8St9FQftVmoSiLo3ftwWCNN%2bB2bQmbSfJ3Qqphg%3d",
      "team": "Tampa Tempest",
      "score": "F",
      "oppScore": "W",
      "opp": "Yee-Haw",
      "oppId": "NpDxoDV0mpf0Hc%2fBzLLx0bhgfIsOKi%2b2AIQMCptTcck%3d"
    },
    {
      "date": "August 06",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "Mmg%2fDswOktC%2fQQp5eYoVs%2bUBTnoCjyJD7Ox4lPr6rtM%3d",
      "team": "Tampa Tempest",
      "score": "7",
      "oppScore": "15",
      "opp": "Vicious Cycle",
      "oppId": "iThg94F9Gjn72NSYiusu2Lt7M1KILkpxIeQ2vLs8yes%3d"
    },
    {
      "date": "August 06",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "R2qXD5fo54Ppe3Mrr1q1ttV%2fz5Xaw9p%2fXmnjaZSWNyA%3d",
      "team": "Tampa Tempest",
      "score": "11",
      "oppScore": "6",
      "opp": "Reptar",
      "oppId": "zO5MdCdfs1wmlIOMRsdDti3ewmeXeC48aUnnQqdSG9o%3d"
    },
    {
      "date": "August 06",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "hWK7%2b5DH%2fCXB3rHCiH1a4Fvnemb3M6GxQt8s%2b3dtkFg%3d",
      "team": "Tampa Tempest",
      "score": "7",
      "oppScore": "10",
      "opp": "El Ni\u00f1o",
      "oppId": "LE1oc%2fdB%2f%2f8We2RewjqcXWGVvpctpO4IKZbpyJ8TCpw%3d"
    },
    {
      "date": "August 07",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "J%2bI3a3KAfD80IPoQHME4UeWCXz6IsfRnzQNiY91m0so%3d",
      "team": "Tampa Tempest",
      "score": "5",
      "oppScore": "12",
      "opp": "Southside",
      "oppId": "jnzIw1u16R54lBpk1gV3qvXlKsIvelmRZNPjjDpRKXo%3d"
    },
    {
      "date": "August 07",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "ZtICzdL5TcZbtXslGQ9ScKABpEstkZgeBbXw%2bTmvhzc%3d",
      "team": "Tampa Tempest",
      "score": "5",
      "oppScore": "15",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "August 07",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "c7DJcql5wsVJPiS2q2ekPVje%2fklf96SgjVeNn5u1oLI%3d",
      "team": "Tampa Tempest",
      "score": "6",
      "oppScore": "11",
      "opp": "ScooberDivers",
      "oppId": "EQ5VJcEYXXeAviHK3zWePBbmNmZFDh9f%2fMkKpC9pY4Y%3d"
    },
    {
      "date": "August 07",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "M83xZjlmX0qunLBqyuwXezanY8LQoyzBZuWSVeFTloY%3d",
      "team": "Tampa Tempest",
      "score": "0",
      "oppScore": "0",
      "opp": "ScooberDivers",
      "oppId": "EQ5VJcEYXXeAviHK3zWePBbmNmZFDh9f%2fMkKpC9pY4Y%3d"
    }
  ],
  "3%2bZ1uFiVej3zLASHWkgqXkJ7hNQ98n84b2FvR4T3pQA%3d": [
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "HtsIQvSA2ZsCtZ877PKHBWNt0AdOk%2b9PaFY7SYBEujg%3d",
      "team": "Space Cowboys",
      "score": "6",
      "oppScore": "8",
      "opp": "Dyno",
      "oppId": "fIw72i6bkbJnjTbK02aFLpJ9Qy2lCUVWlRBvI%2b8UmIc%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "1vsTnnjQxREqAz1KkWDmYmJE8Hmv0DNWFrhwmLY6UOw%3d",
      "team": "Space Cowboys",
      "score": "12",
      "oppScore": "10",
      "opp": "Second Nature",
      "oppId": "9QN%2fOffYfC%2f0mj9CpLqFRthKvgHjrHj%2bM2%2fY44PMiCE%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "3%2fRDVuzh0DAOwXpRIJiYzw6SoNiXf1EWxHBcbI0Q9FM%3d",
      "team": "Space Cowboys",
      "score": "9",
      "oppScore": "11",
      "opp": "Black Lung",
      "oppId": "olaZlUwWblgF%2b%2b2nRJgbWJ2tj6QzPm5aIO7kfbpnA58%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "xPHxwzLNP53qibT0X1%2faF3vaxZYklkqqAJRMpAFLoo0%3d",
      "team": "Space Cowboys",
      "score": "13",
      "oppScore": "8",
      "opp": "Yee-Haw",
      "oppId": "NpDxoDV0mpf0Hc%2fBzLLx0bhgfIsOKi%2b2AIQMCptTcck%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "DgUiTZVvmiaXk%2fq9vLZ5v2cLQl46pSe%2bdwg35B6BhI8%3d",
      "team": "Space Cowboys",
      "score": "4",
      "oppScore": "13",
      "opp": "Rush Hour ATL",
      "oppId": "LdH2zJ494O%2bxdz6p92IsLTMaAmjgf6aP%2fBvM9Lr1RaQ%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "wX6%2feCL9GRE%2bF0qpIP1cRC%2bwS4pf4n%2bzcF4GdVCBKME%3d",
      "team": "Space Cowboys",
      "score": "9",
      "oppScore": "10",
      "opp": "Atlanta Arson",
      "oppId": "oAgat63CZNVga8weCR1ui3IB9YvjaA54miCvE%2f%2bRb5g%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "cWJTzf2zm9Ss6uBGSefUg5Pvuii9HQb%2bcRfECNQ1r%2fY%3d",
      "team": "Space Cowboys",
      "score": "10",
      "oppScore": "6",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "5PHu5G7XVbWnQiCRPB6vY1o8YDzYrfwjrWJyN7LxGD8%3d",
      "team": "Space Cowboys",
      "score": "11",
      "oppScore": "2",
      "opp": "Nashville Mudcats",
      "oppId": "m1V5goSU%2bO1BR4XRhH%2fyGq44j%2f4hNKcb%2bBApPx5jVzQ%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "EyXYXp3AER3fzAj%2f9KMB0MxSF8SwcoHgzDlzVP5%2fw1M%3d",
      "team": "Space Cowboys",
      "score": "7",
      "oppScore": "11",
      "opp": "Rush Hour ATL",
      "oppId": "LdH2zJ494O%2bxdz6p92IsLTMaAmjgf6aP%2fBvM9Lr1RaQ%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "%2bVCCL2XriOo22BENs4RSuXCl%2bKUKgK75SDrlCHVrZQc%3d",
      "team": "Space Cowboys",
      "score": "11",
      "oppScore": "10",
      "opp": "baNC",
      "oppId": "uce10clG%2fWf3GrKUxwKToSDTqXSp6FS58Fb50TN%2fPwU%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "yoL2W5W4u5%2b9zJXeB5sijhenYR7KHzUIRvFZ5%2bo4UrU%3d",
      "team": "Space Cowboys",
      "score": "2",
      "oppScore": "11",
      "opp": "Triumph",
      "oppId": "wAZDt10Q5MoiqMIAGOgPxedaQ2KA3QCSPn7hC8W%2bn5s%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "5sG8C94qXqtsqgiaKb%2bf7VtpF%2f4EHpjQEPjLiHlBKlM%3d",
      "team": "Space Cowboys",
      "score": "10",
      "oppScore": "9",
      "opp": "Tennessee Folklore",
      "oppId": "z7Hwsz316C%2bFf9Cg1gKP3NGBSqxMqnFjQRbeyMUhUD4%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "PhpsOTTt1QVQWJTfU1hUzXciUq5lZeTxtJbAkfBsDM8%3d",
      "team": "Space Cowboys",
      "score": "W",
      "oppScore": "F",
      "opp": "Dyno",
      "oppId": "fIw72i6bkbJnjTbK02aFLpJ9Qy2lCUVWlRBvI%2b8UmIc%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "ACWS0tUcuwXknqsvTagVV82V9kakEn535ps7Djcn5h0%3d",
      "team": "Space Cowboys",
      "score": "13",
      "oppScore": "11",
      "opp": "Charleston Heat Stroke",
      "oppId": "quSAXJHtjRXSWAHbdztMDD8ftXgRqlTrsEfTBm793Nk%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "nOye6PEVEODzen4s0w8qvYxZQYlNAR517NpxLiwM15A%3d",
      "team": "Space Cowboys",
      "score": "10",
      "oppScore": "13",
      "opp": "Black Lung",
      "oppId": "olaZlUwWblgF%2b%2b2nRJgbWJ2tj6QzPm5aIO7kfbpnA58%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "HL9wwk%2faI2ja2bGkQE9TyXo1cG47xb2m22Qy%2brdJs0A%3d",
      "team": "Space Cowboys",
      "score": "6",
      "oppScore": "13",
      "opp": "Oakgrove Boys",
      "oppId": "1zUV4kEpPc%2flA39WIyrp9j1w%2fhuIkgE6KlD9%2f%2ffQzg8%3d"
    }
  ],
  "z7Hwsz316C%2bFf9Cg1gKP3NGBSqxMqnFjQRbeyMUhUD4%3d": [
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "Dp4f9XZcBEjDZim3mOGSRMopgaRGfut2QvPX%2fBkbuzg%3d",
      "team": "Tennessee Folklore",
      "score": "11",
      "oppScore": "12",
      "opp": "Atlanta Arson",
      "oppId": "oAgat63CZNVga8weCR1ui3IB9YvjaA54miCvE%2f%2bRb5g%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "2D5%2fRYoprSTqBjariU8maQa6GzZ0S0BJmVNpAQHEiTE%3d",
      "team": "Tennessee Folklore",
      "score": "5",
      "oppScore": "13",
      "opp": "Alliance",
      "oppId": "%2f7JeYXylBxa9Xw5f5LSwua5DyAomcfJ7a7MZDP7nSUs%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "bP9s0vsNXwM%2b0BuB%2bMGyfIgwyr7LfRnDcRPlO6bocOc%3d",
      "team": "Tennessee Folklore",
      "score": "13",
      "oppScore": "8",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "5%2blM%2bqTrrOvX77scSm6DCeCVt0c40rHGPZRDwTcfX2M%3d",
      "team": "Tennessee Folklore",
      "score": "6",
      "oppScore": "13",
      "opp": "UpRoar",
      "oppId": "JYJdk5dDNlc6X6evPtQ6K9GWMwDy1xmzavYjS6uyHaE%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "bIBMGijZa8p1SZxxbc8IaixJ6r6uxr5z0EfasQ3hLyM%3d",
      "team": "Tennessee Folklore",
      "score": "4",
      "oppScore": "11",
      "opp": "Black Lung",
      "oppId": "olaZlUwWblgF%2b%2b2nRJgbWJ2tj6QzPm5aIO7kfbpnA58%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "Qfh%2fMCFPVlKOV3z7HFc5XwouKhmuRnXOSX7JFlC0j4I%3d",
      "team": "Tennessee Folklore",
      "score": "W",
      "oppScore": "F",
      "opp": "Second Nature",
      "oppId": "9QN%2fOffYfC%2f0mj9CpLqFRthKvgHjrHj%2bM2%2fY44PMiCE%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "bUhvPyVC5Xcm6gu6ASHeVFLRbHze8Wz%2f8y3NLik6%2fCA%3d",
      "team": "Tennessee Folklore",
      "score": "5",
      "oppScore": "11",
      "opp": "Brickhouse",
      "oppId": "b5ARnVg7OngG1a0%2fJuMZTHluwgNFe%2bN5esQIIKsEOUY%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "IjpZ2KO9V8jCwxgCPewzNLxJeOcoEqvB7qPR2bG9wJM%3d",
      "team": "Tennessee Folklore",
      "score": "7",
      "oppScore": "11",
      "opp": "Charleston Heat Stroke",
      "oppId": "quSAXJHtjRXSWAHbdztMDD8ftXgRqlTrsEfTBm793Nk%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "z%2fxz7Fia9JldngswLWLQSdb7%2b%2b9FDiK18UciLEC26j4%3d",
      "team": "Tennessee Folklore",
      "score": "11",
      "oppScore": "3",
      "opp": "Scoop",
      "oppId": "Rj8mJfKJry2xyWaxTIh5HdXGLaTMxr7%2fXjyL4uJET2o%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "TDXiVxibZjXeWruuq8KYtXa41WEP%2bZQ17gq0adxMQVw%3d",
      "team": "Tennessee Folklore",
      "score": "6",
      "oppScore": "11",
      "opp": "Dyno",
      "oppId": "fIw72i6bkbJnjTbK02aFLpJ9Qy2lCUVWlRBvI%2b8UmIc%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "Xl6bi1gdChgh7RzW1BXDL3r5DDBVl0S%2bmRNAVzeWXLE%3d",
      "team": "Tennessee Folklore",
      "score": "11",
      "oppScore": "10",
      "opp": "Queen City Kings",
      "oppId": "95yP4SyRqRX7HgLtZ9jNth95JPB163KSMqpgMxymy8g%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "5sG8C94qXqtsqgiaKb%2bf7VtpF%2f4EHpjQEPjLiHlBKlM%3d",
      "team": "Tennessee Folklore",
      "score": "9",
      "oppScore": "10",
      "opp": "Space Cowboys",
      "oppId": "3%2bZ1uFiVej3zLASHWkgqXkJ7hNQ98n84b2FvR4T3pQA%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "BqIiP7O%2ft22jwN2og36buVMR%2bOtacjsP5rtE90goIuM%3d",
      "team": "Tennessee Folklore",
      "score": "F",
      "oppScore": "W",
      "opp": "Triumph",
      "oppId": "wAZDt10Q5MoiqMIAGOgPxedaQ2KA3QCSPn7hC8W%2bn5s%3d"
    },
    {
      "date": "August 20",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "n1YcR2ZzHV0nrv5EEZf5hfh60nBHgN4n9%2blrc8D6dp8%3d",
      "team": "Tennessee Folklore",
      "score": "13",
      "oppScore": "12",
      "opp": "Atlanta Arson",
      "oppId": "oAgat63CZNVga8weCR1ui3IB9YvjaA54miCvE%2f%2bRb5g%3d"
    },
    {
      "date": "August 20",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "1RLyKfdAmsOoso3gpvBRhvrSlqCuuo%2b%2f3Z%2fmnGUVol8%3d",
      "team": "Tennessee Folklore",
      "score": "13",
      "oppScore": "7",
      "opp": "Alliance",
      "oppId": "%2f7JeYXylBxa9Xw5f5LSwua5DyAomcfJ7a7MZDP7nSUs%3d"
    },
    {
      "date": "August 20",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "%2bowgWugqrGoAOrZLhXwuhMD5YkCZa46sEJChcwARrM4%3d",
      "team": "Tennessee Folklore",
      "score": "11",
      "oppScore": "13",
      "opp": "Hooch",
      "oppId": "OmOeVI6iSB0tHCNLK01vsOOpZmXF97z%2b2x81ifAm8Vs%3d"
    },
    {
      "date": "August 20",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "R9ooUw1VdydGq6Et3cxg94Bnp2fOqQ6zQ3k5FxrxS08%3d",
      "team": "Tennessee Folklore",
      "score": "5",
      "oppScore": "13",
      "opp": "Delirium",
      "oppId": "0yudJHn8N3NbInYx1wqMxoticHnPBt4YkAfZZAUc7U8%3d"
    },
    {
      "date": "August 21",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "62ZeG9V0S9LT5j5F6AiWfYqTfChO1fqdqDAX44BhMus%3d",
      "team": "Tennessee Folklore",
      "score": "13",
      "oppScore": "12",
      "opp": "Hooch",
      "oppId": "OmOeVI6iSB0tHCNLK01vsOOpZmXF97z%2b2x81ifAm8Vs%3d"
    },
    {
      "date": "August 21",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "bT1Z9eGmjW%2fwk60UUDfsU6DKsQRm9osWM1wZ6FeqkTI%3d",
      "team": "Tennessee Folklore",
      "score": "12",
      "oppScore": "13",
      "opp": "Delirium",
      "oppId": "0yudJHn8N3NbInYx1wqMxoticHnPBt4YkAfZZAUc7U8%3d"
    }
  ],
  "JYJdk5dDNlc6X6evPtQ6K9GWMwDy1xmzavYjS6uyHaE%3d": [
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "dDd89SwVUtUyjOUjaXp8Wx34xuAqJ1zmGpdBp%2bstKhI%3d",
      "team": "UpRoar",
      "score": "13",
      "oppScore": "10",
      "opp": "Dyno",
      "oppId": "fIw72i6bkbJnjTbK02aFLpJ9Qy2lCUVWlRBvI%2b8UmIc%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "1l8DghI%2f87xE3rNGgMSXJl%2fvg1eZ%2b132C1xR2dbZK1A%3d",
      "team": "UpRoar",
      "score": "10",
      "oppScore": "11",
      "opp": "Lost Boys",
      "oppId": "aNMyR0uAJNwnidjeClZAMqixGjtlQqA6k7CiZP1R0%2bM%3d"
    },
    {
      "date": "July 09",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "1RXwBeLv%2f03YGMhf0TqFaw6de58XmmCLTf%2b7ChAPAh8%3d",
      "team": "UpRoar",
      "score": "13",
      "oppScore": "4",
      "opp": "Yee-Haw",
      "oppId": "NpDxoDV0mpf0Hc%2fBzLLx0bhgfIsOKi%2b2AIQMCptTcck%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "5%2blM%2bqTrrOvX77scSm6DCeCVt0c40rHGPZRDwTcfX2M%3d",
      "team": "UpRoar",
      "score": "13",
      "oppScore": "6",
      "opp": "Tennessee Folklore",
      "oppId": "z7Hwsz316C%2bFf9Cg1gKP3NGBSqxMqnFjQRbeyMUhUD4%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "2lskUGgG6ngBRnXcod6tiFruBwq%2f9neQtqSaCqoMHLU%3d",
      "team": "UpRoar",
      "score": "11",
      "oppScore": "10",
      "opp": "Alliance",
      "oppId": "%2f7JeYXylBxa9Xw5f5LSwua5DyAomcfJ7a7MZDP7nSUs%3d"
    },
    {
      "date": "July 10",
      "tournament": "Terminus 2022",
      "tournamentId": "Terminus-2022",
      "gameId": "n%2bcVc2KQSDQG%2bXENZ0HPcUS3Uw1TF8RY3k3RHTg4j0g%3d",
      "team": "UpRoar",
      "score": "14",
      "oppScore": "15",
      "opp": "Delirium",
      "oppId": "0yudJHn8N3NbInYx1wqMxoticHnPBt4YkAfZZAUc7U8%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "8J9GTRqr41QEn3b7%2b1NonRigj71dct35vBHJKmymhK0%3d",
      "team": "UpRoar",
      "score": "6",
      "oppScore": "15",
      "opp": "GOAT",
      "oppId": "6IFGDipIDG9l%2br4E8TUyujjAslzlgFQUH9y5j9M0G4g%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "6uQx1SKuR1YW56UIa8Xf%2bcPvarnCjafbt6uJtE3Nogc%3d",
      "team": "UpRoar",
      "score": "7",
      "oppScore": "14",
      "opp": "Mad Men",
      "oppId": "Al%2fA4sj9NEIvG2pfoVeKR8OCQL%2fREoXEJcD3sZnI49A%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "ED3iT7UtHcHthdc3m5DPsn35R9%2fo%2bR%2bPKYT2xVB6Y5s%3d",
      "team": "UpRoar",
      "score": "10",
      "oppScore": "12",
      "opp": "Clutch",
      "oppId": "JDc%2bBsCjGUX7gdebRqmdPvq1u2rFlZ1giM6BgTaL5Lk%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "y4UULVy96y%2fl8QgDKzVV5Nn4NFPEnXizhc1joa2SxtI%3d",
      "team": "UpRoar",
      "score": "7",
      "oppScore": "15",
      "opp": "Trident I",
      "oppId": "HJQp9EgoBkW9LmipJ4BOA7JQ%2bJ%2fsSTavaeYtiM6KQus%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "lRbKO%2fVD5cymDZws7IKjnMFse%2f%2ftfd6%2fF90uKpEctG4%3d",
      "team": "UpRoar",
      "score": "11",
      "oppScore": "8",
      "opp": "Inception",
      "oppId": "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "bIBdIvzz33ElqRZ3wqD0tJw%2bQY58aYvwNxNq715unOE%3d",
      "team": "UpRoar",
      "score": "9",
      "oppScore": "14",
      "opp": "Mallard",
      "oppId": "iIrscp1ZOpIpCSkyCbUNt0Vq3FobvpA18ZWbPM63hJo%3d"
    }
  ],
  "%2f0f3WxBZYJ%2b3OL6PsFnvv3U%2fOe26OttW545T0qgsuCU%3d": [
    {
      "date": "July 09",
      "tournament": "Revolution",
      "tournamentId": "Revolution",
      "gameId": "m0GJ%2bOAVgef5Oy%2f7OJDBvijmfJr8N%2bhApfcz%2f8TR%2fa8%3d",
      "team": "Journeymen",
      "score": "7",
      "oppScore": "13",
      "opp": "Anchor",
      "oppId": "65D8RPtgl9G13w60CAflaWzwxgNmzb1k9wZdA1O25m0%3d"
    },
    {
      "date": "July 09",
      "tournament": "Revolution",
      "tournamentId": "Revolution",
      "gameId": "ijYC8UOWUm%2fEJMiyFlHbuup85XO%2bSc7j2Ick7z8K9N8%3d",
      "team": "Journeymen",
      "score": "6",
      "oppScore": "11",
      "opp": "OAKLAND Ultimate",
      "oppId": "NU8saUpp6kvWaPJScKgEI2XRcIv7eeRwJI5ZPArOifo%3d"
    },
    {
      "date": "July 10",
      "tournament": "Revolution",
      "tournamentId": "Revolution",
      "gameId": "HZq8c4euQBvUqhLRja83Zg5C0UJkC%2bMrA%2bBmNm7vpcE%3d",
      "team": "Journeymen",
      "score": "L",
      "oppScore": "W",
      "opp": "Mavericks",
      "oppId": "aOMaClZUq3ONOWgUgWYMbnRTrQ1lXICtIdPXubWywt4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Sanctionals 2022",
      "tournamentId": "Sanctionals-2022",
      "gameId": "7SWUuil%2b%2fWW7Ko5RmLDIrBoOQbTTn%2fY1%2fR3mGrnOOA0%3d",
      "team": "Journeymen",
      "score": "12",
      "oppScore": "9",
      "opp": "Mavericks",
      "oppId": "aOMaClZUq3ONOWgUgWYMbnRTrQ1lXICtIdPXubWywt4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Sanctionals 2022",
      "tournamentId": "Sanctionals-2022",
      "gameId": "1zSgr7RYH%2ffj%2fmmQy9OqWPKhMPriEjRmmmEwN8%2fV7tw%3d",
      "team": "Journeymen",
      "score": "4",
      "oppScore": "13",
      "opp": "Anchor",
      "oppId": "65D8RPtgl9G13w60CAflaWzwxgNmzb1k9wZdA1O25m0%3d"
    }
  ],
  "NU8saUpp6kvWaPJScKgEI2XRcIv7eeRwJI5ZPArOifo%3d": [
    {
      "date": "July 09",
      "tournament": "Revolution",
      "tournamentId": "Revolution",
      "gameId": "5zadCrd16e1n%2b9b%2bhZT1I3ga85DJ%2biZLNPL9uzLN2tA%3d",
      "team": "OAKLAND Ultimate",
      "score": "13",
      "oppScore": "10",
      "opp": "Anchor",
      "oppId": "65D8RPtgl9G13w60CAflaWzwxgNmzb1k9wZdA1O25m0%3d"
    },
    {
      "date": "July 09",
      "tournament": "Revolution",
      "tournamentId": "Revolution",
      "gameId": "ijYC8UOWUm%2fEJMiyFlHbuup85XO%2bSc7j2Ick7z8K9N8%3d",
      "team": "OAKLAND Ultimate",
      "score": "11",
      "oppScore": "6",
      "opp": "Journeymen",
      "oppId": "%2f0f3WxBZYJ%2b3OL6PsFnvv3U%2fOe26OttW545T0qgsuCU%3d"
    },
    {
      "date": "July 09",
      "tournament": "Revolution",
      "tournamentId": "Revolution",
      "gameId": "EH5wxoemZTVH3mEIrpqkwxdCu35KBNbw87V7kzEW8jE%3d",
      "team": "OAKLAND Ultimate",
      "score": "9",
      "oppScore": "13",
      "opp": "OAT",
      "oppId": "3jrAxF6U290JZyiuGff9bkOyKPIspXXtN94jCffcvgs%3d"
    }
  ],
  "aOMaClZUq3ONOWgUgWYMbnRTrQ1lXICtIdPXubWywt4%3d": [
    {
      "date": "July 09",
      "tournament": "Revolution",
      "tournamentId": "Revolution",
      "gameId": "LI33Z2TUQQct0RR3vM6ivMXp%2f0qnQd%2bm5JnRxHurpJA%3d",
      "team": "Mavericks",
      "score": "8",
      "oppScore": "9",
      "opp": "Red Dawn",
      "oppId": "wgus%2bDUjcIEQFNwcW6C2SbArugk%2b1zLeWnZguxqHXlE%3d"
    },
    {
      "date": "July 09",
      "tournament": "Revolution",
      "tournamentId": "Revolution",
      "gameId": "6e2l1zuxb0jXOJVlGk%2fcTUYOUCBj2q97M%2fl9bTr0mK0%3d",
      "team": "Mavericks",
      "score": "2",
      "oppScore": "13",
      "opp": "OAT",
      "oppId": "3jrAxF6U290JZyiuGff9bkOyKPIspXXtN94jCffcvgs%3d"
    },
    {
      "date": "July 10",
      "tournament": "Revolution",
      "tournamentId": "Revolution",
      "gameId": "HZq8c4euQBvUqhLRja83Zg5C0UJkC%2bMrA%2bBmNm7vpcE%3d",
      "team": "Mavericks",
      "score": "W",
      "oppScore": "L",
      "opp": "Journeymen",
      "oppId": "%2f0f3WxBZYJ%2b3OL6PsFnvv3U%2fOe26OttW545T0qgsuCU%3d"
    },
    {
      "date": "August 20",
      "tournament": "Sanctionals 2022",
      "tournamentId": "Sanctionals-2022",
      "gameId": "7SWUuil%2b%2fWW7Ko5RmLDIrBoOQbTTn%2fY1%2fR3mGrnOOA0%3d",
      "team": "Mavericks",
      "score": "9",
      "oppScore": "12",
      "opp": "Journeymen",
      "oppId": "%2f0f3WxBZYJ%2b3OL6PsFnvv3U%2fOe26OttW545T0qgsuCU%3d"
    },
    {
      "date": "August 20",
      "tournament": "Sanctionals 2022",
      "tournamentId": "Sanctionals-2022",
      "gameId": "mSSSnIbW4CTnANqx%2bF7RO3Ue3Dcr7yzEX%2fRHErLVrsI%3d",
      "team": "Mavericks",
      "score": "9",
      "oppScore": "13",
      "opp": "Anchor",
      "oppId": "65D8RPtgl9G13w60CAflaWzwxgNmzb1k9wZdA1O25m0%3d"
    }
  ],
  "wgus%2bDUjcIEQFNwcW6C2SbArugk%2b1zLeWnZguxqHXlE%3d": [
    {
      "date": "July 09",
      "tournament": "Revolution",
      "tournamentId": "Revolution",
      "gameId": "LI33Z2TUQQct0RR3vM6ivMXp%2f0qnQd%2bm5JnRxHurpJA%3d",
      "team": "Red Dawn",
      "score": "9",
      "oppScore": "8",
      "opp": "Mavericks",
      "oppId": "aOMaClZUq3ONOWgUgWYMbnRTrQ1lXICtIdPXubWywt4%3d"
    },
    {
      "date": "July 09",
      "tournament": "Revolution",
      "tournamentId": "Revolution",
      "gameId": "bzi8y1cyEN%2frX%2bQOjwjhHaghB%2f1sn8CiRzImDIhpjaE%3d",
      "team": "Red Dawn",
      "score": "2",
      "oppScore": "13",
      "opp": "OAT",
      "oppId": "3jrAxF6U290JZyiuGff9bkOyKPIspXXtN94jCffcvgs%3d"
    },
    {
      "date": "July 10",
      "tournament": "Revolution",
      "tournamentId": "Revolution",
      "gameId": "Z7kGmbe04o7Us5cK%2fTULHbS2BV39yRHQPLfWt18SIu4%3d",
      "team": "Red Dawn",
      "score": "3",
      "oppScore": "13",
      "opp": "Anchor",
      "oppId": "65D8RPtgl9G13w60CAflaWzwxgNmzb1k9wZdA1O25m0%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "4YQ%2f%2byuksW3PxmKb%2bcw0SSIqsrvPrkD0ZEmiaxfgxY4%3d",
      "team": "Red Dawn",
      "score": "9",
      "oppScore": "14",
      "opp": "Minnesota Superior U20B",
      "oppId": "l0841gMVxrjhuZzhqzFnYR7VeDuFVKrJOxuJebPVKDc%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "WcCSptS7MUZQhUOPIcugLbarZIB61AtgWN8ktUy4h90%3d",
      "team": "Red Dawn",
      "score": "7",
      "oppScore": "14",
      "opp": "Triforce",
      "oppId": "bE9A3Qj1MqW7pCIHHtQnnzKx11rAl2O9wYRSSZQ4Z40%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "ZDpl3uc1%2f361e15S3b7colbnw1TZZHkTEhYcIvGNJfM%3d",
      "team": "Red Dawn",
      "score": "15",
      "oppScore": "3",
      "opp": "Birds Of Play",
      "oppId": "C5gjZFjOICEF1K9fBVXojD5NxWjD32cRDWmzxGkasm0%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "dVv7vQaTr70gT%2bAVanNvxxvLCXzj%2bmrbNNa32VmlFrs%3d",
      "team": "Red Dawn",
      "score": "12",
      "oppScore": "15",
      "opp": "Oregon Trainwreck",
      "oppId": "4kZQQUYXvneeQkXiO%2bU1YCVyOwrHOLC6tdR6dVhA%2b%2bA%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "j2uvtmSg6qD%2fedijGyeuibYdUseLsxxPCYseD%2bRwUEQ%3d",
      "team": "Red Dawn",
      "score": "6",
      "oppScore": "15",
      "opp": "Charlotte Flight",
      "oppId": "P4imWyKxeDrrrqjQ0lCOZq28YNb5YA%2fJ4ekFVl4wwG4%3d"
    },
    {
      "date": "August 08",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "RBNu4TErTHEK091yWzdXkdw5I1yVu%2bq8gmmJ69nfTFk%3d",
      "team": "Red Dawn",
      "score": "7",
      "oppScore": "15",
      "opp": "Foggy Bottom Boys",
      "oppId": "pZBAWiTtMe65S%2b5DyaXh%2fK8oMrDmXk6ny66TYS85X7U%3d"
    },
    {
      "date": "August 08",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "l%2fS910TUyqzwJtJ3WdJm8Au82L2ijWZGFesfOmRb%2b%2fM%3d",
      "team": "Red Dawn",
      "score": "11",
      "oppScore": "13",
      "opp": "DEVYL U-20 Boys",
      "oppId": "YXOyQcXz9dyRRYJVW1knp%2felzsehAC4MeGolkdDmmfc%3d"
    }
  ],
  "65D8RPtgl9G13w60CAflaWzwxgNmzb1k9wZdA1O25m0%3d": [
    {
      "date": "July 09",
      "tournament": "Revolution",
      "tournamentId": "Revolution",
      "gameId": "5zadCrd16e1n%2b9b%2bhZT1I3ga85DJ%2biZLNPL9uzLN2tA%3d",
      "team": "Anchor",
      "score": "10",
      "oppScore": "13",
      "opp": "OAKLAND Ultimate",
      "oppId": "NU8saUpp6kvWaPJScKgEI2XRcIv7eeRwJI5ZPArOifo%3d"
    },
    {
      "date": "July 09",
      "tournament": "Revolution",
      "tournamentId": "Revolution",
      "gameId": "m0GJ%2bOAVgef5Oy%2f7OJDBvijmfJr8N%2bhApfcz%2f8TR%2fa8%3d",
      "team": "Anchor",
      "score": "13",
      "oppScore": "7",
      "opp": "Journeymen",
      "oppId": "%2f0f3WxBZYJ%2b3OL6PsFnvv3U%2fOe26OttW545T0qgsuCU%3d"
    },
    {
      "date": "July 10",
      "tournament": "Revolution",
      "tournamentId": "Revolution",
      "gameId": "Z7kGmbe04o7Us5cK%2fTULHbS2BV39yRHQPLfWt18SIu4%3d",
      "team": "Anchor",
      "score": "13",
      "oppScore": "3",
      "opp": "Red Dawn",
      "oppId": "wgus%2bDUjcIEQFNwcW6C2SbArugk%2b1zLeWnZguxqHXlE%3d"
    },
    {
      "date": "August 20",
      "tournament": "Sanctionals 2022",
      "tournamentId": "Sanctionals-2022",
      "gameId": "1zSgr7RYH%2ffj%2fmmQy9OqWPKhMPriEjRmmmEwN8%2fV7tw%3d",
      "team": "Anchor",
      "score": "13",
      "oppScore": "4",
      "opp": "Journeymen",
      "oppId": "%2f0f3WxBZYJ%2b3OL6PsFnvv3U%2fOe26OttW545T0qgsuCU%3d"
    },
    {
      "date": "August 20",
      "tournament": "Sanctionals 2022",
      "tournamentId": "Sanctionals-2022",
      "gameId": "mSSSnIbW4CTnANqx%2bF7RO3Ue3Dcr7yzEX%2fRHErLVrsI%3d",
      "team": "Anchor",
      "score": "13",
      "oppScore": "9",
      "opp": "Mavericks",
      "oppId": "aOMaClZUq3ONOWgUgWYMbnRTrQ1lXICtIdPXubWywt4%3d"
    }
  ],
  "uzGm2kYsw4N4NvAkRAEeLbHAJE108B9cHHBgy%2bR99xE%3d": [
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "TGfgJRFZn5KVAGB3%2bFNPcUVfl1aS7XGu5sBV3GZuXcY%3d",
      "team": "Dreadnought",
      "score": "11",
      "oppScore": "15",
      "opp": "Mallard II",
      "oppId": "waPLoije6zVsjVUcOXTlGSFZ10PPabaoS6kk%2bnW2NCo%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "a4wUjZ1nhUzNPcJDKo87sDUw%2bs9px1%2f1ttCER%2bY3z6Y%3d",
      "team": "Dreadnought",
      "score": "4",
      "oppScore": "15",
      "opp": "Cryptic",
      "oppId": "Qhc%2fzwjxDlH6UTqMQqHKE2ms56DaeAMWMHQJCBwAjIw%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "vHHJkexZaJ5wsG4e9nc8uIusZwQOJV7cVf85iQWtH4k%3d",
      "team": "Dreadnought",
      "score": "15",
      "oppScore": "7",
      "opp": "THE BODY",
      "oppId": "nyLl78Eg24EtJDKG0oHsU9e%2fg0hrOYqklm7h8m4R0Jo%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "AUI7euQAzXzst%2fMSe1bRaIFkxff%2fmPGXIzyZftCkSnU%3d",
      "team": "Dreadnought",
      "score": "15",
      "oppScore": "8",
      "opp": "Meadowlark",
      "oppId": "5mFdu3Xc%2bl7OoxB5yBc9Q8%2bCImG%2b%2fOFvdvmpUhgLwdo%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "SJBvNoPh5m8iQb6hME83OZtoeDWZOkjHAujJ%2f%2fp6shY%3d",
      "team": "Dreadnought",
      "score": "15",
      "oppScore": "4",
      "opp": "Overdrive",
      "oppId": "cfApDIqN4eoe%2fr26h%2b8fQj3s9XKGa9nJC8lT5uya7gM%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "x4jG9pM26rGVDaC0Tr%2fAmeklJZQ3eknIJPWdwbwSNdY%3d",
      "team": "Dreadnought",
      "score": "15",
      "oppScore": "4",
      "opp": "Supercell",
      "oppId": "%2bhSpC0vxzjqumEjEEoGhDiOz5%2bUgihYtLrNXn407Tbg%3d"
    },
    {
      "date": "August 20",
      "tournament": "OK Corral 2022",
      "tournamentId": "OK-Corral-2022",
      "gameId": "YYRElDfu1T87zAD1kiKN2tohblrPuFoMnem13LFIhxg%3d",
      "team": "Dreadnought",
      "score": "13",
      "oppScore": "3",
      "opp": "Central Arkansas Surge",
      "oppId": "a2C88dafFqaR9lnMU22LAsN40joRWyCBnRQyyoRh3jY%3d"
    },
    {
      "date": "August 20",
      "tournament": "OK Corral 2022",
      "tournamentId": "OK-Corral-2022",
      "gameId": "P%2b8nYlvvSSft%2bjmEJz3GsC6Rrtpkv7kQE83DFNaUBPw%3d",
      "team": "Dreadnought",
      "score": "9",
      "oppScore": "13",
      "opp": "Rawhide",
      "oppId": "loSeq%2bvNEN8CCdx6pU5P0mfNTczm7%2fAa01t6KMfxol0%3d"
    }
  ],
  "waPLoije6zVsjVUcOXTlGSFZ10PPabaoS6kk%2bnW2NCo%3d": [
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "TGfgJRFZn5KVAGB3%2bFNPcUVfl1aS7XGu5sBV3GZuXcY%3d",
      "team": "Mallard II",
      "score": "15",
      "oppScore": "11",
      "opp": "Dreadnought",
      "oppId": "uzGm2kYsw4N4NvAkRAEeLbHAJE108B9cHHBgy%2bR99xE%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "pUoZ%2fNEjb30TMBRZAFhBOhcPUqqrOYH73SERDiRqeUA%3d",
      "team": "Mallard II",
      "score": "10",
      "oppScore": "15",
      "opp": "Cryptic",
      "oppId": "Qhc%2fzwjxDlH6UTqMQqHKE2ms56DaeAMWMHQJCBwAjIw%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "9070KOhaD4b%2bSLm5s%2bCKBuVLGtqxJpLJZr8zKu%2fPK3Y%3d",
      "team": "Mallard II",
      "score": "15",
      "oppScore": "6",
      "opp": "Loaded Panda",
      "oppId": "8S%2f2XcUwQbLf0KM7yOmv3HEYXSrnLhAdwnHQvhMjxCY%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "eWL4DLODuwGVqKDxm95X%2fU6ME%2bJwRr34nBcpzWi8%2fv4%3d",
      "team": "Mallard II",
      "score": "14",
      "oppScore": "13",
      "opp": "DeMo",
      "oppId": "FdlFpUSGIahm89rZTXrHg2K85ugGqml2fjUWEPHtgPo%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "d8jt%2f7PMIWzYiCqExh1SUOD3jrvMM9u%2f4Cd0qU001Y0%3d",
      "team": "Mallard II",
      "score": "11",
      "oppScore": "15",
      "opp": "Scythe",
      "oppId": "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "74kjMCiwnyllruGppKV1p8a%2fAfbJwpajAm1r%2bs9RhYA%3d",
      "team": "Mallard II",
      "score": "14",
      "oppScore": "15",
      "opp": "Cryptic",
      "oppId": "Qhc%2fzwjxDlH6UTqMQqHKE2ms56DaeAMWMHQJCBwAjIw%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "aCb642SFPsILqxuscXb0JxZygYCbzzb6LBQG%2bBdjfko%3d",
      "team": "Mallard II",
      "score": "13",
      "oppScore": "7",
      "opp": "DINGWOP",
      "oppId": "xZAGkPMHJ9VCCvgCVVn5WRxQtwy%2bmZ7Q%2bGsb4rLqQqE%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "pXJrz4BsxxAiOsOpEEP5pwWbGh%2bQ2jwcclguH%2bqpT6w%3d",
      "team": "Mallard II",
      "score": "13",
      "oppScore": "7",
      "opp": "NOMAD",
      "oppId": "RFpmXY6FT7eRry4gGUAnkSywT92Q24c%2fMw5CrinbhpI%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "%2fIOmO%2bZsXK4a%2ftIt4wUORNMSPupKdGUdf1V5dZtvJ9Y%3d",
      "team": "Mallard II",
      "score": "6",
      "oppScore": "10",
      "opp": "DeMo",
      "oppId": "FdlFpUSGIahm89rZTXrHg2K85ugGqml2fjUWEPHtgPo%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "4cj%2fJB5LiDMDIhKliY30A5F55%2fIAPA%2fhtjJOgKR0m4I%3d",
      "team": "Mallard II",
      "score": "8",
      "oppScore": "13",
      "opp": "Knights of Ni",
      "oppId": "dgH60oTrddgw%2bSl2xkIe%2fis%2fu1HY9ndk1MZTdN4OiAs%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "PmYwPLEewmgr%2bro315tQpxkC0qquCef8QZxzPITcFWo%3d",
      "team": "Mallard II",
      "score": "12",
      "oppScore": "6",
      "opp": "Overdrive",
      "oppId": "cfApDIqN4eoe%2fr26h%2b8fQj3s9XKGa9nJC8lT5uya7gM%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "kaaQ6u1xGnfUiEjRslsmtWM9tcpwQT%2f55YR3b6t%2fubI%3d",
      "team": "Mallard II",
      "score": "13",
      "oppScore": "12",
      "opp": "Cryptic",
      "oppId": "Qhc%2fzwjxDlH6UTqMQqHKE2ms56DaeAMWMHQJCBwAjIw%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "rdBPWsHns90Hj91I3biUyT5TYOuPlR8Q97Pb9HMfsto%3d",
      "team": "Mallard II",
      "score": "13",
      "oppScore": "5",
      "opp": "Trident II",
      "oppId": "b69A0xfMxQKwR01A7jfOqdv1wbChbTxRp5jko3aOCXk%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "UcklxgduEO5UzA6N47BlgA1iyjBTBlpqPJszCaTvexU%3d",
      "team": "Mallard II",
      "score": "13",
      "oppScore": "4",
      "opp": "Overdrive",
      "oppId": "cfApDIqN4eoe%2fr26h%2b8fQj3s9XKGa9nJC8lT5uya7gM%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "BvNgmvO28D62Gbjx29Rsi0rEl2Pr3dpDPOBC9nNTddg%3d",
      "team": "Mallard II",
      "score": "13",
      "oppScore": "12",
      "opp": "Beacon",
      "oppId": "4n5jY6eFSRuptn3xrctxCFjAy7g9dqmJM2uPNhY3qbk%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "TBdUYuCG64ggsPeBUBhDAOomQOjiwBXEtvkSPjEdEzw%3d",
      "team": "Mallard II",
      "score": "13",
      "oppScore": "11",
      "opp": "MKE",
      "oppId": "qHyFAYrlLVWh07Z9rgHqv1nh7SsCt5k4p8Ri0Bna7Zc%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "3gHWBSyqNWHnPyNXHwD1NraPlIM3QOP106S93qS7w4Q%3d",
      "team": "Mallard II",
      "score": "13",
      "oppScore": "12",
      "opp": "Kansas City Smokestack",
      "oppId": "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "kcxFjNd%2f3MNc4fDxIkbOeu5uqVIc5VSlLsQPGOlxhM4%3d",
      "team": "Mallard II",
      "score": "6",
      "oppScore": "13",
      "opp": "Scythe",
      "oppId": "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "DEDFF%2bk3tlwnHpflxkHt2AO9o3OBVprY83yulXLOUgw%3d",
      "team": "Mallard II",
      "score": "10",
      "oppScore": "13",
      "opp": "Haymaker",
      "oppId": "F7zZjPQTPbFe%2fxIVicHJD2zRIOORpO5hSxfBuY5fxcQ%3d"
    }
  ],
  "%2bhSpC0vxzjqumEjEEoGhDiOz5%2bUgihYtLrNXn407Tbg%3d": [
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "ObM3FYhD7F56UQcmhggtvqXk1HPNkqxVkeNOmL50bl0%3d",
      "team": "Supercell",
      "score": "5",
      "oppScore": "15",
      "opp": "NOx",
      "oppId": "YLXPz%2fCNrXaNTSa14rMcZI2zV%2brkt0P1fA0UxcJlPaE%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "hyFaHkYKrAIn3D5Ae4naJAxQtZJ96K%2fpl1lasxfOdSo%3d",
      "team": "Supercell",
      "score": "4",
      "oppScore": "15",
      "opp": "STL Moonar",
      "oppId": "XslCVk8W7kUV6p1A54%2bck0Soa5yWKBsdq%2fdwbiJ%2f%2bkk%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "KIQVka97WjUM%2bc3ulBb2Q8tSDQUHVYKZkufHHNPQzLo%3d",
      "team": "Supercell",
      "score": "1",
      "oppScore": "15",
      "opp": "Scythe",
      "oppId": "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "uuL5ou3A%2b8Bcu0R37yjw1%2b7dWR5MuvOUTM8lcJGBLPU%3d",
      "team": "Supercell",
      "score": "6",
      "oppScore": "15",
      "opp": "THE BODY",
      "oppId": "nyLl78Eg24EtJDKG0oHsU9e%2fg0hrOYqklm7h8m4R0Jo%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "x4jG9pM26rGVDaC0Tr%2fAmeklJZQ3eknIJPWdwbwSNdY%3d",
      "team": "Supercell",
      "score": "4",
      "oppScore": "15",
      "opp": "Dreadnought",
      "oppId": "uzGm2kYsw4N4NvAkRAEeLbHAJE108B9cHHBgy%2bR99xE%3d"
    }
  ],
  "Qhc%2fzwjxDlH6UTqMQqHKE2ms56DaeAMWMHQJCBwAjIw%3d": [
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "pUoZ%2fNEjb30TMBRZAFhBOhcPUqqrOYH73SERDiRqeUA%3d",
      "team": "Cryptic",
      "score": "15",
      "oppScore": "10",
      "opp": "Mallard II",
      "oppId": "waPLoije6zVsjVUcOXTlGSFZ10PPabaoS6kk%2bnW2NCo%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "a4wUjZ1nhUzNPcJDKo87sDUw%2bs9px1%2f1ttCER%2bY3z6Y%3d",
      "team": "Cryptic",
      "score": "15",
      "oppScore": "4",
      "opp": "Dreadnought",
      "oppId": "uzGm2kYsw4N4NvAkRAEeLbHAJE108B9cHHBgy%2bR99xE%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "EO4KT0JANjyFoT2bACwr0HlULIWG8cr7KxgZbJnSMnw%3d",
      "team": "Cryptic",
      "score": "11",
      "oppScore": "15",
      "opp": "DeMo",
      "oppId": "FdlFpUSGIahm89rZTXrHg2K85ugGqml2fjUWEPHtgPo%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "%2bjdeDMboXV3BITRffnBffNle2AQ5oiiLJP8VezfeKf4%3d",
      "team": "Cryptic",
      "score": "15",
      "oppScore": "6",
      "opp": "Loaded Panda",
      "oppId": "8S%2f2XcUwQbLf0KM7yOmv3HEYXSrnLhAdwnHQvhMjxCY%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "9BEyWpk1fHuGHno8k718RTydTEHU9aFHtBXvsx9hWc4%3d",
      "team": "Cryptic",
      "score": "14",
      "oppScore": "15",
      "opp": "Kansas City Smokestack",
      "oppId": "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "74kjMCiwnyllruGppKV1p8a%2fAfbJwpajAm1r%2bs9RhYA%3d",
      "team": "Cryptic",
      "score": "15",
      "oppScore": "14",
      "opp": "Mallard II",
      "oppId": "waPLoije6zVsjVUcOXTlGSFZ10PPabaoS6kk%2bnW2NCo%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "daR3V1xm0sD4InWcoFX7sODYWG%2beG4ECivgz0FxleM8%3d",
      "team": "Cryptic",
      "score": "13",
      "oppScore": "6",
      "opp": "DINGWOP",
      "oppId": "xZAGkPMHJ9VCCvgCVVn5WRxQtwy%2bmZ7Q%2bGsb4rLqQqE%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "xqCGnzpUkP9x1DmgzpaGsR3HS%2biaaI1kQadUEkEkJN0%3d",
      "team": "Cryptic",
      "score": "13",
      "oppScore": "7",
      "opp": "NOMAD",
      "oppId": "RFpmXY6FT7eRry4gGUAnkSywT92Q24c%2fMw5CrinbhpI%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "zYiSYDriyabZTJhVTCDFgcNDCTcWYxYl0RLKecQETGg%3d",
      "team": "Cryptic",
      "score": "10",
      "oppScore": "11",
      "opp": "Knights of Ni",
      "oppId": "dgH60oTrddgw%2bSl2xkIe%2fis%2fu1HY9ndk1MZTdN4OiAs%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "eTntf9WRc1ly3X7TE6WfKcvs5kdYq%2fZDotDU9bpVJ8k%3d",
      "team": "Cryptic",
      "score": "8",
      "oppScore": "12",
      "opp": "DeMo",
      "oppId": "FdlFpUSGIahm89rZTXrHg2K85ugGqml2fjUWEPHtgPo%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "SP1VUYmerlZQfFNZ57i0dgeiOYtn%2bA2iHJtgiKcT1n0%3d",
      "team": "Cryptic",
      "score": "13",
      "oppScore": "3",
      "opp": "NOx",
      "oppId": "YLXPz%2fCNrXaNTSa14rMcZI2zV%2brkt0P1fA0UxcJlPaE%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "kaaQ6u1xGnfUiEjRslsmtWM9tcpwQT%2f55YR3b6t%2fubI%3d",
      "team": "Cryptic",
      "score": "12",
      "oppScore": "13",
      "opp": "Mallard II",
      "oppId": "waPLoije6zVsjVUcOXTlGSFZ10PPabaoS6kk%2bnW2NCo%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "pxLSDRI8NzK%2b%2fDnDKNllHyi5WlJxqLSVxwuE6cJE04U%3d",
      "team": "Cryptic",
      "score": "13",
      "oppScore": "12",
      "opp": "Enigma",
      "oppId": "vGMVLiic34oSyMk%2bETpVqMkuexU7MBkROOjGtFdKoVM%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "HnYzHz2JY3nWbFgkqCGKPLWdr3F5PbEq3J8p4i%2bfOBQ%3d",
      "team": "Cryptic",
      "score": "13",
      "oppScore": "5",
      "opp": "Rubicon Rapids",
      "oppId": "%2bIdRZdPfxa07Yaa3PzfmJw5so76rO6A7JVJAQXOY%2b40%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "QirYaEcv9o0zyl7MFqWXazfuo8q%2bg72%2fMjROjWwSNEs%3d",
      "team": "Cryptic",
      "score": "13",
      "oppScore": "10",
      "opp": "MKE",
      "oppId": "qHyFAYrlLVWh07Z9rgHqv1nh7SsCt5k4p8Ri0Bna7Zc%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "dpxEyuGppCjB%2bXB81P%2bNvPWCmnaFmK2XM3GUm5KCaKI%3d",
      "team": "Cryptic",
      "score": "13",
      "oppScore": "7",
      "opp": "Beacon",
      "oppId": "4n5jY6eFSRuptn3xrctxCFjAy7g9dqmJM2uPNhY3qbk%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "0mq8Z7tpJN9PPVJJL0%2bFNbKdx3A3Rwo%2bdGhvxcYXzNc%3d",
      "team": "Cryptic",
      "score": "6",
      "oppScore": "13",
      "opp": "Scythe",
      "oppId": "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "aFvOAezFczvqwhXAWeediU0DfGK3UmsI2%2fGS7%2fvXwhI%3d",
      "team": "Cryptic",
      "score": "8",
      "oppScore": "7",
      "opp": "Kansas City Smokestack",
      "oppId": "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "1PBYZ0EMVAIj8Lzdo66okytkMwMZz%2fLVz6Zm68VGz%2fY%3d",
      "team": "Cryptic",
      "score": "10",
      "oppScore": "13",
      "opp": "DeMo",
      "oppId": "FdlFpUSGIahm89rZTXrHg2K85ugGqml2fjUWEPHtgPo%3d"
    }
  ],
  "cfApDIqN4eoe%2fr26h%2b8fQj3s9XKGa9nJC8lT5uya7gM%3d": [
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "Qb2RtUSIdZ%2bJOQefp1X6gYWKqpf%2bACAJhLEU8H9SPPo%3d",
      "team": "Overdrive",
      "score": "6",
      "oppScore": "15",
      "opp": "Kansas City Smokestack",
      "oppId": "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "YsIW8XOjBBEtjr4DKv6hD%2b%2bxHbrLjE%2fBvYzQ1B7YNfs%3d",
      "team": "Overdrive",
      "score": "5",
      "oppScore": "13",
      "opp": "Nomads",
      "oppId": "clyQEvW53ITGsU%2bGFSkoVd77O856fO%2f%2fPStXV2bXfy4%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "00o5rz8Yvr8X1u0FBqz8sbR8X5WlY0P1lFz0hSzu%2b8A%3d",
      "team": "Overdrive",
      "score": "13",
      "oppScore": "15",
      "opp": "Meadowlark",
      "oppId": "5mFdu3Xc%2bl7OoxB5yBc9Q8%2bCImG%2b%2fOFvdvmpUhgLwdo%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "SJBvNoPh5m8iQb6hME83OZtoeDWZOkjHAujJ%2f%2fp6shY%3d",
      "team": "Overdrive",
      "score": "4",
      "oppScore": "15",
      "opp": "Dreadnought",
      "oppId": "uzGm2kYsw4N4NvAkRAEeLbHAJE108B9cHHBgy%2bR99xE%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "Qtxn5inCCi86tkbZFfpZtXycc79G2I4I5WQ01VZzvKY%3d",
      "team": "Overdrive",
      "score": "6",
      "oppScore": "12",
      "opp": "NOx",
      "oppId": "YLXPz%2fCNrXaNTSa14rMcZI2zV%2brkt0P1fA0UxcJlPaE%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "3LKlY5OEyZoY%2fRLj7Ypvtnfbrm8QNQh0XNJcG6N9C%2bI%3d",
      "team": "Overdrive",
      "score": "13",
      "oppScore": "11",
      "opp": "Trident II",
      "oppId": "b69A0xfMxQKwR01A7jfOqdv1wbChbTxRp5jko3aOCXk%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "CeS6BhsdbfSh%2bcaTX1dZF9w%2fvg8%2b%2fYZCleF4ijSA6ak%3d",
      "team": "Overdrive",
      "score": "5",
      "oppScore": "13",
      "opp": "Scythe",
      "oppId": "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "4c5T%2b4vlGIqrka4Mug1wCkGxWZmnDL4TwWG3A2GUffw%3d",
      "team": "Overdrive",
      "score": "2",
      "oppScore": "13",
      "opp": "Kansas City Smokestack",
      "oppId": "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "PmYwPLEewmgr%2bro315tQpxkC0qquCef8QZxzPITcFWo%3d",
      "team": "Overdrive",
      "score": "6",
      "oppScore": "12",
      "opp": "Mallard II",
      "oppId": "waPLoije6zVsjVUcOXTlGSFZ10PPabaoS6kk%2bnW2NCo%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "DOlopRQHskXsEmU7E6rbmwdXj%2ft%2fH0hCT%2bG1zEwYmpU%3d",
      "team": "Overdrive",
      "score": "10",
      "oppScore": "13",
      "opp": "NOx",
      "oppId": "YLXPz%2fCNrXaNTSa14rMcZI2zV%2brkt0P1fA0UxcJlPaE%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "Z5q5Px6t5GHnJ3xEIqNWAKs79O%2f6mB6y%2bVFA6PtrZGs%3d",
      "team": "Overdrive",
      "score": "9",
      "oppScore": "12",
      "opp": "Beacon",
      "oppId": "4n5jY6eFSRuptn3xrctxCFjAy7g9dqmJM2uPNhY3qbk%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "UcklxgduEO5UzA6N47BlgA1iyjBTBlpqPJszCaTvexU%3d",
      "team": "Overdrive",
      "score": "4",
      "oppScore": "13",
      "opp": "Mallard II",
      "oppId": "waPLoije6zVsjVUcOXTlGSFZ10PPabaoS6kk%2bnW2NCo%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "yaLNTT1XV9Zf9c6k6GlubEYGjldVXgzpYKdaovHRe0w%3d",
      "team": "Overdrive",
      "score": "10",
      "oppScore": "13",
      "opp": "Trident II",
      "oppId": "b69A0xfMxQKwR01A7jfOqdv1wbChbTxRp5jko3aOCXk%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "4uTSy3DjsWKu7Us3X8GvY%2fGOW90udRfeEzdnekrTXpo%3d",
      "team": "Overdrive",
      "score": "5",
      "oppScore": "13",
      "opp": "Enigma",
      "oppId": "vGMVLiic34oSyMk%2bETpVqMkuexU7MBkROOjGtFdKoVM%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "ijU4DbU6Zk5vQsGXLyAFvdpHkBYjdyoa5ajUV5WeAlY%3d",
      "team": "Overdrive",
      "score": "13",
      "oppScore": "6",
      "opp": "THE BODY",
      "oppId": "nyLl78Eg24EtJDKG0oHsU9e%2fg0hrOYqklm7h8m4R0Jo%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "9uX8U0Xb79CYninHv8s7vGnlmKl08Yby11ZWvvr0jwg%3d",
      "team": "Overdrive",
      "score": "13",
      "oppScore": "7",
      "opp": "Loaded Panda",
      "oppId": "8S%2f2XcUwQbLf0KM7yOmv3HEYXSrnLhAdwnHQvhMjxCY%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "2ze3YaApDsSqNTz6AbHpL4%2bHOx1iIbnpKwirB0WbosI%3d",
      "team": "Overdrive",
      "score": "11",
      "oppScore": "7",
      "opp": "Trident II",
      "oppId": "b69A0xfMxQKwR01A7jfOqdv1wbChbTxRp5jko3aOCXk%3d"
    }
  ],
  "8S%2f2XcUwQbLf0KM7yOmv3HEYXSrnLhAdwnHQvhMjxCY%3d": [
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "pc0zo678A%2bPwKXtrDOZAHUXl0NNjphqszktri%2flm%2brY%3d",
      "team": "Loaded Panda",
      "score": "15",
      "oppScore": "9",
      "opp": "THE BODY",
      "oppId": "nyLl78Eg24EtJDKG0oHsU9e%2fg0hrOYqklm7h8m4R0Jo%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "T5XVT3bUwzB28dGfBHK%2f7k4Lf1lssT5aHjICqDMsHnU%3d",
      "team": "Loaded Panda",
      "score": "4",
      "oppScore": "15",
      "opp": "DeMo",
      "oppId": "FdlFpUSGIahm89rZTXrHg2K85ugGqml2fjUWEPHtgPo%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "9070KOhaD4b%2bSLm5s%2bCKBuVLGtqxJpLJZr8zKu%2fPK3Y%3d",
      "team": "Loaded Panda",
      "score": "6",
      "oppScore": "15",
      "opp": "Mallard II",
      "oppId": "waPLoije6zVsjVUcOXTlGSFZ10PPabaoS6kk%2bnW2NCo%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "%2bjdeDMboXV3BITRffnBffNle2AQ5oiiLJP8VezfeKf4%3d",
      "team": "Loaded Panda",
      "score": "6",
      "oppScore": "15",
      "opp": "Cryptic",
      "oppId": "Qhc%2fzwjxDlH6UTqMQqHKE2ms56DaeAMWMHQJCBwAjIw%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "JcOWmprUG0jq%2fJ%2bKJjPlT3bAgmZlCYCVr63pfc8kAr0%3d",
      "team": "Loaded Panda",
      "score": "8",
      "oppScore": "15",
      "opp": "NOx",
      "oppId": "YLXPz%2fCNrXaNTSa14rMcZI2zV%2brkt0P1fA0UxcJlPaE%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "sRK9%2f4bKPS94Wnfc7pu5xTVQ%2bmKfntG8ypTYuN0n9q8%3d",
      "team": "Loaded Panda",
      "score": "8",
      "oppScore": "11",
      "opp": "Nomads",
      "oppId": "clyQEvW53ITGsU%2bGFSkoVd77O856fO%2f%2fPStXV2bXfy4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "MKRFGUD464ZLLF2V8Ln1EQxaftfcVqdcfwRmF%2fR%2fqTg%3d",
      "team": "Loaded Panda",
      "score": "2",
      "oppScore": "13",
      "opp": "Haymaker",
      "oppId": "F7zZjPQTPbFe%2fxIVicHJD2zRIOORpO5hSxfBuY5fxcQ%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "COAtsafvfs0RzxFtLAi%2fmVHlxFq%2bVNtgiBZRjJ8%2fEaA%3d",
      "team": "Loaded Panda",
      "score": "7",
      "oppScore": "13",
      "opp": "Nomads",
      "oppId": "clyQEvW53ITGsU%2bGFSkoVd77O856fO%2f%2fPStXV2bXfy4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "FQmtBluGVsRF83vZ5eupkfVTkKWw6fI49ha0GjIVmCM%3d",
      "team": "Loaded Panda",
      "score": "13",
      "oppScore": "9",
      "opp": "THE BODY",
      "oppId": "nyLl78Eg24EtJDKG0oHsU9e%2fg0hrOYqklm7h8m4R0Jo%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "j53rCFzdTfC5xKNk3p02jxML%2bz31fRNvcQ8yktzfW%2b0%3d",
      "team": "Loaded Panda",
      "score": "10",
      "oppScore": "13",
      "opp": "Chimney",
      "oppId": "jzVhyLybRfcABTsshmdvgsxYDSASMTHMGNde%2bkQTb4Y%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "OjNlhr1Qgd8RQuZ00GnTABNKybVF8olMjfj2QyCORb4%3d",
      "team": "Loaded Panda",
      "score": "10",
      "oppScore": "13",
      "opp": "Trident II",
      "oppId": "b69A0xfMxQKwR01A7jfOqdv1wbChbTxRp5jko3aOCXk%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "9uX8U0Xb79CYninHv8s7vGnlmKl08Yby11ZWvvr0jwg%3d",
      "team": "Loaded Panda",
      "score": "7",
      "oppScore": "13",
      "opp": "Overdrive",
      "oppId": "cfApDIqN4eoe%2fr26h%2b8fQj3s9XKGa9nJC8lT5uya7gM%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "1kmFSKRGUcnsW7Vg53xeSSExxInzAgz7%2b4%2fMBuJ0fyw%3d",
      "team": "Loaded Panda",
      "score": "11",
      "oppScore": "9",
      "opp": "THE BODY",
      "oppId": "nyLl78Eg24EtJDKG0oHsU9e%2fg0hrOYqklm7h8m4R0Jo%3d"
    }
  ],
  "FdlFpUSGIahm89rZTXrHg2K85ugGqml2fjUWEPHtgPo%3d": [
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "STUQaJL6u0Z7zvszJHspmAAJRI4H2Z7S%2bt6uksTJ22o%3d",
      "team": "DeMo",
      "score": "15",
      "oppScore": "3",
      "opp": "THE BODY",
      "oppId": "nyLl78Eg24EtJDKG0oHsU9e%2fg0hrOYqklm7h8m4R0Jo%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "T5XVT3bUwzB28dGfBHK%2f7k4Lf1lssT5aHjICqDMsHnU%3d",
      "team": "DeMo",
      "score": "15",
      "oppScore": "4",
      "opp": "Loaded Panda",
      "oppId": "8S%2f2XcUwQbLf0KM7yOmv3HEYXSrnLhAdwnHQvhMjxCY%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "EO4KT0JANjyFoT2bACwr0HlULIWG8cr7KxgZbJnSMnw%3d",
      "team": "DeMo",
      "score": "15",
      "oppScore": "11",
      "opp": "Cryptic",
      "oppId": "Qhc%2fzwjxDlH6UTqMQqHKE2ms56DaeAMWMHQJCBwAjIw%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "eWL4DLODuwGVqKDxm95X%2fU6ME%2bJwRr34nBcpzWi8%2fv4%3d",
      "team": "DeMo",
      "score": "13",
      "oppScore": "14",
      "opp": "Mallard II",
      "oppId": "waPLoije6zVsjVUcOXTlGSFZ10PPabaoS6kk%2bnW2NCo%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "EAwTDwpMOKzk1UQQrR%2fuyjDH9YjvgaeOROArudQB4Ww%3d",
      "team": "DeMo",
      "score": "15",
      "oppScore": "8",
      "opp": "Nomads",
      "oppId": "clyQEvW53ITGsU%2bGFSkoVd77O856fO%2f%2fPStXV2bXfy4%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "4Nta6PhRKY2822cS6trXqlGqgl2i0aEQkl76hrk6REk%3d",
      "team": "DeMo",
      "score": "13",
      "oppScore": "7",
      "opp": "NOx",
      "oppId": "YLXPz%2fCNrXaNTSa14rMcZI2zV%2brkt0P1fA0UxcJlPaE%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "ghOmqgZF8BzBUhJ1lt0CCiljJzpZJ19hZim7bKibl6g%3d",
      "team": "DeMo",
      "score": "13",
      "oppScore": "8",
      "opp": "Knights of Ni",
      "oppId": "dgH60oTrddgw%2bSl2xkIe%2fis%2fu1HY9ndk1MZTdN4OiAs%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "Nkoj8wgU2w30yqTcrxaWwIgVdejkDdFzLuoCDc0ASAs%3d",
      "team": "DeMo",
      "score": "13",
      "oppScore": "7",
      "opp": "DINGWOP",
      "oppId": "xZAGkPMHJ9VCCvgCVVn5WRxQtwy%2bmZ7Q%2bGsb4rLqQqE%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "%2fIOmO%2bZsXK4a%2ftIt4wUORNMSPupKdGUdf1V5dZtvJ9Y%3d",
      "team": "DeMo",
      "score": "10",
      "oppScore": "6",
      "opp": "Mallard II",
      "oppId": "waPLoije6zVsjVUcOXTlGSFZ10PPabaoS6kk%2bnW2NCo%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "eTntf9WRc1ly3X7TE6WfKcvs5kdYq%2fZDotDU9bpVJ8k%3d",
      "team": "DeMo",
      "score": "12",
      "oppScore": "8",
      "opp": "Cryptic",
      "oppId": "Qhc%2fzwjxDlH6UTqMQqHKE2ms56DaeAMWMHQJCBwAjIw%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "XGM9tuALhZCN%2fVMRcHfA%2fptRnW637G4QesS7dMXq0js%3d",
      "team": "DeMo",
      "score": "12",
      "oppScore": "10",
      "opp": "Scythe",
      "oppId": "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "i2Y3cTuXchwe4Ao65XR%2fOcekI1E9P2d6B3%2f6S8h8ucw%3d",
      "team": "DeMo",
      "score": "10",
      "oppScore": "12",
      "opp": "Kansas City Smokestack",
      "oppId": "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "Sz%2batzYFRMV4cFFtPXGvJU8yahShdhws7cEQMernWF8%3d",
      "team": "DeMo",
      "score": "13",
      "oppScore": "12",
      "opp": "Knights of Ni",
      "oppId": "dgH60oTrddgw%2bSl2xkIe%2fis%2fu1HY9ndk1MZTdN4OiAs%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "mNhi9%2bAtl75dpT%2b7falNtyfI%2bPP1s2I8OycDok5fqfo%3d",
      "team": "DeMo",
      "score": "13",
      "oppScore": "12",
      "opp": "Scythe",
      "oppId": "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "h5ogb%2bgP43WK%2bcnBcY6Z7VHcBP5UeJ8zJpe1xlJT%2fE0%3d",
      "team": "DeMo",
      "score": "12",
      "oppScore": "13",
      "opp": "Kansas City Smokestack",
      "oppId": "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "husq5IyfbEZs2mnbq2nyRZQwinBBSKsGuhoP4g%2bIvgs%3d",
      "team": "DeMo",
      "score": "7",
      "oppScore": "11",
      "opp": "Haymaker",
      "oppId": "F7zZjPQTPbFe%2fxIVicHJD2zRIOORpO5hSxfBuY5fxcQ%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "3JxIjfLXe5Iey95cl8M9R4bohzgq%2b3BlQpxM1e30a%2bw%3d",
      "team": "DeMo",
      "score": "13",
      "oppScore": "6",
      "opp": "HouSE",
      "oppId": "W3JpjYTNBVirvq%2bLhhgD1xswtN%2bDHXo2Y5uhwfm%2f450%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "1PBYZ0EMVAIj8Lzdo66okytkMwMZz%2fLVz6Zm68VGz%2fY%3d",
      "team": "DeMo",
      "score": "13",
      "oppScore": "10",
      "opp": "Cryptic",
      "oppId": "Qhc%2fzwjxDlH6UTqMQqHKE2ms56DaeAMWMHQJCBwAjIw%3d"
    }
  ],
  "nyLl78Eg24EtJDKG0oHsU9e%2fg0hrOYqklm7h8m4R0Jo%3d": [
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "pc0zo678A%2bPwKXtrDOZAHUXl0NNjphqszktri%2flm%2brY%3d",
      "team": "THE BODY",
      "score": "9",
      "oppScore": "15",
      "opp": "Loaded Panda",
      "oppId": "8S%2f2XcUwQbLf0KM7yOmv3HEYXSrnLhAdwnHQvhMjxCY%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "STUQaJL6u0Z7zvszJHspmAAJRI4H2Z7S%2bt6uksTJ22o%3d",
      "team": "THE BODY",
      "score": "3",
      "oppScore": "15",
      "opp": "DeMo",
      "oppId": "FdlFpUSGIahm89rZTXrHg2K85ugGqml2fjUWEPHtgPo%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "vHHJkexZaJ5wsG4e9nc8uIusZwQOJV7cVf85iQWtH4k%3d",
      "team": "THE BODY",
      "score": "7",
      "oppScore": "15",
      "opp": "Dreadnought",
      "oppId": "uzGm2kYsw4N4NvAkRAEeLbHAJE108B9cHHBgy%2bR99xE%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "c8AdIqAzAT9jmXVp5P4eriXtKoWhJpzLJ%2bybPmCkTVY%3d",
      "team": "THE BODY",
      "score": "8",
      "oppScore": "15",
      "opp": "STL Moonar",
      "oppId": "XslCVk8W7kUV6p1A54%2bck0Soa5yWKBsdq%2fdwbiJ%2f%2bkk%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "uuL5ou3A%2b8Bcu0R37yjw1%2b7dWR5MuvOUTM8lcJGBLPU%3d",
      "team": "THE BODY",
      "score": "15",
      "oppScore": "6",
      "opp": "Supercell",
      "oppId": "%2bhSpC0vxzjqumEjEEoGhDiOz5%2bUgihYtLrNXn407Tbg%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "dOEJgoREa4261YnIVnTBewSXipaDtHZbicJHCgb84J0%3d",
      "team": "THE BODY",
      "score": "11",
      "oppScore": "10",
      "opp": "Meadowlark",
      "oppId": "5mFdu3Xc%2bl7OoxB5yBc9Q8%2bCImG%2b%2fOFvdvmpUhgLwdo%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "XVHfJiGFtu0UAFoZ0l6rRTw3TGZV4A6x%2b3m6dKmKRnM%3d",
      "team": "THE BODY",
      "score": "8",
      "oppScore": "13",
      "opp": "Trident II",
      "oppId": "b69A0xfMxQKwR01A7jfOqdv1wbChbTxRp5jko3aOCXk%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "dyQD0glN2qc6ZAskg4h%2bjlAGTjYwI0uW6cxruhGZM%2fw%3d",
      "team": "THE BODY",
      "score": "5",
      "oppScore": "13",
      "opp": "NOx",
      "oppId": "YLXPz%2fCNrXaNTSa14rMcZI2zV%2brkt0P1fA0UxcJlPaE%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "MzDs3T5UBt%2fNUOfI2yq8v3MzZa2j%2f8XmJNirWCiqaxI%3d",
      "team": "THE BODY",
      "score": "1",
      "oppScore": "13",
      "opp": "Kansas City Smokestack",
      "oppId": "QZG2gvHNEmxP%2fLLNK21vH6vfNBaRJUi%2bIuc5dbIrwiQ%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "YkW00%2f0sWgkB4mOpO3ZevBXjJq4%2ft2JnMNNilSYTrbM%3d",
      "team": "THE BODY",
      "score": "8",
      "oppScore": "13",
      "opp": "DINGWOP",
      "oppId": "xZAGkPMHJ9VCCvgCVVn5WRxQtwy%2bmZ7Q%2bGsb4rLqQqE%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "e6qQHltu8UyuIyu%2b2Fb32%2bXR9AmgsuAaeXxuRHgv72k%3d",
      "team": "THE BODY",
      "score": "12",
      "oppScore": "13",
      "opp": "NOMAD",
      "oppId": "RFpmXY6FT7eRry4gGUAnkSywT92Q24c%2fMw5CrinbhpI%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "ZLWQtUEKQWhTnQALtIMy5cdq%2bE9yowD%2fLYKK7KM0nRI%3d",
      "team": "THE BODY",
      "score": "9",
      "oppScore": "7",
      "opp": "Trident II",
      "oppId": "b69A0xfMxQKwR01A7jfOqdv1wbChbTxRp5jko3aOCXk%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "5Grc5t%2fdYaMzcBWOw5iEB8LpR5X8f4zhwtil8oWn14c%3d",
      "team": "THE BODY",
      "score": "7",
      "oppScore": "13",
      "opp": "Nomads",
      "oppId": "clyQEvW53ITGsU%2bGFSkoVd77O856fO%2f%2fPStXV2bXfy4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "o0vhI%2bVrCs0sqQlHCehhXWMnm20Ku7as76MYNCCLnsA%3d",
      "team": "THE BODY",
      "score": "5",
      "oppScore": "13",
      "opp": "Haymaker",
      "oppId": "F7zZjPQTPbFe%2fxIVicHJD2zRIOORpO5hSxfBuY5fxcQ%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "FQmtBluGVsRF83vZ5eupkfVTkKWw6fI49ha0GjIVmCM%3d",
      "team": "THE BODY",
      "score": "9",
      "oppScore": "13",
      "opp": "Loaded Panda",
      "oppId": "8S%2f2XcUwQbLf0KM7yOmv3HEYXSrnLhAdwnHQvhMjxCY%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "%2bmXR4n27t2Wi8URNcSFEFCiD9g30DrcxZc7XhzK%2bSaY%3d",
      "team": "THE BODY",
      "score": "6",
      "oppScore": "13",
      "opp": "Timber",
      "oppId": "KEdthZtu%2ffyLTQQr6Y655G91Wz0j0VrQj7Ap2G5YBOM%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "ijU4DbU6Zk5vQsGXLyAFvdpHkBYjdyoa5ajUV5WeAlY%3d",
      "team": "THE BODY",
      "score": "6",
      "oppScore": "13",
      "opp": "Overdrive",
      "oppId": "cfApDIqN4eoe%2fr26h%2b8fQj3s9XKGa9nJC8lT5uya7gM%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "%2bjKwxLW5xtWExVSDs0kCbhhFY0GlF3Mjt%2bZoUO0rvAI%3d",
      "team": "THE BODY",
      "score": "2",
      "oppScore": "13",
      "opp": "Trident II",
      "oppId": "b69A0xfMxQKwR01A7jfOqdv1wbChbTxRp5jko3aOCXk%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "1kmFSKRGUcnsW7Vg53xeSSExxInzAgz7%2b4%2fMBuJ0fyw%3d",
      "team": "THE BODY",
      "score": "9",
      "oppScore": "11",
      "opp": "Loaded Panda",
      "oppId": "8S%2f2XcUwQbLf0KM7yOmv3HEYXSrnLhAdwnHQvhMjxCY%3d"
    }
  ],
  "XslCVk8W7kUV6p1A54%2bck0Soa5yWKBsdq%2fdwbiJ%2f%2bkk%3d": [
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "zrZU6eexIUaH4qYtTNR4f%2bBSOvlQ2BC5ztCSQC25q7o%3d",
      "team": "STL Moonar",
      "score": "7",
      "oppScore": "15",
      "opp": "Scythe",
      "oppId": "75XZVA02B%2bIAoKl%2f3Ay3RiNzRpHS5NPt2GZkWSk88bg%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "hyFaHkYKrAIn3D5Ae4naJAxQtZJ96K%2fpl1lasxfOdSo%3d",
      "team": "STL Moonar",
      "score": "15",
      "oppScore": "4",
      "opp": "Supercell",
      "oppId": "%2bhSpC0vxzjqumEjEEoGhDiOz5%2bUgihYtLrNXn407Tbg%3d"
    },
    {
      "date": "July 09",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "aG2DeXbE%2bUMIAUskFxtBP3akoKJbT5knNd69TyEfGGY%3d",
      "team": "STL Moonar",
      "score": "11",
      "oppScore": "15",
      "opp": "NOx",
      "oppId": "YLXPz%2fCNrXaNTSa14rMcZI2zV%2brkt0P1fA0UxcJlPaE%3d"
    },
    {
      "date": "July 10",
      "tournament": "The Royal Experience 2022",
      "tournamentId": "The-Royal-Experience-2022",
      "gameId": "c8AdIqAzAT9jmXVp5P4eriXtKoWhJpzLJ%2bybPmCkTVY%3d",
      "team": "STL Moonar",
      "score": "15",
      "oppScore": "8",
      "opp": "THE BODY",
      "oppId": "nyLl78Eg24EtJDKG0oHsU9e%2fg0hrOYqklm7h8m4R0Jo%3d"
    }
  ],
  "XgsOT%2bl5FkWgZoV7cQAy4vMUDJS3ygcjNozHGHsTYUE%3d": [
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "Snauo1xvLBrLXZefXtLuQ4OYrLEKoQZBNIPUcIJi3dE%3d",
      "team": "Battery",
      "score": "7",
      "oppScore": "12",
      "opp": "Inception",
      "oppId": "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "xe9xC62Cc%2f82A1YxwB0wPti89tJLawNbkJlnded%2b%2f%2fo%3d",
      "team": "Battery",
      "score": "5",
      "oppScore": "14",
      "opp": "General Strike",
      "oppId": "%2bAa3yq8xXOgWBEYZi%2fAT%2fiiGNJfFLcQeXn3x6sH%2fmhA%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "m1jRa8yPVq2Lqd%2feK9RpgyffrdIFoXITp87Ni4Fdsy4%3d",
      "team": "Battery",
      "score": "8",
      "oppScore": "15",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "2PzsO5YveB%2fFjM8tFCMGdbqcBfULyMz%2favNr6EU%2bZMI%3d",
      "team": "Battery",
      "score": "8",
      "oppScore": "10",
      "opp": "Zyzzyva",
      "oppId": "Yfu4RKEHOC158Q5KQGT5xHxtk5hGHKp2RxFoXMEKk%2fA%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "HFvjpHBo81wVvfSc%2bOTpA3l3YW5Jax6VBLlJre4ft4U%3d",
      "team": "Battery",
      "score": "6",
      "oppScore": "14",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "DNBB0poCnAulitUeBAr7Cs0%2bBk6wiY1wCuTbvMNZz6s%3d",
      "team": "Battery",
      "score": "9",
      "oppScore": "12",
      "opp": "Flash Flood",
      "oppId": "JQrYQgb4YJNC2aMynOWjHiZmxJvsuqeLyz2Red9EabU%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "0kpoz5I6jL%2fHH5V3Y7C41vxu2CBn261NV6PeMLHrtUg%3d",
      "team": "Battery",
      "score": "10",
      "oppScore": "11",
      "opp": "Hazard",
      "oppId": "P0N8mJF5hP7HulHfG72Zdu2tlioAZAaLyhUwPE4s6T8%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "cciJiWqRkpQDUCFUU1iXDygvV02OqaucSrjP2wBIe4M%3d",
      "team": "Battery",
      "score": "5",
      "oppScore": "13",
      "opp": "OAT",
      "oppId": "3jrAxF6U290JZyiuGff9bkOyKPIspXXtN94jCffcvgs%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "O8JQz9zRKHhWfNNjVnVyu2iBc0XL0biguAEy%2fyoaV%2fw%3d",
      "team": "Battery",
      "score": "6",
      "oppScore": "11",
      "opp": "Sawtooth",
      "oppId": "O%2fOvOBARFYfl3iP1PCdqMwd9SiYSMEiR%2b0E8MZeOOD4%3d"
    },
    {
      "date": "July 30",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "RItpzOyVgFW7L8in1fwAuJHyy%2b6ao1qhPug2M42hVxs%3d",
      "team": "Battery",
      "score": "9",
      "oppScore": "10",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "9Pp8h9K6LymxnMSc5%2b%2fFxwnXMcrldBOTuLoV7Jal0Q8%3d",
      "team": "Battery",
      "score": "7",
      "oppScore": "15",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "July 31",
      "tournament": "TCT Select Flight Invite   West 2022",
      "tournamentId": "TCT-Select-Flight-Invite---West-2022",
      "gameId": "R7PcAUFAMtE0bA8%2fP8%2bXbctuQxerVeo%2fxtndBIYYN6E%3d",
      "team": "Battery",
      "score": "7",
      "oppScore": "13",
      "opp": "Switchback",
      "oppId": "XNBtJjuPsH6Zq%2bmVmPwL1Zo0%2bl5xYeh3mWrrpzn7CTk%3d"
    },
    {
      "date": "August 20",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "ozNQ4FHZ4NtMMLTljJe3yPAhzDclyz2gH5sgfupVeUM%3d",
      "team": "Battery",
      "score": "13",
      "oppScore": "15",
      "opp": "OAT",
      "oppId": "3jrAxF6U290JZyiuGff9bkOyKPIspXXtN94jCffcvgs%3d"
    },
    {
      "date": "August 20",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "IJXZdRdzvlWZdm00VJktDzgSZMuQ7q9H9a6Cxv1sc%2bU%3d",
      "team": "Battery",
      "score": "6",
      "oppScore": "15",
      "opp": "Emerald City",
      "oppId": "m4Q4f0lvofIPyQTpoi5YZ5nNQ2WH52F0MziY%2fWjcttE%3d"
    },
    {
      "date": "August 20",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "kqHXihiaZZ3mLVt8YeBdukjyvfwMHUuvs%2bqZteMD0NE%3d",
      "team": "Battery",
      "score": "7",
      "oppScore": "15",
      "opp": "Revolver",
      "oppId": "Kem%2foxDeD8r5XN1HNW8eHeRyzfBqnWTRaPCmLck6F1I%3d"
    },
    {
      "date": "August 21",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "plAsYmNhx5vfq6UcqtjI5VY92bIZDt2%2bnfVNCWlDuGY%3d",
      "team": "Battery",
      "score": "4",
      "oppScore": "15",
      "opp": "Furious George",
      "oppId": "AyYWHH3tsm9Kguy8GVmoEwGKAXpOtKzKP1KhenuZxfY%3d"
    },
    {
      "date": "August 21",
      "tournament": "Bay Area Tournament",
      "tournamentId": "Bay-Area-Tournament",
      "gameId": "f9zgwzZOXe5JrDY0%2foZgfvKwi4x%2bUqmCVm3tcfKaVxA%3d",
      "team": "Battery",
      "score": "4",
      "oppScore": "15",
      "opp": "OAT",
      "oppId": "3jrAxF6U290JZyiuGff9bkOyKPIspXXtN94jCffcvgs%3d"
    }
  ],
  "7MICo92AgV%2flT4d8dpq%2bhDNyJFhVtTVq8%2f94L7J8idw%3d": [
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "1TU3MJt7ZhvX9EO%2fvFqo2bORaakKklEK8QvVsnMtqi0%3d",
      "team": "Tanasi",
      "score": "12",
      "oppScore": "14",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "2TtwLRT9A43LsKBYpfgoWt0lADrnr3lmhffFLmuDyNk%3d",
      "team": "Tanasi",
      "score": "13",
      "oppScore": "5",
      "opp": "Clutch",
      "oppId": "JDc%2bBsCjGUX7gdebRqmdPvq1u2rFlZ1giM6BgTaL5Lk%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "nGcgHgOcn7FflcI5lxwdxtHG7cvN%2fn4WG%2f6qEJIMd34%3d",
      "team": "Tanasi",
      "score": "14",
      "oppScore": "6",
      "opp": "Flash Flood",
      "oppId": "JQrYQgb4YJNC2aMynOWjHiZmxJvsuqeLyz2Red9EabU%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "JT%2f%2fy8ZYODmYWKaKlHzhylRKln04a2kyE2iaek2oXj4%3d",
      "team": "Tanasi",
      "score": "15",
      "oppScore": "12",
      "opp": "GOAT",
      "oppId": "6IFGDipIDG9l%2br4E8TUyujjAslzlgFQUH9y5j9M0G4g%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "tY%2fUqqVW%2frRvz7KvC6fOOU0zy%2bGVMVWNtBJDpZeAfyE%3d",
      "team": "Tanasi",
      "score": "10",
      "oppScore": "15",
      "opp": "Johnny Bravo",
      "oppId": "AKF9iH0VKK2mK%2fzpE9qJYeqGR%2ff%2fBETu1dm16hUGaVo%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "cuwSGjwS3b4UFha98hiHDs4bZgBp5wzjoGCqn2aqoZQ%3d",
      "team": "Tanasi",
      "score": "7",
      "oppScore": "14",
      "opp": "SoCal Condors",
      "oppId": "Hs8IYCPj2pf9Nump3cK24FlAdOgCZVYhTQKGBSXFLXA%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "mJ2AzbCyxq89P3gI5St6mbvwTk%2boGuSH6tTdegW6rKY%3d",
      "team": "Tanasi",
      "score": "13",
      "oppScore": "12",
      "opp": "Phantom",
      "oppId": "Hc0As%2bNg1YaD%2fyL3LwaIEtSMTc23KxHOibI0ZwQLDa4%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "rByi0pjJp1QXi%2fsv4ElU64ocPtKSffnsVFlBGNNuuDE%3d",
      "team": "Tanasi",
      "score": "14",
      "oppScore": "4",
      "opp": "Mallard",
      "oppId": "iIrscp1ZOpIpCSkyCbUNt0Vq3FobvpA18ZWbPM63hJo%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "1m5ZQyC4YFk1%2fRbxgvGO73kvbEoCxyBEKkxmhWVzQbg%3d",
      "team": "Tanasi",
      "score": "11",
      "oppScore": "8",
      "opp": "Trident I",
      "oppId": "HJQp9EgoBkW9LmipJ4BOA7JQ%2bJ%2fsSTavaeYtiM6KQus%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "DPHOuG%2fICQV3OJD1zm1t0nz2zUwbwFXf7mV4Kugf%2f5k%3d",
      "team": "Tanasi",
      "score": "10",
      "oppScore": "7",
      "opp": "Garden State Ultimate",
      "oppId": "rv5RwYIoNIY0EqGzDkizdJwIJ%2bLTiDa65wF3GyKDUjo%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "W2x7walRamnEBYMQ26WXxGvlRnuceKsCi72F87FczHE%3d",
      "team": "Tanasi",
      "score": "9",
      "oppScore": "10",
      "opp": "Mad Men",
      "oppId": "Al%2fA4sj9NEIvG2pfoVeKR8OCQL%2fREoXEJcD3sZnI49A%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "reHUm8VYOrWd3CNw9BwMQ1qt0FVTCavNYmkamGu8Av0%3d",
      "team": "Tanasi",
      "score": "10",
      "oppScore": "9",
      "opp": "Trident I",
      "oppId": "HJQp9EgoBkW9LmipJ4BOA7JQ%2bJ%2fsSTavaeYtiM6KQus%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "2PcpbbKca62X%2f4QHbVnb4Lrj5Ut%2fIrM%2bH5SSjnJDg5o%3d",
      "team": "Tanasi",
      "score": "15",
      "oppScore": "4",
      "opp": "Inception",
      "oppId": "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "DhsrlUBo6%2bAPkV9g1JGFCjrh0WrdMz73J6%2bETlg2lE8%3d",
      "team": "Tanasi",
      "score": "12",
      "oppScore": "13",
      "opp": "Colonels",
      "oppId": "7Xv2AHNIg3Y%2fm1y7qWkHUz%2b9KT3NZFOCMpW7gBaxnfs%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "1m2exN8rxyooCRyn8KDp5SNvXdqraiBJ%2bXnj2Bu2wtE%3d",
      "team": "Tanasi",
      "score": "14",
      "oppScore": "11",
      "opp": "Phoenix",
      "oppId": "gSiJoSXGsIFqzcdqVTCYpgvQv7XMC%2b0qfhx6EDDLgrQ%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "fMro7mjvtApIRlmb5clk6zzQuCuSBwDfsDau3pbF0fI%3d",
      "team": "Tanasi",
      "score": "9",
      "oppScore": "10",
      "opp": "Vault",
      "oppId": "3xV6rIwyIem%2bql5tz%2bZmFG9gr%2bp6WKu5k4q5bwFcSp0%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "H5qQ8zgpm3feVMASFl9C9kGG5qfaiAIU3WejrV%2fmdRY%3d",
      "team": "Tanasi",
      "score": "12",
      "oppScore": "9",
      "opp": "Garden State Ultimate",
      "oppId": "rv5RwYIoNIY0EqGzDkizdJwIJ%2bLTiDa65wF3GyKDUjo%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "3sviqyX88ubx2N8IfFtLXGUYrTTUzwziwKiP6f75daw%3d",
      "team": "Tanasi",
      "score": "13",
      "oppScore": "11",
      "opp": "Mad Men",
      "oppId": "Al%2fA4sj9NEIvG2pfoVeKR8OCQL%2fREoXEJcD3sZnI49A%3d"
    }
  ],
  "Hs8IYCPj2pf9Nump3cK24FlAdOgCZVYhTQKGBSXFLXA%3d": [
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "EqyYDlRv039IO%2fpWWtc1HoaHeojLz2AokhgM0qDockY%3d",
      "team": "SoCal Condors",
      "score": "15",
      "oppScore": "2",
      "opp": "Ghost Train",
      "oppId": "Tqxb9%2fb2Hxtbg%2f54ifErhjS%2bc%2fBnacDZk5RhQ%2fd%2b%2f5g%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "WlFx51PnXJtnVf3lc0KzutOp8hP4tQIbZLwa%2f9iuGI8%3d",
      "team": "SoCal Condors",
      "score": "12",
      "oppScore": "14",
      "opp": "Revolver",
      "oppId": "Kem%2foxDeD8r5XN1HNW8eHeRyzfBqnWTRaPCmLck6F1I%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "Fy0K7aKRaYKjZ9kIoxxKTiYAhlznHAqG%2f3NT85nXI8g%3d",
      "team": "SoCal Condors",
      "score": "15",
      "oppScore": "9",
      "opp": "Zyzzyva",
      "oppId": "Yfu4RKEHOC158Q5KQGT5xHxtk5hGHKp2RxFoXMEKk%2fA%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "o4ZRywMiRsxsdm9XatOT0GUjz3xAgg9UHPAhsasbtMc%3d",
      "team": "SoCal Condors",
      "score": "15",
      "oppScore": "10",
      "opp": "Inception",
      "oppId": "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "t8dznbnaMCM6ZpXJeZk%2bkCLNyjN1L%2b2lkFkdDVEKMEI%3d",
      "team": "SoCal Condors",
      "score": "9",
      "oppScore": "12",
      "opp": "Revolver",
      "oppId": "Kem%2foxDeD8r5XN1HNW8eHeRyzfBqnWTRaPCmLck6F1I%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "cuwSGjwS3b4UFha98hiHDs4bZgBp5wzjoGCqn2aqoZQ%3d",
      "team": "SoCal Condors",
      "score": "14",
      "oppScore": "7",
      "opp": "Tanasi",
      "oppId": "7MICo92AgV%2flT4d8dpq%2bhDNyJFhVtTVq8%2f94L7J8idw%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "a1",
      "team": "SoCal Condors",
      "score": "12",
      "oppScore": "13",
      "opp": "Sockeye",
      "oppId": "Ur5qIXbyxiUHKLhvlB%2bSYJDI9nx7KM4Txz1fUtmqC2w%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "a3",
      "team": "SoCal Condors",
      "score": "15",
      "oppScore": "10",
      "opp": "Doublewide",
      "oppId": "Nm8EGFzg2L2gF7kgWvfP7%2bbHNWDz7Dq77ckjxxzanlg%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "pq4",
      "team": "SoCal Condors",
      "score": "12",
      "oppScore": "14",
      "opp": "Rhino Slam!",
      "oppId": "oAJ4T9WLKOA4ICTPMYYK1645dFdRoI5WhU60sNRZIMk%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "e2",
      "team": "SoCal Condors",
      "score": "8",
      "oppScore": "15",
      "opp": "Doublewide",
      "oppId": "Nm8EGFzg2L2gF7kgWvfP7%2bbHNWDz7Dq77ckjxxzanlg%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "e3",
      "team": "SoCal Condors",
      "score": "10",
      "oppScore": "15",
      "opp": "Pittsburgh Temper",
      "oppId": "u%2bSlJ3XL7d589O5YW%2bbHhuzzJ855SfIO%2bGHlzwH3w3M%3d"
    },
    {
      "date": "September 05",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "e6",
      "team": "SoCal Condors",
      "score": "15",
      "oppScore": "10",
      "opp": "Sub Zero",
      "oppId": "8R4mmVt8rvJ%2bEDsQVxGD5KkmF8Rfnl3EHt4CpzxANac%3d"
    },
  ],
  "AKF9iH0VKK2mK%2fzpE9qJYeqGR%2ff%2fBETu1dm16hUGaVo%3d": [
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "HaMRuplGKJbh2vNw4bsYIqtyX%2bNQFQ7c0BNBebjxk3E%3d",
      "team": "Johnny Bravo",
      "score": "15",
      "oppScore": "6",
      "opp": "Zyzzyva",
      "oppId": "Yfu4RKEHOC158Q5KQGT5xHxtk5hGHKp2RxFoXMEKk%2fA%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "RcD0kShg6DLqkv2rOOoINMzK%2fcd%2fMKNnk5Rga%2f0nCNU%3d",
      "team": "Johnny Bravo",
      "score": "15",
      "oppScore": "10",
      "opp": "GOAT",
      "oppId": "6IFGDipIDG9l%2br4E8TUyujjAslzlgFQUH9y5j9M0G4g%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "Fhb0CLjmowO3BG3NkwQrh48x0kWjQhMz4%2fJNMc9hVD4%3d",
      "team": "Johnny Bravo",
      "score": "14",
      "oppScore": "13",
      "opp": "Revolver",
      "oppId": "Kem%2foxDeD8r5XN1HNW8eHeRyzfBqnWTRaPCmLck6F1I%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "308osjRVRqduX%2fuVVdf4BaT8ljh9C8sEk2KHrHrQ0CY%3d",
      "team": "Johnny Bravo",
      "score": "15",
      "oppScore": "6",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "tY%2fUqqVW%2frRvz7KvC6fOOU0zy%2bGVMVWNtBJDpZeAfyE%3d",
      "team": "Johnny Bravo",
      "score": "15",
      "oppScore": "10",
      "opp": "Tanasi",
      "oppId": "7MICo92AgV%2flT4d8dpq%2bhDNyJFhVtTVq8%2f94L7J8idw%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "6vR6Iy%2fFALg6kV7KFR3TifOwx4E%2fKogJWShg1%2befUdo%3d",
      "team": "Johnny Bravo",
      "score": "15",
      "oppScore": "13",
      "opp": "Revolver",
      "oppId": "Kem%2foxDeD8r5XN1HNW8eHeRyzfBqnWTRaPCmLck6F1I%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "JJ0LlgqfxrsYGzQYQyP2oMTGGYEK4Qt%2bytAEHSmTV3I%3d",
      "team": "Johnny Bravo",
      "score": "15",
      "oppScore": "9",
      "opp": "Pittsburgh Temper",
      "oppId": "u%2bSlJ3XL7d589O5YW%2bbHhuzzJ855SfIO%2bGHlzwH3w3M%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "O3U5HjOllL6uXxWOfRN5WgXikXdWyCzxFqcm%2bnRJIco%3d",
      "team": "Johnny Bravo",
      "score": "12",
      "oppScore": "10",
      "opp": "Sprout",
      "oppId": "QyN2sOWwUrO10Eheq9tje8Trmro0kiO6dKpnQzSh9q4%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "pZdRpcre2nRTPw4C8tBTb5STqXRlgKl3p5sAZnPj5a4%3d",
      "team": "Johnny Bravo",
      "score": "15",
      "oppScore": "12",
      "opp": "Truck Stop",
      "oppId": "pUj3i6rzWdnpW5TeEEoriE%2fRmJjK%2brKBLe8y4LGWtZY%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "gz4uO5107uFalrDvyiUi81jW5NfHWZhPGX0fJWd3dMg%3d",
      "team": "Johnny Bravo",
      "score": "15",
      "oppScore": "12",
      "opp": "Pando",
      "oppId": "CWW3TWk5yeYKuKEW8USBkbYZz5DyfbKjfswKBgSzYsA%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "bUMOagb%2bkNRUPFecQMgrkKnvk3fS1ytyQ9qRNnzIlMU%3d",
      "team": "Johnny Bravo",
      "score": "15",
      "oppScore": "13",
      "opp": "DiG",
      "oppId": "kn%2bHmtzxdjY7vxb%2fpJ9qsfEsWaDB6JrUBL7Ga4bU18M%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "Vri%2bPOrClzrKXZ%2b7mmV1fbSFkJ%2fEanpRnzIlHsqHnBQ%3d",
      "team": "Johnny Bravo",
      "score": "13",
      "oppScore": "14",
      "opp": "Truck Stop",
      "oppId": "pUj3i6rzWdnpW5TeEEoriE%2fRmJjK%2brKBLe8y4LGWtZY%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "b1",
      "team": "Johnny Bravo",
      "score": "15",
      "oppScore": "9",
      "opp": "Sub Zero",
      "oppId": "8R4mmVt8rvJ%2bEDsQVxGD5KkmF8Rfnl3EHt4CpzxANac%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "b2",
      "team": "Johnny Bravo",
      "score": "13",
      "oppScore": "12",
      "opp": "DiG",
      "oppId": "kn%2bHmtzxdjY7vxb%2fpJ9qsfEsWaDB6JrUBL7Ga4bU18M%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "cross2",
      "team": "Johnny Bravo",
      "score": "11",
      "oppScore": "15",
      "opp": "Truck Stop",
      "oppId": "pUj3i6rzWdnpW5TeEEoriE%2fRmJjK%2brKBLe8y4LGWtZY%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "q2",
      "team": "Johnny Bravo",
      "score": "12",
      "oppScore": "13",
      "opp": "PoNY",
      "oppId": "s8Hq5QrzeTvvFPbmIo%2bj5qGjiFRUrzVIivVUtEM%2bvd4%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "f1",
      "team": "Johnny Bravo",
      "score": "15",
      "oppScore": "11",
      "opp": "Chicago Machine",
      "oppId": "a63rCMScaUnSuWtwLw1rcbPhZh4%2bUKiYzBab%2b52i9Pk%3d"
    },
    {
      "date": "September 05",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "fifth",
      "team": "Johnny Bravo",
      "score": "11",
      "oppScore": "12",
      "opp": "Rhino Slam!",
      "oppId": "oAJ4T9WLKOA4ICTPMYYK1645dFdRoI5WhU60sNRZIMk%3d"
    },
  ],
  "6IFGDipIDG9l%2br4E8TUyujjAslzlgFQUH9y5j9M0G4g%3d": [
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "o2tW9XudK%2fvHHB%2bDRcekUXKw2Ny5wH5D8Tdt16uv4hg%3d",
      "team": "GOAT",
      "score": "15",
      "oppScore": "8",
      "opp": "Flash Flood",
      "oppId": "JQrYQgb4YJNC2aMynOWjHiZmxJvsuqeLyz2Red9EabU%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "RcD0kShg6DLqkv2rOOoINMzK%2fcd%2fMKNnk5Rga%2f0nCNU%3d",
      "team": "GOAT",
      "score": "10",
      "oppScore": "15",
      "opp": "Johnny Bravo",
      "oppId": "AKF9iH0VKK2mK%2fzpE9qJYeqGR%2ff%2fBETu1dm16hUGaVo%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "DA1XcS4TQblKhJvS4IzGsCRh%2bKGYZU%2bmbh%2b8Dma8PXI%3d",
      "team": "GOAT",
      "score": "11",
      "oppScore": "10",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "JT%2f%2fy8ZYODmYWKaKlHzhylRKln04a2kyE2iaek2oXj4%3d",
      "team": "GOAT",
      "score": "12",
      "oppScore": "15",
      "opp": "Tanasi",
      "oppId": "7MICo92AgV%2flT4d8dpq%2bhDNyJFhVtTVq8%2f94L7J8idw%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "0rskRpQQNHY9z6VOiZjKIM48N%2fng6XxyikxdYBfGMO0%3d",
      "team": "GOAT",
      "score": "14",
      "oppScore": "8",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "zA2vDn3bSuqkXTnXCHMVoKlSifwSKOvFGOvkTDM4WZg%3d",
      "team": "GOAT",
      "score": "15",
      "oppScore": "9",
      "opp": "Clutch",
      "oppId": "JDc%2bBsCjGUX7gdebRqmdPvq1u2rFlZ1giM6BgTaL5Lk%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "8J9GTRqr41QEn3b7%2b1NonRigj71dct35vBHJKmymhK0%3d",
      "team": "GOAT",
      "score": "15",
      "oppScore": "6",
      "opp": "UpRoar",
      "oppId": "JYJdk5dDNlc6X6evPtQ6K9GWMwDy1xmzavYjS6uyHaE%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "k2rHd1AO2lpBUOsvGM44d4JtsRgCN0CewWJIaiAJmic%3d",
      "team": "GOAT",
      "score": "15",
      "oppScore": "9",
      "opp": "Clutch",
      "oppId": "JDc%2bBsCjGUX7gdebRqmdPvq1u2rFlZ1giM6BgTaL5Lk%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "0wTIY7C7vdnALiQCzL%2fMDMUEP2RyUC89G8BfaNGzNgQ%3d",
      "team": "GOAT",
      "score": "13",
      "oppScore": "10",
      "opp": "Mad Men",
      "oppId": "Al%2fA4sj9NEIvG2pfoVeKR8OCQL%2fREoXEJcD3sZnI49A%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "6ctM0k%2bJWIZAquwZG%2b9c5x2qaa7yEsf6OtX2M8seYfQ%3d",
      "team": "GOAT",
      "score": "14",
      "oppScore": "9",
      "opp": "Garden State Ultimate",
      "oppId": "rv5RwYIoNIY0EqGzDkizdJwIJ%2bLTiDa65wF3GyKDUjo%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "c1sBcFWUiYbc72HBh2jbpDKTtM%2fWVHXY979EYcoSgmk%3d",
      "team": "GOAT",
      "score": "13",
      "oppScore": "11",
      "opp": "Vault",
      "oppId": "3xV6rIwyIem%2bql5tz%2bZmFG9gr%2bp6WKu5k4q5bwFcSp0%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "aF6WQ6oCWEX%2fpEX2ge%2f5yU0wGUAGAjACtPwpr%2fq52g4%3d",
      "team": "GOAT",
      "score": "8",
      "oppScore": "13",
      "opp": "Omen",
      "oppId": "LvpOSyQd02uHlDLxvVMuw0fzNjwTUO8G7CpXAwQn40I%3d"
    }
  ],
  "%2bAa3yq8xXOgWBEYZi%2fAT%2fiiGNJfFLcQeXn3x6sH%2fmhA%3d": [
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "xe9xC62Cc%2f82A1YxwB0wPti89tJLawNbkJlnded%2b%2f%2fo%3d",
      "team": "General Strike",
      "score": "14",
      "oppScore": "5",
      "opp": "Battery",
      "oppId": "XgsOT%2bl5FkWgZoV7cQAy4vMUDJS3ygcjNozHGHsTYUE%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "oCxMXnJLJwO%2fRcEFabE5GECIh7%2bg4bra4yxUkIKTgoI%3d",
      "team": "General Strike",
      "score": "9",
      "oppScore": "10",
      "opp": "Inception",
      "oppId": "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d"
    },
    {
      "date": "July 09",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "CH8fu%2f0QviFWicxh%2bIOo6xNS2G%2fahxpZLZlufRnqjbY%3d",
      "team": "General Strike",
      "score": "12",
      "oppScore": "15",
      "opp": "Clutch",
      "oppId": "JDc%2bBsCjGUX7gdebRqmdPvq1u2rFlZ1giM6BgTaL5Lk%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "YUCrIdrqy0t8cFXWY%2bmelMJfujQrKsF%2fcXqjroNbCgc%3d",
      "team": "General Strike",
      "score": "10",
      "oppScore": "11",
      "opp": "Ghost Train",
      "oppId": "Tqxb9%2fb2Hxtbg%2f54ifErhjS%2bc%2fBnacDZk5RhQ%2fd%2b%2f5g%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "usKc1Rhj29llAUiKC36U%2f%2bbceZ2IU2FOkhoLW6gAjlg%3d",
      "team": "General Strike",
      "score": "10",
      "oppScore": "9",
      "opp": "Flash Flood",
      "oppId": "JQrYQgb4YJNC2aMynOWjHiZmxJvsuqeLyz2Red9EabU%3d"
    },
    {
      "date": "July 10",
      "tournament": "TCT Pro Elite Challenge (Colorado Cup) 2022",
      "tournamentId": "TCT-Pro-Elite-Challenge-Colorado-Cup-2022",
      "gameId": "moE8sVbW1jmFYEakBQ0INcaa1aEhcUPzwXHBZJlpmJc%3d",
      "team": "General Strike",
      "score": "14",
      "oppScore": "10",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    }
  ],
  "1zUV4kEpPc%2flA39WIyrp9j1w%2fhuIkgE6KlD9%2f%2ffQzg8%3d": [
    {
      "date": "July 10",
      "tournament": "MOB Open",
      "tournamentId": "MOB-Open",
      "gameId": "BasNc4oHSdnY1srYvq4Ksp5X0Am7XN1B0XymN%2bypfwU%3d",
      "team": "Oakgrove Boys",
      "score": "12",
      "oppScore": "9",
      "opp": "Redwolves",
      "oppId": "DoL%2frShNUJHM7OLFhcsMzWlsS0aohpfm30HvDbpVZi4%3d"
    },
    {
      "date": "July 10",
      "tournament": "MOB Open",
      "tournamentId": "MOB-Open",
      "gameId": "HJw9i0cXcbbSkmBDTn5rEeKXic0Jt%2bX9qOPP%2bEH2UK4%3d",
      "team": "Oakgrove Boys",
      "score": "13",
      "oppScore": "12",
      "opp": "Bomb Squad",
      "oppId": "GMU0OYY8eXqf1AR6M93JxRSXSpRgEq9sJxKUtkOjXgg%3d"
    },
    {
      "date": "July 10",
      "tournament": "MOB Open",
      "tournamentId": "MOB-Open",
      "gameId": "Xi0YowVm577Z2phq%2bi1Z34SuktBvVp4IiNUgpzX0cfU%3d",
      "team": "Oakgrove Boys",
      "score": "11",
      "oppScore": "8",
      "opp": "Winc City Fog of War",
      "oppId": "6gsiUt3INMB4Td2RTxJkksYpRIJsACnjptCfHUZeg2o%3d"
    },
    {
      "date": "July 10",
      "tournament": "MOB Open",
      "tournamentId": "MOB-Open",
      "gameId": "FHIpvtc8XfuwoEuYTc7A9i2GIysvFus55Q3YHnWh6XY%3d",
      "team": "Oakgrove Boys",
      "score": "12",
      "oppScore": "8",
      "opp": "MOB Ultimate",
      "oppId": "otuSqjNVhCJZE0ojAe0x6mw6GKk1Ux8ciM5ADgrPUEI%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "66BdzvQil2UbcSzeH%2bA6mHaDkSBkX6mvxOvk0P2kffk%3d",
      "team": "Oakgrove Boys",
      "score": "13",
      "oppScore": "6",
      "opp": "MOB Ultimate",
      "oppId": "otuSqjNVhCJZE0ojAe0x6mw6GKk1Ux8ciM5ADgrPUEI%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "yZq16%2bxhGAngFAKgNgefkAg92DjaI1FbviyE6XKK0ew%3d",
      "team": "Oakgrove Boys",
      "score": "13",
      "oppScore": "6",
      "opp": "JAWN",
      "oppId": "ZmiNobvozutOTlvBTZEmqu8bW1a0Lvp6PqGmIiNlkGU%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "b3VaEf4wWZC%2bi6tf9oz%2boyI%2bmaDdKsfWd%2fK0pUUqD%2bY%3d",
      "team": "Oakgrove Boys",
      "score": "11",
      "oppScore": "8",
      "opp": "Helots",
      "oppId": "m6Orv8C1KcDAyDCEIuhdmpNBKN41R0jWgAvkxa%2bLmOk%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "vE8BofnYNwdseEPlPYUl%2bMnFcpPyYXrs7S5QfHRIses%3d",
      "team": "Oakgrove Boys",
      "score": "10",
      "oppScore": "11",
      "opp": "Blueprint",
      "oppId": "BDhLSFxnViHodVQ9noIy0%2by0HWSmiJ3qgV5WI1ozsAk%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "nE4D0Q8EhLO2j4ShCoiqxYbxVR6FYhUDD5rAk2aZAtw%3d",
      "team": "Oakgrove Boys",
      "score": "13",
      "oppScore": "10",
      "opp": "Pittsburgh Stealers",
      "oppId": "icSGwphEBV6tcYJLRwPxEC22s0bt6rct5HG5ubjyetw%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "8UMAawBQP4CFaRFU6CM%2bvDS%2bo2dMfiLB2BbNyiLMs3g%3d",
      "team": "Oakgrove Boys",
      "score": "8",
      "oppScore": "9",
      "opp": "Big Wrench",
      "oppId": "cD5%2b6qwajeEeZXFAZqH%2f%2fSNl6zunDFFnMpdAAl7eKv0%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "8j5gpFUW48OF8Pa5lMJdNu12O9V3eXwBfZnen4Yi3M4%3d",
      "team": "Oakgrove Boys",
      "score": "6",
      "oppScore": "13",
      "opp": "Raleigh-Durham United",
      "oppId": "ESHhN%2bMK1oy0j%2bQJSL8mb9cq8h30Erb63T07U8pubEc%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "7N%2b0n44oGyWLRv6obEpX4nEhN94Aq2LooJl%2bYv6NYoQ%3d",
      "team": "Oakgrove Boys",
      "score": "13",
      "oppScore": "9",
      "opp": "Black Lung",
      "oppId": "olaZlUwWblgF%2b%2b2nRJgbWJ2tj6QzPm5aIO7kfbpnA58%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "Z4i0iNWfL%2fh8ZszTNof8keqALOQGd4baiDVvcTS6oCE%3d",
      "team": "Oakgrove Boys",
      "score": "13",
      "oppScore": "8",
      "opp": "Charleston Heat Stroke",
      "oppId": "quSAXJHtjRXSWAHbdztMDD8ftXgRqlTrsEfTBm793Nk%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "HL9wwk%2faI2ja2bGkQE9TyXo1cG47xb2m22Qy%2brdJs0A%3d",
      "team": "Oakgrove Boys",
      "score": "13",
      "oppScore": "6",
      "opp": "Space Cowboys",
      "oppId": "3%2bZ1uFiVej3zLASHWkgqXkJ7hNQ98n84b2FvR4T3pQA%3d"
    }
  ],
  "DoL%2frShNUJHM7OLFhcsMzWlsS0aohpfm30HvDbpVZi4%3d": [
    {
      "date": "July 10",
      "tournament": "MOB Open",
      "tournamentId": "MOB-Open",
      "gameId": "AntCXWM8FAe3eXz3Wk3R8Sxbdgt3g%2bydMGEHEf9ryqc%3d",
      "team": "Redwolves",
      "score": "13",
      "oppScore": "9",
      "opp": "MOB Ultimate",
      "oppId": "otuSqjNVhCJZE0ojAe0x6mw6GKk1Ux8ciM5ADgrPUEI%3d"
    },
    {
      "date": "July 10",
      "tournament": "MOB Open",
      "tournamentId": "MOB-Open",
      "gameId": "BasNc4oHSdnY1srYvq4Ksp5X0Am7XN1B0XymN%2bypfwU%3d",
      "team": "Redwolves",
      "score": "9",
      "oppScore": "12",
      "opp": "Oakgrove Boys",
      "oppId": "1zUV4kEpPc%2flA39WIyrp9j1w%2fhuIkgE6KlD9%2f%2ffQzg8%3d"
    },
    {
      "date": "July 10",
      "tournament": "MOB Open",
      "tournamentId": "MOB-Open",
      "gameId": "SVfsIjMqr%2fT2hRXPGyJCFf6k4SCheM8aishfLvGy7bA%3d",
      "team": "Redwolves",
      "score": "13",
      "oppScore": "5",
      "opp": "Winc City Fog of War",
      "oppId": "6gsiUt3INMB4Td2RTxJkksYpRIJsACnjptCfHUZeg2o%3d"
    },
    {
      "date": "July 10",
      "tournament": "MOB Open",
      "tournamentId": "MOB-Open",
      "gameId": "ZecpxoNftVB2QO8UpdRRM2fu4WznQZpV1xrMCOQynRU%3d",
      "team": "Redwolves",
      "score": "13",
      "oppScore": "9",
      "opp": "Bomb Squad",
      "oppId": "GMU0OYY8eXqf1AR6M93JxRSXSpRgEq9sJxKUtkOjXgg%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "ZQn%2bNqJMv3%2fSw%2b8oA0wxLSTYIACZrFxvbVw5rLhTXOc%3d",
      "team": "Redwolves",
      "score": "11",
      "oppScore": "7",
      "opp": "Mallard",
      "oppId": "iIrscp1ZOpIpCSkyCbUNt0Vq3FobvpA18ZWbPM63hJo%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "rpIUYU78sKrsvbElkh6x9BMtqSfkN0UJkdAqbH7UEE8%3d",
      "team": "Redwolves",
      "score": "11",
      "oppScore": "9",
      "opp": "Shade",
      "oppId": "Lh0BmORltuq%2bkEq6e8QTGFiUkZuG5GuP4DNNS5vaaAc%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "ONYKyMKUbUge22bR72Yn%2fpz7rYsgcHJ%2fmpnvGPg6VFs%3d",
      "team": "Redwolves",
      "score": "9",
      "oppScore": "13",
      "opp": "Mad Men",
      "oppId": "Al%2fA4sj9NEIvG2pfoVeKR8OCQL%2fREoXEJcD3sZnI49A%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "cHxZtlMXOI%2b2AKQ%2fWQ8rBy8hU7PwbsJujP26RG2cssI%3d",
      "team": "Redwolves",
      "score": "5",
      "oppScore": "15",
      "opp": "Phoenix",
      "oppId": "gSiJoSXGsIFqzcdqVTCYpgvQv7XMC%2b0qfhx6EDDLgrQ%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "qN9rYO0ey%2fwbppxRg%2bgTmnrFl%2fC9X7D1E9nV0MPP3Ys%3d",
      "team": "Redwolves",
      "score": "10",
      "oppScore": "8",
      "opp": "Lost Boys",
      "oppId": "aNMyR0uAJNwnidjeClZAMqixGjtlQqA6k7CiZP1R0%2bM%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "6h95vW6z4GqLmJryukFeb9ArfGA3vCz6E3CmGB1g%2fBI%3d",
      "team": "Redwolves",
      "score": "8",
      "oppScore": "10",
      "opp": "Phantom",
      "oppId": "Hc0As%2bNg1YaD%2fyL3LwaIEtSMTc23KxHOibI0ZwQLDa4%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "D9w%2fWqkfgv6Z4A%2fp5rSnGC2c0CAhteOjsvk4LaW1dsM%3d",
      "team": "Redwolves",
      "score": "13",
      "oppScore": "7",
      "opp": "Winc City Fog of War",
      "oppId": "6gsiUt3INMB4Td2RTxJkksYpRIJsACnjptCfHUZeg2o%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "X%2fnDV4TWs9JM4NMbp4YucpAJllyPMmJsfljo%2fbjTIaA%3d",
      "team": "Redwolves",
      "score": "13",
      "oppScore": "6",
      "opp": "Chimney",
      "oppId": "jzVhyLybRfcABTsshmdvgsxYDSASMTHMGNde%2bkQTb4Y%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "7czY02gAVRwRUXbGlnGkadLo1%2f84ovLK%2fmglnzEiILs%3d",
      "team": "Redwolves",
      "score": "13",
      "oppScore": "6",
      "opp": "Magma Bears",
      "oppId": "tOcZDMKk80VDM0VC5NJXyzOSixma%2fCVGplidpqww6q8%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "97YA9h5gKbpI2LktAUpRiAwXuBsCx0F25R45b7x3%2bAc%3d",
      "team": "Redwolves",
      "score": "13",
      "oppScore": "7",
      "opp": "Brickhouse",
      "oppId": "b5ARnVg7OngG1a0%2fJuMZTHluwgNFe%2bN5esQIIKsEOUY%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "%2bEFxDN80NfoeiNbRCFZRG4zyOKsktmMUXA%2fCDjU9p2U%3d",
      "team": "Redwolves",
      "score": "13",
      "oppScore": "5",
      "opp": "BAG",
      "oppId": "0tQmFBgNfrHGaAXxTYC%2f8oP1TSA1q7cMU6hhrYmt1Y0%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "P5WS62%2bgHPsBY3ivLWA4zsA0frZT7pXVBKsN9qUnMo0%3d",
      "team": "Redwolves",
      "score": "6",
      "oppScore": "13",
      "opp": "Blueprint",
      "oppId": "BDhLSFxnViHodVQ9noIy0%2by0HWSmiJ3qgV5WI1ozsAk%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "s78RxB%2fgzZAZ0BKSnEjp9JlizVAIlsfhjT1zRFuco0k%3d",
      "team": "Redwolves",
      "score": "10",
      "oppScore": "11",
      "opp": "Brickhouse",
      "oppId": "b5ARnVg7OngG1a0%2fJuMZTHluwgNFe%2bN5esQIIKsEOUY%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "uLgp%2b4PklPjNWoci5llGdzLuI%2fjLYSPn1duWHHz6xpw%3d",
      "team": "Redwolves",
      "score": "11",
      "oppScore": "10",
      "opp": "Lost Boys",
      "oppId": "aNMyR0uAJNwnidjeClZAMqixGjtlQqA6k7CiZP1R0%2bM%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "ClaR0n%2bC5OOvdnVCG2Xd%2f7bzdfOvKSOa5bR08hWe610%3d",
      "team": "Redwolves",
      "score": "10",
      "oppScore": "11",
      "opp": "baNC",
      "oppId": "uce10clG%2fWf3GrKUxwKToSDTqXSp6FS58Fb50TN%2fPwU%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "5mbgXo6uO%2bNRZwCrGukK5XTf4TACcP8WbanJfaD9Nq4%3d",
      "team": "Redwolves",
      "score": "11",
      "oppScore": "5",
      "opp": "Richmond Floodwall",
      "oppId": "fa1yMF%2fsPXZM%2b%2f4My9LlUixC%2bMFxnwM7gPH410zO6EA%3d"
    }
  ],
  "hLR3gjv9xQ1g1QebpZRZmoy9aTcPypW2VOulzmPNeXs%3d": [
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "DcuRCDHve%2ftqkd0FhRvzCEBpolbowgWd2A%2bp8k3kXCk%3d",
      "team": "MBTA",
      "score": "5",
      "oppScore": "15",
      "opp": "Colt",
      "oppId": "csuSEMdqMABUec374gfmA4qxClU85JTTEhhJhBcJ2Do%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "m9ZDv1L%2fxqcz5%2fIb%2bTSQVZf5sUUiY5WamFRVBI8l8P4%3d",
      "team": "MBTA",
      "score": "11",
      "oppScore": "12",
      "opp": "Red Squirrels",
      "oppId": "jLIBBhnc%2bi3%2bYrlyriV4mCL5Ti7S8%2b9yO0XC5HXh%2fcQ%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "HNF7pK4VR9uNkhX4GRbMKbRekgDNLq8WqGRdhhAiOnU%3d",
      "team": "MBTA",
      "score": "11",
      "oppScore": "12",
      "opp": "TROUT",
      "oppId": "bv%2fXE7f5uCQpkvM1FFa1CUcXftCxs7NptVBS7qJ1FVE%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "cM105Boy%2b1%2bq9qgzf1KZ7rkAQWUujYRvbDUKRfORyXg%3d",
      "team": "MBTA",
      "score": "15",
      "oppScore": "3",
      "opp": "Alibi",
      "oppId": "YEITgS48Qi42eAbGR80m%2fHHsltj1RQq%2be2tZnpaH3HY%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "Wfut%2bRTuW8CZjWXjSu90otZ%2fzRtMLsfz8hUzHmUQflQ%3d",
      "team": "MBTA",
      "score": "15",
      "oppScore": "8",
      "opp": "Red Squirrels",
      "oppId": "jLIBBhnc%2bi3%2bYrlyriV4mCL5Ti7S8%2b9yO0XC5HXh%2fcQ%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "frhZMBcSByrfVDmmz6ZwzvHnioW6P0xdBl7fS7dIcSY%3d",
      "team": "MBTA",
      "score": "6",
      "oppScore": "15",
      "opp": "BUDA U20B",
      "oppId": "jNsNN0Cf8lhuONh44thxFg7QsW6SRNpdPPPxrQyss8I%3d"
    },
    {
      "date": "August 20",
      "tournament": "Northeast Classic 2022",
      "tournamentId": "Northeast-Classic-2022",
      "gameId": "DzCM6YK26HpugvIwQ4%2bP9ZOZvp0FqhMR9s%2flDP0rJso%3d",
      "team": "MBTA",
      "score": "15",
      "oppScore": "10",
      "opp": "ADK Mountain Crew",
      "oppId": "kq%2bjX0z0mI2demJGurW9c4YwXa1qYvydu%2fcl7Dmf4PM%3d"
    },
    {
      "date": "August 20",
      "tournament": "Northeast Classic 2022",
      "tournamentId": "Northeast-Classic-2022",
      "gameId": "cOsXHaZ7eL8aej2sZAQLIAleaAOtmA4oRfA7UUrErTU%3d",
      "team": "MBTA",
      "score": "15",
      "oppScore": "8",
      "opp": "Alibi",
      "oppId": "YEITgS48Qi42eAbGR80m%2fHHsltj1RQq%2be2tZnpaH3HY%3d"
    },
    {
      "date": "August 20",
      "tournament": "Northeast Classic 2022",
      "tournamentId": "Northeast-Classic-2022",
      "gameId": "hZIA2qcnG%2bG%2bbt00uUTlnww0L%2b9M531k1bgqpbvJogU%3d",
      "team": "MBTA",
      "score": "15",
      "oppScore": "3",
      "opp": "Odyssey",
      "oppId": "b3BwYIqzVB8cFGN1zic0%2bcWc5J5ATsFKGuQD%2ferKcTM%3d"
    },
    {
      "date": "August 21",
      "tournament": "Northeast Classic 2022",
      "tournamentId": "Northeast-Classic-2022",
      "gameId": "d7X0rahXc4OPmRNggt%2fQtqTe9XYaD1NQCrVz3H50ERU%3d",
      "team": "MBTA",
      "score": "15",
      "oppScore": "8",
      "opp": "Odyssey",
      "oppId": "b3BwYIqzVB8cFGN1zic0%2bcWc5J5ATsFKGuQD%2ferKcTM%3d"
    },
    {
      "date": "August 21",
      "tournament": "Northeast Classic 2022",
      "tournamentId": "Northeast-Classic-2022",
      "gameId": "VwZVIf3IZCVJC6wH%2fa0ZPYK1x3KNbUNm%2f5H4l%2f4e7as%3d",
      "team": "MBTA",
      "score": "15",
      "oppScore": "14",
      "opp": "Alibi",
      "oppId": "YEITgS48Qi42eAbGR80m%2fHHsltj1RQq%2be2tZnpaH3HY%3d"
    }
  ],
  "csuSEMdqMABUec374gfmA4qxClU85JTTEhhJhBcJ2Do%3d": [
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "DcuRCDHve%2ftqkd0FhRvzCEBpolbowgWd2A%2bp8k3kXCk%3d",
      "team": "Colt",
      "score": "15",
      "oppScore": "5",
      "opp": "MBTA",
      "oppId": "hLR3gjv9xQ1g1QebpZRZmoy9aTcPypW2VOulzmPNeXs%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "SA07IVmEdIMPjed0As44mQ3aA6XumlFLG9IN7ETDy2w%3d",
      "team": "Colt",
      "score": "10",
      "oppScore": "5",
      "opp": "Alibi",
      "oppId": "YEITgS48Qi42eAbGR80m%2fHHsltj1RQq%2be2tZnpaH3HY%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "VIK0pV1KzgP4c70kkZwWNd7zGuWPnfn3G6lBM6eG8Gc%3d",
      "team": "Colt",
      "score": "15",
      "oppScore": "4",
      "opp": "Red Squirrels",
      "oppId": "jLIBBhnc%2bi3%2bYrlyriV4mCL5Ti7S8%2b9yO0XC5HXh%2fcQ%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "KYt3P888MIOzCxu2mkJg%2frB5QcmNFcnJ%2f5TMIMJePC0%3d",
      "team": "Colt",
      "score": "13",
      "oppScore": "5",
      "opp": "TROUT",
      "oppId": "bv%2fXE7f5uCQpkvM1FFa1CUcXftCxs7NptVBS7qJ1FVE%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "g6cnuoSl7Km3RnLakbWviZMG%2fsDAHRqjl%2flVBVwcY%2b0%3d",
      "team": "Colt",
      "score": "12",
      "oppScore": "7",
      "opp": "Helots",
      "oppId": "m6Orv8C1KcDAyDCEIuhdmpNBKN41R0jWgAvkxa%2bLmOk%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "EmCC5B%2fb9Yx4%2b6KRukQeV7ba8Ge2ScM1A5A53A%2fEYTM%3d",
      "team": "Colt",
      "score": "11",
      "oppScore": "15",
      "opp": "Club M - Manic",
      "oppId": "EPJEJz5rBmfBf0wlHEMDiTnhdrXlFtK%2bu2m7WHlH71E%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "S9gL1sIqVi9NVqfuYuJIoWxLgFnCAgm909xZMwC0JN8%3d",
      "team": "Colt",
      "score": "4",
      "oppScore": "13",
      "opp": "BAG",
      "oppId": "0tQmFBgNfrHGaAXxTYC%2f8oP1TSA1q7cMU6hhrYmt1Y0%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "674Pv4vLP7Ux%2bhO5ThIX5T%2fSAbO4IyQGQ7FBUGpn3zI%3d",
      "team": "Colt",
      "score": "10",
      "oppScore": "6",
      "opp": "Adelphos",
      "oppId": "IINBHzSyAD1qveaivUllPpQBPkMwjzVWcFo4s84DFtc%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "FrJSLgeQaAHOi5SQm9cMyGqNpnYfJ7m3ftqElFFR3tQ%3d",
      "team": "Colt",
      "score": "13",
      "oppScore": "7",
      "opp": "Bomb Squad",
      "oppId": "GMU0OYY8eXqf1AR6M93JxRSXSpRgEq9sJxKUtkOjXgg%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "vmB0ZafxVEqTP8GCpp1YQMniLjHHI%2bDIxWumaPC0QPk%3d",
      "team": "Colt",
      "score": "8",
      "oppScore": "12",
      "opp": "Rumspringa",
      "oppId": "ZL0F50TyR6dI%2b5OluYY14WIhkuQQcvw4HafjYT2i%2bT0%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "CU1UmY2eqpqysE%2bgP58wc8lNgWcwBkO27XpRqDi9RbQ%3d",
      "team": "Colt",
      "score": "12",
      "oppScore": "8",
      "opp": "Ballston Booya",
      "oppId": "nyrFBt9ebf%2bCb5bJv%2fsbfoGgjlyW%2bH3CLoFeaZGYMEY%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "Zk4rJ%2bwo8IYLmtRCjh3Jrj49bO1xVjqCI7YOet6jUDw%3d",
      "team": "Colt",
      "score": "8",
      "oppScore": "9",
      "opp": "John Doe",
      "oppId": "U8i%2ffBl%2bGdNRXUaehdkV4W9lOM4QHUciytmWcy3oxXI%3d"
    }
  ],
  "jLIBBhnc%2bi3%2bYrlyriV4mCL5Ti7S8%2b9yO0XC5HXh%2fcQ%3d": [
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "3TIRjTKi2rizEFJGXfOStS69a4GLBIo86jKRllJ%2fB2g%3d",
      "team": "Red Squirrels",
      "score": "11",
      "oppScore": "12",
      "opp": "Alibi",
      "oppId": "YEITgS48Qi42eAbGR80m%2fHHsltj1RQq%2be2tZnpaH3HY%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "0j5oiY1BuXkzCQA1o4WhkoJ4KeonufB2tD444yvCzeg%3d",
      "team": "Red Squirrels",
      "score": "7",
      "oppScore": "13",
      "opp": "TROUT",
      "oppId": "bv%2fXE7f5uCQpkvM1FFa1CUcXftCxs7NptVBS7qJ1FVE%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "m9ZDv1L%2fxqcz5%2fIb%2bTSQVZf5sUUiY5WamFRVBI8l8P4%3d",
      "team": "Red Squirrels",
      "score": "12",
      "oppScore": "11",
      "opp": "MBTA",
      "oppId": "hLR3gjv9xQ1g1QebpZRZmoy9aTcPypW2VOulzmPNeXs%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "VIK0pV1KzgP4c70kkZwWNd7zGuWPnfn3G6lBM6eG8Gc%3d",
      "team": "Red Squirrels",
      "score": "4",
      "oppScore": "15",
      "opp": "Colt",
      "oppId": "csuSEMdqMABUec374gfmA4qxClU85JTTEhhJhBcJ2Do%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "dqM0fdo0a9MJtaFY9IKQ6TGgvwKgXnx4ivq4vBc9%2bxU%3d",
      "team": "Red Squirrels",
      "score": "12",
      "oppScore": "11",
      "opp": "Odyssey",
      "oppId": "b3BwYIqzVB8cFGN1zic0%2bcWc5J5ATsFKGuQD%2ferKcTM%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "Wfut%2bRTuW8CZjWXjSu90otZ%2fzRtMLsfz8hUzHmUQflQ%3d",
      "team": "Red Squirrels",
      "score": "8",
      "oppScore": "15",
      "opp": "MBTA",
      "oppId": "hLR3gjv9xQ1g1QebpZRZmoy9aTcPypW2VOulzmPNeXs%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "FBpHYqavlJ8N8Kpik0k4sR5%2buJ45gjoMIY%2fDB5Jdq2c%3d",
      "team": "Red Squirrels",
      "score": "11",
      "oppScore": "9",
      "opp": "NIIIght",
      "oppId": "Mrfn10Tc53xvcYoOI6fGWhBaehYqA70hV8%2bGcc6QWF4%3d"
    }
  ],
  "jNsNN0Cf8lhuONh44thxFg7QsW6SRNpdPPPxrQyss8I%3d": [
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "dqXTAycG1Jj2F69BmpAwA0Hj5IisCg024J0iz7TuZ5g%3d",
      "team": "BUDA U20B",
      "score": "9",
      "oppScore": "12",
      "opp": "Club M - Manic",
      "oppId": "EPJEJz5rBmfBf0wlHEMDiTnhdrXlFtK%2bu2m7WHlH71E%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "zBfGF2EDE5ywIRQoLbqsYWELwuCCCVeWS6%2bBqty5w28%3d",
      "team": "BUDA U20B",
      "score": "13",
      "oppScore": "7",
      "opp": "NIIIght",
      "oppId": "Mrfn10Tc53xvcYoOI6fGWhBaehYqA70hV8%2bGcc6QWF4%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "YhIpgaf2MyPybxv5IhmyEzw%2fuOL8831ICo9hn1VsN5Y%3d",
      "team": "BUDA U20B",
      "score": "7",
      "oppScore": "12",
      "opp": "Helots",
      "oppId": "m6Orv8C1KcDAyDCEIuhdmpNBKN41R0jWgAvkxa%2bLmOk%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "De8nRGBhbhF69QWs64v%2btMKLP9kpOgV0p355Q6JXisU%3d",
      "team": "BUDA U20B",
      "score": "13",
      "oppScore": "6",
      "opp": "Odyssey",
      "oppId": "b3BwYIqzVB8cFGN1zic0%2bcWc5J5ATsFKGuQD%2ferKcTM%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "HOHcNcVqlVe2SAhpok2U78wFdOqA52Z8H0oY0C3D0NA%3d",
      "team": "BUDA U20B",
      "score": "12",
      "oppScore": "6",
      "opp": "NIIIght",
      "oppId": "Mrfn10Tc53xvcYoOI6fGWhBaehYqA70hV8%2bGcc6QWF4%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "frhZMBcSByrfVDmmz6ZwzvHnioW6P0xdBl7fS7dIcSY%3d",
      "team": "BUDA U20B",
      "score": "15",
      "oppScore": "6",
      "opp": "MBTA",
      "oppId": "hLR3gjv9xQ1g1QebpZRZmoy9aTcPypW2VOulzmPNeXs%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "1AeAX6j7tYlV6CbT06z%2f3ug2JkIFLai7Iumd9U%2bmAP4%3d",
      "team": "BUDA U20B",
      "score": "15",
      "oppScore": "5",
      "opp": "DEVYL U-20 Boys",
      "oppId": "YXOyQcXz9dyRRYJVW1knp%2felzsehAC4MeGolkdDmmfc%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "RfITDdsJT6QoSEen0gEMNDlGby71A8zxeOFPeM%2fpPU8%3d",
      "team": "BUDA U20B",
      "score": "11",
      "oppScore": "8",
      "opp": "Seven Hills U20 Open",
      "oppId": "2D%2bFPn8oCjm3tG6NxWZp2Dp0JX6jLR1rE1uzYoJ%2f6lI%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "6sJwggce5MlKoaJ7zGeIi9NfM9ov%2bNuZZGmM5eF%2bUdc%3d",
      "team": "BUDA U20B",
      "score": "14",
      "oppScore": "13",
      "opp": "Philadelphia SEPDA (Boys)",
      "oppId": "5O9ir9HXplxStTnTGuxWkmxt8TitCqJnl0x7u%2fVD5VU%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "M6Ss%2f6Jc7ruYI5ecqHCJUrPvhVLTEEd5TVwiFHnFUOE%3d",
      "team": "BUDA U20B",
      "score": "15",
      "oppScore": "6",
      "opp": "Triforce",
      "oppId": "bE9A3Qj1MqW7pCIHHtQnnzKx11rAl2O9wYRSSZQ4Z40%3d"
    },
    {
      "date": "August 08",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "GNhPdhaKDLyRbGc52ZlBIPb2kkP7LlSBDQLe%2bmFvRow%3d",
      "team": "BUDA U20B",
      "score": "9",
      "oppScore": "15",
      "opp": "Utah Swarm U20 Boys",
      "oppId": "7zOE8CWHFub%2bZUOhOxBboFAMcpZTTbC8qJbOkBlESOk%3d"
    },
    {
      "date": "August 08",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "%2bOId9j3fcWMh8O55SHf9Q4I%2brLvtAcKJt4Hw4P8RcBQ%3d",
      "team": "BUDA U20B",
      "score": "15",
      "oppScore": "6",
      "opp": "Minnesota Superior U20B",
      "oppId": "l0841gMVxrjhuZzhqzFnYR7VeDuFVKrJOxuJebPVKDc%3d"
    }
  ],
  "bv%2fXE7f5uCQpkvM1FFa1CUcXftCxs7NptVBS7qJ1FVE%3d": [
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "0j5oiY1BuXkzCQA1o4WhkoJ4KeonufB2tD444yvCzeg%3d",
      "team": "TROUT",
      "score": "13",
      "oppScore": "7",
      "opp": "Red Squirrels",
      "oppId": "jLIBBhnc%2bi3%2bYrlyriV4mCL5Ti7S8%2b9yO0XC5HXh%2fcQ%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "XuT%2bPXa0ZUspYEkYETK0KY9LfSTQfYVapGAlBdxKELQ%3d",
      "team": "TROUT",
      "score": "12",
      "oppScore": "4",
      "opp": "Alibi",
      "oppId": "YEITgS48Qi42eAbGR80m%2fHHsltj1RQq%2be2tZnpaH3HY%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "HNF7pK4VR9uNkhX4GRbMKbRekgDNLq8WqGRdhhAiOnU%3d",
      "team": "TROUT",
      "score": "12",
      "oppScore": "11",
      "opp": "MBTA",
      "oppId": "hLR3gjv9xQ1g1QebpZRZmoy9aTcPypW2VOulzmPNeXs%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "KYt3P888MIOzCxu2mkJg%2frB5QcmNFcnJ%2f5TMIMJePC0%3d",
      "team": "TROUT",
      "score": "5",
      "oppScore": "13",
      "opp": "Colt",
      "oppId": "csuSEMdqMABUec374gfmA4qxClU85JTTEhhJhBcJ2Do%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "rgtOIkrL7eoDibIccMUgK9BPkjNOzJObXQfOM6eAXO4%3d",
      "team": "TROUT",
      "score": "4",
      "oppScore": "15",
      "opp": "Club M - Manic",
      "oppId": "EPJEJz5rBmfBf0wlHEMDiTnhdrXlFtK%2bu2m7WHlH71E%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "tBqqZr3IUj0U9xojsjSCVqCD8hrbj6kBjNNmKm4Vmxo%3d",
      "team": "TROUT",
      "score": "5",
      "oppScore": "15",
      "opp": "Helots",
      "oppId": "m6Orv8C1KcDAyDCEIuhdmpNBKN41R0jWgAvkxa%2bLmOk%3d"
    }
  ],
  "7tBre8lNjeAgLNCS5dx%2fnhybe521PEeqaFyoOhXPzSk%3d": [
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "TBuL%2frAv2fdeY6iWUbzCM5%2bnm4Fu4agcpwPmw%2bxzsIQ%3d",
      "team": "Iditarod",
      "score": "10",
      "oppScore": "12",
      "opp": "TireBizFriz",
      "oppId": "8V7KiKjowPKXYVA3WBFCPRK7xrpBH0Y57Z6GJCfBx3s%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "Llm%2b57CENugwmDNgNqsEfobCTH8Kq32DzV%2f4e1pWgXI%3d",
      "team": "Iditarod",
      "score": "12",
      "oppScore": "10",
      "opp": "Needham Squad",
      "oppId": "nB%2bSQRgdJB9OGhcTAQ0lU8uZ8f%2bTFwQebHh0A8topa0%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "A5qj4KCF47Do3%2baidx29wPAHaVpq7n5eA27qngW8zss%3d",
      "team": "Iditarod",
      "score": "7",
      "oppScore": "12",
      "opp": "Red Tide",
      "oppId": "s6HGV42d1dEo0YRx%2fIycGA2qxvKRYzzfEITLPlDYO1I%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "uCGdumrcPI1Bqk6F%2bjkfY8y%2f9dSc4N2ryCZqocLULd4%3d",
      "team": "Iditarod",
      "score": "5",
      "oppScore": "15",
      "opp": "DiG",
      "oppId": "kn%2bHmtzxdjY7vxb%2fpJ9qsfEsWaDB6JrUBL7Ga4bU18M%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "Z21vFRfGIyzdb1mZ68X5OowKb1G419e4DWKzUx4Jtus%3d",
      "team": "Iditarod",
      "score": "15",
      "oppScore": "8",
      "opp": "MKE",
      "oppId": "qHyFAYrlLVWh07Z9rgHqv1nh7SsCt5k4p8Ri0Bna7Zc%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "iWqN2of0161csqjASbP1xgHU92HNqUjFbiyhFzpYEMg%3d",
      "team": "Iditarod",
      "score": "8",
      "oppScore": "13",
      "opp": "Needham Squad",
      "oppId": "nB%2bSQRgdJB9OGhcTAQ0lU8uZ8f%2bTFwQebHh0A8topa0%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "p%2ffiP2bUp%2fftpoCsfc%2fS6w3C2pB4XLEPR8iTea21f6k%3d",
      "team": "Iditarod",
      "score": "11",
      "oppScore": "10",
      "opp": "BAG",
      "oppId": "0tQmFBgNfrHGaAXxTYC%2f8oP1TSA1q7cMU6hhrYmt1Y0%3d"
    }
  ],
  "Mrfn10Tc53xvcYoOI6fGWhBaehYqA70hV8%2bGcc6QWF4%3d": [
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "%2fSNxw%2bucUUjM%2f5Y1FT0GrjFRUrRWIaoIFtOTN%2bzK160%3d",
      "team": "NIIIght",
      "score": "11",
      "oppScore": "6",
      "opp": "Odyssey",
      "oppId": "b3BwYIqzVB8cFGN1zic0%2bcWc5J5ATsFKGuQD%2ferKcTM%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "tlqav1VAc56U6BuMUPWTJ0jk%2fX2zQ7cKCdMsTmG61SM%3d",
      "team": "NIIIght",
      "score": "5",
      "oppScore": "13",
      "opp": "Helots",
      "oppId": "m6Orv8C1KcDAyDCEIuhdmpNBKN41R0jWgAvkxa%2bLmOk%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "zBfGF2EDE5ywIRQoLbqsYWELwuCCCVeWS6%2bBqty5w28%3d",
      "team": "NIIIght",
      "score": "7",
      "oppScore": "13",
      "opp": "BUDA U20B",
      "oppId": "jNsNN0Cf8lhuONh44thxFg7QsW6SRNpdPPPxrQyss8I%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "3Giyfj3Xv1jZUKWZ9ii%2fT8rQ%2bYSon0olB%2f0Lfn2fiHk%3d",
      "team": "NIIIght",
      "score": "4",
      "oppScore": "15",
      "opp": "Club M - Manic",
      "oppId": "EPJEJz5rBmfBf0wlHEMDiTnhdrXlFtK%2bu2m7WHlH71E%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "yPJRd3GzU%2ffEKhcwsx3%2fRQt489m8oq6mJmmXnsPHTZ8%3d",
      "team": "NIIIght",
      "score": "15",
      "oppScore": "7",
      "opp": "Alibi",
      "oppId": "YEITgS48Qi42eAbGR80m%2fHHsltj1RQq%2be2tZnpaH3HY%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "HOHcNcVqlVe2SAhpok2U78wFdOqA52Z8H0oY0C3D0NA%3d",
      "team": "NIIIght",
      "score": "6",
      "oppScore": "12",
      "opp": "BUDA U20B",
      "oppId": "jNsNN0Cf8lhuONh44thxFg7QsW6SRNpdPPPxrQyss8I%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "FBpHYqavlJ8N8Kpik0k4sR5%2buJ45gjoMIY%2fDB5Jdq2c%3d",
      "team": "NIIIght",
      "score": "9",
      "oppScore": "11",
      "opp": "Red Squirrels",
      "oppId": "jLIBBhnc%2bi3%2bYrlyriV4mCL5Ti7S8%2b9yO0XC5HXh%2fcQ%3d"
    }
  ],
  "tOcZDMKk80VDM0VC5NJXyzOSixma%2fCVGplidpqww6q8%3d": [
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "brE4ri9bSetudlpT%2fc3KhAOX1YNwRj%2bXTAUHOGQ2PDk%3d",
      "team": "Magma Bears",
      "score": "8",
      "oppScore": "13",
      "opp": "Big Wrench",
      "oppId": "cD5%2b6qwajeEeZXFAZqH%2f%2fSNl6zunDFFnMpdAAl7eKv0%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "fMdsugaMjGTAFg%2bnZjWquX8pWUU%2fGLnc%2blQAM6r7NIY%3d",
      "team": "Magma Bears",
      "score": "6",
      "oppScore": "9",
      "opp": "BAG",
      "oppId": "0tQmFBgNfrHGaAXxTYC%2f8oP1TSA1q7cMU6hhrYmt1Y0%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "7JJRUopxQiRfF0%2fC%2fQdjyGoUPI7n%2bRYsCowSmcHd8kY%3d",
      "team": "Magma Bears",
      "score": "8",
      "oppScore": "10",
      "opp": "MKE",
      "oppId": "qHyFAYrlLVWh07Z9rgHqv1nh7SsCt5k4p8Ri0Bna7Zc%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "zG3Migju48p%2fZ7InxGYhwK8rOo5w3y%2fqPmtrX6MfvXY%3d",
      "team": "Magma Bears",
      "score": "6",
      "oppScore": "15",
      "opp": "Lantern",
      "oppId": "5z%2bIWsl3V2NLqJRxKMauFZK8Pmkffh9obp1ocWQtXnY%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "2qWs6KIdGS07gEoRfZ0cqSpGkXJ6TSPmnoIbh4ASvwQ%3d",
      "team": "Magma Bears",
      "score": "8",
      "oppScore": "15",
      "opp": "Red Tide",
      "oppId": "s6HGV42d1dEo0YRx%2fIycGA2qxvKRYzzfEITLPlDYO1I%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "%2fv5GC9ShzeOQ5sAOZlcVccA%2f2zq2FeoQ9aJP50nDDCA%3d",
      "team": "Magma Bears",
      "score": "11",
      "oppScore": "12",
      "opp": "MKE",
      "oppId": "qHyFAYrlLVWh07Z9rgHqv1nh7SsCt5k4p8Ri0Bna7Zc%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "%2fYLEXKLdM8dWpj2H4S5bjmRNBwVfTheNtDvYFREiuMc%3d",
      "team": "Magma Bears",
      "score": "12",
      "oppScore": "11",
      "opp": "Chimney",
      "oppId": "jzVhyLybRfcABTsshmdvgsxYDSASMTHMGNde%2bkQTb4Y%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "q2KFQ4b5duFHn3sdGvTwqxUiCRdhSZefPG0ypY3bnP4%3d",
      "team": "Magma Bears",
      "score": "10",
      "oppScore": "8",
      "opp": "Winc City Fog of War",
      "oppId": "6gsiUt3INMB4Td2RTxJkksYpRIJsACnjptCfHUZeg2o%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "7czY02gAVRwRUXbGlnGkadLo1%2f84ovLK%2fmglnzEiILs%3d",
      "team": "Magma Bears",
      "score": "6",
      "oppScore": "13",
      "opp": "Redwolves",
      "oppId": "DoL%2frShNUJHM7OLFhcsMzWlsS0aohpfm30HvDbpVZi4%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "bXWGMCMr3TpQ4nSKR4Y0xLim4ZupYtMbPdSbxgf8oqA%3d",
      "team": "Magma Bears",
      "score": "12",
      "oppScore": "9",
      "opp": "Ballston Booya",
      "oppId": "nyrFBt9ebf%2bCb5bJv%2fsbfoGgjlyW%2bH3CLoFeaZGYMEY%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "54HGnS2nhPszA586pu%2fj%2bgHMGe2E%2bLFdJ52YamkAEz0%3d",
      "team": "Magma Bears",
      "score": "8",
      "oppScore": "12",
      "opp": "Rumspringa",
      "oppId": "ZL0F50TyR6dI%2b5OluYY14WIhkuQQcvw4HafjYT2i%2bT0%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "aPOY2b6QeIHdEACVxeQLl6VYyXv9yEXGnB0x%2fNV9%2bZY%3d",
      "team": "Magma Bears",
      "score": "11",
      "oppScore": "7",
      "opp": "Bomb Squad",
      "oppId": "GMU0OYY8eXqf1AR6M93JxRSXSpRgEq9sJxKUtkOjXgg%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "wykpPlgCW1mheq%2bafPcqbRETlpbMhvpyqpkfQkeRpKs%3d",
      "team": "Magma Bears",
      "score": "13",
      "oppScore": "4",
      "opp": "Odyssey",
      "oppId": "b3BwYIqzVB8cFGN1zic0%2bcWc5J5ATsFKGuQD%2ferKcTM%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "zn4bYLfkChvHc7NEeCPf6Z90DJZQHX40dnYlMjJAGw8%3d",
      "team": "Magma Bears",
      "score": "13",
      "oppScore": "10",
      "opp": "Needham Squad",
      "oppId": "nB%2bSQRgdJB9OGhcTAQ0lU8uZ8f%2bTFwQebHh0A8topa0%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "PU9BbyJZRlT516PFLJuwjj%2fVouB35e%2fJlA7B2A7kio0%3d",
      "team": "Magma Bears",
      "score": "11",
      "oppScore": "13",
      "opp": "Bomb Squad",
      "oppId": "GMU0OYY8eXqf1AR6M93JxRSXSpRgEq9sJxKUtkOjXgg%3d"
    }
  ],
  "0tQmFBgNfrHGaAXxTYC%2f8oP1TSA1q7cMU6hhrYmt1Y0%3d": [
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "iYwbal%2f6baP52oVn6XzhSsZBHoN%2fOGdHP9%2b2McEle84%3d",
      "team": "BAG",
      "score": "4",
      "oppScore": "13",
      "opp": "Lantern",
      "oppId": "5z%2bIWsl3V2NLqJRxKMauFZK8Pmkffh9obp1ocWQtXnY%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "yoTDw9XpQl6bIdFU%2bgve5YZxnpL%2bIIKWizQHUjL%2f9M4%3d",
      "team": "BAG",
      "score": "15",
      "oppScore": "10",
      "opp": "MKE",
      "oppId": "qHyFAYrlLVWh07Z9rgHqv1nh7SsCt5k4p8Ri0Bna7Zc%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "fMdsugaMjGTAFg%2bnZjWquX8pWUU%2fGLnc%2blQAM6r7NIY%3d",
      "team": "BAG",
      "score": "9",
      "oppScore": "6",
      "opp": "Magma Bears",
      "oppId": "tOcZDMKk80VDM0VC5NJXyzOSixma%2fCVGplidpqww6q8%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "doMq9ggerbxydBDQZ5aB82AfC7anRxyqtlV4ltE7Pyw%3d",
      "team": "BAG",
      "score": "6",
      "oppScore": "9",
      "opp": "Big Wrench",
      "oppId": "cD5%2b6qwajeEeZXFAZqH%2f%2fSNl6zunDFFnMpdAAl7eKv0%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "kaOe%2b1M3WilimrCMZ8i%2f0YpDb7zbk5fdwBpCwgEpWmE%3d",
      "team": "BAG",
      "score": "7",
      "oppScore": "12",
      "opp": "Red Tide",
      "oppId": "s6HGV42d1dEo0YRx%2fIycGA2qxvKRYzzfEITLPlDYO1I%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "p%2ffiP2bUp%2fftpoCsfc%2fS6w3C2pB4XLEPR8iTea21f6k%3d",
      "team": "BAG",
      "score": "10",
      "oppScore": "11",
      "opp": "Iditarod",
      "oppId": "7tBre8lNjeAgLNCS5dx%2fnhybe521PEeqaFyoOhXPzSk%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "S9gL1sIqVi9NVqfuYuJIoWxLgFnCAgm909xZMwC0JN8%3d",
      "team": "BAG",
      "score": "13",
      "oppScore": "4",
      "opp": "Colt",
      "oppId": "csuSEMdqMABUec374gfmA4qxClU85JTTEhhJhBcJ2Do%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "GLjcjC1hedTmmwBIJ4G2iYUSXu0FlNz5lDMiOATO2cI%3d",
      "team": "BAG",
      "score": "9",
      "oppScore": "8",
      "opp": "Bomb Squad",
      "oppId": "GMU0OYY8eXqf1AR6M93JxRSXSpRgEq9sJxKUtkOjXgg%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "alfYx1D9EjWuUpWGc%2fUiFAhQJOcOLF3ByQJpri7hI8M%3d",
      "team": "BAG",
      "score": "12",
      "oppScore": "3",
      "opp": "Adelphos",
      "oppId": "IINBHzSyAD1qveaivUllPpQBPkMwjzVWcFo4s84DFtc%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "DbxS8Y4bMXDScYXbNkisbOfvGWXQRt6%2fH8wLs0zs9us%3d",
      "team": "BAG",
      "score": "9",
      "oppScore": "5",
      "opp": "Big Wrench",
      "oppId": "cD5%2b6qwajeEeZXFAZqH%2f%2fSNl6zunDFFnMpdAAl7eKv0%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "%2bEFxDN80NfoeiNbRCFZRG4zyOKsktmMUXA%2fCDjU9p2U%3d",
      "team": "BAG",
      "score": "5",
      "oppScore": "13",
      "opp": "Redwolves",
      "oppId": "DoL%2frShNUJHM7OLFhcsMzWlsS0aohpfm30HvDbpVZi4%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "1211NE3miAALwGBT508uorA0qLjKQjLuhwMSQN8RLnY%3d",
      "team": "BAG",
      "score": "W",
      "oppScore": "F",
      "opp": "Red Tide",
      "oppId": "s6HGV42d1dEo0YRx%2fIycGA2qxvKRYzzfEITLPlDYO1I%3d"
    }
  ],
  "s6HGV42d1dEo0YRx%2fIycGA2qxvKRYzzfEITLPlDYO1I%3d": [
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "AsiS32vXHZe0%2bKbjx0zfGt6%2bNyVo0qC%2byr9c7fWnqi0%3d",
      "team": "Red Tide",
      "score": "8",
      "oppScore": "14",
      "opp": "DiG",
      "oppId": "kn%2bHmtzxdjY7vxb%2fpJ9qsfEsWaDB6JrUBL7Ga4bU18M%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "eT0jWU5RDyUBoE18gIMBTM5VptoxUSOrtp11n8TyOLY%3d",
      "team": "Red Tide",
      "score": "7",
      "oppScore": "12",
      "opp": "TireBizFriz",
      "oppId": "8V7KiKjowPKXYVA3WBFCPRK7xrpBH0Y57Z6GJCfBx3s%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "A5qj4KCF47Do3%2baidx29wPAHaVpq7n5eA27qngW8zss%3d",
      "team": "Red Tide",
      "score": "12",
      "oppScore": "7",
      "opp": "Iditarod",
      "oppId": "7tBre8lNjeAgLNCS5dx%2fnhybe521PEeqaFyoOhXPzSk%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "lEf4hZTg4LilNZIOiUAwxUChlckeE%2bYlJHI1ayqpoYg%3d",
      "team": "Red Tide",
      "score": "9",
      "oppScore": "13",
      "opp": "Needham Squad",
      "oppId": "nB%2bSQRgdJB9OGhcTAQ0lU8uZ8f%2bTFwQebHh0A8topa0%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "2qWs6KIdGS07gEoRfZ0cqSpGkXJ6TSPmnoIbh4ASvwQ%3d",
      "team": "Red Tide",
      "score": "15",
      "oppScore": "8",
      "opp": "Magma Bears",
      "oppId": "tOcZDMKk80VDM0VC5NJXyzOSixma%2fCVGplidpqww6q8%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "kaOe%2b1M3WilimrCMZ8i%2f0YpDb7zbk5fdwBpCwgEpWmE%3d",
      "team": "Red Tide",
      "score": "12",
      "oppScore": "7",
      "opp": "BAG",
      "oppId": "0tQmFBgNfrHGaAXxTYC%2f8oP1TSA1q7cMU6hhrYmt1Y0%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "nkDZ9bzKnfjKrMDcAwHEXItXwxlRuPeyVeTy%2fI%2bHo5A%3d",
      "team": "Red Tide",
      "score": "12",
      "oppScore": "10",
      "opp": "Needham Squad",
      "oppId": "nB%2bSQRgdJB9OGhcTAQ0lU8uZ8f%2bTFwQebHh0A8topa0%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "1wXAZiSDlTf0SjPNL0A3NAN3q9qQnveHOOMYLRqldvw%3d",
      "team": "Red Tide",
      "score": "13",
      "oppScore": "6",
      "opp": "Richmond Floodwall",
      "oppId": "fa1yMF%2fsPXZM%2b%2f4My9LlUixC%2bMFxnwM7gPH410zO6EA%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "xq%2bY02AWO6w3%2bn9AeKSPtvSX5l4Id9Hlf1uxPktXdns%3d",
      "team": "Red Tide",
      "score": "13",
      "oppScore": "6",
      "opp": "Hazard",
      "oppId": "RBbGte9ro%2fCZWrmOmcHNTdHB41zGQFb64ubBkZjcTEI%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "ZlBhgxWKuWaEkiSPJdRFdSnrF0XZ4ngQmM0YjyuPRaY%3d",
      "team": "Red Tide",
      "score": "11",
      "oppScore": "9",
      "opp": "Brickhouse",
      "oppId": "b5ARnVg7OngG1a0%2fJuMZTHluwgNFe%2bN5esQIIKsEOUY%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "txhyiXovL6EgowbPqD3BLzgOA4yAi9iOWeA73uerMv8%3d",
      "team": "Red Tide",
      "score": "12",
      "oppScore": "9",
      "opp": "Pittsburgh Stealers",
      "oppId": "icSGwphEBV6tcYJLRwPxEC22s0bt6rct5HG5ubjyetw%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "sfxzV3olpyiLZ6e5wmYzm%2fqGc3Ip%2bLNx74K9UtBKT%2bM%3d",
      "team": "Red Tide",
      "score": "9",
      "oppScore": "12",
      "opp": "Blueprint",
      "oppId": "BDhLSFxnViHodVQ9noIy0%2by0HWSmiJ3qgV5WI1ozsAk%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "1211NE3miAALwGBT508uorA0qLjKQjLuhwMSQN8RLnY%3d",
      "team": "Red Tide",
      "score": "F",
      "oppScore": "W",
      "opp": "BAG",
      "oppId": "0tQmFBgNfrHGaAXxTYC%2f8oP1TSA1q7cMU6hhrYmt1Y0%3d"
    }
  ],
  "nB%2bSQRgdJB9OGhcTAQ0lU8uZ8f%2bTFwQebHh0A8topa0%3d": [
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "%2bjLm%2b04f8JD9Eu7TCxYGcEQGWq33I5i2uep614KB3QU%3d",
      "team": "Needham Squad",
      "score": "10",
      "oppScore": "14",
      "opp": "TireBizFriz",
      "oppId": "8V7KiKjowPKXYVA3WBFCPRK7xrpBH0Y57Z6GJCfBx3s%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "PacFVZt%2bh7IzI67vaaRgzQRPAp5XK9Lq1r4SzBTpvkc%3d",
      "team": "Needham Squad",
      "score": "7",
      "oppScore": "15",
      "opp": "DiG",
      "oppId": "kn%2bHmtzxdjY7vxb%2fpJ9qsfEsWaDB6JrUBL7Ga4bU18M%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "Llm%2b57CENugwmDNgNqsEfobCTH8Kq32DzV%2f4e1pWgXI%3d",
      "team": "Needham Squad",
      "score": "10",
      "oppScore": "12",
      "opp": "Iditarod",
      "oppId": "7tBre8lNjeAgLNCS5dx%2fnhybe521PEeqaFyoOhXPzSk%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "lEf4hZTg4LilNZIOiUAwxUChlckeE%2bYlJHI1ayqpoYg%3d",
      "team": "Needham Squad",
      "score": "13",
      "oppScore": "9",
      "opp": "Red Tide",
      "oppId": "s6HGV42d1dEo0YRx%2fIycGA2qxvKRYzzfEITLPlDYO1I%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "iWqN2of0161csqjASbP1xgHU92HNqUjFbiyhFzpYEMg%3d",
      "team": "Needham Squad",
      "score": "13",
      "oppScore": "8",
      "opp": "Iditarod",
      "oppId": "7tBre8lNjeAgLNCS5dx%2fnhybe521PEeqaFyoOhXPzSk%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "nkDZ9bzKnfjKrMDcAwHEXItXwxlRuPeyVeTy%2fI%2bHo5A%3d",
      "team": "Needham Squad",
      "score": "10",
      "oppScore": "12",
      "opp": "Red Tide",
      "oppId": "s6HGV42d1dEo0YRx%2fIycGA2qxvKRYzzfEITLPlDYO1I%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "LBBdBR%2bwjcP7AARkJ%2bGMYeK55Zsm8AiIFdy1vxGFKFg%3d",
      "team": "Needham Squad",
      "score": "13",
      "oppScore": "4",
      "opp": "7AM",
      "oppId": "mQPEfmcAYYIzzM4%2fYEbIkukCamR5oeyXQBECP7Pwhh8%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "zn4bYLfkChvHc7NEeCPf6Z90DJZQHX40dnYlMjJAGw8%3d",
      "team": "Needham Squad",
      "score": "10",
      "oppScore": "13",
      "opp": "Magma Bears",
      "oppId": "tOcZDMKk80VDM0VC5NJXyzOSixma%2fCVGplidpqww6q8%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "tymOdGEKmGC%2bp1MkMXQvjgX9W95P6%2fmeWcW4zz8Ij6o%3d",
      "team": "Needham Squad",
      "score": "13",
      "oppScore": "4",
      "opp": "Odyssey",
      "oppId": "b3BwYIqzVB8cFGN1zic0%2bcWc5J5ATsFKGuQD%2ferKcTM%3d"
    }
  ],
  "m6Orv8C1KcDAyDCEIuhdmpNBKN41R0jWgAvkxa%2bLmOk%3d": [
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "tlqav1VAc56U6BuMUPWTJ0jk%2fX2zQ7cKCdMsTmG61SM%3d",
      "team": "Helots",
      "score": "13",
      "oppScore": "5",
      "opp": "NIIIght",
      "oppId": "Mrfn10Tc53xvcYoOI6fGWhBaehYqA70hV8%2bGcc6QWF4%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "SqScEuYr58dKrNh%2biNavFNTgo0dICtimS5MnMoubChQ%3d",
      "team": "Helots",
      "score": "15",
      "oppScore": "2",
      "opp": "Odyssey",
      "oppId": "b3BwYIqzVB8cFGN1zic0%2bcWc5J5ATsFKGuQD%2ferKcTM%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "YhIpgaf2MyPybxv5IhmyEzw%2fuOL8831ICo9hn1VsN5Y%3d",
      "team": "Helots",
      "score": "12",
      "oppScore": "7",
      "opp": "BUDA U20B",
      "oppId": "jNsNN0Cf8lhuONh44thxFg7QsW6SRNpdPPPxrQyss8I%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "7MXmjlAkyxYViZu0IYk54jSA5yLOh5auPSs20I1QGTA%3d",
      "team": "Helots",
      "score": "7",
      "oppScore": "10",
      "opp": "Club M - Manic",
      "oppId": "EPJEJz5rBmfBf0wlHEMDiTnhdrXlFtK%2bu2m7WHlH71E%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "g6cnuoSl7Km3RnLakbWviZMG%2fsDAHRqjl%2flVBVwcY%2b0%3d",
      "team": "Helots",
      "score": "7",
      "oppScore": "12",
      "opp": "Colt",
      "oppId": "csuSEMdqMABUec374gfmA4qxClU85JTTEhhJhBcJ2Do%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "tBqqZr3IUj0U9xojsjSCVqCD8hrbj6kBjNNmKm4Vmxo%3d",
      "team": "Helots",
      "score": "15",
      "oppScore": "5",
      "opp": "TROUT",
      "oppId": "bv%2fXE7f5uCQpkvM1FFa1CUcXftCxs7NptVBS7qJ1FVE%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "eLfEHXLCT%2fmT1M7DeZdZ32U4xObVidDH7jtu3mwjedQ%3d",
      "team": "Helots",
      "score": "10",
      "oppScore": "7",
      "opp": "JAWN",
      "oppId": "ZmiNobvozutOTlvBTZEmqu8bW1a0Lvp6PqGmIiNlkGU%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "ZnT0iAN9kjRMyD94pVNcmgG4Pf3EmKgVfmHFI2EspPE%3d",
      "team": "Helots",
      "score": "10",
      "oppScore": "6",
      "opp": "MOB Ultimate",
      "oppId": "otuSqjNVhCJZE0ojAe0x6mw6GKk1Ux8ciM5ADgrPUEI%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "b3VaEf4wWZC%2bi6tf9oz%2boyI%2bmaDdKsfWd%2fK0pUUqD%2bY%3d",
      "team": "Helots",
      "score": "8",
      "oppScore": "11",
      "opp": "Oakgrove Boys",
      "oppId": "1zUV4kEpPc%2flA39WIyrp9j1w%2fhuIkgE6KlD9%2f%2ffQzg8%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "VafB1wWbuCK0YfNtPs%2fnjt%2b5kfDoiOlX629tt0e34Pc%3d",
      "team": "Helots",
      "score": "13",
      "oppScore": "4",
      "opp": "SUPA FC",
      "oppId": "KtH9xWy9jBGhUoOWQhn%2fSIE8Vsl4jI9MQcOiMTnZdyQ%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "Cfi7t0bg%2bDv4pUT6%2btZta4qvghzitPRYQO%2fJgd68DeY%3d",
      "team": "Helots",
      "score": "9",
      "oppScore": "8",
      "opp": "Bomb Squad",
      "oppId": "GMU0OYY8eXqf1AR6M93JxRSXSpRgEq9sJxKUtkOjXgg%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "PJRM3tBwy7QbUUqXfjydE32i3WBiW0bNt%2bfdumlUFjM%3d",
      "team": "Helots",
      "score": "6",
      "oppScore": "12",
      "opp": "Rumspringa",
      "oppId": "ZL0F50TyR6dI%2b5OluYY14WIhkuQQcvw4HafjYT2i%2bT0%3d"
    }
  ],
  "kn%2bHmtzxdjY7vxb%2fpJ9qsfEsWaDB6JrUBL7Ga4bU18M%3d": [
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "AsiS32vXHZe0%2bKbjx0zfGt6%2bNyVo0qC%2byr9c7fWnqi0%3d",
      "team": "DiG",
      "score": "14",
      "oppScore": "8",
      "opp": "Red Tide",
      "oppId": "s6HGV42d1dEo0YRx%2fIycGA2qxvKRYzzfEITLPlDYO1I%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "PacFVZt%2bh7IzI67vaaRgzQRPAp5XK9Lq1r4SzBTpvkc%3d",
      "team": "DiG",
      "score": "15",
      "oppScore": "7",
      "opp": "Needham Squad",
      "oppId": "nB%2bSQRgdJB9OGhcTAQ0lU8uZ8f%2bTFwQebHh0A8topa0%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "uOdeJ0pzen2chFFcndWsuiF8Y38btlPcca%2bqld4SsQA%3d",
      "team": "DiG",
      "score": "15",
      "oppScore": "5",
      "opp": "TireBizFriz",
      "oppId": "8V7KiKjowPKXYVA3WBFCPRK7xrpBH0Y57Z6GJCfBx3s%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "uCGdumrcPI1Bqk6F%2bjkfY8y%2f9dSc4N2ryCZqocLULd4%3d",
      "team": "DiG",
      "score": "15",
      "oppScore": "5",
      "opp": "Iditarod",
      "oppId": "7tBre8lNjeAgLNCS5dx%2fnhybe521PEeqaFyoOhXPzSk%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "CCYVZUaCEzC6tMXxjoEsM059paMfZz4bIA5WaPQ3HYk%3d",
      "team": "DiG",
      "score": "15",
      "oppScore": "6",
      "opp": "Lantern",
      "oppId": "5z%2bIWsl3V2NLqJRxKMauFZK8Pmkffh9obp1ocWQtXnY%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "A9jBmz88HrPl7865cX%2ft1soj9edG9JYy%2f0d8Cxure3o%3d",
      "team": "DiG",
      "score": "15",
      "oppScore": "4",
      "opp": "TireBizFriz",
      "oppId": "8V7KiKjowPKXYVA3WBFCPRK7xrpBH0Y57Z6GJCfBx3s%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "C6yifiderwlfUJZ2Ve6Rx65YUpG1qnTu5z6GDEm7LBY%3d",
      "team": "DiG",
      "score": "15",
      "oppScore": "7",
      "opp": "Pando",
      "oppId": "CWW3TWk5yeYKuKEW8USBkbYZz5DyfbKjfswKBgSzYsA%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "VL1bCYx3i8gODc117vldPSVjV2aQSjT%2bOvOKYJLENuA%3d",
      "team": "DiG",
      "score": "15",
      "oppScore": "11",
      "opp": "Revolver",
      "oppId": "Kem%2foxDeD8r5XN1HNW8eHeRyzfBqnWTRaPCmLck6F1I%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "uOSL4aslxZLO98Ioh53kllJ1TzTKYX08J8ZtnZUzdCg%3d",
      "team": "DiG",
      "score": "8",
      "oppScore": "15",
      "opp": "Chicago Machine",
      "oppId": "a63rCMScaUnSuWtwLw1rcbPhZh4%2bUKiYzBab%2b52i9Pk%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "Jp2NajSpSjLhHek3ML3IupTs6Xx%2frF1giCoAf%2bOPD68%3d",
      "team": "DiG",
      "score": "15",
      "oppScore": "9",
      "opp": "Emerald City",
      "oppId": "m4Q4f0lvofIPyQTpoi5YZ5nNQ2WH52F0MziY%2fWjcttE%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "bUMOagb%2bkNRUPFecQMgrkKnvk3fS1ytyQ9qRNnzIlMU%3d",
      "team": "DiG",
      "score": "13",
      "oppScore": "15",
      "opp": "Johnny Bravo",
      "oppId": "AKF9iH0VKK2mK%2fzpE9qJYeqGR%2ff%2fBETu1dm16hUGaVo%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "9fipQpSsq9f%2f9WqoJHhOQsNB75Pyt%2btOyZSBj0w97V0%3d",
      "team": "DiG",
      "score": "15",
      "oppScore": "14",
      "opp": "Chain Lightning",
      "oppId": "xMA73o8VVEaOTmaBmpN0fQZ4779P9LJ9jH3j44aVabA%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "b2",
      "team": "DiG",
      "score": "12",
      "oppScore": "13",
      "opp": "Johnny Bravo",
      "oppId": "AKF9iH0VKK2mK%2fzpE9qJYeqGR%2ff%2fBETu1dm16hUGaVo%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "b3",
      "team": "DiG",
      "score": "14",
      "oppScore": "13",
      "opp": "Sub Zero",
      "oppId": "8R4mmVt8rvJ%2bEDsQVxGD5KkmF8Rfnl3EHt4CpzxANac%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "pq1",
      "team": "DiG",
      "score": "15",
      "oppScore": "13",
      "opp": "Pittsburgh Temper",
      "oppId": "u%2bSlJ3XL7d589O5YW%2bbHhuzzJ855SfIO%2bGHlzwH3w3M%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "q1",
      "team": "DiG",
      "score": "14",
      "oppScore": "12",
      "opp": "Chicago Machine",
      "oppId": "a63rCMScaUnSuWtwLw1rcbPhZh4%2bUKiYzBab%2b52i9Pk%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "s1",
      "team": "DiG",
      "score": "12",
      "oppScore": "15",
      "opp": "PoNY",
      "oppId": "s8Hq5QrzeTvvFPbmIo%2bj5qGjiFRUrzVIivVUtEM%2bvd4%3d"
    },
    {
      "date": "September 05",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "third",
      "team": "DiG",
      "score": "11",
      "oppScore": "15",
      "opp": "Ring of Fire",
      "oppId": "smh7D9CkiNxg%2bf%2b9mPfQh2h%2fUCMicaHAcvHdBji3s6k%3d"
    },
  ],
  "EPJEJz5rBmfBf0wlHEMDiTnhdrXlFtK%2bu2m7WHlH71E%3d": [
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "dqXTAycG1Jj2F69BmpAwA0Hj5IisCg024J0iz7TuZ5g%3d",
      "team": "Club M - Manic",
      "score": "12",
      "oppScore": "9",
      "opp": "BUDA U20B",
      "oppId": "jNsNN0Cf8lhuONh44thxFg7QsW6SRNpdPPPxrQyss8I%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "T2APvj%2bzDPfzba3dkFsQTRSybX3%2fwRKqrEPRSyRmA8M%3d",
      "team": "Club M - Manic",
      "score": "15",
      "oppScore": "2",
      "opp": "Odyssey",
      "oppId": "b3BwYIqzVB8cFGN1zic0%2bcWc5J5ATsFKGuQD%2ferKcTM%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "3Giyfj3Xv1jZUKWZ9ii%2fT8rQ%2bYSon0olB%2f0Lfn2fiHk%3d",
      "team": "Club M - Manic",
      "score": "15",
      "oppScore": "4",
      "opp": "NIIIght",
      "oppId": "Mrfn10Tc53xvcYoOI6fGWhBaehYqA70hV8%2bGcc6QWF4%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "7MXmjlAkyxYViZu0IYk54jSA5yLOh5auPSs20I1QGTA%3d",
      "team": "Club M - Manic",
      "score": "10",
      "oppScore": "7",
      "opp": "Helots",
      "oppId": "m6Orv8C1KcDAyDCEIuhdmpNBKN41R0jWgAvkxa%2bLmOk%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "rgtOIkrL7eoDibIccMUgK9BPkjNOzJObXQfOM6eAXO4%3d",
      "team": "Club M - Manic",
      "score": "15",
      "oppScore": "4",
      "opp": "TROUT",
      "oppId": "bv%2fXE7f5uCQpkvM1FFa1CUcXftCxs7NptVBS7qJ1FVE%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "EmCC5B%2fb9Yx4%2b6KRukQeV7ba8Ge2ScM1A5A53A%2fEYTM%3d",
      "team": "Club M - Manic",
      "score": "15",
      "oppScore": "11",
      "opp": "Colt",
      "oppId": "csuSEMdqMABUec374gfmA4qxClU85JTTEhhJhBcJ2Do%3d"
    }
  ],
  "cD5%2b6qwajeEeZXFAZqH%2f%2fSNl6zunDFFnMpdAAl7eKv0%3d": [
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "brE4ri9bSetudlpT%2fc3KhAOX1YNwRj%2bXTAUHOGQ2PDk%3d",
      "team": "Big Wrench",
      "score": "13",
      "oppScore": "8",
      "opp": "Magma Bears",
      "oppId": "tOcZDMKk80VDM0VC5NJXyzOSixma%2fCVGplidpqww6q8%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "vq4pdMiy2LV3WsBsfGjrPlyYhnV4jgsEVwT%2bKO76ixc%3d",
      "team": "Big Wrench",
      "score": "14",
      "oppScore": "8",
      "opp": "Lantern",
      "oppId": "5z%2bIWsl3V2NLqJRxKMauFZK8Pmkffh9obp1ocWQtXnY%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "doMq9ggerbxydBDQZ5aB82AfC7anRxyqtlV4ltE7Pyw%3d",
      "team": "Big Wrench",
      "score": "9",
      "oppScore": "6",
      "opp": "BAG",
      "oppId": "0tQmFBgNfrHGaAXxTYC%2f8oP1TSA1q7cMU6hhrYmt1Y0%3d"
    },
    {
      "date": "July 16",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "aEDTGyxAeRNFYJv39gW%2f5JkgOe3xropEvSVCGbAqk%2fA%3d",
      "team": "Big Wrench",
      "score": "15",
      "oppScore": "4",
      "opp": "MKE",
      "oppId": "qHyFAYrlLVWh07Z9rgHqv1nh7SsCt5k4p8Ri0Bna7Zc%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "EGCE3R%2b12wVK3FuEl4K2SIm4A0yb94W%2bIvmzpQwn5UQ%3d",
      "team": "Big Wrench",
      "score": "9",
      "oppScore": "14",
      "opp": "TireBizFriz",
      "oppId": "8V7KiKjowPKXYVA3WBFCPRK7xrpBH0Y57Z6GJCfBx3s%3d"
    },
    {
      "date": "July 17",
      "tournament": "Boston Invite 2022",
      "tournamentId": "Boston-Invite-2022",
      "gameId": "cuTokFP4c0QiZ23N9mlPwunCqnrlzYXYO176bbCO9O0%3d",
      "team": "Big Wrench",
      "score": "10",
      "oppScore": "11",
      "opp": "Lantern",
      "oppId": "5z%2bIWsl3V2NLqJRxKMauFZK8Pmkffh9obp1ocWQtXnY%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "tNwz6%2bXgqc6sUN2sGwGVoVzb%2bvQuXZr3JP45DOiQfXQ%3d",
      "team": "Big Wrench",
      "score": "13",
      "oppScore": "2",
      "opp": "Rebels",
      "oppId": "uV%2beAif391mDFcejwcWVicj%2fYkXfD%2fwqKg5TtqrsFno%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "TWcCCAjTIqA8DCBs3fJjLJY2k30wyTpqzyLmaS35Its%3d",
      "team": "Big Wrench",
      "score": "13",
      "oppScore": "6",
      "opp": "Spring Break '93",
      "oppId": "hRUlbCXzaOf83wDSPKpV36LeX3FmlXgFaE5wdndlB3Q%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "9OURp8nob6gLac0XejPpUKti%2fwvISShcrJQKqC7QHBI%3d",
      "team": "Big Wrench",
      "score": "13",
      "oppScore": "10",
      "opp": "Rumspringa",
      "oppId": "ZL0F50TyR6dI%2b5OluYY14WIhkuQQcvw4HafjYT2i%2bT0%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "DbxS8Y4bMXDScYXbNkisbOfvGWXQRt6%2fH8wLs0zs9us%3d",
      "team": "Big Wrench",
      "score": "5",
      "oppScore": "9",
      "opp": "BAG",
      "oppId": "0tQmFBgNfrHGaAXxTYC%2f8oP1TSA1q7cMU6hhrYmt1Y0%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "OL7Wlmkyz1QPYKnrduVmz5mFb0Bj6YdBNT5GL0mAYh4%3d",
      "team": "Big Wrench",
      "score": "9",
      "oppScore": "3",
      "opp": "Brickhouse",
      "oppId": "b5ARnVg7OngG1a0%2fJuMZTHluwgNFe%2bN5esQIIKsEOUY%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "8UMAawBQP4CFaRFU6CM%2bvDS%2bo2dMfiLB2BbNyiLMs3g%3d",
      "team": "Big Wrench",
      "score": "9",
      "oppScore": "8",
      "opp": "Oakgrove Boys",
      "oppId": "1zUV4kEpPc%2flA39WIyrp9j1w%2fhuIkgE6KlD9%2f%2ffQzg8%3d"
    }
  ],
  "IINBHzSyAD1qveaivUllPpQBPkMwjzVWcFo4s84DFtc%3d": [
    {
      "date": "July 16",
      "tournament": "Philly Special 2022",
      "tournamentId": "Philly-Special-2022",
      "gameId": "R%2bfosar%2fB4a8XTpI9hzWDA7EPFIbKzHR%2b6nw8fkeAAg%3d",
      "team": "Adelphos",
      "score": "12",
      "oppScore": "15",
      "opp": "Town Hall Stars",
      "oppId": "2Kd8tzcR0KA7rHRMTMb17b6HIPSIH%2bVBl3400ISNCtw%3d"
    },
    {
      "date": "July 16",
      "tournament": "Philly Special 2022",
      "tournamentId": "Philly-Special-2022",
      "gameId": "g%2bDuGP3%2fwaQgb%2bLSIl2E%2fOE8QHv8QJ1ZmX94y1lq438%3d",
      "team": "Adelphos",
      "score": "9",
      "oppScore": "15",
      "opp": "John Doe",
      "oppId": "U8i%2ffBl%2bGdNRXUaehdkV4W9lOM4QHUciytmWcy3oxXI%3d"
    },
    {
      "date": "July 16",
      "tournament": "Philly Special 2022",
      "tournamentId": "Philly-Special-2022",
      "gameId": "KW3pP0BPh8urO53nl%2frLvfXNQ9%2fAf4FIaAlRPRjMtIs%3d",
      "team": "Adelphos",
      "score": "7",
      "oppScore": "15",
      "opp": "Bomb Squad",
      "oppId": "GMU0OYY8eXqf1AR6M93JxRSXSpRgEq9sJxKUtkOjXgg%3d"
    },
    {
      "date": "July 17",
      "tournament": "Philly Special 2022",
      "tournamentId": "Philly-Special-2022",
      "gameId": "i0BXFIoHPq1b6vBFmHgFTByY%2b00CmXe2fe2kRGEAW1s%3d",
      "team": "Adelphos",
      "score": "11",
      "oppScore": "15",
      "opp": "John Doe",
      "oppId": "U8i%2ffBl%2bGdNRXUaehdkV4W9lOM4QHUciytmWcy3oxXI%3d"
    },
    {
      "date": "July 17",
      "tournament": "Philly Special 2022",
      "tournamentId": "Philly-Special-2022",
      "gameId": "OYEa8MJ%2bPw43x7qRZx7HtjhGOX1aCHEs%2bx6mVhtk2%2fg%3d",
      "team": "Adelphos",
      "score": "10",
      "oppScore": "12",
      "opp": "Bomb Squad",
      "oppId": "GMU0OYY8eXqf1AR6M93JxRSXSpRgEq9sJxKUtkOjXgg%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "rTVs8qe86Vt6w87ktRBfESm2Gerj4zcXSIg62YNp2kQ%3d",
      "team": "Adelphos",
      "score": "5",
      "oppScore": "12",
      "opp": "Bomb Squad",
      "oppId": "GMU0OYY8eXqf1AR6M93JxRSXSpRgEq9sJxKUtkOjXgg%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "674Pv4vLP7Ux%2bhO5ThIX5T%2fSAbO4IyQGQ7FBUGpn3zI%3d",
      "team": "Adelphos",
      "score": "6",
      "oppScore": "10",
      "opp": "Colt",
      "oppId": "csuSEMdqMABUec374gfmA4qxClU85JTTEhhJhBcJ2Do%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "alfYx1D9EjWuUpWGc%2fUiFAhQJOcOLF3ByQJpri7hI8M%3d",
      "team": "Adelphos",
      "score": "3",
      "oppScore": "12",
      "opp": "BAG",
      "oppId": "0tQmFBgNfrHGaAXxTYC%2f8oP1TSA1q7cMU6hhrYmt1Y0%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "4obQ2wP7JmZW1ylh20lozUUKeG7xa02sz%2fZZ6EEkXyg%3d",
      "team": "Adelphos",
      "score": "9",
      "oppScore": "7",
      "opp": "Winc City Fog of War",
      "oppId": "6gsiUt3INMB4Td2RTxJkksYpRIJsACnjptCfHUZeg2o%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "3vsG1DaNnOlXPPf%2brJ9C0UH72L45YmyhnODVXAqNOvg%3d",
      "team": "Adelphos",
      "score": "7",
      "oppScore": "11",
      "opp": "Chimney",
      "oppId": "jzVhyLybRfcABTsshmdvgsxYDSASMTHMGNde%2bkQTb4Y%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "R6Iu50zsBfUJTNhp5WAE0PHj%2fkG06IxyqLAbhm41iHE%3d",
      "team": "Adelphos",
      "score": "7",
      "oppScore": "8",
      "opp": "JAWN",
      "oppId": "ZmiNobvozutOTlvBTZEmqu8bW1a0Lvp6PqGmIiNlkGU%3d"
    }
  ],
  "2Kd8tzcR0KA7rHRMTMb17b6HIPSIH%2bVBl3400ISNCtw%3d": [
    {
      "date": "July 16",
      "tournament": "Philly Special 2022",
      "tournamentId": "Philly-Special-2022",
      "gameId": "R%2bfosar%2fB4a8XTpI9hzWDA7EPFIbKzHR%2b6nw8fkeAAg%3d",
      "team": "Town Hall Stars",
      "score": "15",
      "oppScore": "12",
      "opp": "Adelphos",
      "oppId": "IINBHzSyAD1qveaivUllPpQBPkMwjzVWcFo4s84DFtc%3d"
    },
    {
      "date": "July 16",
      "tournament": "Philly Special 2022",
      "tournamentId": "Philly-Special-2022",
      "gameId": "4fVM4T93ZmHKgQWHJYMVwBvZmu3q%2bPv%2f4AZbbWiFTO0%3d",
      "team": "Town Hall Stars",
      "score": "15",
      "oppScore": "8",
      "opp": "Bomb Squad",
      "oppId": "GMU0OYY8eXqf1AR6M93JxRSXSpRgEq9sJxKUtkOjXgg%3d"
    },
    {
      "date": "July 16",
      "tournament": "Philly Special 2022",
      "tournamentId": "Philly-Special-2022",
      "gameId": "Fli5QPuxuxDGRgt7mGyD7sjI5gwj%2bnge8hJZGIl57QI%3d",
      "team": "Town Hall Stars",
      "score": "15",
      "oppScore": "11",
      "opp": "John Doe",
      "oppId": "U8i%2ffBl%2bGdNRXUaehdkV4W9lOM4QHUciytmWcy3oxXI%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "1cc2tD0m2urWOU2OUpP7gye18st7LK5sY8CRpeQvOo0%3d",
      "team": "Town Hall Stars",
      "score": "4",
      "oppScore": "13",
      "opp": "Blueprint",
      "oppId": "BDhLSFxnViHodVQ9noIy0%2by0HWSmiJ3qgV5WI1ozsAk%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "eVR8VXD8vK4%2bqmwINSGciWVGtzFzgHklDYz%2bJOAvqus%3d",
      "team": "Town Hall Stars",
      "score": "6",
      "oppScore": "10",
      "opp": "SUPA FC",
      "oppId": "KtH9xWy9jBGhUoOWQhn%2fSIE8Vsl4jI9MQcOiMTnZdyQ%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "aVs%2bGDte6X4wq7Na5fCwuJ8JRKbEaoTx%2bzP1ugma0ns%3d",
      "team": "Town Hall Stars",
      "score": "13",
      "oppScore": "4",
      "opp": "Queen City Kings",
      "oppId": "95yP4SyRqRX7HgLtZ9jNth95JPB163KSMqpgMxymy8g%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "fy7dFJQrKc3p8kd1k%2fFqKEtRg0%2bDux%2fG088eTZZr3mI%3d",
      "team": "Town Hall Stars",
      "score": "8",
      "oppScore": "13",
      "opp": "JAWN",
      "oppId": "ZmiNobvozutOTlvBTZEmqu8bW1a0Lvp6PqGmIiNlkGU%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "1AAUommDt1Vm2mmtbUZTz8JGXpoXEGNqGf3fUJmOZrw%3d",
      "team": "Town Hall Stars",
      "score": "11",
      "oppScore": "3",
      "opp": "MOB Ultimate",
      "oppId": "otuSqjNVhCJZE0ojAe0x6mw6GKk1Ux8ciM5ADgrPUEI%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "VJWKtUz9yE2bCprx99dXg2cFa3rQBa6k2c0HOuNP78c%3d",
      "team": "Town Hall Stars",
      "score": "11",
      "oppScore": "7",
      "opp": "Winc City Fog of War",
      "oppId": "6gsiUt3INMB4Td2RTxJkksYpRIJsACnjptCfHUZeg2o%3d"
    }
  ],
  "uce10clG%2fWf3GrKUxwKToSDTqXSp6FS58Fb50TN%2fPwU%3d": [
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "3gsZ%2fD%2fttRGpgtsxskYNhul92XfkQmF8tUqcmKiyDiM%3d",
      "team": "baNC",
      "score": "11",
      "oppScore": "8",
      "opp": "Triumph",
      "oppId": "wAZDt10Q5MoiqMIAGOgPxedaQ2KA3QCSPn7hC8W%2bn5s%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "8s9PhiYe0oGSpWP7e8Kx2S6GfCL2G3KCvHNqoFEeehs%3d",
      "team": "baNC",
      "score": "11",
      "oppScore": "5",
      "opp": "Rush Hour ATL",
      "oppId": "LdH2zJ494O%2bxdz6p92IsLTMaAmjgf6aP%2fBvM9Lr1RaQ%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "SDAzlaSf4sKq8utR7jJ9ESrjuyZ%2f%2bxZe23hASoELEUY%3d",
      "team": "baNC",
      "score": "11",
      "oppScore": "10",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "%2bVCCL2XriOo22BENs4RSuXCl%2bKUKgK75SDrlCHVrZQc%3d",
      "team": "baNC",
      "score": "10",
      "oppScore": "11",
      "opp": "Space Cowboys",
      "oppId": "3%2bZ1uFiVej3zLASHWkgqXkJ7hNQ98n84b2FvR4T3pQA%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "DwMyo3o0keU6%2fTqs%2fHhUR87mzhWQUrMcQzk5%2b%2fg77vc%3d",
      "team": "baNC",
      "score": "11",
      "oppScore": "4",
      "opp": "Nashville Mudcats",
      "oppId": "m1V5goSU%2bO1BR4XRhH%2fyGq44j%2f4hNKcb%2bBApPx5jVzQ%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "2Y%2bNN9fJuIlkxRV%2ffGB60Ee9MGR7I1S2%2bZ%2bCFrBdIKM%3d",
      "team": "baNC",
      "score": "10",
      "oppScore": "9",
      "opp": "Charleston Heat Stroke",
      "oppId": "quSAXJHtjRXSWAHbdztMDD8ftXgRqlTrsEfTBm793Nk%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "GSVfHGZJpMT7r6d6UHoJtyhHf0iNjX22jSQkoisyKQI%3d",
      "team": "baNC",
      "score": "9",
      "oppScore": "13",
      "opp": "Brickhouse",
      "oppId": "b5ARnVg7OngG1a0%2fJuMZTHluwgNFe%2bN5esQIIKsEOUY%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "GoimM%2fhQ3JgQOB5skjQgx%2babDi6BHvAncz3t5O5ceeo%3d",
      "team": "baNC",
      "score": "11",
      "oppScore": "7",
      "opp": "Richmond Floodwall",
      "oppId": "fa1yMF%2fsPXZM%2b%2f4My9LlUixC%2bMFxnwM7gPH410zO6EA%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "GLldJ4ZTVOENEY4QfrKCkGdMMFXLquk5Vd7FuKjao1c%3d",
      "team": "baNC",
      "score": "10",
      "oppScore": "11",
      "opp": "Brickhouse",
      "oppId": "b5ARnVg7OngG1a0%2fJuMZTHluwgNFe%2bN5esQIIKsEOUY%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "ClaR0n%2bC5OOvdnVCG2Xd%2f7bzdfOvKSOa5bR08hWe610%3d",
      "team": "baNC",
      "score": "11",
      "oppScore": "10",
      "opp": "Redwolves",
      "oppId": "DoL%2frShNUJHM7OLFhcsMzWlsS0aohpfm30HvDbpVZi4%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "YO2KBD21zBMnZZ%2f3dyjJ%2bMcYiZoKHV5rSgisGSgLBOI%3d",
      "team": "baNC",
      "score": "11",
      "oppScore": "2",
      "opp": "Triumph",
      "oppId": "wAZDt10Q5MoiqMIAGOgPxedaQ2KA3QCSPn7hC8W%2bn5s%3d"
    }
  ],
  "quSAXJHtjRXSWAHbdztMDD8ftXgRqlTrsEfTBm793Nk%3d": [
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "Fhn8%2bQgvHy1wwm8ZzRz79nHZulWw%2f3Zbx8N0t2mUYLE%3d",
      "team": "Charleston Heat Stroke",
      "score": "11",
      "oppScore": "0",
      "opp": "Queen City Kings",
      "oppId": "95yP4SyRqRX7HgLtZ9jNth95JPB163KSMqpgMxymy8g%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "IjpZ2KO9V8jCwxgCPewzNLxJeOcoEqvB7qPR2bG9wJM%3d",
      "team": "Charleston Heat Stroke",
      "score": "11",
      "oppScore": "7",
      "opp": "Tennessee Folklore",
      "oppId": "z7Hwsz316C%2bFf9Cg1gKP3NGBSqxMqnFjQRbeyMUhUD4%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "pJErF4uqIANtts9LrjyC2aNC0k7qwGHtcdsQ6IzVrr0%3d",
      "team": "Charleston Heat Stroke",
      "score": "7",
      "oppScore": "11",
      "opp": "Brickhouse",
      "oppId": "b5ARnVg7OngG1a0%2fJuMZTHluwgNFe%2bN5esQIIKsEOUY%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "vf55Xk87d%2f3KMqa9DNNb0CZBZ4g%2fiN32EKkc4HwRnB4%3d",
      "team": "Charleston Heat Stroke",
      "score": "11",
      "oppScore": "2",
      "opp": "Scoop",
      "oppId": "Rj8mJfKJry2xyWaxTIh5HdXGLaTMxr7%2fXjyL4uJET2o%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "MxPFfSUMqyDNEgnUF5uqdMcBZGHmi%2f6gwCeQQasTBFA%3d",
      "team": "Charleston Heat Stroke",
      "score": "9",
      "oppScore": "8",
      "opp": "Dyno",
      "oppId": "fIw72i6bkbJnjTbK02aFLpJ9Qy2lCUVWlRBvI%2b8UmIc%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "2Y%2bNN9fJuIlkxRV%2ffGB60Ee9MGR7I1S2%2bZ%2bCFrBdIKM%3d",
      "team": "Charleston Heat Stroke",
      "score": "9",
      "oppScore": "10",
      "opp": "baNC",
      "oppId": "uce10clG%2fWf3GrKUxwKToSDTqXSp6FS58Fb50TN%2fPwU%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "vVbzrt3s13DS0y6OhAtP0b%2bjs97bujLpxNYc43TNbMU%3d",
      "team": "Charleston Heat Stroke",
      "score": "9",
      "oppScore": "7",
      "opp": "Rush Hour ATL",
      "oppId": "LdH2zJ494O%2bxdz6p92IsLTMaAmjgf6aP%2fBvM9Lr1RaQ%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "ACWS0tUcuwXknqsvTagVV82V9kakEn535ps7Djcn5h0%3d",
      "team": "Charleston Heat Stroke",
      "score": "11",
      "oppScore": "13",
      "opp": "Space Cowboys",
      "oppId": "3%2bZ1uFiVej3zLASHWkgqXkJ7hNQ98n84b2FvR4T3pQA%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "2SRdkBAvXUQKc47%2fBwGqwPxgwd5V2TedKfTXzLfF0%2fM%3d",
      "team": "Charleston Heat Stroke",
      "score": "7",
      "oppScore": "13",
      "opp": "Raleigh-Durham United",
      "oppId": "ESHhN%2bMK1oy0j%2bQJSL8mb9cq8h30Erb63T07U8pubEc%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "Z4i0iNWfL%2fh8ZszTNof8keqALOQGd4baiDVvcTS6oCE%3d",
      "team": "Charleston Heat Stroke",
      "score": "8",
      "oppScore": "13",
      "opp": "Oakgrove Boys",
      "oppId": "1zUV4kEpPc%2flA39WIyrp9j1w%2fhuIkgE6KlD9%2f%2ffQzg8%3d"
    }
  ],
  "wAZDt10Q5MoiqMIAGOgPxedaQ2KA3QCSPn7hC8W%2bn5s%3d": [
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "3gsZ%2fD%2fttRGpgtsxskYNhul92XfkQmF8tUqcmKiyDiM%3d",
      "team": "Triumph",
      "score": "8",
      "oppScore": "11",
      "opp": "baNC",
      "oppId": "uce10clG%2fWf3GrKUxwKToSDTqXSp6FS58Fb50TN%2fPwU%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "8Qs27sFcPYIXIlxTOvx0QydEYFLDznoMlw8SXe5Z94M%3d",
      "team": "Triumph",
      "score": "8",
      "oppScore": "11",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "A3g3fh%2bwNFvaQYzZXRdDt8vckFaH57Vw8bENHVdN4hI%3d",
      "team": "Triumph",
      "score": "11",
      "oppScore": "4",
      "opp": "Nashville Mudcats",
      "oppId": "m1V5goSU%2bO1BR4XRhH%2fyGq44j%2f4hNKcb%2bBApPx5jVzQ%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "xRvXBN4JFcglRRiaCTLpJsF3ovdv7VNp8DJX4JX67e4%3d",
      "team": "Triumph",
      "score": "4",
      "oppScore": "11",
      "opp": "Rush Hour ATL",
      "oppId": "LdH2zJ494O%2bxdz6p92IsLTMaAmjgf6aP%2fBvM9Lr1RaQ%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "yoL2W5W4u5%2b9zJXeB5sijhenYR7KHzUIRvFZ5%2bo4UrU%3d",
      "team": "Triumph",
      "score": "11",
      "oppScore": "2",
      "opp": "Space Cowboys",
      "oppId": "3%2bZ1uFiVej3zLASHWkgqXkJ7hNQ98n84b2FvR4T3pQA%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "EnRvC16WqsVeLX7NVxUM0pdOQ6OXMaxBAEsyKCKOGqY%3d",
      "team": "Triumph",
      "score": "8",
      "oppScore": "12",
      "opp": "Dyno",
      "oppId": "fIw72i6bkbJnjTbK02aFLpJ9Qy2lCUVWlRBvI%2b8UmIc%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "BqIiP7O%2ft22jwN2og36buVMR%2bOtacjsP5rtE90goIuM%3d",
      "team": "Triumph",
      "score": "W",
      "oppScore": "F",
      "opp": "Tennessee Folklore",
      "oppId": "z7Hwsz316C%2bFf9Cg1gKP3NGBSqxMqnFjQRbeyMUhUD4%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "jfLFNv4mKmiTR0eKPzp9f%2b6kyfOBaozJbjROjJ%2bOUkc%3d",
      "team": "Triumph",
      "score": "7",
      "oppScore": "11",
      "opp": "Lost Boys",
      "oppId": "aNMyR0uAJNwnidjeClZAMqixGjtlQqA6k7CiZP1R0%2bM%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "dFIFJAoVprG%2fu9%2fXsjrM5am%2ffU4ZzBQiof4BYmzhfRU%3d",
      "team": "Triumph",
      "score": "11",
      "oppScore": "8",
      "opp": "Richmond Floodwall",
      "oppId": "fa1yMF%2fsPXZM%2b%2f4My9LlUixC%2bMFxnwM7gPH410zO6EA%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "GPKVaPul%2bWSVNQjmhRdl8UUjRhsqI3j71KvnsLcyEYk%3d",
      "team": "Triumph",
      "score": "5",
      "oppScore": "11",
      "opp": "Brickhouse",
      "oppId": "b5ARnVg7OngG1a0%2fJuMZTHluwgNFe%2bN5esQIIKsEOUY%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "YO2KBD21zBMnZZ%2f3dyjJ%2bMcYiZoKHV5rSgisGSgLBOI%3d",
      "team": "Triumph",
      "score": "2",
      "oppScore": "11",
      "opp": "baNC",
      "oppId": "uce10clG%2fWf3GrKUxwKToSDTqXSp6FS58Fb50TN%2fPwU%3d"
    }
  ],
  "b5ARnVg7OngG1a0%2fJuMZTHluwgNFe%2bN5esQIIKsEOUY%3d": [
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "bUhvPyVC5Xcm6gu6ASHeVFLRbHze8Wz%2f8y3NLik6%2fCA%3d",
      "team": "Brickhouse",
      "score": "11",
      "oppScore": "5",
      "opp": "Tennessee Folklore",
      "oppId": "z7Hwsz316C%2bFf9Cg1gKP3NGBSqxMqnFjQRbeyMUhUD4%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "TKghAeos5oVs%2byO2vz%2fYhVs3thZVNLpq%2bLMnAGvjFJg%3d",
      "team": "Brickhouse",
      "score": "11",
      "oppScore": "3",
      "opp": "Dyno",
      "oppId": "fIw72i6bkbJnjTbK02aFLpJ9Qy2lCUVWlRBvI%2b8UmIc%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "pJErF4uqIANtts9LrjyC2aNC0k7qwGHtcdsQ6IzVrr0%3d",
      "team": "Brickhouse",
      "score": "11",
      "oppScore": "7",
      "opp": "Charleston Heat Stroke",
      "oppId": "quSAXJHtjRXSWAHbdztMDD8ftXgRqlTrsEfTBm793Nk%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "e4gMv879RJnHz3F5I1wcNQoH775PHkHPZgXYV%2bDQQ30%3d",
      "team": "Brickhouse",
      "score": "11",
      "oppScore": "1",
      "opp": "Queen City Kings",
      "oppId": "95yP4SyRqRX7HgLtZ9jNth95JPB163KSMqpgMxymy8g%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "ke%2biFsCto2HrVVYShE6KWBnyzHEyYIc3V3101rmdppo%3d",
      "team": "Brickhouse",
      "score": "11",
      "oppScore": "4",
      "opp": "Scoop",
      "oppId": "Rj8mJfKJry2xyWaxTIh5HdXGLaTMxr7%2fXjyL4uJET2o%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "6OHPwc703s0LkeV4E37LKaWOiRt7XAYq75muPy56u3c%3d",
      "team": "Brickhouse",
      "score": "13",
      "oppScore": "9",
      "opp": "Rush Hour ATL",
      "oppId": "LdH2zJ494O%2bxdz6p92IsLTMaAmjgf6aP%2fBvM9Lr1RaQ%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "GSVfHGZJpMT7r6d6UHoJtyhHf0iNjX22jSQkoisyKQI%3d",
      "team": "Brickhouse",
      "score": "13",
      "oppScore": "9",
      "opp": "baNC",
      "oppId": "uce10clG%2fWf3GrKUxwKToSDTqXSp6FS58Fb50TN%2fPwU%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "zRNqb8jT8BQo3cSQt9V5Qk%2fIRocJD7iwG1Zcx3nDtVs%3d",
      "team": "Brickhouse",
      "score": "13",
      "oppScore": "7",
      "opp": "Hazard",
      "oppId": "RBbGte9ro%2fCZWrmOmcHNTdHB41zGQFb64ubBkZjcTEI%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "5DUJRHQdcGdnb%2b4kg783rarsRTx5XHfq7er3U5Qp7a0%3d",
      "team": "Brickhouse",
      "score": "13",
      "oppScore": "4",
      "opp": "Richmond Floodwall",
      "oppId": "fa1yMF%2fsPXZM%2b%2f4My9LlUixC%2bMFxnwM7gPH410zO6EA%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "ZlBhgxWKuWaEkiSPJdRFdSnrF0XZ4ngQmM0YjyuPRaY%3d",
      "team": "Brickhouse",
      "score": "9",
      "oppScore": "11",
      "opp": "Red Tide",
      "oppId": "s6HGV42d1dEo0YRx%2fIycGA2qxvKRYzzfEITLPlDYO1I%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "97YA9h5gKbpI2LktAUpRiAwXuBsCx0F25R45b7x3%2bAc%3d",
      "team": "Brickhouse",
      "score": "7",
      "oppScore": "13",
      "opp": "Redwolves",
      "oppId": "DoL%2frShNUJHM7OLFhcsMzWlsS0aohpfm30HvDbpVZi4%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "OL7Wlmkyz1QPYKnrduVmz5mFb0Bj6YdBNT5GL0mAYh4%3d",
      "team": "Brickhouse",
      "score": "3",
      "oppScore": "9",
      "opp": "Big Wrench",
      "oppId": "cD5%2b6qwajeEeZXFAZqH%2f%2fSNl6zunDFFnMpdAAl7eKv0%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "FScsYrlwr4OqfhgGBIgkrg7B%2b7FTIGQ3LSmd7ZvyoIw%3d",
      "team": "Brickhouse",
      "score": "4",
      "oppScore": "5",
      "opp": "Pittsburgh Stealers",
      "oppId": "icSGwphEBV6tcYJLRwPxEC22s0bt6rct5HG5ubjyetw%3d"
    },
    {
      "date": "August 20",
      "tournament": "Raleigh Round Robin",
      "tournamentId": "Raleigh-Round-Robin",
      "gameId": "bnBOkTmc6or9kyGObrR1W0wTSbL%2f9PoJD43OvUPbx60%3d",
      "team": "Brickhouse",
      "score": "5",
      "oppScore": "13",
      "opp": "Chain Lightning",
      "oppId": "xMA73o8VVEaOTmaBmpN0fQZ4779P9LJ9jH3j44aVabA%3d"
    },
    {
      "date": "August 21",
      "tournament": "Raleigh Round Robin",
      "tournamentId": "Raleigh-Round-Robin",
      "gameId": "DKYE5j3wK7IIbE5YtFW1RA8faX4SdkRB5bduFVKdR58%3d",
      "team": "Brickhouse",
      "score": "2",
      "oppScore": "13",
      "opp": "Ring of Fire",
      "oppId": "smh7D9CkiNxg%2bf%2b9mPfQh2h%2fUCMicaHAcvHdBji3s6k%3d"
    },
    {
      "date": "August 21",
      "tournament": "Raleigh Round Robin",
      "tournamentId": "Raleigh-Round-Robin",
      "gameId": "WYXLSqtNkN2Ct5rhkyd%2fUPZ3%2fZzxWEY1XpXsd9kEk3Q%3d",
      "team": "Brickhouse",
      "score": "8",
      "oppScore": "11",
      "opp": "Raleigh-Durham United",
      "oppId": "ESHhN%2bMK1oy0j%2bQJSL8mb9cq8h30Erb63T07U8pubEc%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "s78RxB%2fgzZAZ0BKSnEjp9JlizVAIlsfhjT1zRFuco0k%3d",
      "team": "Brickhouse",
      "score": "11",
      "oppScore": "10",
      "opp": "Redwolves",
      "oppId": "DoL%2frShNUJHM7OLFhcsMzWlsS0aohpfm30HvDbpVZi4%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "GLldJ4ZTVOENEY4QfrKCkGdMMFXLquk5Vd7FuKjao1c%3d",
      "team": "Brickhouse",
      "score": "11",
      "oppScore": "10",
      "opp": "baNC",
      "oppId": "uce10clG%2fWf3GrKUxwKToSDTqXSp6FS58Fb50TN%2fPwU%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "GPKVaPul%2bWSVNQjmhRdl8UUjRhsqI3j71KvnsLcyEYk%3d",
      "team": "Brickhouse",
      "score": "11",
      "oppScore": "5",
      "opp": "Triumph",
      "oppId": "wAZDt10Q5MoiqMIAGOgPxedaQ2KA3QCSPn7hC8W%2bn5s%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "A8IhiyBZZc0fdva7%2f6WOM5ULuoaXr4sUk61VzTABHpo%3d",
      "team": "Brickhouse",
      "score": "6",
      "oppScore": "11",
      "opp": "Lost Boys",
      "oppId": "aNMyR0uAJNwnidjeClZAMqixGjtlQqA6k7CiZP1R0%2bM%3d"
    }
  ],
  "95yP4SyRqRX7HgLtZ9jNth95JPB163KSMqpgMxymy8g%3d": [
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "Fhn8%2bQgvHy1wwm8ZzRz79nHZulWw%2f3Zbx8N0t2mUYLE%3d",
      "team": "Queen City Kings",
      "score": "0",
      "oppScore": "11",
      "opp": "Charleston Heat Stroke",
      "oppId": "quSAXJHtjRXSWAHbdztMDD8ftXgRqlTrsEfTBm793Nk%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "xogU3BOqHGpjhm%2b9GqMAsD2W0JzAP3SU5sYHsiQe0cM%3d",
      "team": "Queen City Kings",
      "score": "11",
      "oppScore": "10",
      "opp": "Scoop",
      "oppId": "Rj8mJfKJry2xyWaxTIh5HdXGLaTMxr7%2fXjyL4uJET2o%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "AoOoGB%2fHm0KK7YM8ZtJOYe%2bQpmqEMsjZ%2fjzO5iv9%2bF0%3d",
      "team": "Queen City Kings",
      "score": "0",
      "oppScore": "11",
      "opp": "Dyno",
      "oppId": "fIw72i6bkbJnjTbK02aFLpJ9Qy2lCUVWlRBvI%2b8UmIc%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "e4gMv879RJnHz3F5I1wcNQoH775PHkHPZgXYV%2bDQQ30%3d",
      "team": "Queen City Kings",
      "score": "1",
      "oppScore": "11",
      "opp": "Brickhouse",
      "oppId": "b5ARnVg7OngG1a0%2fJuMZTHluwgNFe%2bN5esQIIKsEOUY%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "Xl6bi1gdChgh7RzW1BXDL3r5DDBVl0S%2bmRNAVzeWXLE%3d",
      "team": "Queen City Kings",
      "score": "10",
      "oppScore": "11",
      "opp": "Tennessee Folklore",
      "oppId": "z7Hwsz316C%2bFf9Cg1gKP3NGBSqxMqnFjQRbeyMUhUD4%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "h7WbS%2bFuGGR1dJknwhRvTHeQ4qRTWLMBNFE8b5eV8S8%3d",
      "team": "Queen City Kings",
      "score": "6",
      "oppScore": "13",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "Md1sMVw6IyG0%2bczLkefVlzDWAHu70uvPm3VhvcHbCOE%3d",
      "team": "Queen City Kings",
      "score": "11",
      "oppScore": "8",
      "opp": "Scoop",
      "oppId": "Rj8mJfKJry2xyWaxTIh5HdXGLaTMxr7%2fXjyL4uJET2o%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "JnvNtMnD5XFwgs4qO76tKfA4MGHNaPmIv4%2bI1VOKCr0%3d",
      "team": "Queen City Kings",
      "score": "6",
      "oppScore": "13",
      "opp": "SUPA FC",
      "oppId": "KtH9xWy9jBGhUoOWQhn%2fSIE8Vsl4jI9MQcOiMTnZdyQ%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "TU6To86r%2b7rhxfXnX%2fovgmDaJ5zqUQj7MK1dKeWwQ8s%3d",
      "team": "Queen City Kings",
      "score": "1",
      "oppScore": "13",
      "opp": "Blueprint",
      "oppId": "BDhLSFxnViHodVQ9noIy0%2by0HWSmiJ3qgV5WI1ozsAk%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "aVs%2bGDte6X4wq7Na5fCwuJ8JRKbEaoTx%2bzP1ugma0ns%3d",
      "team": "Queen City Kings",
      "score": "4",
      "oppScore": "13",
      "opp": "Town Hall Stars",
      "oppId": "2Kd8tzcR0KA7rHRMTMb17b6HIPSIH%2bVBl3400ISNCtw%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "%2br8kNsGJ5kgo35TRbDExM3WFAQCZbL8q3nhwY7yCdrg%3d",
      "team": "Queen City Kings",
      "score": "6",
      "oppScore": "8",
      "opp": "Richmond Floodwall",
      "oppId": "fa1yMF%2fsPXZM%2b%2f4My9LlUixC%2bMFxnwM7gPH410zO6EA%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "txoS8VbJV7ELZ3suxaWmokxxKsqkfiXp6qdaoDesDik%3d",
      "team": "Queen City Kings",
      "score": "10",
      "oppScore": "8",
      "opp": "Rebels",
      "oppId": "uV%2beAif391mDFcejwcWVicj%2fYkXfD%2fwqKg5TtqrsFno%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "2ZHTEoMbVCLEGZ%2f4v3HmQtlcZUZ1wx1nwxB9G5zR1uw%3d",
      "team": "Queen City Kings",
      "score": "0",
      "oppScore": "1",
      "opp": "Deepfake",
      "oppId": "MjCJCQyu%2bVgQCxsNuCDsIlsaBlb84XeNodQMva1Vl6s%3d"
    }
  ],
  "Rj8mJfKJry2xyWaxTIh5HdXGLaTMxr7%2fXjyL4uJET2o%3d": [
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "eCqzNzeNSUfR55lDOqrhElt464Xlq02X2KtT0B8Ezmk%3d",
      "team": "Scoop",
      "score": "4",
      "oppScore": "11",
      "opp": "Dyno",
      "oppId": "fIw72i6bkbJnjTbK02aFLpJ9Qy2lCUVWlRBvI%2b8UmIc%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "xogU3BOqHGpjhm%2b9GqMAsD2W0JzAP3SU5sYHsiQe0cM%3d",
      "team": "Scoop",
      "score": "10",
      "oppScore": "11",
      "opp": "Queen City Kings",
      "oppId": "95yP4SyRqRX7HgLtZ9jNth95JPB163KSMqpgMxymy8g%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "z%2fxz7Fia9JldngswLWLQSdb7%2b%2b9FDiK18UciLEC26j4%3d",
      "team": "Scoop",
      "score": "3",
      "oppScore": "11",
      "opp": "Tennessee Folklore",
      "oppId": "z7Hwsz316C%2bFf9Cg1gKP3NGBSqxMqnFjQRbeyMUhUD4%3d"
    },
    {
      "date": "July 23",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "vf55Xk87d%2f3KMqa9DNNb0CZBZ4g%2fiN32EKkc4HwRnB4%3d",
      "team": "Scoop",
      "score": "2",
      "oppScore": "11",
      "opp": "Charleston Heat Stroke",
      "oppId": "quSAXJHtjRXSWAHbdztMDD8ftXgRqlTrsEfTBm793Nk%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "ke%2biFsCto2HrVVYShE6KWBnyzHEyYIc3V3101rmdppo%3d",
      "team": "Scoop",
      "score": "4",
      "oppScore": "11",
      "opp": "Brickhouse",
      "oppId": "b5ARnVg7OngG1a0%2fJuMZTHluwgNFe%2bN5esQIIKsEOUY%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "qHUxx%2f1mZlbcQNtxQ1W9%2bouzfJcHiuOWLf%2bd1bX9dhQ%3d",
      "team": "Scoop",
      "score": "11",
      "oppScore": "13",
      "opp": "Nashville Mudcats",
      "oppId": "m1V5goSU%2bO1BR4XRhH%2fyGq44j%2f4hNKcb%2bBApPx5jVzQ%3d"
    },
    {
      "date": "July 24",
      "tournament": "Filling the Void",
      "tournamentId": "Filling-the-Void",
      "gameId": "Md1sMVw6IyG0%2bczLkefVlzDWAHu70uvPm3VhvcHbCOE%3d",
      "team": "Scoop",
      "score": "8",
      "oppScore": "11",
      "opp": "Queen City Kings",
      "oppId": "95yP4SyRqRX7HgLtZ9jNth95JPB163KSMqpgMxymy8g%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "wezNQB5PcgAXNagFVq611YXHb3qwKxWMCo09Bkl8H7E%3d",
      "team": "Scoop",
      "score": "13",
      "oppScore": "7",
      "opp": "FLI-B Honeybees",
      "oppId": "A%2bfgoEP0pWAon7RlAWF7INXWdOLczWdCDnKVBK6W%2btY%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "iG%2fyOlqKAWDuQO5xOUH3rSsAHplGxUte7wkFwnMxB3w%3d",
      "team": "Scoop",
      "score": "10",
      "oppScore": "12",
      "opp": "Spring Break '93",
      "oppId": "hRUlbCXzaOf83wDSPKpV36LeX3FmlXgFaE5wdndlB3Q%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "OxmWj3jbZax5TiC6mAkbX%2bnrjL5mvWYmFoUAUXoW98A%3d",
      "team": "Scoop",
      "score": "3",
      "oppScore": "13",
      "opp": "Lantern",
      "oppId": "5z%2bIWsl3V2NLqJRxKMauFZK8Pmkffh9obp1ocWQtXnY%3d"
    }
  ],
  "B8w1PwMJi87%2fAp%2b6M8SYsYlCa%2fBJcbAiBoKLkadwT1Y%3d": [
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "EZz77DbJ6RkIZrIOBFt%2b7tV0ak9SW78qkpqNqGnKS58%3d",
      "team": "Deaf Fruit",
      "score": "3",
      "oppScore": "13",
      "opp": "Dallas United Outlaws",
      "oppId": "JvlTMemVWiCex%2fC0N5CCgk2DrV419cGvk%2bO1z84fDZY%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "MvQ7ngXfvDNwqurHFDM9590tUzIxp80hju26BGeeHBI%3d",
      "team": "Deaf Fruit",
      "score": "0",
      "oppScore": "13",
      "opp": "Foxtrot",
      "oppId": "0iROWvYUWTMjSSoepkFSHy9JjWFh%2bD04t8pOIQ3p9I4%3d"
    },
    {
      "date": "July 30",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "BCsmOMFiG8kU%2bcVSDsV7%2feDxVW9DQga49TSedvcADss%3d",
      "team": "Deaf Fruit",
      "score": "0",
      "oppScore": "13",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "KJGZXJZp9xh8%2bCpspYb6EJ4pxQjU1VLwSiWI2w6PLBg%3d",
      "team": "Deaf Fruit",
      "score": "1",
      "oppScore": "13",
      "opp": "Alamode",
      "oppId": "KQpJIKwJNo0Ue1B4HG72J1RHG35Xquk0pCeyRP4y06g%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "%2fDyOj%2bhDfpf68cU4ldurKx82NBHfejPCABl6NU3kvZY%3d",
      "team": "Deaf Fruit",
      "score": "7",
      "oppScore": "10",
      "opp": "Adventure Time",
      "oppId": "qvXwPo5QehiheOQO5VGwHcsVyYUPf2WOcw0w%2fKf0KR0%3d"
    },
    {
      "date": "July 31",
      "tournament": "PB&J 2022",
      "tournamentId": "PBJ-2022",
      "gameId": "K8TC4ysKTGhbSAKH9jDJfBgr2QPb8AJrAhw0hLc6VZs%3d",
      "team": "Deaf Fruit",
      "score": "6",
      "oppScore": "13",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    }
  ],
  "Al%2fA4sj9NEIvG2pfoVeKR8OCQL%2fREoXEJcD3sZnI49A%3d": [
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "PDtHtshciSuaieBGSFpwZZkq%2bQShcmeb7OtC19YL0rw%3d",
      "team": "Mad Men",
      "score": "14",
      "oppScore": "11",
      "opp": "Phoenix",
      "oppId": "gSiJoSXGsIFqzcdqVTCYpgvQv7XMC%2b0qfhx6EDDLgrQ%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "ONYKyMKUbUge22bR72Yn%2fpz7rYsgcHJ%2fmpnvGPg6VFs%3d",
      "team": "Mad Men",
      "score": "13",
      "oppScore": "9",
      "opp": "Redwolves",
      "oppId": "DoL%2frShNUJHM7OLFhcsMzWlsS0aohpfm30HvDbpVZi4%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "PNLwv6Xf5sPCjl7CQilifDakut%2foK%2bBGhrJT%2fCaKkyc%3d",
      "team": "Mad Men",
      "score": "12",
      "oppScore": "8",
      "opp": "Shade",
      "oppId": "Lh0BmORltuq%2bkEq6e8QTGFiUkZuG5GuP4DNNS5vaaAc%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "HaJG92mVwEm5gIYxr1DzZBYvxtB7PEbVWwXRR71ZaM8%3d",
      "team": "Mad Men",
      "score": "12",
      "oppScore": "7",
      "opp": "Raleigh-Durham United",
      "oppId": "ESHhN%2bMK1oy0j%2bQJSL8mb9cq8h30Erb63T07U8pubEc%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "W2x7walRamnEBYMQ26WXxGvlRnuceKsCi72F87FczHE%3d",
      "team": "Mad Men",
      "score": "10",
      "oppScore": "9",
      "opp": "Tanasi",
      "oppId": "7MICo92AgV%2flT4d8dpq%2bhDNyJFhVtTVq8%2f94L7J8idw%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "mPKV1dqSILKrHpeIQdr8lZ%2bRG7Oa0Nm9YipGVSRUaYI%3d",
      "team": "Mad Men",
      "score": "8",
      "oppScore": "10",
      "opp": "Vault",
      "oppId": "3xV6rIwyIem%2bql5tz%2bZmFG9gr%2bp6WKu5k4q5bwFcSp0%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "G1gGAQfMeEhVZgGUp86quyr4ocX4aVqYtvVjak64Vog%3d",
      "team": "Mad Men",
      "score": "15",
      "oppScore": "6",
      "opp": "Clutch",
      "oppId": "JDc%2bBsCjGUX7gdebRqmdPvq1u2rFlZ1giM6BgTaL5Lk%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "6uQx1SKuR1YW56UIa8Xf%2bcPvarnCjafbt6uJtE3Nogc%3d",
      "team": "Mad Men",
      "score": "14",
      "oppScore": "7",
      "opp": "UpRoar",
      "oppId": "JYJdk5dDNlc6X6evPtQ6K9GWMwDy1xmzavYjS6uyHaE%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "0wTIY7C7vdnALiQCzL%2fMDMUEP2RyUC89G8BfaNGzNgQ%3d",
      "team": "Mad Men",
      "score": "10",
      "oppScore": "13",
      "opp": "GOAT",
      "oppId": "6IFGDipIDG9l%2br4E8TUyujjAslzlgFQUH9y5j9M0G4g%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "Iq%2bWKXmDvKZWLc0OtnTcmNByUliYSQAXOCoAw5Wy78o%3d",
      "team": "Mad Men",
      "score": "9",
      "oppScore": "15",
      "opp": "Doublewide",
      "oppId": "Nm8EGFzg2L2gF7kgWvfP7%2bbHNWDz7Dq77ckjxxzanlg%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "2sRFG%2bBrw0DO6oYYpaItPG5SJHieWfPmhZP5P%2bhRQjM%3d",
      "team": "Mad Men",
      "score": "15",
      "oppScore": "7",
      "opp": "Colonels",
      "oppId": "7Xv2AHNIg3Y%2fm1y7qWkHUz%2b9KT3NZFOCMpW7gBaxnfs%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "3sviqyX88ubx2N8IfFtLXGUYrTTUzwziwKiP6f75daw%3d",
      "team": "Mad Men",
      "score": "11",
      "oppScore": "13",
      "opp": "Tanasi",
      "oppId": "7MICo92AgV%2flT4d8dpq%2bhDNyJFhVtTVq8%2f94L7J8idw%3d"
    }
  ],
  "rv5RwYIoNIY0EqGzDkizdJwIJ%2bLTiDa65wF3GyKDUjo%3d": [
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "5dP2nHSFCRl771aZEoTXDZfFSC03G4CwnuiAFtIBNE0%3d",
      "team": "Garden State Ultimate",
      "score": "12",
      "oppScore": "13",
      "opp": "Omen",
      "oppId": "LvpOSyQd02uHlDLxvVMuw0fzNjwTUO8G7CpXAwQn40I%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "fBmuJi1M1oKa8RD65iJue3fcPjPMM5pu5J2UcXO5QYQ%3d",
      "team": "Garden State Ultimate",
      "score": "14",
      "oppScore": "6",
      "opp": "Lost Boys",
      "oppId": "aNMyR0uAJNwnidjeClZAMqixGjtlQqA6k7CiZP1R0%2bM%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "kIV8DihbWsZCtRICTPBVFva7RpW7OLZ6pg7B2GVHPW0%3d",
      "team": "Garden State Ultimate",
      "score": "8",
      "oppScore": "7",
      "opp": "CITYWIDE Special",
      "oppId": "gfGiZOzR7u7D3oIG%2bAqrTtu3j0xEuTDZKIumDUKFRto%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "DPHOuG%2fICQV3OJD1zm1t0nz2zUwbwFXf7mV4Kugf%2f5k%3d",
      "team": "Garden State Ultimate",
      "score": "7",
      "oppScore": "10",
      "opp": "Tanasi",
      "oppId": "7MICo92AgV%2flT4d8dpq%2bhDNyJFhVtTVq8%2f94L7J8idw%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "4rd9pxfKfjyHRFQiGnBPk61b8S6D5lRB8rXqXDpzB%2bU%3d",
      "team": "Garden State Ultimate",
      "score": "7",
      "oppScore": "9",
      "opp": "Phoenix",
      "oppId": "gSiJoSXGsIFqzcdqVTCYpgvQv7XMC%2b0qfhx6EDDLgrQ%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "DSx1X2ySjPPpgn1JVCxL%2ft1Y5S%2b%2bHW8ooqa0%2btjn5Mo%3d",
      "team": "Garden State Ultimate",
      "score": "7",
      "oppScore": "9",
      "opp": "Omen",
      "oppId": "LvpOSyQd02uHlDLxvVMuw0fzNjwTUO8G7CpXAwQn40I%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "dR5cNKZw%2bMJYRO6Uz61EbkMN1GPkrcyZaChQG%2fNe2Us%3d",
      "team": "Garden State Ultimate",
      "score": "6",
      "oppScore": "15",
      "opp": "Doublewide",
      "oppId": "Nm8EGFzg2L2gF7kgWvfP7%2bbHNWDz7Dq77ckjxxzanlg%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "h0gLRHa2qPaVfq5ZaMEesvk1YnoPb%2beWDSMoauarQfo%3d",
      "team": "Garden State Ultimate",
      "score": "14",
      "oppScore": "12",
      "opp": "Trident I",
      "oppId": "HJQp9EgoBkW9LmipJ4BOA7JQ%2bJ%2fsSTavaeYtiM6KQus%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "%2fYdjIYdrXbj3tT%2bMcHTo7EAVBXfWCHP%2fCFu2HTzVgNE%3d",
      "team": "Garden State Ultimate",
      "score": "14",
      "oppScore": "8",
      "opp": "I-69",
      "oppId": "a64fDYPntTHPU4vghTeNstb32Z0JFlq528bbATJx%2bbs%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "6ctM0k%2bJWIZAquwZG%2b9c5x2qaa7yEsf6OtX2M8seYfQ%3d",
      "team": "Garden State Ultimate",
      "score": "9",
      "oppScore": "14",
      "opp": "GOAT",
      "oppId": "6IFGDipIDG9l%2br4E8TUyujjAslzlgFQUH9y5j9M0G4g%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "H5qQ8zgpm3feVMASFl9C9kGG5qfaiAIU3WejrV%2fmdRY%3d",
      "team": "Garden State Ultimate",
      "score": "9",
      "oppScore": "12",
      "opp": "Tanasi",
      "oppId": "7MICo92AgV%2flT4d8dpq%2bhDNyJFhVtTVq8%2f94L7J8idw%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "f2%2fqEDuzwTXbgUzL8vYLtKi5e8o2QhvfQsgwVolftiE%3d",
      "team": "Garden State Ultimate",
      "score": "14",
      "oppScore": "7",
      "opp": "Colonels",
      "oppId": "7Xv2AHNIg3Y%2fm1y7qWkHUz%2b9KT3NZFOCMpW7gBaxnfs%3d"
    }
  ],
  "BDhLSFxnViHodVQ9noIy0%2by0HWSmiJ3qgV5WI1ozsAk%3d": [
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "SptZOkU5pQ%2fOxH9tuqVTuDoDWQtL0On%2fOQfQewTopgQ%3d",
      "team": "Blueprint",
      "score": "7",
      "oppScore": "8",
      "opp": "CITYWIDE Special",
      "oppId": "gfGiZOzR7u7D3oIG%2bAqrTtu3j0xEuTDZKIumDUKFRto%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "VWqgP29cJURXKXycP1hDOfCmbXmOMe0f%2fHqXLWXTaQA%3d",
      "team": "Blueprint",
      "score": "8",
      "oppScore": "10",
      "opp": "Raleigh-Durham United",
      "oppId": "ESHhN%2bMK1oy0j%2bQJSL8mb9cq8h30Erb63T07U8pubEc%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "mspb6YEL1aJ4k6asz3%2fhKYtThGgBBs%2fqzFUfFSLrBZw%3d",
      "team": "Blueprint",
      "score": "9",
      "oppScore": "13",
      "opp": "Vault",
      "oppId": "3xV6rIwyIem%2bql5tz%2bZmFG9gr%2bp6WKu5k4q5bwFcSp0%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "Tz73rjSzazC7aGtkyoL%2f7MWZxUHpjjpHFBuh2NBGVfk%3d",
      "team": "Blueprint",
      "score": "12",
      "oppScore": "6",
      "opp": "Haymaker",
      "oppId": "F7zZjPQTPbFe%2fxIVicHJD2zRIOORpO5hSxfBuY5fxcQ%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "3Bz%2b2y6E%2baUV9cgKAbnfWCLlc8HsYWvYzisXhQ4dAHo%3d",
      "team": "Blueprint",
      "score": "7",
      "oppScore": "9",
      "opp": "Shade",
      "oppId": "Lh0BmORltuq%2bkEq6e8QTGFiUkZuG5GuP4DNNS5vaaAc%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "rGPKgniB8HbX4IXRFqkiFeDEllQ3NqEAeITgDmGwBqQ%3d",
      "team": "Blueprint",
      "score": "11",
      "oppScore": "8",
      "opp": "Mallard",
      "oppId": "iIrscp1ZOpIpCSkyCbUNt0Vq3FobvpA18ZWbPM63hJo%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "1cc2tD0m2urWOU2OUpP7gye18st7LK5sY8CRpeQvOo0%3d",
      "team": "Blueprint",
      "score": "13",
      "oppScore": "4",
      "opp": "Town Hall Stars",
      "oppId": "2Kd8tzcR0KA7rHRMTMb17b6HIPSIH%2bVBl3400ISNCtw%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "TU6To86r%2b7rhxfXnX%2fovgmDaJ5zqUQj7MK1dKeWwQ8s%3d",
      "team": "Blueprint",
      "score": "13",
      "oppScore": "1",
      "opp": "Queen City Kings",
      "oppId": "95yP4SyRqRX7HgLtZ9jNth95JPB163KSMqpgMxymy8g%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "EculGFWFr53OGSNYlrkrHfR00WrTvY32%2bFh6iJhYJuE%3d",
      "team": "Blueprint",
      "score": "13",
      "oppScore": "6",
      "opp": "SUPA FC",
      "oppId": "KtH9xWy9jBGhUoOWQhn%2fSIE8Vsl4jI9MQcOiMTnZdyQ%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "vE8BofnYNwdseEPlPYUl%2bMnFcpPyYXrs7S5QfHRIses%3d",
      "team": "Blueprint",
      "score": "11",
      "oppScore": "10",
      "opp": "Oakgrove Boys",
      "oppId": "1zUV4kEpPc%2flA39WIyrp9j1w%2fhuIkgE6KlD9%2f%2ffQzg8%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "sfxzV3olpyiLZ6e5wmYzm%2fqGc3Ip%2bLNx74K9UtBKT%2bM%3d",
      "team": "Blueprint",
      "score": "12",
      "oppScore": "9",
      "opp": "Red Tide",
      "oppId": "s6HGV42d1dEo0YRx%2fIycGA2qxvKRYzzfEITLPlDYO1I%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "P5WS62%2bgHPsBY3ivLWA4zsA0frZT7pXVBKsN9qUnMo0%3d",
      "team": "Blueprint",
      "score": "13",
      "oppScore": "6",
      "opp": "Redwolves",
      "oppId": "DoL%2frShNUJHM7OLFhcsMzWlsS0aohpfm30HvDbpVZi4%3d"
    }
  ],
  "ESHhN%2bMK1oy0j%2bQJSL8mb9cq8h30Erb63T07U8pubEc%3d": [
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "VWqgP29cJURXKXycP1hDOfCmbXmOMe0f%2fHqXLWXTaQA%3d",
      "team": "Raleigh-Durham United",
      "score": "10",
      "oppScore": "8",
      "opp": "Blueprint",
      "oppId": "BDhLSFxnViHodVQ9noIy0%2by0HWSmiJ3qgV5WI1ozsAk%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "DUZhw8GIL4818hB9Jz1DXvTvhFvmc2JyrTwcBZqmids%3d",
      "team": "Raleigh-Durham United",
      "score": "13",
      "oppScore": "7",
      "opp": "Haymaker",
      "oppId": "F7zZjPQTPbFe%2fxIVicHJD2zRIOORpO5hSxfBuY5fxcQ%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "y2NBMzCnYfUaRcVVSfnVCsEUml0ESo6YqH5NTaBYmbs%3d",
      "team": "Raleigh-Durham United",
      "score": "8",
      "oppScore": "11",
      "opp": "Vault",
      "oppId": "3xV6rIwyIem%2bql5tz%2bZmFG9gr%2bp6WKu5k4q5bwFcSp0%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "HaJG92mVwEm5gIYxr1DzZBYvxtB7PEbVWwXRR71ZaM8%3d",
      "team": "Raleigh-Durham United",
      "score": "7",
      "oppScore": "12",
      "opp": "Mad Men",
      "oppId": "Al%2fA4sj9NEIvG2pfoVeKR8OCQL%2fREoXEJcD3sZnI49A%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "6kyw2qzi4HgrRz04KOaemUKUBmwrwG3JMpsUL1lenrE%3d",
      "team": "Raleigh-Durham United",
      "score": "10",
      "oppScore": "8",
      "opp": "Omen",
      "oppId": "LvpOSyQd02uHlDLxvVMuw0fzNjwTUO8G7CpXAwQn40I%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "J4bn9GNlAlDEvRP5rAo66xbyElX5gufqq8iHhiPPxhc%3d",
      "team": "Raleigh-Durham United",
      "score": "9",
      "oppScore": "12",
      "opp": "Phoenix",
      "oppId": "gSiJoSXGsIFqzcdqVTCYpgvQv7XMC%2b0qfhx6EDDLgrQ%3d"
    },
    {
      "date": "August 20",
      "tournament": "Raleigh Round Robin",
      "tournamentId": "Raleigh-Round-Robin",
      "gameId": "7Yp%2fDA%2bN1lNuM%2fG0QvO8OIYp3WVbAX5xiTQ5fyoeqUY%3d",
      "team": "Raleigh-Durham United",
      "score": "13",
      "oppScore": "12",
      "opp": "Chain Lightning",
      "oppId": "xMA73o8VVEaOTmaBmpN0fQZ4779P9LJ9jH3j44aVabA%3d"
    },
    {
      "date": "August 21",
      "tournament": "Raleigh Round Robin",
      "tournamentId": "Raleigh-Round-Robin",
      "gameId": "YQVuw2L7Pw%2fimsCrl09ePuVA1IfpIXZP31K4othuTW4%3d",
      "team": "Raleigh-Durham United",
      "score": "10",
      "oppScore": "13",
      "opp": "Chain Lightning",
      "oppId": "xMA73o8VVEaOTmaBmpN0fQZ4779P9LJ9jH3j44aVabA%3d"
    },
    {
      "date": "August 21",
      "tournament": "Raleigh Round Robin",
      "tournamentId": "Raleigh-Round-Robin",
      "gameId": "WYXLSqtNkN2Ct5rhkyd%2fUPZ3%2fZzxWEY1XpXsd9kEk3Q%3d",
      "team": "Raleigh-Durham United",
      "score": "11",
      "oppScore": "8",
      "opp": "Brickhouse",
      "oppId": "b5ARnVg7OngG1a0%2fJuMZTHluwgNFe%2bN5esQIIKsEOUY%3d"
    },
    {
      "date": "August 21",
      "tournament": "Raleigh Round Robin",
      "tournamentId": "Raleigh-Round-Robin",
      "gameId": "vKcjH7AbzCH%2f%2b%2fMzc3feqi1sPXDKg9ZE8ImnTnQDVdU%3d",
      "team": "Raleigh-Durham United",
      "score": "6",
      "oppScore": "11",
      "opp": "Ring of Fire",
      "oppId": "smh7D9CkiNxg%2bf%2b9mPfQh2h%2fUCMicaHAcvHdBji3s6k%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "8j5gpFUW48OF8Pa5lMJdNu12O9V3eXwBfZnen4Yi3M4%3d",
      "team": "Raleigh-Durham United",
      "score": "13",
      "oppScore": "6",
      "opp": "Oakgrove Boys",
      "oppId": "1zUV4kEpPc%2flA39WIyrp9j1w%2fhuIkgE6KlD9%2f%2ffQzg8%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "2SRdkBAvXUQKc47%2fBwGqwPxgwd5V2TedKfTXzLfF0%2fM%3d",
      "team": "Raleigh-Durham United",
      "score": "13",
      "oppScore": "7",
      "opp": "Charleston Heat Stroke",
      "oppId": "quSAXJHtjRXSWAHbdztMDD8ftXgRqlTrsEfTBm793Nk%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "YmPXUOykrMMadDK2I3vme2TW8Z3ykaMnDx4YZShU0A4%3d",
      "team": "Raleigh-Durham United",
      "score": "13",
      "oppScore": "5",
      "opp": "Black Lung",
      "oppId": "olaZlUwWblgF%2b%2b2nRJgbWJ2tj6QzPm5aIO7kfbpnA58%3d"
    }
  ],
  "Lh0BmORltuq%2bkEq6e8QTGFiUkZuG5GuP4DNNS5vaaAc%3d": [
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "mrrtO9pPnrq02hp8jCcUa8r7u9J5xAHmlzg8e8iYWjg%3d",
      "team": "Shade",
      "score": "7",
      "oppScore": "10",
      "opp": "Phantom",
      "oppId": "Hc0As%2bNg1YaD%2fyL3LwaIEtSMTc23KxHOibI0ZwQLDa4%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "rpIUYU78sKrsvbElkh6x9BMtqSfkN0UJkdAqbH7UEE8%3d",
      "team": "Shade",
      "score": "9",
      "oppScore": "11",
      "opp": "Redwolves",
      "oppId": "DoL%2frShNUJHM7OLFhcsMzWlsS0aohpfm30HvDbpVZi4%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "xAt8rB06W4BUvbZGhU%2fA022Bl%2fdDiGm5UbK5QZtn4cU%3d",
      "team": "Shade",
      "score": "7",
      "oppScore": "10",
      "opp": "Phoenix",
      "oppId": "gSiJoSXGsIFqzcdqVTCYpgvQv7XMC%2b0qfhx6EDDLgrQ%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "PNLwv6Xf5sPCjl7CQilifDakut%2foK%2bBGhrJT%2fCaKkyc%3d",
      "team": "Shade",
      "score": "8",
      "oppScore": "12",
      "opp": "Mad Men",
      "oppId": "Al%2fA4sj9NEIvG2pfoVeKR8OCQL%2fREoXEJcD3sZnI49A%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "3Bz%2b2y6E%2baUV9cgKAbnfWCLlc8HsYWvYzisXhQ4dAHo%3d",
      "team": "Shade",
      "score": "9",
      "oppScore": "7",
      "opp": "Blueprint",
      "oppId": "BDhLSFxnViHodVQ9noIy0%2by0HWSmiJ3qgV5WI1ozsAk%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "iMTAyz7CXCRel38XUAqxHYC6k6IdH8GsW9Qg1ePPPWU%3d",
      "team": "Shade",
      "score": "10",
      "oppScore": "3",
      "opp": "Haymaker",
      "oppId": "F7zZjPQTPbFe%2fxIVicHJD2zRIOORpO5hSxfBuY5fxcQ%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "I%2bcw%2bEySbIec3ELlq6DRLl4BPr7L5V1ZaEeAJDr5hYE%3d",
      "team": "Shade",
      "score": "6",
      "oppScore": "15",
      "opp": "Vault",
      "oppId": "3xV6rIwyIem%2bql5tz%2bZmFG9gr%2bp6WKu5k4q5bwFcSp0%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "20b8s4Wq%2bnUhBbIncjkjHoeHjyweksXukQ5wngqXOIg%3d",
      "team": "Shade",
      "score": "6",
      "oppScore": "15",
      "opp": "Omen",
      "oppId": "LvpOSyQd02uHlDLxvVMuw0fzNjwTUO8G7CpXAwQn40I%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "gCvPrqHLjhi6xZMORJkbNhDGxY15oQDrQFgf0p%2fpJI8%3d",
      "team": "Shade",
      "score": "9",
      "oppScore": "11",
      "opp": "Mallard",
      "oppId": "iIrscp1ZOpIpCSkyCbUNt0Vq3FobvpA18ZWbPM63hJo%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "ikshrvI9oNShfyPY1mCPiAd86ld%2fp1nmploYobZVDyg%3d",
      "team": "Shade",
      "score": "12",
      "oppScore": "9",
      "opp": "Inception",
      "oppId": "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "MavHVqa0xTQxdMzkjPdC2eyvkz%2bw3znZZUeLsrr%2bcp0%3d",
      "team": "Shade",
      "score": "8",
      "oppScore": "11",
      "opp": "Trident I",
      "oppId": "HJQp9EgoBkW9LmipJ4BOA7JQ%2bJ%2fsSTavaeYtiM6KQus%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "6SvAUz7cfQbWe501L63Z6fN3iv6JmyAuNaQIF6AomGg%3d",
      "team": "Shade",
      "score": "3",
      "oppScore": "11",
      "opp": "Phoenix",
      "oppId": "gSiJoSXGsIFqzcdqVTCYpgvQv7XMC%2b0qfhx6EDDLgrQ%3d"
    }
  ],
  "gfGiZOzR7u7D3oIG%2bAqrTtu3j0xEuTDZKIumDUKFRto%3d": [
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "SptZOkU5pQ%2fOxH9tuqVTuDoDWQtL0On%2fOQfQewTopgQ%3d",
      "team": "CITYWIDE Special",
      "score": "8",
      "oppScore": "7",
      "opp": "Blueprint",
      "oppId": "BDhLSFxnViHodVQ9noIy0%2by0HWSmiJ3qgV5WI1ozsAk%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "OgYoQGGQmOxLQm3nlDrbGsCKWckSrrAZmDjirNZk%2fw0%3d",
      "team": "CITYWIDE Special",
      "score": "11",
      "oppScore": "12",
      "opp": "Lost Boys",
      "oppId": "aNMyR0uAJNwnidjeClZAMqixGjtlQqA6k7CiZP1R0%2bM%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "AIjwGLnGCD4wkogbRFlf6uRIU5q%2fHQuF%2buizMNrffDs%3d",
      "team": "CITYWIDE Special",
      "score": "5",
      "oppScore": "11",
      "opp": "Omen",
      "oppId": "LvpOSyQd02uHlDLxvVMuw0fzNjwTUO8G7CpXAwQn40I%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "kIV8DihbWsZCtRICTPBVFva7RpW7OLZ6pg7B2GVHPW0%3d",
      "team": "CITYWIDE Special",
      "score": "7",
      "oppScore": "8",
      "opp": "Garden State Ultimate",
      "oppId": "rv5RwYIoNIY0EqGzDkizdJwIJ%2bLTiDa65wF3GyKDUjo%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "5MlYN7D%2f7AWFA%2fojbtyYZ6uOlng1g%2f1892yKVowB6LM%3d",
      "team": "CITYWIDE Special",
      "score": "7",
      "oppScore": "10",
      "opp": "Phantom",
      "oppId": "Hc0As%2bNg1YaD%2fyL3LwaIEtSMTc23KxHOibI0ZwQLDa4%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "vLDegsk7NLfNaUta34SdbP9MQbvGN%2fKrE9csmoO6PhM%3d",
      "team": "CITYWIDE Special",
      "score": "6",
      "oppScore": "9",
      "opp": "Lost Boys",
      "oppId": "aNMyR0uAJNwnidjeClZAMqixGjtlQqA6k7CiZP1R0%2bM%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "RxgQ1ryqZRZHR5aEw5nln%2fHc2zwiJgNtEL0EozDSCwo%3d",
      "team": "CITYWIDE Special",
      "score": "13",
      "oppScore": "0",
      "opp": "Deepfake",
      "oppId": "MjCJCQyu%2bVgQCxsNuCDsIlsaBlb84XeNodQMva1Vl6s%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "2agntXeeoq8qr%2bIhjD6Mno1EuVxZSffrV2OwIo0%2fCfk%3d",
      "team": "CITYWIDE Special",
      "score": "6",
      "oppScore": "13",
      "opp": "Lantern",
      "oppId": "5z%2bIWsl3V2NLqJRxKMauFZK8Pmkffh9obp1ocWQtXnY%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "U3PiaVHMgZiXYZROPi78%2fp4LHR87Lp6t8jd%2fh4S9kTI%3d",
      "team": "CITYWIDE Special",
      "score": "13",
      "oppScore": "0",
      "opp": "FLI-B Honeybees",
      "oppId": "A%2bfgoEP0pWAon7RlAWF7INXWdOLczWdCDnKVBK6W%2btY%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "TVTjuob9TkYeJaA2URkaTtCdncnJKert0y5WIBczgQc%3d",
      "team": "CITYWIDE Special",
      "score": "13",
      "oppScore": "0",
      "opp": "Spring Break '93",
      "oppId": "hRUlbCXzaOf83wDSPKpV36LeX3FmlXgFaE5wdndlB3Q%3d"
    }
  ],
  "gSiJoSXGsIFqzcdqVTCYpgvQv7XMC%2b0qfhx6EDDLgrQ%3d": [
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "PDtHtshciSuaieBGSFpwZZkq%2bQShcmeb7OtC19YL0rw%3d",
      "team": "Phoenix",
      "score": "11",
      "oppScore": "14",
      "opp": "Mad Men",
      "oppId": "Al%2fA4sj9NEIvG2pfoVeKR8OCQL%2fREoXEJcD3sZnI49A%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "xAt8rB06W4BUvbZGhU%2fA022Bl%2fdDiGm5UbK5QZtn4cU%3d",
      "team": "Phoenix",
      "score": "10",
      "oppScore": "7",
      "opp": "Shade",
      "oppId": "Lh0BmORltuq%2bkEq6e8QTGFiUkZuG5GuP4DNNS5vaaAc%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "cHxZtlMXOI%2b2AKQ%2fWQ8rBy8hU7PwbsJujP26RG2cssI%3d",
      "team": "Phoenix",
      "score": "15",
      "oppScore": "5",
      "opp": "Redwolves",
      "oppId": "DoL%2frShNUJHM7OLFhcsMzWlsS0aohpfm30HvDbpVZi4%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "Wskfwve3zwgL7IE1VpM3sdfFIbwzNYlE%2bvyBMKqO6To%3d",
      "team": "Phoenix",
      "score": "7",
      "oppScore": "13",
      "opp": "Vault",
      "oppId": "3xV6rIwyIem%2bql5tz%2bZmFG9gr%2bp6WKu5k4q5bwFcSp0%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "4rd9pxfKfjyHRFQiGnBPk61b8S6D5lRB8rXqXDpzB%2bU%3d",
      "team": "Phoenix",
      "score": "9",
      "oppScore": "7",
      "opp": "Garden State Ultimate",
      "oppId": "rv5RwYIoNIY0EqGzDkizdJwIJ%2bLTiDa65wF3GyKDUjo%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "J4bn9GNlAlDEvRP5rAo66xbyElX5gufqq8iHhiPPxhc%3d",
      "team": "Phoenix",
      "score": "12",
      "oppScore": "9",
      "opp": "Raleigh-Durham United",
      "oppId": "ESHhN%2bMK1oy0j%2bQJSL8mb9cq8h30Erb63T07U8pubEc%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "tu3Vhnpvodhqvl0MhCdeP2TU8%2fg%2f4fv8P6rD1wHZIE8%3d",
      "team": "Phoenix",
      "score": "13",
      "oppScore": "15",
      "opp": "Colonels",
      "oppId": "7Xv2AHNIg3Y%2fm1y7qWkHUz%2b9KT3NZFOCMpW7gBaxnfs%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "xCVi5kXtIGBMXs6x%2bcBz2xmbBLxcCgwq3f5hxK0t518%3d",
      "team": "Phoenix",
      "score": "9",
      "oppScore": "10",
      "opp": "Inception",
      "oppId": "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "1m2exN8rxyooCRyn8KDp5SNvXdqraiBJ%2bXnj2Bu2wtE%3d",
      "team": "Phoenix",
      "score": "11",
      "oppScore": "14",
      "opp": "Tanasi",
      "oppId": "7MICo92AgV%2flT4d8dpq%2bhDNyJFhVtTVq8%2f94L7J8idw%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "FGQYzLDNSPp6GqEBAhhsPdDGpIzg%2fYZWyt3vbT0qzRU%3d",
      "team": "Phoenix",
      "score": "12",
      "oppScore": "11",
      "opp": "Mallard",
      "oppId": "iIrscp1ZOpIpCSkyCbUNt0Vq3FobvpA18ZWbPM63hJo%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "OFRyJruMolY6Dc3tPsQDUwmz4%2fI0g8u9gJ5CHJNy6XY%3d",
      "team": "Phoenix",
      "score": "9",
      "oppScore": "11",
      "opp": "Clutch",
      "oppId": "JDc%2bBsCjGUX7gdebRqmdPvq1u2rFlZ1giM6BgTaL5Lk%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "6SvAUz7cfQbWe501L63Z6fN3iv6JmyAuNaQIF6AomGg%3d",
      "team": "Phoenix",
      "score": "11",
      "oppScore": "3",
      "opp": "Shade",
      "oppId": "Lh0BmORltuq%2bkEq6e8QTGFiUkZuG5GuP4DNNS5vaaAc%3d"
    }
  ],
  "LvpOSyQd02uHlDLxvVMuw0fzNjwTUO8G7CpXAwQn40I%3d": [
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "5dP2nHSFCRl771aZEoTXDZfFSC03G4CwnuiAFtIBNE0%3d",
      "team": "Omen",
      "score": "13",
      "oppScore": "12",
      "opp": "Garden State Ultimate",
      "oppId": "rv5RwYIoNIY0EqGzDkizdJwIJ%2bLTiDa65wF3GyKDUjo%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "AIjwGLnGCD4wkogbRFlf6uRIU5q%2fHQuF%2buizMNrffDs%3d",
      "team": "Omen",
      "score": "11",
      "oppScore": "5",
      "opp": "CITYWIDE Special",
      "oppId": "gfGiZOzR7u7D3oIG%2bAqrTtu3j0xEuTDZKIumDUKFRto%3d"
    },
    {
      "date": "July 23",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "jL1chbS7TnDzX1%2fVagnzX5TzWeqaaI9dxX0OTKeIW2g%3d",
      "team": "Omen",
      "score": "14",
      "oppScore": "7",
      "opp": "Lost Boys",
      "oppId": "aNMyR0uAJNwnidjeClZAMqixGjtlQqA6k7CiZP1R0%2bM%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "FPkrRtMnY%2b2FjYr4j3DsMLEv5WO2zMo2dhBdhjzw8uA%3d",
      "team": "Omen",
      "score": "9",
      "oppScore": "10",
      "opp": "Trident I",
      "oppId": "HJQp9EgoBkW9LmipJ4BOA7JQ%2bJ%2fsSTavaeYtiM6KQus%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "6kyw2qzi4HgrRz04KOaemUKUBmwrwG3JMpsUL1lenrE%3d",
      "team": "Omen",
      "score": "8",
      "oppScore": "10",
      "opp": "Raleigh-Durham United",
      "oppId": "ESHhN%2bMK1oy0j%2bQJSL8mb9cq8h30Erb63T07U8pubEc%3d"
    },
    {
      "date": "July 24",
      "tournament": "TCT Select Flight Invite   East 2022",
      "tournamentId": "TCT-Select-Flight-Invite---East-2022",
      "gameId": "DSx1X2ySjPPpgn1JVCxL%2ft1Y5S%2b%2bHW8ooqa0%2btjn5Mo%3d",
      "team": "Omen",
      "score": "9",
      "oppScore": "7",
      "opp": "Garden State Ultimate",
      "oppId": "rv5RwYIoNIY0EqGzDkizdJwIJ%2bLTiDa65wF3GyKDUjo%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "PeelBdPpjn9Re%2bG2RWfM6tj%2b63%2fIXYeogmhWdNagfcE%3d",
      "team": "Omen",
      "score": "13",
      "oppScore": "9",
      "opp": "Mallard",
      "oppId": "iIrscp1ZOpIpCSkyCbUNt0Vq3FobvpA18ZWbPM63hJo%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "20b8s4Wq%2bnUhBbIncjkjHoeHjyweksXukQ5wngqXOIg%3d",
      "team": "Omen",
      "score": "15",
      "oppScore": "6",
      "opp": "Shade",
      "oppId": "Lh0BmORltuq%2bkEq6e8QTGFiUkZuG5GuP4DNNS5vaaAc%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "EhmidJtop%2b0OpniCpftgTgZ%2fjsOQcXcnpUMlZxD3DlU%3d",
      "team": "Omen",
      "score": "13",
      "oppScore": "14",
      "opp": "Vault",
      "oppId": "3xV6rIwyIem%2bql5tz%2bZmFG9gr%2bp6WKu5k4q5bwFcSp0%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "KpLT0bPIYwb75u2gpG1Dq9wWKTpGjEf8Fj54aCvwSVM%3d",
      "team": "Omen",
      "score": "13",
      "oppScore": "11",
      "opp": "Colonels",
      "oppId": "7Xv2AHNIg3Y%2fm1y7qWkHUz%2b9KT3NZFOCMpW7gBaxnfs%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "SYEEFghJvNjO%2f3Vbk%2fctRdzylpyAiNfkIXQaya0RNu0%3d",
      "team": "Omen",
      "score": "13",
      "oppScore": "11",
      "opp": "Doublewide",
      "oppId": "Nm8EGFzg2L2gF7kgWvfP7%2bbHNWDz7Dq77ckjxxzanlg%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "aF6WQ6oCWEX%2fpEX2ge%2f5yU0wGUAGAjACtPwpr%2fq52g4%3d",
      "team": "Omen",
      "score": "13",
      "oppScore": "8",
      "opp": "GOAT",
      "oppId": "6IFGDipIDG9l%2br4E8TUyujjAslzlgFQUH9y5j9M0G4g%3d"
    }
  ],
  "a63rCMScaUnSuWtwLw1rcbPhZh4%2bUKiYzBab%2b52i9Pk%3d": [
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "LRAr6cfDwXnoyjI58fsLMjxvG%2bPZWmuQ72wVaeNVBlI%3d",
      "team": "Chicago Machine",
      "score": "15",
      "oppScore": "2",
      "opp": "Inception",
      "oppId": "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "GU%2bp344iQCc46HdWMGJeoNygD7MZY%2fWEMQpTtKC854o%3d",
      "team": "Chicago Machine",
      "score": "15",
      "oppScore": "3",
      "opp": "Sub Zero",
      "oppId": "8R4mmVt8rvJ%2bEDsQVxGD5KkmF8Rfnl3EHt4CpzxANac%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "uOSL4aslxZLO98Ioh53kllJ1TzTKYX08J8ZtnZUzdCg%3d",
      "team": "Chicago Machine",
      "score": "15",
      "oppScore": "8",
      "opp": "DiG",
      "oppId": "kn%2bHmtzxdjY7vxb%2fpJ9qsfEsWaDB6JrUBL7Ga4bU18M%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "eLCr%2fwlCKPdM7WcIZ%2bdd%2fdGygkvvAthCfZiXwjNNBUc%3d",
      "team": "Chicago Machine",
      "score": "13",
      "oppScore": "15",
      "opp": "Chain Lightning",
      "oppId": "xMA73o8VVEaOTmaBmpN0fQZ4779P9LJ9jH3j44aVabA%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "Ojt4yqkytJhCAFrz2pHBrcc1t5M02mKxcG7WHrgdEUg%3d",
      "team": "Chicago Machine",
      "score": "12",
      "oppScore": "15",
      "opp": "Revolver",
      "oppId": "Kem%2foxDeD8r5XN1HNW8eHeRyzfBqnWTRaPCmLck6F1I%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "jg0SbkKkVuqCt2Ui2KUM9GS5%2fd3l0XypyIsKiGfk0%2fs%3d",
      "team": "Chicago Machine",
      "score": "15",
      "oppScore": "7",
      "opp": "Emerald City",
      "oppId": "m4Q4f0lvofIPyQTpoi5YZ5nNQ2WH52F0MziY%2fWjcttE%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "c2",
      "team": "Chicago Machine",
      "score": "14",
      "oppScore": "12",
      "opp": "PoNY",
      "oppId": "s8Hq5QrzeTvvFPbmIo%2bj5qGjiFRUrzVIivVUtEM%2bvd4%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "c3",
      "team": "Chicago Machine",
      "score": "15",
      "oppScore": "11",
      "opp": "Rhino Slam!",
      "oppId": "oAJ4T9WLKOA4ICTPMYYK1645dFdRoI5WhU60sNRZIMk%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "cross1",
      "team": "Chicago Machine",
      "score": "15",
      "oppScore": "14",
      "opp": "Sockeye",
      "oppId": "Ur5qIXbyxiUHKLhvlB%2bSYJDI9nx7KM4Txz1fUtmqC2w%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "q1",
      "team": "Chicago Machine",
      "score": "12",
      "oppScore": "14",
      "opp": "DiG",
      "oppId": "kn%2bHmtzxdjY7vxb%2fpJ9qsfEsWaDB6JrUBL7Ga4bU18M%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "f1",
      "team": "Chicago Machine",
      "score": "11",
      "oppScore": "15",
      "opp": "Johnny Bravo",
      "oppId": "AKF9iH0VKK2mK%2fzpE9qJYeqGR%2ff%2fBETu1dm16hUGaVo%3d"
    },
    {
      "date": "September 05",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "seventh",
      "team": "Chicago Machine",
      "score": "13",
      "oppScore": "10",
      "opp": "Sockeye",
      "oppId": "Ur5qIXbyxiUHKLhvlB%2bSYJDI9nx7KM4Txz1fUtmqC2w%3d"
    },
  ],
  "CWW3TWk5yeYKuKEW8USBkbYZz5DyfbKjfswKBgSzYsA%3d": [
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "C6yifiderwlfUJZ2Ve6Rx65YUpG1qnTu5z6GDEm7LBY%3d",
      "team": "Pando",
      "score": "7",
      "oppScore": "15",
      "opp": "DiG",
      "oppId": "kn%2bHmtzxdjY7vxb%2fpJ9qsfEsWaDB6JrUBL7Ga4bU18M%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "Qy93cRlsWroBTf9uFmr%2bquMI5qzIneGak7780e7Psa4%3d",
      "team": "Pando",
      "score": "13",
      "oppScore": "15",
      "opp": "Revolver",
      "oppId": "Kem%2foxDeD8r5XN1HNW8eHeRyzfBqnWTRaPCmLck6F1I%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "7wrlFgXeQUhmL5jNq0vDHWgrRNE5S3c33FDt2w5j1qo%3d",
      "team": "Pando",
      "score": "15",
      "oppScore": "11",
      "opp": "Sub Zero",
      "oppId": "8R4mmVt8rvJ%2bEDsQVxGD5KkmF8Rfnl3EHt4CpzxANac%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "gz4uO5107uFalrDvyiUi81jW5NfHWZhPGX0fJWd3dMg%3d",
      "team": "Pando",
      "score": "12",
      "oppScore": "15",
      "opp": "Johnny Bravo",
      "oppId": "AKF9iH0VKK2mK%2fzpE9qJYeqGR%2ff%2fBETu1dm16hUGaVo%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "SgrFEq9VLADgByG1BBLXEb83UKPzJ7%2fRW9AUbJ7dpgQ%3d",
      "team": "Pando",
      "score": "15",
      "oppScore": "12",
      "opp": "Emerald City",
      "oppId": "m4Q4f0lvofIPyQTpoi5YZ5nNQ2WH52F0MziY%2fWjcttE%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "Me9ov0cjH8ir29J29WfArdD8OPfoQM40L5zGQ2eyrPM%3d",
      "team": "Pando",
      "score": "4",
      "oppScore": "15",
      "opp": "Revolver",
      "oppId": "Kem%2foxDeD8r5XN1HNW8eHeRyzfBqnWTRaPCmLck6F1I%3d"
    },
    {
      "date": "August 25",
      "tournament": "Utah Round Robin",
      "tournamentId": "Utah-Round-Robin",
      "gameId": "AOUfNwva23WOKPDYXj4ngqLsHAB4tdyi7phfiSqAxpQ%3d",
      "team": "Pando",
      "score": "11",
      "oppScore": "4",
      "opp": "Utah Hatu",
      "oppId": "%2bmdoaqywGR6P4rhXTSYYxtvjQhBX094atWIs4EEL1Ys%3d"
    },
    {
      "date": "August 25",
      "tournament": "Utah Round Robin",
      "tournamentId": "Utah-Round-Robin",
      "gameId": "t3xP8GeS8QKiBn5sNkXrZbLVDeJGJp5LGnNpzvF1o7Q%3d",
      "team": "Pando",
      "score": "11",
      "oppScore": "3",
      "opp": "PowderHogs",
      "oppId": "4M9MJYyVOotcr31jr1X%2bW%2fnNzBLDCtMhEBgjHQi1W9s%3d"
    },
    {
      "date": "August 25",
      "tournament": "Utah Round Robin",
      "tournamentId": "Utah-Round-Robin",
      "gameId": "421hTNka1n%2fO2uxavF9CWMbrYRINbILuZIwwkcenM9k%3d",
      "team": "Pando",
      "score": "11",
      "oppScore": "3",
      "opp": "Old Ephraim",
      "oppId": "8pwKgFPj6U4BEx29pAWNsvAmMgzhAWQy%2fWTSNOSoj3c%3d"
    },
    {
      "date": "August 25",
      "tournament": "Utah Round Robin",
      "tournamentId": "Utah-Round-Robin",
      "gameId": "9O%2b6ZkRSYZCaEk56%2bL%2fGhdj4lEu3XevEUpUSXgdTPXI%3d",
      "team": "Pando",
      "score": "11",
      "oppScore": "4",
      "opp": "Utah Hatu",
      "oppId": "%2bmdoaqywGR6P4rhXTSYYxtvjQhBX094atWIs4EEL1Ys%3d"
    }
  ],
  "8R4mmVt8rvJ%2bEDsQVxGD5KkmF8Rfnl3EHt4CpzxANac%3d": [
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "GU%2bp344iQCc46HdWMGJeoNygD7MZY%2fWEMQpTtKC854o%3d",
      "team": "Sub Zero",
      "score": "3",
      "oppScore": "15",
      "opp": "Chicago Machine",
      "oppId": "a63rCMScaUnSuWtwLw1rcbPhZh4%2bUKiYzBab%2b52i9Pk%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "qSLJ0IlxEttAZM%2bl%2bMfomb%2f0JhBEoLBIS5uOiBAtUl8%3d",
      "team": "Sub Zero",
      "score": "15",
      "oppScore": "6",
      "opp": "Inception",
      "oppId": "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "7wrlFgXeQUhmL5jNq0vDHWgrRNE5S3c33FDt2w5j1qo%3d",
      "team": "Sub Zero",
      "score": "11",
      "oppScore": "15",
      "opp": "Pando",
      "oppId": "CWW3TWk5yeYKuKEW8USBkbYZz5DyfbKjfswKBgSzYsA%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "0lq%2ba4wD1FvVOX2DpzfHulCzWiQ5yiI9J%2bKF2XvQ1zY%3d",
      "team": "Sub Zero",
      "score": "15",
      "oppScore": "12",
      "opp": "Sprout",
      "oppId": "QyN2sOWwUrO10Eheq9tje8Trmro0kiO6dKpnQzSh9q4%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "O0JclbF1V7%2b45PNN3%2fwKgsL1J%2f6T3KS8WAHAzEpgI%2fM%3d",
      "team": "Sub Zero",
      "score": "15",
      "oppScore": "7",
      "opp": "Inception",
      "oppId": "b1Nu8UPS9M%2fE3arYcOhNM1Q4ALhBqlmlUY3I%2bBiLmLo%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "1cnkyvJAE0gkMrTLLiAAFFMenDlCufu2%2fk7tXMgOPGU%3d",
      "team": "Sub Zero",
      "score": "7",
      "oppScore": "15",
      "opp": "Pittsburgh Temper",
      "oppId": "u%2bSlJ3XL7d589O5YW%2bbHhuzzJ855SfIO%2bGHlzwH3w3M%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "b1",
      "team": "Sub Zero",
      "score": "9",
      "oppScore": "15",
      "opp": "Johnny Bravo",
      "oppId": "AKF9iH0VKK2mK%2fzpE9qJYeqGR%2ff%2fBETu1dm16hUGaVo%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "b3",
      "team": "Sub Zero",
      "score": "13",
      "oppScore": "14",
      "opp": "DiG",
      "oppId": "kn%2bHmtzxdjY7vxb%2fpJ9qsfEsWaDB6JrUBL7Ga4bU18M%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "pq3",
      "team": "Sub Zero",
      "score": "9",
      "oppScore": "15",
      "opp": "Ring of Fire",
      "oppId": "smh7D9CkiNxg%2bf%2b9mPfQh2h%2fUCMicaHAcvHdBji3s6k%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "e1",
      "team": "Sub Zero",
      "score": "15",
      "oppScore": "12",
      "opp": "Pittsburgh Temper",
      "oppId": "u%2bSlJ3XL7d589O5YW%2bbHhuzzJ855SfIO%2bGHlzwH3w3M%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "e4",
      "team": "Sub Zero",
      "score": "9",
      "oppScore": "15",
      "opp": "Doublewide",
      "oppId": "Nm8EGFzg2L2gF7kgWvfP7%2bbHNWDz7Dq77ckjxxzanlg%3d"
    },
    {
      "date": "September 05",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "e6",
      "team": "Sub Zero",
      "score": "10",
      "oppScore": "15",
      "opp": "SoCal Condors",
      "oppId": "Hs8IYCPj2pf9Nump3cK24FlAdOgCZVYhTQKGBSXFLXA%3d"
    },
  ],
  "xMA73o8VVEaOTmaBmpN0fQZ4779P9LJ9jH3j44aVabA%3d": [
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "7uKs%2f27J0HIBNeVxLMnut2Ya0lnMOB2BNzYpIpsBWVI%3d",
      "team": "Chain Lightning",
      "score": "13",
      "oppScore": "15",
      "opp": "Truck Stop",
      "oppId": "pUj3i6rzWdnpW5TeEEoriE%2fRmJjK%2brKBLe8y4LGWtZY%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "pLJmnX%2fIgkBxnvDh%2bQce%2bNK53CyXxeOu5bfVd0bUtX8%3d",
      "team": "Chain Lightning",
      "score": "15",
      "oppScore": "8",
      "opp": "Emerald City",
      "oppId": "m4Q4f0lvofIPyQTpoi5YZ5nNQ2WH52F0MziY%2fWjcttE%3d"
    },
    {
      "date": "August 05",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "iR21rHqChHlPTlLJXVPd4cv5lljjtojCefw68R5akMc%3d",
      "team": "Chain Lightning",
      "score": "12",
      "oppScore": "11",
      "opp": "Pittsburgh Temper",
      "oppId": "u%2bSlJ3XL7d589O5YW%2bbHhuzzJ855SfIO%2bGHlzwH3w3M%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "eLCr%2fwlCKPdM7WcIZ%2bdd%2fdGygkvvAthCfZiXwjNNBUc%3d",
      "team": "Chain Lightning",
      "score": "15",
      "oppScore": "13",
      "opp": "Chicago Machine",
      "oppId": "a63rCMScaUnSuWtwLw1rcbPhZh4%2bUKiYzBab%2b52i9Pk%3d"
    },
    {
      "date": "August 06",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "mbpe84OvibQdB3v6aUvMCwuv4uVKzjI9sbAyyNKHsJ0%3d",
      "team": "Chain Lightning",
      "score": "13",
      "oppScore": "15",
      "opp": "Truck Stop",
      "oppId": "pUj3i6rzWdnpW5TeEEoriE%2fRmJjK%2brKBLe8y4LGWtZY%3d"
    },
    {
      "date": "August 07",
      "tournament": "2022 U.S. Open Club Championships",
      "tournamentId": "2022-US-Open-Club-Championships",
      "gameId": "9fipQpSsq9f%2f9WqoJHhOQsNB75Pyt%2btOyZSBj0w97V0%3d",
      "team": "Chain Lightning",
      "score": "14",
      "oppScore": "15",
      "opp": "DiG",
      "oppId": "kn%2bHmtzxdjY7vxb%2fpJ9qsfEsWaDB6JrUBL7Ga4bU18M%3d"
    },
    {
      "date": "August 20",
      "tournament": "Raleigh Round Robin",
      "tournamentId": "Raleigh-Round-Robin",
      "gameId": "bnBOkTmc6or9kyGObrR1W0wTSbL%2f9PoJD43OvUPbx60%3d",
      "team": "Chain Lightning",
      "score": "13",
      "oppScore": "5",
      "opp": "Brickhouse",
      "oppId": "b5ARnVg7OngG1a0%2fJuMZTHluwgNFe%2bN5esQIIKsEOUY%3d"
    },
    {
      "date": "August 20",
      "tournament": "Raleigh Round Robin",
      "tournamentId": "Raleigh-Round-Robin",
      "gameId": "7Yp%2fDA%2bN1lNuM%2fG0QvO8OIYp3WVbAX5xiTQ5fyoeqUY%3d",
      "team": "Chain Lightning",
      "score": "12",
      "oppScore": "13",
      "opp": "Raleigh-Durham United",
      "oppId": "ESHhN%2bMK1oy0j%2bQJSL8mb9cq8h30Erb63T07U8pubEc%3d"
    },
    {
      "date": "August 21",
      "tournament": "Raleigh Round Robin",
      "tournamentId": "Raleigh-Round-Robin",
      "gameId": "GjYpDQU%2fdsKYFyNi26Xdp866KTONizXO4BwXjlX7tMM%3d",
      "team": "Chain Lightning",
      "score": "8",
      "oppScore": "11",
      "opp": "Ring of Fire",
      "oppId": "smh7D9CkiNxg%2bf%2b9mPfQh2h%2fUCMicaHAcvHdBji3s6k%3d"
    },
    {
      "date": "August 21",
      "tournament": "Raleigh Round Robin",
      "tournamentId": "Raleigh-Round-Robin",
      "gameId": "YQVuw2L7Pw%2fimsCrl09ePuVA1IfpIXZP31K4othuTW4%3d",
      "team": "Chain Lightning",
      "score": "13",
      "oppScore": "10",
      "opp": "Raleigh-Durham United",
      "oppId": "ESHhN%2bMK1oy0j%2bQJSL8mb9cq8h30Erb63T07U8pubEc%3d"
    },
    {
      "date": "August 21",
      "tournament": "Raleigh Round Robin",
      "tournamentId": "Raleigh-Round-Robin",
      "gameId": "TazdgpjkqHEkA9LUaoYR8i67fx3SaWNQ7IVVMwfxlMU%3d",
      "team": "Chain Lightning",
      "score": "9",
      "oppScore": "11",
      "opp": "Ring of Fire",
      "oppId": "smh7D9CkiNxg%2bf%2b9mPfQh2h%2fUCMicaHAcvHdBji3s6k%3d"
    }
  ],
  "LE1oc%2fdB%2f%2f8We2RewjqcXWGVvpctpO4IKZbpyJ8TCpw%3d": [
    {
      "date": "August 06",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "zsIDb03a46%2fAwOuV%2bv8PgAQ61d%2b3UAlMBjMs2c%2fbnZU%3d",
      "team": "El Ni\u00f1o",
      "score": "13",
      "oppScore": "8",
      "opp": "Reptar",
      "oppId": "zO5MdCdfs1wmlIOMRsdDti3ewmeXeC48aUnnQqdSG9o%3d"
    },
    {
      "date": "August 06",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "uc5Sdal08F8L7f2XXSzrFvRaChAY0VV3j3U%2bEeesiV8%3d",
      "team": "El Ni\u00f1o",
      "score": "12",
      "oppScore": "11",
      "opp": "Southside",
      "oppId": "jnzIw1u16R54lBpk1gV3qvXlKsIvelmRZNPjjDpRKXo%3d"
    },
    {
      "date": "August 06",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "mMSpuVFpbbMWf7zAkt0pejQgg1RzsO8gOWnoeKgq%2fMc%3d",
      "team": "El Ni\u00f1o",
      "score": "10",
      "oppScore": "9",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "August 06",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "hWK7%2b5DH%2fCXB3rHCiH1a4Fvnemb3M6GxQt8s%2b3dtkFg%3d",
      "team": "El Ni\u00f1o",
      "score": "10",
      "oppScore": "7",
      "opp": "Tampa Tempest",
      "oppId": "hQXi92LAmE32qiZ%2fpZRYvkIBTehvd13BZlvBS7aR3%2fQ%3d"
    },
    {
      "date": "August 07",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "yvyeqhDJou8vJiHrUmN8LKM5i5JAlDvBUGG%2b8b4NrOw%3d",
      "team": "El Ni\u00f1o",
      "score": "12",
      "oppScore": "8",
      "opp": "ScooberDivers",
      "oppId": "EQ5VJcEYXXeAviHK3zWePBbmNmZFDh9f%2fMkKpC9pY4Y%3d"
    },
    {
      "date": "August 07",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "oFcNbap8uTCJiLkZJvr%2btU4Ij%2fjFKoCt1vPoa%2buLl08%3d",
      "team": "El Ni\u00f1o",
      "score": "10",
      "oppScore": "15",
      "opp": "Vicious Cycle",
      "oppId": "iThg94F9Gjn72NSYiusu2Lt7M1KILkpxIeQ2vLs8yes%3d"
    },
    {
      "date": "August 08",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "uHaQ3pP57iq6NSnDbmy5MjANG5tY3Ik%2fuvQ791RBRKw%3d",
      "team": "El Ni\u00f1o",
      "score": "0",
      "oppScore": "0",
      "opp": "Vicious Cycle",
      "oppId": "iThg94F9Gjn72NSYiusu2Lt7M1KILkpxIeQ2vLs8yes%3d"
    }
  ],
  "zO5MdCdfs1wmlIOMRsdDti3ewmeXeC48aUnnQqdSG9o%3d": [
    {
      "date": "August 06",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "zsIDb03a46%2fAwOuV%2bv8PgAQ61d%2b3UAlMBjMs2c%2fbnZU%3d",
      "team": "Reptar",
      "score": "8",
      "oppScore": "13",
      "opp": "El Ni\u00f1o",
      "oppId": "LE1oc%2fdB%2f%2f8We2RewjqcXWGVvpctpO4IKZbpyJ8TCpw%3d"
    },
    {
      "date": "August 06",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "HEKdi6vSm%2bp6qVyXI1dEyQetDvlmpNnsmfyML4ZVaWU%3d",
      "team": "Reptar",
      "score": "9",
      "oppScore": "15",
      "opp": "ScooberDivers",
      "oppId": "EQ5VJcEYXXeAviHK3zWePBbmNmZFDh9f%2fMkKpC9pY4Y%3d"
    },
    {
      "date": "August 06",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "R2qXD5fo54Ppe3Mrr1q1ttV%2fz5Xaw9p%2fXmnjaZSWNyA%3d",
      "team": "Reptar",
      "score": "6",
      "oppScore": "11",
      "opp": "Tampa Tempest",
      "oppId": "hQXi92LAmE32qiZ%2fpZRYvkIBTehvd13BZlvBS7aR3%2fQ%3d"
    },
    {
      "date": "August 06",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "A1ez05FOUgpgzRySknNJmk3b8B0f9mmleaMRVGIImMw%3d",
      "team": "Reptar",
      "score": "6",
      "oppScore": "13",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "August 07",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "H3AX4NsArlT25GxhDZ5qKLg%2bXw1Iyd3upfXsNV%2fUrPI%3d",
      "team": "Reptar",
      "score": "3",
      "oppScore": "15",
      "opp": "Vicious Cycle",
      "oppId": "iThg94F9Gjn72NSYiusu2Lt7M1KILkpxIeQ2vLs8yes%3d"
    },
    {
      "date": "August 07",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "nQAp1a5P%2bf%2bebZCD1niVDWczSxNoaYAynQdYzsxGFyY%3d",
      "team": "Reptar",
      "score": "3",
      "oppScore": "12",
      "opp": "Southside",
      "oppId": "jnzIw1u16R54lBpk1gV3qvXlKsIvelmRZNPjjDpRKXo%3d"
    }
  ],
  "jnzIw1u16R54lBpk1gV3qvXlKsIvelmRZNPjjDpRKXo%3d": [
    {
      "date": "August 06",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "nWDI7Ijkuzsq%2bRbCRAzDNahafHgkqCC6KtS91jxVLRM%3d",
      "team": "Southside",
      "score": "12",
      "oppScore": "9",
      "opp": "ScooberDivers",
      "oppId": "EQ5VJcEYXXeAviHK3zWePBbmNmZFDh9f%2fMkKpC9pY4Y%3d"
    },
    {
      "date": "August 06",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "uc5Sdal08F8L7f2XXSzrFvRaChAY0VV3j3U%2bEeesiV8%3d",
      "team": "Southside",
      "score": "11",
      "oppScore": "12",
      "opp": "El Ni\u00f1o",
      "oppId": "LE1oc%2fdB%2f%2f8We2RewjqcXWGVvpctpO4IKZbpyJ8TCpw%3d"
    },
    {
      "date": "August 06",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "pHgAHep7XgnRB87MwpNW4kC0utp6g%2fmts%2fNjPIdQVYg%3d",
      "team": "Southside",
      "score": "9",
      "oppScore": "12",
      "opp": "Vicious Cycle",
      "oppId": "iThg94F9Gjn72NSYiusu2Lt7M1KILkpxIeQ2vLs8yes%3d"
    },
    {
      "date": "August 07",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "J%2bI3a3KAfD80IPoQHME4UeWCXz6IsfRnzQNiY91m0so%3d",
      "team": "Southside",
      "score": "12",
      "oppScore": "5",
      "opp": "Tampa Tempest",
      "oppId": "hQXi92LAmE32qiZ%2fpZRYvkIBTehvd13BZlvBS7aR3%2fQ%3d"
    },
    {
      "date": "August 07",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "nQAp1a5P%2bf%2bebZCD1niVDWczSxNoaYAynQdYzsxGFyY%3d",
      "team": "Southside",
      "score": "12",
      "oppScore": "3",
      "opp": "Reptar",
      "oppId": "zO5MdCdfs1wmlIOMRsdDti3ewmeXeC48aUnnQqdSG9o%3d"
    },
    {
      "date": "August 07",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "%2byRunQFSqvhniEQHXQ9A%2bpBiKojQJq4gGOr23uZTWR8%3d",
      "team": "Southside",
      "score": "10",
      "oppScore": "9",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "August 07",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "9z0m2OMZAPPFLdKN52gZ3f3s0KEllaayYBtEqJ6mUOI%3d",
      "team": "Southside",
      "score": "0",
      "oppScore": "0",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    }
  ],
  "iThg94F9Gjn72NSYiusu2Lt7M1KILkpxIeQ2vLs8yes%3d": [
    {
      "date": "August 06",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "Mmg%2fDswOktC%2fQQp5eYoVs%2bUBTnoCjyJD7Ox4lPr6rtM%3d",
      "team": "Vicious Cycle",
      "score": "15",
      "oppScore": "7",
      "opp": "Tampa Tempest",
      "oppId": "hQXi92LAmE32qiZ%2fpZRYvkIBTehvd13BZlvBS7aR3%2fQ%3d"
    },
    {
      "date": "August 06",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "m5WPewYf0XWhXMrGGeSVNZLExUOK9%2fQ6nNp%2f%2fIOucXI%3d",
      "team": "Vicious Cycle",
      "score": "12",
      "oppScore": "13",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "August 06",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "3tGRrk0kgO3UCcCL00x3srJ2E78O4mv5sqh1LdpjGoI%3d",
      "team": "Vicious Cycle",
      "score": "13",
      "oppScore": "10",
      "opp": "ScooberDivers",
      "oppId": "EQ5VJcEYXXeAviHK3zWePBbmNmZFDh9f%2fMkKpC9pY4Y%3d"
    },
    {
      "date": "August 06",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "pHgAHep7XgnRB87MwpNW4kC0utp6g%2fmts%2fNjPIdQVYg%3d",
      "team": "Vicious Cycle",
      "score": "12",
      "oppScore": "9",
      "opp": "Southside",
      "oppId": "jnzIw1u16R54lBpk1gV3qvXlKsIvelmRZNPjjDpRKXo%3d"
    },
    {
      "date": "August 07",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "H3AX4NsArlT25GxhDZ5qKLg%2bXw1Iyd3upfXsNV%2fUrPI%3d",
      "team": "Vicious Cycle",
      "score": "15",
      "oppScore": "3",
      "opp": "Reptar",
      "oppId": "zO5MdCdfs1wmlIOMRsdDti3ewmeXeC48aUnnQqdSG9o%3d"
    },
    {
      "date": "August 07",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "oFcNbap8uTCJiLkZJvr%2btU4Ij%2fjFKoCt1vPoa%2buLl08%3d",
      "team": "Vicious Cycle",
      "score": "15",
      "oppScore": "10",
      "opp": "El Ni\u00f1o",
      "oppId": "LE1oc%2fdB%2f%2f8We2RewjqcXWGVvpctpO4IKZbpyJ8TCpw%3d"
    },
    {
      "date": "August 08",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "uHaQ3pP57iq6NSnDbmy5MjANG5tY3Ik%2fuvQ791RBRKw%3d",
      "team": "Vicious Cycle",
      "score": "0",
      "oppScore": "0",
      "opp": "El Ni\u00f1o",
      "oppId": "LE1oc%2fdB%2f%2f8We2RewjqcXWGVvpctpO4IKZbpyJ8TCpw%3d"
    }
  ],
  "EQ5VJcEYXXeAviHK3zWePBbmNmZFDh9f%2fMkKpC9pY4Y%3d": [
    {
      "date": "August 06",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "nWDI7Ijkuzsq%2bRbCRAzDNahafHgkqCC6KtS91jxVLRM%3d",
      "team": "ScooberDivers",
      "score": "9",
      "oppScore": "12",
      "opp": "Southside",
      "oppId": "jnzIw1u16R54lBpk1gV3qvXlKsIvelmRZNPjjDpRKXo%3d"
    },
    {
      "date": "August 06",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "HEKdi6vSm%2bp6qVyXI1dEyQetDvlmpNnsmfyML4ZVaWU%3d",
      "team": "ScooberDivers",
      "score": "15",
      "oppScore": "9",
      "opp": "Reptar",
      "oppId": "zO5MdCdfs1wmlIOMRsdDti3ewmeXeC48aUnnQqdSG9o%3d"
    },
    {
      "date": "August 06",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "3tGRrk0kgO3UCcCL00x3srJ2E78O4mv5sqh1LdpjGoI%3d",
      "team": "ScooberDivers",
      "score": "10",
      "oppScore": "13",
      "opp": "Vicious Cycle",
      "oppId": "iThg94F9Gjn72NSYiusu2Lt7M1KILkpxIeQ2vLs8yes%3d"
    },
    {
      "date": "August 07",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "ID0Y00RJkPLQIigOBUPvdC3wM7szHlCrCgQ9yJ8uZDo%3d",
      "team": "ScooberDivers",
      "score": "9",
      "oppScore": "13",
      "opp": "Raptor",
      "oppId": "0KI8qWlWFCsa%2fAX9dGPcENMGLC4l4iUB59ZWVc0wLZk%3d"
    },
    {
      "date": "August 07",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "yvyeqhDJou8vJiHrUmN8LKM5i5JAlDvBUGG%2b8b4NrOw%3d",
      "team": "ScooberDivers",
      "score": "8",
      "oppScore": "12",
      "opp": "El Ni\u00f1o",
      "oppId": "LE1oc%2fdB%2f%2f8We2RewjqcXWGVvpctpO4IKZbpyJ8TCpw%3d"
    },
    {
      "date": "August 07",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "c7DJcql5wsVJPiS2q2ekPVje%2fklf96SgjVeNn5u1oLI%3d",
      "team": "ScooberDivers",
      "score": "11",
      "oppScore": "6",
      "opp": "Tampa Tempest",
      "oppId": "hQXi92LAmE32qiZ%2fpZRYvkIBTehvd13BZlvBS7aR3%2fQ%3d"
    },
    {
      "date": "August 07",
      "tournament": "Swan Boat",
      "tournamentId": "Swan-Boat",
      "gameId": "M83xZjlmX0qunLBqyuwXezanY8LQoyzBZuWSVeFTloY%3d",
      "team": "ScooberDivers",
      "score": "0",
      "oppScore": "0",
      "opp": "Tampa Tempest",
      "oppId": "hQXi92LAmE32qiZ%2fpZRYvkIBTehvd13BZlvBS7aR3%2fQ%3d"
    }
  ],
  "EKT%2b4wN3oW3%2bvTZF1BOVy%2fpLrku%2frikWkw8iDqstIOQ%3d": [
    {
      "date": "August 06",
      "tournament": "Coconino Classic",
      "tournamentId": "Coconino-Classic",
      "gameId": "0aFNp0vZ2qZCNy0Ee8Jv0Trehr%2f%2byhrO6ZigbDHaJTA%3d",
      "team": "Seven Out",
      "score": "9",
      "oppScore": "15",
      "opp": "Sonoran Dog",
      "oppId": "WAPjwPL2R8YMCWgolwmIL1cqRoFxsqyk2gQeZ9iDOMA%3d"
    },
    {
      "date": "August 06",
      "tournament": "Coconino Classic",
      "tournamentId": "Coconino-Classic",
      "gameId": "EMVCeWuZp0LhaUkbKYpqi%2f0Q3zMzTjLAXt88totUdME%3d",
      "team": "Seven Out",
      "score": "7",
      "oppScore": "15",
      "opp": "Drought",
      "oppId": "ksz2i7%2bjkkoIQzWKSrtEphZr9icgiUTPL3Ixum49pfY%3d"
    },
    {
      "date": "August 06",
      "tournament": "Coconino Classic",
      "tournamentId": "Coconino-Classic",
      "gameId": "3GHbC1oFt89EuDcQ4hHtTdnbcsrUmcdGptGVW6o3K2g%3d",
      "team": "Seven Out",
      "score": "14",
      "oppScore": "13",
      "opp": "Monsoon",
      "oppId": "iMWyb%2fFNBKjTkIqXjFBY%2bhoKQ%2b3niXP6UqbJkmR4Fiw%3d"
    },
    {
      "date": "August 21",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "PyplyxUfZhRLeJj8XKdZGOhbhJil9qzMqXRXNuRSxnI%3d",
      "team": "Seven Out",
      "score": "12",
      "oppScore": "8",
      "opp": "Offshore",
      "oppId": "z3CZ5zLpuh4%2b6lmsKpQSH%2fDpWRfMktj5DGqERnXkcXY%3d"
    },
    {
      "date": "August 21",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "I%2fTx8pUd%2fsDNiTW9wg6BCzuHmLUjeoLrSOK1db0Fnp8%3d",
      "team": "Seven Out",
      "score": "7",
      "oppScore": "13",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "GaD%2fvVQqjFZ6uWSW7QZJUQ8tyUTUWSKwv7JoWlysCjM%3d",
      "team": "Seven Out",
      "score": "7",
      "oppScore": "13",
      "opp": "Sawtooth",
      "oppId": "O%2fOvOBARFYfl3iP1PCdqMwd9SiYSMEiR%2b0E8MZeOOD4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "qV%2bJx85wMKFhS32VM2d99lyES%2fyz7Zki4Vb%2fiB1PdvA%3d",
      "team": "Seven Out",
      "score": "13",
      "oppScore": "12",
      "opp": "PowderHogs",
      "oppId": "4M9MJYyVOotcr31jr1X%2bW%2fnNzBLDCtMhEBgjHQi1W9s%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "00xZSlY4FIOGqrajuZ%2blaTlSArJel%2bUAZc3%2bZU%2f53Hs%3d",
      "team": "Seven Out",
      "score": "1",
      "oppScore": "13",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "23DgN7oxqVFV9IpXQrdhR22hlMIkHSPeXoyzl%2fPJqEA%3d",
      "team": "Seven Out",
      "score": "3",
      "oppScore": "13",
      "opp": "Zyzzyva",
      "oppId": "Yfu4RKEHOC158Q5KQGT5xHxtk5hGHKp2RxFoXMEKk%2fA%3d"
    }
  ],
  "hRUlbCXzaOf83wDSPKpV36LeX3FmlXgFaE5wdndlB3Q%3d": [
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "19zMO2pFiNE1vezwNMZ2V3ybqSo9Bg1Xi18GegylroU%3d",
      "team": "Spring Break '93",
      "score": "7",
      "oppScore": "13",
      "opp": "Rumspringa",
      "oppId": "ZL0F50TyR6dI%2b5OluYY14WIhkuQQcvw4HafjYT2i%2bT0%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "TWcCCAjTIqA8DCBs3fJjLJY2k30wyTpqzyLmaS35Its%3d",
      "team": "Spring Break '93",
      "score": "6",
      "oppScore": "13",
      "opp": "Big Wrench",
      "oppId": "cD5%2b6qwajeEeZXFAZqH%2f%2fSNl6zunDFFnMpdAAl7eKv0%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "n2B4ShE1p%2b5FR2PzNFcKOq92Re0JhYX7Tm62%2fnsEF6s%3d",
      "team": "Spring Break '93",
      "score": "11",
      "oppScore": "9",
      "opp": "Rebels",
      "oppId": "uV%2beAif391mDFcejwcWVicj%2fYkXfD%2fwqKg5TtqrsFno%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "n8t0neSU6LNfxMG%2bDRZowEzPuTwJzI7VrYFRDg%2bDC8w%3d",
      "team": "Spring Break '93",
      "score": "8",
      "oppScore": "13",
      "opp": "Chimney",
      "oppId": "jzVhyLybRfcABTsshmdvgsxYDSASMTHMGNde%2bkQTb4Y%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "PmFk1nM7rpHRxDh3qowhdP0mTbzUmsCNHu2rY8fll0o%3d",
      "team": "Spring Break '93",
      "score": "8",
      "oppScore": "11",
      "opp": "Winc City Fog of War",
      "oppId": "6gsiUt3INMB4Td2RTxJkksYpRIJsACnjptCfHUZeg2o%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "GLdhMq2EAMdJ6l%2bQnB11HM%2bd1ZNqSVn6pxOq70sRYQY%3d",
      "team": "Spring Break '93",
      "score": "7",
      "oppScore": "12",
      "opp": "MOB Ultimate",
      "oppId": "otuSqjNVhCJZE0ojAe0x6mw6GKk1Ux8ciM5ADgrPUEI%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "szLWq%2bUFNVfBstPGcQFILAI0wzLu1tHHzdq9MfgRLcE%3d",
      "team": "Spring Break '93",
      "score": "5",
      "oppScore": "13",
      "opp": "Lantern",
      "oppId": "5z%2bIWsl3V2NLqJRxKMauFZK8Pmkffh9obp1ocWQtXnY%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "iG%2fyOlqKAWDuQO5xOUH3rSsAHplGxUte7wkFwnMxB3w%3d",
      "team": "Spring Break '93",
      "score": "12",
      "oppScore": "10",
      "opp": "Scoop",
      "oppId": "Rj8mJfKJry2xyWaxTIh5HdXGLaTMxr7%2fXjyL4uJET2o%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "2ELEeAN2Xw%2bmey3tJcdVmISK1L%2bd7xXHQZ%2fUnosrqGs%3d",
      "team": "Spring Break '93",
      "score": "12",
      "oppScore": "6",
      "opp": "Deepfake",
      "oppId": "MjCJCQyu%2bVgQCxsNuCDsIlsaBlb84XeNodQMva1Vl6s%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "TVTjuob9TkYeJaA2URkaTtCdncnJKert0y5WIBczgQc%3d",
      "team": "Spring Break '93",
      "score": "0",
      "oppScore": "13",
      "opp": "CITYWIDE Special",
      "oppId": "gfGiZOzR7u7D3oIG%2bAqrTtu3j0xEuTDZKIumDUKFRto%3d"
    }
  ],
  "ZmiNobvozutOTlvBTZEmqu8bW1a0Lvp6PqGmIiNlkGU%3d": [
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "eLfEHXLCT%2fmT1M7DeZdZ32U4xObVidDH7jtu3mwjedQ%3d",
      "team": "JAWN",
      "score": "7",
      "oppScore": "10",
      "opp": "Helots",
      "oppId": "m6Orv8C1KcDAyDCEIuhdmpNBKN41R0jWgAvkxa%2bLmOk%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "yZq16%2bxhGAngFAKgNgefkAg92DjaI1FbviyE6XKK0ew%3d",
      "team": "JAWN",
      "score": "6",
      "oppScore": "13",
      "opp": "Oakgrove Boys",
      "oppId": "1zUV4kEpPc%2flA39WIyrp9j1w%2fhuIkgE6KlD9%2f%2ffQzg8%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "hcJvxtCNEzcjEtUf32fWornxXJ191a82nj%2fxAl0iGFc%3d",
      "team": "JAWN",
      "score": "13",
      "oppScore": "9",
      "opp": "MOB Ultimate",
      "oppId": "otuSqjNVhCJZE0ojAe0x6mw6GKk1Ux8ciM5ADgrPUEI%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "fy7dFJQrKc3p8kd1k%2fFqKEtRg0%2bDux%2fG088eTZZr3mI%3d",
      "team": "JAWN",
      "score": "13",
      "oppScore": "8",
      "opp": "Town Hall Stars",
      "oppId": "2Kd8tzcR0KA7rHRMTMb17b6HIPSIH%2bVBl3400ISNCtw%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "7cgLo%2bpwscy9THMqqyfPTPKyBoVmsCdUdvdxy3I7o3Y%3d",
      "team": "JAWN",
      "score": "7",
      "oppScore": "8",
      "opp": "Hazard",
      "oppId": "RBbGte9ro%2fCZWrmOmcHNTdHB41zGQFb64ubBkZjcTEI%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "R6Iu50zsBfUJTNhp5WAE0PHj%2fkG06IxyqLAbhm41iHE%3d",
      "team": "JAWN",
      "score": "8",
      "oppScore": "7",
      "opp": "Adelphos",
      "oppId": "IINBHzSyAD1qveaivUllPpQBPkMwjzVWcFo4s84DFtc%3d"
    }
  ],
  "uV%2beAif391mDFcejwcWVicj%2fYkXfD%2fwqKg5TtqrsFno%3d": [
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "tNwz6%2bXgqc6sUN2sGwGVoVzb%2bvQuXZr3JP45DOiQfXQ%3d",
      "team": "Rebels",
      "score": "2",
      "oppScore": "13",
      "opp": "Big Wrench",
      "oppId": "cD5%2b6qwajeEeZXFAZqH%2f%2fSNl6zunDFFnMpdAAl7eKv0%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "EyV7M00zrt2%2bKvPl9ZqxAMw0Gc6zCyvwUXb%2bP9jptMs%3d",
      "team": "Rebels",
      "score": "5",
      "oppScore": "13",
      "opp": "Rumspringa",
      "oppId": "ZL0F50TyR6dI%2b5OluYY14WIhkuQQcvw4HafjYT2i%2bT0%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "n2B4ShE1p%2b5FR2PzNFcKOq92Re0JhYX7Tm62%2fnsEF6s%3d",
      "team": "Rebels",
      "score": "9",
      "oppScore": "11",
      "opp": "Spring Break '93",
      "oppId": "hRUlbCXzaOf83wDSPKpV36LeX3FmlXgFaE5wdndlB3Q%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "GxQoAY665zHsPNB9aVMFDDpDxc43669Wgx2HX2XeHi4%3d",
      "team": "Rebels",
      "score": "12",
      "oppScore": "4",
      "opp": "Deepfake",
      "oppId": "MjCJCQyu%2bVgQCxsNuCDsIlsaBlb84XeNodQMva1Vl6s%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "txoS8VbJV7ELZ3suxaWmokxxKsqkfiXp6qdaoDesDik%3d",
      "team": "Rebels",
      "score": "8",
      "oppScore": "10",
      "opp": "Queen City Kings",
      "oppId": "95yP4SyRqRX7HgLtZ9jNth95JPB163KSMqpgMxymy8g%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "7UDf2RBDHP3OkjoZnP8gJIT5sJTTqn1C%2bxgSPXIAWU0%3d",
      "team": "Rebels",
      "score": "9",
      "oppScore": "6",
      "opp": "Richmond Floodwall",
      "oppId": "fa1yMF%2fsPXZM%2b%2f4My9LlUixC%2bMFxnwM7gPH410zO6EA%3d"
    }
  ],
  "MjCJCQyu%2bVgQCxsNuCDsIlsaBlb84XeNodQMva1Vl6s%3d": [
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "70hWmK1BBkPiRHuTZCWIkOg5e9Rkfm3buG%2fSZgg%2bYRk%3d",
      "team": "Deepfake",
      "score": "2",
      "oppScore": "13",
      "opp": "Pittsburgh Stealers",
      "oppId": "icSGwphEBV6tcYJLRwPxEC22s0bt6rct5HG5ubjyetw%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "eRez9aTB7SoNH3lkCImHMN9%2bV9NJQJJT6l4tMpaTd8M%3d",
      "team": "Deepfake",
      "score": "5",
      "oppScore": "13",
      "opp": "John Doe",
      "oppId": "U8i%2ffBl%2bGdNRXUaehdkV4W9lOM4QHUciytmWcy3oxXI%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "DMVY5SYodR2QC2qUPLCyIVZjFVfeOznkDA6kRA93NIs%3d",
      "team": "Deepfake",
      "score": "6",
      "oppScore": "13",
      "opp": "Ballston Booya",
      "oppId": "nyrFBt9ebf%2bCb5bJv%2fsbfoGgjlyW%2bH3CLoFeaZGYMEY%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "GxQoAY665zHsPNB9aVMFDDpDxc43669Wgx2HX2XeHi4%3d",
      "team": "Deepfake",
      "score": "4",
      "oppScore": "12",
      "opp": "Rebels",
      "oppId": "uV%2beAif391mDFcejwcWVicj%2fYkXfD%2fwqKg5TtqrsFno%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "371aT7uEr58yHumgztXcxVfOAeHB1COXK2UR7vSeTVE%3d",
      "team": "Deepfake",
      "score": "12",
      "oppScore": "11",
      "opp": "Richmond Floodwall",
      "oppId": "fa1yMF%2fsPXZM%2b%2f4My9LlUixC%2bMFxnwM7gPH410zO6EA%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "2ZHTEoMbVCLEGZ%2f4v3HmQtlcZUZ1wx1nwxB9G5zR1uw%3d",
      "team": "Deepfake",
      "score": "1",
      "oppScore": "0",
      "opp": "Queen City Kings",
      "oppId": "95yP4SyRqRX7HgLtZ9jNth95JPB163KSMqpgMxymy8g%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "RxgQ1ryqZRZHR5aEw5nln%2fHc2zwiJgNtEL0EozDSCwo%3d",
      "team": "Deepfake",
      "score": "0",
      "oppScore": "13",
      "opp": "CITYWIDE Special",
      "oppId": "gfGiZOzR7u7D3oIG%2bAqrTtu3j0xEuTDZKIumDUKFRto%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "us55oNw6952maN0nR7Y9B1W5kH1Yf4kYMsaDQHd%2bHww%3d",
      "team": "Deepfake",
      "score": "12",
      "oppScore": "13",
      "opp": "FLI-B Honeybees",
      "oppId": "A%2bfgoEP0pWAon7RlAWF7INXWdOLczWdCDnKVBK6W%2btY%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "2ELEeAN2Xw%2bmey3tJcdVmISK1L%2bd7xXHQZ%2fUnosrqGs%3d",
      "team": "Deepfake",
      "score": "6",
      "oppScore": "12",
      "opp": "Spring Break '93",
      "oppId": "hRUlbCXzaOf83wDSPKpV36LeX3FmlXgFaE5wdndlB3Q%3d"
    }
  ],
  "ZL0F50TyR6dI%2b5OluYY14WIhkuQQcvw4HafjYT2i%2bT0%3d": [
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "19zMO2pFiNE1vezwNMZ2V3ybqSo9Bg1Xi18GegylroU%3d",
      "team": "Rumspringa",
      "score": "13",
      "oppScore": "7",
      "opp": "Spring Break '93",
      "oppId": "hRUlbCXzaOf83wDSPKpV36LeX3FmlXgFaE5wdndlB3Q%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "EyV7M00zrt2%2bKvPl9ZqxAMw0Gc6zCyvwUXb%2bP9jptMs%3d",
      "team": "Rumspringa",
      "score": "13",
      "oppScore": "5",
      "opp": "Rebels",
      "oppId": "uV%2beAif391mDFcejwcWVicj%2fYkXfD%2fwqKg5TtqrsFno%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "9OURp8nob6gLac0XejPpUKti%2fwvISShcrJQKqC7QHBI%3d",
      "team": "Rumspringa",
      "score": "10",
      "oppScore": "13",
      "opp": "Big Wrench",
      "oppId": "cD5%2b6qwajeEeZXFAZqH%2f%2fSNl6zunDFFnMpdAAl7eKv0%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "vmB0ZafxVEqTP8GCpp1YQMniLjHHI%2bDIxWumaPC0QPk%3d",
      "team": "Rumspringa",
      "score": "12",
      "oppScore": "8",
      "opp": "Colt",
      "oppId": "csuSEMdqMABUec374gfmA4qxClU85JTTEhhJhBcJ2Do%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "54HGnS2nhPszA586pu%2fj%2bgHMGe2E%2bLFdJ52YamkAEz0%3d",
      "team": "Rumspringa",
      "score": "12",
      "oppScore": "8",
      "opp": "Magma Bears",
      "oppId": "tOcZDMKk80VDM0VC5NJXyzOSixma%2fCVGplidpqww6q8%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "PJRM3tBwy7QbUUqXfjydE32i3WBiW0bNt%2bfdumlUFjM%3d",
      "team": "Rumspringa",
      "score": "12",
      "oppScore": "6",
      "opp": "Helots",
      "oppId": "m6Orv8C1KcDAyDCEIuhdmpNBKN41R0jWgAvkxa%2bLmOk%3d"
    }
  ],
  "nyrFBt9ebf%2bCb5bJv%2fsbfoGgjlyW%2bH3CLoFeaZGYMEY%3d": [
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "DgiQfcLs6S%2bl7yTjUw%2bm48R70k8RD3G2WhTOP0ZibYM%3d",
      "team": "Ballston Booya",
      "score": "9",
      "oppScore": "13",
      "opp": "John Doe",
      "oppId": "U8i%2ffBl%2bGdNRXUaehdkV4W9lOM4QHUciytmWcy3oxXI%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "%2f%2bDYTEomUu%2bLHdxYdvRtj0ALfJTCL%2bd7y%2fpc8JZpPGU%3d",
      "team": "Ballston Booya",
      "score": "10",
      "oppScore": "13",
      "opp": "Pittsburgh Stealers",
      "oppId": "icSGwphEBV6tcYJLRwPxEC22s0bt6rct5HG5ubjyetw%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "DMVY5SYodR2QC2qUPLCyIVZjFVfeOznkDA6kRA93NIs%3d",
      "team": "Ballston Booya",
      "score": "13",
      "oppScore": "6",
      "opp": "Deepfake",
      "oppId": "MjCJCQyu%2bVgQCxsNuCDsIlsaBlb84XeNodQMva1Vl6s%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "bXWGMCMr3TpQ4nSKR4Y0xLim4ZupYtMbPdSbxgf8oqA%3d",
      "team": "Ballston Booya",
      "score": "9",
      "oppScore": "12",
      "opp": "Magma Bears",
      "oppId": "tOcZDMKk80VDM0VC5NJXyzOSixma%2fCVGplidpqww6q8%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "CU1UmY2eqpqysE%2bgP58wc8lNgWcwBkO27XpRqDi9RbQ%3d",
      "team": "Ballston Booya",
      "score": "8",
      "oppScore": "12",
      "opp": "Colt",
      "oppId": "csuSEMdqMABUec374gfmA4qxClU85JTTEhhJhBcJ2Do%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "72X9YB84RUWkOMpYsghGRnpiPU1KStqlaJxClM0eioE%3d",
      "team": "Ballston Booya",
      "score": "13",
      "oppScore": "7",
      "opp": "SUPA FC",
      "oppId": "KtH9xWy9jBGhUoOWQhn%2fSIE8Vsl4jI9MQcOiMTnZdyQ%3d"
    }
  ],
  "fa1yMF%2fsPXZM%2b%2f4My9LlUixC%2bMFxnwM7gPH410zO6EA%3d": [
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "1wXAZiSDlTf0SjPNL0A3NAN3q9qQnveHOOMYLRqldvw%3d",
      "team": "Richmond Floodwall",
      "score": "6",
      "oppScore": "13",
      "opp": "Red Tide",
      "oppId": "s6HGV42d1dEo0YRx%2fIycGA2qxvKRYzzfEITLPlDYO1I%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "5DUJRHQdcGdnb%2b4kg783rarsRTx5XHfq7er3U5Qp7a0%3d",
      "team": "Richmond Floodwall",
      "score": "4",
      "oppScore": "13",
      "opp": "Brickhouse",
      "oppId": "b5ARnVg7OngG1a0%2fJuMZTHluwgNFe%2bN5esQIIKsEOUY%3d"
    },
    {
      "date": "August 06",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "t6CVLI9ollRIQqmhSU2pUwHHMMXrknKM7rXwVnbc4n0%3d",
      "team": "Richmond Floodwall",
      "score": "7",
      "oppScore": "12",
      "opp": "Hazard",
      "oppId": "RBbGte9ro%2fCZWrmOmcHNTdHB41zGQFb64ubBkZjcTEI%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "%2br8kNsGJ5kgo35TRbDExM3WFAQCZbL8q3nhwY7yCdrg%3d",
      "team": "Richmond Floodwall",
      "score": "8",
      "oppScore": "6",
      "opp": "Queen City Kings",
      "oppId": "95yP4SyRqRX7HgLtZ9jNth95JPB163KSMqpgMxymy8g%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "371aT7uEr58yHumgztXcxVfOAeHB1COXK2UR7vSeTVE%3d",
      "team": "Richmond Floodwall",
      "score": "11",
      "oppScore": "12",
      "opp": "Deepfake",
      "oppId": "MjCJCQyu%2bVgQCxsNuCDsIlsaBlb84XeNodQMva1Vl6s%3d"
    },
    {
      "date": "August 07",
      "tournament": "Philly Open 2022",
      "tournamentId": "Philly-Open-2022",
      "gameId": "7UDf2RBDHP3OkjoZnP8gJIT5sJTTqn1C%2bxgSPXIAWU0%3d",
      "team": "Richmond Floodwall",
      "score": "6",
      "oppScore": "9",
      "opp": "Rebels",
      "oppId": "uV%2beAif391mDFcejwcWVicj%2fYkXfD%2fwqKg5TtqrsFno%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "GoimM%2fhQ3JgQOB5skjQgx%2babDi6BHvAncz3t5O5ceeo%3d",
      "team": "Richmond Floodwall",
      "score": "7",
      "oppScore": "11",
      "opp": "baNC",
      "oppId": "uce10clG%2fWf3GrKUxwKToSDTqXSp6FS58Fb50TN%2fPwU%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "dFIFJAoVprG%2fu9%2fXsjrM5am%2ffU4ZzBQiof4BYmzhfRU%3d",
      "team": "Richmond Floodwall",
      "score": "8",
      "oppScore": "11",
      "opp": "Triumph",
      "oppId": "wAZDt10Q5MoiqMIAGOgPxedaQ2KA3QCSPn7hC8W%2bn5s%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "fcuSrVPEBVCEMRjJ8bn%2bmJc%2fede5RCo04tmuHOv2vBw%3d",
      "team": "Richmond Floodwall",
      "score": "1",
      "oppScore": "11",
      "opp": "Lost Boys",
      "oppId": "aNMyR0uAJNwnidjeClZAMqixGjtlQqA6k7CiZP1R0%2bM%3d"
    },
    {
      "date": "August 27",
      "tournament": "FCS Invite 2022",
      "tournamentId": "FCS-Invite-2022",
      "gameId": "5mbgXo6uO%2bNRZwCrGukK5XTf4TACcP8WbanJfaD9Nq4%3d",
      "team": "Richmond Floodwall",
      "score": "5",
      "oppScore": "11",
      "opp": "Redwolves",
      "oppId": "DoL%2frShNUJHM7OLFhcsMzWlsS0aohpfm30HvDbpVZi4%3d"
    }
  ],
  "RFpmXY6FT7eRry4gGUAnkSywT92Q24c%2fMw5CrinbhpI%3d": [
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "ykl9NSec02yU3aPxGKwi1jtdlQFVY0p8Nm0VMLZdJic%3d",
      "team": "NOMAD",
      "score": "6",
      "oppScore": "13",
      "opp": "Knights of Ni",
      "oppId": "dgH60oTrddgw%2bSl2xkIe%2fis%2fu1HY9ndk1MZTdN4OiAs%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "pXJrz4BsxxAiOsOpEEP5pwWbGh%2bQ2jwcclguH%2bqpT6w%3d",
      "team": "NOMAD",
      "score": "7",
      "oppScore": "13",
      "opp": "Mallard II",
      "oppId": "waPLoije6zVsjVUcOXTlGSFZ10PPabaoS6kk%2bnW2NCo%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "xqCGnzpUkP9x1DmgzpaGsR3HS%2biaaI1kQadUEkEkJN0%3d",
      "team": "NOMAD",
      "score": "7",
      "oppScore": "13",
      "opp": "Cryptic",
      "oppId": "Qhc%2fzwjxDlH6UTqMQqHKE2ms56DaeAMWMHQJCBwAjIw%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "utOtkbjMLP2GlAaOdMjTKEcQ%2bI5uIk5kMk3ddDECcFI%3d",
      "team": "NOMAD",
      "score": "13",
      "oppScore": "6",
      "opp": "Trident II",
      "oppId": "b69A0xfMxQKwR01A7jfOqdv1wbChbTxRp5jko3aOCXk%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "e6qQHltu8UyuIyu%2b2Fb32%2bXR9AmgsuAaeXxuRHgv72k%3d",
      "team": "NOMAD",
      "score": "13",
      "oppScore": "12",
      "opp": "THE BODY",
      "oppId": "nyLl78Eg24EtJDKG0oHsU9e%2fg0hrOYqklm7h8m4R0Jo%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "20XhEU%2fPGU8iWGlMH0SDeMQDVNcXgEaDUe4fLqeoCMc%3d",
      "team": "NOMAD",
      "score": "3",
      "oppScore": "7",
      "opp": "DINGWOP",
      "oppId": "xZAGkPMHJ9VCCvgCVVn5WRxQtwy%2bmZ7Q%2bGsb4rLqQqE%3d"
    }
  ],
  "xZAGkPMHJ9VCCvgCVVn5WRxQtwy%2bmZ7Q%2bGsb4rLqQqE%3d": [
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "aCb642SFPsILqxuscXb0JxZygYCbzzb6LBQG%2bBdjfko%3d",
      "team": "DINGWOP",
      "score": "7",
      "oppScore": "13",
      "opp": "Mallard II",
      "oppId": "waPLoije6zVsjVUcOXTlGSFZ10PPabaoS6kk%2bnW2NCo%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "daR3V1xm0sD4InWcoFX7sODYWG%2beG4ECivgz0FxleM8%3d",
      "team": "DINGWOP",
      "score": "6",
      "oppScore": "13",
      "opp": "Cryptic",
      "oppId": "Qhc%2fzwjxDlH6UTqMQqHKE2ms56DaeAMWMHQJCBwAjIw%3d"
    },
    {
      "date": "August 06",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "Nkoj8wgU2w30yqTcrxaWwIgVdejkDdFzLuoCDc0ASAs%3d",
      "team": "DINGWOP",
      "score": "7",
      "oppScore": "13",
      "opp": "DeMo",
      "oppId": "FdlFpUSGIahm89rZTXrHg2K85ugGqml2fjUWEPHtgPo%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "YkW00%2f0sWgkB4mOpO3ZevBXjJq4%2ft2JnMNNilSYTrbM%3d",
      "team": "DINGWOP",
      "score": "13",
      "oppScore": "8",
      "opp": "THE BODY",
      "oppId": "nyLl78Eg24EtJDKG0oHsU9e%2fg0hrOYqklm7h8m4R0Jo%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "Has4L%2bvJTvsitizH6QRyFlgYdgQ8VODiYWSz4ch4I%2fk%3d",
      "team": "DINGWOP",
      "score": "10",
      "oppScore": "12",
      "opp": "Trident II",
      "oppId": "b69A0xfMxQKwR01A7jfOqdv1wbChbTxRp5jko3aOCXk%3d"
    },
    {
      "date": "August 07",
      "tournament": "Spirit of the Plains 2022",
      "tournamentId": "Spirit-of-the-Plains-2022",
      "gameId": "20XhEU%2fPGU8iWGlMH0SDeMQDVNcXgEaDUe4fLqeoCMc%3d",
      "team": "DINGWOP",
      "score": "7",
      "oppScore": "3",
      "opp": "NOMAD",
      "oppId": "RFpmXY6FT7eRry4gGUAnkSywT92Q24c%2fMw5CrinbhpI%3d"
    }
  ],
  "VlCPZLsfBQ0DobBH%2fc22bLMbT2Ge3MbFrq%2fRZ8Jw0gM%3d": [
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "fNwZsXXmS5DAX5QyRfhYpdhUXr%2fjDC4Q6HY3O3sk%2b9o%3d",
      "team": "Nain Rouge",
      "score": "9",
      "oppScore": "13",
      "opp": "HouSE",
      "oppId": "W3JpjYTNBVirvq%2bLhhgD1xswtN%2bDHXo2Y5uhwfm%2f450%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "wPA%2fq7GnBUsUd7930rdB4kqsgUhY0509nTRmW1X6Jhs%3d",
      "team": "Nain Rouge",
      "score": "13",
      "oppScore": "8",
      "opp": "Timber",
      "oppId": "KEdthZtu%2ffyLTQQr6Y655G91Wz0j0VrQj7Ap2G5YBOM%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "YUuHwUPYPb230GsBoKA4f9cHnUnd4lerKdGn%2be5uCYM%3d",
      "team": "Nain Rouge",
      "score": "13",
      "oppScore": "12",
      "opp": "Chimney",
      "oppId": "jzVhyLybRfcABTsshmdvgsxYDSASMTHMGNde%2bkQTb4Y%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "outfIxF2vanpk970FXST2b3r8XfAtoqotRgwUR7qmiE%3d",
      "team": "Nain Rouge",
      "score": "8",
      "oppScore": "13",
      "opp": "Haymaker",
      "oppId": "F7zZjPQTPbFe%2fxIVicHJD2zRIOORpO5hSxfBuY5fxcQ%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "Y1QWzL9YCsqkD0U%2bhJ6%2bgvwZJODU3ApZZlPix6p88Qg%3d",
      "team": "Nain Rouge",
      "score": "13",
      "oppScore": "12",
      "opp": "Enigma",
      "oppId": "vGMVLiic34oSyMk%2bETpVqMkuexU7MBkROOjGtFdKoVM%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "UZkRz9UVnvJsTT9tPlZ87HCpIpL3bx%2bMfvnku24sEYw%3d",
      "team": "Nain Rouge",
      "score": "12",
      "oppScore": "13",
      "opp": "Nomads",
      "oppId": "clyQEvW53ITGsU%2bGFSkoVd77O856fO%2f%2fPStXV2bXfy4%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "vXZsDrridsPi60K%2bH9VOixV%2bttImQbnjZxznvYHjPXA%3d",
      "team": "Nain Rouge",
      "score": "11",
      "oppScore": "13",
      "opp": "Beacon",
      "oppId": "4n5jY6eFSRuptn3xrctxCFjAy7g9dqmJM2uPNhY3qbk%3d"
    }
  ],
  "%2bIdRZdPfxa07Yaa3PzfmJw5so76rO6A7JVJAQXOY%2b40%3d": [
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "ED85YQkp6QcR4zUlsQiWc%2bbuJwrgR9HZIoVWX%2bhz45M%3d",
      "team": "Rubicon Rapids",
      "score": "5",
      "oppScore": "13",
      "opp": "MKE",
      "oppId": "qHyFAYrlLVWh07Z9rgHqv1nh7SsCt5k4p8Ri0Bna7Zc%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "HnYzHz2JY3nWbFgkqCGKPLWdr3F5PbEq3J8p4i%2bfOBQ%3d",
      "team": "Rubicon Rapids",
      "score": "5",
      "oppScore": "13",
      "opp": "Cryptic",
      "oppId": "Qhc%2fzwjxDlH6UTqMQqHKE2ms56DaeAMWMHQJCBwAjIw%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "O2BEWwC1ciAtJ3CCh5niKLCZ8W9nZ5ZNKoR8DlJNW%2fg%3d",
      "team": "Rubicon Rapids",
      "score": "5",
      "oppScore": "13",
      "opp": "Enigma",
      "oppId": "vGMVLiic34oSyMk%2bETpVqMkuexU7MBkROOjGtFdKoVM%3d"
    },
    {
      "date": "August 20",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "g7wJ4ftaMWEyrywSpeROzoB9lpDCvbaqTpXaiQbrhHQ%3d",
      "team": "Rubicon Rapids",
      "score": "11",
      "oppScore": "10",
      "opp": "Trident II",
      "oppId": "b69A0xfMxQKwR01A7jfOqdv1wbChbTxRp5jko3aOCXk%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "SQiGsHDd9PqqCSC3k6%2fwmZlTUdent8p1baRe6EvvIZg%3d",
      "team": "Rubicon Rapids",
      "score": "8",
      "oppScore": "13",
      "opp": "Nomads",
      "oppId": "clyQEvW53ITGsU%2bGFSkoVd77O856fO%2f%2fPStXV2bXfy4%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "0xnpzdCNjIcU%2bugPkHimmJqir2xR36jwDwkb%2beTQ7yA%3d",
      "team": "Rubicon Rapids",
      "score": "11",
      "oppScore": "13",
      "opp": "Enigma",
      "oppId": "vGMVLiic34oSyMk%2bETpVqMkuexU7MBkROOjGtFdKoVM%3d"
    },
    {
      "date": "August 21",
      "tournament": "Cooler Classic 33",
      "tournamentId": "Cooler-Classic-33",
      "gameId": "40%2fG0O5CqsYwd9bfzdX31OX98Ymt%2bGp%2bCGfaFGZfys8%3d",
      "team": "Rubicon Rapids",
      "score": "10",
      "oppScore": "13",
      "opp": "Timber",
      "oppId": "KEdthZtu%2ffyLTQQr6Y655G91Wz0j0VrQj7Ap2G5YBOM%3d"
    }
  ],
  "OmOeVI6iSB0tHCNLK01vsOOpZmXF97z%2b2x81ifAm8Vs%3d": [
    {
      "date": "August 20",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "WtENl9ycDMWwXNKzeV%2bgpFqKtg%2bwPovCBev%2fI1jE%2baY%3d",
      "team": "Hooch",
      "score": "9",
      "oppScore": "13",
      "opp": "Delirium",
      "oppId": "0yudJHn8N3NbInYx1wqMxoticHnPBt4YkAfZZAUc7U8%3d"
    },
    {
      "date": "August 20",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "%2bowgWugqrGoAOrZLhXwuhMD5YkCZa46sEJChcwARrM4%3d",
      "team": "Hooch",
      "score": "13",
      "oppScore": "11",
      "opp": "Tennessee Folklore",
      "oppId": "z7Hwsz316C%2bFf9Cg1gKP3NGBSqxMqnFjQRbeyMUhUD4%3d"
    },
    {
      "date": "August 20",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "5axhaC4kT%2bvRg%2fGGuxokC3apWfDcfbkqfCVLWdGEECg%3d",
      "team": "Hooch",
      "score": "11",
      "oppScore": "13",
      "opp": "Alliance",
      "oppId": "%2f7JeYXylBxa9Xw5f5LSwua5DyAomcfJ7a7MZDP7nSUs%3d"
    },
    {
      "date": "August 20",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "kz6yxmht6Cp49EYvUFvKIAacUopNWkifUcdW0m%2birbg%3d",
      "team": "Hooch",
      "score": "11",
      "oppScore": "9",
      "opp": "Atlanta Arson",
      "oppId": "oAgat63CZNVga8weCR1ui3IB9YvjaA54miCvE%2f%2bRb5g%3d"
    },
    {
      "date": "August 21",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "62ZeG9V0S9LT5j5F6AiWfYqTfChO1fqdqDAX44BhMus%3d",
      "team": "Hooch",
      "score": "12",
      "oppScore": "13",
      "opp": "Tennessee Folklore",
      "oppId": "z7Hwsz316C%2bFf9Cg1gKP3NGBSqxMqnFjQRbeyMUhUD4%3d"
    },
    {
      "date": "August 21",
      "tournament": "Trestlemania IV",
      "tournamentId": "Trestlemania-IV",
      "gameId": "X5vR7YN%2bh7qv0yiy5YUPZRMuafrB9KRI%2fKSwE9PB4ro%3d",
      "team": "Hooch",
      "score": "9",
      "oppScore": "6",
      "opp": "Alliance",
      "oppId": "%2f7JeYXylBxa9Xw5f5LSwua5DyAomcfJ7a7MZDP7nSUs%3d"
    }
  ],
  "smh7D9CkiNxg%2bf%2b9mPfQh2h%2fUCMicaHAcvHdBji3s6k%3d": [
    {
      "date": "August 21",
      "tournament": "Raleigh Round Robin",
      "tournamentId": "Raleigh-Round-Robin",
      "gameId": "GjYpDQU%2fdsKYFyNi26Xdp866KTONizXO4BwXjlX7tMM%3d",
      "team": "Ring of Fire",
      "score": "11",
      "oppScore": "8",
      "opp": "Chain Lightning",
      "oppId": "xMA73o8VVEaOTmaBmpN0fQZ4779P9LJ9jH3j44aVabA%3d"
    },
    {
      "date": "August 21",
      "tournament": "Raleigh Round Robin",
      "tournamentId": "Raleigh-Round-Robin",
      "gameId": "DKYE5j3wK7IIbE5YtFW1RA8faX4SdkRB5bduFVKdR58%3d",
      "team": "Ring of Fire",
      "score": "13",
      "oppScore": "2",
      "opp": "Brickhouse",
      "oppId": "b5ARnVg7OngG1a0%2fJuMZTHluwgNFe%2bN5esQIIKsEOUY%3d"
    },
    {
      "date": "August 21",
      "tournament": "Raleigh Round Robin",
      "tournamentId": "Raleigh-Round-Robin",
      "gameId": "TazdgpjkqHEkA9LUaoYR8i67fx3SaWNQ7IVVMwfxlMU%3d",
      "team": "Ring of Fire",
      "score": "11",
      "oppScore": "9",
      "opp": "Chain Lightning",
      "oppId": "xMA73o8VVEaOTmaBmpN0fQZ4779P9LJ9jH3j44aVabA%3d"
    },
    {
      "date": "August 21",
      "tournament": "Raleigh Round Robin",
      "tournamentId": "Raleigh-Round-Robin",
      "gameId": "vKcjH7AbzCH%2f%2b%2fMzc3feqi1sPXDKg9ZE8ImnTnQDVdU%3d",
      "team": "Ring of Fire",
      "score": "11",
      "oppScore": "6",
      "opp": "Raleigh-Durham United",
      "oppId": "ESHhN%2bMK1oy0j%2bQJSL8mb9cq8h30Erb63T07U8pubEc%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "d2",
      "team": "Ring of Fire",
      "score": "7",
      "oppScore": "15",
      "opp": "Truck Stop",
      "oppId": "pUj3i6rzWdnpW5TeEEoriE%2fRmJjK%2brKBLe8y4LGWtZY%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "d3",
      "team": "Ring of Fire",
      "score": "15",
      "oppScore": "10",
      "opp": "Pittsburgh Temper",
      "oppId": "u%2bSlJ3XL7d589O5YW%2bbHhuzzJ855SfIO%2bGHlzwH3w3M%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "pq3",
      "team": "Ring of Fire",
      "score": "15",
      "oppScore": "9",
      "opp": "Sub Zero",
      "oppId": "8R4mmVt8rvJ%2bEDsQVxGD5KkmF8Rfnl3EHt4CpzxANac%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "q3",
      "team": "Ring of Fire",
      "score": "14",
      "oppScore": "12",
      "opp": "Sockeye",
      "oppId": "Ur5qIXbyxiUHKLhvlB%2bSYJDI9nx7KM4Txz1fUtmqC2w%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "s2",
      "team": "Ring of Fire",
      "score": "14",
      "oppScore": "15",
      "opp": "Truck Stop",
      "oppId": "pUj3i6rzWdnpW5TeEEoriE%2fRmJjK%2brKBLe8y4LGWtZY%3d"
    },
    {
      "date": "September 05",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "third",
      "team": "Ring of Fire",
      "score": "15",
      "oppScore": "11",
      "opp": "DiG",
      "oppId": "kn%2bHmtzxdjY7vxb%2fpJ9qsfEsWaDB6JrUBL7Ga4bU18M%3d"
    },
  ],
  "%2fk2Al%2fURWo%2fM8QndFYA1FAuFkpV3PwlxFADSQxiJBu4%3d": [
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "OK2Sk3gILadaPP0UYBCuFkch3PHJXCi9fR89xF3Tuew%3d",
      "team": "Riverside Messengers-B",
      "score": "1",
      "oppScore": "13",
      "opp": "Cowtown Cannons",
      "oppId": "cHgu6r1HLUuQviYkIKB3XqF38Ayn7u3chdXCX2n5e30%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "u8assaIprrh50mbIX8dyWxQ2DGGU%2f8%2blqy1P5jO1n5g%3d",
      "team": "Riverside Messengers-B",
      "score": "3",
      "oppScore": "13",
      "opp": "Harvey Cats",
      "oppId": "cbjkcOMrhSyGKLqnK1uuJLy8ENbcEcYemex94WHxvxU%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "dcgEPZsmGx%2fmgHQjnpBjklKUoBM7YEr5CSvaVgx802A%3d",
      "team": "Riverside Messengers-B",
      "score": "4",
      "oppScore": "13",
      "opp": "Riverside",
      "oppId": "btdybQHkeLP7UjqWKhbXqcEMojopFgSilO0%2b3mjGSzM%3d"
    },
    {
      "date": "August 20",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "bYk7f%2fqFpiKj3FSH9MkLzEkuZjJxI7Oz8j8DoF0wY%2fU%3d",
      "team": "Riverside Messengers-B",
      "score": "5",
      "oppScore": "13",
      "opp": "H.I.P",
      "oppId": "yMGVOSF8BWomPoB9kZvtpq6%2fnNPqHBwUahTfiK5yXoo%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "G6Bk2%2fC9dpJzMEcl9t1lRo25n0fxhEYajFk%2fIphsDbw%3d",
      "team": "Riverside Messengers-B",
      "score": "3",
      "oppScore": "13",
      "opp": "Alamode",
      "oppId": "KQpJIKwJNo0Ue1B4HG72J1RHG35Xquk0pCeyRP4y06g%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "2J%2bBx7NaEmVg90GX46NmbL6es5frk2ZZroYDakuJRnc%3d",
      "team": "Riverside Messengers-B",
      "score": "1",
      "oppScore": "13",
      "opp": "Sonoran Dog",
      "oppId": "WAPjwPL2R8YMCWgolwmIL1cqRoFxsqyk2gQeZ9iDOMA%3d"
    },
    {
      "date": "August 21",
      "tournament": "Riverside Classic",
      "tournamentId": "Riverside-Classic",
      "gameId": "6L3MojiBodjbNNxJ%2bsfa08Uybav32kWIZcsShd8JAv0%3d",
      "team": "Riverside Messengers-B",
      "score": "5",
      "oppScore": "10",
      "opp": "San Antonio Warhawks",
      "oppId": "APGZ104aX6NYbHxFkplxXFuRTM5w8xjmMBKqQp4GrAw%3d"
    }
  ],
  "z3CZ5zLpuh4%2b6lmsKpQSH%2fDpWRfMktj5DGqERnXkcXY%3d": [
    {
      "date": "July 23",
      "tournament": "Angel City Shootout 2022",
      "tournamentId": "Angel-City-Shootout-2022",
      "gameId": "SRA2P6w3%2faRUtqFYYT88sdVayoylIX5oVqY5NxyxduM%3d",
      "team": "Offshore",
      "score": "13",
      "oppScore": "15",
      "opp": "Hazard",
      "oppId": "P0N8mJF5hP7HulHfG72Zdu2tlioAZAaLyhUwPE4s6T8%3d"
    },
    {
      "date": "July 23",
      "tournament": "Angel City Shootout 2022",
      "tournamentId": "Angel-City-Shootout-2022",
      "gameId": "bOYpObOA9McrS8SnZ5zFKg%2bxLgXNet%2bG%2bch2c1YvLoU%3d",
      "team": "Offshore",
      "score": "9",
      "oppScore": "15",
      "opp": "Drought",
      "oppId": "ksz2i7%2bjkkoIQzWKSrtEphZr9icgiUTPL3Ixum49pfY%3d"
    },
    {
      "date": "July 23",
      "tournament": "Angel City Shootout 2022",
      "tournamentId": "Angel-City-Shootout-2022",
      "gameId": "iIorzRRRsHBFLhRO5PtIG2FtpFBMQM%2fRJHe%2fQHpHFYQ%3d",
      "team": "Offshore",
      "score": "14",
      "oppScore": "11",
      "opp": "OC Crows",
      "oppId": "NvxMsKI3fOV1hpY0a9oUKl0EGgRtzFW8o3PpEg3r%2b%2bI%3d"
    },
    {
      "date": "August 21",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "PyplyxUfZhRLeJj8XKdZGOhbhJil9qzMqXRXNuRSxnI%3d",
      "team": "Offshore",
      "score": "8",
      "oppScore": "12",
      "opp": "Seven Out",
      "oppId": "EKT%2b4wN3oW3%2bvTZF1BOVy%2fpLrku%2frikWkw8iDqstIOQ%3d"
    },
    {
      "date": "August 21",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "%2f9MqJfqO5lu0Va2dj2jBnmtz2QtPOtdQ4Bib%2bKgTpqE%3d",
      "team": "Offshore",
      "score": "13",
      "oppScore": "9",
      "opp": "PowderHogs",
      "oppId": "4M9MJYyVOotcr31jr1X%2bW%2fnNzBLDCtMhEBgjHQi1W9s%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "G5scC3H65H11tR4haorZg0sINfWT1S68vFTgbfUuHnI%3d",
      "team": "Offshore",
      "score": "10",
      "oppScore": "11",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "NuwJuxdHlZQ%2fdhhbdC9hOTjtCqxKAi4shm7KszxUOfs%3d",
      "team": "Offshore",
      "score": "3",
      "oppScore": "13",
      "opp": "Switchback",
      "oppId": "XNBtJjuPsH6Zq%2bmVmPwL1Zo0%2bl5xYeh3mWrrpzn7CTk%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "WAe68uWB11OA2EX8QEL2qQGSK2k5Cl%2fpl%2bDewReFh5U%3d",
      "team": "Offshore",
      "score": "8",
      "oppScore": "13",
      "opp": "Utah Hatu",
      "oppId": "%2bmdoaqywGR6P4rhXTSYYxtvjQhBX094atWIs4EEL1Ys%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "eVF5MXXRlxMkxOq2O8x3%2bFOEk2%2b2iymgf8beeYUAUbA%3d",
      "team": "Offshore",
      "score": "5",
      "oppScore": "13",
      "opp": "Drought",
      "oppId": "ksz2i7%2bjkkoIQzWKSrtEphZr9icgiUTPL3Ixum49pfY%3d"
    }
  ],
  "%2bmdoaqywGR6P4rhXTSYYxtvjQhBX094atWIs4EEL1Ys%3d": [
    {
      "date": "August 21",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "GrHUJrW9qkVWO%2fJ9vpu8GD2w5zeabW7%2fPo3Z1uR8UPo%3d",
      "team": "Utah Hatu",
      "score": "11",
      "oppScore": "15",
      "opp": "Fungi",
      "oppId": "IVugk83nUx1kxR%2boFsz21WxwaNCKRmmOogBmMyTV3XI%3d"
    },
    {
      "date": "August 21",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "hmwRPNY2n50N1O5CozEjBcEdtwCscJYs45GZgEcUuEs%3d",
      "team": "Utah Hatu",
      "score": "13",
      "oppScore": "8",
      "opp": "Drought",
      "oppId": "ksz2i7%2bjkkoIQzWKSrtEphZr9icgiUTPL3Ixum49pfY%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "yUSMM02NIcou4pD7pgoCQQ4qem5mIk%2bnoLvnJ0MYAvQ%3d",
      "team": "Utah Hatu",
      "score": "12",
      "oppScore": "13",
      "opp": "Drought",
      "oppId": "ksz2i7%2bjkkoIQzWKSrtEphZr9icgiUTPL3Ixum49pfY%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "WAe68uWB11OA2EX8QEL2qQGSK2k5Cl%2fpl%2bDewReFh5U%3d",
      "team": "Utah Hatu",
      "score": "13",
      "oppScore": "8",
      "opp": "Offshore",
      "oppId": "z3CZ5zLpuh4%2b6lmsKpQSH%2fDpWRfMktj5DGqERnXkcXY%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "AOV621y71nqimqjmZPiOOpHRrO1potTiv69Su2n89yA%3d",
      "team": "Utah Hatu",
      "score": "10",
      "oppScore": "13",
      "opp": "Switchback",
      "oppId": "XNBtJjuPsH6Zq%2bmVmPwL1Zo0%2bl5xYeh3mWrrpzn7CTk%3d"
    },
    {
      "date": "August 20",
      "tournament": "Ski Town Classic 2022",
      "tournamentId": "Ski-Town-Classic-2022",
      "gameId": "owk%2fwVWvKBfaoW8Mf9I12J7RDRVgSXpzsKCtfMQTqAw%3d",
      "team": "Utah Hatu",
      "score": "13",
      "oppScore": "11",
      "opp": "ISO Atmo",
      "oppId": "qj5qvBoGCkK49XapYY26HzlK53OgO2pRUs1ALAfTCO8%3d"
    },
    {
      "date": "August 25",
      "tournament": "Utah Round Robin",
      "tournamentId": "Utah-Round-Robin",
      "gameId": "AOUfNwva23WOKPDYXj4ngqLsHAB4tdyi7phfiSqAxpQ%3d",
      "team": "Utah Hatu",
      "score": "4",
      "oppScore": "11",
      "opp": "Pando",
      "oppId": "CWW3TWk5yeYKuKEW8USBkbYZz5DyfbKjfswKBgSzYsA%3d"
    },
    {
      "date": "August 25",
      "tournament": "Utah Round Robin",
      "tournamentId": "Utah-Round-Robin",
      "gameId": "T9qBDS0Ipvqwvpd5w%2faYEQxjpC6RsqxfzJVNl8RTnJY%3d",
      "team": "Utah Hatu",
      "score": "11",
      "oppScore": "5",
      "opp": "Old Ephraim",
      "oppId": "8pwKgFPj6U4BEx29pAWNsvAmMgzhAWQy%2fWTSNOSoj3c%3d"
    },
    {
      "date": "August 25",
      "tournament": "Utah Round Robin",
      "tournamentId": "Utah-Round-Robin",
      "gameId": "nWIax3zEysFNREfXnYShy2zNZy3B07tFXJs0NcJacTM%3d",
      "team": "Utah Hatu",
      "score": "11",
      "oppScore": "8",
      "opp": "PowderHogs",
      "oppId": "4M9MJYyVOotcr31jr1X%2bW%2fnNzBLDCtMhEBgjHQi1W9s%3d"
    },
    {
      "date": "August 25",
      "tournament": "Utah Round Robin",
      "tournamentId": "Utah-Round-Robin",
      "gameId": "9O%2b6ZkRSYZCaEk56%2bL%2fGhdj4lEu3XevEUpUSXgdTPXI%3d",
      "team": "Utah Hatu",
      "score": "4",
      "oppScore": "11",
      "opp": "Pando",
      "oppId": "CWW3TWk5yeYKuKEW8USBkbYZz5DyfbKjfswKBgSzYsA%3d"
    }
  ],
  "Nm8EGFzg2L2gF7kgWvfP7%2bbHNWDz7Dq77ckjxxzanlg%3d": [
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "dR5cNKZw%2bMJYRO6Uz61EbkMN1GPkrcyZaChQG%2fNe2Us%3d",
      "team": "Doublewide",
      "score": "15",
      "oppScore": "6",
      "opp": "Garden State Ultimate",
      "oppId": "rv5RwYIoNIY0EqGzDkizdJwIJ%2bLTiDa65wF3GyKDUjo%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "%2fkSoNekY4NU%2fHgtlg6eSL%2fBTRkeGVc7bt8YuSjfb0Fg%3d",
      "team": "Doublewide",
      "score": "15",
      "oppScore": "7",
      "opp": "I-69",
      "oppId": "a64fDYPntTHPU4vghTeNstb32Z0JFlq528bbATJx%2bbs%3d"
    },
    {
      "date": "August 20",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "SuPEEd9mpo7MqhPzzyb0ZMMT01junB%2fFgOixECLyR00%3d",
      "team": "Doublewide",
      "score": "14",
      "oppScore": "10",
      "opp": "Trident I",
      "oppId": "HJQp9EgoBkW9LmipJ4BOA7JQ%2bJ%2fsSTavaeYtiM6KQus%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "Iq%2bWKXmDvKZWLc0OtnTcmNByUliYSQAXOCoAw5Wy78o%3d",
      "team": "Doublewide",
      "score": "15",
      "oppScore": "9",
      "opp": "Mad Men",
      "oppId": "Al%2fA4sj9NEIvG2pfoVeKR8OCQL%2fREoXEJcD3sZnI49A%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "SYEEFghJvNjO%2f3Vbk%2fctRdzylpyAiNfkIXQaya0RNu0%3d",
      "team": "Doublewide",
      "score": "11",
      "oppScore": "13",
      "opp": "Omen",
      "oppId": "LvpOSyQd02uHlDLxvVMuw0fzNjwTUO8G7CpXAwQn40I%3d"
    },
    {
      "date": "August 21",
      "tournament": "TCT Elite Select Challenge 2022   Indy Invite",
      "tournamentId": "TCT-Elite-Select-Challenge-2022",
      "gameId": "j0fUQcE6bnQYpzYbuI8ewWhVv7saiRCsOl1QdyNLw28%3d",
      "team": "Doublewide",
      "score": "12",
      "oppScore": "10",
      "opp": "Vault",
      "oppId": "3xV6rIwyIem%2bql5tz%2bZmFG9gr%2bp6WKu5k4q5bwFcSp0%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "a2",
      "team": "Doublewide",
      "score": "11",
      "oppScore": "15",
      "opp": "Sockeye",
      "oppId": "Ur5qIXbyxiUHKLhvlB%2bSYJDI9nx7KM4Txz1fUtmqC2w%3d"
    },
    {
      "date": "September 03",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "a3",
      "team": "Doublewide",
      "score": "10",
      "oppScore": "15",
      "opp": "SoCal Condors",
      "oppId": "Hs8IYCPj2pf9Nump3cK24FlAdOgCZVYhTQKGBSXFLXA%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "pq2",
      "team": "Doublewide",
      "score": "11",
      "oppScore": "15",
      "opp": "PoNY",
      "oppId": "s8Hq5QrzeTvvFPbmIo%2bj5qGjiFRUrzVIivVUtEM%2bvd4%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "e2",
      "team": "Doublewide",
      "score": "15",
      "oppScore": "8",
      "opp": "SoCal Condors",
      "oppId": "Hs8IYCPj2pf9Nump3cK24FlAdOgCZVYhTQKGBSXFLXA%3d"
    },
    {
      "date": "September 04",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "e4",
      "team": "Doublewide",
      "score": "15",
      "oppScore": "9",
      "opp": "Sub Zero",
      "oppId": "8R4mmVt8rvJ%2bEDsQVxGD5KkmF8Rfnl3EHt4CpzxANac%3d"
    },
    {
      "date": "September 05",
      "tournament": "Pro Champs",
      "tournamentId": "Pro Champs",
      "gameId": "e5",
      "team": "Doublewide",
      "score": "15",
      "oppScore": "11",
      "opp": "Pittsburgh Temper",
      "oppId": "u%2bSlJ3XL7d589O5YW%2bbHhuzzJ855SfIO%2bGHlzwH3w3M%3d"
    },
  ],
  "kq%2bjX0z0mI2demJGurW9c4YwXa1qYvydu%2fcl7Dmf4PM%3d": [
    {
      "date": "August 20",
      "tournament": "Northeast Classic 2022",
      "tournamentId": "Northeast-Classic-2022",
      "gameId": "DzCM6YK26HpugvIwQ4%2bP9ZOZvp0FqhMR9s%2flDP0rJso%3d",
      "team": "ADK Mountain Crew",
      "score": "10",
      "oppScore": "15",
      "opp": "MBTA",
      "oppId": "hLR3gjv9xQ1g1QebpZRZmoy9aTcPypW2VOulzmPNeXs%3d"
    },
    {
      "date": "August 20",
      "tournament": "Northeast Classic 2022",
      "tournamentId": "Northeast-Classic-2022",
      "gameId": "tdlP2FndG0RXnFYGVzNAd5%2bi2SR%2bRHcRvbQ9nMaWaCI%3d",
      "team": "ADK Mountain Crew",
      "score": "15",
      "oppScore": "7",
      "opp": "Odyssey",
      "oppId": "b3BwYIqzVB8cFGN1zic0%2bcWc5J5ATsFKGuQD%2ferKcTM%3d"
    },
    {
      "date": "August 20",
      "tournament": "Northeast Classic 2022",
      "tournamentId": "Northeast-Classic-2022",
      "gameId": "%2f9OOVaR99sZoCm71z%2bxLeEKrYEw94CWrhPOwVzLUkr0%3d",
      "team": "ADK Mountain Crew",
      "score": "15",
      "oppScore": "14",
      "opp": "Alibi",
      "oppId": "YEITgS48Qi42eAbGR80m%2fHHsltj1RQq%2be2tZnpaH3HY%3d"
    },
    {
      "date": "August 21",
      "tournament": "Northeast Classic 2022",
      "tournamentId": "Northeast-Classic-2022",
      "gameId": "f4REulb%2bL882EwXfbVu4Xgpf4uaL7Bia8kLhgnn8%2bCs%3d",
      "team": "ADK Mountain Crew",
      "score": "11",
      "oppScore": "15",
      "opp": "Alibi",
      "oppId": "YEITgS48Qi42eAbGR80m%2fHHsltj1RQq%2be2tZnpaH3HY%3d"
    },
    {
      "date": "August 22",
      "tournament": "Northeast Classic 2022",
      "tournamentId": "Northeast-Classic-2022",
      "gameId": "sU%2fligE45YmpsMpMCt%2b9jNiFOo9ouogZRv9mTEfDFmE%3d",
      "team": "ADK Mountain Crew",
      "score": "10",
      "oppScore": "12",
      "opp": "Odyssey",
      "oppId": "b3BwYIqzVB8cFGN1zic0%2bcWc5J5ATsFKGuQD%2ferKcTM%3d"
    }
  ],
  "8pwKgFPj6U4BEx29pAWNsvAmMgzhAWQy%2fWTSNOSoj3c%3d": [
    {
      "date": "August 25",
      "tournament": "Utah Round Robin",
      "tournamentId": "Utah-Round-Robin",
      "gameId": "T9qBDS0Ipvqwvpd5w%2faYEQxjpC6RsqxfzJVNl8RTnJY%3d",
      "team": "Old Ephraim",
      "score": "5",
      "oppScore": "11",
      "opp": "Utah Hatu",
      "oppId": "%2bmdoaqywGR6P4rhXTSYYxtvjQhBX094atWIs4EEL1Ys%3d"
    },
    {
      "date": "August 25",
      "tournament": "Utah Round Robin",
      "tournamentId": "Utah-Round-Robin",
      "gameId": "421hTNka1n%2fO2uxavF9CWMbrYRINbILuZIwwkcenM9k%3d",
      "team": "Old Ephraim",
      "score": "3",
      "oppScore": "11",
      "opp": "Pando",
      "oppId": "CWW3TWk5yeYKuKEW8USBkbYZz5DyfbKjfswKBgSzYsA%3d"
    },
    {
      "date": "August 25",
      "tournament": "Utah Round Robin",
      "tournamentId": "Utah-Round-Robin",
      "gameId": "ZLlwjcU5KHKqRN%2byW5L0aRhnzej9SNfTuaFKMolwcUk%3d",
      "team": "Old Ephraim",
      "score": "4",
      "oppScore": "11",
      "opp": "PowderHogs",
      "oppId": "4M9MJYyVOotcr31jr1X%2bW%2fnNzBLDCtMhEBgjHQi1W9s%3d"
    }
  ],
  "mQPEfmcAYYIzzM4%2fYEbIkukCamR5oeyXQBECP7Pwhh8%3d": [
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "LBBdBR%2bwjcP7AARkJ%2bGMYeK55Zsm8AiIFdy1vxGFKFg%3d",
      "team": "7AM",
      "score": "4",
      "oppScore": "13",
      "opp": "Needham Squad",
      "oppId": "nB%2bSQRgdJB9OGhcTAQ0lU8uZ8f%2bTFwQebHh0A8topa0%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "IL%2b7VJ8cTP05TuvvzZt44N7zCkBgwVhOk8kJIi0NtNY%3d",
      "team": "7AM",
      "score": "13",
      "oppScore": "8",
      "opp": "Odyssey",
      "oppId": "b3BwYIqzVB8cFGN1zic0%2bcWc5J5ATsFKGuQD%2ferKcTM%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "rTTYTOZU8xqA7lo%2b5Hy3DCV8wjOQXDYSztcQJDvFREc%3d",
      "team": "7AM",
      "score": "12",
      "oppScore": "11",
      "opp": "Bomb Squad",
      "oppId": "GMU0OYY8eXqf1AR6M93JxRSXSpRgEq9sJxKUtkOjXgg%3d"
    }
  ],
  "A%2bfgoEP0pWAon7RlAWF7INXWdOLczWdCDnKVBK6W%2btY%3d": [
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "wezNQB5PcgAXNagFVq611YXHb3qwKxWMCo09Bkl8H7E%3d",
      "team": "FLI-B Honeybees",
      "score": "7",
      "oppScore": "13",
      "opp": "Scoop",
      "oppId": "Rj8mJfKJry2xyWaxTIh5HdXGLaTMxr7%2fXjyL4uJET2o%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "us55oNw6952maN0nR7Y9B1W5kH1Yf4kYMsaDQHd%2bHww%3d",
      "team": "FLI-B Honeybees",
      "score": "13",
      "oppScore": "12",
      "opp": "Deepfake",
      "oppId": "MjCJCQyu%2bVgQCxsNuCDsIlsaBlb84XeNodQMva1Vl6s%3d"
    },
    {
      "date": "August 27",
      "tournament": "The Incident 2022",
      "tournamentId": "The-Incident-2022",
      "gameId": "U3PiaVHMgZiXYZROPi78%2fp4LHR87Lp6t8jd%2fh4S9kTI%3d",
      "team": "FLI-B Honeybees",
      "score": "0",
      "oppScore": "13",
      "opp": "CITYWIDE Special",
      "oppId": "gfGiZOzR7u7D3oIG%2bAqrTtu3j0xEuTDZKIumDUKFRto%3d"
    }
  ],
  "GNSXf0PP6g6tw8m0RFzPDaKIh%2fXdPOhBGKFYOiBXFeQ%3d": [
    {
      "date": "August 27",
      "tournament": "CBR 2022",
      "tournamentId": "CBR-2022",
      "gameId": "pV%2bmiITemglcSLU%2bLC%2bsE22Iy0fvGVDydVA0S2v6Su4%3d",
      "team": "Make it Rain",
      "score": "10",
      "oppScore": "14",
      "opp": "Dark Star",
      "oppId": "c9pW2q80DagMAQ3MIlN4qV7%2fnufSjZapIvSzbu5OU%2f4%3d"
    },
    {
      "date": "August 27",
      "tournament": "CBR 2022",
      "tournamentId": "CBR-2022",
      "gameId": "wv0e1wxNuQksbWXmE%2b82SpimnoqBlxkrtP3hV%2b5gqXo%3d",
      "team": "Make it Rain",
      "score": "7",
      "oppScore": "13",
      "opp": "Ghost Train",
      "oppId": "Tqxb9%2fb2Hxtbg%2f54ifErhjS%2bc%2fBnacDZk5RhQ%2fd%2b%2f5g%3d"
    },
    {
      "date": "August 27",
      "tournament": "CBR 2022",
      "tournamentId": "CBR-2022",
      "gameId": "6lRLFZWBSzwlNWRuhSQJBcXxivX7T%2f8Y9yv6mlvb3kU%3d",
      "team": "Make it Rain",
      "score": "10",
      "oppScore": "12",
      "opp": "SOUF",
      "oppId": "apgZHT%2fi0I9dSEMbVZWQYonmRxVork%2bwAmVlY01CfPQ%3d"
    },
    {
      "date": "August 27",
      "tournament": "CBR 2022",
      "tournamentId": "CBR-2022",
      "gameId": "qLYTYIHqsJ8YUNedhXm49BJD1qTVQpUchKjkqQdnhxU%3d",
      "team": "Make it Rain",
      "score": "8",
      "oppScore": "15",
      "opp": "Streetgang",
      "oppId": "%2fo6V9EACiot%2bzfjGzj7GQtyBnMoDlmHGs3Nl35pLoeU%3d"
    }
  ]
};