import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { collection } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";

import {
  AudlPlayerData,
  AudlThrowData,
  PlayerStatRow,
  ThrowEvent,
  throwIsHuck,
  throwIsComplete,
} from "../common/audl";

const ALL_CATEGORIES = [
  "games",
  "o_points",
  "d_points",
  "throws",
  "completions",
  "completionPercent",
  "assists",
  "throwAways",
  "stalls",
  "hucks",
  "huckPercent",
  "huckCompletedPercent",
  "yards",
  "yardsPerThrow",
];

const useStyles = makeStyles(() =>
  createStyles({
    centerContent: {
      textAlign: "center",
      width: "70%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 10,
      marginBottom: 10,
    },
  })
);

export function AudlStats() {
  const classes = useStyles();

  const collectionRef = collection(useFirestore(), "audl");
  const { status, data } = useFirestoreCollectionData(collectionRef);

  const [teams, setTeams] = useState<{
    [key: string]: { full_name: string; abbrev: string };
  }>({});
  const [players, setPlayers] = useState<{
    [key: string]: AudlPlayerData;
  }>({});
  const [throws, setThrows] = useState<ThrowEvent[]>([]);
  const [statsObj, setStatsObj] = useState<{
    [playerId: string]: PlayerStatRow;
  }>({});

  const [order, setOrder] = useState<"desc" | "asc">("desc");
  const [orderBy, setOrderBy] = useState<string>("name");

  useEffect(() => {
    // If there is no data there is nothing to do.
    if (!data) return;
    let throwEvents: ThrowEvent[] = [];
    for (const d of data as unknown as AudlThrowData[]) {
      if (d.teams) {
        setTeams(d.teams);
      }
      if (d.throws) {
        for (const gameId of Object.keys(d.throws)) {
          throwEvents = throwEvents.concat(d.throws[gameId]);
        }
      }
      if (d.players) {
        setPlayers(d.players);
      }
    }
    setThrows(throwEvents);
  }, [data]);

  useEffect(() => {
    // Ignore until all of these are defined.
    if (
      Object.keys(teams).length === 0 ||
      Object.keys(players).length === 0 ||
      Object.keys(throws).length === 0
    ) {
      return;
    }
    const throwsPerPlayer: { [playerId: string]: ThrowEvent[] } = {};
    for (const throwEvt of throws) {
      const throwerId = throwEvt.t_id;
      if (!throwsPerPlayer[throwerId]) {
        throwsPerPlayer[throwerId] = [];
      }
      throwsPerPlayer[throwerId].push(throwEvt);
    }

    const statsObj: { [playerId: string]: PlayerStatRow } = {};
    for (const playerId of Object.keys(players)) {
      const playerThrows = throwsPerPlayer[playerId] || [];
      const completions = playerThrows.filter(throwIsComplete);
      const assists = playerThrows.filter((t) => t.res === "goal");
      const throwAways = playerThrows.filter((t) => t.res === "throw_away");
      const stalls = playerThrows.filter((t) => t.res === "stall");
      const hucks = playerThrows.filter(throwIsHuck);
      const completedHucks = hucks.filter(throwIsComplete);
      // Instead of counting dumps as negative yards, count them as 0.
      const yards = completions
        .map((t) => Math.max(t.y2 - t.y1, 0))
        .reduce((prev, cur) => prev + cur, 0);

      statsObj[playerId] = {
        name: players[playerId].name,
        team: teams[players[playerId].team_id].abbrev,
        teamId: players[playerId].team_id,
        games: players[playerId].games,
        o_points: players[playerId].o_points,
        d_points: players[playerId].d_points,
        throws: playerThrows.length,
        completions: completions.length,
        completionPercent: completions.length / (playerThrows.length || 1),
        assists: assists.length,
        throwAways: throwAways.length,
        stalls: stalls.length,
        hucks: hucks.length,
        huckPercent: hucks.length / (playerThrows.length || 1),
        huckCompletedPercent: completedHucks.length / (hucks.length || 1),
        yards,
        yardsPerThrow: yards / (completions.length + 1),
      };
    }
    setStatsObj(statsObj);
  }, [teams, players, throws]);

  function categoryIsPercent(category: string) {
    return (
      category === "completionPercent" ||
      category === "huckCompletedPercent" ||
      category === "huckPercent"
    );
  }

  function getCatForPlayer(category: string, playerId: string) {
    let val = (statsObj[playerId] as any)[category];
    if (categoryIsPercent(category)) {
      val = (val * 100).toFixed(1);
    }
    if (category === "yardsPerThrow") {
      val = val.toFixed(1);
    }
    return val;
  }

  function sortByCat(category: string) {
    const isAsc = orderBy === category && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(category);
  }

  function applyTableOrder(a: string, b: string) {
    const playerA = statsObj[a];
    const playerB = statsObj[b];
    const dir = order === "desc" ? 1 : -1;
    if ((playerB as any)[orderBy] < (playerA as any)[orderBy]) {
      return -1 * dir;
    }
    if ((playerB as any)[orderBy] > (playerA as any)[orderBy]) {
      return 1 * dir;
    }
    return 0;
  }

  return status === "loading" || Object.keys(statsObj).length === 0 ? (
    <div className={classes.centerContent}>
      <CircularProgress />
    </div>
  ) : (
    <TableContainer component={Paper} style={{ maxHeight: 900 }}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {["name", "team"].concat(ALL_CATEGORIES).map((cat) => (
              <TableCell key={cat}>
                <TableSortLabel
                  active={orderBy === cat}
                  direction={orderBy === cat ? order : "desc"}
                  onClick={() => {
                    sortByCat(cat);
                  }}
                >
                  {cat}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(players)
            .sort(applyTableOrder)
            .map((playerId: string, idx: number) => (
              <TableRow key={playerId}>
                <TableCell>{idx + 1}</TableCell>
                <TableCell>
                  <Link to={"/audl/player/" + playerId}>
                    {statsObj[playerId].name}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={"/audl/team/" + statsObj[playerId].teamId}>
                    {statsObj[playerId].team}
                  </Link>
                </TableCell>
                {ALL_CATEGORIES.map((cat) => (
                  <TableCell key={cat}>
                    {getCatForPlayer(cat, playerId)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
