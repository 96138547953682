import React from "react";
import { Container } from "@material-ui/core";
import { useParams, Link } from "react-router-dom";

import { DATA, Game } from "./data";

type GameWithTeamId = Game & { teamId: string };

export function Tournament() {
  const { id } = useParams<{ id: string }>();

  const games: GameWithTeamId[] = [];
  for (const teamId of Object.keys(DATA)) {
    games.push(
      ...DATA[teamId]
        .map((g) => Object.assign({ teamId }, g))
        .filter(
          (g) =>
            g.tournamentId === id && parseInt(g.score) > parseInt(g.oppScore)
        )
    );
  }

  const name = games[0].tournament;

  return (
    <Container>
      <h1>
        <a
          rel="noreferrer"
          target={"_blank"}
          href={`https://play.usaultimate.org/events/${id}/schedule/Men/Club-Men/`}
        >
          {name}
        </a>
      </h1>
      {games.map((g) => {
        return (
          <div>
            <Link to={`/usau/team/${g.teamId}`}>{g.team}</Link>
            <span>{` ${g.score} - ${g.oppScore} `}</span>
            <Link to={`/usau/team/${g.oppId}`}>{g.opp}</Link>
            <span>({g.date})</span>
          </div>
        );
      })}
    </Container>
  );
}
