import React from "react";
import { LineChart, Line, XAxis, ReferenceLine } from "recharts";

import { useTheme } from "@material-ui/core/styles";

export interface IPSparkChartProps {
  value: number;
  data: number[];
  label: string;
  bucketSize?: number;
}

export function IPSparkChart(props: IPSparkChartProps) {
  const theme = useTheme();
  const referenceLineColor =
    theme.palette.type === "light" ? "#8B2131" : theme.palette.secondary.main;
  const lineColor =
    theme.palette.type === "light"
      ? theme.palette.secondary.contrastText
      : theme.palette.primary.contrastText;

  const bucketSize = props.bucketSize || 1;
  let dataPointsAboveValue = 0;

  const dataDict: { [key: string]: number } = {};
  for (const d of props.data) {
    const bucket = (Math.ceil(d / bucketSize) * bucketSize).toString();
    if (!dataDict[bucket]) {
      dataDict[bucket] = 0;
    }
    dataDict[bucket] = dataDict[bucket] + 1;
    if (props.value > d) {
      dataPointsAboveValue++;
    }
  }

  const data: { value: number; count: number }[] = Object.keys(dataDict).map(
    (key) => {
      return {
        value: parseInt(key),
        count: dataDict[key],
      };
    }
  );

  const refVal = (Math.ceil(props.value / bucketSize) * bucketSize).toFixed(0);

  const percentile = (100 * (dataPointsAboveValue / props.data.length)).toFixed(
    1
  );

  return (
    <div>
      <div>
        {props.label} - {props.value.toFixed(1)} ({percentile}% percentile)
      </div>
      <LineChart width={200} height={100} data={data}>
        <ReferenceLine
          x={refVal}
          stroke={referenceLineColor}
          strokeWidth={2}
          strokeDasharray="3 3"
        />
        <XAxis dataKey="value" hide={true} />
        <Line
          isAnimationActive={false}
          stroke={lineColor}
          type="linear"
          dataKey="count"
          strokeDasharray="3 3"
          strokeWidth={1}
          dot={false}
        />
      </LineChart>
    </div>
  );
}
