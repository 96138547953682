import React, { useEffect, useState, useMemo } from "react";
import { collection, doc } from "firebase/firestore";
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
} from "reactfire";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Slider from "@material-ui/core/Slider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radio from "@material-ui/core//Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import { Circle, Stage, Layer, Line } from "react-konva";
import h337 from "heatmap.js";

import { IPAutocomplete } from "../common/ipautocomplete";
import {
  ThrowEvent,
  throwIsHuck,
  throwIsDump,
  throwIsGainer,
  throwIsSwing,
  distanceOfThrow,
} from "../common/audl";

interface Team {
  abbrev: string;
  full_name: string;
}

interface Player {
  name: string;
  team_ids: string[];
}

interface ThrowPoint {
  x: number;
  y: number;
  turnover: boolean;
  goal: boolean;
}

const CANVAS_WIDTH = 340;

const CANVAS_HEIGHT = 750;

// Convert a number that ranges from -27 -> 27 to 0 -> CANVAS_WIDTH
function xCoordToCanvas(x: number) {
  return ((x + 27) / 54) * CANVAS_WIDTH;
}

// Convert a number that ranges from 0 -> 120 to 0 -> CANVAS_HEIGHT
function yCoordToCanvas(y: number) {
  return (1 - y / 120) * CANVAS_HEIGHT;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      marginTop: 10,
      marginLeft: 20,
      display: "flex",
      gap: 20,
      flexWrap: "wrap",
    },
    centerContent: {
      textAlign: "center",
      width: "70%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 10,
      marginBottom: 10,
    },
    filerControlsContainer: {
      display: "flex",
      flexDirection: "column",
      gap: 10,
    },
    locSlider: {
      width: 300,
    },
    checkboxes: {
      display: "flex",
    },
    attackingEndzone: {
      width: 339,
      height: 120,
      position: "absolute",
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
      zIndex: 1,
    },
    heatmap: {
      width: CANVAS_WIDTH,
      height: CANVAS_HEIGHT,
    },
    fieldContainer: {
      borderStyle: "solid",
      borderWidth: 1,
      width: CANVAS_WIDTH,
      height: CANVAS_HEIGHT,
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
      gap: 10,
    },
    infoText: {
      fontSize: "large",
    },
    keyText: {
      fontSize: "small",
      borderStyle: "solid",
      borderWidth: 1,
      width: 300,
      padding: 10,
    },
  })
);

export function Audl() {
  const firestore = useFirestore();
  const theme = useTheme();
  // Pick either primary or secondary to give most contrast against current mode.
  const contrastingThemeColor =
    theme.palette.type === "light" ? "primary" : "secondary";
  const lineColor =
    theme.palette.type === "light"
      ? theme.palette.secondary.contrastText
      : theme.palette.primary.contrastText;

  const classes = useStyles();

  const teamsRef = doc(firestore, "audl-teams", "teams");
  const { data: teamData } = useFirestoreDocData(teamsRef);

  const teams = (teamData ? teamData : {}) as Record<string, Team>;

  const playersRef = doc(firestore, "audl-players", "players");
  const { data: playerData } = useFirestoreDocData(playersRef);

  const players = (playerData ? playerData : {}) as Record<string, Player>;

  const collectionRef = collection(firestore, "audl-throws");
  const { status, data } = useFirestoreCollectionData(collectionRef);
  const [heatMapInstance, setHeatMapInstance] = useState<h337.Heatmap<
    "value",
    "x",
    "y"
  > | null>(null);
  const [seasons, setSeasons] = useState<string[]>([]);
  const [throws, setThrows] = useState<ThrowEvent[]>([]);
  const [throwsMatchingFilter, setThrowsMatchingFilter] = useState<
    ThrowEvent[]
  >([]);
  const [throwArrays, setThrowArrays] = useState<ThrowPoint[][]>([]);
  const [vizType, setVizType] = useState<string>("origin");

  // Fiters
  const [season, setSeason] = useState<string | null>(null);
  const [gameId, setGameId] = useState<string | null>(null);
  const [team, setTeam] = useState<string | null>(null);
  const [defTeam, setDefTeam] = useState<string | null>(null);
  const [thrower, setThrower] = useState<string | null>(null);
  const [receiver, setReceiver] = useState<string | null>(null);
  const [originXSlider, setOriginXSlider] = useState<number[]>([-27, 27]);
  const [originYSlider, setOriginYSlider] = useState<number[]>([0, 120]);
  const [destinationXSlider, setDestinationXSlider] = useState<number[]>([
    -27, 27,
  ]);
  const [destinationYSlider, setDestinationYSlider] = useState<number[]>([
    0, 120,
  ]);
  const [distanceSlider, setDistanceSlider] = useState<number[]>([0, 132]);
  const [showGoals, setShowGoals] = useState<boolean>(true);
  const [showCompletions, setShowCompletions] = useState<boolean>(true);
  const [showTurnovers, setShowTurnovers] = useState<boolean>(true);
  const [showDumps, setShowDumps] = useState<boolean>(true);
  const [showSwings, setShowSwings] = useState<boolean>(true);
  const [showHucks, setShowHucks] = useState<boolean>(true);
  const [showGainers, setShowGainers] = useState<boolean>(true);

  // Calculated stats
  const [totalThrows, setTotalThrows] = useState<number>(0);
  const [totalCompletions, setTotalCompletions] = useState<number>(0);
  const [totalGoals, setTotalGoals] = useState<number>(0);
  const [totalTurnovers, setTotalTurnovers] = useState<number>(0);
  const [averageYardsPerCompletion, setAverageYardsPerCompletion] =
    useState<number>(0);
  const [averageYardsPerThrow, setAverageYardsPerThrow] = useState<number>(0);
  const [totalDumps, setTotalDumps] = useState<number>(0);
  const [totalSwings, setTotalSwings] = useState<number>(0);
  const [totalHucks, setTotalHucks] = useState<number>(0);
  const [totalGainers, setTotalGainers] = useState<number>(0);

  const gameIds = useMemo(() => {
    return [
      ...new Set(
        throws
          .filter((t) => season === null || t.season === season)
          .map((t) => t.game_id)
      ),
    ].sort((a, b) => (a > b ? -1 : 1));
  }, [season, throws]);

  useDebouncedEffect(
    () => {
      const seasonFilter = (throwEvent: ThrowEvent) => {
        return season === null || throwEvent.season === season;
      };

      const gameFilter = (throwEvent: ThrowEvent) => {
        return gameId === null || throwEvent.game_id === gameId;
      };

      const teamFilter = (throwEvent: ThrowEvent) => {
        return (
          (team === null || throwEvent.ot_id === team) &&
          (defTeam === null || throwEvent.dt_id === defTeam)
        );
      };

      const playerFilter = (throwEvent: ThrowEvent) => {
        return (
          (thrower === null || throwEvent.t_id === thrower) &&
          (receiver === null || throwEvent.r_id === receiver)
        );
      };

      const originFilter = (throwEvent: ThrowEvent) => {
        return (
          throwEvent.x1 >= originXSlider[0] &&
          throwEvent.x1 <= originXSlider[1] &&
          throwEvent.y1 >= originYSlider[0] &&
          throwEvent.y1 <= originYSlider[1]
        );
      };

      const destinationFilter = (throwEvent: ThrowEvent) => {
        return (
          throwEvent.x2 >= destinationXSlider[0] &&
          throwEvent.x2 <= destinationXSlider[1] &&
          throwEvent.y2 >= destinationYSlider[0] &&
          throwEvent.y2 <= destinationYSlider[1]
        );
      };

      const distanceFilter = (throwEvent: ThrowEvent) => {
        let distance = 0;
        if (throwEvent.x2 && throwEvent.y2) {
          distance = distanceOfThrow(throwEvent);
        }
        return distance >= distanceSlider[0] && distance <= distanceSlider[1];
      };

      const resultFilter = (throwEvent: ThrowEvent) => {
        const result = throwEvent.res;
        const goal = result === "goal";
        if (goal) return showGoals;

        const turnover =
          result === "stall" || result === "throw_away" || result === "drop";
        if (turnover) return showTurnovers;

        return showCompletions;
      };

      const typeFilter = (throwEvent: ThrowEvent) => {
        if (!showDumps && throwIsDump(throwEvent)) return false;
        if (!showSwings && throwIsSwing(throwEvent)) return false;
        if (!showHucks && throwIsHuck(throwEvent)) return false;
        if (!showGainers && throwIsGainer(throwEvent)) return false;
        return true;
      };

      const filteredThrows = throws
        .filter(seasonFilter)
        .filter(gameFilter)
        .filter(teamFilter)
        .filter(playerFilter)
        .filter(originFilter)
        .filter(destinationFilter)
        .filter(distanceFilter)
        .filter(resultFilter)
        .filter(typeFilter);
      setThrowsMatchingFilter(filteredThrows);
    },
    [
      throws,
      season,
      gameId,
      team,
      defTeam,
      thrower,
      receiver,
      originXSlider,
      originYSlider,
      destinationXSlider,
      destinationYSlider,
      distanceSlider,
      showGoals,
      showCompletions,
      showTurnovers,
      showDumps,
      showSwings,
      showHucks,
      showGainers,
    ],
    250
  );

  // When the filtered throws change make updates that depend on this.
  useEffect(() => {
    // 2-d array where each array represents the throw/catch of a throw.
    setThrowArrays(
      throwsMatchingFilter.map((throwEvent) => [
        {
          x: throwEvent.x1,
          y: throwEvent.y1,
          turnover: throwEvent.res === "stall",
          goal: false,
        },
        {
          x: throwEvent.x2,
          y: throwEvent.y2,
          turnover: throwEvent.res === "throw_away",
          goal: throwEvent.res === "goal",
        },
      ])
    );

    setTotalThrows(throwsMatchingFilter.length);
    const completedThrows = throwsMatchingFilter.filter(
      (t) => !(t.res === "stall" || t.res === "throw_away")
    );
    setTotalCompletions(completedThrows.length);
    setTotalGoals(throwsMatchingFilter.filter((t) => t.res === "goal").length);
    setTotalTurnovers(
      throwsMatchingFilter.filter(
        (t) => t.res === "stall" || t.res === "throw_away"
      ).length
    );
    setAverageYardsPerCompletion(
      completedThrows
        .map((throwEvent) => distanceOfThrow(throwEvent))
        .reduce((sum, a) => sum + a, 0) / completedThrows.length
    );
    setAverageYardsPerThrow(
      throwsMatchingFilter
        .map((throwEvent) => distanceOfThrow(throwEvent))
        .reduce((sum, a) => sum + a, 0) / throwsMatchingFilter.length
    );
    setTotalDumps(throwsMatchingFilter.filter(throwIsDump).length);
    setTotalSwings(throwsMatchingFilter.filter(throwIsSwing).length);
    setTotalHucks(throwsMatchingFilter.filter(throwIsHuck).length);
    setTotalGainers(throwsMatchingFilter.filter(throwIsGainer).length);
  }, [throwsMatchingFilter]);

  useEffect(() => {
    // If there is no data there is nothing to do.
    if (!data) return;
    // Go threw each game and create a "throw event"
    let throws: ThrowEvent[] = [];
    for (const d of data) {
      const gameId = d["NO_ID_FIELD"] as string;
      const throwsForGame = d["throws"] as ThrowEvent[];
      throws = throws.concat(
        throwsForGame.map((tfg) => {
          return { ...tfg, game_id: gameId, season: gameId.slice(0, 4) };
        })
      );
    }
    setSeasons(
      [...new Set(throws.map((t) => t.season))].sort((a, b) => (a > b ? -1 : 1))
    );
    setThrows(throws);
  }, [data]);

  const originXSliderChange = (event: {}, newValue: number | number[]) => {
    if (typeof newValue !== "number") {
      setOriginXSlider(newValue);
    }
  };
  const originYSliderChange = (event: {}, newValue: number | number[]) => {
    if (typeof newValue !== "number") {
      setOriginYSlider(newValue);
    }
  };
  const destinationXSliderChange = (event: {}, newValue: number | number[]) => {
    if (typeof newValue !== "number") {
      setDestinationXSlider(newValue);
    }
  };
  const destinationYSliderChange = (event: {}, newValue: number | number[]) => {
    if (typeof newValue !== "number") {
      setDestinationYSlider(newValue);
    }
  };
  const distanceSliderChange = (event: {}, newValue: number | number[]) => {
    if (typeof newValue !== "number") {
      setDistanceSlider(newValue);
    }
  };
  const toggleShowGoals = () => {
    setShowGoals(!showGoals);
  };
  const toggleShowTurnovers = () => {
    setShowTurnovers(!showTurnovers);
  };
  const toggleShowCompletions = () => {
    setShowCompletions(!showCompletions);
  };
  const toggleShowDumps = () => {
    setShowDumps(!showDumps);
  };
  const toggleShowSwings = () => {
    setShowSwings(!showSwings);
  };
  const toggleShowHucks = () => {
    setShowHucks(!showHucks);
  };
  const toggleShowGainers = () => {
    setShowGainers(!showGainers);
  };
  const changeVizType = (event: {}, value: string) => {
    setVizType(value);
  };

  // Create the heatmap instance when data has loaded.
  useEffect(() => {
    if (!document.querySelector("#heatMap") || heatMapInstance) return;
    setHeatMapInstance(
      h337.create({
        radius: 25,
        blur: 0.9,
        container: document.querySelector<HTMLElement>("#heatMap")!,
      })
    );
  }, [data, heatMapInstance]);

  useEffect(() => {
    if (heatMapInstance) {
      const origin = vizType === "origin";
      const throwBuckets: {
        [key: string]: { x: number; y: number; value: number };
      } = {};
      let max = 1;
      for (const throwEvent of throwsMatchingFilter) {
        const xToUse = origin ? throwEvent.x1 : throwEvent.x2;
        const yToUse = origin ? throwEvent.y1 : throwEvent.y2;
        const x = Math.floor(xCoordToCanvas(xToUse));
        const y = Math.floor(yCoordToCanvas(yToUse));
        const key = x + "-" + y;
        if (!throwBuckets[key]) {
          throwBuckets[key] = { x: x, y: y, value: 0 };
        }
        throwBuckets[key].value = throwBuckets[key].value + 1;
        if (throwBuckets[key].value > max) {
          max = throwBuckets[key].value;
        }
      }

      // heatmap data format
      const data = {
        min: 0,
        max: max,
        data: Object.values(throwBuckets),
      };
      // if you have a set of datapoints always use setData instead of addData
      // for data initialization
      heatMapInstance.setData(data);
    }
  }, [throwsMatchingFilter, heatMapInstance, vizType]);

  function fillColor(throwPoint: ThrowPoint) {
    if (throwPoint.goal) {
      return "green";
    }
    if (throwPoint.turnover) {
      return "red";
    }
    return lineColor;
  }

  const getTeamIdsFromGameId = (gameId: string) => {
    const parts = gameId.split("-");
    const teamAbbr1 = parts[parts.length - 2];
    const teamAbbr2 = parts[parts.length - 1];
    return Object.entries(teams)
      .filter(([teamId, team]) => [teamAbbr1, teamAbbr2].includes(team.abbrev))
      .map(([teamId, team]) => teamId);
  };

  const filteredTeams = Object.keys(teams)
    .filter(
      (k) =>
        k !== "NO_ID_FIELD" &&
        (!gameId || getTeamIdsFromGameId(gameId).includes(k))
    )
    .sort((a, b) => (teams[a].full_name > teams[b].full_name ? 1 : -1));

  const filteredPlayers = Object.keys(players)
    .filter(
      (k) =>
        k !== "NO_ID_FIELD" &&
        (!team || players[k].team_ids.includes(team)) &&
        (!gameId ||
          getTeamIdsFromGameId(gameId).some((g) =>
            players[k].team_ids.includes(g)
          ))
    )
    .sort((a, b) => (players[a].name > players[b].name ? 1 : -1));

  return status === "loading" ? (
    <div className={classes.centerContent}>
      <CircularProgress />
    </div>
  ) : (
    <div className={classes.container}>
      <div className={classes.filerControlsContainer}>
        <IPAutocomplete
          options={seasons}
          getOptionLabel={(option: string) => option}
          value={season}
          onChange={setSeason}
          label="Season"
        />
        <IPAutocomplete
          options={gameIds}
          getOptionLabel={(option: string) => option}
          value={gameId}
          onChange={setGameId}
          label="Game"
        />
        <FormLabel component="legend">Teams</FormLabel>
        <IPAutocomplete
          options={filteredTeams}
          getOptionLabel={(option: string) => teams[option].full_name}
          value={team}
          onChange={setTeam}
          label="Off Team"
        />
        <IPAutocomplete
          options={filteredTeams}
          getOptionLabel={(option: string) => teams[option].full_name}
          value={defTeam}
          onChange={setDefTeam}
          label="Def Team"
        />
        <FormLabel component="legend">Players</FormLabel>
        <IPAutocomplete
          options={filteredPlayers}
          getOptionLabel={(option: string) => players[option].name}
          value={thrower}
          onChange={setThrower}
          label="Thrower"
        />
        <IPAutocomplete
          options={filteredPlayers}
          getOptionLabel={(option: string) => players[option].name}
          value={receiver}
          onChange={setReceiver}
          label="Receiver"
        />
        <FormLabel component="legend">Throw Location</FormLabel>
        <div className={classes.locSlider}>
          Throw Origin X
          <Slider
            value={originXSlider}
            onChange={originXSliderChange}
            valueLabelDisplay="auto"
            min={-27}
            max={27}
            color={contrastingThemeColor}
          ></Slider>
        </div>
        <div className={classes.locSlider}>
          Throw Origin Y
          <Slider
            value={originYSlider}
            onChange={originYSliderChange}
            valueLabelDisplay="auto"
            min={0}
            max={120}
            color={contrastingThemeColor}
          ></Slider>
        </div>
        <div className={classes.locSlider}>
          Throw Destination X
          <Slider
            value={destinationXSlider}
            onChange={destinationXSliderChange}
            valueLabelDisplay="auto"
            min={-27}
            max={27}
            color={contrastingThemeColor}
          ></Slider>
        </div>
        <div className={classes.locSlider}>
          Throw Destination Y
          <Slider
            value={destinationYSlider}
            onChange={destinationYSliderChange}
            valueLabelDisplay="auto"
            min={0}
            max={120}
            color={contrastingThemeColor}
          ></Slider>
        </div>
        <div className={classes.locSlider}>
          Throw Distance
          <Slider
            value={distanceSlider}
            onChange={distanceSliderChange}
            valueLabelDisplay="auto"
            min={0}
            max={132}
            color={contrastingThemeColor}
          ></Slider>
        </div>
        <FormLabel component="legend">Throw Result</FormLabel>
        <div className={classes.checkboxes}>
          <div>
            <Checkbox
              checked={showGoals}
              onChange={toggleShowGoals}
              color={contrastingThemeColor}
            />
            Goals
          </div>
          <div>
            <Checkbox
              checked={showCompletions}
              onChange={toggleShowCompletions}
              color={contrastingThemeColor}
            />
            Completions
          </div>
          <div>
            <Checkbox
              checked={showTurnovers}
              onChange={toggleShowTurnovers}
              color={contrastingThemeColor}
            />
            Turnovers
          </div>
        </div>
        <FormLabel component="legend">Throw Type</FormLabel>
        <div className={classes.checkboxes}>
          <div>
            <Checkbox
              checked={showDumps}
              onChange={toggleShowDumps}
              color={contrastingThemeColor}
            />
            Dumps
          </div>
          <div>
            <Checkbox
              checked={showSwings}
              onChange={toggleShowSwings}
              color={contrastingThemeColor}
            />
            Swings
          </div>
          <div>
            <Checkbox
              checked={showHucks}
              onChange={toggleShowHucks}
              color={contrastingThemeColor}
            />
            Hucks
          </div>
          <div>
            <Checkbox
              checked={showGainers}
              onChange={toggleShowGainers}
              color={contrastingThemeColor}
            />
            Gainers
          </div>
        </div>
      </div>
      <div
        className={classes.fieldContainer}
        style={{ borderColor: lineColor }}
      >
        <div
          className={classes.attackingEndzone}
          style={{ borderColor: lineColor }}
        ></div>
        <div
          id="heatMap"
          className={classes.heatmap}
          style={{ display: vizType === "line" ? "none" : "inline" }}
        ></div>
        {vizType === "line" && (
          <Stage width={CANVAS_WIDTH} height={CANVAS_HEIGHT}>
            <Layer>
              {throwArrays.slice(0, 1000).map((throwArray, i) => {
                return (
                  <Line
                    key={"line" + i}
                    points={[
                      xCoordToCanvas(throwArray[0].x),
                      yCoordToCanvas(throwArray[0].y),
                      xCoordToCanvas(throwArray[1].x),
                      yCoordToCanvas(throwArray[1].y),
                    ]}
                    stroke={fillColor(throwArray[1])}
                  />
                );
              })}
              {throwArrays.slice(0, 1000).map((throwArray, i) => {
                return (
                  <Circle
                    key={"circle" + i}
                    x={xCoordToCanvas(throwArray[1].x)}
                    y={yCoordToCanvas(throwArray[1].y)}
                    radius={2}
                    fill={fillColor(throwArray[1])}
                  />
                );
              })}
            </Layer>
          </Stage>
        )}
      </div>
      <div className={classes.textContainer}>
        <div className={classes.infoText}>
          <div>
            <b>Total Throws: </b> {totalThrows.toLocaleString()}
          </div>
          <div>
            <b>Completions: </b>
            {totalCompletions.toLocaleString()} (
            {((totalCompletions / totalThrows) * 100).toFixed(1)}%)
          </div>
          <div>
            <b>Turnovers: </b>
            {totalTurnovers.toLocaleString()} (
            {((totalTurnovers / totalThrows) * 100).toFixed(1)}
            %)
          </div>
          <div>
            <b>Goals: </b>
            {totalGoals.toLocaleString()} (
            {((totalGoals / totalThrows) * 100).toFixed(1)}
            %)
          </div>
          <div>
            <b>Avg Yards Per Throw: </b>
            {averageYardsPerThrow.toFixed(1)}
          </div>
          <div>
            <b>Avg Yards Per Completion: </b>
            {averageYardsPerCompletion.toFixed(1)}
          </div>
          <div>
            <b>Dump Throws: </b>
            {totalDumps.toLocaleString()} (
            {((totalDumps / totalThrows) * 100).toFixed(1)}
            %)
          </div>
          <div>
            <b>Swing Throws: </b>
            {totalSwings.toLocaleString()} (
            {((totalSwings / totalThrows) * 100).toFixed(1)}
            %)
          </div>
          <div>
            <b>Huck Throws: </b>
            {totalHucks.toLocaleString()} (
            {((totalHucks / totalThrows) * 100).toFixed(1)}
            %)
          </div>
          <div>
            <b>Gainer Throws: </b>
            {totalGainers.toLocaleString()} (
            {((totalGainers / totalThrows) * 100).toFixed(1)}
            %)
          </div>
        </div>
        <div className={classes.keyText}>
          <div>
            <b>Huck: </b>A throw that gains 40+ vertical yards.
          </div>
          <div>
            <b>Swing: </b>A throw that travels more horizontal distance than
            vertical and doesn't go more than 5 yards upfield.
          </div>
          <div>
            <b>Dump: </b>A throw that gains no vertical yards and isn't
            classified as a swing.
          </div>
          <div>
            <b>Gainer: </b>Any other throw.
          </div>
        </div>
        <RadioGroup value={vizType} onChange={changeVizType}>
          <FormLabel component="legend">Graph Type</FormLabel>
          <div>
            <Radio color={contrastingThemeColor} value="origin" />
            Throw Origin Heatmap
          </div>
          <div>
            <Radio color={contrastingThemeColor} value="dest" />
            Throw Destination Heatmap
          </div>
          <div>
            <Radio color={contrastingThemeColor} value="line" />
            Throw Lines
          </div>
        </RadioGroup>
      </div>
    </div>
  );
}

const useDebouncedEffect = (effect: any, deps: any[], delay: number) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
    // eslint-disable-next-line
  }, [...(deps || []), delay]);
};
